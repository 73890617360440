import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import langService from "./langService";

import i18next from "i18next";

import { setLanguageInUrl } from "../../../helper/setLanguageInUrl";
import cookie from "js-cookie";

const urlLang = window?.location?.pathname?.split("/")?.[1]?.split("_")?.[1];

const currentLngIso = localStorage.getItem("i18next") || urlLang;
if (currentLngIso) {
  cookie.set("i18next", currentLngIso);
}

const initialState = {
  // get languages
  languages: null,
  languagesSelect: null,
  loading: false,
  error: null,
  ISO: currentLngIso,
};

// get languages
export const getLanguages = createAsyncThunk(
  "lang/getLanguages",
  async (_, thunkAPI) => {
    try {
      const result = await langService.getLanguages();
      return result;
    } catch (error) {
      const message =
        (error.response && error.response.data) || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// set Language
export const setLang = createAsyncThunk("lang/setLang", async (data) => {
  if (data) {
    await i18next.changeLanguage(data.toLowerCase());
    setLanguageInUrl(data.toLowerCase());
  }
  return data.toLowerCase();
});

export const langSlice = createSlice({
  name: "lang",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get languages
      .addCase(getLanguages.pending, (state) => {
        state.loading = true;
        state.languages = null;
        state.languagesSelect = null;
        state.error = null;
      })
      .addCase(getLanguages.fulfilled, (state, action) => {
        state.loading = false;
        state.languages = action.payload;
        state.languagesSelect = action.payload.map((lang) => ({
          label: lang.native,
          // (
          //   <div style={{ display: "flex", alignItems: "center" }}>
          //     <div
          //      style={{
          //       width: "15px",
          //        height: "15px",
          //         backgroundImage: `url(${lang.flag})`,
          //        backgroundPosition: "cover",
          //        backgroundRepeat: "no-repeat",
          //        marginRight: "5px",
          //       }}
          //    ></div>
          //     <p>{lang.native}</p>
          //   </div>
          // ),
          value: lang,
        }));
        state.error = null;
      })
      .addCase(getLanguages.rejected, (state, action) => {
        state.loading = false;
        state.languages = null;
        state.languagesSelect = null;
        state.error = action.payload;
      });
  },
});

export default langSlice.reducer;
