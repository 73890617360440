import _request from "../_request";

const getRoutesList = async (ISO, TOKEN) => {
  const config = {
    url: "routes",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getCrmRoutes = async (ISO) => {
  const config = {
    url: "/auth/routes",
    ISO,
  };
  return _request(config);
};

const routesService = {
  getRoutesList,
  getCrmRoutes,
};

export default routesService;
