import React, { Fragment } from "react";
import "./CountryMobileList.scss";

import { useDispatch, useSelector } from "react-redux";

import { FiCheck, FiChevronLeft } from "react-icons/fi";

import { setCountryId } from "../../../store/additional/settings/settingsSlice";
import { setCountryInUrl } from "../../../helper/setCountryInUrl";

const CountryMobileList = ({ value, setValue }) => {
  const dispatch = useDispatch();
  const { countries, countryId } = useSelector((state) => state.settings);

  const currentCountry = countries?.find(
    (country) => country?.id === countryId
  )?.country;

  const onChange = (country) => {
    dispatch(setCountryId(country?.id));
    setCountryInUrl(country?.country?.code?.toLowerCase());
  };

  const RenderItem = ({ item }) => {
    return (
      <li
        onClick={() => onChange(item)}
        className={item?.country?.name === currentCountry?.name ? "active" : ""}
      >
        <div className='left'>
          <p>{item?.country?.name}</p>
        </div>
        <div className='check'>
          <FiCheck />
        </div>
      </li>
    );
  };

  return (
    <Fragment>
      {countries && (
        <div id='CountryMobileList' className={value ? "open" : ""}>
          <div className='close'>
            <div
              className='icon'
              onClick={() => {
                setValue(false);
              }}
            >
              <FiChevronLeft />
            </div>
            <h3>Choose your office</h3>
          </div>
          <ul className='list'>
            {countries.map((item) => (
              <RenderItem key={item?.id} item={item} />
            ))}
          </ul>
        </div>
      )}
    </Fragment>
  );
};

export default CountryMobileList;
