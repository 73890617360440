import React, { useState, useEffect } from "react";
import "./SignUpShipperForm.scss";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  registerStep1,
  resetRegisterStep1,
} from "../../../../store/auth/authSlice";
import { useAuthError } from "./../../../../validations/auth/useAuthError";

import AuthFormWrapper from "../../Components/AuthFormWrapper/AuthFormWrapper";
import FormTitle from "../../Components/FormTitle/FormTitle";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import Button from "../../../Form/Button/Button";
import Input from "../../../Form/Inputs/Input";
import Label from "../../../Form/Label/Label";
import ErrorText from "../../../Form/ErrorText/ErrorText";
import SelectCountry from "../../../Form/Select/Components/SelectCountry/SelectCountry";
import PhoneInput from "../../../Form/Inputs/PhoneInput/PhoneInput";
import NotHaveAccount from "./../../../Form/NotHaveAccount/NotHaveAccount";

const SignUpShipperForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { registerStep1Error, registerStep1Loading } = useSelector(
    (state) => state.auth
  );
  const {
    step1_first_name_error,
    step1_last_name_error,
    step1_phone_index_id_error,
    step1_phone_error,
    step1_branch_id_error,
    step1_user_type_id_error,
  } = useAuthError();

  const { countryId, phoneIndexValueId } = useSelector(
    (state) => state.settings
  );
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");

  const submitHandler = async () => {
    let data = {
      user_type_id: 5,
      branch_id: countryId,
      first_name: firstName,
      last_name: lastName,
      phone_index_id: phoneIndexValueId,
      phone: phone,
    };
    dispatch(registerStep1(data));
  };

  useEffect(() => {
    if (
      registerStep1Error &&
      !step1_first_name_error &&
      !step1_last_name_error &&
      !step1_phone_index_id_error &&
      !step1_phone_error &&
      !step1_branch_id_error &&
      !step1_user_type_id_error
    ) {
      const url = `/register/shipper/auth?firstName=${firstName}&lastName=${lastName}&countryId=${countryId}&phoneIndex=${phoneIndexValueId}&phone=${phone}`;
      navigate(url);
      dispatch(resetRegisterStep1());
    }
  }, [
    dispatch,
    navigate,
    registerStep1Error,
    step1_first_name_error,
    step1_last_name_error,
    step1_phone_index_id_error,
    step1_phone_error,
    step1_branch_id_error,
    step1_user_type_id_error,
    firstName,
    lastName,
    countryId,
    phone,
    phoneIndexValueId,
  ]);

  return (
    <AuthFormWrapper>
      <form id='SignUpShipperForm' className='SignUpShipperForm'>
        <FormTitle
          title={t("landing.auth.titles.Welcome to the future of logistics")}
        >
          <span>
            {t("landing.auth.titles.It just take 2 minutes to set up")}
          </span>
        </FormTitle>
        <ProgressBar />
        <div className='group'>
          <div>
            <Label text={t("landing.auth.form.First name")} id='FirstName' />
            <Input
              value={firstName}
              setValue={setFirstName}
              placeholder={t("landing.auth.form.Enter first name")}
              id='FirstName'
              error={step1_first_name_error}
            />
            <ErrorText error={step1_first_name_error} />
          </div>
          <div>
            <Label text={t("landing.auth.form.Surname")} id='Surname' />
            <Input
              value={lastName}
              setValue={setLastName}
              placeholder={t("landing.auth.form.Enter surname")}
              id='Surname'
              error={step1_last_name_error}
            />
            <ErrorText error={step1_last_name_error} />
          </div>
          <div>
            <Label
              text={t("landing.auth.form.Phone number")}
              id='PhoneNumber'
            />
            <PhoneInput
              id='PhoneNumber'
              value={phone}
              setValue={setPhone}
              placeholder={t("landing.auth.form.Enter Phone number")}
              error={step1_phone_index_id_error || step1_phone_error}
            />
            <ErrorText error={step1_phone_index_id_error} />
            <ErrorText error={step1_phone_error} />
          </div>
          <div>
            <Label text={t("landing.auth.form.Country")} />
            <SelectCountry
              placeholder={t("landing.auth.form.Choose Country")}
              error={step1_branch_id_error}
            />
            <ErrorText error={step1_branch_id_error} />
            <ErrorText error={step1_user_type_id_error} />
          </div>
        </div>
        <Button
          loading={registerStep1Loading}
          title={t("landing.auth.form.Next")}
          onClick={() => {
            submitHandler();
          }}
        />
        <NotHaveAccount
          desc={t("landing.auth.titles.Already have an account?")}
          link='/login'
          text={t("landing.auth.form.Sign in here")}
        />
      </form>
    </AuthFormWrapper>
  );
};

export default SignUpShipperForm;
