import React from "react";
import "./CreateOrderAditionalInformation.scss";

import { useTranslation } from "react-i18next";

import DDatepicker from "./../../../../Form/DDatepicker/DDatepicker";
import DCurrencyInput from "../../../../Form/DCurrencyInput/DCurrencyInput";
import DTextarea from "./../../../../Form/DTextarea/DTextarea";
import DFileFormLarge from "../../../../Form/DFile/DFileFormLarge/DFileFormLarge";
import CreateOrderSectionTitle from "../CreateOrderSectionTitle/CreateOrderSectionTitle";

const CreateOrderAditionalInformation = ({
  title,
  readyCargoTime,
  setReadyCargoTime,
  cargoPrice,
  setCargoPrice,
  cargoPriceCurrency,
  setCargoPriceCurrency,
  comment,
  setComment,
  aditionalDocument,
  setAditionalDocument,
  /// errors
  cargos_comment_error,
  cargos_ready_date_error,
  cargos_price_error,
  cargos_price_currency_id_error,
  order_documents_error,
}) => {
  const { t } = useTranslation();
  return (
    <div id='CreateOrderAditionalInformation'>
      <CreateOrderSectionTitle title={title} />
      <div className='selectContainers'>
        {setReadyCargoTime && (
          <div className='dateContainer'>
            <DDatepicker
              value={readyCargoTime}
              setValue={setReadyCargoTime}
              placeholder='DD/MM/YYYY'
              label={t("shipper.forms.content.When will cargo be ready?")}
              error={cargos_ready_date_error}
            />
          </div>
        )}
        <div className='moneyContainer'>
          <DCurrencyInput
            id='AirShipperCreateCargoprice'
            value={cargoPrice}
            setValue={setCargoPrice}
            currencyValue={cargoPriceCurrency}
            setCurrencyValue={setCargoPriceCurrency}
            label={t("shipper.forms.content.Cargo price")}
            placeholder={t("shipper.forms.content.Cargo price")}
            error={cargos_price_error || cargos_price_currency_id_error}
          />
        </div>
      </div>
      {setAditionalDocument && (
        <div className='docsContainer'>
          <DFileFormLarge
            value={aditionalDocument}
            setValue={setAditionalDocument}
            title={t("shipper.forms.content.Attach document")}
            subTitle={t("shipper.forms.content.Optional")}
            error={order_documents_error}
          />
        </div>
      )}
      {setComment && (
        <div className='commentContainer'>
          <DTextarea
            id='AirShipperCreateComment'
            value={comment}
            setValue={setComment}
            placeholder={t("shipper.forms.content.Additional comment")}
            error={cargos_comment_error}
          />
        </div>
      )}
    </div>
  );
};

export default CreateOrderAditionalInformation;
