import { useSelector } from "react-redux";

export const useAdvancesErrors = () => {
  const { createAdvanceError, updateAdvanceError } = useSelector(
    (state) => state.advance
  );

  const create_currency_id_error =
    createAdvanceError &&
    createAdvanceError.errors &&
    createAdvanceError.errors.currency_id &&
    createAdvanceError.errors.currency_id[0];

  const create_amount_error =
    createAdvanceError &&
    createAdvanceError.errors &&
    createAdvanceError.errors.amount &&
    createAdvanceError.errors.amount[0];

  const create_payment_at_error =
    createAdvanceError &&
    createAdvanceError.errors &&
    createAdvanceError.errors.payment_at &&
    createAdvanceError.errors.payment_at[0];

  const create_comment_error =
    createAdvanceError &&
    createAdvanceError.errors &&
    createAdvanceError.errors.comment &&
    createAdvanceError.errors.comment[0];

  const update_currency_id_error =
    updateAdvanceError &&
    updateAdvanceError.errors &&
    updateAdvanceError.errors.currency_id &&
    updateAdvanceError.errors.currency_id[0];

  const update_amount_error =
    updateAdvanceError &&
    updateAdvanceError.errors &&
    updateAdvanceError.errors.amount &&
    updateAdvanceError.errors.amount[0];

  const update_payment_at_error =
    updateAdvanceError &&
    updateAdvanceError.errors &&
    updateAdvanceError.errors.payment_at &&
    updateAdvanceError.errors.payment_at[0];

  const update_comment_error =
    updateAdvanceError &&
    updateAdvanceError.errors &&
    updateAdvanceError.errors.comment &&
    updateAdvanceError.errors.comment[0];

  return {
    create_currency_id_error,
    create_amount_error,
    create_payment_at_error,
    create_comment_error,
    update_currency_id_error,
    update_amount_error,
    update_payment_at_error,
    update_comment_error,
  };
};
