import React, { useEffect, useState } from "react";
import "./EditProfileModal.scss";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { MdModeEditOutline } from "react-icons/md";

import { useUpdateUser } from "../../../../../../validations/auth/useUpdateUser";
import {
  resetUpdateUser,
  updateUser,
} from "../../../../../../store/user/userSlice";

import DModal from "../../../../../Modal/DModal/DModal";
import DInputs from "../../../../../Form/DInputs/DInputs";

const EditProfileModal = ({ isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { user, userType } = useSelector((state) => state.auth);
  const { updateUserLoading, updateUserResponce } = useSelector(
    (state) => state.user
  );

  const {
    update_first_name_error,
    update_last_name_error,
    update_company_name_error,
    update_company_legal_id_error,
    update_carrier_legal_id_error,
  } = useUpdateUser();

  const [firstName, setFirstName] = useState(
    user.first_name ? user.first_name : ""
  );
  const [lastName, setLastName] = useState(
    user.last_name ? user.last_name : ""
  );

  const [companyName, setCompanyName] = useState(
    user && user?.company && user?.company.name ? user?.company.name : ""
  );

  const [companyLegalId, setCompanyLegalId] = useState(
    user && user?.company && user?.company?.legal_id
      ? user?.company.legal_id
      : ""
  );
  const [legalId, setLegalId] = useState(
    user && user.legal_id ? user.legal_id : ""
  );
  const [address, setAddress] = useState(user?.company?.address || "");

  const submitHandler = () => {
    let data;

    switch (userType) {
      case 4:
        data = {
          first_name: firstName,
          last_name: lastName,
          company: {
            name: companyName,
            legal_id: companyLegalId,
            address: address,
          },
        };
        break;
      case 5:
        data = {
          first_name: firstName,
          last_name: lastName,
          company: {
            name: companyName,
            legal_id: companyLegalId,
            address: address,
          },
        };
        break;
      case 2:
        data = {
          first_name: firstName,
          last_name: lastName,
          legal_id: legalId,
        };
        break;
      default:
        return;
    }
    dispatch(updateUser(data));
  };

  useEffect(() => {
    if (updateUserResponce) {
      dispatch(resetUpdateUser());
      isClose();
    }
  }, [dispatch, isClose, updateUserResponce]);

  return (
    <DModal
      loading={updateUserLoading}
      isOpen={isOpen}
      isClose={isClose}
      submitText={t("tms.modals.buttons.Save")}
      status={"info"}
      icon={<MdModeEditOutline />}
      title={t("tms.modals.titles.Edit profile")}
      onClick={submitHandler}
    >
      <div id='editUserProfileModal'>
        <div className='fromGorup'>
          <DInputs
            id='firstNameUserProfile'
            value={firstName}
            setValue={setFirstName}
            label={t("tms.modals.labels.First name")}
            placeholder={t("tms.modals.placeholder.Enter first name")}
            required
            error={update_first_name_error}
          />
          <DInputs
            id='lastNameUserProfile'
            value={lastName}
            setValue={setLastName}
            required
            label={t("tms.modals.labels.Last name")}
            placeholder={t("tms.modals.placeholder.Enter last name")}
            error={update_last_name_error}
          />
          {userType === 2 && (
            <DInputs
              id='userLegalId'
              value={legalId}
              setValue={setLegalId}
              required
              label={t("tms.modals.labels.Legal ID")}
              placeholder={t("tms.modals.placeholder.Enter legal ID")}
              error={update_carrier_legal_id_error}
            />
          )}

          {(userType === 4 || userType === 5) && (
            <DInputs
              id='companyNameUserProfile'
              value={companyName}
              setValue={setCompanyName}
              required
              label={t("tms.modals.labels.Company name")}
              placeholder={t("tms.modals.placeholder.Enter company name")}
              error={update_company_name_error}
            />
          )}
          {(userType === 4 || userType === 5) && (
            <DInputs
              id='companyLegalIdUserProfile'
              value={companyLegalId}
              setValue={setCompanyLegalId}
              required
              label={t("tms.modals.labels.Company legal ID")}
              placeholder={t("tms.modals.placeholder.Enter company legal ID")}
              error={update_company_legal_id_error}
            />
          )}
          {(userType === 4 || userType === 5) && (
            <DInputs
              id='companyAddressUserProfile'
              value={address}
              setValue={setAddress}
              label={t("tms.modals.labels.Company address")}
              placeholder={t("tms.modals.placeholder.Enter company address")}
            />
          )}
        </div>
      </div>
    </DModal>
  );
};

export default EditProfileModal;
