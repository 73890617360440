import React, { Fragment, useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { FiEdit2 } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";

import {
  updateOrderCost,
  resetUpdateOrderCost,
} from "../../../../../../store/orders/costs/orderCostsSlice";
import { useOrderCostErrors } from "../../../../../../validations/order/useOrderCostsErrors";

import DModal from "../../../../../Modal/DModal/DModal";
import DCurrencyInput from "../../../../../Form/DCurrencyInput/DCurrencyInput";
import DSelect from "../../../../../Form/DSelect/DSelect";
import DTextarea from "../../../../../Form/DTextarea/DTextarea";

const UpdateCostModal = ({ isOpen, isClose, orderId, cost, operation }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    updateOrderCostLoading,
    updateOrderCostResponce,
    getOrderCostTypesLoading,
    getOrderCostTypesSelectResponce,
  } = useSelector((state) => state.orderCosts);

  const { currenciesSelect } = useSelector((state) => state.settings);
  const {
    update_category_id_error,
    update_amount_error,
    update_comment_error,
  } = useOrderCostErrors();

  const [amountName, setAmountName] = useState(
    getOrderCostTypesSelectResponce?.filter(
      (item) => item.value.id === cost?.category?.id
    )[0]
  );
  const [amountValue, setAmountValue] = useState(cost?.amount || "");
  const [currencyValue, setCurrencyValue] = useState(cost?.currency?.id);
  const [commentValue, setCommentValue] = useState(cost?.comment || "");

  useEffect(() => {
    if (getOrderCostTypesSelectResponce && cost && cost.type && cost.type.id) {
      const findValue = getOrderCostTypesSelectResponce.find(
        (item) => item.value.id === cost.type.id
      );
      setAmountName(findValue);
    }
  }, [getOrderCostTypesSelectResponce, cost]);

  useEffect(() => {
    if (currenciesSelect && cost && cost.currency && cost.currency.id) {
      const findValue = currenciesSelect.find(
        (item) => item.value.id === cost.currency.id
      );
      setCurrencyValue(findValue);
    }
  }, [currenciesSelect, cost]);

  const submithandler = () => {
    const data = {
      orderId: orderId,
      costId: cost && cost.id,
      operation: operation ? operation : "IN", // IN, EX
      category_id: amountName && amountName.value.id,
      amount: amountValue,
      comment: commentValue,
      currency_id: currencyValue && currencyValue.value.id,
    };
    dispatch(updateOrderCost(data));
  };

  useEffect(() => {
    if (updateOrderCostResponce) {
      dispatch(resetUpdateOrderCost());
      isClose();
    }
  }, [dispatch, isClose, updateOrderCostResponce]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose()}
        onClick={() => submithandler()}
        title={
          operation === "IN"
            ? t("tms.modals.titles.Update charge")
            : t("tms.modals.titles.Update cost")
        }
        submitText={t("tms.modals.buttons.Save")}
        icon={<FiEdit2 />}
        status='success'
        loading={updateOrderCostLoading}
      >
        <div id='orderAddNewCost'>
          <form className='formGroup'>
            <DSelect
              id='OrderAdditionalCostAmountName'
              value={amountName}
              setValue={setAmountName}
              placeholder={t("tms.modals.placeholder.Select cost name")}
              label={t("tms.modals.labels.Cost name")}
              options={
                getOrderCostTypesSelectResponce
                  ? getOrderCostTypesSelectResponce
                  : []
              }
              required
              error={update_category_id_error}
              loading={getOrderCostTypesLoading}
            />
            <DCurrencyInput
              id='OrderAdditionalCostAmount'
              value={amountValue}
              setValue={setAmountValue}
              currencyValue={currencyValue}
              setCurrencyValue={setCurrencyValue}
              required
              placeholder={t("tms.modals.placeholder.Enter cost")}
              label={t("tms.modals.labels.Cost")}
              error={update_amount_error}
            />
            <DTextarea
              id='OrderAdditionalCostComment'
              value={commentValue}
              setValue={setCommentValue}
              required
              placeholder={t("tms.modals.placeholder.Your comment...")}
              label={t("tms.modals.labels.Comment")}
              error={update_comment_error}
            />
          </form>
        </div>
      </DModal>
    </Fragment>
  );
};

export default UpdateCostModal;
