import { useSelector } from "react-redux";

export const useDriverErrors = () => {
  const { createDriverError, updateDriverError } = useSelector(
    (state) => state.drivers
  );

  const create_first_name_error =
    createDriverError &&
    createDriverError.errors &&
    createDriverError.errors.first_name &&
    createDriverError.errors.first_name[0];

  const create_last_name_error =
    createDriverError &&
    createDriverError.errors &&
    createDriverError.errors.last_name &&
    createDriverError.errors.last_name[0];

  const create_email_error =
    createDriverError &&
    createDriverError.errors &&
    createDriverError.errors.email &&
    createDriverError.errors.email[0];

  const create_phone_error =
    createDriverError &&
    createDriverError.errors &&
    createDriverError.errors.phone &&
    createDriverError.errors.phone[0];

  const create_phone_index_id_error =
    createDriverError &&
    createDriverError.errors &&
    createDriverError.errors.phone_index_id &&
    createDriverError.errors.phone_index_id[0];

  const update_first_name_error =
    updateDriverError &&
    updateDriverError.errors &&
    updateDriverError.errors.first_name &&
    updateDriverError.errors.first_name[0];

  const update_last_name_error =
    updateDriverError &&
    updateDriverError.errors &&
    updateDriverError.errors.last_name &&
    updateDriverError.errors.last_name[0];

  const update_email_error =
    updateDriverError &&
    updateDriverError.errors &&
    updateDriverError.errors.email &&
    updateDriverError.errors.email[0];

  const update_phone_error =
    updateDriverError &&
    updateDriverError.errors &&
    updateDriverError.errors.phone &&
    updateDriverError.errors.phone[0];

  const update_phone_index_id_error =
    updateDriverError &&
    updateDriverError.errors &&
    updateDriverError.errors.phone_index_id &&
    updateDriverError.errors.phone_index_id[0];

  return {
    create_first_name_error,
    create_last_name_error,
    create_email_error,
    create_phone_error,
    create_phone_index_id_error,
    update_first_name_error,
    update_last_name_error,
    update_email_error,
    update_phone_error,
    update_phone_index_id_error,
  };
};

export default useDriverErrors;
