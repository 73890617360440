import React, { Fragment } from "react";
import "./CreateOrderContainerGroup.scss";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import DSelect from "../../../../../../Form/DSelect/DSelect";
import DInputs from "../../../../../../Form/DInputs/DInputs";
import DQuantitySelect from "../../../../../../Form/DSelect/DQuantitySelect/DQuantitySelect";

const CreateOrderContainerGroup = ({ edit, index, item, group, setGroup }) => {
  const { t } = useTranslation();
  const {
    trailerTypesLoading,
    trailerTypesSelect,
    temperaturesLoading,
    temperaturesSelect,
  } = useSelector((state) => state.orderSettings);

  const tailerTypeHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].trailerType = value;
    updatedGroup[index].trailerSize = null;
    updatedGroup[index].temperatureRange = null;
    setGroup(updatedGroup);
  };

  const traileVolumeHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].trailerSize = value;
    setGroup(updatedGroup);
  };

  const temperatureHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].temperatureRange = value;
    setGroup(updatedGroup);
  };

  const weightHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].weight = value;
    setGroup(updatedGroup);
  };

  const quantityHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].quantity = value;
    setGroup(updatedGroup);
  };

  const trailerSizeOptions = item?.trailerType?.value?.sizes?.map((size) => {
    return {
      label: size.label,
      value: size,
    };
  });

  return (
    <Fragment>
      <div id='CreateOceanOrderContainerGroup'>
        <div className='BlockA'>
          <div className='RoadPackageTrailer'>
            <DSelect
              required
              value={item.trailerType}
              setValue={(value) => tailerTypeHandler(value)}
              id={`CreateOrderContainerGroup${index}`}
              label={t("shipper.forms.content.Container type")}
              placeholder={t("shipper.forms.content.Select container")}
              options={trailerTypesSelect}
              loading={trailerTypesLoading}
              error={item.trailerTypeError}
            />
          </div>
          {item && item.trailerType && item.trailerType.value.temperature && (
            <div className='RoadPackageTemperature'>
              <DSelect
                required
                value={item.temperatureRange}
                setValue={(value) => temperatureHandler(value)}
                id={`AddOrderTrailertemperatureSelect${index}`}
                loading={temperaturesLoading}
                label={t("shipper.forms.content.Temperature")}
                placeholder={t("shipper.forms.content.Select temperature")}
                options={temperaturesSelect}
                error={item.temperatureRangeError}
              />
            </div>
          )}
        </div>
        <div className='BlockB'>
          <div className='RoadPackageItemVolume'>
            <DSelect
              required
              value={item.trailerSize}
              setValue={(value) => traileVolumeHandler(value)}
              id={`AddOrderTrailerVolumeSelect${index}`}
              loading={false}
              label={t("shipper.forms.content.Container volume")}
              placeholder={t("shipper.forms.content.Select volume")}
              options={trailerSizeOptions || []}
              disabled={!item.trailerType}
              error={item.trailerSizeError}
            />
          </div>

          <div className='RoadPackageItemWeight'>
            <DInputs
              required
              type={"number"}
              id={`ShipperAirPackageItemTotalWeight${index}}`}
              placeholder={t("shipper.forms.content.Weight")}
              label={t("shipper.forms.content.Weight per container")}
              value={item.weight}
              setValue={(value) => weightHandler(value)}
              error={item.weightError}
            />
          </div>

          <div className='RoadPackageQuantity'>
            <DQuantitySelect
              disabled={edit}
              value={item.quantity}
              setValue={(value) => quantityHandler(value)}
              label={t("shipper.forms.content.Number of containers")}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CreateOrderContainerGroup;
