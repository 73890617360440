import React, { Fragment, useState, useEffect } from "react";
import "./AddTrailersModal.scss";

import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";

import {
  createTrailer,
  resetCreateTrailer,
} from "../../../../store/fleet/trailers/trailersSlice";
import { useTrailerErrors } from "./../../../../validations/fleet/useTrailerErrors";

import DModal from "../../../Modal/DModal/DModal";
import DSelect from "../../../Form/DSelect/DSelect";
import DInputs from "../../../Form/DInputs/DInputs";

const AddTrailersModal = ({ isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    createTrailerLoading,
    createTrailerResponce,
    getTrailerTypesLoading,
    getTrailerGroupSelectResponse,
  } = useSelector((state) => state.trailers);
  const {
    create_trailer_type_id_error,
    create_volume_capacity_error,
    create_weight_capacity_error,
    create_registration_number_error,
  } = useTrailerErrors();

  const [trailerType, setTrailerType] = useState(null);
  const [maxVolume, setMaxVolume] = useState("");
  const [maxWeight, setMaxWeight] = useState("");
  const [plateNumber, setPlateNumber] = useState("");

  const resetState = () => {
    setTrailerType(null);
    setMaxVolume("");
    setMaxWeight("");
    setPlateNumber("");
  };

  const submitHandler = () => {
    const data = {
      trailer_type_group_id: trailerType && trailerType.value.id,
      volume_capacity: maxVolume,
      weight_capacity: maxWeight,
      registration_number: plateNumber,
    };
    dispatch(createTrailer(data));
  };

  const filterOption = (option, inputValue) => {
    const { value } = option;
    return value.name.toLowerCase().includes(inputValue.toLowerCase());
  };

  useEffect(() => {
    if (createTrailerResponce) {
      dispatch(resetCreateTrailer());
      resetState();
      isClose();
    }
  }, [dispatch, isClose, createTrailerResponce]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose(false)}
        title={t("tms.modals.titles.Create Trailer")}
        submitText={t("tms.modals.buttons.Save")}
        icon={<FiPlus />}
        status='success'
        onClick={() => submitHandler()}
        loading={createTrailerLoading}
      >
        <div id='AddTrailersModal'>
          <div className='AddTrailersModalGroup'>
            <DSelect
              value={trailerType}
              setValue={setTrailerType}
              label={t("tms.modals.labels.Trailer")}
              placeholder={t("tms.modals.placeholder.Choose trailer type")}
              error={create_trailer_type_id_error}
              id='chooseTrailerType'
              options={getTrailerGroupSelectResponse}
              loading={getTrailerTypesLoading}
              filterOption={filterOption}
              required
              search
            />
            <DInputs
              id='trailerMaxWeight'
              label={t("tms.modals.labels.Max weight (KG)")}
              placeholder={t("tms.modals.placeholder.Enter max weight")}
              value={maxWeight}
              setValue={setMaxWeight}
              error={create_weight_capacity_error}
              required
            />
            <DInputs
              id='trailerMaxVolume'
              label={t("tms.modals.labels.Max volume (M³)")}
              placeholder={t("tms.modals.placeholder.Enter max volume")}
              value={maxVolume}
              setValue={setMaxVolume}
              error={create_volume_capacity_error}
              required
            />
            <DInputs
              id='trailerRegistrationNumber'
              label={t("tms.modals.labels.Registration plate number")}
              placeholder={t(
                "tms.modals.placeholder.Enter registration plate number"
              )}
              value={plateNumber}
              setValue={setPlateNumber}
              error={create_registration_number_error}
              required
            />
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default AddTrailersModal;
