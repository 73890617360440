import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { FiTrash2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  deletePartner,
  resetDeletePartner,
} from "./../../../../store/participants/partner/partnerSlice";

import DModal from "../../../Modal/DModal/DModal";

const DeletePartnerModal = ({ isOpen, isClose, participantId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { deletePartnerLoading, deletePartnerResponce } = useSelector(
    (state) => state.partner
  );

  const submitHandler = () => {
    dispatch(deletePartner(participantId));
  };

  useEffect(() => {
    if (deletePartnerResponce) {
      dispatch(resetDeletePartner());
      isClose();
      navigate("/dashboard/tms/customers_partners/partners");
    }
  }, [dispatch, navigate, isClose, deletePartnerResponce]);

  return (
    <DModal
      isOpen={isOpen}
      isClose={isClose}
      onClick={() => submitHandler()}
      submitText={t("tms.modals.buttons.Delete")}
      title={t("tms.modals.titles.Delete Partner")}
      icon={<FiTrash2 />}
      status='danger'
      loading={deletePartnerLoading}
    >
      <p>
        {t("tms.modals.desc.Are you sure you want to delete your partner?")}
      </p>
    </DModal>
  );
};

export default DeletePartnerModal;
