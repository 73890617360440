import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import partnersService from "./partnersService";

const initialState = {
  // get Partners
  partners: null,
  partnersLoading: false,
  partnersError: null,
};

// get Partners
export const getPartners = createAsyncThunk(
  "partners/getPartners",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.token;
      return await partnersService.getPartners(ISO, TOKEN);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const partnersSlice = createSlice({
  name: "partners",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get Partners
      .addCase(getPartners.pending, (state) => {
        state.partnersLoading = true;
        state.partners = null;
        state.partnersError = null;
      })
      .addCase(getPartners.fulfilled, (state, action) => {
        state.partnersLoading = false;
        state.partners = action.payload;
        state.partnersError = null;
      })
      .addCase(getPartners.rejected, (state, action) => {
        state.partnersLoading = false;
        state.partners = null;
        state.partnersError = action.payload;
      });
  },
});

export default partnersSlice.reducer;
