import React, { useState, useEffect } from "react";
import "./LoadBoardAddressSearchForm.scss";

import { useTranslation } from "react-i18next";

import { FiSearch } from "react-icons/fi";
import { PiHeartStraightDuotone } from "react-icons/pi";
import { useSelector, useDispatch } from "react-redux";

import {
  createLocation,
  resetCreateLocation,
} from "../../../store/favoriteLocations/favoriteLocationsSlice";
import { useFavoriteLocationsErrors } from "../../../validations/favoriteLocations/useFavoriteLocationsErrors";

import AddressSearchInput from "../../FilterForms/AddressSearchInput/AddressSearchInput";
import DButton from "../../Form/DButtons/DButton/DButton";
import ButtonLoader from "../../Form/DButtons/ButtonLoader/ButtonLoader";

const LoadBoardAddressSearchForm = ({
  setPickUp,
  setDropOff,
  pickUpInput,
  setPickUpInput,
  dropOffInput,
  setDropOffInput,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { createLocationLoading, createLocationResponce } = useSelector(
    (state) => state.favoriteLocations
  );
  const { from_inputted_address_error, to_inputted_address_error } =
    useFavoriteLocationsErrors();

  const [favourite, setFavourite] = useState(false);

  const favoriteHanlder = () => {
    const data = {
      from_inputted_address: pickUpInput,
      to_inputted_address: dropOffInput,
    };
    dispatch(createLocation(data));
  };

  const submitHandler = () => {
    setPickUp(pickUpInput);
    setDropOff(dropOffInput);
  };

  useEffect(() => {
    if (createLocationResponce) {
      dispatch(resetCreateLocation());
      setFavourite(true);
    }
  }, [dispatch, createLocationResponce]);

  return (
    <div id='LoadBoardAddressSearchForm'>
      <AddressSearchInput
        label={t("tms.filters.labels.Pick up")}
        placeholder={t("tms.filters.placeholders.City or country")}
        id={"LoadBoardFiltersPickUp"}
        inputValue={pickUpInput}
        setInputValue={setPickUpInput}
        icon={<FiSearch />}
        onChange={() => setFavourite(false)}
        error={from_inputted_address_error}
        clear={() => setPickUp("")}
      />
      <AddressSearchInput
        label={t("tms.filters.labels.Drop off")}
        placeholder={t("tms.filters.placeholders.City or country")}
        id={"LoadBoardFiltersDropOff"}
        inputValue={dropOffInput}
        setInputValue={setDropOffInput}
        icon={<FiSearch />}
        onChange={() => setFavourite(false)}
        error={to_inputted_address_error}
        clear={() => setDropOff("")}
      />

      {pickUpInput && dropOffInput && (
        <div className='favourite'>
          <div className='favouriteContainer'>
            {createLocationLoading ? (
              <ButtonLoader />
            ) : (
              <div
                className={`icon ${favourite ? "active" : ""} 
              `}
              >
                <PiHeartStraightDuotone
                  onClick={() => !favourite && favoriteHanlder()}
                />
              </div>
            )}
          </div>
        </div>
      )}
      <div className='searchButton'>
        <DButton
          disabled={!pickUpInput && !dropOffInput}
          title={t("tms.filters.buttons.Search")}
          onClick={submitHandler}
          loading={null}
        />
      </div>
    </div>
  );
};

export default LoadBoardAddressSearchForm;
