import React, { Fragment, useState } from "react";

import RowField from "./../../../../../../Form/TableFields/RowField/RowField";
import TextField from "./../../../../../../Form/TableFields/TextField/TextField";
import CenterField from "./../../../../../../Form/TableFields/CenterField/CenterField";
import CheckBoxField from "./../../../../../../Form/TableFields/CheckBoxField/CheckBoxField";
import PartnerField from "./../../../../../../Form/TableFields/PartnerField/PartnerField";
import BidResponseField from "./../../../Components/BidResponseField/BidResponseField";
import RateField from "../../../../../../Form/TableFields/RateField/RateField";

const SendBidTableItem = ({ partner, partnersBids, setPartnersBids }) => {
  const [activeCheckBox, setActiveCheckBox] = useState(
    partnersBids.find((i) => i.id === partner.id) ? true : null
  );

  const changedHanlder = () => {
    const findArray = partnersBids.find((i) => i.id === partner.id);
    if (findArray) {
      setPartnersBids(partnersBids.filter((i) => i.id !== partner.id));
      setActiveCheckBox(null);
    } else {
      setPartnersBids((state) => [...state, partner]);
      setActiveCheckBox(true);
    }
  };

  return (
    <Fragment>
      <RowField>
        <CenterField>
          <CheckBoxField
            disabled={partner.analytics.number_of_bid_requests > 0}
            value={activeCheckBox}
            setValue={changedHanlder}
          />
        </CenterField>
        <PartnerField label={partner.company.name || "-"} />
        <RateField rate={partner?.rating || 0} />
        <TextField
          label={
            (partner.company?.phone_index?.name || "") +
            (partner.company?.phone || "-")
          }
        />
        <TextField max={40} label={partner.company.email || "-"} />
        <BidResponseField partner={partner} />
      </RowField>
    </Fragment>
  );
};

export default SendBidTableItem;
