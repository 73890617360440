import React, { Fragment, useState, useEffect } from "react";
import "./AssignPartnerTableItem.scss";

import { useTranslation } from "react-i18next";

import { MdLink } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";

import {
  assignCarrier,
  resetAssignCarrier,
} from "./../../../../../../../store/orders/manageOrder/manageOrderSlice";
import { resetUpdateOrderCost } from "../../../../../../../store/orders/costs/orderCostsSlice";
import { useOrderCostErrors } from "./../../../../../../../validations/order/useOrderCostsErrors";

import RowField from "./../../../../../../Form/TableFields/RowField/RowField";
import TextField from "./../../../../../../Form/TableFields/TextField/TextField";
import CenterField from "./../../../../../../Form/TableFields/CenterField/CenterField";
import BidResponseField from "../../../Components/BidResponseField/BidResponseField";
import PartnerField from "./../../../../../../Form/TableFields/PartnerField/PartnerField";
import AssignPartnerButton from "./../../../Components/AssignPartnerButton/AssignPartnerButton";
import DModal from "../../../../../../Modal/DModal/DModal";
import RateField from "./../../../../../../Form/TableFields/RateField/RateField";
import DCurrencyInput from "./../../../../../../Form/DCurrencyInput/DCurrencyInput";

const AssignPartnerTableItem = ({
  setBidScreen,
  partner,
  isOpen,
  isClose,
  order,
  tab,
  setTab,
  partnerTab,
  setPartnerTab,
  page,
  setPage,
  partnersBids,
  setPartnersBids,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { assignCarrierLoading, assignCarrierResponce } = useSelector(
    (state) => state.manageOrder
  );
  const { updateOrderCostLoading, updateOrderCostResponce } = useSelector(
    (state) => state.orderCosts
  );
  const { update_amount_error } = useOrderCostErrors();

  const bidPrice =
    partner &&
    partner.analytics &&
    partner.analytics.bids[0] &&
    partner.analytics.bids[0].amount
      ? partner.analytics.bids[0].amount
      : "0";

  const bidCurrency =
    partner &&
    partner.analytics &&
    partner.analytics.bids[0] &&
    partner.analytics.bids[0].currency
      ? partner.analytics.bids[0].currency
      : null;

  const [openAssign, setOpenAssign] = useState(false);

  const [expense, setExpense] = useState(bidPrice);
  const [expenseCurrency, setExpenseCurrency] = useState({
    label: bidCurrency ? bidCurrency.code : order?.expense?.currency?.code,
    value: bidCurrency ? bidCurrency : order?.expense?.currency,
  });

  const submitHandler = () => {
    const dataAssign = {
      orderId: order.id,
      partner_id: partner.id,
      expense: expense || "0",
      currency_id: expenseCurrency?.value?.id,
    };
    dispatch(assignCarrier(dataAssign));
  };

  useEffect(() => {
    if (assignCarrierResponce && updateOrderCostResponce) {
      dispatch(resetAssignCarrier());
      dispatch(resetUpdateOrderCost());
      isClose();
    }
  }, [dispatch, isClose, assignCarrierResponce, updateOrderCostResponce]);

  return (
    <Fragment>
      <RowField>
        <PartnerField label={partner?.company?.name || "-"} />
        <RateField rate={partner?.rating || 0} />
        <TextField
          label={
            (partner.company?.phone_index?.name || "") +
            (partner.company?.phone || "-")
          }
        />
        <TextField max={40} label={partner?.company?.email || "-"} />
        <BidResponseField partner={partner} />
        <CenterField>
          <AssignPartnerButton
            title={t("tms.forms.buttons.Assign partner")}
            onClick={() => setOpenAssign(true)}
          />
        </CenterField>
      </RowField>
      {openAssign && (
        <DModal
          title={t("tms.modals.titles.Assign partner")}
          submitText={t("tms.modals.buttons.Confirm")}
          isOpen={openAssign}
          isClose={() => setOpenAssign(false)}
          onClick={() => submitHandler()}
          loading={assignCarrierLoading || updateOrderCostLoading}
          icon={<MdLink />}
        >
          <div id='AssignPartnerTableModal'>
            <p>
              {t(
                "tms.modals.desc.Before assign partner enter the partners price"
              )}
            </p>
            <div className='curr'>
              <DCurrencyInput
                value={expense}
                setValue={setExpense}
                placeholder={t("tms.modals.placeholder.Enter partner price")}
                label={t("tms.modals.labels.Partner price")}
                currencyValue={expenseCurrency}
                setCurrencyValue={setExpenseCurrency}
                required
                id='FinancialDetailsPartnerPrice'
                error={update_amount_error}
              />
            </div>
          </div>
        </DModal>
      )}
    </Fragment>
  );
};

export default AssignPartnerTableItem;
