import React, { Fragment } from "react";
import "./ShipperTracking.scss";

import TrackingHeader from "./TrackingHeader/TrackingHeader";
import TrackingDriverData from "./TrackingDriverData/TrackingDriverData";
import TrackingDistanceData from "./TrackingDistanceData/TrackingDistanceData";
import TrackingUpdatesData from "./TrackingUpdatesData/TrackingUpdatesData";

const ShipperTracking = ({ order }) => {
  return (
    <Fragment>
      {order && (
        <div id='ShipperTrackingSection'>
          <TrackingHeader order={order} />
          <TrackingDriverData order={order} />
          {order?.distanceCovered && (
            <Fragment>
              <TrackingDistanceData order={order} />
              <TrackingUpdatesData order={order} />
            </Fragment>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default ShipperTracking;
