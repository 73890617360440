import React, { useState, useEffect } from "react";
import "./ShipperNav.scss";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import ShipperNavItem from "./ShipperNavItem/ShipperNavItem";
import ShipperProfile from "../ShipperProfile/ShipperProfile";
import BookShipment from "../BookShipment/BookShipment";
import ShipperNotifications from "../ShipperNotifications/ShipperNotifications";
import ShipperLanguage from "../ShipperLanguage/ShipperLanguage";
import BurgerIcon from "./../BurgerIcon/BurgerIcon";
import ShipperNavMobile from "../ShipperNavMobile/ShipperNavMobile";
import { APP_MODE } from "./../../../../constants/APP_MODE";

const ShipperNav = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [open]);

  useEffect(() => {
    setOpen(false);
  }, [pathname, setOpen]);

  let MENU = [
    {
      title: t("shipper.menu.Overview"),
      to: "/dashboard/shipper/home",
    },
    {
      title: t("shipper.menu.My shipments"),
      to: "/dashboard/shipper/accepted",
    },
    {
      title: t("shipper.menu.Quotations"),
      to: "/dashboard/shipper/orders",
    },
    {
      title: t("shipper.menu.Billing"),
      to: "/dashboard/shipper/billing",
    },
  ];

  // if (APP_MODE.APP_MODE === "PRODUCTION") {
  //   MENU = [
  //     {
  //       title: "Overview",
  //       to: "/dashboard/shipper/home",
  //     },
  //   ];
  // }

  return (
    <nav id='ShipperNav'>
      <ul className='ShipperNavLeft'>
        {MENU.map((item, index) => (
          <ShipperNavItem key={index} title={item.title} to={item.to} />
        ))}
      </ul>
      <div className='ShipperNavRight'>
        {APP_MODE.APP_MODE === "DEVELOPMENT" && <BookShipment />}
        {APP_MODE.APP_MODE === "DEVELOPMENT" && <ShipperNotifications />}
        {APP_MODE.APP_MODE === "DEVELOPMENT" && <ShipperLanguage />}

        <ShipperProfile to={"/dashboard/shipper/profile"} />
        <BurgerIcon value={open} setValue={setOpen} />
      </div>

      <ShipperNavMobile MENU={MENU} open={open} />
    </nav>
  );
};

export default ShipperNav;
