import React, { Fragment, useState, useEffect } from "react";
import "./EditDriversModal.scss";

import { useTranslation } from "react-i18next";
import { FiEdit2 } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";
import {
  updateDriver,
  resetUpdateDriver,
} from "./../../../../store/fleet/drivers/driversSlice";
import { useDriverErrors } from "./../../../../validations/fleet/useDriverErrors";

import DModal from "./../../../Modal/DModal/DModal";
import DInputs from "./../../../Form/DInputs/DInputs";
import DPhoneInput from "./../../../Form/DPhoneInput/DPhoneInput";

const EditDriversModal = ({ isOpen, isClose, driver }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { updateDriverLoading, updateDriverResponce } = useSelector(
    (state) => state.drivers
  );
  const { phoneIndexValueId } = useSelector((state) => state.settings);
  const {
    update_first_name_error,
    update_last_name_error,
    update_phone_error,
    update_phone_index_id_error,
  } = useDriverErrors();

  const [firstName, setFirstName] = useState(
    driver.first_name ? driver.first_name : ""
  );
  const [lastName, setLastName] = useState(
    driver.last_name ? driver.last_name : ""
  );
  const [phoneNumber, setPhoneNumber] = useState(
    driver.phone ? driver.phone : ""
  );

  const submitHandler = () => {
    const data = {
      id: driver.id,
      first_name: firstName,
      last_name: lastName,
      phone: phoneNumber,
      phone_index_id: phoneIndexValueId,
    };
    dispatch(updateDriver(data));
  };

  useEffect(() => {
    if (updateDriverResponce) {
      dispatch(resetUpdateDriver());
      isClose();
    }
  }, [dispatch, isClose, updateDriverResponce]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose(false)}
        title={t("tms.modals.titles.Edit Driver")}
        submitText={t("tms.modals.buttons.Save")}
        icon={<FiEdit2 />}
        status='success'
        onClick={() => submitHandler()}
        loading={updateDriverLoading}
      >
        <div id='EditDriversModal'>
          <div className='EditDriversModalGroup'>
            <DPhoneInput
              id='enterDriverPhoneAddDriver'
              label={t("tms.modals.labels.Phone number")}
              placeholder={t("tms.modals.placeholder.Enter phone number")}
              value={phoneNumber}
              required
              setValue={setPhoneNumber}
              error={update_phone_error || update_phone_index_id_error}
            />
            <DInputs
              id='enterDriverFisrNameAddDriver'
              label={t("tms.modals.labels.Driver first name")}
              placeholder={t("tms.modals.placeholder.Enter first name")}
              value={firstName}
              setValue={setFirstName}
              required
              error={update_first_name_error}
            />

            <DInputs
              id='enterDriverLastNameAddDriver'
              label={t("tms.modals.labels.Driver last name")}
              placeholder={t("tms.modals.placeholder.Enter last name")}
              value={lastName}
              setValue={setLastName}
              required
              error={update_last_name_error}
            />
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default EditDriversModal;
