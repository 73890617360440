import React from "react";
import "./RoadTypeSwitcher.scss";

const RoadTypeSwitcher = ({ ORDERTYPES, order_type_id, set_order_type_id }) => {
  return (
    <div id='RoadTypeSwitcher'>
      {ORDERTYPES.map((item) => (
        <div
          key={item.id}
          className={`typeSwitcherItem${item.id === 1 ? "Left" : "Right"} ${
            order_type_id === item.id ? "active" : ""
          }`}
          onClick={() => set_order_type_id(item.id)}
        >
          {item.name}
        </div>
      ))}
    </div>
  );
};

export default RoadTypeSwitcher;
