import React, { Fragment, useState, useEffect } from "react";
import "./OrderList.scss";

import { useTranslation } from "react-i18next";

import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import {
  getOrders,
  resetGetOrders,
} from "./../../../../../store/orders/ordersSlice";

import Wrapper from "../../../../../componentsAdditional/Wrapper";
import EmptyList from "../../../../../componentsDashboard/UI/EmptyList/EmptyList";
import OrdersTable from "../../../../../componentsDashboard/OrderList/Orders/OrdersTable";
import LoadingPage from "./../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import OrdersListHeader from "../../../../../componentsDashboard/OrderList/Orders/OrdersListHeader/OrdersListHeader";

const OrderList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1
  );
  const [keyword, setKeyword] = useState("");
  const [status, setStatus] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [trailerType, setTrailerType] = useState(null);
  const [price, setPrice] = useState({});
  const [date, setDate] = useState({});
  const [route, setRoute] = useState({});
  const [sort, setSort] = useState(null);
  const [direction, setDirection] = useState(null);

  const { getOrderscountResponce, getOrderscountLoading } = useSelector(
    (state) => state.summary
  );

  const {
    ordersLoading,
    orders,
    ordersMeta,
    createResponce,
    updateResponce,
    deleteResponce,
  } = useSelector((state) => state.orders);

  const handleClearFilters = () => {
    if (keyword) setKeyword("");
    if (status) setStatus(null);
    if (customer) setCustomer(null);
    if (trailerType) setTrailerType(null);
    if (Object.keys(route).length > 0) setRoute({});
    if (Object.keys(price).length > 0) setPrice({});
    if (Object.keys(date).length > 0) setDate({});
  };

  useEffect(() => {
    dispatch(
      getOrders({
        page,
        keyword,
        status_id: status?.value,
        customer_id: customer?.value?.id,
        trailer_type_id: trailerType?.value?.id,
        start_date:
          Object.keys(date).length > 0 && date?.startDate
            ? moment(date.startDate).format("DD/MM/YYYY")
            : "",
        end_date:
          Object.keys(date).length > 0 && date?.endDate
            ? moment(date.endDate).format("DD/MM/YYYY")
            : "",
        min_price: price?.minPrice,
        max_price: price?.maxPrice,
        revenue_currency_id: price?.revenueCurrencyId,
        pickup_address: route?.fromCountry?.label,
        dropoff_address: route?.toCountry?.label,
        sort_by: sort?.data?.sortBy,
        direction: direction,
      })
    );
    return () => {
      dispatch(resetGetOrders());
    };
  }, [
    dispatch,
    page,
    keyword,
    createResponce,
    updateResponce,
    deleteResponce,
    status,
    customer,
    trailerType,
    price,
    date,
    sort,
    direction,
    route,
  ]);

  return (
    <Wrapper documentTitle={t("tms.documentTitles.Orders")}>
      <section id='TMCOrderList'>
        {getOrderscountLoading && <LoadingPage />}
        {getOrderscountResponce && !getOrderscountLoading && (
          <Fragment>
            {getOrderscountResponce.orders_count === 0 ? (
              <EmptyList
                image={"/assets/images/dashboard/cover1.svg"}
                title={t("tms.titles.No Bookings")}
                description={t(
                  "tms.desc.Add booking and take full control of your orders"
                )}
                onClick={() => {
                  navigate("/dashboard/tms/orders/new");
                }}
                buttonText={t("tms.forms.buttons.Add Booking")}
              />
            ) : (
              <Fragment>
                <OrdersListHeader
                  total={ordersMeta?.total || 0}
                  keyword={keyword}
                  setKeyword={setKeyword}
                  status={status}
                  setStatus={setStatus}
                  customer={customer}
                  setCustomer={setCustomer}
                  trailerType={trailerType}
                  setTrailerType={setTrailerType}
                  price={price}
                  setPrice={setPrice}
                  date={date}
                  setDate={setDate}
                  route={route}
                  setRoute={setRoute}
                  sort={sort}
                  setSort={setSort}
                  setDirection={setDirection}
                  onClear={handleClearFilters}
                />
                {ordersLoading && <LoadingPage />}
                {orders && (
                  <OrdersTable
                    orders={orders}
                    page={page}
                    setPage={setPage}
                    pageCount={ordersMeta.last_page}
                    total={ordersMeta.total}
                  />
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      </section>
    </Wrapper>
  );
};

export default OrderList;
