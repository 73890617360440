import React, { useState } from "react";
import "./SelectableButton.scss";

import { FiX } from "react-icons/fi";

const SelectableButton = ({
  selectedTypes,
  setSelectedTypes,
  setTypeList,
  item,
  removable,
  selected,
}) => {
  const [isSelected, setIsSelected] = useState(
    removable || selected ? true : false
  );
  const handleClick = () => {
    if (!isSelected) {
      setIsSelected(true);
      setSelectedTypes([...selectedTypes, item?.id]);
    } else {
      setIsSelected(false);
      setSelectedTypes(selectedTypes.filter((i) => i !== item?.id));
      removable &&
        setTypeList((prev) => prev.filter((i) => i?.id !== item?.id));
    }
  };

  return (
    <div
      id='SelectableButton'
      style={{
        backgroundColor: isSelected ? "#00814D" : "#EAEBEB",
      }}
      onClick={handleClick}
    >
      <div
        className='text'
        style={{ color: isSelected ? "#FFFFFF" : "#4F575B" }}
      >
        {item?.name}
      </div>
      {isSelected && (
        <div className='icon'>
          <FiX />
        </div>
      )}
    </div>
  );
};

export default SelectableButton;
