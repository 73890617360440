import React, { useState, useEffect, Fragment } from "react";
import "./EditRoadOrder.scss";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FiCheck } from "react-icons/fi";
import moment from "moment";

import { getTrailerTypes } from "../../../../../../store/orders/orderSettings/orderSettingsSlice";
import {
  updateRoad,
  resetUpdateRoad,
  getOrder,
  resetGetOrder,
} from "./../../../../../../store/orders/shipperOrders/shipperOrdersSlice";
import { useEditShipperRoadError } from "./../../../../../../validations/order/useEditShipperRoadError";

import Wrapper from "./../../../../../../componentsAdditional/Wrapper";
import CreateOrderHeader from "./../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderHeader/CreateOrderHeader";
import RoadTypeSwitcher from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperRoad/RoadTypeSwitcher/RoadTypeSwitcher";
import RoadTopMenu from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperRoad/RoadTopMenu/RoadTopMenu";
import CreateOrderAditionalServices from "./../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderAditionalServices/CreateOrderAditionalServices";
import CreateOrderAditionalInformation from "./../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderAditionalInformation/CreateOrderAditionalInformation";
import RoadLocation from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperRoad/RoadLocation/RoadLocation";
import RoadPackage from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperRoad/RoadPackage/RoadPackage";
import CreateOrderSubmit from "../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderSubmit/CreateOrderSubmit";
import LoadingPage from "../../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import PageNotFound from "../../../../../../componentsBids/PageNotFound/PageNotFound";

const EditRoadOrder = () => {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const {
    orderLoading,
    orderResponce,
    orderError,
    updateRoadLoading,
    updateRoadResponce,
    updateRoadError,
  } = useSelector((state) => state.shipperOrders);
  const { cargoTypesSelectResponce, trailerTypes, temperaturesSelect } =
    useSelector((state) => state.orderSettings);

  const {
    trailer_type_id_error,
    trailer_size_id_error,
    temperature_range_id_error,
    order_documents_error,
    cargos_type_id_error,
    hs_code_error,
    cargos_weight_error,
    cargos_adr_error,
    cargos_adr_class_error,
    cargos_un_number_error,
    cargos_pickup_date_error,
    cargos_price_error,
    cargos_price_currency_id_error,
    cargos_oversized_error,
    // cargos_unknown_container_error,
  } = useEditShipperRoadError();

  const ORDERTYPES = [
    { id: 1, name: "FTL" },
    { id: 2, name: "LTL" },
  ];

  const [order_type_id, set_order_type_id] = useState(1);
  const [locations, setLocations] = useState([
    {
      direction: "A",
      inputted_address: "",
      error: null,
    },
    {
      direction: "B",
      inputted_address: "",
      error: null,
    },
  ]);
  const [pickUpDate, setPickUpDate] = useState(null);
  const [showPickUpTime, setShowPickUpTime] = useState(false);
  const [pickUpTime, setPickUpTime] = useState(null);
  const [cargoType, setCargoType] = useState(null);
  const [totalWeight, setTotalWeight] = useState("");
  const [HSCode, setHSCode] = useState(false);
  const [HSCodeValue, setHSCodeValue] = useState("");
  const [adr, setAdr] = useState(false);
  const [adrNumber, setAdrNumber] = useState("");
  const [adrClass, setAdrClass] = useState("");
  const [oversized, setOversized] = useState(false);
  // const [unknownContainer, setUnknownContainer] = useState(false);
  const [group, setGroup] = useState([
    {
      trailerType: null,
      trailerSize: null,
      temperatureRange: null,
      quantity: 1,
    },
  ]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [cargoPrice, setCargoPrice] = useState("");
  const [cargoPriceCurrency, setCargoPriceCurrency] = useState(
    user
      ? {
          value: user.currency,
          label: user.currency.code,
        }
      : null
  );
  const [comment, setComment] = useState("");
  const [orders, setOrders] = useState(null);

  // get detail order
  useEffect(() => {
    dispatch(getOrder(orderId));
    return () => {
      dispatch(resetGetOrder());
    };
  }, [dispatch, orderId]);

  // edit
  useEffect(() => {
    if (orderResponce && user && cargoTypesSelectResponce) {
      set_order_type_id(orderResponce?.type?.id || 1);
      setPickUpDate(
        orderResponce.cargos[0].pickup_date
          ? moment(orderResponce.cargos[0].pickup_date, "DD/MM/YYYY").toDate()
          : null
      );
      if (orderResponce?.cargos[0]?.pickup_time_range) {
        setShowPickUpTime(true);
        setPickUpTime([
          orderResponce?.cargos[0]?.pickup_time_range?.start,
          orderResponce?.cargos[0]?.pickup_time_range?.end,
        ]);
      }
      setSelectedServices(orderResponce?.services || []);
      setCargoType(
        cargoTypesSelectResponce?.find(
          (i) => i.value.id === orderResponce.cargos[0].type.id
        )
      );
      if (orderResponce?.cargos[0]?.hs_code) {
        setHSCode(true);
        setHSCodeValue(orderResponce?.cargos[0]?.hs_code || "");
      }
      if (orderResponce?.cargos[0]?.adr) {
        setAdr(true);
        setAdrNumber(orderResponce?.cargos[0]?.un_number || "");
        setAdrClass(orderResponce?.cargos[0]?.adr_class || "");
      }
      if (orderResponce?.cargos[0]?.oversized) {
        setOversized(true);
        // setUnknownContainer(orderResponce?.cargos[0]?.unknownContainer || false);
      }

      setComment(orderResponce?.cargos[0]?.about || "");
      setCargoPrice(orderResponce?.cargos[0]?.price || "");
      setCargoPriceCurrency({
        label:
          orderResponce?.cargos[0]?.price_currency?.code || user.currency.code,
        value: orderResponce?.cargos[0]?.price_currency || user.currency,
      });
      setLocations([
        ...orderResponce.cargos[0].locations
          .filter((i) => i.direction === "A")
          .map((i) => ({
            id: i.id,
            direction: "A",
            inputted_address: i.formatted_address,
            error: null,
          })),
        ...orderResponce.cargos[0].locations
          .filter((i) => i.direction === "B")
          .map((i) => ({
            id: i.id,
            direction: "B",
            inputted_address: i.formatted_address,
            error: null,
          })),
      ]);
      setTotalWeight(orderResponce?.weight || "");

      const newData = [
        {
          trailerType: orderResponce?.trailer_type
            ? {
                label: orderResponce?.trailer_type?.name || "",
                value: {
                  label: orderResponce?.trailer_type?.name || "",
                  id: orderResponce?.trailer_type?.id || "",
                  temperature:
                    orderResponce?.trailer_type?.temperature || false,
                },
              }
            : null,
          trailerSize: orderResponce?.trailer_size
            ? {
                label: orderResponce?.trailer_size?.label || "",
                value: {
                  value: {
                    label: orderResponce?.trailer_size?.label || "",
                    id: orderResponce?.trailer_size?.id || "",
                  },
                },
              }
            : null,
          temperatureRange: orderResponce?.temperature
            ? {
                label: orderResponce?.temperature?.title || "",
                value: {
                  label: orderResponce?.temperature?.title || "",
                  id: orderResponce?.temperature?.id || "",
                },
              }
            : null,
          quantity: 1,
        },
      ];
      setGroup(newData);
    }
  }, [
    orderResponce,
    user,
    cargoTypesSelectResponce,
    temperaturesSelect,
    trailerTypes,
  ]);

  // catch locations error
  useEffect(() => {
    if (updateRoadError && updateRoadError.errors) {
      const updatedLocations = locations.map((location, index) => {
        const errorKey = `cargos.0.locations.${index}.inputted_address`;
        const errorValue =
          updateRoadError.errors[errorKey] &&
          updateRoadError.errors[errorKey][0]
            ? updateRoadError.errors[errorKey][0]
            : null;

        return {
          ...location,
          error: errorValue,
        };
      });
      setLocations(updatedLocations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateRoadError]);

  useEffect(() => {
    const convertedGroup = group.flatMap((item) =>
      Array.from({ length: item.quantity }, () => ({
        trailerType: item.trailerType,
        trailerSize: item.trailerSize,
        temperatureRange: item.temperatureRange,
        weight: 0,
      }))
    );
    const convertedWeight = convertedGroup.map((order) => ({
      ...order,
      weight:
        totalWeight && totalWeight.length > 0
          ? parseFloat((+totalWeight / convertedGroup.length).toFixed(0))
          : 0,
    }));
    setOrders(convertedWeight);
  }, [group, totalWeight]);

  // get trailer types
  useEffect(() => {
    dispatch(getTrailerTypes(1));
  }, [dispatch]);

  // reset
  useEffect(() => {
    if (updateRoadResponce) {
      dispatch(resetUpdateRoad());
      navigate(-1);
    }
  }, [dispatch, navigate, updateRoadResponce]);

  // submit
  const submitHandler = () => {
    const filsterSelectedServices =
      selectedServices.length > 0 ? selectedServices.map((i) => i.id) : null;

    const filsterLocation = locations.map((location) => ({
      ...location,
      type: "LOCALITY",
    }));

    const data = {
      orderId: orderId,
      transfer_type_id: 1,
      type_id: order_type_id || 1,
      trailer_type_id: group[0]?.trailerType?.value?.id || null,
      trailer_size_id: group[0]?.trailerSize?.value?.value?.id || null,
      temperature_range_id: group[0]?.temperatureRange?.value?.id || null,
      comment: "",
      services: filsterSelectedServices,
      cargos: [
        {
          id: orderResponce && orderResponce.cargos[0].id,
          type_id: cargoType?.value?.id || null,
          hs_code: HSCodeValue || null,
          weight: totalWeight.toString().length > 0 ? totalWeight : null,
          volume: null,
          adr: adr,
          adr_class: adrClass.toString().length > 0 ? adrClass : null,
          oversized: oversized,
          // unknownContainer: unknownContainer,
          un_number: adrNumber.toString().length > 0 ? adrNumber : null,
          about: comment.length.toString() > 0 ? comment : null,
          pickup_date: pickUpDate
            ? moment(pickUpDate).format("YYYY-MM-DD")
            : null,
          pickup_time_range: pickUpTime
            ? {
                start: pickUpTime[0],
                end: pickUpTime[1],
              }
            : null,
          price: cargoPrice || null,
          price_currency_id: cargoPriceCurrency?.value?.id || "",
          locations: filsterLocation,
        },
      ],
    };
    dispatch(updateRoad(data));
  };

  return (
    <Wrapper documentTitle={t("shipper.titles.Road freight")}>
      {orderLoading && <LoadingPage />}
      {orderError && <PageNotFound />}
      {orderResponce && !orderLoading && (
        <Fragment>
          <RoadTopMenu
            ORDERTYPES={ORDERTYPES}
            order_type_id={order_type_id}
            pickUpDate={pickUpDate}
            totalWeight={totalWeight}
            cargoType={cargoType}
            orders={orders}
            locations={locations}
          />
          <section id='ShipperEditOrderRoad'>
            <div className='ShipperCreateOrderRoadWrapper'>
              <CreateOrderHeader />
              <RoadTypeSwitcher
                ORDERTYPES={ORDERTYPES}
                order_type_id={order_type_id}
                set_order_type_id={set_order_type_id}
              />
              <RoadLocation
                edit
                locations={locations}
                setLocations={setLocations}
                pickUpDate={pickUpDate}
                setPickUpDate={setPickUpDate}
                showPickUpTime={showPickUpTime}
                setShowPickUpTime={setShowPickUpTime}
                pickUpTime={pickUpTime}
                setPickUpTime={setPickUpTime}
                cargos_pickup_date_error={cargos_pickup_date_error}
              />
              <RoadPackage
                edit
                ///////////////////
                group={group}
                setGroup={setGroup}
                orders={orders}
                setOrders={setOrders}
                ////////////////////
                cargoType={cargoType}
                setCargoType={setCargoType}
                totalWeight={totalWeight}
                setTotalWeight={setTotalWeight}
                HSCode={HSCode}
                setHSCode={setHSCode}
                HSCodeValue={HSCodeValue}
                setHSCodeValue={setHSCodeValue}
                adr={adr}
                setAdr={setAdr}
                adrNumber={adrNumber}
                setAdrNumber={setAdrNumber}
                adrClass={adrClass}
                setAdrClass={setAdrClass}
                oversized={oversized}
                setOversized={setOversized}
                // unknownContainer={unknownContainer}
                // setUnknownContainer={setUnknownContainer}
                // errors
                trailer_type_id_error={trailer_type_id_error}
                trailer_size_id_error={trailer_size_id_error}
                temperature_range_id_error={temperature_range_id_error}
                hs_code_error={hs_code_error}
                cargos_type_id_error={cargos_type_id_error}
                cargos_weight_error={cargos_weight_error}
                cargos_adr_error={cargos_adr_error}
                cargos_adr_class_error={cargos_adr_class_error}
                cargos_un_number_error={cargos_un_number_error}
                cargos_oversized_error={cargos_oversized_error}
                // cargos_unknown_container_error={cargos_unknown_container_error}
              />
              <CreateOrderAditionalServices
                title={t("shipper.forms.titles.Additional Services")}
                selectedServices={selectedServices}
                setSelectedServices={setSelectedServices}
              />
              <CreateOrderAditionalInformation
                title={t("shipper.forms.titles.Additional information")}
                cargoPrice={cargoPrice}
                setCargoPrice={setCargoPrice}
                cargoPriceCurrency={cargoPriceCurrency}
                setCargoPriceCurrency={setCargoPriceCurrency}
                comment={comment}
                setComment={setComment}
                // errors
                order_documents_error={order_documents_error}
                cargos_price_error={cargos_price_error}
                cargos_price_currency_id_error={cargos_price_currency_id_error}
              />

              <CreateOrderSubmit
                icon={<FiCheck />}
                title={t("shipper.buttons.Save")}
                loading={updateRoadLoading}
                onClick={() => submitHandler()}
              />
            </div>
          </section>
        </Fragment>
      )}
    </Wrapper>
  );
};

export default EditRoadOrder;
