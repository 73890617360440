import React, { Fragment } from "react";

import { useTranslation } from "react-i18next";

import { MdWarning } from "react-icons/md";

import DModal from "../../Modal/DModal/DModal";

const NetworkErrorModal = ({ isOpen, isClose }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      {isOpen && (
        <DModal
          icon={<MdWarning />}
          status='danger'
          submitText={t("shipper.modal.Close")}
          title={t("shipper.modal.Network Error")}
          isOpen={isOpen}
          isClose={isClose}
          noSubmit
        >
          <p>{t("shipper.modal.Please reduce the size of all files")}</p>
        </DModal>
      )}
    </Fragment>
  );
};

export default NetworkErrorModal;
