import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./authService";

// 1, 'SUPER_ADMIN'
// 2, 'INDIVIDUAL_DRIVER'
// 3, 'LOGISTRICS_COMPANY_DRIVER'
// 4, 'LOGISTRICS_COMPANY'
// 5, 'SENDER'

const access_token = JSON.parse(localStorage.getItem("access_token"));

const initialState = {
  access_token: access_token ? access_token : null,

  loading: false,
  data: null,
  error: null,

  userLoading: true,
  user: null,
  verify: false,
  userType: null,
  userError: null,

  removeTokenResponse: false,

  codeCheckResponce: null,
  codeCheckError: null,
  codeCheckLoading: false,

  registerStep1Responce: null,
  registerStep1Error: null,
  registerStep1Loading: false,

  registerStep2Responce: null,
  registerStep2Error: null,
  registerStep2Loading: false,
};

export const getUser = createAsyncThunk("auth/getUser", async (_, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await authService.getUser(ISO, TOKEN);
  } catch (error) {
    const message = {
      message: "TOKEN IS NOT CORRECT",
      status: error?.response?.status,
    };
    return thunkAPI.rejectWithValue(message);
  }
});

export const register = createAsyncThunk(
  "auth/register",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const result = await authService.register(ISO, data);
      if (result && result.access_token) {
        localStorage.setItem(
          "access_token",
          JSON.stringify(result.access_token)
        );
      }
      return result;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const registerStep1 = createAsyncThunk(
  "auth/registerStep1",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const result = await authService.register(ISO, data);
      if (result && result.access_token) {
        localStorage.setItem(
          "access_token",
          JSON.stringify(result.access_token)
        );
      }
      return result;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const registerStep2 = createAsyncThunk(
  "auth/registerStep2",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const result = await authService.register(ISO, data);
      if (result && result.access_token) {
        localStorage.setItem(
          "access_token",
          JSON.stringify(result.access_token)
        );
      }
      return result;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const login = createAsyncThunk("auth/login", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const result = await authService.login(ISO, data);
    if (result && result.access_token) {
      localStorage.setItem("access_token", JSON.stringify(result.access_token));
    }
    return result;
  } catch (error) {
    const message =
      (error.response && error.response.data) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const verifyUser = createAsyncThunk(
  "auth/verifyUser",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await authService.verifyUser(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const resendVerifyUser = createAsyncThunk(
  "auth/resendVerifyUser",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await authService.resendVerifyUser(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      return await authService.forgotPassword(ISO, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const codeCheck = createAsyncThunk(
  "auth/codeCheck",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      return await authService.codeCheck(ISO, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const result = await authService.resetPassword(ISO, data);
      if (result && result.access_token) {
        localStorage.setItem(
          "access_token",
          JSON.stringify(result.access_token)
        );
      }
      return result;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    const result = await authService.logout(ISO, TOKEN);
    if (result) {
      localStorage.removeItem("access_token");
    }
    return result;
  } catch (error) {
    const message =
      (error.response && error.response.data) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const removeToken = createAsyncThunk(
  "auth/removeToken",
  async (_, thunkAPI) => {
    localStorage.removeItem("access_token");
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.data = null;
      state.error = false;
    },
    resetUser: (state) => {
      state.userLoading = true;
      state.user = null;
      state.verify = false;
      state.userType = null;
      state.userError = null;
    },
    resetCodeCheck: (state) => {
      state.codeCheckLoading = false;
      state.codeCheckResponce = null;
      state.codeCheckError = null;
    },

    resetRemoveToken: (state) => {
      state.removeTokenResponse = false;
    },

    resetRegisterStep1: (state) => {
      state.registerStep1Responce = null;
      state.registerStep1Error = null;
      state.registerStep1Loading = false;
    },

    resetRegisterStep2: (state) => {
      state.registerStep2Responce = null;
      state.registerStep2Error = null;
      state.registerStep2Loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout.pending, (state) => {
        state.loading = true;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.user = null;
        state.verify = null;
        state.access_token = null;
        state.error = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload;
      })
      // get user
      .addCase(getUser.pending, (state) => {
        state.userLoading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.userLoading = false;
        state.user = action.payload;
        state.verify =
          action.payload.type.id === 5 || action.payload.type.id === 4
            ? action.payload.email_verified
            : action.payload.phone_verified;
        state.userType = action.payload.type.id;
        state.userError = null;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.userLoading = false;
        state.user = null;
        state.verify = false;
        state.userType = null;
        state.userError = action.payload;
      })
      // register
      .addCase(register.pending, (state) => {
        state.loading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.access_token = action.payload.access_token;
        state.error = null;
      })
      .addCase(register.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload;
      })
      // register step 1
      .addCase(registerStep1.pending, (state) => {
        state.registerStep1Loading = true;
        state.registerStep1Responce = null;
        state.registerStep1Error = null;
      })
      .addCase(registerStep1.fulfilled, (state, action) => {
        state.registerStep1Loading = false;
        state.registerStep1Responce = action.payload;
        state.registerStep1Error = null;
      })
      .addCase(registerStep1.rejected, (state, action) => {
        state.registerStep1Loading = false;
        state.registerStep1Responce = null;
        state.registerStep1Error = action.payload;
      })
      // register step 2
      .addCase(registerStep2.pending, (state) => {
        state.registerStep2Loading = true;
        state.registerStep2Responce = null;
        state.registerStep2Error = null;
      })
      .addCase(registerStep2.fulfilled, (state, action) => {
        state.registerStep2Loading = false;
        state.registerStep2Responce = action.payload;
        state.access_token = action.payload.access_token;
        state.registerStep2Error = null;
      })
      .addCase(registerStep2.rejected, (state, action) => {
        state.registerStep2Loading = false;
        state.registerStep2Responce = null;
        state.registerStep2Error = action.payload;
      })
      // login
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.access_token = action.payload.access_token;
        state.error = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload;
      })
      // verifyUser
      .addCase(verifyUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyUser.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(verifyUser.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload;
      })

      // resendVerifyUser
      .addCase(resendVerifyUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(resendVerifyUser.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(resendVerifyUser.rejected, (state, action) => {
        state.loading = false;
      })

      // forgotPassword
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload;
      })

      // reset password
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.access_token = action.payload.access_token;
        state.error = null;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload;
      })

      .addCase(removeToken.fulfilled, (state) => {
        state.removeTokenResponse = true;

        state.access_token = null;
        state.loading = false;
        state.data = null;
        state.error = null;
        state.userLoading = false;
        state.user = null;
        state.verify = false;
        state.userType = null;
        state.userError = null;
      })
      .addCase(codeCheck.pending, (state) => {
        state.codeCheckLoading = true;
        state.codeCheckResponce = null;
        state.codeCheckError = null;
      })
      .addCase(codeCheck.fulfilled, (state, action) => {
        state.codeCheckLoading = false;
        state.codeCheckResponce = action.payload;
        state.codeCheckError = null;
      })
      .addCase(codeCheck.rejected, (state, action) => {
        state.codeCheckLoading = false;
        state.codeCheckResponce = null;
        state.codeCheckError = action.payload;
      });
  },
});

export const {
  reset,
  resetUser,
  resetCodeCheck,
  resetRemoveToken,
  resetRegisterStep1,
  resetRegisterStep2,
} = authSlice.actions;
export default authSlice.reducer;
