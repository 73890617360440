import React, { Fragment, useState } from "react";
import "./DocumentsDetailsItem.scss";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { FiMoreVertical, FiTrash2 } from "react-icons/fi";
import { IoDocumentTextOutline } from "react-icons/io5";

import { Menu, MenuItem } from "@mui/material";
import { Tooltip } from "@mui/material";

import DeleteDocumentModal from "../DeleteDocumentModal/DeleteDocumentModal";
import Character from "../../../../UI/Character/Character";

const DocumentsDetailsItem = ({ document, orderId }) => {
  const { t } = useTranslation();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Fragment>
      {document && (
        <li id='DocumentsDetailsItem'>
          <Tooltip
            placement='top'
            title={
              <div className='DocumentsDetailsItemTooltipTitle'>
                <Character title={document?.fileName} max={50} />
              </div>
            }
            classes={{ tooltip: "DocumentsDetailsItemTooltip" }}
          >
            <div className='item'>
              <Link to={document.url} target='_blank'>
                <div className='icon'>
                  <IoDocumentTextOutline />
                </div>

                <h1>
                  <Character
                    title={document.type && document.type.name}
                    max={8}
                  />
                  <p>{document.created_at && document.created_at}</p>
                </h1>
              </Link>
              <div className='MoreIcon' onClick={handleClick}>
                <FiMoreVertical />
              </div>
              <Menu
                id='ContactTableMore'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    setOpenDeleteModal(true);
                    handleClose();
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      color: "red",
                    }}
                  >
                    <FiTrash2 />
                    {t("tms.forms.buttons.delete")}
                  </div>
                </MenuItem>
              </Menu>
            </div>
          </Tooltip>
        </li>
      )}
      {openDeleteModal && (
        <DeleteDocumentModal
          documentId={document.id}
          orderId={orderId}
          isOpen={openDeleteModal}
          isClose={() => setOpenDeleteModal(false)}
        />
      )}
    </Fragment>
  );
};

export default DocumentsDetailsItem;
