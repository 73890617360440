import React from "react";
import "./TrailerNeedDetails.scss";

import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

import Character from "../../componentsDashboard/UI/Character/Character";

const TrailerNeedDetailsItem = ({ title, text }) => {
  const parsedText = parse(text.toString());
  return (
    <div className='item'>
      <div className='itemTitle'>{title}</div>
      <div className='itemText'>
        <Character title={parsedText} max={50} />
      </div>
    </div>
  );
};
const TrailerNeedDetails = ({ order }) => {
  const { t } = useTranslation();
  return (
    <div id='TrailerNeedDetails'>
      <div className='titleContainer'>
        <div
          className='icon'
          style={{ backgroundImage: `url(${order?.trailer_type?.icon})` }}
        ></div>
        <div className='title'>{t("partnerBid.Trailer need")}</div>
      </div>
      <div className='items'>
        <TrailerNeedDetailsItem
          title={`${t("partnerBid.Type")}:`}
          text={order?.trailer_type?.name || ""}
        />
        <TrailerNeedDetailsItem
          title={`${t("partnerBid.Volume")}:`}
          text={order?.trailer_size?.label || ""}
        />
        {order?.temperature && (
          <TrailerNeedDetailsItem
            title={`${t("partnerBid.Temperature")}:`}
            text={order?.temperature?.title}
          />
        )}
      </div>
      {order?.cargos[0]?.about && (
        <div className='additionalInfoitem'>
          <div className='additionalInfoitemTitle'>
            {t("partnerBid.Additional information")}:
          </div>
          <div className='additionalInfoitemText'>
            <div>{order?.cargos[0]?.about}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TrailerNeedDetails;
