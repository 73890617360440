import React, { Fragment, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  getShipperActiveOrdersSummary,
  getShipperAcceptedOrdersSummary,
  getShipperRejectOrdersSummary,
  getOrdersSummary,
} from "../store/summary/summarySlice";

import { getCargoTypes } from "../store/orders/orderSettings/orderSettingsSlice";

import ShipperHeader from "../componentsDashboard/ShipperHeader/ShipperHeader";
import Shipper from "../routes/Shipper";

const AppShipper = () => {
  const dispatch = useDispatch();

  const {
    deleteResponce,
    createOceanResponce,
    createAirResponce,
    createRoadResponce,
    takeOrderResponce,
    rejectOrderResponce,
  } = useSelector((state) => state.shipperOrders);

  useEffect(() => {
    dispatch(getShipperActiveOrdersSummary());
    dispatch(getShipperAcceptedOrdersSummary());
    dispatch(getShipperRejectOrdersSummary());
    dispatch(getCargoTypes());
    dispatch(getOrdersSummary());
  }, [
    dispatch,
    deleteResponce,
    createOceanResponce,
    createAirResponce,
    createRoadResponce,
    takeOrderResponce,
    rejectOrderResponce,
  ]);

  return (
    <Fragment>
      <ShipperHeader />
      <main>
        <Shipper />
      </main>
    </Fragment>
  );
};

export default AppShipper;
