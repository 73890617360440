import React, { useEffect, useState } from "react";
import "./AddBankRequisitesModal.scss";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IoMdWallet } from "react-icons/io";

import {
  createBankAccount,
  resetCreateBankAccount,
} from "../../../../../store/user/bankAccounts/bankAccountsSlice";

import { useBankAccountsErrors } from "./../../../../../validations/bankAccounts/useBankAccountsErrors";

import DModal from "../../../../Modal/DModal/DModal";
import DInputs from "../../../../Form/DInputs/DInputs";

const AddBankRequisitesModal = ({ isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { create_name_error, create_code_error, create_account_error } =
    useBankAccountsErrors();
  const { createBankAccountLoading, createBankAccountResponce } = useSelector(
    (state) => state.bankAccounts
  );

  const [bankName, setBankName] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [bankAccount, setBankAccount] = useState("");

  const submitHandler = () => {
    const data = {
      name: bankName,
      code: bankCode,
      account: bankAccount,
    };
    dispatch(createBankAccount(data));
  };

  useEffect(() => {
    if (createBankAccountResponce) {
      dispatch(resetCreateBankAccount());
      isClose();
    }
  }, [dispatch, isClose, createBankAccountResponce]);

  return (
    <DModal
      isOpen={isOpen}
      isClose={isClose}
      title={t("tms.modals.titles.Add bank requisites")}
      icon={<IoMdWallet />}
      submitText={t("tms.modals.buttons.Save")}
      loading={createBankAccountLoading}
      onClick={submitHandler}
      status='info'
    >
      <div id='AddBankRequisitesModal'>
        <div className='fromGorup'>
          <DInputs
            id='Enterbankname'
            label={t("tms.modals.labels.Bank name")}
            placeholder={t("tms.modals.placeholder.Enter bank name")}
            value={bankName}
            setValue={setBankName}
            error={create_name_error}
            required
          />
          <DInputs
            id='Enterbankcode'
            label={t("tms.modals.labels.Bank code")}
            placeholder={t("tms.modals.placeholder.Enter bank code")}
            value={bankCode}
            setValue={setBankCode}
            error={create_code_error}
            required
          />
          <DInputs
            id='Enterbankaccount'
            label={t("tms.modals.labels.Bank account")}
            placeholder={t("tms.modals.placeholder.Enter bank account")}
            value={bankAccount}
            setValue={setBankAccount}
            error={create_account_error}
            required
          />
        </div>
      </div>
    </DModal>
  );
};

export default AddBankRequisitesModal;
