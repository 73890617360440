import React, { Fragment, useEffect, useState } from "react";
import "./TrailerMaintenance.scss";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FiPlus } from "react-icons/fi";

import { getTrailerMaintenances } from "../../../../../store/fleet/maintenance/maintenanceSlice";

import DButton from "../../../../Form/DButtons/DButton/DButton";
import TrailerMaintenanceTable from "./TrailerMaintenanceTable/TrailerMaintenanceTable";
import LoadingPage from "../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import AddTrailerExpensesModal from "./AddTrailerExpensesModal/AddTrailerExpensesModal";
import DSearch from "../../../../FilterForms/DSearch/DSearch";

const TrailerMaintenance = ({ trailer }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    getTrailerMaintenancesResponce,
    getTrailerMaintenancesLoading,
    createMaintenanceResponce,
    updateMaintenanceResponce,
    deleteMaintenanceResponce,
  } = useSelector((state) => state.maintenance);

  const [openAddExpenseModal, setOpenAddExpenseModal] = useState(false);
  const [keyword, setKeyword] = useState("");

  const trailerId = trailer.id;

  useEffect(() => {
    dispatch(
      getTrailerMaintenances({
        trailer_id: trailerId,
        keyword: keyword,
      })
    );
  }, [
    dispatch,
    trailerId,
    createMaintenanceResponce,
    updateMaintenanceResponce,
    deleteMaintenanceResponce,
    keyword,
  ]);

  return (
    <Fragment>
      <div id='TrailerMaintenance'>
        <h1>{t("tms.titles.Maintenance")}</h1>
        <div className='TrailerMaintenanceHeader'>
          <DSearch value={keyword} setValue={setKeyword} />

          <DButton
            title={t("tms.forms.buttons.Add expense")}
            rightIcon={<FiPlus />}
            onClick={() => {
              setOpenAddExpenseModal(true);
            }}
          />
        </div>
        {getTrailerMaintenancesLoading && <LoadingPage />}
        {getTrailerMaintenancesResponce && (
          <TrailerMaintenanceTable
            maintenances={getTrailerMaintenancesResponce || []}
          />
        )}
      </div>
      <AddTrailerExpensesModal
        trailer={trailer}
        isOpen={openAddExpenseModal}
        isClose={setOpenAddExpenseModal}
      />
    </Fragment>
  );
};

export default TrailerMaintenance;
