import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import documentsService from "./documentsService";

const initialState = {
  getOrderDocumentsLoading: true,
  getOrderDocumentsResponce: null,
  getOrderDocumentsError: null,

  createOrderDocumentLoading: false,
  createOrderDocumentResponce: null,
  createOrderDocumentError: null,

  deleteOrderDocumentLoading: false,
  deleteOrderDocumentResponce: null,
  deleteOrderDocumentError: null,

  getOrderDocumentTypesLoading: false,
  getOrderDocumentTypesResponce: null,
  getOrderDocumentTypesSelectResponce: null,
  getOrderDocumentTypesError: null,

  getInvoiceLoading: true,
  getInvoiceResponce: null,
  getInvoiceError: null,

  createInvoiceLoading: false,
  createInvoiceResponce: null,
  createInvoiceError: null,
};

export const getOrderDocuments = createAsyncThunk(
  "orderDocuments/getOrderDocuments",
  async (orderId, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await documentsService.getOrderDocuments(ISO, TOKEN, orderId);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createOrderDocument = createAsyncThunk(
  "orderDocuments/createOrderDocument",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      const document_type_id = data.document_type_id;
      const document = data.document;
      const publish_to_group = data.publish_to_group;
      const orderId = data.orderId;
      const formData = new FormData();
      formData.append("document", document);
      formData.append("document_type_id", document_type_id);
      formData.append("publish_to_group", publish_to_group);
      const result = await documentsService.createOrderDocument(
        ISO,
        TOKEN,
        orderId,
        formData
      );
      return result;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteOrderDocument = createAsyncThunk(
  "orderDocuments/deleteOrderDocument",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await documentsService.deleteOrderDocument(
        ISO,
        TOKEN,
        data.orderId,
        data.documentId
      );
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getOrderDocumentTypes = createAsyncThunk(
  "orderDocuments/getOrderDocumentTypes",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await documentsService.getOrderDocumentTypes(ISO, TOKEN);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getInvoiceData = createAsyncThunk(
  "orderDocuments/getInvoiceData",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await documentsService.getInvoiceData(
        ISO,
        TOKEN,
        data.orderId,
        data.currencyId
      );
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createInvoice = createAsyncThunk(
  "orderDocuments/createInvoice",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      const result = await documentsService.createInvoice(
        ISO,
        TOKEN,
        data.orderId,
        data
      );
      return result;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const orderDocumentsSlice = createSlice({
  name: "orderDocuments",
  initialState,
  reducers: {
    resetCreateOrderDocument: (state) => {
      state.createOrderDocumentLoading = false;
      state.createOrderDocumentResponce = null;
      state.createOrderDocumentError = null;
    },
    resetDeleteOrderDocument: (state) => {
      state.deleteOrderDocumentLoading = false;
      state.deleteOrderDocumentResponce = null;
      state.deleteOrderDocumentError = null;
    },
    resetGetInvoiceData: (state) => {
      state.getInvoiceLoading = true;
      state.getInvoiceResponce = null;
      state.getInvoiceError = null;
    },
    resetCreateInvoice: (state) => {
      state.createInvoiceLoading = false;
      state.createInvoiceResponce = null;
      state.createInvoiceError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrderDocuments.pending, (state) => {
        state.getOrderDocumentsLoading = true;
        state.getOrderDocumentsResponce = null;
        state.getOrderDocumentsError = null;
      })
      .addCase(getOrderDocuments.fulfilled, (state, action) => {
        state.getOrderDocumentsLoading = false;
        state.getOrderDocumentsResponce = action.payload;
        state.getOrderDocumentsError = null;
      })
      .addCase(getOrderDocuments.rejected, (state, action) => {
        state.getOrderDocumentsLoading = false;
        state.getOrderDocumentsResponce = null;
        state.getOrderDocumentsError = action.payload;
      })

      .addCase(createOrderDocument.pending, (state) => {
        state.createOrderDocumentLoading = true;
        state.createOrderDocumentResponce = null;
        state.createOrderDocumentError = null;
      })
      .addCase(createOrderDocument.fulfilled, (state, action) => {
        state.createOrderDocumentLoading = false;
        state.createOrderDocumentResponce = action.payload;
        state.createOrderDocumentError = null;
      })
      .addCase(createOrderDocument.rejected, (state, action) => {
        state.createOrderDocumentLoading = false;
        state.createOrderDocumentResponce = null;
        state.createOrderDocumentError = action.payload;
      })

      .addCase(deleteOrderDocument.pending, (state) => {
        state.deleteOrderDocumentLoading = true;
        state.deleteOrderDocumentResponce = null;
        state.deleteOrderDocumentError = null;
      })
      .addCase(deleteOrderDocument.fulfilled, (state, action) => {
        state.deleteOrderDocumentLoading = false;
        state.deleteOrderDocumentResponce = action.payload;
        state.deleteOrderDocumentError = null;
      })
      .addCase(deleteOrderDocument.rejected, (state, action) => {
        state.deleteOrderDocumentLoading = false;
        state.deleteOrderDocumentResponce = null;
        state.deleteOrderDocumentError = action.payload;
      })

      .addCase(getOrderDocumentTypes.pending, (state) => {
        state.getOrderDocumentTypesLoading = true;
        state.getOrderDocumentTypesResponce = null;
        state.getOrderDocumentTypesSelectResponce = null;
        state.getOrderDocumentTypesError = null;
      })
      .addCase(getOrderDocumentTypes.fulfilled, (state, action) => {
        state.getOrderDocumentTypesLoading = false;
        state.getOrderDocumentTypesResponce = action.payload;
        state.getOrderDocumentTypesSelectResponce = action.payload.map((i) => ({
          label: i.name,
          value: i,
        }));
        state.getOrderDocumentTypesError = null;
      })
      .addCase(getOrderDocumentTypes.rejected, (state, action) => {
        state.getOrderDocumentTypesLoading = false;
        state.getOrderDocumentTypesResponce = null;
        state.getOrderDocumentTypesSelectResponce = null;
        state.getOrderDocumentTypesError = action.payload;
      })

      .addCase(getInvoiceData.pending, (state) => {
        state.getInvoiceLoading = true;
        state.getInvoiceResponce = null;
        state.getInvoiceError = null;
      })
      .addCase(getInvoiceData.fulfilled, (state, action) => {
        state.getInvoiceLoading = false;
        state.getInvoiceResponce = action.payload;
        state.getInvoiceError = null;
      })
      .addCase(getInvoiceData.rejected, (state, action) => {
        state.getInvoiceLoading = false;
        state.getInvoiceResponce = null;
        state.getInvoiceError = action.payload;
      })

      .addCase(createInvoice.pending, (state) => {
        state.createInvoiceLoading = true;
        state.createInvoiceResponce = null;
        state.createInvoiceError = null;
      })
      .addCase(createInvoice.fulfilled, (state, action) => {
        state.createInvoiceLoading = false;
        state.createInvoiceResponce = action.payload;
        state.createInvoiceError = null;
      })
      .addCase(createInvoice.rejected, (state, action) => {
        state.createInvoiceLoading = false;
        state.createInvoiceResponce = null;
        state.createInvoiceError = action.payload;
      });
  },
});

export const {
  resetCreateOrderDocument,
  resetDeleteOrderDocument,
  resetGetInvoiceData,
  resetCreateInvoice,
} = orderDocumentsSlice.actions;
export default orderDocumentsSlice.reducer;
