import React from "react";
import "./OrdersSidebarItem.scss";

import { useTranslation } from "react-i18next";
import { FaWeightHanging } from "react-icons/fa";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { BsBoxSeamFill } from "react-icons/bs";

import { useMoney } from "./../../../../../helper/useMoney";
import { useConvertShippingType } from "./../../../../../helper/useConvertShippingType";
import Character from "../../../../UI/Character/Character";
import { useUniqueProductCategory } from "./../../../../../helper/useUniqueProductCategory";

const OrdersSidebarItem = ({
  index,
  order,
  activeOrderId,
  setActiveOrderId,
  activeOrderIndex,
  setActiveOrderIndex,
}) => {
  const { t } = useTranslation();
  const formattedMoney = useMoney(
    order?.expense?.amount || 0,
    order?.expense?.currency?.symbol || "-"
  );
  const { shippingTypeSecondaryIcon } = useConvertShippingType(
    order?.type || null
  );
  const uniqueTypesWithCount = useUniqueProductCategory(order.cargos).length;

  const submitHandler = () => {
    setActiveOrderId(order.id);
    setActiveOrderIndex(index);
  };

  return (
    <li
      id='OrdersSidebarItem'
      className={order.id === activeOrderId ? "active" : ""}
      onClick={() => submitHandler()}
    >
      <div className='header'>
        <div className='code'>
          <div
            className='tracking'
            style={{
              border: `2px solid ${order.color ? order.color : "#eaebeb"}`,
            }}
          >
            <h3>{order.tracking}</h3>
          </div>
          <h4>{order?.cargos[0]?.pickup_date || ""}</h4>
        </div>
        <div
          className='status'
          style={{ backgroundColor: order.status.color_2 }}
        >
          <h4 style={{ color: order.status.color_1 }}>
            <Character max={25} title={order?.status?.name || ""} />
          </h4>
        </div>
      </div>
      <div className='line'>
        <div className='color'></div>
      </div>
      <div className='content'>
        <div className='item'>
          <div className='icon'>{shippingTypeSecondaryIcon}</div>
          <h5>
            {order?.cargos?.[0]?.locations?.find((A) => A.direction === "A")
              ?.country ?? ""}{" "}
            -{" "}
            {order?.cargos?.[0]?.locations?.find((B) => B.direction === "B")
              ?.country ?? ""}
          </h5>
        </div>
        <div className='item'>
          <div className='icon'>
            <RiMoneyDollarCircleFill />
          </div>
          <h5>{formattedMoney}</h5>
        </div>
        <div className='item'>
          <div className='icon'>
            <BsBoxSeamFill />
          </div>

          <h5>
            <Character title={order?.cargos[0]?.type?.name ?? "-"} max={15} />
            {uniqueTypesWithCount > 1 ? (
              <span>({uniqueTypesWithCount || ""})</span>
            ) : (
              ""
            )}
          </h5>
        </div>
        <div className='item'>
          <div className='icon'>
            <FaWeightHanging />
          </div>
          <h5>
            {order?.weight ?? "0"} {t("shipper.units.KG")}
          </h5>
        </div>
      </div>
    </li>
  );
};

export default OrdersSidebarItem;
