import React from "react";
import "./QuotesTable.scss";

import { useTranslation } from "react-i18next";

import QuotesTableHeader from "./QuotesTableHeader/QuotesTableHeader";
import TableWrapper from "../../Form/TableFields/TableWrapper/TableWrapper";
import QuotesTableItem from "./QuotesTableItem/QuotesTableItem";

const QuotesTable = ({ orders, total }) => {
  const { t } = useTranslation();
  const ordersWithQuotes = orders.filter((order) => order?.bids?.length > 0);
  return (
    <div id='QuotesTable'>
      <QuotesTableHeader badge={total} />
      <TableWrapper
        emptyText={t("shipper.desc.No order with offer")}
        total={ordersWithQuotes?.length}
      >
        <tbody>
          {ordersWithQuotes?.slice(0, 5).map((order) => (
            <QuotesTableItem key={order.id} order={order} />
          ))}
        </tbody>
      </TableWrapper>
    </div>
  );
};

export default QuotesTable;
