import { useSelector } from "react-redux";

export const useFavoriteLocationsErrors = () => {
  const { createLocationError } = useSelector(
    (state) => state.favoriteLocations
  );

  const from_inputted_address_error =
    createLocationError &&
    createLocationError.errors &&
    createLocationError.errors.from_inputted_address &&
    createLocationError.errors.from_inputted_address[0];

  const to_inputted_address_error =
    createLocationError &&
    createLocationError.errors &&
    createLocationError.errors.to_inputted_address &&
    createLocationError.errors.to_inputted_address[0];

  return {
    from_inputted_address_error,
    to_inputted_address_error,
  };
};
