import React, { useState } from "react";
import "./FilterPriceRangeForm.scss";

import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

import DDropdown from "../DDropdown/DDropdown";
import DInputs from "../../Form/DInputs/DInputs";
import DButton from "../../Form/DButtons/DButton/DButton";

const FilterPriceRangeForm = ({ value, setValue, placeholder }) => {
  const { t } = useTranslation();

  const { user } = useSelector((state) => state.auth);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");

  return (
    <div id='FilterPriceRangeForm'>
      <DDropdown
        value={value}
        setValue={setValue}
        placeholder={
          placeholder ? placeholder : t("tms.filters.placeholders.Price range")
        }
        dropdownOpen={dropdownOpen}
        setDropdownOpen={setDropdownOpen}
      >
        <div className='dropdownContentContainer'>
          <DInputs
            id={"minPriceInput"}
            label={`${t("tms.filters.labels.Min price")} (${
              user?.currency?.symbol
            })`}
            type={"number"}
            value={minPrice}
            setValue={setMinPrice}
            placeholder={t("tms.filters.placeholders.Enter min price")}
          />
          <DInputs
            id={"maxPriceInput"}
            label={`${t("tms.filters.labels.Max price")} (${
              user?.currency?.symbol
            })`}
            type={"number"}
            value={maxPrice}
            setValue={setMaxPrice}
            placeholder={t("tms.filters.placeholders.Enter max price")}
          />
          <div className='saveButton'>
            <DButton
              title={t("tms.filters.buttons.Save")}
              disabled={!minPrice && !maxPrice}
              onClick={() => {
                setValue({
                  minPrice,
                  maxPrice,
                  revenueCurrencyId: user?.currency?.id,
                });
                setDropdownOpen(false);
              }}
            />
          </div>
        </div>
      </DDropdown>
    </div>
  );
};

export default FilterPriceRangeForm;
