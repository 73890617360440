import React from "react";
import "./SendAgain.scss";

import { AiOutlineReload } from "react-icons/ai";

const SendAgain = ({ title, onClick }) => {
  return (
    <div className='SendAgain' id='SendAgain'>
      <div className='content' onClick={onClick}>
        <div className='icon'>
          <AiOutlineReload />
        </div>
        <p>{title}</p>
      </div>
    </div>
  );
};

export default SendAgain;
