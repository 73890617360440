import _request from "../../_request";

const getAdvances = async (ISO, TOKEN, orderId) => {
  const config = {
    url: `order/${orderId}/payments`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const createAdvance = async (ISO, TOKEN, orderId, data) => {
  const config = {
    url: `order/${orderId}/payments`,
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const updateAdvance = async (ISO, TOKEN, orderId, advanceId, data) => {
  const config = {
    url: `order/${orderId}/payments/${advanceId}`,
    method: "put",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const deleteAdvance = async (ISO, TOKEN, orderId, advanceId) => {
  const config = {
    url: `order/${orderId}/payments/${advanceId}`,
    method: "delete",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const markPaid = async (ISO, TOKEN, orderId, data) => {
  const config = {
    url: `order/${orderId}/mark-paid`,
    method: "put",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const advanceService = {
  getAdvances,
  createAdvance,
  updateAdvance,
  deleteAdvance,
  markPaid,
};

export default advanceService;
