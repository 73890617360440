import React, { Fragment, useState, useEffect } from "react";
import "./OrderStatusBar.scss";

import { useTranslation } from "react-i18next";

import { FaArrowRight } from "react-icons/fa6";
import { PiClockCounterClockwiseBold } from "react-icons/pi";
import { useSelector, useDispatch } from "react-redux";

import {
  getCargoStatuses,
  updateCargoStatuses,
  resetUpdateCargoStatuses,
} from "../../../store/orders/cargo/cargosSlice";
import { useUpdateStatusesError } from "../../../validations/order/useUpdateStatusesError";

import Skeleton from "@mui/material/Skeleton";

import OrderStatus from "./components/OrderStatus/OrderStatus";
import DButton from "../../Form/DButtons/DButton/DButton";
import DModal from "../../Modal/DModal/DModal";
import DTextarea from "../../Form/DTextarea/DTextarea";

const OrderStatusBar = ({ order }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    getCargoStatusesLoading,
    getCargoStatusesResponce,
    updateCargoStatusesResponce,
  } = useSelector((state) => state.cargos);
  const { update_message_error } = useUpdateStatusesError();

  const [openModal, setOpenModal] = useState(false);
  const [customerMessage, setCustomerMessage] = useState("");

  const handleNextStep = () => {
    setOpenModal(true);
  };

  const listedStatuses =
    getCargoStatusesResponce &&
    getCargoStatusesResponce.filter((status) => status.listed);

  const handleSubmitMessage = (notify) => {
    dispatch(
      updateCargoStatuses({
        cargoId: order && order.cargos[0].id,
        status_id:
          listedStatuses[
            listedStatuses?.findIndex(
              (status) => status.id === order.status.id
            ) + 1
          ].id,
        message: notify ? customerMessage : null,
        notify,
      })
    );
  };

  useEffect(() => {
    if (updateCargoStatusesResponce) {
      setOpenModal(false);
      dispatch(resetUpdateCargoStatuses());
    }
  }, [dispatch, updateCargoStatusesResponce]);

  const renderStatuses = (statuses) => {
    if (statuses === undefined) {
      return null;
    }
    return (
      <Fragment>
        {statuses.map((status) => {
          const currentStatus = status.id === order?.status.id;
          const doneStatus = status.id <= order?.status.id;
          const lastStatus = status.id === statuses.length;

          return (
            <li
              className={status.id < order?.status.id ? "active" : "passive"}
              key={status.id}
            >
              <div className='active' />
              <div className='passive' />
              <div className='dots'>
                <div
                  className='smallDot'
                  style={{
                    background: currentStatus
                      ? status?.color_1
                      : status.id > order?.status.id
                      ? "#f4f6f9"
                      : "",
                  }}
                />
                <div
                  className='largeDot'
                  style={{
                    background: status?.color_2,
                    display: !currentStatus && "none",
                  }}
                />
              </div>
              {currentStatus && lastStatus ? (
                <div className='statusContainer'>
                  <OrderStatus
                    status={status.name}
                    done
                    currentStatusColors={{
                      background: status.color_2,
                      text: status.color_1,
                    }}
                  />
                </div>
              ) : currentStatus ? (
                <div className='currentStatusContainer'>
                  <OrderStatus
                    status={status.name}
                    done={doneStatus}
                    currentStatusColors={{
                      background: status.color_2,
                      text: status.color_1,
                    }}
                  />
                  <DButton
                    title={t("tms.forms.buttons.Go to next step")}
                    rightIcon={<FaArrowRight />}
                    onClick={() => handleNextStep()}
                    disabled={
                      !order?.carrier ||
                      !order?.carrier?.trailer_registration_number ||
                      !order?.carrier?.truck_registration_number
                    }
                  />
                </div>
              ) : (
                <div className='statusContainer'>
                  <OrderStatus
                    status={status.name}
                    done={doneStatus}
                    current={currentStatus}
                    disabled={!doneStatus}
                  />
                </div>
              )}
            </li>
          );
        })}
      </Fragment>
    );
  };

  useEffect(() => {
    dispatch(getCargoStatuses());
    setCustomerMessage("");
    setOpenModal(false);
  }, [dispatch, updateCargoStatusesResponce]);

  return (
    <Fragment>
      {getCargoStatusesLoading && (
        <Skeleton
          variant='rounded'
          width={"100%"}
          className='getCargoStatusesLoadingSkeleton'
        />
      )}

      {getCargoStatusesResponce && !getCargoStatusesLoading && (
        <div id='OrderStatusBar'>
          <ul className='progress-bar'>{renderStatuses(listedStatuses)}</ul>
        </div>
      )}
      <DModal
        icon={<PiClockCounterClockwiseBold />}
        title={t("tms.modals.titles.Want to send update to your customer?")}
        isClose={() => setOpenModal(false)}
        isOpen={openModal}
        submitText={t("tms.modals.buttons.Send update")}
        orderStatus
        onOrderStatusClick={() => handleSubmitMessage(false)}
        onClick={() => handleSubmitMessage(true)}
        loading={false}
        disabled={!order?.customer?.company?.email && !order?.company?.email}
      >
        <DTextarea
          id={"customerMessage"}
          value={customerMessage}
          setValue={setCustomerMessage}
          placeholder={t(
            "tms.modals.placeholder.Message that you want to send customer..."
          )}
          error={update_message_error}
          disabled={!order?.customer?.company?.email && !order?.company?.email}
        />
        {!order?.customer?.company?.email && !order?.company?.email && (
          <div className='noCustomerMailText'>
            {t(
              "tms.modals.desc.*Unfortunately, it seems that the customer doesn’t have an email address, so if you want to change the order status then simply click on 'Move without update.'"
            )}
          </div>
        )}
      </DModal>
    </Fragment>
  );
};

export default OrderStatusBar;
