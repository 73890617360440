import React, { Fragment, useState } from "react";
import "./FilledOwnCarrier.scss";

import { useTranslation } from "react-i18next";

import { FaUser } from "react-icons/fa";
import { MdLinkOff } from "react-icons/md";

import Character from "../../../UI/Character/Character";
import DButton from "../../../Form/DButtons/DButton/DButton";

import LinkOffCarrierModal from "./../../ManageOrder/LinkOffCarrierModal/LinkOffCarrierModal";

const FilledOwnCarrier = ({ order }) => {
  const { t } = useTranslation();
  const [showLinkOfModal, setShowLinkOfModal] = useState(false);

  const driverName = order?.carrier?.first_name || "";
  const driverLastName = order?.carrier?.last_name || "";
  return (
    <Fragment>
      <div id='FilledOwnCarrier'>
        <div className='FilledOwnCarrierItem'>
          <div className='icon'>
            <FaUser />
          </div>
          <div className='text'>
            <h3>
              <Character title={driverName + " " + driverLastName} max={30} />
            </h3>
            <p>{order?.carrier?.phone ? order?.carrier?.phone : ""}</p>
          </div>
        </div>
        <div className='FilledOwnCarrierItem'>
          <div
            className='cover'
            style={{
              backgroundImage: `url(${`/assets/images/dashboard/cover8.svg`})`,
            }}
          ></div>
          <div className='text'>
            <h3>
              <Character
                title={
                  order.carrier.truck_registration_number
                    ? order.carrier.truck_registration_number
                    : ""
                }
                max={14}
              />
            </h3>
            {/* <p>
              {order.carrier.truck_passport_number
                ? order.carrier.truck_passport_number
                : ""}
            </p> */}
          </div>
        </div>
        <div className='FilledOwnCarrierItem'>
          <div
            className='cover'
            style={{
              backgroundImage: `url(${`/assets/images/dashboard/cover7.svg`})`,
            }}
          ></div>
          <div className='text'>
            <h3>
              <Character
                title={
                  order.carrier.trailer_registration_number
                    ? order.carrier.trailer_registration_number
                    : ""
                }
                max={20}
              />
            </h3>
          </div>
        </div>
        <div className='FilledOwnCarrierItem'>
          <DButton
            onClick={() => setShowLinkOfModal(true)}
            title={t("tms.forms.buttons.Unlink")}
            rightIcon={<MdLinkOff />}
          />
        </div>
      </div>
      <LinkOffCarrierModal
        ownCarrier
        isOpen={showLinkOfModal}
        isClose={() => setShowLinkOfModal(false)}
        order={order}
      />
    </Fragment>
  );
};

export default FilledOwnCarrier;
