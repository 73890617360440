import { useSelector } from "react-redux";

export const useAuthError = () => {
  const { error, codeCheckError, registerStep1Error, registerStep2Error } =
    useSelector((state) => state.auth);

  const branch_id_error =
    error &&
    error.errors &&
    error.errors.branch_id &&
    error.errors.branch_id[0];

  const user_type_id_error =
    error &&
    error.errors &&
    error.errors.user_type_id &&
    error.errors.user_type_id[0];

  const first_name_error =
    error &&
    error.errors &&
    error.errors.first_name &&
    error.errors.first_name[0];

  const last_name_error =
    error &&
    error.errors &&
    error.errors.last_name &&
    error.errors.last_name[0];

  const email_error =
    error && error.errors && error.errors.email && error.errors.email[0];

  const phone_index_id_error =
    error &&
    error.errors &&
    error.errors.phone_index_id &&
    error.errors.phone_index_id[0];

  const phone_error =
    error && error.errors && error.errors.phone && error.errors.phone[0];

  const legal_id_error =
    error && error.errors && error.errors.legal_id && error.errors.legal_id[0];

  const password_error =
    error && error.errors && error.errors.password && error.errors.password[0];

  const password_confirmation_error =
    error &&
    error.errors &&
    error.errors.password_confirmation &&
    error.errors.password_confirmation[0];

  const company_legal_id_error =
    error &&
    error.errors &&
    error.errors["company.legal_id"] &&
    error.errors["company.legal_id"][0];

  const company_name_error =
    error &&
    error.errors &&
    error.errors["company.name"] &&
    error.errors["company.name"][0];

  const agreement_error =
    error &&
    error.errors &&
    error.errors.agreement &&
    error.errors.agreement[0];

  const code_error =
    error && error.errors && error.errors.code && error.errors.code;

  const code_check_error = codeCheckError?.errors?.code?.[0];

  const reset_code_error =
    error && error.errors && error.errors.code && error.errors.code;

  // register step 1

  const step1_first_name_error = registerStep1Error?.errors?.first_name?.[0];
  const step1_last_name_error = registerStep1Error?.errors?.last_name?.[0];
  const step1_phone_index_id_error =
    registerStep1Error?.errors?.phone_index_id?.[0];
  const step1_phone_error = registerStep1Error?.errors?.phone?.[0];
  const step1_branch_id_error = registerStep1Error?.errors?.branch_id?.[0];
  const step1_user_type_id_error =
    registerStep1Error?.errors?.user_type_id?.[0];

  // register step 2

  const step2_first_name_error = registerStep2Error?.errors?.first_name?.[0];
  const step2_last_name_error = registerStep2Error?.errors?.last_name?.[0];
  const step2_phone_index_id_error =
    registerStep2Error?.errors?.phone_index_id?.[0];
  const step2_phone_error = registerStep2Error?.errors?.phone?.[0];
  const step2_branch_id_error = registerStep2Error?.errors?.branch_id?.[0];
  const step2_company_legal_id_error =
    registerStep2Error?.errors?.["company.legal_id"]?.[0];
  const step2_company_name_error =
    registerStep2Error?.errors?.["company.name"]?.[0];
  const step2_email_error = registerStep2Error?.errors?.email?.[0];
  const step2_password_error = registerStep2Error?.errors?.password?.[0];
  const step2_agreement_error = registerStep2Error?.errors?.agreement?.[0];
  const step2_user_type_id_error =
    registerStep2Error?.errors?.user_type_id?.[0];

  return {
    branch_id_error,
    user_type_id_error,
    first_name_error,
    last_name_error,
    email_error,
    phone_index_id_error,
    phone_error,
    legal_id_error,
    password_error,
    password_confirmation_error,
    company_legal_id_error,
    company_name_error,
    agreement_error,
    code_error,
    code_check_error,
    reset_code_error,

    step1_first_name_error,
    step1_last_name_error,
    step1_phone_index_id_error,
    step1_phone_error,
    step1_branch_id_error,
    step1_user_type_id_error,

    step2_first_name_error,
    step2_last_name_error,
    step2_phone_index_id_error,
    step2_phone_error,
    step2_branch_id_error,
    step2_company_legal_id_error,
    step2_company_name_error,
    step2_email_error,
    step2_password_error,
    step2_agreement_error,
    step2_user_type_id_error,
  };
};

export default useAuthError;
