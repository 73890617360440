import React from "react";
import "./FilterShipperFinancialStatusesClips.scss";

import { useTranslation } from "react-i18next";

const FilterShipperFinancialStatusesClips = ({ status, setStatus }) => {
  const { t } = useTranslation();
  const STATUSES = [
    {
      id: null,
      label: t("shipper.filters.All"),
      value: null,
    },
    {
      id: 1,
      label: t("shipper.filters.Unpaid"),
      value: "unpaid",
    },
    {
      id: 2,
      label: t("shipper.filters.Partially paid"),
      value: "partial",
    },
    {
      id: 3,
      label: t("shipper.filters.Overdue"),
      value: "overdue",
    },
    {
      id: 4,
      label: t("shipper.filters.Paid"),
      value: "paid",
    },
  ];
  return (
    <div id='FilterShipperFinancialStatusesClips'>
      {STATUSES.map((i, index) => (
        <div
          key={index}
          className={i.id === status.id ? "status active" : "status"}
          onClick={() => setStatus(i)}
        >
          <h4>{i?.label}</h4>
        </div>
      ))}
    </div>
  );
};

export default FilterShipperFinancialStatusesClips;
