import { useSelector } from "react-redux";

export const useBankAccountsErrors = () => {
  const { createBankAccountError, updateBankAccountError } = useSelector(
    (state) => state.bankAccounts
  );

  //// create
  const create_name_error =
    createBankAccountError &&
    createBankAccountError.errors &&
    createBankAccountError.errors.name &&
    createBankAccountError.errors.name[0];

  const create_code_error =
    createBankAccountError &&
    createBankAccountError.errors &&
    createBankAccountError.errors.code &&
    createBankAccountError.errors.code[0];

  const create_account_error =
    createBankAccountError &&
    createBankAccountError.errors &&
    createBankAccountError.errors.account &&
    createBankAccountError.errors.account[0];

  // update
  const update_name_error =
    updateBankAccountError &&
    updateBankAccountError.errors &&
    updateBankAccountError.errors.name &&
    updateBankAccountError.errors.name[0];

  const update_code_error =
    updateBankAccountError &&
    updateBankAccountError.errors &&
    updateBankAccountError.errors.code &&
    updateBankAccountError.errors.code[0];

  const update_account_error =
    updateBankAccountError &&
    updateBankAccountError.errors &&
    updateBankAccountError.errors.account &&
    updateBankAccountError.errors.account[0];

  return {
    create_name_error,
    create_code_error,
    create_account_error,
    update_name_error,
    update_code_error,
    update_account_error,
  };
};
