import { useSelector } from "react-redux";

export const useMakeBidErrors = () => {
  const { biddingBoardError } = useSelector((state) => state.loadBoards);

  const amount_error =
    biddingBoardError &&
    biddingBoardError.errors &&
    biddingBoardError.errors.amount &&
    biddingBoardError.errors.amount[0];

  const payment_days_error =
    biddingBoardError &&
    biddingBoardError.errors &&
    biddingBoardError.errors["payment.days"] &&
    biddingBoardError.errors["payment.days"][0];

  const payment_amount_error =
    biddingBoardError &&
    biddingBoardError.errors &&
    biddingBoardError.errors["payment.amount"] &&
    biddingBoardError.errors["payment.amount"][0];

  const payment_currency_id_error =
    biddingBoardError &&
    biddingBoardError.errors &&
    biddingBoardError.errors["payment.currency_id"] &&
    biddingBoardError.errors["payment.currency_id"][0];

  const payment_option_type_error =
    biddingBoardError &&
    biddingBoardError.errors &&
    biddingBoardError.errors["payment.option_type"] &&
    biddingBoardError.errors["payment.option_type"][0];

  return {
    amount_error,
    payment_days_error,
    payment_amount_error,
    payment_currency_id_error,
    payment_option_type_error,
  };
};

export default useMakeBidErrors;
