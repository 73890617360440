import React from "react";
import "./Copyright.scss";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Lang from "../../../Controls/Lang/Lang";
import CountrySelector from "../../../Controls/CountrySelector/CountrySelector";

const Copyright = () => {
  const { t } = useTranslation();

  const { languages } = useSelector((state) => state.lang);
  const { countries } = useSelector((state) => state.settings);

  const currentYear = new Date().getFullYear();

  return (
    <div id='Copyright' className='wrapper'>
      <div className='container'>
        <h6>
          Copyright © {currentYear} Cargon.
          {t("landing.navigation.All rights reserved.")}
        </h6>
        <div className='controls'>
          {languages && <Lang />} {countries && <CountrySelector />}
        </div>
      </div>
    </div>
  );
};

export default Copyright;
