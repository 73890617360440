import React, { useEffect } from "react";

import { MdCancel } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  rejectOrder,
  resetRejectOrder,
} from "./../../../../store/orders/shipperOrders/shipperOrdersSlice";

import DModal from "../../../Modal/DModal/DModal";

const ShipperOfferRejectModal = ({
  isOpen,
  isClose,
  order,
  setParentModalClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { rejectOrderLoading, rejectOrderResponce } = useSelector(
    (state) => state.shipperOrders
  );

  useEffect(() => {
    if (rejectOrderResponce) {
      dispatch(resetRejectOrder());
      isClose();
      setParentModalClose && setParentModalClose();
    }
  }, [dispatch, isClose, rejectOrderResponce, setParentModalClose]);

  const submitHandler = () => {
    const data = {
      orderId: order.id,
      bidId: order?.bids?.[0]?.id,
    };
    dispatch(rejectOrder(data));
  };

  return (
    <DModal
      isOpen={isOpen}
      isClose={isClose}
      onClick={() => submitHandler()}
      submitText={t("shipper.buttons.Reject")}
      title={t("shipper.modal.Reject offer")}
      icon={<MdCancel />}
      status='danger'
      loading={rejectOrderLoading}
    >
      <p>{t("shipper.modal.Are you sure you want to reject this offer?")}</p>
    </DModal>
  );
};

export default ShipperOfferRejectModal;
