import React from "react";
import "./DFileForm.scss";

import { FiTrash2 } from "react-icons/fi";
import { IoDocumentTextOutline } from "react-icons/io5";
import { MdUpload } from "react-icons/md";

import Character from "../../../UI/Character/Character";
import DErrorText from "../../DErrorText/DErrorText";

const DFileForm = ({ value, setValue, title, subTitle, error }) => {
  const openFileInBrowser = () => {
    if (value) {
      const url = URL.createObjectURL(value);
      const newTab = window.open(url, "_blank");
      newTab.focus();
    }
  };

  return (
    <div id='DFileForm' className={error ? "error" : ""}>
      {value ? (
        <div className='Document'>
          <div className='title' onClick={openFileInBrowser}>
            <div className='fileIcon'>
              <IoDocumentTextOutline />
            </div>

            <h6>
              <Character
                max={15}
                title={value?.name ? value.name : "No title"}
              />
            </h6>
          </div>

          <div className='removeIcon' onClick={() => setValue(null)}>
            <FiTrash2 />
          </div>
        </div>
      ) : (
        <div className='noDocument'>
          <div className='icon'>
            <MdUpload />
          </div>
          <div className='content'>
            <h3>{title}</h3>
            <h4>{subTitle}</h4>
          </div>
          <input type='file' onChange={(e) => setValue(e.target.files[0])} />
        </div>
      )}
      <DErrorText error={error} />
    </div>
  );
};

export default DFileForm;
