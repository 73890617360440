import { useSelector } from "react-redux";

export const useOrderCostErrors = () => {
  const { createOrderCostError, updateOrderCostError } = useSelector(
    (state) => state.orderCosts
  );

  const create_category_id_error =
    createOrderCostError &&
    createOrderCostError.errors &&
    createOrderCostError.errors.category_id &&
    createOrderCostError.errors.category_id[0];

  const create_operation_error =
    createOrderCostError &&
    createOrderCostError.errors &&
    createOrderCostError.errors.operation &&
    createOrderCostError.errors.operation[0];

  const create_currency_id_error =
    createOrderCostError &&
    createOrderCostError.errors &&
    createOrderCostError.errors.currency_id &&
    createOrderCostError.errors.currency_id[0];

  const create_amount_error =
    createOrderCostError &&
    createOrderCostError.errors &&
    createOrderCostError.errors.amount &&
    createOrderCostError.errors.amount[0];

  const create_comment_error =
    createOrderCostError &&
    createOrderCostError.errors &&
    createOrderCostError.errors.comment &&
    createOrderCostError.errors.comment[0];

  const update_category_id_error =
    updateOrderCostError &&
    updateOrderCostError.errors &&
    updateOrderCostError.errors.category_id &&
    updateOrderCostError.errors.category_id[0];

  const update_currency_id_error =
    updateOrderCostError &&
    updateOrderCostError.errors &&
    updateOrderCostError.errors.currency_id &&
    updateOrderCostError.errors.currency_id[0];

  const update_amount_error =
    updateOrderCostError &&
    updateOrderCostError.errors &&
    updateOrderCostError.errors.amount &&
    updateOrderCostError.errors.amount[0];
  const update_comment_error =
    updateOrderCostError &&
    updateOrderCostError.errors &&
    updateOrderCostError.errors.comment &&
    updateOrderCostError.errors.comment[0];
  return {
    create_category_id_error,
    create_operation_error,
    create_currency_id_error,
    create_amount_error,
    create_comment_error,
    update_category_id_error,
    update_currency_id_error,
    update_amount_error,
    update_comment_error,
  };
};
