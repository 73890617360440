import { useSelector } from "react-redux";

export const useCreateShipperAirError = () => {
  const { createAirError } = useSelector((state) => state.shipperOrders);

  const cargos_price_error =
    createAirError?.errors?.["cargos.0.price"]?.[0] ?? null;

  const cargos_price_currency_id_error =
    createAirError?.errors?.["cargos.0.price_currency_id"]?.[0] ?? null;

  const cargos_comment_error =
    createAirError?.errors?.["cargos.0.about"]?.[0] ?? null;

  const cargos_ready_date_error =
    createAirError?.errors?.["cargos.0.cargo_ready_date"]?.[0] ?? null;

  const order_documents_error = createAirError?.errors?.documents?.[0] ?? null;

  const create_order_Network_error = createAirError === "Network Error";

  return {
    create_order_Network_error,
    cargos_comment_error,
    cargos_ready_date_error,
    cargos_price_error,
    cargos_price_currency_id_error,
    order_documents_error,
  };
};

export default useCreateShipperAirError;
