import React from "react";
import "./ShipperPaymentStatusField.scss";

import { useTranslation } from "react-i18next";

const ShipperPaymentStatusField = ({ status }) => {
  const { t } = useTranslation();
  return (
    <td id='ShipperPaymentStatusField'>
      <div className='content'>
        <div
          className='status'
          style={{ backgroundColor: status?.color_2 || "#fff4ec" }}
        >
          <div
            className='dot'
            style={{ backgroundColor: status.color_1 || "#b95000" }}
          ></div>
          <h4 style={{ color: status.color_1 || "#b95000" }}>
            {status?.name || t("shipper.table.desc.No information")}
          </h4>
        </div>
      </div>
    </td>
  );
};

export default ShipperPaymentStatusField;
