import React, { useState } from "react";
import "./VectorMap.scss";

import { useSelector } from "react-redux";
import { VectorMap } from "@react-jvectormap/core";
import { worldMill } from "@react-jvectormap/world";

import Info from "./Info/Info";
import Marker from "./Marker/Marker";

const VectorMapC = () => {
  const [activeIndex, setActiveIndex] = useState();

  const { contacts, contactsLoading } = useSelector((state) => state.contact);

  const data = contacts?.map((contact) => {
    return {
      name: contact?.label,
      address: contact?.value?.contact_address,
      tel: contact?.value?.contact_phone,
      email: contact?.value?.contact_email,
      coords: [contact?.value?.location?.lat, contact?.value?.location?.lng],
    };
  });

  if (contactsLoading) return null;
  return (
    <div id='VectorMap'>
      <Info />
      {data?.map((item, index) => (
        <Marker
          item={item}
          key={index}
          index={index}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        />
      ))}
      <VectorMap
        zoomOnScroll={false}
        zoomMin={1}
        zoomMax={8}
        focusOn={
          window.innerWidth < 1000
            ? { regions: ["TM", "MD", "GE"] }
            : { regions: ["JP", "CU", "GE"] }
        }
        backgroundColor='#F4F2EF'
        className='VectorMap'
        map={worldMill}
        labels={false}
        markersSelectable={true}
        onMarkerClick={(event, element) => {
          setActiveIndex(element);
        }}
        markers={data?.map((item) => ({
          name: item.name,
          latLng: item.coords,
          id: item.name,
        }))}
        regionStyle={{
          initial: {
            fill: "#e4e4e4",
            "fill-opacity": 0.9,
            stroke: "none",
            "stroke-width": 0,
            "stroke-opacity": 0,
          },
          hover: {
            fill: "#763452",
            "fill-opacity": 0.9,
          },
        }}
      />
    </div>
  );
};

export default VectorMapC;
