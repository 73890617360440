import _request from "../../_request";

const getBankAccounts = async (ISO, TOKEN) => {
  const config = {
    url: "/user/bank-accounts",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const createBankAccount = async (ISO, TOKEN, data) => {
  const config = {
    url: `/user/bank-accounts`,
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const updateBankAccount = async (ISO, TOKEN, id, data) => {
  const config = {
    url: `/user/bank-accounts/${id}`,
    method: "put",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const deleteBankAccount = async (ISO, TOKEN, id) => {
  const config = {
    url: `/user/bank-accounts/${id}`,
    method: "delete",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const bankAccountsService = {
  getBankAccounts,
  updateBankAccount,
  createBankAccount,
  deleteBankAccount,
};

export default bankAccountsService;
