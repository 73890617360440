import React, { Fragment, useState, useEffect } from "react";
import "./DocumentsDetails.scss";

import { useTranslation } from "react-i18next";

import { MdAttachFile } from "react-icons/md";
import { IoDocumentText } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";

import {
  getOrderDocuments,
  getOrderDocumentTypes,
} from "./../../../store/orders/documents/documentsSlice";

import DButton from "../../Form/DButtons/DButton/DButton";
import DocumentsDetailsItem from "./components/DocumentsDetailsItem/DocumentsDetailsItem";
import AddNewDocumentModal from "./components/AddNewDocumentModal/AddNewDocumentModal";
import LoadingPage from "./../../../componentsAdditional/Loading/LoadingPage/LoadingPage";

const DocumentsDetails = ({ orderId, order }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    getOrderDocumentsLoading,
    getOrderDocumentsResponce,
    createOrderDocumentResponce,
    deleteOrderDocumentResponce,
    createInvoiceResponce,
  } = useSelector((state) => state.orderDocuments);

  const [openCreateModal, setOpenCreateModal] = useState(false);

  useEffect(() => {
    dispatch(getOrderDocuments(orderId));
  }, [
    dispatch,
    orderId,
    createOrderDocumentResponce,
    deleteOrderDocumentResponce,
    createInvoiceResponce,
  ]);

  useEffect(() => {
    dispatch(getOrderDocumentTypes());
  }, [dispatch]);

  return (
    <Fragment>
      <div id='DocumentsDetails'>
        {getOrderDocumentsLoading && <LoadingPage />}
        {getOrderDocumentsResponce && (
          <Fragment>
            <div className='DocumentsDetailsHeader'>
              <div className='icon'>
                <IoDocumentText />
              </div>
              <div className='titleContainer'>
                <h1>{t("tms.titles.Documents")}</h1>
                <DButton
                  title={t("tms.forms.buttons.Upload")}
                  rightIcon={<MdAttachFile />}
                  onClick={() => {
                    setOpenCreateModal(true);
                  }}
                />
              </div>
            </div>
            {getOrderDocumentsResponce.length === 0 ? (
              <p>{t("tms.desc.No documents")}</p>
            ) : (
              <ul className='documentList'>
                {getOrderDocumentsResponce.map((document) => (
                  <DocumentsDetailsItem
                    key={document.id}
                    document={document}
                    orderId={orderId}
                  />
                ))}
              </ul>
            )}
          </Fragment>
        )}
      </div>

      <AddNewDocumentModal
        isOpen={openCreateModal}
        isClose={() => setOpenCreateModal(false)}
        orderId={orderId}
        order={order}
      />
    </Fragment>
  );
};

export default DocumentsDetails;
