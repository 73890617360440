import React, { useState, useEffect } from "react";
import "./SignUpShipperAuthForm.scss";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";

import {
  registerStep2,
  resetRegisterStep2,
} from "../../../../store/auth/authSlice";
import { useAuthError } from "../../../../validations/auth/useAuthError";

import AuthFormWrapper from "../../Components/AuthFormWrapper/AuthFormWrapper";
import FormTitle from "../../Components/FormTitle/FormTitle";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import Button from "../../../Form/Button/Button";
import Input from "../../../Form/Inputs/Input";
import Label from "../../../Form/Label/Label";
import ErrorText from "../../../Form/ErrorText/ErrorText";
import TermsAgree from "../../../Form/Checkbox/TermsAgree/TermsAgree";

const SignUpShipperAuthForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const firstName = searchParams.get("firstName");
  const lastName = searchParams.get("lastName");
  const countryId = searchParams.get("countryId");
  const phoneIndexId = searchParams.get("phoneIndex");
  const phone = searchParams.get("phone");
  const { registerStep2Error, registerStep2Loading, registerStep2Responce } =
    useSelector((state) => state.auth);
  const {
    step2_branch_id_error,
    step2_user_type_id_error,
    step2_first_name_error,
    step2_last_name_error,
    step2_email_error,
    step2_phone_index_id_error,
    step2_phone_error,
    step2_password_error,
    step2_company_legal_id_error,
    step2_company_name_error,
    step2_agreement_error,
  } = useAuthError();

  const [companyVat, setCompanyVat] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [agree, setAgree] = useState(true);

  const submitHandler = () => {
    let data = {
      branch_id: countryId,
      user_type_id: 5,
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_index_id: phoneIndexId,
      phone: phone,
      password: password,
      password_confirmation: password,
      company: {
        legal_id: companyVat,
        name: companyName,
      },
      agreement: agree,
    };
    dispatch(registerStep2(data));
  };

  useEffect(() => {
    if (!registerStep2Error && registerStep2Responce) {
      const url = `/register/shipper/verify?firstName=${firstName}&lastName=${lastName}&countryId=${countryId}&phoneIndexId=${phoneIndexId}&phone=${phone}&companyVat=${companyVat}&companyName=${companyName}&email=${email}&agree=${agree}`;
      navigate(url);
      dispatch(resetRegisterStep2());
    }
  }, [
    registerStep2Error,
    registerStep2Responce,
    firstName,
    lastName,
    countryId,
    phoneIndexId,
    phone,
    companyVat,
    companyName,
    email,
    agree,
    navigate,
    dispatch,
  ]);

  useEffect(() => {
    if (
      step2_user_type_id_error ||
      step2_first_name_error ||
      step2_last_name_error ||
      step2_phone_index_id_error ||
      step2_phone_error ||
      step2_branch_id_error
    ) {
      navigate("/register/shipper");
    }
  }, [
    navigate,
    step2_user_type_id_error,
    step2_first_name_error,
    step2_last_name_error,
    step2_phone_index_id_error,
    step2_phone_error,
    step2_branch_id_error,
  ]);

  return (
    <AuthFormWrapper>
      <form id='SignUpShipperAuthForm' className='SignUpShipperAuthForm'>
        <FormTitle
          title={t("landing.auth.titles.Welcome to the future of logistics")}
        >
          <span>{t("landing.auth.titles.We almost there")}</span>
        </FormTitle>
        <ProgressBar position={2} />
        <div className='group'>
          <div>
            <Label
              text={t("landing.auth.form.Company VAT number")}
              id='CompanyVATnumber'
            />
            <Input
              value={companyVat}
              setValue={setCompanyVat}
              placeholder={t("landing.auth.form.Enter company VAT number")}
              id='CompanyVATnumber'
              error={step2_company_legal_id_error}
            />
            <ErrorText error={step2_company_legal_id_error} />
          </div>
          <div>
            <Label
              text={t("landing.auth.form.Company name")}
              id='CompanyName'
            />
            <Input
              value={companyName}
              setValue={setCompanyName}
              placeholder={t("landing.auth.form.Enter company name")}
              id='CompanyName'
              error={step2_company_name_error}
            />
            <ErrorText error={step2_company_name_error} />
          </div>
          <div>
            <Label text={t("landing.auth.form.Email")} id='AuthEmail' />
            <Input
              value={email}
              setValue={setEmail}
              placeholder={t("landing.auth.form.Enter email")}
              id='AuthEmail'
              error={step2_email_error}
            />
            <ErrorText error={step2_email_error} />
          </div>
          <div>
            <Label
              text={t("landing.auth.form.Create Password")}
              id='Password'
              warning
              warningText={t(
                "landing.form.warningText.password must contain minimum of 6 character"
              )}
            />
            <Input
              value={password}
              setValue={setPassword}
              placeholder={t("landing.auth.form.Enter password")}
              id='Password'
              password
              error={step2_password_error}
            />
            <ErrorText error={step2_password_error} />
          </div>
          <div>
            <TermsAgree value={agree} setValue={setAgree} />
            <ErrorText error={step2_agreement_error} />
            <ErrorText error={step2_user_type_id_error} />
          </div>
        </div>

        <Button
          loading={registerStep2Loading}
          title={t("landing.auth.form.Next")}
          onClick={() => {
            submitHandler();
          }}
        />
      </form>
    </AuthFormWrapper>
  );
};

export default SignUpShipperAuthForm;
