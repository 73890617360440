import React, { Fragment, useState, useEffect } from "react";
import "./EditAirOrder.scss";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { FiCheck } from "react-icons/fi";

import {
  updateAir,
  resetUpdateAir,
  getOrder,
  resetGetOrder,
} from "../../../../../../store/orders/shipperOrders/shipperOrdersSlice";
import { useEditShipperAirError } from "./../../../../../../validations/order/useEditShipperAirError";

import Wrapper from "./../../../../../../componentsAdditional/Wrapper";
import AirTopMenu from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperAir/AirTopMenu/AirTopMenu";
import AirLocation from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperAir/AirLocation/AirLocation";
import AirPackage from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperAir/AirPackage/AirPackage";
import CreateOrderSubmit from "../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderSubmit/CreateOrderSubmit";
import CreateOrderHeader from "../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderHeader/CreateOrderHeader";
import CreateOrderAditionalInformation from "./../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderAditionalInformation/CreateOrderAditionalInformation";
import CreateOrderAditionalServices from "./../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderAditionalServices/CreateOrderAditionalServices";
import LoadingPage from "../../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import PageNotFound from "../../../../../../componentsBids/PageNotFound/PageNotFound";

const EditAirOrder = () => {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const {
    orderLoading,
    orderResponce,
    orderError,
    updateAirLoading,
    updateAirResponce,
    updateAirError,
  } = useSelector((state) => state.shipperOrders);

  const {
    cargos_comment_error,
    cargos_ready_date_error,
    cargos_price_error,
    cargos_price_currency_id_error,
    order_documents_error,
  } = useEditShipperAirError();

  const [group, setGroup] = useState([
    {
      cargoType: null,
      HSCode: false,
      HSCodeValue: "",
      adr: false,
      adrNumber: "",
      adrClass: "",
      cargoTypeError: null,
      HSCodeValueError: null,
      adrError: null,
      adrNumberError: null,
      adrClassError: null,
      dimensions: [
        {
          packingType: null,
          count: 1,
          weight: "",
          length: "",
          width: "",
          height: "",
          packingTypeError: null,
          countError: null,
          weightError: null,
          lengthError: null,
          widthError: null,
          heightError: null,
        },
      ],
    },
  ]);
  const [locations, setLocations] = useState([
    {
      direction: "A",
      inputted_address: "",
      type: "AIRPORT",
      error: null,
    },
    {
      direction: "B",
      inputted_address: "",
      type: "AIRPORT",
      error: null,
    },
  ]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [readyCargoTime, setReadyCargoTime] = useState(null);
  const [cargoPrice, setCargoPrice] = useState("");
  const [cargoPriceCurrency, setCargoPriceCurrency] = useState(
    user
      ? {
          value: user.currency,
          label: user.currency.code,
        }
      : null
  );
  const [comment, setComment] = useState("");

  // get detail order
  useEffect(() => {
    dispatch(getOrder(orderId));
    return () => {
      dispatch(resetGetOrder());
    };
  }, [dispatch, orderId]);

  // edit
  useEffect(() => {
    if (orderResponce && user) {
      setLocations([
        ...orderResponce.cargos[0].locations
          .filter((i) => i.direction === "A")
          .map((i) => ({
            id: i.id,
            direction: "A",
            type: i.type,
            inputted_address: i.formatted_address,
            error: null,
          })),
        ...orderResponce.cargos[0].locations
          .filter((i) => i.direction === "B")
          .map((i) => ({
            id: i.id,
            direction: "B",
            type: i.type,
            inputted_address: i.formatted_address,
            error: null,
          })),
      ]);
      setComment(orderResponce?.cargos[0]?.about || "");
      setCargoPrice(orderResponce?.cargos[0]?.price || "");
      setCargoPriceCurrency({
        label:
          orderResponce?.cargos[0]?.price_currency?.code || user.currency.code,
        value: orderResponce?.cargos[0]?.price_currency || user.currency,
      });
      setReadyCargoTime(
        orderResponce?.cargos[0]?.cargo_ready_date
          ? moment(
              orderResponce?.cargos[0]?.cargo_ready_date,
              "DD/MM/YYYY"
            ).toDate()
          : null
      );
      setSelectedServices(orderResponce?.services || []);

      const newData = orderResponce.cargos.map((item) => ({
        id: item?.id || null,
        cargoType: item.type
          ? {
              value: {
                label: item?.type?.name || "",
                id: item?.type?.id || null,
              },
              label: item?.type?.name || "",
            }
          : null,
        HSCode: item.hs_code ? true : false,
        HSCodeValue: item.hs_code ? item.hs_code : "",
        adr: item.adr ? true : false,
        adrNumber: item.un_number ? item.un_number : "",
        adrClass: item.adr_class ? item.adr_class : "",
        cargoTypeError: null,
        HSCodeValueError: null,
        adrError: null,
        adrNumberError: null,
        adrClassError: null,
        dimensions: [
          {
            id: item?.dimensions[0]?.id || null,
            packingType: item?.dimensions[0]?.packing_type
              ? {
                  value: {
                    label: item?.dimensions[0]?.packing_type?.name || "",
                    id: item?.dimensions[0]?.packing_type?.id || null,
                  },
                  label: item?.dimensions[0]?.packing_type?.name || "",
                }
              : null,
            count: item?.dimensions[0]?.count || 1,
            weight: item?.dimensions[0]?.weight || "",
            length: item?.dimensions[0]?.length || "",
            width: item?.dimensions[0]?.width || "",
            height: item?.dimensions[0]?.height || "",
            packingTypeError: null,
            countError: null,
            weightError: null,
            lengthError: null,
            widthError: null,
            heightError: null,
          },
        ],
      }));
      setGroup(newData);
    }
  }, [orderResponce, user]);

  // catch locations error
  useEffect(() => {
    if (updateAirError?.errors) {
      const updatedLocations = locations.map((location, index) => ({
        ...location,
        error:
          updateAirError.errors[
            `cargos.0.locations.${index}.inputted_address`
          ]?.[0] ?? null,
      }));
      setLocations(updatedLocations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateAirError]);

  // catch cargo errors
  useEffect(() => {
    if (updateAirError?.errors) {
      const updatedGroup = group.map((item, index) => ({
        ...item,
        cargoTypeError:
          updateAirError?.errors?.[`cargos.${index}.type_id`]?.[0] || null,
        HSCodeValueError:
          updateAirError?.errors?.[`cargos.${index}.hs_code`]?.[0] || null,
        adrError: updateAirError?.errors?.[`cargos.${index}.adr`]?.[0] || null,
        adrNumberError:
          updateAirError?.errors?.[`cargos.${index}.un_number`]?.[0] || null,
        adrClassError:
          updateAirError?.errors?.[`cargos.${index}.adr_class`]?.[0] || null,
        adrFileError:
          updateAirError?.errors?.[`cargos.${index}.msds_file`]?.[0] || null,
        dimensions: item.dimensions.map((dimension) => ({
          ...dimension,
          packingTypeError:
            updateAirError?.errors?.[
              `cargos.${index}.dimensions.0.packing_type_id`
            ]?.[0] || null,
          countError:
            updateAirError?.errors?.[
              `cargos.${index}.dimensions.0.count`
            ]?.[0] || null,
          weightError:
            updateAirError?.errors?.[
              `cargos.${index}.dimensions.0.weight`
            ]?.[0] || null,
          lengthError:
            updateAirError?.errors?.[
              `cargos.${index}.dimensions.0.length`
            ]?.[0] || null,
          widthError:
            updateAirError?.errors?.[
              `cargos.${index}.dimensions.0.width`
            ]?.[0] || null,
          heightError:
            updateAirError?.errors?.[
              `cargos.${index}.dimensions.0.height`
            ]?.[0] || null,
        })),
      }));
      setGroup(updatedGroup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateAirError]);

  // reset redux states
  useEffect(() => {
    if (updateAirResponce) {
      dispatch(resetUpdateAir());
      navigate(-1);
    }
  }, [dispatch, navigate, updateAirResponce]);

  // submit
  const submitHandler = () => {
    const data = {
      orderId: orderId,
      transfer_type_id: 1,
      type_id: 5,
      services:
        selectedServices.length > 0 ? selectedServices.map((i) => i.id) : null,

      cargos: group.map((cargo) => ({
        id: cargo?.id || null,
        type_id: cargo.cargoType.value?.id || null,
        locations: locations.map((location) => ({
          id: location?.id || null,
          direction: location?.direction || null,
          type: location?.type || null,
          inputted_address: location?.inputted_address || null,
        })),
        weight:
          cargo?.dimensions[0]?.weight * cargo?.dimensions[0]?.count || "",
        volume:
          ((cargo.dimensions[0].length *
            cargo.dimensions[0].width *
            cargo.dimensions[0].height) /
            1000000) *
          cargo.dimensions[0].count,
        adr: cargo.adr ? true : false,
        un_number: cargo?.adrNumber || null,
        adr_class: cargo?.adrClass || null,
        hs_code: cargo?.HSCodeValue || null,
        about: comment || null,
        price: cargoPrice || null,
        price_currency_id: cargoPriceCurrency?.value?.id || null,
        cargo_ready_date: readyCargoTime
          ? moment(readyCargoTime).format("YYYY-MM-DD")
          : null,
        dimensions: [
          {
            id: cargo?.dimensions[0]?.id || null,
            packing_type_id:
              cargo?.dimensions[0]?.packingType?.value?.id || null,
            count: cargo?.dimensions[0]?.count || null,
            weight: cargo?.dimensions[0]?.weight || null,
            volume:
              ((cargo.dimensions[0].length *
                cargo.dimensions[0].width *
                cargo.dimensions[0].height) /
                1000000) *
              cargo.dimensions[0].count,
            length: cargo?.dimensions[0]?.length || null,
            width: cargo?.dimensions[0]?.width || null,
            height: cargo?.dimensions[0]?.height || null,
          },
        ],
      })),
    };

    dispatch(updateAir(data));
  };

  return (
    <Wrapper documentTitle={t("shipper.titles.Air freight")}>
      {orderLoading && <LoadingPage />}
      {orderError && <PageNotFound />}
      {orderResponce && !orderLoading && (
        <Fragment>
          <AirTopMenu
            locations={locations}
            group={group}
            time={readyCargoTime}
          />
          <section id='ShipperEditOrderAir'>
            <div className='ShipperEditOrderAirWrapper'>
              <CreateOrderHeader />
              <AirLocation locations={locations} setLocations={setLocations} />
              <AirPackage edit group={group} setGroup={setGroup} />
              <CreateOrderAditionalServices
                title={t("shipper.forms.titles.Additional Services")}
                selectedServices={selectedServices}
                setSelectedServices={setSelectedServices}
              />
              <CreateOrderAditionalInformation
                title={t("shipper.forms.titles.Additional information")}
                readyCargoTime={readyCargoTime}
                setReadyCargoTime={setReadyCargoTime}
                cargoPrice={cargoPrice}
                setCargoPrice={setCargoPrice}
                cargoPriceCurrency={cargoPriceCurrency}
                setCargoPriceCurrency={setCargoPriceCurrency}
                comment={comment}
                setComment={setComment}
                //errors
                cargos_comment_error={cargos_comment_error}
                cargos_ready_date_error={cargos_ready_date_error}
                cargos_price_error={cargos_price_error}
                cargos_price_currency_id_error={cargos_price_currency_id_error}
                order_documents_error={order_documents_error}
              />
              <CreateOrderSubmit
                icon={<FiCheck />}
                title={t("shipper.buttons.Save")}
                loading={updateAirLoading}
                onClick={() => submitHandler()}
              />
            </div>
          </section>
        </Fragment>
      )}
    </Wrapper>
  );
};

export default EditAirOrder;
