import React, { Fragment, useState, useEffect } from "react";
import "./AddExpensesModal.scss";

import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  createMaintenance,
  resetCreateMaintenance,
} from "../../../../store/fleet/maintenance/maintenanceSlice";
import {
  getTrucks,
  resetGetTrucks,
} from "./../../../../store/fleet/trucks/trucksSlice";
import {
  getTrailers,
  resetGetTrailers,
} from "./../../../../store/fleet/trailers/trailersSlice";

import { useMaintenancesErrors } from "./../../../../validations/fleet/useMaintenancesErrors";

import DModal from "../../../Modal/DModal/DModal";
import DSelect from "../../../Form/DSelect/DSelect";
import DInputs from "../../../Form/DInputs/DInputs";
import DCheckBox from "../../../Form/DCheckBox/DCheckBox";
import DDatepicker from "../../../Form/DDatepicker/DDatepicker";
import DCurrencyInput from "../../../Form/DCurrencyInput/DCurrencyInput";

const AddExpensesModal = ({ tab, isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { createMaintenanceLoading, createMaintenanceResponce } = useSelector(
    (state) => state.maintenance
  );
  const { getTrucksLoading, getTrucksResponce } = useSelector(
    (state) => state.trucks
  );
  const { getTrailersLoading, getTrailersResponce } = useSelector(
    (state) => state.trailers
  );
  const {
    create_truck_id_error,
    create_trailer_id_error,
    create_service_error,
    create_provider_error,
    create_cost_error,
    create_downtime_start_error,
    create_downtime_end_error,
  } = useMaintenancesErrors();

  const { user } = useSelector((state) => state.auth);
  const { currenciesSelect } = useSelector((state) => state.settings);

  const [activeItem, setActiveItem] = useState(tab ? tab : "truck");
  const [truck, setTruck] = useState(null);
  const [trailer, setTrailer] = useState(null);
  const [service, setService] = useState("");
  const [serviceProvider, setServiceProvider] = useState("");
  const [cost, setCost] = useState("");
  const [currencyValue, setCurrencyValue] = useState(null);
  const [downtimeShow, setDowntimeShow] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const clearState = () => {
    setTruck(null);
    setTrailer(null);
    setService("");
    setServiceProvider("");
    setCost("");
    setStartDate(null);
    setEndDate(null);
  };

  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  const submitHandler = () => {
    let data = {
      service,
      currency_id: currencyValue && currencyValue.value.id,
      provider: serviceProvider,
      cost: cost,
      downtime_start: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
      downtime_end: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
    };
    if (activeItem === "truck") {
      data = { ...data, truck_id: truck && truck.value.id };
    } else {
      data = { ...data, trailer_id: trailer && trailer.value.id };
    }
    dispatch(createMaintenance(data));
  };

  const convertedTruckArray =
    getTrucksResponce &&
    getTrucksResponce.map((truck) => ({
      label: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p style={{ fontSize: "14px", fontWeight: "600", color: "#2b353a" }}>
            {truck.model.brand.name}
          </p>
          <p style={{ fontSize: "12px", color: "#848a8d" }}>
            {truck.registration_number}
          </p>
        </div>
      ),
      value: truck,
    }));

  const convertedTrailersArray =
    getTrailersResponce &&
    getTrailersResponce.map((trailer) => ({
      label: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p style={{ fontSize: "14px", fontWeight: "600", color: "#2b353a" }}>
            {trailer.type.name}
          </p>
          <p style={{ fontSize: "12px", color: "#848a8d" }}>
            {trailer.registration_number}
          </p>
        </div>
      ),
      value: trailer,
    }));

  const filterOptionTruck = (option, inputValue) => {
    if (option) {
      const { value } = option;
      return (
        value?.registration_number
          ?.toLowerCase()
          ?.includes(inputValue?.toLowerCase()) ||
        value?.model?.brand?.name
          ?.toLowerCase()
          ?.includes(inputValue?.toLowerCase())
      );
    }
  };

  const filterOptionTrailer = (option, inputValue) => {
    if (option) {
      const { value } = option;
      return (
        value?.registration_number
          ?.toLowerCase()
          ?.includes(inputValue?.toLowerCase()) ||
        value?.type?.name?.toLowerCase()?.includes(inputValue?.toLowerCase())
      );
    }
  };

  useEffect(() => {
    if (currenciesSelect) {
      const findUserCurrency = currenciesSelect?.find(
        (currency) =>
          currency?.value?.id?.toString() === user?.currency?.id?.toString()
      );
      setCurrencyValue(findUserCurrency);
    }
  }, [currenciesSelect, user]);

  useEffect(() => {
    dispatch(getTrucks());
    dispatch(getTrailers());
    return () => {
      dispatch(resetGetTrucks());
      dispatch(resetGetTrailers());
    };
  }, [dispatch]);

  useEffect(() => {
    if (createMaintenanceResponce) {
      dispatch(resetCreateMaintenance());
      clearState();
      isClose();
    }
  }, [dispatch, isClose, createMaintenanceResponce]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose(false)}
        title={`${t("tms.modals.titles.Add")} ${
          activeItem === "truck"
            ? t("tms.modals.titles.truck")
            : t("tms.modals.titles.trailer")
        } ${t("tms.modals.titles.expense")}`}
        submitText={t("tms.modals.buttons.Save")}
        icon={<FiPlus />}
        status='success'
        onClick={() => submitHandler()}
        loading={createMaintenanceLoading}
      >
        <div id='AddExpensesModal'>
          <div className='switcherContainer'>
            <div
              className={activeItem === "truck" ? "item active" : "item"}
              onClick={() => handleItemClick("truck")}
            >
              <div
                className='cover'
                style={{
                  backgroundImage: `url(${`/assets/images/dashboard/cover8.svg`})`,
                }}
              ></div>
              {t("tms.modals.others.Truck")}
            </div>
            <div
              className={activeItem === "trailer" ? "item active" : "item"}
              onClick={() => handleItemClick("trailer")}
            >
              <div
                className='cover'
                style={{
                  backgroundImage: `url(${`/assets/images/dashboard/cover7.svg`})`,
                }}
              ></div>
              {t("tms.modals.others.Trailer")}
            </div>
          </div>
          <div className='AddExpensesModalGroup'>
            {activeItem === "truck" ? (
              <DSelect
                label={t("tms.modals.labels.Truck")}
                placeholder={t("tms.modals.placeholder.Select truck")}
                id='chooseTruck'
                options={convertedTruckArray ? convertedTruckArray : []}
                value={truck}
                setValue={setTruck}
                required
                loading={getTrucksLoading}
                error={create_truck_id_error}
                search
                filterOption={filterOptionTruck}
              />
            ) : (
              <DSelect
                label={t("tms.modals.labels.Trailer")}
                placeholder={t("tms.modals.placeholder.Select trailer")}
                id='chooseTrailer'
                options={convertedTrailersArray ? convertedTrailersArray : []}
                value={trailer}
                setValue={setTrailer}
                required
                loading={getTrailersLoading}
                error={create_trailer_id_error}
                search
                filterOption={filterOptionTrailer}
              />
            )}

            <DInputs
              label={t("tms.modals.labels.Service")}
              placeholder={t("tms.modals.placeholder.Enter service")}
              value={service}
              setValue={setService}
              id='chooseService'
              required
              error={create_service_error}
            />
            <DInputs
              id='serviceProvider'
              label={t("tms.modals.labels.Service provider")}
              placeholder={t("tms.modals.placeholder.Enter service provider")}
              value={serviceProvider}
              setValue={setServiceProvider}
              required
              error={create_provider_error}
            />
            <DCurrencyInput
              id='expenseCost'
              label={t("tms.modals.labels.Cost")}
              placeholder={t("tms.modals.placeholder.Enter cost")}
              value={cost}
              setValue={setCost}
              currencyValue={currencyValue}
              setCurrencyValue={setCurrencyValue}
              required
              error={create_cost_error}
            />
            <div className='groupItem'>
              <DCheckBox
                value={downtimeShow}
                setValue={setDowntimeShow}
                label={t("tms.modals.labels.Downtime")}
              />
              {downtimeShow && (
                <div className='downtimeContainer'>
                  <DDatepicker
                    label={t("tms.modals.labels.Start date")}
                    placeholder={t("tms.modals.placeholder.Start date")}
                    value={startDate}
                    setValue={setStartDate}
                    error={create_downtime_start_error}
                  />
                  <DDatepicker
                    label={t("tms.modals.labels.End date")}
                    placeholder={t("tms.modals.placeholder.End date")}
                    value={endDate}
                    setValue={setEndDate}
                    error={create_downtime_end_error}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default AddExpensesModal;
