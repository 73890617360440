import React, { Fragment } from "react";
import "./OrdersSidebar.scss";

import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import InfiniteLoading from "../UI/InfiniteLoading/InfiniteLoading";
import NoMoreData from "../UI/NoMoreData/NoMoreData";
import OrdersSidebarItem from "./OrdersSidebarItem/OrdersSidebarItem";
import NoData from "./../UI/NoData/NoData";

const OrdersSidebar = ({
  page,
  setPage,
  activeOrderId,
  setActiveOrderId,
  activeOrderIndex,
  setActiveOrderIndex,
}) => {
  const { ordersLoading, orders, ordersMeta } = useSelector(
    (state) => state.shipperAccepted
  );

  const hasMore = ordersMeta && ordersMeta.current_page < ordersMeta.last_page;

  const handleLoadMore = () => {
    if (hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <div id='ShipperOrderListCards'>
      {ordersLoading && page === 1 && <InfiniteLoading />}

      {ordersMeta && orders && (
        <Fragment>
          {ordersMeta.total === 0 ? (
            <NoData />
          ) : (
            <Fragment>
              <InfiniteScroll
                dataLength={orders.length}
                next={handleLoadMore}
                hasMore={hasMore}
                loader={<InfiniteLoading />}
                endMessage={<NoMoreData />}
                height={`calc(100vh - 330px)`}
                initialScrollY={activeOrderIndex ? 152 * activeOrderIndex : 0}
              >
                <ul className='orderList'>
                  {orders.map((order, index) => (
                    <OrdersSidebarItem
                      key={order.id}
                      index={index}
                      order={order}
                      activeOrderId={activeOrderId}
                      setActiveOrderId={setActiveOrderId}
                      activeOrderIndex={activeOrderIndex}
                      setActiveOrderIndex={setActiveOrderIndex}
                    />
                  ))}
                </ul>
              </InfiniteScroll>
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default OrdersSidebar;
