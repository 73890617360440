import React from "react";
import "./FinancialFilter.scss";

import { useTranslation } from "react-i18next";
import moment from "moment";

import FiltersContainer from "../../FilterForms/FiltersContainer/FiltersContainer";
import FilterRouteSelectForm from "./../../FilterForms/FilterRouteSelectForm/FilterRouteSelectForm";
import FilterPriceRangeForm from "../../FilterForms/FilterPriceRangeForm/FilterPriceRangeForm";
import DSearch from "./../../FilterForms/DSearch/DSearch";
import FilterShipperFinancialStatusesClips from "../../FilterForms/FilterShipperFinancialStatusesClips/FilterShipperFinancialStatusesClips";
import FilterDateRangeForm from "../../FilterForms/FilterDateRangeForm/FilterDateRangeForm";

const FinancialFilter = ({
  clear,
  keyword,
  setKeyword,
  route,
  setRoute,
  price,
  setPrice,
  status,
  setStatus,
  date,
  setDate,
}) => {
  const { t } = useTranslation();
  return (
    <div id='FinancialFilter'>
      <DSearch value={keyword} setValue={setKeyword} />
      <div className='filtersContainer'>
        <FiltersContainer onClear={clear}>
          <FilterDateRangeForm
            label={t("shipper.filters.Deadline")}
            value={
              Object.keys(date).length > 0
                ? `${
                    date.startDate
                      ? moment(date.startDate).format("DD/MM/YYYY")
                      : ""
                  } - ${
                    date.endDate
                      ? moment(date.endDate).format("DD/MM/YYYY")
                      : ""
                  }`
                : ""
            }
            setValue={setDate}
          />
          <FilterRouteSelectForm
            value={
              Object.keys(route).length > 0
                ? `${route?.fromCountry?.value?.code || ""} - ${
                    route?.toCountry?.value?.code || ""
                  }`
                : ""
            }
            setValue={setRoute}
          />
          <FilterPriceRangeForm
            placeholder={t("shipper.filters.Amount")}
            value={
              Object.keys(price).length > 0
                ? `${price?.minPrice} - ${price?.maxPrice}`
                : ""
            }
            setValue={setPrice}
          />
        </FiltersContainer>
      </div>
      <div className='statusWrapper'>
        <FilterShipperFinancialStatusesClips
          status={status}
          setStatus={setStatus}
        />
      </div>
    </div>
  );
};

export default FinancialFilter;
