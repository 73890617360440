import React, { useState, useEffect } from "react";
import "./FinancialManagement.scss";

import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import moment from "moment";

import {
  getFinancialOrders,
  resetGetFinancialOrders,
} from "./../../../../store/orders/shipperFinancial/shipperFinancialSlice";

import Wrapper from "../../../../componentsAdditional/Wrapper";
import MainTitle from "../../../../componentsDashboard/MainTitle/MainTitle";
import FinancialFilter from "../../../../componentsDashboard/ShipperFinancial/FinancialFilter/FinancialFilter";
import LoadingPage from "../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import FinancialTable from "../../../../componentsDashboard/ShipperFinancial/FinancialTable/FinancialTable";
import AccountantEmail from "../../../../componentsDashboard/ShipperFinancial/AccountantEmail/AccountantEmail";

const FinancialManagement = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const {
    getFinancialOrdersLoading,
    getFinancialOrdersResponce,
    getFinancialOrdersMeta,
  } = useSelector((state) => state.shipperFinancial);

  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1
  );
  const [keyword, setKeyword] = useState("");
  const [route, setRoute] = useState({});
  const [price, setPrice] = useState({});
  const [status, setStatus] = useState({ id: null, label: "All", value: null });
  const [date, setDate] = useState({});

  const handleClearFilters = () => {
    if (keyword) setKeyword("");
    if (Object.keys(route).length > 0) setRoute({});
    if (Object.keys(price).length > 0) setPrice({});
    if (status?.id) setStatus({ id: null, label: "All", value: null });
    if (Object.keys(date).length > 0) setDate({});
  };

  useEffect(() => {
    const data = {
      page,
      keyword,
      payment_status: status?.value,
      pickup_address: route?.fromCountry?.label,
      dropoff_address: route?.toCountry?.label,
      min_price: price?.minPrice,
      max_price: price?.maxPrice,
      start_date:
        Object.keys(date).length > 0 && date?.startDate
          ? moment(date.startDate).format("DD/MM/YYYY")
          : "",
      end_date:
        Object.keys(date).length > 0 && date?.endDate
          ? moment(date.endDate).format("DD/MM/YYYY")
          : "",
    };
    dispatch(getFinancialOrders(data));
    return () => {
      dispatch(resetGetFinancialOrders());
    };
  }, [dispatch, keyword, page, status, route, price, date]);

  return (
    <Wrapper documentTitle={t("shipper.titles.Billing")}>
      <section id='ShipperFinancialManagement'>
        <div className='ShipperFinancialManagementHeader'>
          <MainTitle title={t("shipper.titles.Billing")} />
          <AccountantEmail />
        </div>
        <FinancialFilter
          clear={handleClearFilters}
          keyword={keyword}
          setKeyword={setKeyword}
          route={route}
          setRoute={setRoute}
          price={price}
          setPrice={setPrice}
          status={status}
          setStatus={setStatus}
          date={date}
          setDate={setDate}
        />
        {getFinancialOrdersLoading ? (
          <LoadingPage />
        ) : (
          getFinancialOrdersResponce && (
            <FinancialTable
              items={getFinancialOrdersResponce}
              page={page}
              setPage={setPage}
              pageCount={getFinancialOrdersMeta.last_page}
              total={getFinancialOrdersMeta.total}
            />
          )
        )}
      </section>
    </Wrapper>
  );
};

export default FinancialManagement;
