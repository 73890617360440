import React from "react";
import "./RoleRadio.scss";

import { useTranslation } from "react-i18next";

import { FiCheck } from "react-icons/fi";

const RoleRadio = ({ value, setValue, labelOne, labelTwo }) => {
  const { t } = useTranslation();

  return (
    <div id='RoleRadio'>
      <div className={value === "SHIPPER" ? "active item" : "item"}>
        <div
          className='icon'
          onClick={() => {
            setValue("SHIPPER");
          }}
        >
          <FiCheck />
        </div>
        <p
          onClick={() => {
            setValue("SHIPPER");
          }}
        >
          {labelOne ? labelOne : t("landing.form.label.I’m a shipper")}
        </p>
      </div>
      <div className={value === "CARRIER" ? "active item" : "item"}>
        <div
          className='icon'
          onClick={() => {
            setValue("CARRIER");
          }}
        >
          <FiCheck />
        </div>
        <p
          onClick={() => {
            setValue("CARRIER");
          }}
        >
          {labelTwo ? labelTwo : t("landing.form.label.I’m a carrier")}
        </p>
      </div>
    </div>
  );
};

export default RoleRadio;
