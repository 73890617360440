import React from "react";
import "./CreateOrderLocation.scss";

import { useTranslation } from "react-i18next";

import {
  MdOutlineRadioButtonUnchecked,
  MdOutlineRadioButtonChecked,
} from "react-icons/md";

import DInputs from "../../../../Form/DInputs/DInputs";
import CreateOrderSectionTitle from "../CreateOrderSectionTitle/CreateOrderSectionTitle";

const CreateOrderLocation = ({
  type,
  locations,
  setLocations,
  locationADesc,
  locationBDesc,
  title,
}) => {
  const { t } = useTranslation();
  const setLocationAHandler = (value) => {
    const updatedGroup = [...locations];
    updatedGroup[0].inputted_address = value;
    setLocations(updatedGroup);
  };

  const setLocationAdescriptionHandler = (value) => {
    const updatedGroup = [...locations];
    updatedGroup[0].type = value;
    setLocations(updatedGroup);
  };

  const setLocationBHandler = (value) => {
    const updatedGroup = [...locations];
    updatedGroup[1].inputted_address = value;
    setLocations(updatedGroup);
  };

  const setLocationBdescriptionHandler = (value) => {
    const updatedGroup = [...locations];
    updatedGroup[1].type = value;
    setLocations(updatedGroup);
  };

  return (
    <div id='ShipperCreateOrderLocation'>
      <CreateOrderSectionTitle title={title} />
      <div className='addressConatiner'>
        <div className='addressGroup'>
          <h4 className='title'>{t("shipper.forms.content.From")}</h4>
          {locationADesc.map((i, index) => (
            <div
              key={index}
              className={
                locations[0].type === i.type ? "radioItem active" : "radioItem"
              }
              onClick={() => setLocationAdescriptionHandler(i.type)}
            >
              <div className='icon'>
                {locations[0].type === i.type ? (
                  <MdOutlineRadioButtonChecked />
                ) : (
                  <MdOutlineRadioButtonUnchecked />
                )}
              </div>
              <p>{i.name}</p>
            </div>
          ))}

          <DInputs
            id='ShipperAirLocationFromAddress'
            value={locations[0].inputted_address}
            setValue={(value) => setLocationAHandler(value)}
            placeholder={t("shipper.forms.content.Address to pick up")}
            icon={
              locations[0].type === type
                ? locationADesc[0].icon
                : locationADesc[1].icon
            }
            error={locations[0].error}
          />
        </div>

        <div className='addressGroup'>
          <h4 className='title'>{t("shipper.forms.content.To")}</h4>
          {locationBDesc.map((i, index) => (
            <div
              key={index}
              className={
                locations[1].type === i.type ? "radioItem active" : "radioItem"
              }
              onClick={() => setLocationBdescriptionHandler(i.type)}
            >
              <div className='icon'>
                {locations[1].type === i.type ? (
                  <MdOutlineRadioButtonChecked />
                ) : (
                  <MdOutlineRadioButtonUnchecked />
                )}
              </div>
              <p>{i.name}</p>
            </div>
          ))}

          <DInputs
            id='ShipperAirLocationFromAddress'
            value={locations[1].inputted_address}
            setValue={(value) => setLocationBHandler(value)}
            placeholder={t("shipper.forms.content.Address to drop off")}
            icon={
              locations[1].type === type
                ? locationBDesc[0].icon
                : locationBDesc[1].icon
            }
            error={locations[1].error}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateOrderLocation;
