import React from "react";
import "./OceanTypeSwitcher.scss";

const OceanTypeSwitcher = ({
  ORDERTYPES,
  order_type_id,
  set_order_type_id,
}) => {
  return (
    <div id='OceanTypeSwitcher'>
      {ORDERTYPES.map((item) => (
        <div
          key={item.id}
          className={`typeSwitcherItem${item.id === 3 ? "Left" : "Right"} ${
            order_type_id === item.id ? "active" : ""
          }`}
          onClick={() => set_order_type_id(item.id)}
        >
          {item.name}
        </div>
      ))}
    </div>
  );
};

export default OceanTypeSwitcher;
