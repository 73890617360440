import React from "react";

import NameField from "../NameField/NameField";

const LocationFieldLoadBoards = ({ item }) => {
  const getLocation = (item, direction) => {
    const locationData =
      item && item.cargos[0].locations.filter((i) => i.direction === direction);

    const country = locationData && locationData[0]?.country;
    const city = locationData && locationData[0]?.city;

    return `${city ? city + ", " : ""}${country || ""}`;
  };
  const Alocation = getLocation(item, "A");
  const Blocation = getLocation(item, "B");
  const location = `${Alocation} - ${Blocation}`;

  return (
    <NameField
      max={60}
      label={location}
      to={`/dashboard/tms/load_boards/${item.id}`}
    />
  );
};

export default LocationFieldLoadBoards;
