import React from "react";
import "./ShipperCardsContainer.scss";

import { useMoney } from "../../../../helper/useMoney";
import { useNumberFormatter } from "../../../../helper/useNumberFormatter";
import { useTranslation } from "react-i18next";

import ShipperCardsContainerItem from "./ShipperCardsContainerItem/ShipperCardsContainerItem";

const ShipperCardsContainer = ({ analyticsData, user }) => {
  const { t } = useTranslation();
  const userCurrency = user?.currency?.symbol;
  const FormattedTotalCharges = useMoney(
    analyticsData?.expense || 0,
    userCurrency,
    0
  );
  const FormattedAverageCharge = useMoney(
    analyticsData?.average_charge_per_shipment || 0,
    userCurrency,
    0
  );
  const FormattedTotalWeight = useNumberFormatter(
    analyticsData?.total_weight || 0
  );
  const FormattedAverageWeight = useNumberFormatter(
    analyticsData?.average_weight_per_shipment || 0
  );
  const FormattedTotalDistance = useNumberFormatter(
    analyticsData?.total_distance || 0
  );
  const FormattedAverageDistance = useNumberFormatter(
    analyticsData?.average_distance_per_shipment || 0
  );

  return (
    <div id='ShipperCardsContainer'>
      <div className='ShipperCardsContainerRow'>
        <ShipperCardsContainerItem
          title={t("shipper.titles.Total charges")}
          number={FormattedTotalCharges}
          progress={analyticsData?.expense_increase_percent || "0"}
        />
        <ShipperCardsContainerItem
          title={t("shipper.titles.Average charge per shipment")}
          number={FormattedAverageCharge}
        />
      </div>
      <div className='ShipperCardsContainerRow'>
        <ShipperCardsContainerItem
          title={t("shipper.titles.Total shipped weight")}
          number={`${FormattedTotalWeight} ${t("shipper.units.KG")}`}
          progress={analyticsData?.weight_percent || "0"}
        />
        <ShipperCardsContainerItem
          title={t("shipper.titles.Average weight per shipment")}
          number={`${FormattedAverageWeight} ${t("shipper.units.KG")}`}
        />
      </div>
      <div className='ShipperCardsContainerRow'>
        <ShipperCardsContainerItem
          title={t("shipper.titles.Total distance")}
          number={`${FormattedTotalDistance} ${t("shipper.units.KM")}`}
          progress={analyticsData?.distance_percent || "0"}
        />
        <ShipperCardsContainerItem
          title={t("shipper.titles.Average distance")}
          number={`${FormattedAverageDistance} ${t("shipper.units.KM")}`}
        />
      </div>
    </div>
  );
};

export default ShipperCardsContainer;
