import React, { Fragment } from "react";
import "./FilterStatusesClips.scss";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const FilterStatusesClips = ({ status, setStatus }) => {
  const { t } = useTranslation();
  const { getCargoStatusesResponce } = useSelector((state) => state.cargos);

  let filteredStatuses = getCargoStatusesResponce?.filter((status) => status?.listed);
  if (filteredStatuses === undefined) {
    filteredStatuses = [];
  }
  filteredStatuses = [{ id: null, name: t("shipper.filters.All") }, ...filteredStatuses];

  return (
    <Fragment>
      {getCargoStatusesResponce && (
        <div id='FilterStatusesClips'>
          {filteredStatuses.map((i, index) => (
            <div
              key={index}
              className={i.id === status.id ? "status active" : "status"}
              onClick={() => setStatus(i)}
            >
              <h4>{i.name}</h4>
            </div>
          ))}
        </div>
      )}
    </Fragment>
  );
};

export default FilterStatusesClips;
