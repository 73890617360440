import React from "react";
import "./OceanTrailerDetails.scss";

import { useTranslation } from "react-i18next";

import { GiCargoCrate } from "react-icons/gi";

const OceanTrailerDetails = ({ orderResponce }) => {
  const { t } = useTranslation();

  const container = orderResponce?.cargos?.[0]?.trailer_type?.name;
  const temperature = orderResponce?.cargos?.[0]?.temperature?.title;
  const perWeight = orderResponce?.cargos?.[0]?.weight;
  const quantity = orderResponce?.cargos?.length;

  return (
    <div id='OceanTrailerDetails'>
      <div className='titleContainer'>
        <div className='icon'>
          <img src='/assets/images/dashboard/cover7.svg' alt='container' />
        </div>
        <h1 className='title'>
          {t("shipper.forms.titles.Container Requirements")}
        </h1>
      </div>
      <div className='OceanTrailerDetailsShipperContainer'>
        <div className='OceanTrailerDetailsShipperCover'>
          <div className='OceanTrailerDetailsShipperImg'>
            <GiCargoCrate />
          </div>
        </div>
        <div className='OceanTrailerDetailsShippercontent'>
          {container && (
            <p>
              {t("shipper.forms.content.Container")}: <span>{container}</span>
            </p>
          )}
          {temperature && (
            <p>
              {t("shipper.forms.content.Temperature")}:{" "}
              <span>{temperature}</span>
            </p>
          )}
          {perWeight && (
            <p>
              {t("shipper.forms.content.Per weight")}: <span>{perWeight}</span>
            </p>
          )}
          {quantity && (
            <p>
              {t("shipper.forms.content.Quantity")}: <span>{quantity}</span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default OceanTrailerDetails;
