import React from "react";
import "./RoadWeightGroupItem.scss";

import { useTranslation } from "react-i18next";

import DInputs from "../../../../../../../Form/DInputs/DInputs";

const RoadWeightGroupItem = ({ index, item, orders, setOrders }) => {
  const { t } = useTranslation();
  const setWeightHandler = (value) => {
    const updatedOrders = [...orders];
    updatedOrders[index].weight = value;
    setOrders(updatedOrders);
  };

  const trailerType = item.trailerType ? item.trailerType.label : "";
  const trailerVolume = item.trailerSize ? item.trailerSize.label : "";

  return (
    <div id='RoadWeightGroupItem'>
      <h4>
        #{index + 1}{" "}
        <span>
          {trailerType} {trailerVolume}
        </span>
      </h4>

      <div className='formGroup'>
        <div className='formGrouptem'>
          <DInputs
            type='number'
            value={item.weight}
            setValue={(value) => setWeightHandler(value)}
          />
          <h6>{t("shipper.units.KG")}</h6>
        </div>
      </div>
    </div>
  );
};

export default RoadWeightGroupItem;
