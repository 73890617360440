import React from "react";
import { Routes, Route } from "react-router-dom";

import {
  Dashboard,
  AddOrder,
  EditOrder,
  OrderList,
  OrderDetails,
  CustomerDetails,
  CustomersList,
  PartnersList,
  PartnerDetails,
  TruckList,
  TrailerList,
  DriverList,
  MaintenanceList,
  TruckDetails,
  TrailerDetails,
  Profile,
  BoardsList,
  BidsList,
  BoardDetails,
  Notifications,
  ActiveList,
  FinishedList,
  TrackerMap,
  GPS,
  Mobile,
} from "../pages/Dashboard/Company/index";
import DelayedRedirect from "../componentsAdditional/DelayedRedirect";

const Company = () => {
  return (
    <Routes>
      <Route path='/dashboard/tms/home' element={<Dashboard />} />

      <Route path='/dashboard/tms/profile' element={<Profile />} />

      <Route path='/dashboard/tms/orders'>
        <Route index element={<OrderList />} />
        <Route path='new' element={<AddOrder />} />
        <Route path='edit/:orderId' element={<EditOrder />} />
        <Route path=':orderId' element={<OrderDetails />} />
      </Route>

      <Route path='/dashboard/tms/load_boards'>
        <Route index element={<BoardsList />} />
        <Route path='bids' element={<BidsList />} />
        <Route path=':boardId' element={<BoardDetails />} />
      </Route>

      <Route path='/dashboard/tms/customers_partners'>
        <Route path='customers'>
          <Route index element={<CustomersList />} />
          <Route path=':customerId' element={<CustomerDetails />} />
        </Route>
        <Route path='partners'>
          <Route index element={<PartnersList />} />
          <Route path=':partnerId' element={<PartnerDetails />} />
        </Route>
      </Route>

      <Route path='/dashboard/tms/financial'>
        <Route path='active' index element={<ActiveList />} />
        <Route path='finished' element={<FinishedList />} />
      </Route>

      <Route path='/dashboard/tms/fleet_management'>
        <Route path='trucks'>
          <Route index element={<TruckList />} />
          <Route path=':truckId' element={<TruckDetails />} />
        </Route>
        <Route path='trailers'>
          <Route index element={<TrailerList />} />
          <Route path=':trailerId' element={<TrailerDetails />} />
        </Route>
        <Route path='drivers'>
          <Route index element={<DriverList />} />
        </Route>
        <Route path='maintenance'>
          <Route index element={<MaintenanceList />} />
        </Route>
      </Route>

      <Route path='/dashboard/tms/notifications' element={<Notifications />} />

      <Route path='/dashboard/tms/tracking' element={<TrackerMap />} />
      <Route path='/dashboard/tms/tracker/GPS_tracking' element={<GPS />} />
      <Route
        path='/dashboard/tms/tracker/mobile_tracking'
        element={<Mobile />}
      />

      <Route
        path='*'
        element={<DelayedRedirect to='/dashboard/tms/home' delay={0} />}
      />
    </Routes>
  );
};

export default Company;
