import React, { Fragment, useState } from "react";
import "./Subscribe.scss";

import { useTranslation } from "react-i18next";

import Wrapper from "../../../../componentsAdditional/Wrapper";
import CoverCarrierSubscribe from "../../../../componentsLanding/Landing/Covers/CoverCarrierSubscribe/CoverCarrierSubscribe";
import WhyUs from "../../../../componentsLanding/Landing/SubscribeCarrier/WhyUs/WhyUs";
import CarrierSubscribeForm from "../../../../componentsLanding/Landing/SubscribeCarrier/CarrierSubscribeForm/CarrierSubscribeForm";
import Button from "../../../../componentsLanding/Form/Button/Button";
import SubscribeModal from "../../../../componentsLanding/Landing/SubscribeCarrier/SubscribeModal/SubscribeModal";

const Subscribe = () => {
  const [showModal, setShowModal] = useState(false);

  const { t } = useTranslation();
  return (
    <Fragment>
      <Wrapper documentTitle={t("landing.documentTitles.Subscribe")}>
        <div id='CarrierSubscribe'>
          <CoverCarrierSubscribe />
          <div className='bottomContent'>
            <WhyUs />
            <div className='CarrierSubscribeForm'>
              <CarrierSubscribeForm />
            </div>
            <div className='subscribeModalButton'>
              <Button
                title={t("landing.landingCarrierSubscribe.Want to subscribe?")}
                onClick={() => setShowModal(true)}
              />
            </div>
          </div>
        </div>
      </Wrapper>
      <SubscribeModal isOpen={showModal} isClose={() => setShowModal(false)} />
    </Fragment>
  );
};

export default Subscribe;
