import React, { Fragment, useEffect } from "react";
import "./OrderDetails.scss";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  getOrder,
  resetGetOrder,
} from "../../../../../store/orders/ordersSlice";

import Wrapper from "../../../../../componentsAdditional/Wrapper";
import OrderDetailsHeader from "../../../../../componentsDashboard/Orders/OrderDetailsHeader/OrderDetailsHeader";
import FinancialDetails from "../../../../../componentsDashboard/Orders/FinancialDetails/FinancialDetails";
import SimilarOrdersNavbar from "../../../../../componentsDashboard/Orders/SimilarOrdersNavbar/SimilarOrdersNavbar";
import LoadingPage from "../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import OrderMapImage from "../../../../../componentsDashboard/Orders/OrderMapImage/OrderMapImage";
import SentUpdatesDetails from "../../../../../componentsDashboard/Orders/SentUpdatesDetails/SentUpdatesDetails";
import OrderStatusBar from "../../../../../componentsDashboard/Orders/OrderStatusBar/OrderStatusBar";
import DocumentsDetails from "../../../../../componentsDashboard/Orders/DocumentsDetails/DocumentsDetails";
import FilledAssignCarrier from "./../../../../../componentsDashboard/Orders/ManageOrder/FilledAssignCarrier/FilledAssignCarrier";
import AssignCarrier from "./../../../../../componentsDashboard/Orders/ManageOrder/AssignCarrier/AssignCarrier";
import PageNotFound from "./../../../../../componentsBids/PageNotFound/PageNotFound";
import PartnerRate from "../../../../../componentsDashboard/Orders/PartnerRate/PartnerRate";
import OrderInformationDetails from "../../../../../componentsDashboard/Orders/OrderInformationDetails/OrderInformationDetails";

const OrderDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orderId } = useParams();

  const { orderLoading, orderResponce, orderError } = useSelector(
    (state) => state.orders
  );
  const { unassignCarrierResponce, assignCarrierResponce } = useSelector(
    (state) => state.manageOrder
  );
  const { updateCargoStatusesResponce } = useSelector((state) => state.cargos);
  const {
    createOrderCostResponce,
    updateOrderCostResponce,
    deleteOrderCostResponce,
  } = useSelector((state) => state.orderCosts);
  const { createInvoiceResponce } = useSelector(
    (state) => state.orderDocuments
  );
  const {
    createAdvanceResponce,
    updateAdvanceResponce,
    deleteAdvanceResponce,
    markPaidResponce,
  } = useSelector((state) => state.advance);

  const { ratePartnerResponce } = useSelector((state) => state.partner);
  const { createOrderDocumentResponce, deleteOrderDocumentResponce } =
    useSelector((state) => state.orderDocuments);

  useEffect(() => {
    dispatch(getOrder(orderId));
    return () => {
      dispatch(resetGetOrder());
    };
  }, [
    dispatch,
    orderId,
    updateCargoStatusesResponce,
    assignCarrierResponce,
    unassignCarrierResponce,
    createOrderCostResponce,
    updateOrderCostResponce,
    deleteOrderCostResponce,
    createAdvanceResponce,
    updateAdvanceResponce,
    deleteAdvanceResponce,
    markPaidResponce,
    createInvoiceResponce,
    ratePartnerResponce,
    createOrderDocumentResponce,
    deleteOrderDocumentResponce,
  ]);

  return (
    <Wrapper documentTitle={t("tms.documentTitles.Manage tracking")}>
      {orderLoading && <LoadingPage />}
      {orderError && <PageNotFound />}
      {orderResponce && !orderLoading && (
        <section id='OrderDetailsTMC'>
          <OrderDetailsHeader order={orderResponce} Delete Edit />
          <SimilarOrdersNavbar groups={orderResponce.groups} />
          <OrderStatusBar order={orderResponce} />
          <Fragment>
            <div className='trakingContainer'>
              <div className='left'>
                <PartnerRate order={orderResponce} />
                <AssignCarrier order={orderResponce} />
                <FilledAssignCarrier order={orderResponce} />
                <FinancialDetails order={orderResponce} />
                <OrderInformationDetails orderResponce={orderResponce} />
              </div>
              <div className='right'>
                <OrderMapImage order={orderResponce} />
                <DocumentsDetails orderId={orderId} order={orderResponce} />
                <SentUpdatesDetails order={orderResponce} />
              </div>
            </div>
          </Fragment>
        </section>
      )}
    </Wrapper>
  );
};

export default OrderDetails;
