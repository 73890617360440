import React from "react";
import "./AditionalInformation.scss";

import { useTranslation } from "react-i18next";

import DTextarea from "./../../../../Form/DTextarea/DTextarea";
import NewOrderTitle from "./../Components/NewOrderTitle/NewOrderTitle";

const AditionalInformation = ({ comment, setComment }) => {
  const { t } = useTranslation();

  return (
    <div id='AditionalInformationNewOrder'>
      <NewOrderTitle title={t("tms.titles.Additional information")} />

      <DTextarea
        id='leaveComment'
        value={comment}
        setValue={setComment}
        placeholder={t("tms.forms.placeholder.Your text ...")}
        error={null}
      />
    </div>
  );
};

export default AditionalInformation;
