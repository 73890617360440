import React, { Fragment } from "react";
import "./NavItem.scss";

import { IoIosArrowForward } from "react-icons/io";
import { FaLocationPin } from "react-icons/fa6";

import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import TooltipItem from "../TooltipItem/TooltipItem";

const NavItem = ({ to, activePath, icon, text, menu }) => {
  const { pathname } = useLocation();
  const { openSidebar } = useSelector((state) => state.userUI);

  let classname = "";
  if (
    pathname.startsWith(activePath) ||
    (menu && menu.some((item) => pathname.startsWith(item.activePath)))
  ) {
    classname = "active";
  }

  return (
    <Fragment>
      {menu ? (
        <li id='DNavItem'>
          <Link to={to} className={classname}>
            <TooltipItem title={!openSidebar && text}>
              <div id='DNavItemWrapper' className={openSidebar ? "max" : "min"}>
                <div className='DNavItemWrapperMainIcon'>{icon}</div>
                <div className='text menu'>
                  <h3>{text}</h3>
                  {!classname && (
                    <div className={classname ? "arrowIcon show" : "arrowIcon"}>
                      <IoIosArrowForward />
                    </div>
                  )}
                </div>
              </div>
            </TooltipItem>
          </Link>
          {menu.map((item, index) => (
            <TooltipItem title={!openSidebar && item.text} key={index}>
              <Link
                to={item.to}
                id='DNavItemSubMenu'
                className={
                  menu.some((item) => pathname.startsWith(item.activePath)) &&
                  "show"
                }
              >
                <div
                  className={`text ${
                    pathname.startsWith(item.activePath) && "active"
                  }`}
                >
                  <div
                    className={
                      !openSidebar
                        ? "DNavItemSubMenuIcon show"
                        : "DNavItemSubMenuIcon"
                    }
                  >
                    {item.icon ? item.icon : <FaLocationPin />}
                  </div>

                  <h3 className={openSidebar ? "max" : "min"}>{item.text}</h3>
                </div>
              </Link>
            </TooltipItem>
          ))}
        </li>
      ) : (
        <TooltipItem title={(text.length > 23 || !openSidebar) && text}>
          <li id='DNavItem'>
            <Link to={to} className={classname}>
              <div id='DNavItemWrapper' className={openSidebar ? "max" : "min"}>
                <div className='DNavItemWrapperMainIcon'>{icon}</div>
                <div className='text'>
                  <h3>
                    {text.length < 22 ? text : text.substring(0, 20) + "..."}
                  </h3>
                </div>
              </div>
            </Link>
          </li>
        </TooltipItem>
      )}
    </Fragment>
  );
};

export default NavItem;
