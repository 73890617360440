import React, { Fragment, useState, useEffect } from "react";
import "./SelectDriverModal.scss";

import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";

import {
  getDrivers,
  resetGetDrivers,
} from "../../../../store/fleet/drivers/driversSlice";

import DSearch from "./../../../FilterForms/DSearch/DSearch";
import DTextButton from "../../../Form/DButtons/DTextButton/DTextButton";
import DOverlay from "../../../Modal/DOverlay/DOverlay";
import AddDriversModal from "../AddDriversModal/AddDriversModal";
import SelectDriverModalHeader from "./SelectDriverModalHeader/SelectDriverModalHeader";
import SelectDriverModalTable from "./SelectDriverModalTable/SelectDriverModalTable";
import LoadingPage from "../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import FilterLinkedSelect from "../../../FilterForms/FilterLinkedSelect/FilterLinkedSelect";

const SelectDriverModal = ({ isOpen, isClose, truck }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getDriversLoading, getDriversResponce, createDriverResponce } =
    useSelector((state) => state.drivers);

  const [openAddDriverModal, setOpenAddDriverModal] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [linkedTo, setLinkedTo] = useState(null);

  const truckName =
    truck && truck.model && truck.model.brand.name + " " + truck.model.name;

  useEffect(() => {
    if (isOpen) {
      dispatch(
        getDrivers({
          keyword,
          linked_to: linkedTo?.value,
        })
      );
    }
    return () => {
      dispatch(resetGetDrivers());
    };
  }, [dispatch, isOpen, createDriverResponce, keyword, linkedTo]);

  return (
    <DOverlay isOpen={isOpen} isClose={isClose}>
      <div id='SelectDriverModal'>
        <div className='SelectDriverModalcontent'>
          <SelectDriverModalHeader truckName={truckName} isClose={isClose} />

          <Fragment>
            <div className='SelectDriverModalTableHeader'>
              <div className='SelectDriverModalTableHeaderFilters'>
                <DSearch value={keyword} setValue={setKeyword} />
                <FilterLinkedSelect value={linkedTo} setValue={setLinkedTo} />
              </div>
              <DTextButton
                title={t("tms.forms.buttons.Add driver")}
                rightIcon={<FiPlus />}
                onClick={() => {
                  setOpenAddDriverModal(true);
                }}
              />
            </div>
            {getDriversLoading && <LoadingPage />}
            {getDriversResponce && (
              <SelectDriverModalTable
                truckId={truck.id}
                drivers={getDriversResponce}
              />
            )}
          </Fragment>
        </div>
        <AddDriversModal
          isOpen={openAddDriverModal}
          isClose={setOpenAddDriverModal}
        />
      </div>
    </DOverlay>
  );
};

export default SelectDriverModal;
