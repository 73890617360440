import React from "react";
import "./TrailerDetails2.scss";

import { useTranslation } from "react-i18next";
import { PiTruck } from "react-icons/pi";

const TrailerDetails2 = ({ orderResponce }) => {
  const { t } = useTranslation();
  const cover = orderResponce?.trailer_type?.icon || "";
  const trailerType = orderResponce?.trailer_type?.name || "";
  const trailerVolume = orderResponce?.trailer_size?.label || "";
  const trailerTemperature =
    orderResponce?.trailer_type?.temperature &&
    orderResponce?.temperature?.title;

  return (
    <div id='TrailerDetails2'>
      <div className='titleContainer'>
        <div className='icon'>
          <PiTruck />
        </div>
        <h1 className='title'>{t("tms.titles.Trailer need")}</h1>
      </div>
      <div className='TrailerDetails2Container'>
        <div className='TrailerDetails2Cover'>
          <div
            className='TrailerDetails2Img'
            style={{ backgroundImage: `url(${cover})` }}
          />
        </div>
        <div className='TrailerDetails2content'>
          {trailerType && (
            <p>
              {t("tms.titles.Trailer type")}: <span>{trailerType}</span>
            </p>
          )}
          {trailerVolume && (
            <p>
              {t("tms.titles.Trailer volume")}: <span>{trailerVolume}</span>
            </p>
          )}
          {trailerTemperature && (
            <p>
              {t("tms.titles.Temperature")}: <span>{trailerTemperature}</span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default TrailerDetails2;
