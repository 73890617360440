import React from "react";
import "./OrdersTableItem.scss";

import { useDispatch } from "react-redux";

import { setShipperAcceptedOrderView } from "../../../../../store/orders/shipperAccepted/shipperAcceptedSlice";

import RouteField from "./../../../../Form/TableFields/RouteField/RouteField";
import RowField from "./../../../../Form/TableFields/RowField/RowField";
import OrderStatusField from "./../../../../Form/TableFields/OrderStatusField/OrderStatusField";
import ProductCategoryField from "../../../../Form/TableFields/ProductCategoryField/ProductCategoryField";
import ShippingTypeField from "./../../../../Form/TableFields/ShippingTypeField/ShippingTypeField";
import DateField from "./../../../../Form/TableFields/DateField/DateField";
import ProgressField from "../../../../Form/TableFields/ProgressField/ProgressField";
import PriceField from "../../../../Form/TableFields/PriceField/PriceField";
import NameField from "../../../../Form/TableFields/NameField/NameField";
import TransportationField from "./../../../../Form/TableFields/TransportationField/TransportationField";

const OrdersTableItem = ({
  index,
  order,
  activeOrderId,
  setActiveOrderId,
  activeOrderIndex,
  setActiveOrderIndex,
}) => {
  const dispatch = useDispatch();

  const submitHandler = () => {
    dispatch(setShipperAcceptedOrderView(false));
    setActiveOrderId(order.id);
    setActiveOrderIndex(index);
  };

  const bid = order?.bids?.[0];

  return (
    <RowField
      style={{
        borderLeft: `4px solid ${order.color ? order.color : "transparent"}`,
      }}
    >
      <NameField label={order.tracking} onClick={() => submitHandler()} />
      <OrderStatusField status={order.status ?? null} />
      <RouteField order={order} />
      <ProductCategoryField order={order} />
      <ShippingTypeField type={order?.type} />
      <TransportationField order={order} />
      <ProgressField progress={order?.distanceCovered?.distance || 0} />
      <DateField date={order.cargos[0]?.pickup_date ?? null} />
      <PriceField
        color='#2b353a'
        number={bid?.amount || 0}
        symbol={bid?.currency?.symbol || "-"}
      />
    </RowField>
  );
};

export default OrdersTableItem;
