import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import summaryService from "./summaryService";

const initialState = {
  getOrderscountLoading: true,
  getOrderscountResponce: null,
  getOrderscountError: null,

  getCustomerscountLoading: true,
  getCustomerscountResponce: null,
  getCustomerscountError: null,

  getPartnerscountLoading: true,
  getPartnerscountResponce: null,
  getPartnerscountError: null,

  getTruckscountLoading: true,
  getTruckscountResponce: null,
  getTruckscountError: null,

  getTrailerscountLoading: true,
  getTrailerscountResponce: null,
  getTrailerscountError: null,

  getDriverscountLoading: true,
  getDriverscountResponce: null,
  getDriverscountError: null,

  getMaintenancescountLoading: true,
  getMaintenancescountResponce: null,
  getMaintenancescountError: null,

  getShipperActiveOrdersSummaryLoading: true,
  getShipperActiveOrdersSummaryResponce: null,
  getShipperActiveOrdersSummaryError: null,

  getShipperAcceptedOrdersSummaryLoading: true,
  getShipperAcceptedOrdersSummaryResponce: null,
  getShipperAcceptedOrdersSummaryError: null,

  getShipperRejectOrdersSummaryLoading: true,
  getShipperRejectOrdersSummaryResponce: null,
  getShipperRejectOrdersSummaryError: null,
};

export const getOrdersSummary = createAsyncThunk(
  "summary/getOrdersSummary",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await summaryService.getSummary(ISO, TOKEN);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getCustomersSummary = createAsyncThunk(
  "summary/getCustomersSummary",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await summaryService.getSummary(ISO, TOKEN);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPartnersSummary = createAsyncThunk(
  "summary/getPartnersSummary",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await summaryService.getSummary(ISO, TOKEN);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getTrucksSummary = createAsyncThunk(
  "summary/getTrucksSummary",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await summaryService.getSummary(ISO, TOKEN);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getTrailersSummary = createAsyncThunk(
  "summary/getTrailersSummary",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await summaryService.getSummary(ISO, TOKEN);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDriversSummary = createAsyncThunk(
  "summary/getDriversSummary",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await summaryService.getSummary(ISO, TOKEN);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getMaintenancesSummary = createAsyncThunk(
  "summary/getMaintenancesSummary",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await summaryService.getSummary(ISO, TOKEN);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getShipperActiveOrdersSummary = createAsyncThunk(
  "summary/getShipperActiveOrdersSummary",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await summaryService.getSummary(ISO, TOKEN);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getShipperAcceptedOrdersSummary = createAsyncThunk(
  "summary/getShipperAcceptedOrdersSummary",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await summaryService.getSummary(ISO, TOKEN);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getShipperRejectOrdersSummary = createAsyncThunk(
  "summary/getShipperRejectOrdersSummary",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await summaryService.getSummary(ISO, TOKEN);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const summarySlice = createSlice({
  name: "summary",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrdersSummary.pending, (state) => {
        state.getOrderscountLoading = true;
        state.getOrderscountResponce = null;
        state.getOrderscountError = null;
      })
      .addCase(getOrdersSummary.fulfilled, (state, action) => {
        state.getOrderscountLoading = false;
        state.getOrderscountResponce = action.payload;
        state.getOrderscountError = null;
      })
      .addCase(getOrdersSummary.rejected, (state, action) => {
        state.getOrderscountLoading = false;
        state.getOrderscountResponce = null;
        state.getOrderscountError = action.payload;
      })

      .addCase(getCustomersSummary.pending, (state) => {
        state.getCustomerscountLoading = true;
        state.getCustomerscountResponce = null;
        state.getCustomerscountError = null;
      })
      .addCase(getCustomersSummary.fulfilled, (state, action) => {
        state.getCustomerscountLoading = false;
        state.getCustomerscountResponce = action.payload;
        state.getCustomerscountError = null;
      })
      .addCase(getCustomersSummary.rejected, (state, action) => {
        state.getCustomerscountLoading = false;
        state.getCustomerscountResponce = null;
        state.getCustomerscountError = action.payload;
      })

      .addCase(getPartnersSummary.pending, (state) => {
        state.getPartnerscountLoading = true;
        state.getPartnerscountResponce = null;
        state.getPartnerscountError = null;
      })
      .addCase(getPartnersSummary.fulfilled, (state, action) => {
        state.getPartnerscountLoading = false;
        state.getPartnerscountResponce = action.payload;
        state.getPartnerscountError = null;
      })
      .addCase(getPartnersSummary.rejected, (state, action) => {
        state.getPartnerscountLoading = false;
        state.getPartnerscountResponce = null;
        state.getPartnerscountError = action.payload;
      })

      .addCase(getTrucksSummary.pending, (state) => {
        state.getTruckscountLoading = true;
        state.getTruckscountResponce = null;
        state.getTruckscountError = null;
      })
      .addCase(getTrucksSummary.fulfilled, (state, action) => {
        state.getTruckscountLoading = false;
        state.getTruckscountResponce = action.payload;
        state.getTruckscountError = null;
      })
      .addCase(getTrucksSummary.rejected, (state, action) => {
        state.getTruckscountLoading = false;
        state.getTruckscountResponce = null;
        state.getTruckscountError = action.payload;
      })

      .addCase(getTrailersSummary.pending, (state) => {
        state.getTrailerscountLoading = true;
        state.getTrailerscountResponce = null;
        state.getTrailerscountError = null;
      })
      .addCase(getTrailersSummary.fulfilled, (state, action) => {
        state.getTrailerscountLoading = false;
        state.getTrailerscountResponce = action.payload;
        state.getTrailerscountError = null;
      })
      .addCase(getTrailersSummary.rejected, (state, action) => {
        state.getTrailerscountLoading = false;
        state.getTrailerscountResponce = null;
        state.getTrailerscountError = action.payload;
      })

      .addCase(getDriversSummary.pending, (state) => {
        state.getDriverscountLoading = true;
        state.getDriverscountResponce = null;
        state.getDriverscountError = null;
      })
      .addCase(getDriversSummary.fulfilled, (state, action) => {
        state.getDriverscountLoading = false;
        state.getDriverscountResponce = action.payload;
        state.getDriverscountError = null;
      })
      .addCase(getDriversSummary.rejected, (state, action) => {
        state.getDriverscountLoading = false;
        state.getDriverscountResponce = null;
        state.getDriverscountError = action.payload;
      })

      .addCase(getMaintenancesSummary.pending, (state) => {
        state.getMaintenancescountLoading = true;
        state.getMaintenancescountResponce = null;
        state.getMaintenancescountError = null;
      })
      .addCase(getMaintenancesSummary.fulfilled, (state, action) => {
        state.getMaintenancescountLoading = false;
        state.getMaintenancescountResponce = action.payload;
        state.getMaintenancescountError = null;
      })
      .addCase(getMaintenancesSummary.rejected, (state, action) => {
        state.getMaintenancescountLoading = false;
        state.getMaintenancescountResponce = null;
        state.getMaintenancescountError = action.payload;
      })

      .addCase(getShipperActiveOrdersSummary.pending, (state) => {
        state.getShipperActiveOrdersSummaryLoading = true;
        state.getShipperActiveOrdersSummaryResponce = null;
        state.getShipperActiveOrdersSummaryError = null;
      })
      .addCase(getShipperActiveOrdersSummary.fulfilled, (state, action) => {
        state.getShipperActiveOrdersSummaryLoading = false;
        state.getShipperActiveOrdersSummaryResponce =
          action.payload?.active_orders;
        state.getShipperActiveOrdersSummaryError = null;
      })
      .addCase(getShipperActiveOrdersSummary.rejected, (state, action) => {
        state.getShipperActiveOrdersSummaryLoading = false;
        state.getShipperActiveOrdersSummaryResponce = null;
        state.getShipperActiveOrdersSummaryError = action.payload;
      })

      .addCase(getShipperAcceptedOrdersSummary.pending, (state) => {
        state.getShipperAcceptedOrdersSummaryLoading = true;
        state.getShipperAcceptedOrdersSummaryResponce = null;
        state.getShipperAcceptedOrdersSummaryError = null;
      })
      .addCase(getShipperAcceptedOrdersSummary.fulfilled, (state, action) => {
        state.getShipperAcceptedOrdersSummaryLoading = false;
        state.getShipperAcceptedOrdersSummaryResponce =
          action.payload?.accepted_orders;
        state.getShipperAcceptedOrdersSummaryError = null;
      })
      .addCase(getShipperAcceptedOrdersSummary.rejected, (state, action) => {
        state.getShipperAcceptedOrdersSummaryLoading = false;
        state.getShipperAcceptedOrdersSummaryResponce = null;
        state.getShipperAcceptedOrdersSummaryError = action.payload;
      })

      .addCase(getShipperRejectOrdersSummary.pending, (state) => {
        state.getShipperRejectOrdersSummaryLoading = true;
        state.getShipperRejectOrdersSummaryResponce = null;
        state.getShipperRejectOrdersSummaryError = null;
      })
      .addCase(getShipperRejectOrdersSummary.fulfilled, (state, action) => {
        state.getShipperRejectOrdersSummaryLoading = false;
        state.getShipperRejectOrdersSummaryResponce =
          action.payload?.rejected_orders;
        state.getShipperRejectOrdersSummaryError = null;
      })
      .addCase(getShipperRejectOrdersSummary.rejected, (state, action) => {
        state.getShipperRejectOrdersSummaryLoading = false;
        state.getShipperRejectOrdersSummaryResponce = null;
        state.getShipperRejectOrdersSummaryError = action.payload;
      });
  },
});

export default summarySlice.reducer;
