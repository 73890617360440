import React, { Fragment, useState } from "react";
import "./TrucksTableItem.scss";

import { useTranslation } from "react-i18next";
import { FiMoreVertical, FiEdit2, FiTrash2 } from "react-icons/fi";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import EditTrucksModal from "./../../EditTrucksModal/EditTrucksModal";
import DeleteTrucksModal from "./../../DeleteTrucksModal/DeleteTrucksModal";
import SelectTrailerField from "./../../../../Form/TableFields/SelectTrailerField/SelectTrailerField";
import SelectDriverField from "./../../../../Form/TableFields/SelectDriverField/SelectDriverField";
import SelectTrailerModal from "../../../FleetManagementTrailers/SelectTrailerModal/SelectTrailerModal";
import RowField from "../../../../Form/TableFields/RowField/RowField";
import MoreField from "../../../../Form/TableFields/MoreField/MoreField";
import TruckField from "../../../../Form/TableFields/TruckField/TruckField";
import SelectDriverModal from "./../../../FleetManagementDrivers/SelectDriverModal/SelectDriverModal";
import TruckStatusField from "../../../../Form/TableFields/TruckStatusField/TruckStatusField";
import DateField from "../../../../Form/TableFields/DateField/DateField";

const TrucksTableItem = ({ truck }) => {
  const { t } = useTranslation();

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openSelectTrailerModal, setOpenSelectTrailerModal] = useState(false);
  const [openSelectDriverModal, setOpenSelectDriverModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <RowField>
        <TruckField
          max={40}
          to={`/dashboard/tms/fleet_management/trucks/${truck.id}`}
          truck={truck}
        />
        <DateField
          date={truck?.pickup_date?.[0]}
          noDate={t("tms.tables.noInfo.no order")}
        />

        <SelectTrailerField
          icon={truck?.trailer && truck?.trailer?.type?.icon2}
          trailer={truck?.trailer ? truck?.trailer : null}
          onClick={() => {
            setOpenSelectTrailerModal(true);
          }}
        />

        <SelectDriverField
          driver={truck?.driver ? truck?.driver : null}
          onClick={() => {
            setOpenSelectDriverModal(true);
          }}
        />

        <TruckStatusField status={truck?.active} />

        <MoreField>
          <div className='icon' onClick={handleClick}>
            <FiMoreVertical />
          </div>
          <Menu
            id='OrdersTableItemMore'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenEditModal(true);
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <FiEdit2 />
                {t("tms.tables.buttons.Edit")}
              </div>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenDeleteModal(true);
                handleClose();
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  color: "red",
                }}
              >
                <FiTrash2 />
                {t("tms.tables.buttons.Delete")}
              </div>
            </MenuItem>
          </Menu>
        </MoreField>
      </RowField>

      <EditTrucksModal
        isOpen={openEditModal}
        isClose={setOpenEditModal}
        truck={truck}
      />
      <DeleteTrucksModal
        truckId={truck.id}
        isOpen={openDeleteModal}
        isClose={setOpenDeleteModal}
      />

      <SelectTrailerModal
        truck={truck}
        isOpen={openSelectTrailerModal}
        isClose={() => setOpenSelectTrailerModal(false)}
      />

      <SelectDriverModal
        truck={truck}
        isOpen={openSelectDriverModal}
        isClose={() => setOpenSelectDriverModal(false)}
      />
    </Fragment>
  );
};

export default TrucksTableItem;
