import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import bankAccountsService from "./bankAccountsService";

const initialState = {
  getBankAccountsLoading: true,
  getBankAccountsResponce: null,
  getBankAccountsError: null,

  createBankAccountLoading: false,
  createBankAccountResponce: null,
  createBankAccountError: null,

  updateBankAccountLoading: false,
  updateBankAccountResponce: null,
  updateBankAccountError: null,

  deleteBankAccountLoading: false,
  deleteBankAccountResponce: null,
  deleteBankAccountError: null,
};

export const getBankAccounts = createAsyncThunk(
  "bankAccounts/getBankAccounts",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await bankAccountsService.getBankAccounts(ISO, TOKEN);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createBankAccount = createAsyncThunk(
  "bankAccounts/createBankAccount",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await bankAccountsService.createBankAccount(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateBankAccount = createAsyncThunk(
  "bankAccounts/updateBankAccount",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await bankAccountsService.updateBankAccount(
        ISO,
        TOKEN,
        data.id.toString(),
        data
      );
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteBankAccount = createAsyncThunk(
  "bankAccounts/deleteBankAccount",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await bankAccountsService.deleteBankAccount(
        ISO,
        TOKEN,
        data.id.toString()
      );
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const bankAccountsSlice = createSlice({
  name: "bankAccounts",
  initialState,
  reducers: {
    resetGetBankAccounts: (state) => {
      state.getBankAccountsLoading = false;
      state.getBankAccountsResponce = null;
      state.getBankAccountsError = null;
    },
    resetCreateBankAccount: (state) => {
      state.createBankAccountLoading = false;
      state.createBankAccountResponce = null;
      state.createBankAccountError = null;
    },
    resetUpdateBankAccount: (state) => {
      state.updateBankAccountLoading = false;
      state.updateBankAccountResponce = null;
      state.updateBankAccountError = null;
    },
    resetDeleteBankAccount: (state) => {
      state.deleteBankAccountLoading = false;
      state.deleteBankAccountResponce = null;
      state.deleteBankAccountError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBankAccounts.pending, (state) => {
        state.getBankAccountsLoading = true;
        state.getBankAccountsResponce = null;
        state.getBankAccountsError = null;
      })
      .addCase(getBankAccounts.fulfilled, (state, action) => {
        state.getBankAccountsLoading = false;
        state.getBankAccountsResponce = action.payload;
        state.getBankAccountsError = null;
      })
      .addCase(getBankAccounts.rejected, (state, action) => {
        state.getBankAccountsLoading = false;
        state.getBankAccountsResponce = null;
        state.getBankAccountsError = action.payload;
      })

      .addCase(createBankAccount.pending, (state) => {
        state.createBankAccountLoading = true;
        state.createBankAccountResponce = null;
        state.createBankAccountError = null;
      })
      .addCase(createBankAccount.fulfilled, (state, action) => {
        state.createBankAccountLoading = false;
        state.createBankAccountResponce = action.payload;
        state.createBankAccountError = null;
      })
      .addCase(createBankAccount.rejected, (state, action) => {
        state.createBankAccountLoading = false;
        state.createBankAccountResponce = null;
        state.createBankAccountError = action.payload;
      })

      .addCase(updateBankAccount.pending, (state) => {
        state.updateBankAccountLoading = true;
        state.updateBankAccountResponce = null;
        state.updateBankAccountError = null;
      })
      .addCase(updateBankAccount.fulfilled, (state, action) => {
        state.updateBankAccountLoading = false;
        state.updateBankAccountResponce = action.payload;
        state.updateBankAccountError = null;
      })
      .addCase(updateBankAccount.rejected, (state, action) => {
        state.updateBankAccountLoading = false;
        state.updateBankAccountResponce = null;
        state.updateBankAccountError = action.payload;
      })

      .addCase(deleteBankAccount.pending, (state) => {
        state.deleteBankAccountLoading = true;
        state.deleteBankAccountResponce = null;
        state.deleteBankAccountError = null;
      })
      .addCase(deleteBankAccount.fulfilled, (state, action) => {
        state.deleteBankAccountLoading = false;
        state.deleteBankAccountResponce = action.payload;
        state.deleteBankAccountError = null;
      })
      .addCase(deleteBankAccount.rejected, (state, action) => {
        state.deleteBankAccountLoading = false;
        state.deleteBankAccountResponce = null;
        state.deleteBankAccountError = action.payload;
      });
  },
});

export const {
  resetGetBankAccounts,
  resetCreateBankAccount,
  resetUpdateBankAccount,
  resetDeleteBankAccount,
} = bankAccountsSlice.actions;
export default bankAccountsSlice.reducer;
