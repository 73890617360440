import React from "react";
import "./Footer.scss";

import { useTranslation } from "react-i18next";
import { PopupButton } from "react-calendly";

import Button from "../../../../../Form/Button/Button";

const Footer = ({ submitHandler, loading }) => {
  const { t } = useTranslation();
  return (
    <div id='CarrierSubscribeFormFooter'>
      <Button
        title={t("landing.landingCarrierSubscribe.Next")}
        onClick={submitHandler}
        loading={loading}
      />
      <div className='orContainer'>
        <div className='or'>{t("landing.landingCarrierSubscribe.OR")}</div>
        <PopupButton
          className='requestCall'
          text={t("landing.landingCarrierSubscribe.Request a call")}
          url='https://calendly.com/service-cargon/meeting-for-carriers'
          rootElement={document.getElementById("root")}
        />
      </div>
    </div>
  );
};

export default Footer;
