import React, { useState, useEffect } from "react";
import "./PasswordVerify.scss";

import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  codeCheck,
  forgotPassword,
  resetCodeCheck,
} from "./../../../../store/auth/authSlice";
import { useAuthError } from "../../../../validations/auth/useAuthError";

import AuthFormWrapper from "../../Components/AuthFormWrapper/AuthFormWrapper";
import FormTitle from "../../Components/FormTitle/FormTitle";
import Button from "../../../Form/Button/Button";
import NotHaveAccount from "../../../Form/NotHaveAccount/NotHaveAccount";
import SendAgain from "../../Components/SendAgain/SendAgain";
import OTP from "../../../Form/OTP/OTP";

const PasswordVerify = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { phoneIndexValueId, phoneIndexes } = useSelector(
    (state) => state.settings
  );
  const phoneIndexValue =
    phoneIndexes && phoneIndexes.find((item) => item.id === phoneIndexValueId);
  const { codeCheckLoading, codeCheckError, codeCheckResponce } = useSelector(
    (state) => state.auth
  );
  const { code_check_error } = useAuthError();
  const inputValue = searchParams.get("value");
  const method = searchParams.get("method");
  const [value, setValue] = useState();

  useEffect(() => {
    if (codeCheckResponce && !codeCheckError) {
      const url = `/forgot-password/reset?method=${method}&input-value=${inputValue}&value=${value}`;
      navigate(url);
      dispatch(resetCodeCheck());
      setValue();
    }
  }, [
    dispatch,
    navigate,
    codeCheckError,
    value,
    method,
    inputValue,
    codeCheckResponce,
  ]);

  const submitHandler = () => {
    const data =
      method === "EMAIL"
        ? { code: value, email: inputValue }
        : { code: value, phone_index_id: phoneIndexValueId, phone: inputValue };
    dispatch(codeCheck(data));
  };

  const sendAgainHandler = () => {
    const data =
      method === "EMAIL"
        ? { email: inputValue }
        : { phone_index_id: phoneIndexValueId, phone: inputValue };
    dispatch(forgotPassword(data));
    setValue();
  };

  return (
    <AuthFormWrapper>
      <div id='PasswordVerify' className='PasswordVerify'>
        <FormTitle title={t("landing.auth.titles.Verification code")}>
          {t(
            "landing.auth.titles.Please enter the verification code you received on"
          )}{" "}
          <span>
            {method === "PHONE" &&
              phoneIndexValue &&
              phoneIndexValue.phone_index}
            {inputValue}
          </span>
        </FormTitle>
        <OTP
          value={value}
          setValue={setValue}
          count={method === "EMAIL" ? 6 : 4}
          error={code_check_error}
        />
        <Button
          loading={codeCheckLoading}
          title={t("landing.auth.form.Verified & Continue")}
          onClick={() => {
            submitHandler();
          }}
        />
        <SendAgain
          title={t("landing.auth.form.Send it again")}
          onClick={() => sendAgainHandler()}
        />
        <NotHaveAccount
          link='/login'
          text={t("landing.auth.form.Back to sign in")}
        />
      </div>
    </AuthFormWrapper>
  );
};

export default PasswordVerify;
