import React, { Fragment, useState, useEffect } from "react";
import "./PartnersList.scss";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  getPartners,
  resetGetPartners,
} from "./../../../../../../store/participants/partner/partnerSlice";

import Wrapper from "./../../../../../../componentsAdditional/Wrapper";
import EmptyList from "../../../../../../componentsDashboard/UI/EmptyList/EmptyList";
import LoadingPage from "./../../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import PageSwitcher from "../../../../../../componentsDashboard/PageSwitcher/PageSwitcher";
import CreatePartnerModal from "./../../../../../../componentsDashboard/CustomersPartners/Partners/CreatePartnerModal/CreatePartnerModal";
import PartnersTable from "../../../../../../componentsDashboard/CustomersPartners/Partners/PartnersTable/PartnersTable";
import PartnersListHeader from "../../../../../../componentsDashboard/CustomersPartners/Partners/PartnersListHeader/PartnersListHeader";

const PartnersList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { getPartnerscountResponce, getPartnerscountLoading } = useSelector(
    (state) => state.summary
  );

  const {
    getPartnersLoading,
    getPartnersResponce,
    getPartnersMeta,
    createPartnerResponce,
    updatePartnerResponce,
    deletePartnerResponce,
  } = useSelector((state) => state.partner);

  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1
  );
  const [keyword, setKeyword] = useState("");
  const [addModalShow, setAddModalShow] = useState(false);

  useEffect(() => {
    dispatch(getPartners({ page, keyword }));
    return () => {
      dispatch(resetGetPartners());
    };
  }, [
    dispatch,
    page,
    keyword,
    createPartnerResponce,
    updatePartnerResponce,
    deletePartnerResponce,
  ]);

  return (
    <Wrapper documentTitle={t("tms.documentTitles.Partners")}>
      <section id='PartnersList'>
        <PageSwitcher name='partner' />
        {getPartnerscountLoading && <LoadingPage />}

        {getPartnerscountResponce && (
          <Fragment>
            {getPartnerscountResponce.partners_count === 0 ? (
              <EmptyList
                image='/assets/images/dashboard/Empty-girl.svg'
                title={t("tms.titles.No Partner")}
                description={t(
                  "tms.desc.Add partner and take full data on your hand"
                )}
                onClick={() => setAddModalShow(true)}
                buttonText={t("tms.forms.buttons.Add partner")}
              />
            ) : (
              <Fragment>
                <PartnersListHeader
                  keyword={keyword}
                  setKeyword={setKeyword}
                  setAddModalShow={setAddModalShow}
                />
                {getPartnersLoading && <LoadingPage />}
                {getPartnersResponce && (
                  <PartnersTable
                    partners={getPartnersResponce}
                    page={page}
                    setPage={setPage}
                    pageCount={getPartnersMeta.last_page}
                    total={getPartnersMeta.total}
                  />
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      </section>
      <CreatePartnerModal
        isOpen={addModalShow}
        isClose={() => setAddModalShow(false)}
      />
    </Wrapper>
  );
};

export default PartnersList;
