import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ordersService from "./ordersService";

const initialState = {
  ordersLoading: true,
  orders: null,
  ordersMeta: null,
  ordersError: null,

  orderLoading: true,
  orderResponce: null,
  orderError: null,

  createLoading: false,
  createResponce: null,
  createResponceArray: [],
  createErrorArray: [],
  createError: null,

  updateLoading: false,
  updateResponce: null,
  updateError: null,

  deleteLoading: false,
  deleteResponce: null,
  deleteError: null,

  groupLoading: false,
  groupResponce: null,
  groupError: null,

  bidsRequestLoading: false,
  bidsRequestResponce: null,
  bidsRequestError: null,

  getOrderFiltersLoading: true,
  getOrderFiltersResponce: null,
  getOrderFiltersError: null,
};

export const getOrders = createAsyncThunk(
  "orders/getOrders",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await ordersService.getOrders(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getOrder = createAsyncThunk(
  "orders/getOrder",
  async (orderId, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await ordersService.getOrder(ISO, TOKEN, orderId);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createOrder = createAsyncThunk(
  "orders/createOrder",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await ordersService.createOrder(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateOrder = createAsyncThunk(
  "orders/updateOrder",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await ordersService.updateOrder(
        ISO,
        TOKEN,
        data && data.orderId,
        data
      );
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteOrder = createAsyncThunk(
  "orders/deleteOrder",
  async (orderId, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await ordersService.deleteOrder(ISO, TOKEN, orderId);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const groupOrder = createAsyncThunk(
  "orders/groupOrder",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await ordersService.groupOrder(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const bidsRequest = createAsyncThunk(
  "orders/bidsRequest",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await ordersService.bidsRequest(
        ISO,
        TOKEN,
        data && data.orderId,
        data
      );
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getOrderFilters = createAsyncThunk(
  "orders/getOrderFilters",
  async (orderId, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await ordersService.getOrderFilters(ISO, TOKEN, orderId);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    resetGetOrders: (state) => {
      state.ordersLoading = true;
      state.orders = null;
      state.ordersMeta = null;
      state.ordersError = null;
    },
    resetGetOrder: (state) => {
      state.orderLoading = true;
      state.orderResponce = null;
      state.orderError = null;
    },
    resetCreateOrder: (state) => {
      state.createLoading = false;
      state.createResponce = null;
      state.createError = null;
      state.createResponceArray = [];
      state.createErrorArray = [];
    },
    resetUpdateOrder: (state) => {
      state.updateLoading = false;
      state.updateResponce = null;
      state.updateError = null;
    },
    resetDeleteOrder: (state) => {
      state.deleteLoading = false;
      state.deleteResponce = null;
      state.deleteError = null;
    },
    resetGroupOrder: (state) => {
      state.groupLoading = false;
      state.groupResponce = null;
      state.groupError = null;
    },
    resetBidsRequest: (state) => {
      state.bidsRequestLoading = false;
      state.bidsRequestResponce = null;
      state.bidsRequestError = null;
    },
    resetGetOrderFilters: (state) => {
      state.getOrderFiltersLoading = true;
      state.getOrderFiltersResponce = null;
      state.getOrderFiltersError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrders.pending, (state) => {
        state.ordersLoading = true;
        state.orders = null;
        state.ordersMeta = null;
        state.ordersError = null;
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        state.ordersLoading = false;
        state.orders = action.payload.data;
        state.ordersMeta = action.payload.meta;
        state.ordersError = null;
      })
      .addCase(getOrders.rejected, (state, action) => {
        state.ordersLoading = false;
        state.orders = null;
        state.ordersMeta = null;
        state.ordersError = action.payload;
      })

      .addCase(getOrder.pending, (state) => {
        state.orderLoading = true;
        state.orderResponce = null;
        state.orderError = null;
      })
      .addCase(getOrder.fulfilled, (state, action) => {
        state.orderLoading = false;
        state.orderResponce = action.payload;
        state.orderError = null;
      })
      .addCase(getOrder.rejected, (state, action) => {
        state.orderLoading = false;
        state.orderResponce = null;
        state.orderError = action.payload;
      })

      .addCase(createOrder.pending, (state) => {
        state.createLoading = true;
        state.createResponce = null;
        state.createResponceArray = [];
        state.createErrorArray = [];
        state.createError = null;
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        state.createLoading = false;
        state.createResponce = action.payload;
        state.createResponceArray = [
          ...state.createResponceArray,
          action.payload.id,
        ];
        state.createError = null;
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.createLoading = false;
        state.createResponce = null;
        state.createErrorArray = [...state.createErrorArray, action.payload];
        state.createError = action.payload;
      })

      .addCase(updateOrder.pending, (state) => {
        state.updateLoading = true;
        state.updateResponce = null;
        state.updateError = null;
      })
      .addCase(updateOrder.fulfilled, (state, action) => {
        state.updateLoading = false;
        state.updateResponce = action.payload;
        state.updateError = null;
      })
      .addCase(updateOrder.rejected, (state, action) => {
        state.updateLoading = false;
        state.updateResponce = null;
        state.updateError = action.payload;
      })

      .addCase(deleteOrder.pending, (state) => {
        state.deleteLoading = true;
        state.deleteResponce = null;
        state.deleteError = null;
      })
      .addCase(deleteOrder.fulfilled, (state, action) => {
        state.deleteLoading = false;
        state.deleteResponce = action.payload;
        state.deleteError = null;
      })
      .addCase(deleteOrder.rejected, (state, action) => {
        state.deleteLoading = false;
        state.deleteResponce = null;
        state.deleteError = action.payload;
      })

      .addCase(groupOrder.pending, (state) => {
        state.groupLoading = true;
        state.groupResponce = null;
        state.groupError = null;
      })
      .addCase(groupOrder.fulfilled, (state, action) => {
        state.groupLoading = false;
        state.groupResponce = action.payload;
        state.groupError = null;
      })
      .addCase(groupOrder.rejected, (state, action) => {
        state.groupLoading = false;
        state.groupResponce = null;
        state.groupError = action.payload;
      })

      .addCase(bidsRequest.pending, (state) => {
        state.bidsRequestLoading = true;
        state.bidsRequestResponce = null;
        state.bidsRequestError = null;
      })
      .addCase(bidsRequest.fulfilled, (state, action) => {
        state.bidsRequestLoading = false;
        state.bidsRequestResponce = action.payload;
        state.bidsRequestError = null;
      })
      .addCase(bidsRequest.rejected, (state, action) => {
        state.bidsRequestLoading = false;
        state.bidsRequestResponce = null;
        state.bidsRequestError = action.payload;
      })

      .addCase(getOrderFilters.pending, (state) => {
        state.getOrderFiltersLoading = true;
        state.getOrderFiltersResponce = null;
        state.getOrderFiltersError = null;
      })
      .addCase(getOrderFilters.fulfilled, (state, action) => {
        state.getOrderFiltersLoading = false;
        state.getOrderFiltersResponce = action.payload;
        state.getOrderFiltersError = null;
      })
      .addCase(getOrderFilters.rejected, (state, action) => {
        state.getOrderFiltersLoading = false;
        state.getOrderFiltersResponce = null;
        state.getOrderFiltersError = action.payload;
      });
  },
});

export const {
  resetGetOrders,
  resetGetOrder,
  resetCreateOrder,
  resetUpdateOrder,
  resetDeleteOrder,
  resetGroupOrder,
  resetBidsRequest,
  resetGetOrderFilters,
} = ordersSlice.actions;
export default ordersSlice.reducer;
