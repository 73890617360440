import React from "react";
import "./DriversListHeader.scss";

import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";

import DSearch from "../../../FilterForms/DSearch/DSearch";
import DButton from "../../../Form/DButtons/DButton/DButton";
import FilterLinkedSelect from "../../../FilterForms/FilterLinkedSelect/FilterLinkedSelect";

const DriversListHeader = ({
  keyword,
  setKeyword,
  linkedTo,
  setLinkedTo,
  setOpenAddModal,
}) => {
  const { t } = useTranslation();

  return (
    <div id='DriversListHeader'>
      <div className='DriversListHeaderLeft'>
        <DSearch value={keyword} setValue={setKeyword} />
        <FilterLinkedSelect value={linkedTo} setValue={setLinkedTo} />
      </div>
      <div>
        <DButton
          title={t("tms.forms.buttons.Add driver")}
          rightIcon={<FiPlus />}
          onClick={() => {
            setOpenAddModal(true);
          }}
        />
      </div>
    </div>
  );
};

export default DriversListHeader;
