import React from "react";

import { useTranslation } from "react-i18next";
import { MdLocationPin, MdAnchor } from "react-icons/md";

import CreateOrderLocation from "../../Components/CreateOrderLocation/CreateOrderLocation";

const OceanLocation = ({ locations, setLocations }) => {
  const { t } = useTranslation();
  const locationADesc = [
    {
      type: "PORT",
      name: t("shipper.forms.content.Pick up the container at the port"),
      icon: <MdAnchor />,
    },

    {
      type: "LOCALITY",
      name: t("shipper.forms.content.Pick up the container at a facility"),
      icon: <MdLocationPin />,
    },
  ];

  const locationBDesc = [
    {
      type: "PORT",
      name: t("shipper.forms.content.Deliver the container to the port"),
      icon: <MdAnchor />,
    },

    {
      type: "LOCALITY",
      name: t(
        "shipper.forms.content.Deliver the container to a facility (door)"
      ),
      icon: <MdLocationPin />,
    },
  ];

  return (
    <CreateOrderLocation
      type='PORT'
      locations={locations}
      setLocations={setLocations}
      locationADesc={locationADesc}
      locationBDesc={locationBDesc}
      title={t("shipper.forms.titles.Location details")}
    />
  );
};

export default OceanLocation;
