import React, { Fragment, useState, useEffect } from "react";
import "./CreatePartnerModal.scss";

import { useTranslation } from "react-i18next";
import { FaPlusCircle } from "react-icons/fa";
import { FiMinus, FiPlus } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";

import {
  createPartner,
  resetCreatePartner,
} from "./../../../../store/participants/partner/partnerSlice";

import { usePartnerErrors } from "./../../../../validations/participant/partner/usePartnerErrors";

import DModal from "../../../Modal/DModal/DModal";
import DInputs from "../../../Form/DInputs/DInputs";
import DPhoneInput from "./../../../Form/DPhoneInput/DPhoneInput";
import SelectableButton from "../../Components/SelectableButton/SelectableButton";
import DTextButton from "../../../Form/DButtons/DTextButton/DTextButton";
import AddOtherRouteSelect from "../Components/AddOtherRouteSelect/AddOtherRouteSelect";

const CreatePartnerModal = ({ isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { createPartnerLoading, createPartnerResponce } = useSelector(
    (state) => state.partner
  );
  const { phoneIndexValueId, phoneIndexes } = useSelector(
    (state) => state.settings
  );
  const { getTrailerTypesResponce } = useSelector((state) => state.trailers);
  const { getRoutesListResponce } = useSelector((state) => state.routes);
  const {
    create_company_legal_id_error,
    create_company_name_error,
    create_company_email_error,
    create_company_phone_error,
    create_company_phone2_error,
    create_company_phone_index_id_error,
    create_company_phone2_index_id_error,
  } = usePartnerErrors();

  const indexesOptions =
    phoneIndexes &&
    phoneIndexes.map((item) => ({
      value: item,
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: "15px",
              height: "15px",
              backgroundImage: `url(${item.flag})`,
              backgroundPosition: "cover",
              backgroundRepeat: "no-repeat",
              marginRight: "5px",
            }}
          ></div>
          <p>{item.phone_index}</p>
        </div>
      ),
    }));

  const phoneIndexValueObj =
    indexesOptions &&
    indexesOptions.find((item) => item.value.id === phoneIndexValueId);

  const [showSecondaryPhone, setShowSecondaryPhone] = useState(false);
  const [showAddOtherRoute, setShowAddOtherRoute] = useState(false);

  const [customerID, setCustomerID] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [secondaryPhone, setSecondaryPhone] = useState("");
  const [phoneIndexValue, setPhoneIndexValue] = useState(phoneIndexValueObj);
  const [secondaryPhoneIndexValue, setSecondaryPhoneIndexValue] =
    useState(phoneIndexValueObj);
  const [selectedTrailerTypes, setSelectedTrailerTypes] = useState([]);
  const [selectedRoutes, setSelectedRoutes] = useState([]);

  const [prefferedRoutes, setPrefferedRoutes] = useState([]);

  const resetState = () => {
    setCustomerID("");
    setCustomerName("");
    setEmail("");
    setPhone("");
    setSecondaryPhone("");
    setSelectedTrailerTypes([]);
    setSelectedRoutes([]);
  };

  const submitHandler = () => {
    const data = {
      type: "Partner",
      trailer_types: selectedTrailerTypes,
      route_types: selectedRoutes,
      company: {
        legal_id: customerID,
        name: customerName,
        email: email,
        phone_index_id: phoneIndexValue?.value?.id,
        phone2_index_id: secondaryPhoneIndexValue?.value?.id || null,
        phone: phone,
        phone2: secondaryPhone || null,
      },
    };
    dispatch(createPartner(data));
  };

  // Need to be set in state for rerendering after array changes
  useEffect(() => {
    if (getRoutesListResponce) {
      setPrefferedRoutes(
        getRoutesListResponce
          ?.filter((route) => route?.featured)
          ?.map((item) => ({
            name: item?.title,
            id: item?.id,
            featured: item?.featured,
          }))
      );
    }
  }, [getRoutesListResponce]);

  useEffect(() => {
    if (createPartnerResponce) {
      dispatch(resetCreatePartner());
      resetState();
      isClose();
    }
  }, [dispatch, createPartnerResponce, isClose]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={isClose}
        title={t("tms.modals.titles.Add Partner")}
        icon={<FaPlusCircle />}
        status='success'
        onClick={() => submitHandler()}
        submitText={t("tms.modals.buttons.Save")}
        loading={createPartnerLoading}
      >
        <div id='CreatePartnerModal'>
          <div className='CreatePartnerModalgroup'>
            <DInputs
              value={customerName}
              setValue={setCustomerName}
              placeholder={t("tms.modals.placeholder.Enter partner name")}
              label={t("tms.modals.labels.Partner name")}
              required
              id='partnerName'
              error={create_company_name_error}
            />
            <DInputs
              value={customerID}
              setValue={setCustomerID}
              placeholder={t("tms.modals.placeholder.Enter partner ID")}
              label={t("tms.modals.labels.Partner ID")}
              required
              id='partnerIDID'
              error={create_company_legal_id_error}
            />

            <DPhoneInput
              id='PartnerPhone'
              required
              value={phone}
              setValue={setPhone}
              indexValue={phoneIndexValue}
              setIndexValue={setPhoneIndexValue}
              placeholder={t("tms.modals.placeholder.Enter phone number")}
              label={t("tms.modals.labels.Phone number")}
              status={t("tms.modals.labels.Primary")}
              error={
                create_company_phone_error ||
                create_company_phone_index_id_error
              }
            />
            {!showSecondaryPhone ? (
              <div className='showSecondaryPhoneButtonContainer'>
                <div
                  className='showSecondaryPhoneButton'
                  onClick={() => setShowSecondaryPhone(true)}
                >
                  <div className='icon'>
                    <FiPlus />
                  </div>
                  <div className='text'>
                    {t("tms.modals.buttons.Add secondary phone number")}
                  </div>
                </div>
              </div>
            ) : (
              <Fragment>
                <DPhoneInput
                  id='PartnerPhoneSecondary'
                  value={secondaryPhone}
                  setValue={setSecondaryPhone}
                  indexValue={secondaryPhoneIndexValue}
                  setIndexValue={setSecondaryPhoneIndexValue}
                  placeholder={t("tms.modals.placeholder.Enter phone number")}
                  label={t("tms.modals.labels.Phone number")}
                  status={t("tms.modals.labels.Secondary")}
                  error={
                    create_company_phone2_error ||
                    create_company_phone2_index_id_error
                  }
                />
                <div className='hideSecondaryPhoneButtonContainer'>
                  <div
                    className='hideSecondaryPhoneButton'
                    onClick={() => setShowSecondaryPhone(false)}
                  >
                    <div className='icon'>
                      <FiMinus />
                    </div>
                    <div className='text'>
                      {t("tms.modals.buttons.Remove secondary phone number")}
                    </div>
                  </div>
                </div>
              </Fragment>
            )}

            <DInputs
              value={email}
              setValue={setEmail}
              placeholder={t("tms.modals.placeholder.Enter email")}
              label={t("tms.modals.labels.Email")}
              id='PartnerEmail'
              error={create_company_email_error}
            />
            <div className='prefferedTrailerTypesContainer'>
              <div className='prefferedTrailerTypesTitle'>
                {t("tms.modals.titles.Preffered trailer types")}
              </div>
              <div className='prefferedTrailerTypesList'>
                {getTrailerTypesResponce?.map((prefferedTrilerType) => (
                  <SelectableButton
                    key={prefferedTrilerType?.id}
                    item={prefferedTrilerType}
                    selectedTypes={selectedTrailerTypes}
                    setSelectedTypes={setSelectedTrailerTypes}
                  />
                ))}
              </div>
            </div>
            <div className='prefferedRoutesContainer'>
              <div className='prefferedRoutesTitle'>
                {t("tms.modals.titles.Preffered routes")}
              </div>
              <div className='prefferedRoutesList'>
                {prefferedRoutes?.map((prefferedRoute) => (
                  <SelectableButton
                    key={prefferedRoute?.id}
                    item={prefferedRoute}
                    selectedTypes={selectedRoutes}
                    setSelectedTypes={setSelectedRoutes}
                    setTypeList={setPrefferedRoutes}
                    removable={!prefferedRoute?.featured}
                  />
                ))}
                <div style={{ display: showAddOtherRoute ? "none" : "block" }}>
                  <DTextButton
                    title={t("tms.modals.buttons.Add other")}
                    leftIcon={<FiPlus />}
                    onClick={() => setShowAddOtherRoute(true)}
                  />
                </div>
                <div style={{ display: showAddOtherRoute ? "block" : "none" }}>
                  <AddOtherRouteSelect
                    routesList={getRoutesListResponce}
                    isFocused={showAddOtherRoute}
                    onChange={(route) => {
                      setShowAddOtherRoute(false);
                      setPrefferedRoutes([...prefferedRoutes, route]);
                      setSelectedRoutes([...selectedRoutes, route?.id]);
                    }}
                    selectedRoutes={selectedRoutes}
                    onBlur={() => setShowAddOtherRoute(false)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default CreatePartnerModal;
