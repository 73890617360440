import React, { Fragment, useEffect } from "react";
import "./FinancialDetails.scss";

import { useSelector, useDispatch } from "react-redux";

import {
  getOrderCosts,
  resetGetOrderCosts,
} from "./../../../store/orders/costs/orderCostsSlice";

import OrderProfit from "./Components/OrderProfit/OrderProfit";
import FinancialDetailsHeader from "./Components/FinancialDetailsHeader/FinancialDetailsHeader";
import AddAlreadyPaidMoney from "./Components/AddAlreadyPaidMoney/AddAlreadyPaidMoney";
import FinancialDetailPayment from "./Components/FinancialDetailPayment/FinancialDetailPayment";
import CustomerPrice from "./Components/CustomerPrice/CustomerPrice";
import PartnerPrice from "./Components/PartnerPrice/PartnerPrice";
import OwnCarPrice from "./Components/OwnCarPrice/OwnCarPrice";

const FinancialDetails = ({ order }) => {
  const dispatch = useDispatch();

  const {
    updateOrderCostResponce,
    createOrderCostResponce,
    deleteOrderCostResponce,
  } = useSelector((state) => state.orderCosts);

  useEffect(() => {
    if (order) {
      dispatch(getOrderCosts(order.id));
    }
    return () => {
      dispatch(resetGetOrderCosts());
    };
  }, [
    dispatch,
    order,
    createOrderCostResponce,
    updateOrderCostResponce,
    deleteOrderCostResponce,
  ]);

  return (
    <Fragment>
      {order && (
        <div id='FinancialDetails'>
          <FinancialDetailsHeader order={order} />
          <div className='FinancialDetailsContent'>
            <FinancialDetailPayment order={order} />
            <div className='FinancialDetailslist'>
              <CustomerPrice order={order} />
              {order.carrier && order.carrier.partner ? (
                <PartnerPrice order={order} />
              ) : (
                <OwnCarPrice order={order} />
              )}
              {order?.revenue?.amount > 0 ? (
                <OrderProfit order={order} />
              ) : null}
            </div>
          </div>
          {!order.paid && <AddAlreadyPaidMoney order={order} />}
        </div>
      )}
    </Fragment>
  );
};

export default FinancialDetails;
