import React from "react";
import "./DPhoneInput.scss";

import { useSelector, useDispatch } from "react-redux";
import { setPhoneIndexValue } from "../../../store/additional/settings/settingsSlice";

import DSelect from "./../DSelect/DSelect";
import DInputs from "../DInputs/DInputs";
import DLabel from "../DLabel/DLabel";
import DErrorText from "../DErrorText/DErrorText";

const DPhoneInput = ({
  id,
  label,
  required,
  placeholder,
  error,
  type,
  value,
  setValue,
  indexValue,
  setIndexValue,
  status,
}) => {
  const dispatch = useDispatch();

  const { phoneIndexesLoading, phoneIndexes, phoneIndexValueId } = useSelector(
    (state) => state.settings
  );

  const options =
    phoneIndexes &&
    phoneIndexes.map((item) => ({
      value: item,
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: "15px",
              height: "15px",
              backgroundImage: `url(${item.flag})`,
              backgroundPosition: "cover",
              backgroundRepeat: "no-repeat",
              marginRight: "5px",
            }}
          ></div>
          <p>{item.phone_index}</p>
        </div>
      ),
    }));

  const newValue =
    options && options.find((item) => item.value.id === phoneIndexValueId);

  const filterOption = (option, inputValue) => {
    const { value } = option;
    return value.phone_index.toLowerCase().includes(inputValue.toLowerCase());
  };

  return (
    <div id='DPhoneInput'>
      {label && (
        <DLabel
          label={label}
          required={required}
          htmlFor={id}
          status={status}
        />
      )}

      <div className='container'>
        <DSelect
          placeholder='Phone'
          type={type ? type : "text"}
          value={indexValue ? indexValue : newValue}
          setValue={(state) => {
            setIndexValue
              ? setIndexValue(state)
              : dispatch(setPhoneIndexValue(state.value.id));
          }}
          error={null}
          options={options}
          loading={phoneIndexesLoading}
          search={true}
          filterOption={filterOption}
        />

        <DInputs
          id={id}
          placeholder={placeholder}
          type={type ? type : "text"}
          onChange={setValue}
          value={value}
          setValue={setValue}
          error={null}
        />
      </div>
      {error && <DErrorText error={error} />}
    </div>
  );
};

export default DPhoneInput;
