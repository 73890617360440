import { useSelector } from "react-redux";

export const useTruckErrors = () => {
  const { createTruckError, updateTruckError } = useSelector(
    (state) => state.trucks
  );

  const create_model_id =
    createTruckError &&
    createTruckError.errors &&
    createTruckError.errors.model_id &&
    createTruckError.errors.model_id[0];

  const create_registration_number =
    createTruckError &&
    createTruckError.errors &&
    createTruckError.errors.registration_number &&
    createTruckError.errors.registration_number[0];

  const create_model_year =
    createTruckError &&
    createTruckError.errors &&
    createTruckError.errors.model_year &&
    createTruckError.errors.model_year[0];

  const create_passport_number =
    createTruckError &&
    createTruckError.errors &&
    createTruckError.errors.passport_number &&
    createTruckError.errors.passport_number[0];

  const update_model_id =
    updateTruckError &&
    updateTruckError.errors &&
    updateTruckError.errors.model_id &&
    updateTruckError.errors.model_id[0];

  const update_registration_number =
    updateTruckError &&
    updateTruckError.errors &&
    updateTruckError.errors.registration_number &&
    updateTruckError.errors.registration_number[0];

  const update_model_year =
    updateTruckError &&
    updateTruckError.errors &&
    updateTruckError.errors.model_year &&
    updateTruckError.errors.model_year[0];

  const update_passport_number =
    updateTruckError &&
    updateTruckError.errors &&
    updateTruckError.errors.passport_number &&
    updateTruckError.errors.passport_number[0];

  return {
    create_model_id,
    create_registration_number,
    create_model_year,
    create_passport_number,
    update_model_id,
    update_registration_number,
    update_model_year,
    update_passport_number,
  };
};

export default useTruckErrors;
