import React, { Fragment, useState, useEffect } from "react";
import "./EditEmailModal.scss";

import { useTranslation } from "react-i18next";
import { CiMail } from "react-icons/ci";
import { useSelector, useDispatch } from "react-redux";

import {
  changeEmail,
  resetChangeEmail,
  verifyEmail,
  resetVerifyEmail,
} from "../../../../../store/user/userSlice";
import { useChangeEmailErrors } from "./../../../../../validations/auth/useChangeEmailErrors";

import DModal from "../../../../Modal/DModal/DModal";
import DInputs from "../../../../Form/DInputs/DInputs";
import OTP from "../../../../../componentsLanding/Form/OTP/OTP";

const EditEmailModal = ({ isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const {
    changeEmailLoading,
    changeEmailResponce,
    verifyEmailLoading,
    verifyEmailResponce,
  } = useSelector((state) => state.user);

  const { update_email_error, update_email_verify_error } =
    useChangeEmailErrors();

  const [email, setEmail] = useState(user?.email || "");
  const [showOtp, setShowOtp] = useState(false);
  const [otp, setOtp] = useState("");

  const handleFirstSubmit = () => {
    dispatch(changeEmail({ email: email }));
  };

  const handleSecondSubmit = () => {
    dispatch(verifyEmail({ code: otp }));
  };

  useEffect(() => {
    if (changeEmailResponce) {
      dispatch(resetChangeEmail());
      setShowOtp(true);
    }
  }, [dispatch, changeEmailResponce]);

  useEffect(() => {
    if (verifyEmailResponce) {
      dispatch(resetVerifyEmail());
      setShowOtp(false);
      isClose();
    }
  }, [dispatch, isClose, verifyEmailResponce]);

  return (
    <DModal
      isOpen={isOpen}
      isClose={() => {
        setShowOtp(false);
        isClose();
      }}
      title={
        showOtp
          ? t("tms.modals.titles.Enter verification code")
          : t("tms.modals.titles.Edit email")
      }
      status={"info"}
      submitText={
        showOtp
          ? t("tms.modals.buttons.Verify")
          : t("tms.modals.buttons.Send code")
      }
      onClick={() => (showOtp ? handleSecondSubmit() : handleFirstSubmit())}
      loading={changeEmailLoading || verifyEmailLoading}
      icon={<CiMail />}
    >
      <div id='profileEditEmail'>
        <form className='formGroup'>
          {showOtp ? (
            <Fragment>
              <div className='otpTitle'>
                {t("tms.modals.others.Enter verification code")}
              </div>
              <OTP
                count={6}
                value={otp}
                setValue={setOtp}
                error={update_email_verify_error}
              />
            </Fragment>
          ) : (
            <DInputs
              id={"editProfileEmail"}
              type={"email"}
              label={t("tms.modals.labels.Email")}
              placeholder={t("tms.modals.placeholder.Enter email")}
              value={email}
              setValue={setEmail}
              required
              error={update_email_error}
            />
          )}
        </form>
      </div>
    </DModal>
  );
};

export default EditEmailModal;
