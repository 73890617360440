import React, { useState, useEffect } from "react";
import "./EditOrder.scss";

import { useTranslation } from "react-i18next";

import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import {
  resetUpdateOrder,
  updateOrder,
  getOrder,
  resetGetOrder,
} from "../../../../../store/orders/ordersSlice";
import { getTrailerTypes } from "../../../../../store/orders/orderSettings/orderSettingsSlice";
import useEditOrderErrors from "../../../../../validations/order/useEditOrderErrors";
import { useOrderPaymentSelectSubOptions } from "./../../../../../helper/useOrderPaymentSelectSubOptions";

import DButton from "./../../../../../componentsDashboard/Form/DButtons/DButton/DButton";
import DBackButton from "./../../../../../componentsDashboard/Form/DButtons/DBackButton/DBackButton";
import Wrapper from "./../../../../../componentsAdditional/Wrapper";
import LoadingPage from "./../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import AditionalInformation from "./../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderTMC/AditionalInformation/AditionalInformation";
import ProductNewOrder from "./../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderTMC/ProductNewOrder/ProductNewOrder";
import RouteNewOrder from "./../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderTMC/RouteNewOrder/RouteNewOrder";
import TrailersNewOrder from "./../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderTMC/TrailersNewOrder/TrailersNewOrder";
import PaymentNewOrder from "./../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderTMC/PaymentNewOrder/PaymentNewOrder";

const EditOrder = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orderId } = useParams();

  const [paymentSubOptions] = useState(useOrderPaymentSelectSubOptions());

  const { user } = useSelector((state) => state.auth);
  const {
    orderLoading,
    orderResponce,
    updateLoading,
    updateError,
    updateResponce,
  } = useSelector((state) => state.orders);
  const {
    orderTypesSelectResponce,
    orderPaymentTypesSelect,
    cargoTypesSelectResponce,
    trailerTypes,
    temperaturesSelect,
  } = useSelector((state) => state.orderSettings);
  const { currenciesSelect } = useSelector((state) => state.settings);

  const {
    type_id_error,
    customer_id_error,
    trailer_type_id_error,
    trailer_size_id_error,
    cargos_type_id_error,
    cargos_weight_error,
    cargos_adr_error,
    cargos_pickup_date_error,
    payment_option_type_error,
    payment_type_id_error,
    payment_days_error,
    payment_amount_error,
    payment_amount_currency_error,
  } = useEditOrderErrors();

  const [customer, setCustomer] = useState(null);
  const [orderType, setOrderType] = useState(null);
  const [productType, setProductType] = useState(null);

  const [adr, setAdr] = useState(false);
  const [adrUnNumber, setAdrUnNumber] = useState("");
  const [adrClass, setAdrClass] = useState("");
  const [comment, setComment] = useState("");

  const [locations, setLocations] = useState([
    { direction: "A", inputted_address: "", error: null },
    { direction: "B", inputted_address: "", error: null },
  ]);
  const [pickUpDate, setPickUpDate] = useState(null);
  const [pickUpTime, setPickUpTime] = useState(null);
  const [dropOffShow, setDropOffShow] = useState(false);
  const [dropOffDate, setDropOffDate] = useState(null);
  const [dropOffTime, setDropOffTime] = useState(null);
  const [trailers, setTrailers] = useState([
    {
      trailerType: null,
      trailerSize: null,
      temperatureRange: null,
      weight: "",
      price: "",
      currency: null,
    },
  ]);
  const [payMethod, setPayMethod] = useState(null);
  const [payMethodSub, setPayMethodSub] = useState(null);
  const [payMethodAmount, setPayMethodAmount] = useState("");
  const [payMethodAmountCurrency, setPayMethodAmountCurrency] = useState(null);
  const [payConsignationPeriod, setPayConsignationPeriod] = useState("");

  // get detail order
  useEffect(() => {
    dispatch(getOrder(orderId));
    return () => {
      dispatch(resetGetOrder());
    };
  }, [dispatch, orderId]);

  useEffect(() => {
    if (orderType) {
      dispatch(getTrailerTypes(orderType.value.id));
    }
  }, [dispatch, orderType]);

  // catch locations error
  useEffect(() => {
    if (updateError && updateError.errors) {
      const updatedLocations = locations.map((location, index) => {
        const errorKey = `cargos.0.locations.${index}.inputted_address`;
        const errorValue =
          updateError.errors[errorKey] && updateError.errors[errorKey][0]
            ? updateError.errors[errorKey][0]
            : null;

        return {
          ...location,
          error: errorValue,
        };
      });
      setLocations(updatedLocations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateError]);

  // customer
  useEffect(() => {
    if (orderResponce) {
      const findValue = {
        label:
          orderResponce.customer &&
          orderResponce.customer.company &&
          orderResponce.customer.company.name,
        value: {},
      };
      setCustomer(findValue);
    }
  }, [orderResponce]);

  // type
  useEffect(() => {
    if (orderTypesSelectResponce && orderResponce) {
      const findValue = orderTypesSelectResponce.find(
        (i) => i.value.id === orderResponce.type.id
      );
      setOrderType(findValue);
    }
  }, [orderTypesSelectResponce, orderResponce]);

  // product type
  useEffect(() => {
    if (orderResponce && cargoTypesSelectResponce) {
      const findValue = cargoTypesSelectResponce.find(
        (i) => i.value.id === orderResponce.cargos[0].type.id
      );
      setProductType(findValue);
    }
  }, [orderResponce, cargoTypesSelectResponce]);

  // adr
  useEffect(() => {
    if (orderResponce) {
      const adr = orderResponce.cargos[0].adr;
      const adr_class = orderResponce.cargos[0].adr_class
        ? orderResponce.cargos[0].adr_class
        : "";
      const un_number = orderResponce.cargos[0].un_number
        ? orderResponce.cargos[0].un_number
        : "";
      setAdr(adr);
      setAdrUnNumber(un_number);
      setAdrClass(adr_class);
    }
  }, [orderResponce]);

  // comment
  useEffect(() => {
    if (orderResponce) {
      const comment = orderResponce.cargos[0].about
        ? orderResponce.cargos[0].about
        : "";

      setComment(comment);
    }
  }, [orderResponce]);

  //trailers
  useEffect(() => {
    if (
      orderResponce &&
      currenciesSelect &&
      trailerTypes &&
      temperaturesSelect
    ) {
      const findCurrency = currenciesSelect.find(
        (item) => item.value.id === orderResponce.revenue.currency.id
      );
      const findTrailerType = trailerTypes
        .flatMap((trailerType) => trailerType.types.map((item) => item))
        .map((i) => ({
          label: i.name,
          value: {
            ...i,
            sizes: i.sizes.map((size) => ({
              label: size.label,
              value: size,
            })),
          },
        }))
        .find((i) => i.value.id === orderResponce.trailer_type.id);

      const findTrailerSizes =
        trailerTypes &&
        findTrailerType.value.sizes.find(
          (i) => i.value.id === orderResponce.trailer_size.id
        );

      const fingTemperature = orderResponce.temperature
        ? temperaturesSelect.find(
            (i) => i.value.id === orderResponce.temperature.id
          )
        : null;

      const newData = [
        {
          weight: orderResponce.weight ? orderResponce.weight.toString() : "",
          price: orderResponce.revenue.amount
            ? orderResponce.revenue.amount
            : "",
          currency: findCurrency ? findCurrency : null,
          trailerType: findTrailerType ? findTrailerType : null,
          trailerSize: {
            label: findTrailerSizes.label,
            value: findTrailerSizes,
          },
          temperatureRange: fingTemperature,
        },
      ];
      setTrailers(newData);
    }
  }, [orderResponce, currenciesSelect, trailerTypes, temperaturesSelect]);

  // select  payment type
  useEffect(() => {
    if (orderResponce && orderPaymentTypesSelect) {
      const findPaymentType = orderPaymentTypesSelect.find(
        (i) => i.value.id === orderResponce?.payment?.type?.id
      );
      setPayMethod(findPaymentType);
    }
  }, [orderResponce, orderPaymentTypesSelect]);

  // select  payment amount
  useEffect(() => {
    if (orderResponce && currenciesSelect) {
      const findCurrency = currenciesSelect.find(
        (item) => item.value.id === orderResponce?.payment?.currency_id?.id
      );
      const userCurrency = currenciesSelect.find(
        (item) => item.value.id === user.currency.id
      );
      setPayMethodAmountCurrency(findCurrency ? findCurrency : userCurrency);
      setPayMethodAmount(
        orderResponce.payment?.amount ? orderResponce.payment.amount : ""
      );
    }
  }, [orderResponce, currenciesSelect, user.currency.id]);

  // payment  consignation
  useEffect(() => {
    if (orderResponce) {
      setPayConsignationPeriod(
        orderResponce.payment?.days ? orderResponce.payment.days.toString() : ""
      );
    }
  }, [orderResponce]);

  useEffect(() => {
    if (orderResponce && paymentSubOptions) {
      const findPaymentSubType = paymentSubOptions.find(
        (i) => i.value.id === orderResponce?.payment?.option_type
      );
      setPayMethodSub(findPaymentSubType);
    }
  }, [orderResponce, orderResponce?.payment?.option_type, paymentSubOptions]);

  // dates
  useEffect(() => {
    if (orderResponce) {
      setPickUpDate(
        orderResponce.cargos[0].pickup_date
          ? moment(orderResponce.cargos[0].pickup_date, "DD/MM/YYYY").toDate()
          : null
      );
      setDropOffDate(
        orderResponce.cargos[0].dropoff_date
          ? moment(orderResponce.cargos[0].dropoff_date, "DD/MM/YYYY").toDate()
          : null
      );
      setDropOffShow(orderResponce.cargos[0].dropoff_date ? true : false);
    }
  }, [orderResponce]);

  // time
  useEffect(() => {
    if (orderResponce?.cargos[0]?.pickup_time_range) {
      setPickUpTime([
        orderResponce?.cargos[0]?.pickup_time_range?.start,
        orderResponce?.cargos[0]?.pickup_time_range?.end,
      ]);
    }
    if (orderResponce?.cargos[0]?.dropoff_time_range) {
      setDropOffTime([
        orderResponce?.cargos[0]?.dropoff_time_range?.start,
        orderResponce?.cargos[0]?.dropoff_time_range?.end,
      ]);
    }
  }, [orderResponce]);

  // locations
  useEffect(() => {
    if (orderResponce) {
      const directionA = orderResponce.cargos[0].locations
        .filter((i) => i.direction === "A")
        .map((i) => ({
          id: i.id,
          direction: "A",
          inputted_address: i.formatted_address,
          error: null,
        }));
      const directionB = orderResponce.cargos[0].locations
        .filter((i) => i.direction === "B")
        .map((i) => ({
          id: i.id,
          direction: "B",
          inputted_address: i.formatted_address,
          error: null,
        }));
      setLocations([...directionA, ...directionB]);
    }
    // formatted_address
  }, [orderResponce]);

  // reset
  useEffect(() => {
    if (updateResponce) {
      dispatch(resetUpdateOrder());
      navigate(-1);
    }
  }, [dispatch, navigate, updateResponce]);

  const submitHandler = () => {
    const data = trailers.map((order) => ({
      orderId: orderId,
      payment: {
        type_id: payMethod ? payMethod.value.id : null,
        days: payConsignationPeriod.length > 0 ? +payConsignationPeriod : null,
        option_type: payMethodSub ? payMethodSub.value.id : null,
        amount: payMethod && payMethod.value.id === 2 ? payMethodAmount : null,
        currency_id:
          payMethod && payMethod.value.id === 2 && payMethodAmountCurrency
            ? payMethodAmountCurrency.value.id
            : null,
      },
      revenue: {
        amount: order.price.toString().length > 0 ? order.price : 0,
        currency_id: order.currency ? order.currency.value.id : null,
      },
      transfer_type_id: 1,
      type_id: orderType ? orderType.value.id : null,
      customer_id: customer ? customer.value.id : null,
      trailer_type_id: order.trailerType ? order.trailerType.value.id : null,
      trailer_size_id: order.trailerSize
        ? order.trailerSize.value.value.id
        : null,
      temperature_range_id: order.temperatureRange
        ? order.temperatureRange.value.id
        : null,
      comment: null,
      cargos: [
        {
          id: orderResponce && orderResponce.cargos[0].id,
          type_id: productType ? productType.value.id : null,
          weight: order.weight.toString().length > 0 ? order.weight : null,
          volume: null,
          adr: adr,
          adr_class: adrClass.toString().length > 0 ? adrClass : null,
          un_number: adrUnNumber.toString().length > 0 ? adrUnNumber : null,
          about: comment.length.toString() > 0 ? comment : null,
          pickup_date: pickUpDate
            ? moment(pickUpDate).format("YYYY-MM-DD")
            : null,
          dropoff_date: dropOffDate
            ? moment(dropOffDate).format("YYYY-MM-DD")
            : null,
          pickup_time_range: pickUpTime
            ? {
                start: pickUpTime[0],
                end: pickUpTime[1],
              }
            : null,
          dropoff_time_range: dropOffTime
            ? {
                start: dropOffTime[0],
                end: dropOffTime[1],
              }
            : null,
          locations: locations,
        },
      ],
    }));
    dispatch(updateOrder(data[0]));
  };

  return (
    <Wrapper documentTitle={t("tms.documentTitles.Edit order")}>
      {orderLoading && <LoadingPage />}
      {orderResponce && !orderLoading && (
        <section id='editNewOrderFromCompany'>
          <div className='header'>
            <DBackButton alt='/dashboard/tms/orders?page=1' />
          </div>

          <form>
            <ProductNewOrder
              customer={customer}
              setCustomer={setCustomer}
              customer_id_error={customer_id_error}
              orderType={orderType}
              setOrderType={setOrderType}
              type_id_error={type_id_error}
              productType={productType}
              setProductType={setProductType}
              cargos_type_id_error={cargos_type_id_error}
              adr={adr}
              setAdr={setAdr}
              cargos_adr_error={cargos_adr_error}
              adrUnNumber={adrUnNumber}
              setAdrUnNumber={setAdrUnNumber}
              adrClass={adrClass}
              setAdrClass={setAdrClass}
            />
            <RouteNewOrder
              edit
              locations={locations}
              setLocations={setLocations}
              pickUpDate={pickUpDate}
              setPickUpDate={setPickUpDate}
              cargos_pickup_date_error={cargos_pickup_date_error}
              dropOffDate={dropOffDate}
              setDropOffDate={setDropOffDate}
              dropOffShow={dropOffShow}
              setDropOffShow={setDropOffShow}
              pickUpTime={pickUpTime}
              setPickUpTime={setPickUpTime}
              dropOffTime={dropOffTime}
              setDropOffTime={setDropOffTime}
            />
            <TrailersNewOrder
              edit
              orderType={orderType}
              trailers={trailers}
              setTrailers={setTrailers}
              trailer_type_id_error={trailer_type_id_error}
              trailer_size_id_error={trailer_size_id_error}
              cargos_weight_error={cargos_weight_error}
            />
            <PaymentNewOrder
              payMethod={payMethod}
              setPayMethod={setPayMethod}
              payMethodSub={payMethodSub}
              setPayMethodSub={setPayMethodSub}
              payMethodAmount={payMethodAmount}
              setPayMethodAmount={setPayMethodAmount}
              payMethodAmountCurrency={payMethodAmountCurrency}
              setPayMethodAmountCurrency={setPayMethodAmountCurrency}
              payConsignationPeriod={payConsignationPeriod}
              setPayConsignationPeriod={setPayConsignationPeriod}
              ////
              payment_type_id_error={payment_type_id_error}
              payment_option_type_error={payment_option_type_error}
              payment_days_error={payment_days_error}
              payment_amount_error={payment_amount_error}
              payment_amount_currency_error={payment_amount_currency_error}
            />
            <AditionalInformation comment={comment} setComment={setComment} />

            <div className='submitContainer'>
              <DButton
                loading={updateLoading}
                title={t("tms.forms.buttons.Edit order")}
                onClick={() => submitHandler()}
              />
            </div>
          </form>
        </section>
      )}
    </Wrapper>
  );
};

export default EditOrder;
