import React from "react";
import "./SignUpCarrier.scss";

import { useTranslation } from "react-i18next";

import Wrapper from "./../../../../componentsAdditional/Wrapper";
import CarrierApp from "../../../../componentsLanding/Auth&Password/SignUpForms/CarrierApp/CarrierApp";
// import AuthCover from "./../../../../componentsLanding/Auth&Password/Components/AuthCover/AuthCover";
// import SignUpCarrierForm from "./../../../../componentsLanding/Auth&Password/SignUpForms/SignUpCarrierForm/SignUpCarrierForm";

const SignUpCarrier = () => {
  const { t } = useTranslation();

  return (
    <Wrapper documentTitle={t("landing.documentTitles.Sign Up carrier")}>
      <section id='SignUpCarrier'>
        <CarrierApp />
        {/* <AuthCover url='/assets/images/static/cover4.jpg' />
        <SignUpCarrierForm /> */}
      </section>
    </Wrapper>
  );
};

export default SignUpCarrier;
