import React, { useState } from "react";
import "./BidsLang.scss";

import { useSelector, useDispatch } from "react-redux";

import { setLang } from "../../store/additional/lang/langSlice";

import DSelect from "../../componentsDashboard/Form/DSelect/DSelect";

const BidsLang = () => {
  const dispatch = useDispatch();
  const { languagesSelect, ISO } = useSelector((state) => state.lang);

  const [loading, setLoading] = useState(false);

  const currentValueLang =
    languagesSelect && languagesSelect.find((item) => item.value.key === ISO);

  return (
    <div id='BidsLang'>
      <DSelect
        id='BidsLangSelect'
        value={currentValueLang}
        setValue={(lang) => {
          setLoading(true);
          dispatch(setLang(lang.value.key));
        }}
        placeholder=''
        loading={loading}
        options={languagesSelect ? languagesSelect : []}
      />
    </div>
  );
};

export default BidsLang;
