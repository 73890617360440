import React, { useEffect } from "react";
import "./FavoriteRoutesModalItem.scss";

import { useTranslation } from "react-i18next";
import { IoRemoveCircleOutline } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";

import {
  deleteLocation,
  resetDeleteLocation,
  setFavouriteLocationsInput,
} from "../../../../../store/favoriteLocations/favoriteLocationsSlice";

const FavoriteRoutesModalItem = ({ isEditActive, item, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { deleteLocationResponce } = useSelector(
    (state) => state.favoriteLocations
  );

  const getLocationPart = (city, country) =>
    (city ? `${city}, ` : "") + (country || "");
  const Alocation = getLocationPart(item.from_city, item.from_country);
  const Blocation = getLocationPart(item.to_city, item.to_country);
  const location = `${Alocation} - ${Blocation}`;

  const deleteHandler = () => {
    dispatch(deleteLocation(item.id));
  };

  const routeClickHandler = () => {
    dispatch(
      setFavouriteLocationsInput([
        item?.from_formatted_address,
        item?.to_formatted_address,
      ])
    );
    isClose();
  };

  useEffect(() => {
    if (deleteLocationResponce) {
      dispatch(resetDeleteLocation());
    }
  }, [dispatch, deleteLocationResponce]);

  return (
    <li id='FavoriteRoutesModalItem'>
      <div className='FavoriteRoutesModalItemLeft'>
        <h1 onClick={() => routeClickHandler()}>{location}</h1>
        <p>
          {item.available_orders} {t("tms.desc.new order")}
        </p>
      </div>
      <div className='FavoriteRoutesModalItemRight'>
        <div
          className={
            isEditActive
              ? "FavoriteRoutesModalItemIcon show"
              : "FavoriteRoutesModalItemIcon"
          }
          onClick={() => deleteHandler()}
        >
          <IoRemoveCircleOutline />
        </div>
      </div>
    </li>
  );
};

export default FavoriteRoutesModalItem;
