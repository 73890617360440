import _request from "./../_request";

const updateUser = async (ISO, token, data) => {
  const config = {
    url: "/user/profile",
    method: "put",
    ISO,
    token,
  };
  return _request(config, data);
};

const updateUserCompanyLogo = async (ISO, token, data) => {
  const config = {
    url: "/user/profile",
    method: "put",
    ISO,
    token,
    file: true,
  };
  return _request(config, data);
};

const updateUserLanguage = async (ISO, token, data) => {
  const config = {
    url: "/user/profile",
    method: "put",
    ISO,
    token,
  };
  return _request(config, data);
};

const changeEmail = async (ISO, token, data) => {
  const config = {
    url: "/user/change-contact-info/EMAIL",
    method: "post",
    ISO,
    token,
  };
  return _request(config, data);
};

const verifyEmail = async (ISO, token, data) => {
  const config = {
    url: "/user/verify-new-contact-info/EMAIL",
    method: "post",
    ISO,
    token,
  };
  return _request(config, data);
};

const changePhone = async (ISO, token, data) => {
  const config = {
    url: "/user/change-contact-info/PHONE",
    method: "post",
    ISO,
    token,
  };
  return _request(config, data);
};

const verifyPhone = async (ISO, token, data) => {
  const config = {
    url: "/user/verify-new-contact-info/PHONE",
    method: "post",
    ISO,
    token,
  };
  return _request(config, data);
};

const changePassword = async (ISO, token, data) => {
  const config = {
    url: "/user/change-password",
    method: "post",
    ISO,
    token,
  };
  return _request(config, data);
};

const userService = {
  updateUser,
  updateUserCompanyLogo,
  updateUserLanguage,
  changeEmail,
  verifyEmail,
  changePhone,
  verifyPhone,
  changePassword,
};

export default userService;
