import _request from "./../_request";

const getSummary = async (ISO, token) => {
  const config = {
    url: "/user/summary",
    ISO,
    token,
  };
  return _request(config);
};

const summaryService = {
  getSummary,
};

export default summaryService;
