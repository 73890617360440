import React, { Fragment, useEffect, useState } from "react";
import "./ShipperCharts.scss";

import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { getChartAnalytics } from "../../../store/analytics/analyticsSlice";

import { Skeleton } from "@mui/material";

import ShipperChartHeader from "./ShipperChartHeader/ShipperChartHeader";
import CostsChart from "./CostsChart/CostsChart";
import ShipmentsChart from "./ShipmentsChart/ShipmentsChart";
import DistanceChart from "./DistanceChart/DistanceChart";
import WeightCharts from "./WeightCharts/WeightCharts";

const ShipperCharts = () => {
  const dispatch = useDispatch();
  const { getChartAnalyticsResponce, getChartAnalyticsLoading } = useSelector(
    (state) => state.analytics
  );

  const [activeChart, setActiveChart] = useState("costs");
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    dispatch(getChartAnalytics(moment(date).format("YYYY-MM")));
  }, [date, dispatch]);

  const costsDataWithMonths = getChartAnalyticsResponce?.data?.map((month) => {
    const monthStr = Object.keys(month)?.[0];
    const data = month?.[monthStr]?.original;
    return {
      name: moment(monthStr, "YYYY-MM").format("MMM"),
      value: data?.expense,
    };
  });

  const shipmentsWithMonths = getChartAnalyticsResponce?.data?.map((month) => {
    const monthStr = Object.keys(month)?.[0];
    const data = month?.[monthStr]?.original;
    return {
      name: moment(monthStr, "YYYY-MM").format("MMM"),
      value: data?.order_count,
    };
  });

  const distanceWithMonths = getChartAnalyticsResponce?.data?.map((month) => {
    const monthStr = Object.keys(month)?.[0];
    const data = month?.[monthStr]?.original;
    return {
      name: moment(monthStr, "YYYY-MM").format("MMM"),
      value: data?.total_distance?.toFixed(2),
    };
  });

  const weightWithMonths = getChartAnalyticsResponce?.data?.map((month) => {
    const monthStr = Object.keys(month)?.[0];
    const data = month?.[monthStr]?.original;
    return {
      name: moment(monthStr, "YYYY-MM").format("MMM"),
      value: data?.total_weight,
    };
  });

  return (
    <div id='ShipperCharts'>
      <ShipperChartHeader
        activeChart={activeChart}
        setActiveChart={setActiveChart}
        date={date}
        setDate={setDate}
      />
      {getChartAnalyticsLoading ? (
        <Skeleton variant='rounded' className='skeleton' />
      ) : (
        <Fragment>
          {activeChart === "costs" && <CostsChart data={costsDataWithMonths} />}
          {activeChart === "numberOfShipments" && (
            <ShipmentsChart data={shipmentsWithMonths} />
          )}
          {activeChart === "distance" && (
            <DistanceChart data={distanceWithMonths} />
          )}
          {activeChart === "weight" && <WeightCharts data={weightWithMonths} />}
        </Fragment>
      )}
    </div>
  );
};

export default ShipperCharts;
