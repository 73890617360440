import _request from "./../../_request";

const getOrders = async (ISO, TOKEN, data) => {
  let url = "order?";
  const {
    page,
    keyword,
    status_id,
    trailer_type_id,
    start_date,
    end_date,
    cargo_type_id,
    pickup_address,
    dropoff_address,
    type_ids,
  } = data || {};

  url += page ? `?page=${page}` : "";
  url += keyword ? `&keyword=${keyword}` : "";
  url += status_id ? `&status_id=${status_id}` : "";
  url += trailer_type_id ? `&trailer_type_id=${trailer_type_id}` : "";
  url += start_date ? `&start_date=${start_date}` : "";
  url += end_date ? `&end_date=${end_date}` : "";
  url += cargo_type_id ? `&cargo_type_id=${cargo_type_id}` : "";
  url += pickup_address ? `&pickup_address=${pickup_address}` : "";
  url += dropoff_address ? `&dropoff_address=${dropoff_address}` : "";
  if (type_ids && type_ids?.length) {
    type_ids?.forEach((i) => {
      url += `&type_ids[]=${i}`;
    });
  }
  url += `&quotation=accepted`;

  const config = {
    url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getOrder = async (ISO, TOKEN, orderId) => {
  const config = {
    url: `order/${orderId}`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const shipperAcceptedService = {
  getOrders,
  getOrder,
};

export default shipperAcceptedService;
