import React, { Fragment, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import SideBar from "../componentsDashboard/SideBar/SideBar";
import Company from "../routes/Company";

import {
  getOrdersSummary,
  getCustomersSummary,
  getPartnersSummary,
  getTrucksSummary,
  getTrailersSummary,
  getDriversSummary,
  getMaintenancesSummary,
} from "./../store/summary/summarySlice";
import { getTruckBrands } from "./../store/fleet/trucks/trucksSlice";

const AppCompany = () => {
  const dispatch = useDispatch();
  const { openSidebar } = useSelector((state) => state.userUI);
  const { takeBoardResponce } = useSelector((state) => state.loadBoards);
  const { createResponce, deleteResponce } = useSelector(
    (state) => state.orders
  );

  const { createPartnerResponce, deletePartnerResponce } = useSelector(
    (state) => state.partner
  );
  const { createCustomerResponce, deleteCustomerResponce } = useSelector(
    (state) => state.customer
  );
  const { createTruckResponce, deleteTruckResponce } = useSelector(
    (state) => state.trucks
  );
  const { createTrailerResponce, deleteTrailerResponce } = useSelector(
    (state) => state.trailers
  );
  const { createDriverResponce, deleteDriverResponce } = useSelector(
    (state) => state.drivers
  );
  const { createMaintenanceResponce, deleteMaintenanceResponce } = useSelector(
    (state) => state.maintenance
  );

  useEffect(() => {
    dispatch(getTruckBrands());
  }, [dispatch]);

  // count order
  useEffect(() => {
    dispatch(getOrdersSummary());
  }, [dispatch, createResponce, deleteResponce, takeBoardResponce]);

  useEffect(() => {
    dispatch(getCustomersSummary());
    dispatch(getPartnersSummary());
  }, [
    dispatch,
    createPartnerResponce,
    deletePartnerResponce,
    createCustomerResponce,
    deleteCustomerResponce,
  ]);

  // count truck
  useEffect(() => {
    dispatch(getTrucksSummary());
  }, [dispatch, createTruckResponce, deleteTruckResponce]);

  // count trailer
  useEffect(() => {
    dispatch(getTrailersSummary());
  }, [dispatch, createTrailerResponce, deleteTrailerResponce]);

  // count driver
  useEffect(() => {
    dispatch(getDriversSummary());
  }, [dispatch, createDriverResponce, deleteDriverResponce]);

  // count Maintenance
  useEffect(() => {
    dispatch(getMaintenancesSummary());
  }, [dispatch, createMaintenanceResponce, deleteMaintenanceResponce]);

  return (
    <Fragment>
      <SideBar />
      <main className={openSidebar ? "max" : "min"}>{<Company />}</main>
    </Fragment>
  );
};

export default AppCompany;
