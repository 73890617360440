import React, { Fragment, useState, useEffect } from "react";
import "./ContactTable.scss";

import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";

import { getParticipantContacts } from "../../../../store/participants/participantContacts/participantContactsSlice";

import ContactTableItem from "./ContactTableItem/ContactTableItem";
import DTextButton from "./../../../Form/DButtons/DTextButton/DTextButton";
import CreateParticipantContactModal from "./../CreateParticipantContactModal/CreateParticipantContactModal";
import NoInformation from "./../../../Form/TableFields/NoInformation/NoInformation";
import TableHeader from "../../../Form/TableFields/TableHeader/TableHeader";

const ContactTable = ({ participantId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    getParticipantContactsResponce,
    createParticipantContactResponce,
    updateParticipantContactResponce,
    deleteParticipantContactResponce,
  } = useSelector((state) => state.participantContacts);

  const [openCreateModal, setOpenCreateModal] = useState(false);

  useEffect(() => {
    dispatch(getParticipantContacts(participantId));
  }, [
    dispatch,
    participantId,
    createParticipantContactResponce,
    updateParticipantContactResponce,
    deleteParticipantContactResponce,
  ]);

  return (
    <Fragment>
      {getParticipantContactsResponce && (
        <div id='ContactTable'>
          <div className='headerContact'>
            <h2>{t("tms.titles.Contact")}</h2>
            <DTextButton
              title={t("tms.forms.buttons.Add contact")}
              rightIcon={<FiPlus />}
              onClick={() => {
                setOpenCreateModal(true);
              }}
            />
          </div>
          <div className='tableWrapper'>
            <table>
              <TableHeader>
                <th>{t("tms.tables.titles.Full name")}</th>
                <th>{t("tms.tables.titles.Position")}</th>
                <th>{t("tms.tables.titles.Phone number")}</th>
                <th colSpan='2'>{t("tms.tables.titles.Email")}</th>
              </TableHeader>
              <tbody>
                {getParticipantContactsResponce.map((item) => (
                  <ContactTableItem
                    key={item.id}
                    item={item}
                    participantId={participantId}
                  />
                ))}
              </tbody>
            </table>
            {getParticipantContactsResponce.length === 0 && (
              <NoInformation title={t("tms.tables.noInfo.Contact not found")} />
            )}
          </div>
        </div>
      )}
      <CreateParticipantContactModal
        participantId={participantId}
        isOpen={openCreateModal}
        isClose={() => setOpenCreateModal(false)}
      />
    </Fragment>
  );
};

export default ContactTable;
