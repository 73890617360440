import React, { useState } from "react";
import "./DInputs.scss";

import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";

import DLabel from "./../DLabel/DLabel";
import DErrorText from "../DErrorText/DErrorText";

const DInputs = ({
  id,
  value,
  setValue,
  placeholder,
  type,
  error,
  icon,
  autoFocus,
  label,
  autoComplete,
  required,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div id='DInputs' className={error ? "error" : ""}>
      {label && <DLabel label={label} required={required} htmlFor={id} />}
      <div className='group'>
        <input
          id={id}
          autoComplete={autoComplete ? autoComplete : "false"}
          name={id}
          type={type === "password" ? (showPassword ? "text" : "password") : type ? type : "text"}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder={placeholder}
          autoFocus={autoFocus ? autoFocus : false}
          onWheel={(e) => e.target.blur()}
        />
        {type === "password" && value && value.length > 2 && (
          <div
            className='DInputsIcon'
            onClick={(e) => {
              e.stopPropagation();
              setShowPassword((state) => !state);
            }}
          >
            {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
          </div>
        )}

        {icon && <div className='DInputsIcon'>{icon}</div>}
      </div>
      {error && <DErrorText error={error} />}
    </div>
  );
};

export default DInputs;
