import React, { useState, Fragment } from "react";

import { useTranslation } from "react-i18next";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FiMoreVertical, FiEdit2, FiTrash2 } from "react-icons/fi";

import DeletePartnerModal from "./../../DeletePartnerModal/DeletePartnerModal";
import EditPartnerModal from "./../../EditPartnerModal/EditPartnerModal";
import RowField from "../../../../Form/TableFields/RowField/RowField";
import TextField from "../../../../Form/TableFields/TextField/TextField";
import MoreField from "../../../../Form/TableFields/MoreField/MoreField";
import DateField from "../../../../Form/TableFields/DateField/DateField";
import PartnerField from "./../../../../Form/TableFields/PartnerField/PartnerField";
import RateField from "../../../../Form/TableFields/RateField/RateField";

const PartnersTableItem = ({ item }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <RowField>
        <PartnerField
          max={50}
          label={item?.company?.name || "-"}
          to={`/dashboard/tms/customers_partners/partners/${item.id}`}
        />
        <TextField max={30} label={item.company?.legal_id || "-"} />
        <TextField max={30} label={item.company?.email || "-"} />

        <RateField rate={item?.rating || 0} />

        <TextField
          label={
            (item.company?.phone_index?.name || "") +
            (item.company?.phone || "-")
          }
        />
        <DateField date={item.analytics.last_order_date} />
        <MoreField>
          <div className='icon' onClick={handleClick}>
            <FiMoreVertical />
          </div>
          <Menu
            id='OrdersTableItemMorePartners'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenEditModal(true);
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <FiEdit2 />
                {t("tms.tables.buttons.Edit")}
              </div>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenDeleteModal(true);
                handleClose();
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  color: "red",
                }}
              >
                <FiTrash2 />
                {t("tms.tables.buttons.Delete")}
              </div>
            </MenuItem>
          </Menu>
        </MoreField>
      </RowField>

      <DeletePartnerModal
        participantId={item.id}
        isOpen={openDeleteModal}
        isClose={() => setOpenDeleteModal(false)}
      />
      <EditPartnerModal
        participant={item}
        isOpen={openEditModal}
        isClose={() => setOpenEditModal(false)}
      />
    </Fragment>
  );
};

export default PartnersTableItem;
