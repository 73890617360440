import React, { useState } from "react";
import "./ShowInvoiceField.scss";

import { useTranslation } from "react-i18next";

import { MdInsertDriveFile } from "react-icons/md";
import { Menu, MenuItem } from "@mui/material";
import { FaCheckCircle } from "react-icons/fa";

const ShowInvoiceField = ({ item }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const invoiceDocuments = item?.documents?.filter(
    (doc) => doc.type.name === "Invoice"
  );

  const RenderItem = ({ document }) => {
    return (
      <MenuItem
        style={{
          minWidth: "150px",
          fontSize: "15px",
          color: "#2e5aac",
          width: "250px",
        }}
        onClick={() => {
          window.open(document?.url || "", "_blank");
          handleClose();
        }}
      >
        {document?.type?.name || ""}
      </MenuItem>
    );
  };

  return (
    <td id='ShowInvoiceField'>
      {invoiceDocuments?.length > 0 ? (
        <div className='ShowInvoiceFieldContainer'>
          <div className='viewInvoicesButton' onClick={handleClick}>
            {t("shipper.table.desc.View Invoices")}
            <div className='icon'>
              <MdInsertDriveFile />
            </div>
            <div className='payd'>
              <FaCheckCircle />
            </div>
          </div>
          <Menu
            id='ShowInvoiceFieldInvoiceList'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {invoiceDocuments?.map((document, index) => (
              <RenderItem key={index} document={document} />
            ))}
          </Menu>
        </div>
      ) : (
        <div className='NoInvoiceFieldContainer'>
          <p>{t("shipper.table.desc.No invoice")}</p>
        </div>
      )}
    </td>
  );
};

export default ShowInvoiceField;
