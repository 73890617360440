import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { FiTrash2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  deleteOrder,
  resetDeleteOrder,
} from "./../../../store/orders/ordersSlice";
import DModal from "./../../Modal/DModal/DModal";

const DeleteOrder = ({ isOpen, isClose, orderId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { deleteLoading, deleteResponce } = useSelector(
    (state) => state.orders
  );

  useEffect(() => {
    if (deleteResponce) {
      dispatch(resetDeleteOrder());
      isClose();
      navigate("/dashboard/tms/orders");
    }
  }, [dispatch, navigate, isClose, deleteResponce]);

  return (
    <DModal
      isOpen={isOpen}
      isClose={isClose}
      onClick={() => dispatch(deleteOrder(orderId))}
      title={t("tms.modals.titles.Delete order")}
      submitText={t("tms.modals.buttons.Delete")}
      icon={<FiTrash2 />}
      status='danger'
      loading={deleteLoading}
    >
      <p>{t("tms.modals.desc.Are you sure you want to delete your order?")}</p>
    </DModal>
  );
};

export default DeleteOrder;
