import React from "react";
import "./RegisterButton.scss";

import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const RegisterButton = () => {
  const { t } = useTranslation();

  return (
    <div id='RegisterButton'>
      <NavLink to='/register'>{t("landing.form.button.Get started")}</NavLink>
    </div>
  );
};

export default RegisterButton;
