import React from "react";
import "./MapView.scss";

import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";

import { useSelector } from "react-redux";

import { GOOGLE_KEY } from "./../../../constants/GOOGLE_KEY";
import { settings } from "./mapSettings";
import LoadingPage from "./../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import Marker from "../Components/Marker/Marker";

const MapView = ({ tab, items, activeItem, setActiveItem }) => {
  const { ISO } = useSelector((state) => state.lang);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_KEY.GOOGLE_API_KEY,
    language: [ISO],
  });
  return (
    <div id='TrackingMapView'>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={settings.containerStyle}
          center={settings.center}
          zoom={activeItem ? 5 : 4}
        >
          {items &&
            tab === "tracking" &&
            items
              .filter((item) => item.location !== null)
              .map((item) => (
                <Marker
                  key={item.id}
                  item={item}
                  activeItem={activeItem}
                  setActiveItem={setActiveItem}
                />
              ))}
        </GoogleMap>
      ) : (
        <LoadingPage />
      )}
    </div>
  );
};

export default MapView;
