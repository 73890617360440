import React, { Fragment, useState, useEffect } from "react";
import "./EditTrailersModal.scss";

import { useTranslation } from "react-i18next";
import { FiEdit2 } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";

import {
  updateTrailer,
  resetUpdateTrailer,
} from "../../../../store/fleet/trailers/trailersSlice";
import { useTrailerErrors } from "./../../../../validations/fleet/useTrailerErrors";

import DModal from "../../../Modal/DModal/DModal";
import DSelect from "../../../Form/DSelect/DSelect";
import DInputs from "../../../Form/DInputs/DInputs";

const EditTrailersModal = ({ isOpen, isClose, trailer }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    updateTrailerLoading,
    updateTrailerResponce,
    getTrailerTypesLoading,
    getTrailerGroupSelectResponse,
  } = useSelector((state) => state.trailers);
  const {
    update_trailer_type_id_error,
    update_volume_capacity_error,
    update_weight_capacity_error,
    update_registration_number_error,
  } = useTrailerErrors();

  const [trailerType, setTrailerType] = useState(null);
  const [maxVolume, setMaxVolume] = useState(
    trailer.volume_capacity ? trailer.volume_capacity : ""
  );
  const [maxWeight, setMaxWeight] = useState(
    trailer.weight_capacity ? trailer.weight_capacity : ""
  );
  const [plateNumber, setPlateNumber] = useState(
    trailer.registration_number ? trailer.registration_number : ""
  );

  const submitHandler = () => {
    const data = {
      id: trailer.id,
      trailer_type_group_id: trailerType && trailerType.value.id,
      volume_capacity: maxVolume,
      weight_capacity: maxWeight,
      registration_number: plateNumber,
    };
    dispatch(updateTrailer(data));
  };

  const filterOption = (option, inputValue) => {
    const { value } = option;
    return value.name.toLowerCase().includes(inputValue.toLowerCase());
  };

  useEffect(() => {
    if (getTrailerGroupSelectResponse) {
      const findTrailerType = getTrailerGroupSelectResponse.find(
        (i) => i.value.id === trailer.type.id
      );
      setTrailerType(findTrailerType);
    }
  }, [getTrailerGroupSelectResponse, trailer.type.id]);

  useEffect(() => {
    if (updateTrailerResponce) {
      dispatch(resetUpdateTrailer());
      isClose();
    }
  }, [dispatch, isClose, updateTrailerResponce]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose(false)}
        title={t("tms.modals.titles.Edit Trailer")}
        submitText={t("tms.modals.buttons.Save")}
        icon={<FiEdit2 />}
        status='success'
        onClick={() => submitHandler()}
        loading={updateTrailerLoading}
      >
        <div id='EditTrailersModal'>
          <div className='EditTrailersModalGroup'>
            <DSelect
              value={trailerType}
              setValue={setTrailerType}
              label={t("tms.modals.labels.Trailer")}
              placeholder={t("tms.modals.placeholder.Choose trailer type")}
              error={update_trailer_type_id_error}
              id='chooseTrailerType'
              options={getTrailerGroupSelectResponse}
              loading={getTrailerTypesLoading}
              filterOption={filterOption}
              required
              search
            />
            <DInputs
              id='trailerMaxWeight'
              label={t("tms.modals.labels.Max weight (KG)")}
              placeholder={t("tms.modals.placeholder.Enter max weight")}
              value={maxWeight}
              setValue={setMaxWeight}
              error={update_weight_capacity_error}
              required
            />
            <DInputs
              id='trailerMaxVolume'
              label={t("tms.modals.labels.Max volume (M³)")}
              placeholder={t("tms.modals.placeholder.Enter max volume")}
              value={maxVolume}
              setValue={setMaxVolume}
              error={update_volume_capacity_error}
              required
            />
            <DInputs
              id='trailerRegistrationNumber'
              label={t("tms.modals.labels.Registration plate number")}
              placeholder={t(
                "tms.modals.placeholder.Enter registration plate number"
              )}
              value={plateNumber}
              setValue={setPlateNumber}
              required
              error={update_registration_number_error}
            />
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default EditTrailersModal;
