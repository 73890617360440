import React, { useEffect, Fragment } from "react";
import "./NotificationModal.scss";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  getNewNotifications,
  resetGetNewNotifications,
} from "../../../store/notifications/notificationsSlice";

import DOverlay from "../../Modal/DOverlay/DOverlay";
import DTextButton from "../../Form/DButtons/DTextButton/DTextButton";
import NotificationsPageHeader from "../NotificationsPageHeader/NotificationsPageHeader";
import NotificationItem from "../NotificationItem/NotificationItem";
import LoadingPage from "../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import NoNotifications from "../NoNotifications/NoNotifications";

const NotificationModal = ({ isOpen, isClose, open, shipper }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userType } = useSelector((state) => state.auth);

  const {
    getNewNotiLoading,
    getNewNotiResult,
    getNewNotiMeta,
    markAllNotiResult,
    markNotiResult,
  } = useSelector((state) => state.notifications);

  useEffect(() => {
    dispatch(getNewNotifications({ page: 1, read_at: true }));
    if (isOpen) {
      dispatch(getNewNotifications({ page: 1, read_at: true }));
    }
    const intervalId = setInterval(() => {
      dispatch(getNewNotifications({ page: 1, read_at: true }));
    }, 90 * 1000);
    return () => {
      clearInterval(intervalId);
      dispatch(resetGetNewNotifications());
    };
  }, [dispatch, isOpen, markAllNotiResult, markNotiResult]);

  let navigateUrl =
    userType === 4
      ? "/dashboard/tms/notifications"
      : userType === 5
      ? "/dashboard/shipper/notifications"
      : undefined;

  return (
    <DOverlay isOpen={isOpen} isClose={isClose} transparent>
      <div
        id='NotificationModal'
        className={`${open ? "open" : ""}${shipper ? "shipper" : ""}`}
      >
        <NotificationsPageHeader />

        {getNewNotiLoading && <LoadingPage />}
        {getNewNotiResult && getNewNotiMeta && (
          <div className='NotificationModalContent'>
            {getNewNotiMeta.total === 0 ? (
              <NoNotifications />
            ) : (
              <Fragment>
                {getNewNotiResult.map((item) => (
                  <NotificationItem key={item.id} notification={item} />
                ))}
              </Fragment>
            )}
          </div>
        )}
        <div className='NotificationModalFooter'>
          <DTextButton
            title={t("tms.notifications.See all")}
            onClick={() => {
              navigate(navigateUrl);
              isClose();
            }}
          />
        </div>
      </div>
    </DOverlay>
  );
};

export default NotificationModal;
