import React from "react";
import "./SignUpCarrierAuth.scss";

import { useTranslation } from "react-i18next";

import Wrapper from "./../../../../componentsAdditional/Wrapper";
import AuthCover from "./../../../../componentsLanding/Auth&Password/Components/AuthCover/AuthCover";
import SignUpCarrierAuthForm from "./../../../../componentsLanding/Auth&Password/SignUpForms/SignUpCarrierAuthForm/SignUpCarrierAuthForm";

const SignUpCarrierAuth = () => {
  const { t } = useTranslation();

  return (
    <Wrapper documentTitle={t("landing.documentTitles.Sign Up carrier")}>
      <section id='SignUpCarrierAuth'>
        <AuthCover url='/assets/images/static/cover4.jpg' />
        <SignUpCarrierAuthForm />
      </section>
    </Wrapper>
  );
};

export default SignUpCarrierAuth;
