import React from "react";
import "./TrucksListHeader.scss";

import { useTranslation } from "react-i18next";

import { FiPlus } from "react-icons/fi";
import { IoLink } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import DSearch from "./../../../FilterForms/DSearch/DSearch";
import DButton from "./../../../Form/DButtons/DButton/DButton";
import FilterTruckStatusSelect from "../../../FilterForms/FilterTruckStatusSelect/FilterTruckStatusSelect";

const TrucksListHeader = ({
  keyword,
  setKeyword,
  active,
  setActive,
  setOpenAddModal,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className='TrucksListHeader'>
      <div className='TrucksListHeaderLeft'>
        <DSearch value={keyword} setValue={setKeyword} />
        <FilterTruckStatusSelect value={active} setValue={setActive} />
      </div>
      <div className='TrucksListHeaderRight'>
        <div className='TrucksListHeaderRightBlackButton'>
          <DButton
            title={t("tms.forms.buttons.Connect tracking device")}
            rightIcon={<IoLink />}
            onClick={() => navigate("/dashboard/tms/tracker/GPS_tracking")}
          />
        </div>
        <DButton
          title={t("tms.forms.buttons.Add truck")}
          rightIcon={<FiPlus />}
          onClick={() => {
            setOpenAddModal(true);
          }}
        />
      </div>
    </div>
  );
};

export default TrucksListHeader;
