import React from "react";
import "./CarrierAppTitle.scss";

import { useTranslation } from "react-i18next";

const CarrierAppTitle = () => {
  const { t } = useTranslation();

  return (
    <div id='CarrierAppTitle'>
      <h1>{t("landing.auth.titles.Download the Cargon app")}</h1>
      <div>
        <p>
          {t(
            "landing.auth.titles.Earn more with less hassle when you use the free Cargon app to book freight."
          )}
        </p>
        <br />
        <p>
          {t(
            "landing.auth.titles.With everything done quickly on your smart phone, you’ll spend less time on operational work, phone calls, and chasing freight, and more time on what matters."
          )}
        </p>
      </div>
    </div>
  );
};

export default CarrierAppTitle;
