import _request from "../../_request";

const getPartners = async (ISO, TOKEN, data) => {
  let url = "participants?type=Partner";
  const { page, keyword, orderId, route_id, trailer_type_id, region_id } =
    data || {};

  const routeIds = route_id ? [route_id] : [];
  region_id && routeIds.push(region_id);

  url += page ? `&page=${page}` : "";
  url += keyword ? `&keyword=${keyword}` : "";
  url += orderId ? `&order=${orderId}` : "";
  url += routeIds?.length > 0 ? `&route_id[]=${routeIds}` : "";
  url += trailer_type_id ? `&trailer_type_id=${trailer_type_id}` : "";
  const config = {
    url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getPartner = async (ISO, TOKEN, participantId) => {
  const config = {
    url: `participants/${participantId}`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const createPartner = async (ISO, TOKEN, data) => {
  const config = {
    url: "participants",
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const updatePartner = async (ISO, TOKEN, participantId, data) => {
  const config = {
    url: `participants/${participantId}`,
    method: "put",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const deletePartner = async (ISO, TOKEN, participantId) => {
  const config = {
    url: `participants/${participantId}`,
    method: "delete",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const ratePartner = async (ISO, TOKEN, participantId, data) => {
  const config = {
    url: `/participants/${participantId}/rate`,
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const partnerService = {
  getPartners,
  getPartner,
  createPartner,
  updatePartner,
  deletePartner,
  ratePartner,
};

export default partnerService;
