import React from "react";
import "./RoadPackageItem.scss";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { MdOutlineRemoveCircleOutline } from "react-icons/md";

import DSelect from "../../../../../Form/DSelect/DSelect";
import DQuantitySelect from "./../../../../../Form/DSelect/DQuantitySelect/DQuantitySelect";

const RoadPackageItem = ({
  edit,
  index,
  item,
  group,
  setGroup,
  trailer_type_id_error,
  trailer_size_id_error,
  temperature_range_id_error,
}) => {
  const { t } = useTranslation();
  const {
    trailerTypesLoading,
    trailerTypesSelect,
    temperaturesLoading,
    temperaturesSelect,
  } = useSelector((state) => state.orderSettings);

  const tailerTypeHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].trailerType = value;
    updatedGroup[index].trailerSize = null;
    updatedGroup[index].temperatureRange = null;
    setGroup(updatedGroup);
  };

  const traileVolumeHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].trailerSize = value;
    setGroup(updatedGroup);
  };

  const temperatureHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].temperatureRange = value;
    setGroup(updatedGroup);
  };

  const quantityHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].quantity = value;
    setGroup(updatedGroup);
  };

  const removeItemHandler = () => {
    const updatedGroup = [...group];
    updatedGroup.splice(index, 1);
    setGroup(updatedGroup);
  };

  const trailerSizeOptions = item?.trailerType?.value?.sizes?.map((size) => {
    return {
      label: size.label,
      value: size,
    };
  });

  return (
    <div id='RoadPackageItem'>
      <div className='BlockA'>
        <div className='RoadPackageTrailer'>
          <DSelect
            required
            value={item.trailerType}
            setValue={(value) => tailerTypeHandler(value)}
            id={`CreateOrderContainerGroup${index}`}
            label={t("shipper.forms.content.Trailer type")}
            placeholder={t("shipper.forms.content.Select trailer")}
            options={trailerTypesSelect}
            loading={trailerTypesLoading}
            error={trailer_type_id_error}
          />
        </div>
        {item && item.trailerType && item.trailerType.value.temperature && (
          <div className='RoadPackageTemperature'>
            <DSelect
              required
              value={item.temperatureRange}
              setValue={(value) => temperatureHandler(value)}
              id={`AddOrderTrailertemperatureSelect${index}`}
              loading={temperaturesLoading}
              label={t("shipper.forms.content.Temperature")}
              placeholder={t("shipper.forms.content.Select temperature")}
              options={temperaturesSelect}
              error={temperature_range_id_error}
            />
          </div>
        )}
      </div>

      <div className='BlockB'>
        <div className='RoadPackageItemVolume'>
          <DSelect
            required
            value={item.trailerSize}
            setValue={(value) => traileVolumeHandler(value)}
            id={`AddOrderTrailerVolumeSelect${index}`}
            loading={false}
            label={t("shipper.forms.content.Trailer volume")}
            placeholder={t("shipper.forms.content.Select volume")}
            options={trailerSizeOptions || []}
            disabled={!item.trailerType}
            error={trailer_size_id_error}
          />
        </div>

        {!edit && (
          <div className='RoadPackageQuantity'>
            <DQuantitySelect
              value={item.quantity}
              setValue={(value) => quantityHandler(value)}
              label={t("shipper.forms.content.Number of trailers")}
              error={null}
            />

            {group.length > 1 && (
              <div className='removeBtn' onClick={() => removeItemHandler()}>
                <MdOutlineRemoveCircleOutline />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default RoadPackageItem;
