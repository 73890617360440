import _request from "./../../_request";

// direction - distibution - Collection
const getOrderTransferTypes = async (ISO, TOKEN, branchId) => {
  const config = {
    url: `/order/transfer-types/${branchId}`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

// FTL - LTL
const getOrderTypes = async (ISO, TOKEN, branchId) => {
  const config = {
    url: `/order/types/${branchId}`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

// ალკოჰოლი - საკვები და სასმელი
const getCargoTypes = async (ISO, TOKEN) => {
  const config = {
    url: "/cargo-types",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

// trailer-types by branchId, ordertype
const getTrailerTypes = async (ISO, TOKEN, branchId, ordertypeId) => {
  const config = {
    url: `/trailer-types/${branchId}/${ordertypeId}`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

//getTemperatureRanges
const getTemperatureRanges = async (ISO, TOKEN) => {
  const config = {
    url: "/order/temperature-ranges",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

//get Order Payment Type
const getOrderPaymentType = async (ISO, TOKEN, branchId) => {
  const config = {
    url: `/order/payment-types/${branchId}`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

//get order services list
const getOrderServicesList = async (ISO, TOKEN) => {
  const config = {
    url: "/order/services",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

// get cargo package list
const getCargoPackageList = async (ISO, TOKEN) => {
  const config = {
    url: "cargo-packing-types",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const orderSettingsService = {
  getOrderTransferTypes,
  getOrderTypes,
  getCargoTypes,
  getTrailerTypes,
  getTemperatureRanges,
  getOrderPaymentType,
  getOrderServicesList,
  getCargoPackageList,
};

export default orderSettingsService;
