import React, { Fragment, useState, useEffect } from "react";
import "./TruckList.scss";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import {
  getTrucks,
  resetGetTrucks,
} from "./../../../../../../store/fleet/trucks/trucksSlice";

import EmptyList from "./../../../../../../componentsDashboard/UI/EmptyList/EmptyList";
import Wrapper from "../../../../../../componentsAdditional/Wrapper";
import PageSwitcher from "../../../../../../componentsDashboard/PageSwitcher/PageSwitcher";
import AddTrucksModal from "./../../../../../../componentsDashboard/FleetManagement/FleetManagementTrucks/AddTrucksModal/AddTrucksModal";
import TrucksTable from "./../../../../../../componentsDashboard/FleetManagement/FleetManagementTrucks/TrucksTable/TrucksTable";
import LoadingPage from "../../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import TrucksListHeader from "./../../../../../../componentsDashboard/FleetManagement/FleetManagementTrucks/TrucksListHeader/TrucksListHeader";

const TruckList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { getTruckscountResponce, getTruckscountLoading } = useSelector(
    (state) => state.summary
  );

  const {
    getTrucksLoading,
    getTrucksResponce,
    createTruckResponce,
    updateTruckResponce,
    deleteTruckResponce,
    manageTruckResponce,
  } = useSelector((state) => state.trucks);

  const [openAddModal, setOpenAddModal] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [active, setActive] = useState(null);

  useEffect(() => {
    dispatch(
      getTrucks({
        keyword,
        active: active?.value,
      })
    );
    return () => {
      dispatch(resetGetTrucks());
    };
  }, [
    dispatch,
    createTruckResponce,
    updateTruckResponce,
    deleteTruckResponce,
    manageTruckResponce,
    keyword,
    active,
  ]);

  return (
    <Wrapper documentTitle={t("tms.documentTitles.Trucks")}>
      <section id='TruckList'>
        <PageSwitcher name='trucks' />
        {getTruckscountLoading && <LoadingPage />}
        {getTruckscountResponce && (
          <Fragment>
            {getTruckscountResponce.trucks_count === 0 ? (
              <EmptyList
                title={t("tms.titles.No Truck")}
                description={t(
                  "tms.desc.Add truck, make it active and take order whenever you want"
                )}
                buttonText={t("tms.forms.buttons.Add truck")}
                image='/assets/images/dashboard/cover2.png'
                onClick={() => {
                  setOpenAddModal(true);
                }}
              />
            ) : (
              <Fragment>
                <TrucksListHeader
                  keyword={keyword}
                  setKeyword={setKeyword}
                  active={active}
                  setActive={setActive}
                  setOpenAddModal={setOpenAddModal}
                />
                {getTrucksLoading && <LoadingPage />}
                {getTrucksResponce && (
                  <TrucksTable trucks={getTrucksResponce} />
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      </section>
      <AddTrucksModal isOpen={openAddModal} isClose={setOpenAddModal} />
    </Wrapper>
  );
};

export default TruckList;
