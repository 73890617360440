import React from "react";
import "./NoDataField.scss";

import { useTranslation } from "react-i18next";

const NoDataField = ({ title }) => {
  const { t } = useTranslation();

  return (
    <td id='NoDataFieldTable'>
      <p>{title ? title : t("tms.tables.noInfo.no information")}</p>
    </td>
  );
};

export default NoDataField;
