import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import loadBoardsService from "./loadBoardsService";

const initialState = {
  getBoardsLoading: true,
  getBoardsResult: null,
  getBoardsMeta: null,
  getBoardsError: null,

  getBoardLoading: true,
  getBoardResponce: null,
  getBoardError: null,

  getBidsLoading: true,
  getBidsResult: null,
  getBidsMeta: null,
  getBidsError: null,

  takeBoardLoading: false,
  takeBoardResponce: null,
  takeBoardError: null,

  refuseBoardLoading: false,
  refuseBoardResponce: null,
  refuseBoardError: null,

  biddingBoardLoading: false,
  biddingBoardResponce: null,
  biddingBoardError: null,
};

export const getBoards = createAsyncThunk(
  "loadBoards/getBoards",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await loadBoardsService.getBoards(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getBoard = createAsyncThunk(
  "loadBoards/getBoard",
  async (id, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await loadBoardsService.getBoard(ISO, TOKEN, id);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getBids = createAsyncThunk(
  "loadBoards/getBids",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await loadBoardsService.getBids(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const takeBoard = createAsyncThunk(
  "loadBoards/takeBoard",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await loadBoardsService.takeBoard(ISO, TOKEN, data.id, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const refuseBoard = createAsyncThunk(
  "loadBoards/refuseBoard",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await loadBoardsService.refuseBoard(ISO, TOKEN, data.id, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const biddingBoard = createAsyncThunk(
  "loadBoards/biddingBoard",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await loadBoardsService.biddingBoard(ISO, TOKEN, data.id, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const loadBoardsSlice = createSlice({
  name: "loadBoards",
  initialState,
  reducers: {
    resetGetBoards: (state) => {
      state.getBoardsLoading = true;
      state.getBoardsResult = null;
      state.getBoardsMeta = null;
      state.getBoardsError = null;
    },
    resetGetBoard: (state) => {
      state.getBoardLoading = true;
      state.getBoardResponce = null;
      state.getBoardError = null;
    },
    resetGetBids: (state) => {
      state.getBidsLoading = true;
      state.getBidsResult = null;
      state.getBidsMeta = null;
      state.getBidsError = null;
    },
    resetTakeBoard: (state) => {
      state.takeBoardLoading = false;
      state.takeBoardResponce = null;
      state.takeBoardError = null;
    },
    resetRefuseBoard: (state) => {
      state.refuseBoardLoading = false;
      state.refuseBoardResponce = null;
      state.refuseBoardError = null;
    },
    resetBiddingBoard: (state) => {
      state.biddingBoardLoading = false;
      state.biddingBoardResponce = null;
      state.biddingBoardError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBoards.pending, (state) => {
        state.getBoardsLoading = true;
        state.getBoardsResult = null;
        state.getBoardsMeta = null;
        state.getBoardsError = null;
      })
      .addCase(getBoards.fulfilled, (state, action) => {
        state.getBoardsLoading = false;
        state.getBoardsResult = action.payload.data;
        state.getBoardsMeta = action.payload.meta;
        state.getBoardsError = null;
      })
      .addCase(getBoards.rejected, (state, action) => {
        state.getBoardsLoading = false;
        state.getBoardsResult = null;
        state.getBoardsMeta = null;
        state.getBoardsError = action.payload;
      })

      .addCase(getBoard.pending, (state) => {
        state.getBoardLoading = true;
        state.getBoardResponce = null;
        state.getBoardError = null;
      })
      .addCase(getBoard.fulfilled, (state, action) => {
        state.getBoardLoading = false;
        state.getBoardResponce = action.payload;
        state.getBoardError = null;
      })
      .addCase(getBoard.rejected, (state, action) => {
        state.getBoardLoading = false;
        state.getBoardResponce = null;
        state.getBoardError = action.payload;
      })

      .addCase(getBids.pending, (state) => {
        state.getBidsLoading = true;
        state.getBidsResult = null;
        state.getBidsMeta = null;
        state.getBidsError = null;
      })
      .addCase(getBids.fulfilled, (state, action) => {
        state.getBidsLoading = false;
        state.getBidsResult = action.payload.data;
        state.getBidsMeta = action.payload.meta;
        state.getBidsError = null;
      })
      .addCase(getBids.rejected, (state, action) => {
        state.getBidsLoading = false;
        state.getBidsResult = null;
        state.getBidsMeta = null;
        state.getBidsError = action.payload;
      })

      .addCase(takeBoard.pending, (state) => {
        state.takeBoardLoading = true;
        state.takeBoardResponce = null;
        state.takeBoardError = null;
      })
      .addCase(takeBoard.fulfilled, (state, action) => {
        state.takeBoardLoading = false;
        state.takeBoardResponce = action.payload;
        state.takeBoardError = null;
      })
      .addCase(takeBoard.rejected, (state, action) => {
        state.takeBoardLoading = false;
        state.takeBoardResponce = null;
        state.takeBoardError = action.payload;
      })

      .addCase(refuseBoard.pending, (state) => {
        state.refuseBoardLoading = true;
        state.refuseBoardResponce = null;
        state.refuseBoardError = null;
      })
      .addCase(refuseBoard.fulfilled, (state, action) => {
        state.refuseBoardLoading = false;
        state.refuseBoardResponce = action.payload;
        state.refuseBoardError = null;
      })
      .addCase(refuseBoard.rejected, (state, action) => {
        state.refuseBoardLoading = false;
        state.refuseBoardResponce = null;
        state.refuseBoardError = action.payload;
      })

      .addCase(biddingBoard.pending, (state) => {
        state.biddingBoardLoading = true;
        state.biddingBoardResponce = null;
        state.biddingBoardError = null;
      })
      .addCase(biddingBoard.fulfilled, (state, action) => {
        state.biddingBoardLoading = false;
        state.biddingBoardResponce = action.payload;
        state.biddingBoardError = null;
      })
      .addCase(biddingBoard.rejected, (state, action) => {
        state.biddingBoardLoading = false;
        state.biddingBoardResponce = null;
        state.biddingBoardError = action.payload;
      });
  },
});

export const {
  resetGetBoards,
  resetGetBoard,
  resetGetBids,
  resetTakeBoard,
  resetRefuseBoard,
  resetBiddingBoard,
} = loadBoardsSlice.actions;

export default loadBoardsSlice.reducer;
