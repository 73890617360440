import _request from "./../_request";

const getTracking = async (ISO, TOKEN, data) => {
  let url = "/tracking/trucks?";
  const { keyword } = data || {};
  url += keyword ? `&keyword=${keyword}` : "";

  const config = {
    url: url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const trackerManagerService = { getTracking };

export default trackerManagerService;
