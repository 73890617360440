import React from "react";
import "./RoadPackage.scss";

import { FiPlusCircle } from "react-icons/fi";
import { useTranslation } from "react-i18next";

import CreateOrderSectionTitle from "./../../Components/CreateOrderSectionTitle/CreateOrderSectionTitle";
import RoadPackageItem from "./RoadPackageItem/RoadPackageItem";
import CreateOrderProductType from "./../../Components/CreateOrderProductType/CreateOrderProductType";
import DInputs from "./../../../../Form/DInputs/DInputs";
import CreateOrderAdrSection from "./../../Components/CreateOrderAdrSection/CreateOrderAdrSection";
import CreateOrderSectionSecondaryTitle from "./../../Components/CreateOrderSectionSecondaryTitle/CreateOrderSectionSecondaryTitle";
import RoadWeightGroup from "./RoadPackageItem/RoadWeightGroup/RoadWeightGroup";
import DButton from "./../../../../Form/DButtons/DButton/DButton";
import CreateOrderOversizedSection from "../../Components/CreateOrderOversizedSection/CreateOrderOversizedSection";

const RoadPackage = ({
  edit,
  //////////
  group,
  setGroup,
  orders,
  setOrders,
  //////////////////////
  cargoType,
  setCargoType,
  totalWeight,
  setTotalWeight,
  HSCode,
  setHSCode,
  HSCodeValue,
  setHSCodeValue,
  adr,
  setAdr,
  adrNumber,
  setAdrNumber,
  adrClass,
  setAdrClass,
  adrFile,
  setAdrFile,
  oversized,
  setOversized,
  oversizedFile,
  setOversizedFile,
  oversizedCall,
  setOversizedCall,
  // unknownContainer,
  // setUnknownContainer,
  // errors
  trailer_type_id_error,
  trailer_size_id_error,
  temperature_range_id_error,
  hs_code_error,
  cargos_type_id_error,
  cargos_weight_error,
  cargos_adr_error,
  cargos_un_number_error,
  cargos_adr_class_error,
  cargos_msds_file_error,
  cargos_oversized_error,
  cargos_oversized_file_error,
  cargos_oversized_call_error,
  // cargos_unknown_container_error,
}) => {
  const { t } = useTranslation();
  const createNewGroupHandler = () => {
    const newGroup = [
      ...group,
      {
        trailerType: null,
        trailerSize: null,
        weight: "",
        temperatureRange: null,
        quantity: 1,
      },
    ];
    setGroup(newGroup);
  };

  return (
    <div id='ShipperRoadPackage'>
      <CreateOrderSectionTitle
        title={t("shipper.forms.titles.Order requirements")}
      />
      <div className='ShipperRoadPackageContainer'>
        <div className='productWrapper'>
          <div className='productWrapperProduct'>
            <CreateOrderProductType
              cargoType={cargoType}
              setCargoType={setCargoType}
              HSCode={HSCode}
              setHSCode={setHSCode}
              HSCodeValue={HSCodeValue}
              setHSCodeValue={setHSCodeValue}
              cargos_type_id_error={cargos_type_id_error}
              hs_code_error={hs_code_error}
            />
          </div>
          <div className='TotalproductWrapperWeight'>
            <DInputs
              required
              type={"number"}
              id={`ShipperAirPackageItemTotalWeight`}
              placeholder={t("shipper.forms.content.Enter weight")}
              label={
                edit
                  ? t("shipper.forms.content.Weight (KG)")
                  : t("shipper.forms.content.Total weight (KG)")
              }
              value={totalWeight}
              setValue={setTotalWeight}
              error={cargos_weight_error}
            />
          </div>
        </div>

        <CreateOrderAdrSection
          adr={adr}
          setAdr={setAdr}
          adrNumber={adrNumber}
          setAdrNumber={setAdrNumber}
          adrClass={adrClass}
          setAdrClass={setAdrClass}
          adrFile={adrFile}
          setAdrFile={setAdrFile}
          cargos_adr_error={cargos_adr_error}
          cargos_adr_class_error={cargos_adr_class_error}
          cargos_un_number_error={cargos_un_number_error}
          cargos_msds_file_error={cargos_msds_file_error}
        />
        <div className='roadOversizedContainer'>
          <CreateOrderOversizedSection
            edit={edit}
            oversized={oversized}
            setOversized={setOversized}
            oversizedFile={oversizedFile}
            setOversizedFile={setOversizedFile}
            oversizedCall={oversizedCall}
            setOversizedCall={setOversizedCall}
            oversizedError={cargos_oversized_error}
            oversizedFileError={cargos_oversized_file_error}
            oversizedCallError={cargos_oversized_call_error}
          />
        </div>

        <CreateOrderSectionSecondaryTitle
          title={t("shipper.forms.titles.Trailer requirements")}
        />

        {/* {oversized && (
          <div className='unknownContainerContainer'>
            <DCheckBox
              value={unknownContainer}
              setValue={setUnknownContainer}
              label='I don’t know which container i need'
            />
            <DErrorText error={cargos_oversized_error} />
          </div>
        )} */}

        <div className='roadTrailersContainer'>
          {group.map((item, index) => (
            <RoadPackageItem
              edit={edit}
              key={index}
              index={index}
              item={item}
              group={group}
              setGroup={setGroup}
              trailer_type_id_error={trailer_type_id_error}
              trailer_size_id_error={trailer_size_id_error}
              temperature_range_id_error={temperature_range_id_error}
            />
          ))}
        </div>

        {!edit && (
          <div className='createNewOrderBtn'>
            <DButton
              title={t("shipper.buttons.Add new Trailer type")}
              leftIcon={<FiPlusCircle />}
              onClick={() => createNewGroupHandler()}
            />
          </div>
        )}

        {!edit &&
          orders &&
          orders.length > 1 &&
          totalWeight &&
          totalWeight > 0 && (
            <RoadWeightGroup
              orders={orders}
              setOrders={setOrders}
              totalWeight={totalWeight}
              setTotalWeight={setTotalWeight}
            />
          )}
      </div>
    </div>
  );
};

export default RoadPackage;
