import React from "react";
import "./DCurrencyInput.scss";

import { useSelector } from "react-redux";

import DLabel from "../DLabel/DLabel";
import DSelect from "../DSelect/DSelect";
import DInputs from "../DInputs/DInputs";

const DCurrencyInput = ({
  id,
  label,
  required,
  error,
  placeholder,
  type,
  value,
  setValue,
  currencyValue,
  setCurrencyValue,
}) => {
  const { currenciesLoading, currenciesSelect } = useSelector(
    (state) => state.settings
  );

  return (
    <div id='DCurrencyInput'>
      {label && <DLabel label={label} required={required} htmlFor={id} />}
      <div className='container'>
        <DInputs
          id={id}
          placeholder={placeholder}
          type={type ? type : "number"}
          onChange={setValue}
          value={value}
          setValue={setValue}
          error={error}
        />
        <DSelect
          placeholder=''
          value={currencyValue}
          setValue={(state) => setCurrencyValue(state)}
          error={null}
          options={currenciesSelect}
          loading={currenciesLoading}
          search={false}
        />
      </div>
    </div>
  );
};

export default DCurrencyInput;
