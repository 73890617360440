import React, { Fragment, useState, useEffect } from "react";
import "./AddNewCostModal.scss";

import { useTranslation } from "react-i18next";

import { FiPlus } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";
import {
  createOrderCost,
  resetCreateOrderCost,
} from "../../../../../../store/orders/costs/orderCostsSlice";
import { useOrderCostErrors } from "../../../../../../validations/order/useOrderCostsErrors";

import DModal from "../../../../../Modal/DModal/DModal";
import DCurrencyInput from "../../../../../Form/DCurrencyInput/DCurrencyInput";
import DSelect from "../../../../../Form/DSelect/DSelect";
import DTextarea from "../../../../../Form/DTextarea/DTextarea";

const AddNewCostModal = ({ isOpen, isClose, orderId, operation }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    createOrderCostLoading,
    createOrderCostResponce,
    getOrderCostTypesLoading,
    getOrderCostTypesSelectResponce,
  } = useSelector((state) => state.orderCosts);

  const { currenciesSelect } = useSelector((state) => state.settings);
  const { user } = useSelector((state) => state.auth);
  const {
    create_category_id_error,
    create_amount_error,
    create_comment_error,
  } = useOrderCostErrors();

  const [amountName, setAmountName] = useState();
  const [amountValue, setAmountValue] = useState("");
  const [currencyValue, setCurrencyValue] = useState(null);
  const [commentValue, setCommentValue] = useState("");

  const submithandler = () => {
    const data = {
      primary: false,
      operation: operation ? operation : "IN", // IN, EX
      orderId: orderId,
      category_id: amountName && amountName.value.id,
      amount: amountValue,
      currency_id: currencyValue && currencyValue.value.id,
      comment: commentValue,
    };
    dispatch(createOrderCost(data));
  };

  useEffect(() => {
    if (currenciesSelect && user.currency && user.currency.id) {
      const findValue = currenciesSelect.find(
        (item) => item.value.id === user.currency.id
      );
      setCurrencyValue(findValue);
    }
  }, [currenciesSelect, user.currency]);

  useEffect(() => {
    if (createOrderCostResponce) {
      dispatch(resetCreateOrderCost());
      setAmountName(null);
      setAmountValue("");
      setCommentValue("");
      isClose();
    }
  }, [dispatch, isClose, createOrderCostResponce]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose()}
        onClick={() => submithandler()}
        title={
          operation === "IN"
            ? t("tms.modals.titles.Create charge")
            : t("tms.modals.titles.Create cost")
        }
        submitText={t("tms.modals.buttons.Save")}
        icon={<FiPlus />}
        status='success'
        loading={createOrderCostLoading}
      >
        <div id='orderAddNewCost'>
          <form className='formGroup'>
            <DSelect
              id='OrderAdditionalCostAmountName'
              value={amountName}
              setValue={setAmountName}
              placeholder={t("tms.modals.placeholder.Select cost name")}
              label={t("tms.modals.labels.Cost name")}
              options={
                getOrderCostTypesSelectResponce
                  ? getOrderCostTypesSelectResponce
                  : []
              }
              required
              error={create_category_id_error}
              loading={getOrderCostTypesLoading}
            />
            <DCurrencyInput
              id='OrderAdditionalCostAmount'
              value={amountValue}
              setValue={setAmountValue}
              currencyValue={currencyValue}
              setCurrencyValue={setCurrencyValue}
              required
              error={create_amount_error}
              placeholder={t("tms.modals.placeholder.Enter cost")}
              label={t("tms.modals.labels.Cost")}
            />
            <DTextarea
              id='OrderAdditionalCostComment'
              value={commentValue}
              setValue={setCommentValue}
              placeholder={t("tms.modals.placeholder.Your comment...")}
              label={t("tms.modals.labels.Comment")}
              error={create_comment_error}
            />
          </form>
        </div>
      </DModal>
    </Fragment>
  );
};

export default AddNewCostModal;
