import React from "react";
import { Routes, Route } from "react-router-dom";

import {
  Home,
  SignIn,
  SignInCarrierVerify,
  SignInShipperVerify,
  SignInCompanyVerify,
  SignUp,
  SignUpCarrier,
  SignUpCarrierAuth,
  SignUpCarrierVerify,
  SignUpCarrierSuccess,
  SignUpCompany,
  SignUpCompanyAuth,
  SignUpCompanyVerify,
  SignUpCompanySuccess,
  SignUpShipper,
  SignUpShipperAuth,
  SignUpShipperVerify,
  SignUpShipperSuccess,
  // ShipperBookACall,
  // ShipperBookACallThankYou,
  ForgotPassword,
  ForgotPasswordReset,
  ForgotPasswordSuccess,
  ForgotPasswordVerify,
  Carrier,
  Shipper,
  AirFreight,
  SeaFreight,
  LandFreight,
  FAQ,
  InsurancePolicy,
  PrivacyPolicy,
  Terms,
  Contact,
  Finance,
  CarrierSubscribe,
} from "../pages/Landing/index";

import DelayedRedirect from "../componentsAdditional/DelayedRedirect";

const Landing = () => {
  return (
    <Routes>
      <Route path='/'>
        <Route path='/' index element={<Home />} />
        <Route path='/shipper' element={<Shipper />} />
        <Route path='/carrier'>
          <Route index element={<Carrier />} />
          <Route path='subscribe' element={<CarrierSubscribe />} />
        </Route>
        <Route path='/services'>
          <Route path='air' element={<AirFreight />} />
          <Route path='sea' element={<SeaFreight />} />
          <Route path='land' element={<LandFreight />} />
          <Route path='finance' element={<Finance />} />
        </Route>
        <Route path='/login'>
          <Route index element={<SignIn />} />
          <Route path='carrier/verify' element={<SignInCarrierVerify />} />
          <Route path='carrier/success' element={<SignUpCarrierSuccess />} />
          <Route path='tms/verify' element={<SignInCompanyVerify />} />
          <Route path='tms/success' element={<SignUpCompanySuccess />} />
          <Route path='shipper/verify' element={<SignInShipperVerify />} />
          <Route path='shipper/success' element={<SignUpShipperSuccess />} />
        </Route>
        <Route path='/register'>
          <Route index element={<SignUp />} />
          <Route path='carrier' element={<SignUpCarrier />} />
          <Route path='carrier/auth' element={<SignUpCarrierAuth />} />
          <Route path='carrier/verify' element={<SignUpCarrierVerify />} />
          <Route path='carrier/success' element={<SignUpCarrierSuccess />} />

          <Route path='tms' element={<SignUpCompany />} />
          <Route path='tms/auth' element={<SignUpCompanyAuth />} />
          <Route path='tms/verify' element={<SignUpCompanyVerify />} />
          <Route path='tms/success' element={<SignUpCompanySuccess />} />

          {/* <Route path='shipper/book-a-call' element={<ShipperBookACall />} />
          <Route
            path='shipper/book-a-call/thank-you'
            element={<ShipperBookACallThankYou />}
          /> */}

          <Route path='shipper' element={<SignUpShipper />} />
          <Route path='shipper/auth' element={<SignUpShipperAuth />} />
          <Route path='shipper/verify' element={<SignUpShipperVerify />} />
          <Route path='shipper/success' element={<SignUpShipperSuccess />} />
        </Route>
        <Route path='/forgot-password'>
          <Route index element={<ForgotPassword />} />
          <Route path='verify' element={<ForgotPasswordVerify />} />
          <Route path='reset' element={<ForgotPasswordReset />} />
          <Route path='success' element={<ForgotPasswordSuccess />} />
        </Route>
        <Route path='/insurancePolicy' element={<InsurancePolicy />} />
        <Route path='/qa' element={<FAQ />} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/confidentiality' element={<PrivacyPolicy />} />
        <Route path='/contact' element={<Contact />} />
      </Route>
      <Route path='*' element={<DelayedRedirect to='/' delay={0} />} />;
    </Routes>
  );
};

export default Landing;
