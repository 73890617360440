import _request from "../../_request";

const getTrucks = async (ISO, TOKEN, data) => {
  let url = "truck?";
  const { active, keyword } = data || {};
  url += active ? `&active=${active}` : "";
  url += keyword ? `&keyword=${keyword}` : "";

  const config = {
    url: url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getTruck = async (ISO, TOKEN, id) => {
  const config = {
    url: `truck/${id}`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const createTruck = async (ISO, TOKEN, data) => {
  const config = {
    url: "truck",
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const updateTruck = async (ISO, TOKEN, id, data) => {
  const config = {
    url: `truck/${id}`,
    method: "put",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const deleteTruck = async (ISO, TOKEN, id) => {
  const config = {
    url: `truck/${id}`,
    method: "delete",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getTruckBrands = async (ISO, TOKEN) => {
  const config = {
    url: "truck-brands",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const manageTruck = async (ISO, TOKEN, id, data) => {
  const config = {
    url: `manage-truck/${id}`,
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const trucksService = {
  getTrucks,
  getTruck,
  createTruck,
  updateTruck,
  deleteTruck,
  getTruckBrands,
  manageTruck,
};

export default trucksService;
