import React, { Fragment } from "react";
import "./CreateOrderOversizedSection.scss";

import { useTranslation } from "react-i18next";

import { FiCheck } from "react-icons/fi";

import DCheckBox from "../../../../Form/DCheckBox/DCheckBox";
import CreateOrderSectionSecondaryTitle from "../CreateOrderSectionSecondaryTitle/CreateOrderSectionSecondaryTitle";
import DFileForm from "../../../../Form/DFile/DFileForm/DFileForm";
import DButton from "../../../../Form/DButtons/DButton/DButton";
import DErrorText from "../../../../Form/DErrorText/DErrorText";

const CreateOrderOversizedSection = ({
  edit,
  oversized,
  setOversized,
  oversizedFile,
  setOversizedFile,
  oversizedCall,
  setOversizedCall,
  oversizedError,
  oversizedFileError,
  oversizedCallError,
}) => {
  const { t } = useTranslation();
  return (
    <div id='CreateOrderOversizedSection'>
      <DCheckBox
        value={oversized}
        setValue={() => setOversized((state) => !state)}
        label={t("shipper.forms.content.Oversized cargo")}
      />
      <DErrorText error={oversizedError} />

      {!edit && oversized && (
        <Fragment>
          <CreateOrderSectionSecondaryTitle title={t("shipper.forms.titles.Cargo dimensions")} />
          <div className='oversizedGroupContainer'>
            {setOversizedFile && (
              <DFileForm
                value={oversizedFile}
                setValue={(value) => setOversizedFile(value)}
                title={t("shipper.forms.content.Upload cargo drawings")}
                subTitle={t("shipper.forms.content.Optional")}
                error={oversizedFileError}
              />
            )}
            <div className='orContainer'>
              <span className='or'>{t("shipper.forms.content.OR")}</span>
            </div>
            <div className='requestCallContainer'>
              <div className='title'>
                {t("shipper.forms.content.Request a call from our sales team to specify details")}
              </div>
              {!oversizedCall ? (
                <Fragment>
                  <div className='button'>
                    <DButton
                      title={t("shipper.buttons.Request a call")}
                      onClick={() => setOversizedCall(true)}
                    />
                  </div>
                  <DErrorText error={oversizedCallError} />
                </Fragment>
              ) : (
                <Fragment>
                  <div className='requestedContainer'>
                    <div className='requestedContainerLeft'>
                      <div className='icon'>
                        <FiCheck />
                      </div>
                      <div className='text'>
                        {t("shipper.forms.content.We will contact you for the details")}
                      </div>
                    </div>
                    <div className='requestedContainerRight'>
                      <div className='undoButton' onClick={() => setOversizedCall(false)}>
                        {t("shipper.buttons.Undo")}
                      </div>
                    </div>
                  </div>
                  <DErrorText error={oversizedCallError} />
                </Fragment>
              )}
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default CreateOrderOversizedSection;
