import React, { useEffect, Fragment } from "react";
import "./ShowPrePaymentModal.scss";

import { useTranslation } from "react-i18next";

import { MdMonetizationOn } from "react-icons/md";
import { FiX } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";

import {
  getAdvances,
  resetGetAdvances,
} from "./../../../../store/orders/advance/advanceSlice";

import DOverlay from "../../../Modal/DOverlay/DOverlay";
import ShowPrePaymentModalItem from "./ShowPrePaymentModalItem/ShowPrePaymentModalItem";
import LoadingPage from "./../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";

const ShowPrePaymentModal = ({ isOpen, isClose, order }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    getAdvancesLoading,
    getAdvancesResponce,
    createAdvanceResponce,
    updateAdvanceResponce,
    deleteAdvanceResponce,
  } = useSelector((state) => state.advance);

  useEffect(() => {
    if (isOpen) {
      dispatch(getAdvances(order.id));
    }
    return () => {
      dispatch(resetGetAdvances());
    };
  }, [
    dispatch,
    isOpen,
    order.id,
    createAdvanceResponce,
    updateAdvanceResponce,
    deleteAdvanceResponce,
  ]);

  return (
    <DOverlay isOpen={isOpen} isClose={isClose}>
      <div id='ShowPrePaymentModal'>
        <div className='ShowPrePaymentModalHeader'>
          <div className='title'>
            <div className='titleIcon'>
              <MdMonetizationOn />
            </div>
            <h2>{t("tms.modals.titles.Advances")}</h2>
          </div>
          <div className='closeIcon' onClick={isClose}>
            <FiX />
          </div>
        </div>
        <div className='ShowPrePaymentModalContainer'>
          {getAdvancesLoading && <LoadingPage />}
          {getAdvancesResponce && (
            <Fragment>
              {getAdvancesResponce.length === 0 ? (
                <p className='noPrepayment'>
                  {t("tms.modals.others.No advances")}
                </p>
              ) : (
                <ul className='ShowPrePaymentModalList'>
                  {getAdvancesResponce.map((item) => (
                    <ShowPrePaymentModalItem
                      item={item}
                      key={item.id}
                      order={order}
                    />
                  ))}
                </ul>
              )}
            </Fragment>
          )}
        </div>
      </div>
    </DOverlay>
  );
};

export default ShowPrePaymentModal;
