import React, { Fragment, useState, useEffect } from "react";
import "./AddTrucksModal.scss";

import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";

import {
  createTruck,
  resetCreateTruck,
} from "./../../../../store/fleet/trucks/trucksSlice";
import { useTruckErrors } from "./../../../../validations/fleet/useTruckErrors";

import DModal from "../../../Modal/DModal/DModal";
import DInputs from "../../../Form/DInputs/DInputs";
import DTruckBrandsSelect from "../../../Form/DSelect/DTruckBrandsSelect/DTruckBrandsSelect";
import DTruckModelsSelect from "../../../Form/DSelect/DTruckModelsSelect/DTruckModelsSelect";

const AddTrucksModal = ({ isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { createTruckLoading, createTruckResponce } = useSelector(
    (state) => state.trucks
  );
  const {
    create_model_id,
    create_registration_number,
    create_passport_number,
  } = useTruckErrors();

  const [manufacture, setManufacture] = useState(null);
  const [model, setModel] = useState(null);
  const [plateNumber, setPlateNumber] = useState("");
  const [vinNumber, setVinNumber] = useState("");

  useEffect(() => {
    setModel(null);
  }, [manufacture]);

  const resetState = () => {
    setManufacture(null);
    setModel(null);
    setPlateNumber("");
    setVinNumber("");
  };

  const submitHandler = () => {
    const data = {
      model_id: model && model.value.id,
      registration_number: plateNumber,
      passport_number: vinNumber,
    };
    dispatch(createTruck(data));
  };

  useEffect(() => {
    if (createTruckResponce) {
      dispatch(resetCreateTruck());
      resetState();
      isClose();
    }
  }, [dispatch, isClose, createTruckResponce]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose(false)}
        title={t("tms.modals.titles.Create Truck")}
        submitText={t("tms.modals.buttons.Save")}
        icon={<FiPlus />}
        status='success'
        onClick={() => submitHandler()}
        loading={createTruckLoading}
      >
        <div id='AddTrucksModal'>
          <div className='AddTrucksModalGroup'>
            <DTruckBrandsSelect
              value={manufacture}
              setValue={setManufacture}
              required
              error={create_model_id}
            />
            <DTruckModelsSelect
              value={model}
              setValue={setModel}
              required
              options={manufacture ? manufacture.value.models : []}
              disabled={!manufacture}
              error={create_model_id}
            />

            <DInputs
              id='truckRegistrationPlate'
              value={plateNumber}
              setValue={setPlateNumber}
              label={t("tms.modals.labels.Registration plate")}
              placeholder={t("tms.modals.placeholder.Enter registration plate")}
              error={create_registration_number}
              required
            />
            <DInputs
              id='truckVinNumber'
              label={t("tms.modals.labels.VIN number")}
              placeholder={t("tms.modals.placeholder.Enter VIN number")}
              value={vinNumber}
              setValue={setVinNumber}
              error={create_passport_number}
            />
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default AddTrucksModal;
