import React, { useState, useEffect } from "react";
import "./TmsSolutionCarrier.scss";

import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

import { PopupButton } from "react-calendly";

import HomeTitle from "../Components/HomeTitle/HomeTitle";
import TmsSolutionCarrierItem from "./TmsSolutionCarrierItem/TmsSolutionCarrierItem";
import LinkTransparent from "../Components/Links/LinkTransparent/LinkTransparent";

const TmsSolutionCarrier = () => {
  const { t } = useTranslation();
  const [active, setActive] = useState(0);

  const ARRAY = [
    {
      id: 0,
      number: "01",
      title: t(
        "landing.titles.landingTMSsolutionTitle.Stay in control of your orders & payments"
      ),
      desc: t(
        "landing.bodyText.landingTMSsolutionBody.Gain full visibility over your orders, manage incurred costs, swiftly generate invoices, and ensure timely receivables"
      ),
      cover: `url(${`/assets/images/static/cover44.png`})`,
      delay: "0",
    },
    {
      id: 1,
      number: "02",
      title: t(
        "landing.titles.landingTMSsolutionTitle.Elevate your customer service"
      ),
      desc: t(
        "landing.bodyText.landingTMSsolutionBody.Provide real-time delivery updates, instantly share cargo location, and essential documentation"
      ),
      cover: `url(${`/assets/images/static/cover45.png`})`,
      delay: "100",
    },
    {
      id: 2,
      number: "03",
      title: t(
        "landing.titles.landingTMSsolutionTitle.Effortlessly uncover new orders"
      ),
      desc: t(
        "landing.bodyText.landingTMSsolutionBody.Reduce fleet downtime by accessing orders from the loadboard and booking them instantly"
      ),
      cover: `url(${`/assets/images/static/cover46.png`})`,

      delay: "200",
    },
    {
      id: 3,
      number: "04",
      title: t(
        "landing.titles.landingTMSsolutionTitle.Expedite your load forwarding with ease"
      ),
      desc: t(
        "landing.bodyText.landingTMSsolutionBody.Initiate auctions for your orders and invite partners to a digital bidding process to streamline your forwarding operations"
      ),
      cover: `url(${`/assets/images/static/cover47.png`})`,
      delay: "300",
    },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setActive((prevActive) => (prevActive + 1) % ARRAY.length);
    }, 4000);

    return () => clearTimeout(timer);
  }, [active, ARRAY.length]);

  return (
    <section id='TmsSolutionCarrier' className='TmsSolutionCarrier'>
      <div className='wrapper'>
        <HomeTitle
          description={t(
            "landing.titles.landingMainTitlesDesc.An industry-leading platform to manage your orders, fleet and finances- all within a single workspace"
          )}
        >
          {parse(
            t(
              "landing.titles.landingMainTitles.Cargon <span>TMS</span> solution"
            )
          )}
        </HomeTitle>
        <div className='container'>
          <div className='left'>
            {ARRAY.map((item) => (
              <TmsSolutionCarrierItem
                key={item.id}
                item={item}
                active={active}
                onClick={setActive}
              />
            ))}
          </div>
          <div className='right'>
            <div
              className='cover'
              style={{
                backgroundImage: ARRAY.find((i) => i.id === active).cover,
              }}
            ></div>
          </div>
        </div>
        <div className='actions'>
          <LinkTransparent
            title={t("landing.form.button.Sign up now")}
            to='/register'
          />
          <PopupButton
            className='requestDemo'
            text={t("landing.form.button.Request a demo")}
            url='https://calendly.com/cargon-support/call-for-a-demo'
            rootElement={document.getElementById("root")}
          />
        </div>
      </div>
    </section>
  );
};

export default TmsSolutionCarrier;
