import React, { Fragment, useState, useEffect } from "react";
import "./EditPrePaymentModal.scss";

import { useTranslation } from "react-i18next";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { BiSolidDollarCircle } from "react-icons/bi";

import {
  updateAdvance,
  resetUpdateAdvance,
} from "../../../../store/orders/advance/advanceSlice";
import { useAdvancesErrors } from "./../../../../validations/order/useAdvancesErrors";

import DModal from "../../../Modal/DModal/DModal";
import DCurrencyInput from "../../../Form/DCurrencyInput/DCurrencyInput";
import DDatepicker from "../../../Form/DDatepicker/DDatepicker";
import DTextarea from "../../../Form/DTextarea/DTextarea";

const EditPrePaymentModal = ({ isOpen, isClose, order, item }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { updateAdvanceLoading, updateAdvanceResponce } = useSelector(
    (state) => state.advance
  );
  const { currenciesSelect } = useSelector((state) => state.settings);

  const [amount, setAmount] = useState(item?.amount || "");
  const [currencyValue, setCurrencyValue] = useState(null);
  const [paymentDate, setPaymentDate] = useState(
    item.created_at ? moment(item.created_at, "DD-MM-YYYY").toDate() : null
  );
  const [comment, setComment] = useState(item?.comment || "");

  const {
    update_currency_id_error,
    update_amount_error,
    update_payment_at_error,
    update_comment_error,
  } = useAdvancesErrors();

  useEffect(() => {
    if (currenciesSelect && item && item.currency && item.currency.id) {
      const findValue = currenciesSelect.find(
        (i) => i.value.id === item.currency.id
      );
      setCurrencyValue(findValue);
    }
  }, [currenciesSelect, item]);

  const submitHandler = () => {
    const data = {
      orderId: order && order.id,
      advanceId: item.id,
      currency_id: currencyValue && currencyValue.value.id,
      amount: amount,
      comment: comment,
      payment_at: moment(paymentDate).format("YYYY/MM/DD"),
    };
    dispatch(updateAdvance(data));
  };

  useEffect(() => {
    if (updateAdvanceResponce) {
      dispatch(resetUpdateAdvance());
      isClose();
    }
  }, [dispatch, isClose, updateAdvanceResponce]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose(false)}
        title={t("tms.modals.titles.Edit pre payment")}
        submitText={t("tms.modals.buttons.Save")}
        icon={<BiSolidDollarCircle />}
        status='success'
        onClick={() => submitHandler()}
        loading={updateAdvanceLoading}
      >
        <div id='EditPrePaymentModal'>
          <div className='EditPrePaymentModalGroup'>
            <DCurrencyInput
              id={"AddPaymentAmount"}
              label={t("tms.modals.labels.Amount")}
              placeholder={t("tms.modals.placeholder.Enter paid money")}
              value={amount}
              setValue={setAmount}
              currencyValue={currencyValue}
              setCurrencyValue={setCurrencyValue}
              error={update_amount_error || update_currency_id_error}
              required
            />
            <DDatepicker
              label={t("tms.modals.labels.Payment date")}
              placeholder={t("tms.modals.placeholder.Current date")}
              value={paymentDate}
              setValue={setPaymentDate}
              error={update_payment_at_error}
              required
            />
            <DTextarea
              id={"AddPaymentModalTextAreaAdvances"}
              value={comment}
              setValue={setComment}
              label={t("tms.modals.labels.Comment")}
              placeholder={t("tms.modals.placeholder.Enter comment")}
              error={update_comment_error}
            />
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default EditPrePaymentModal;
