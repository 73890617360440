import React from "react";
import "./AddCustomerButton.scss";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IoMdAddCircleOutline } from "react-icons/io";

const AddCustomerButton = ({ order }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className='AddCustomerButton'
      onClick={() => {
        navigate(`/dashboard/tms/orders/edit/${order?.id}`);
      }}
    >
      <div className='addCustomerButtonLeft'>
        {t("tms.forms.buttons.Add customer")}
      </div>
      <IoMdAddCircleOutline />
    </div>
  );
};

export default AddCustomerButton;
