import React from "react";
import "./OceanPackage.scss";

import { useTranslation } from "react-i18next";

import OceanPackageItem from "./OceanPackageItem/OceanPackageItem";
import CreateOrderSectionTitle from "./../../Components/CreateOrderSectionTitle/CreateOrderSectionTitle";

const OceanPackage = ({ edit, group, setGroup }) => {
  const { t } = useTranslation();
  return (
    <div id='ShipperOceanPackage'>
      <CreateOrderSectionTitle
        title={t("shipper.forms.titles.Product & Container requirements")}
      />
      <div className='packagesContainer'>
        {group.map((item, index) => (
          <OceanPackageItem
            edit={edit}
            key={index}
            index={index}
            item={item}
            group={group}
            setGroup={setGroup}
          />
        ))}
      </div>
    </div>
  );
};

export default OceanPackage;
