import React from "react";
import "./TrailerDetailsShipper.scss";

import LandTrailerDetails from "./LandTrailerDetails/LandTrailerDetails";
import OceanTrailerDetails from "./OceanTrailerDetails/OceanTrailerDetails";
import AirPackageDetails from "./AirPackageDetails/AirPackageDetails";

const TrailerDetailsShipper = ({ orderResponce }) => {
  let isLand = false;
  let isAir = false;
  let isOcean = false;
  // let isRail = false;

  switch (orderResponce?.type?.id) {
    case 1:
    case 2:
      isLand = true;
      break;
    case 3:
    case 4:
      isOcean = true;
      break;
    case 5:
      isAir = true;
      break;
    // case 6:
    // case 7:
    // isRail = true;
    // break;
    default:
      break;
  }

  return (
    <div id='TrailerDetailsShipper'>
      {isLand ? (
        <LandTrailerDetails orderResponce={orderResponce} />
      ) : isOcean ? (
        <OceanTrailerDetails orderResponce={orderResponce} />
      ) : isAir ? (
        <AirPackageDetails orderResponce={orderResponce} />
      ) : (
        ""
      )}
    </div>
  );
};

export default TrailerDetailsShipper;
