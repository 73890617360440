import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { PiBagFill } from "react-icons/pi";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  takeBoard,
  resetTakeBoard,
} from "../../../../store/loadBoards/loadBoardsSlice";

import DModal from "./../../../Modal/DModal/DModal";

const TakeOrderModal = ({ item, isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { takeBoardLoading, takeBoardResponce } = useSelector(
    (state) => state.loadBoards
  );

  const submitHandler = () => {
    const data = {
      id: item.id,
    };
    dispatch(takeBoard(data));
  };

  useEffect(() => {
    if (takeBoardResponce) {
      dispatch(resetTakeBoard());
      navigate(`/dashboard/tms/orders/${item.id}`);
    }
  }, [dispatch, navigate, takeBoardResponce, item.id]);

  return (
    <DModal
      isOpen={isOpen}
      isClose={isClose}
      onClick={() => submitHandler()}
      icon={<PiBagFill />}
      title={t("tms.modals.titles.Take order")}
      submitText={t("tms.modals.buttons.Take order")}
      loading={takeBoardLoading}
      // status='info'
    >
      <p>{t("tms.modals.desc.Are you sure you want to take it?")}</p>
    </DModal>
  );
};

export default TakeOrderModal;
