import React, { Fragment, useEffect, useState } from "react";
import "./EditPartnersPriceModal.scss";

import { useTranslation } from "react-i18next";

import { MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import {
  updateOrderCost,
  resetUpdateOrderCost,
} from "../../../../../store/orders/costs/orderCostsSlice";
import { useOrderCostErrors } from "../../../../../validations/order/useOrderCostsErrors";

import DModal from "../../../../Modal/DModal/DModal";
import DCurrencyInput from "../../../../Form/DCurrencyInput/DCurrencyInput";

const EditPartnersPriceModal = ({ isOpen, isClose, order }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { updateOrderCostLoading, updateOrderCostResponce } = useSelector(
    (state) => state.orderCosts
  );
  const { update_amount_error } = useOrderCostErrors();

  const [expense, setExpense] = useState(
    order?.expense?.amount?.toString() || "0"
  );
  const [expenseCurrency, setExpenseCurrency] = useState(
    {
      label: order?.expense?.currency?.code,
      value: order?.expense?.currency,
    } || null
  );

  const submitHandler = () => {
    const data = {
      orderId: order.id,
      costId: order?.expense?.id,
      currency_id: expenseCurrency.value.id,
      amount: expense,
    };
    dispatch(updateOrderCost(data));
  };

  useEffect(() => {
    if (updateOrderCostResponce) {
      dispatch(resetUpdateOrderCost());
      isClose();
    }
  }, [dispatch, isClose, updateOrderCostResponce]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose(false)}
        title={t("tms.modals.titles.Edit partner price")}
        submitText={t("tms.modals.buttons.Save")}
        icon={<MdEdit />}
        status='success'
        onClick={() => submitHandler()}
        loading={updateOrderCostLoading}
      >
        <div id='EditOrderPriceModal'>
          <div className='EditOrderPriceModalGroup'>
            <DCurrencyInput
              value={expense}
              setValue={setExpense}
              placeholder={t("tms.modals.placeholder.Enter partner price")}
              label={t("tms.modals.labels.Partner price")}
              currencyValue={expenseCurrency}
              setCurrencyValue={setExpenseCurrency}
              required
              id='FinancialDetailsPartnerPrice'
              error={update_amount_error}
            />
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default EditPartnersPriceModal;
