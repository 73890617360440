import { useSelector } from "react-redux";

export const useCustomerErrors = () => {
  const { createCustomerError, updateCustomerError } = useSelector(
    (state) => state.customer
  );

  const create_company_legal_id_error =
    createCustomerError &&
    createCustomerError.errors &&
    createCustomerError.errors["company.legal_id"] &&
    createCustomerError.errors["company.legal_id"][0];

  const create_company_name_error =
    createCustomerError &&
    createCustomerError.errors &&
    createCustomerError.errors["company.name"] &&
    createCustomerError.errors["company.name"][0];

  const create_company_email_error =
    createCustomerError &&
    createCustomerError.errors &&
    createCustomerError.errors["company.email"] &&
    createCustomerError.errors["company.email"][0];

  const create_company_phone_error =
    createCustomerError &&
    createCustomerError.errors &&
    createCustomerError.errors["company.phone"] &&
    createCustomerError.errors["company.phone"][0];

  const create_company_phone_index_id_error =
    createCustomerError &&
    createCustomerError.errors &&
    createCustomerError.errors["company.phone_index_id"] &&
    createCustomerError.errors["company.phone_index_id"][0];

  const create_company_address_error =
    createCustomerError &&
    createCustomerError.errors &&
    createCustomerError.errors["company.address"] &&
    createCustomerError.errors["company.address"][0];

  const update_company_legal_id_error =
    updateCustomerError &&
    updateCustomerError.errors &&
    updateCustomerError.errors["company.legal_id"] &&
    updateCustomerError.errors["company.legal_id"][0];

  const update_company_name_error =
    updateCustomerError &&
    updateCustomerError.errors &&
    updateCustomerError.errors["company.name"] &&
    updateCustomerError.errors["company.name"][0];

  const update_company_email_error =
    updateCustomerError &&
    updateCustomerError.errors &&
    updateCustomerError.errors["company.email"] &&
    updateCustomerError.errors["company.email"][0];

  const update_company_phone_error =
    updateCustomerError &&
    updateCustomerError.errors &&
    updateCustomerError.errors["company.phone"] &&
    updateCustomerError.errors["company.phone"][0];

  const update_company_phone_index_id_error =
    updateCustomerError &&
    updateCustomerError.errors &&
    updateCustomerError.errors["company.phone_index_id"] &&
    updateCustomerError.errors["company.phone_index_id"][0];

  const update_company_address_error =
    updateCustomerError &&
    updateCustomerError.errors &&
    updateCustomerError.errors["company.address"] &&
    updateCustomerError.errors["company.address"][0];

  return {
    create_company_legal_id_error,
    create_company_name_error,
    create_company_email_error,
    create_company_phone_error,
    create_company_phone_index_id_error,
    create_company_address_error,
    update_company_legal_id_error,
    update_company_name_error,
    update_company_email_error,
    update_company_phone_error,
    update_company_phone_index_id_error,
    update_company_address_error,
  };
};

export default useCustomerErrors;
