import React from "react";
import "./DashboardHeader.scss";

import { FaPlusCircle } from "react-icons/fa";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import MainTitle from "../../MainTitle/MainTitle";
import DButton from "../../Form/DButtons/DButton/DButton";
const DashboardHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  return (
    <div id='DashboardHeader'>
      <MainTitle
        title={t("tms.titles.Dashboard Overview")}
        description={`${t("tms.desc.Hi")} ${user.first_name}, ${t(
          "tms.desc.welcome back !"
        )}`}
      />
      <DButton
        title={t("tms.forms.buttons.Add Booking")}
        rightIcon={<FaPlusCircle />}
        onClick={() => navigate("/dashboard/tms/orders/new")}
      />
    </div>
  );
};

export default DashboardHeader;
