import React from "react";
import "./FilterRateSelect.scss";

import { useTranslation } from "react-i18next";
import Select from "react-select";

const FilterRateSelect = ({ rate, setRate }) => {
  const { t } = useTranslation();

  const RATE_OPTIONS = [
    {
      label: t("tms.filters.labels.Rate"),
      options: [
        { value: "false", label: t("tms.filters.labels.Spot price") },
        { value: "true", label: t("tms.filters.labels.Only bidding") },
      ],
    },
  ];

  return (
    <div id='FilterRateSelect'>
      <Select
        classNamePrefix='select'
        name='rateSelect'
        className='selectComponent'
        options={RATE_OPTIONS}
        placeholder={t("tms.filters.placeholders.Rate")}
        value={rate}
        onChange={(state) => setRate(state)}
        isClearable={true}
        isSearchable={false}
      />
    </div>
  );
};

export default FilterRateSelect;
