import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import otherService from "./settingsService";

// get phoneIndexValue from localStorage
const phoneIndexValueId = JSON.parse(localStorage.getItem("phoneIndexValueId"));

// get country id from localStorage
const countryId = JSON.parse(localStorage.getItem("countryId"));

const initialState = {
  // country id
  countryId: countryId,

  // get countries
  countries: null,
  countriesLoading: false,
  countriesError: null,

  // get all countries
  allCountriesResponce: null,
  allCountriesLoading: false,
  allCountriesError: null,

  // phone index id
  phoneIndexValueId: phoneIndexValueId,

  // get phone Indexes
  phoneIndexes: null,
  phoneIndexesLoading: false,
  phoneIndexesError: null,

  // get currency
  currenciesLoading: false,
  currencies: null,
  currenciesSelect: null,
  currenciesError: null,

  // set currency
  setCurrencyLoading: false,
  setCurrencyResponce: null,
  setCurrencyError: null,

  // get Geolocation Country
  getGeolocationCountryLoading: true,
  getGeolocationCountryResponce: null,
  getGeolocationCountryError: null,
};

// get countries
export const getCountries = createAsyncThunk(
  "settings/getCountries",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      return await otherService.getCountries(ISO);
    } catch (error) {
      const message =
        (error.response && error.response.data) || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get all countries
export const getAllCountries = createAsyncThunk(
  "settings/getAllCountries",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      return await otherService.getAllCountries(ISO);
    } catch (error) {
      const message =
        (error.response && error.response.data) || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// changed phone index value
export const setPhoneIndexValue = createAsyncThunk(
  "settings/setPhoneIndexValue",
  async (data) => {
    if (data) {
      localStorage.setItem("phoneIndexValueId", JSON.stringify(data));
    }
    return data;
  }
);

// get phone Indexes
export const getPhoneIndexes = createAsyncThunk(
  "settings/getPhoneIndexes",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const response = await otherService.getPhoneIndexes(ISO);
      return response;
    } catch (error) {
      const message =
        (error.response && error.response.data) || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get currency
export const getCurrency = createAsyncThunk(
  "settings/getCurrency",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const branchId = thunkAPI.getState().auth.user.branch.id;
      return await otherService.getCurrency(ISO, branchId);
    } catch (error) {
      const message =
        (error.response && error.response.data) || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// set currency
export const setCurrency = createAsyncThunk(
  "settings/setCurrency",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await otherService.setCurrency(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get Geolocation Country
export const getGeolocationCountry = createAsyncThunk(
  "settings/getGeolocationCountry",
  async (_, thunkAPI) => {
    try {
      const response = await otherService.getGeolocationCountry();
      return response;
    } catch (error) {
      const message =
        (error.response && error.response.data) || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    resetSetCurrency: (state) => {
      state.setCurrencyLoading = false;
      state.setCurrencyResponce = null;
      state.setCurrencyError = null;
    },
    setPhoneIndexValueOnlyState: (state, action) => {
      state.phoneIndexValueId = action.payload;
    },
    setCountryIdOnlyState: (state, action) => {
      state.countryId = action.payload;
    },
    setCountryId: (state, action) => {
      state.countryId = action.payload;
      localStorage.setItem("countryId", JSON.stringify(action.payload));
    },
  },
  extraReducers: (builder) => {
    builder
      // get countries
      .addCase(getCountries.pending, (state) => {
        state.countriesLoading = true;
        state.countries = null;
        state.countriesError = null;
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        state.countriesLoading = false;
        state.countries = action.payload;
        state.countriesError = null;
      })
      .addCase(getCountries.rejected, (state, action) => {
        state.countriesLoading = false;
        state.countries = null;
        state.countriesError = action.payload;
      })

      // get all countries
      .addCase(getAllCountries.pending, (state) => {
        state.allCountriesLoading = true;
        state.allCountriesResponce = null;
        state.allCountriesError = null;
      })
      .addCase(getAllCountries.fulfilled, (state, action) => {
        state.allCountriesLoading = false;
        state.allCountriesResponce = action.payload;
        state.allCountriesError = null;
      })
      .addCase(getAllCountries.rejected, (state, action) => {
        state.allCountriesLoading = false;
        state.allCountriesResponce = null;
        state.allCountriesError = action.payload;
      })

      // get phone Indexes
      .addCase(getPhoneIndexes.pending, (state) => {
        state.phoneIndexesLoading = true;
        state.phoneIndexes = null;
        state.phoneIndexesError = null;
      })
      .addCase(getPhoneIndexes.fulfilled, (state, action) => {
        state.phoneIndexesLoading = false;
        state.phoneIndexes = action.payload;
        state.phoneIndexesError = null;
      })
      .addCase(getPhoneIndexes.rejected, (state, action) => {
        state.phoneIndexesLoading = false;
        state.phoneIndexes = null;
        state.phoneIndexesError = action.payload;
      })

      // changed phone index value
      .addCase(setPhoneIndexValue.fulfilled, (state, action) => {
        state.phoneIndexValueId = action.payload;
      })

      // get currency
      .addCase(getCurrency.pending, (state) => {
        state.currenciesLoading = true;
        state.currencies = null;
        state.currenciesSelect = null;
        state.currenciesError = null;
      })
      .addCase(getCurrency.fulfilled, (state, action) => {
        state.currenciesLoading = false;
        state.currencies = action.payload;
        state.currenciesSelect = action.payload.map((item) => ({
          value: item,
          label: item.code,
        }));
        state.currenciesError = null;
      })
      .addCase(getCurrency.rejected, (state, action) => {
        state.currenciesLoading = false;
        state.currencies = null;
        state.currenciesSelect = null;
        state.currenciesError = action.payload;
      })

      .addCase(setCurrency.pending, (state) => {
        state.setCurrencyLoading = true;
        state.setCurrencyResponce = null;
        state.setCurrencyError = null;
      })
      .addCase(setCurrency.fulfilled, (state, action) => {
        state.setCurrencyLoading = false;
        state.setCurrencyResponce = action.payload;
        state.setCurrencyError = null;
      })
      .addCase(setCurrency.rejected, (state, action) => {
        state.setCurrencyLoading = false;
        state.setCurrencyResponce = null;
        state.setCurrencyError = action.payload;
      })

      .addCase(getGeolocationCountry.pending, (state) => {
        state.getGeolocationCountryLoading = true;
        state.getGeolocationCountryResponce = null;
        state.getGeolocationCountryError = null;
      })
      .addCase(getGeolocationCountry.fulfilled, (state, action) => {
        state.getGeolocationCountryLoading = false;
        state.getGeolocationCountryResponce = action.payload;
        // state.getGeolocationCountryResponce = { country: "UZ" };
        state.getGeolocationCountryError = null;
      })
      .addCase(getGeolocationCountry.rejected, (state, action) => {
        state.getGeolocationCountryLoading = false;
        state.getGeolocationCountryResponce = null;
        state.getGeolocationCountryError = action.payload;
      });
  },
});

export const {
  resetSetCurrency,
  setPhoneIndexValueOnlyState,
  setCountryIdOnlyState,
  setCountryId,
} = settingsSlice.actions;

export default settingsSlice.reducer;
