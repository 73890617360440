import React, { useEffect, useState } from "react";
import "./Step4.scss";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { resetVerifyCode, verifyCode } from "../../../../../store/subscribe/subscribeSlice";

import Button from "../../../../Form/Button/Button";
import OTP from "../../../../Form/OTP/OTP";

const Step4 = ({ setStep, method, phone, phoneIndexId, email }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { verifyCodeResponce, verifyCodeError, verifyCodeLoading } = useSelector(
    (state) => state.subscribe
  );
  const [otp, setOtp] = useState();

  const submitHandler = () => {
    const data =
      method === "email"
        ? { code: otp, email: email }
        : { code: otp, phone_index_id: phoneIndexId, phone: phone };
    dispatch(verifyCode(data));
  };

  useEffect(() => {
    if (verifyCodeResponce) {
      setStep((prev) => prev + 1);
      navigate("/carrier/subscribe?success");
    }
    return () => {
      dispatch(resetVerifyCode());
    };
  }, [verifyCodeResponce, dispatch, setStep, navigate]);

  return (
    <div id='CarrierSubscribeFormStep4'>
      <div className='titleContainer'>
        <div className='title'>{t("landing.landingCarrierSubscribe.Verification")}</div>
        <div className='subTitle'>
          {t(
            "landing.landingCarrierSubscribe.Please enter verification code which you recieved on your {method}.",
            {
              method:
                method === "phone"
                  ? t("landing.landingCarrierSubscribe.phone")
                  : t("landing.landingCarrierSubscribe.email"),
            }
          )}
        </div>
      </div>
      <div className='otpContainer'>
        <OTP
          value={otp}
          setValue={setOtp}
          count={method === "phone" ? 4 : 6}
          error={verifyCodeError?.errors?.code?.[0]}
        />
        <Button
          title={t("landing.landingCarrierSubscribe.Subscribe")}
          onClick={submitHandler}
          loading={verifyCodeLoading}
        />
      </div>
      <div className='secondaryButtonsContainer'>
        {/* <div className='resendButton' onClick={handleResend}>
          {t("landing.landingCarrierSubscribe.Resend")}
        </div> */}
        <div className='changePhoneButton' onClick={() => setStep(3)}>
          {t("landing.landingCarrierSubscribe.Change the {method}", {
            method:
              method === "phone"
                ? t("landing.landingCarrierSubscribe.phone")
                : t("landing.landingCarrierSubscribe.email"),
          })}
        </div>
      </div>
    </div>
  );
};

export default Step4;
