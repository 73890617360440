import React, { Fragment, useState } from "react";
import "./ExtraChargesItem.scss";

import { useTranslation } from "react-i18next";
import { FiMoreVertical, FiEdit2, FiTrash2 } from "react-icons/fi";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { useMoney } from "../../../../../../helper/useMoney";
import DeleteCostModal from "./../DeleteCostModal/DeleteCostModal";
import UpdateCostModal from "../UpdateCostModal/UpdateCostModal";
import Character from "../../../../../UI/Character/Character";

const ExtraChargesItem = ({ item, orderId, operation }) => {
  const { t } = useTranslation();

  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const formattedMoney = useMoney(
    item?.amount || 0,
    item?.currency?.symbol || "-"
  );

  return (
    <Fragment>
      <li id='ExtraCustomerChargesItem'>
        <h4>
          <Character title={item?.category?.name || "-"} max={17} />
        </h4>
        <div className='action'>
          <h5>{formattedMoney}</h5>
          <div className='Moreicon' onClick={handleClick}>
            <FiMoreVertical />
          </div>
          <Menu
            id='OrdersTableItemMore'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                setEditModal(true);
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <FiEdit2 />
                {t("tms.tables.buttons.Edit")}
              </div>
            </MenuItem>

            <MenuItem
              onClick={() => {
                handleClose();
                setDeleteModal(true);
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  color: "red",
                }}
              >
                <FiTrash2 />
                {t("tms.tables.buttons.Delete")}
              </div>
            </MenuItem>
          </Menu>
        </div>
      </li>
      {deleteModal && (
        <DeleteCostModal
          isOpen={deleteModal}
          isClose={() => setDeleteModal(false)}
          costId={item.id}
          orderId={orderId}
        />
      )}
      {editModal && (
        <UpdateCostModal
          operation={operation}
          isOpen={editModal}
          isClose={() => setEditModal(false)}
          cost={item}
          orderId={orderId}
        />
      )}
    </Fragment>
  );
};

export default ExtraChargesItem;
