import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import orderCostsService from "./orderCostsService";

const initialState = {
  getOrderCostsLoading: true,
  getOrderCostsResponce: null,
  getOrderCostsError: null,

  getOrderCostLoading: true,
  getOrderCostResponce: null,
  getOrderCostError: null,

  createOrderCostLoading: false,
  createOrderCostResponce: null,
  createOrderCostError: null,

  updateOrderCostLoading: false,
  updateOrderCostResponce: null,
  updateOrderCostError: null,

  deleteOrderCostLoading: false,
  deleteOrderCostResponce: null,
  deleteOrderCostError: null,

  getOrderCostTypesLoading: false,
  getOrderCostTypesResponce: null,
  getOrderCostTypesSelectResponce: null,
  getOrderCostTypesError: null,
};

export const getOrderCosts = createAsyncThunk(
  "orderCosts/getOrderCosts",
  async (orderId, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await orderCostsService.getOrderCosts(ISO, TOKEN, orderId);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getOrderCost = createAsyncThunk(
  "orderCosts/getOrderCost",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await orderCostsService.getOrderCost(
        ISO,
        TOKEN,
        data.orderId,
        data.costId
      );
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createOrderCost = createAsyncThunk(
  "orderCosts/createOrderCost",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await orderCostsService.createOrderCost(
        ISO,
        TOKEN,
        data.orderId,
        data
      );
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateOrderCost = createAsyncThunk(
  "orderCosts/updateOrderCost",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await orderCostsService.updateOrderCost(
        ISO,
        TOKEN,
        data.orderId,
        data.costId,
        data
      );
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteOrderCost = createAsyncThunk(
  "orderCosts/deleteOrderCost",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await orderCostsService.deleteOrderCost(
        ISO,
        TOKEN,
        data.orderId,
        data.costId
      );
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getOrderCostTypes = createAsyncThunk(
  "orderCosts/getOrderCostTypes",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      const data = await orderCostsService.getOrderCostTypes(ISO, TOKEN);
      return data;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const orderCostsSlice = createSlice({
  name: "orderCosts",
  initialState,
  reducers: {
    resetGetOrderCosts: (state) => {
      state.getOrderCostsLoading = true;
      state.getOrderCostsResponce = null;
      state.getOrderCostsError = null;
    },
    resetCreateOrderCost: (state) => {
      state.createOrderCostLoading = false;
      state.createOrderCostResponce = null;
      state.createOrderCostError = null;
    },
    resetUpdateOrderCost: (state) => {
      state.updateOrderCostLoading = false;
      state.updateOrderCostResponce = null;
      state.updateOrderCostError = null;
    },
    resetDeleteOrderCost: (state) => {
      state.deleteOrderCostLoading = false;
      state.deleteOrderCostResponce = null;
      state.deleteOrderCostError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrderCosts.pending, (state) => {
        state.getOrderCostsLoading = true;
        state.getOrderCostsResponce = null;
        state.getOrderCostsError = null;
      })
      .addCase(getOrderCosts.fulfilled, (state, action) => {
        state.getOrderCostsLoading = false;
        state.getOrderCostsResponce = action.payload;
        state.getOrderCostsError = null;
      })
      .addCase(getOrderCosts.rejected, (state, action) => {
        state.getOrderCostsLoading = false;
        state.getOrderCostsResponce = null;
        state.getOrderCostsError = action.payload;
      })

      .addCase(getOrderCost.pending, (state) => {
        state.getOrderCostLoading = true;
        state.getOrderCostResponce = null;
        state.getOrderCostError = null;
      })
      .addCase(getOrderCost.fulfilled, (state, action) => {
        state.getOrderCostLoading = false;
        state.getOrderCostResponce = action.payload;
        state.getOrderCostError = null;
      })
      .addCase(getOrderCost.rejected, (state, action) => {
        state.getOrderCostLoading = false;
        state.getOrderCostResponce = null;
        state.getOrderCostError = action.payload;
      })

      .addCase(createOrderCost.pending, (state) => {
        state.createOrderCostLoading = true;
        state.createOrderCostResponce = null;
        state.createOrderCostError = null;
      })
      .addCase(createOrderCost.fulfilled, (state, action) => {
        state.createOrderCostLoading = false;
        state.createOrderCostResponce = action.payload;
        state.createOrderCostError = null;
      })
      .addCase(createOrderCost.rejected, (state, action) => {
        state.createOrderCostLoading = false;
        state.createOrderCostResponce = null;
        state.createOrderCostError = action.payload;
      })

      .addCase(updateOrderCost.pending, (state) => {
        state.updateOrderCostLoading = true;
        state.updateOrderCostResponce = null;
        state.updateOrderCostError = null;
      })
      .addCase(updateOrderCost.fulfilled, (state, action) => {
        state.updateOrderCostLoading = false;
        state.updateOrderCostResponce = action.payload;
        state.updateOrderCostError = null;
      })
      .addCase(updateOrderCost.rejected, (state, action) => {
        state.updateOrderCostLoading = false;
        state.updateOrderCostResponce = null;
        state.updateOrderCostError = action.payload;
      })

      .addCase(deleteOrderCost.pending, (state) => {
        state.deleteOrderCostLoading = true;
        state.deleteOrderCostResponce = null;
        state.deleteOrderCostError = null;
      })
      .addCase(deleteOrderCost.fulfilled, (state, action) => {
        state.deleteOrderCostLoading = false;
        state.deleteOrderCostResponce = action.payload;
        state.deleteOrderCostError = null;
      })
      .addCase(deleteOrderCost.rejected, (state, action) => {
        state.deleteOrderCostLoading = false;
        state.deleteOrderCostResponce = null;
        state.deleteOrderCostError = action.payload;
      })

      .addCase(getOrderCostTypes.pending, (state) => {
        state.getOrderCostTypesLoading = true;
        state.getOrderCostTypesResponce = null;
        state.getOrderCostTypesSelectResponce = null;
        state.getOrderCostTypesError = null;
      })
      .addCase(getOrderCostTypes.fulfilled, (state, action) => {
        state.getOrderCostTypesLoading = false;
        state.getOrderCostTypesResponce = action.payload;
        state.getOrderCostTypesSelectResponce = action.payload.map((i) => ({
          label: i.name,
          value: i,
        }));
        state.getOrderCostTypesError = null;
      })
      .addCase(getOrderCostTypes.rejected, (state, action) => {
        state.getOrderCostTypesLoading = false;
        state.getOrderCostTypesResponce = null;
        state.getOrderCostTypesSelectResponce = null;
        state.getOrderCostTypesError = action.payload;
      });
  },
});

export const {
  resetGetOrderCosts,
  resetCreateOrderCost,
  resetUpdateOrderCost,
  resetDeleteOrderCost,
} = orderCostsSlice.actions;
export default orderCostsSlice.reducer;
