import React from "react";
import "./InvoiceAmountField.scss";

import { useTranslation } from "react-i18next";
import { useMoney } from "../../../../helper/useMoney";

const InvoiceAmountField = ({ item, all, paid, left, currencySymbol }) => {
  const { t } = useTranslation();
  const formatedAll = useMoney(all, currencySymbol);
  const formatedPaid = useMoney(paid, currencySymbol);
  const formatedLeft = useMoney(left, currencySymbol);

  return (
    <td id='InvoiceAmountField'>
      <div className='container'>
        <h4>{formatedAll}</h4>
        {item?.payment_status === "partial" && paid > 0 && left > 0 && (
          <div className='paid'>
            <h5>
              {formatedPaid} {t("shipper.table.desc.paid")}
            </h5>
            <h6>
              {formatedLeft} {t("shipper.table.desc.left")}
            </h6>
          </div>
        )}
      </div>
    </td>
  );
};

export default InvoiceAmountField;
