import React, { Fragment } from "react";
import "./FinancialTableItem.scss";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  setShipperAcceptedOrderView,
  setActiveOrderId,
  setActiveOrderIndex,
} from "./../../../../store/orders/shipperAccepted/shipperAcceptedSlice";

import RowField from "./../../../Form/TableFields/RowField/RowField";
import RouteField from "../../../Form/TableFields/RouteField/RouteField";
import ShippingTypeField from "../../../Form/TableFields/ShippingTypeField/ShippingTypeField";
import InvoiceAmountField from "../../../Form/TableFields/InvoiceAmountField/InvoiceAmountField";
import ShipperPaymentStatusField from "../../../Form/TableFields/ShipperPaymentStatusField/ShipperPaymentStatusField";
import PaymentDeadlineTimeField from "../../../Form/TableFields/PaymentDeadlineTimeField/PaymentDeadlineTimeField";
import ShowInvoiceField from "../../../Form/TableFields/ShowInvoiceField/ShowInvoiceField";
import NameField from "../../../Form/TableFields/NameField/NameField";

const FinancialTableItem = ({ item, index }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const clickFieldHandler = () => {
    dispatch(setShipperAcceptedOrderView(false));
    dispatch(setActiveOrderId(item.id));
    dispatch(setActiveOrderIndex(index));
    navigate("/dashboard/shipper/accepted");
  };

  const paymentStatuses = [
    {
      label: t("shipper.filters.Unpaid"),
      value: {
        color_1: "#B95000",
        color_2: "#FFF4EC",
        name: "unpaid",
      },
    },
    {
      label: t("shipper.filters.Paid"),
      value: {
        color_1: "#287D3C",
        color_2: "#EDF9F0",
        name: "paid",
      },
    },
    {
      label: t("shipper.filters.Overdue"),
      value: {
        color_1: "#DA1414",
        color_2: "#FEEFEF",
        name: "overdue",
      },
    },
    {
      label: t("shipper.filters.Partially paid"),
      value: {
        color_1: "#2E5AAC",
        color_2: "#EEF2FA",
        name: "partial",
      },
    },
  ];

  const paymentStatus = paymentStatuses.find(
    (status) => status?.value?.name === item?.payment_status
  );

  const paymentDeadline = item?.payment_deadline !== null && {
    days: item?.payment_deadline,
    color:
      item?.payment_deadline < 0
        ? "#DA1414"
        : item?.payment_deadline < 3
        ? "#B95000"
        : "#2B353A",
  };

  return (
    <Fragment>
      <RowField
        style={{
          borderLeft: `4px solid ${item.color ? item.color : "transparent"}`,
        }}
      >
        <NameField label={item.tracking} onClick={clickFieldHandler} />
        <ShippingTypeField type={item.type} />
        <RouteField order={item} />
        <InvoiceAmountField
          item={item}
          all={item?.total_expense}
          paid={item?.total_paid}
          left={item?.total_left_paid}
          currencySymbol={item?.expense?.currency?.symbol}
        />
        <ShipperPaymentStatusField
          status={{
            name: paymentStatus?.label,
            color_1: paymentStatus?.value?.color_1,
            color_2: paymentStatus?.value?.color_2,
          }}
        />
        <PaymentDeadlineTimeField status={paymentDeadline} />
        <ShowInvoiceField item={item} />
      </RowField>
    </Fragment>
  );
};

export default FinancialTableItem;
