import { useSelector } from "react-redux";

export const useLeadErrors = () => {
  const { createLeadError } = useSelector((state) => state.lead);

  const branch_id_error =
    createLeadError &&
    createLeadError.errors &&
    createLeadError.errors.branch_id &&
    createLeadError.errors.branch_id[0];

  const name_error =
    createLeadError &&
    createLeadError.errors &&
    createLeadError.errors.name &&
    createLeadError.errors.name[0];

  const type_error =
    createLeadError &&
    createLeadError.errors &&
    createLeadError.errors.type &&
    createLeadError.errors.type[0];

  const email_error =
    createLeadError &&
    createLeadError.errors &&
    createLeadError.errors.email &&
    createLeadError.errors.email[0];

  const phone_index_id_error =
    createLeadError &&
    createLeadError.errors &&
    createLeadError.errors.phone_index_id &&
    createLeadError.errors.phone_index_id[0];

  const phone_error =
    createLeadError &&
    createLeadError.errors &&
    createLeadError.errors.phone &&
    createLeadError.errors.phone[0];

  const message_error =
    createLeadError &&
    createLeadError.errors &&
    createLeadError.errors.message &&
    createLeadError.errors.message[0];

  const agreement_error =
    createLeadError &&
    createLeadError.errors &&
    createLeadError.errors.agreement &&
    createLeadError.errors.agreement[0];

  return {
    branch_id_error,
    name_error,
    type_error,
    email_error,
    phone_index_id_error,
    phone_error,
    message_error,
    agreement_error,
  };
};

export default useLeadErrors;
