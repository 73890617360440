import React, { Fragment } from "react";

import { useTranslation } from "react-i18next";

import RowField from "../../../../../Form/TableFields/RowField/RowField";
import NameField from "../../../../../Form/TableFields/NameField/NameField";
import PriceField from "../../../../../Form/TableFields/PriceField/PriceField";
import RouteField from "./../../../../../Form/TableFields/RouteField/RouteField";
import TrailerField from "./../../../../../Form/TableFields/TrailerField/TrailerField";
import TextField from "../../../../../Form/TableFields/TextField/TextField";
import DateField from "../../../../../Form/TableFields/DateField/DateField";
import OrderStatusField from "../../../../../Form/TableFields/OrderStatusField/OrderStatusField";
import DriverPhoneField from "../../../../../Form/TableFields/DriverPhoneField/DriverPhoneField";
import PartnerField from "../../../../../Form/TableFields/PartnerField/PartnerField";
import NoDataField from "./../../../../../Form/TableFields/NoDataField/NoDataField";

const TruckOrdersTableItem = ({ order }) => {
  const { t } = useTranslation();

  const driverName = order?.carrier?.first_name ?? "";
  const driverLastName = order?.carrier?.last_name ?? "";

  return (
    <Fragment>
      <RowField
        style={{
          borderLeft: `4px solid ${order.color ? order.color : "transparent"}`,
        }}
      >
        <NameField
          label={order.tracking}
          to={`/dashboard/tms/orders/${order.id}`}
        />
        <OrderStatusField status={order.status ?? null} />
        {order.ownership ? (
          order.customer?.company?.name ? (
            <TextField max={30} label={order.customer?.company?.name ?? "-"} />
          ) : (
            <NoDataField title={t("tms.tables.noInfo.no customer")} />
          )
        ) : (
          <TextField max={30} label={order.company?.name ?? "-"} />
        )}
        <RouteField order={order} />
        {order.carrier === null ? (
          <DriverPhoneField driver={null} />
        ) : order.carrier.is_own_fleet ? (
          <DriverPhoneField
            driver={{
              name: `${driverName} ${driverLastName}`.trim(),
              phone: order?.carrier?.phone ?? "",
            }}
          />
        ) : (
          <PartnerField
            label={
              order?.carrier?.partner?.company?.name ||
              t("tms.tables.noInfo.no partner")
            }
          />
        )}

        <TrailerField
          icon={order.trailer_type?.icon}
          trailerType={order.trailer_type?.name ?? "-"}
          volume={order.trailer_size?.label ?? "-"}
        />
        <DateField date={order.cargos[0]?.pickup_date ?? null} />
        <PriceField
          number={order.revenue.amount ?? 0}
          symbol={order.revenue.currency?.symbol ?? "-"}
        />
      </RowField>
    </Fragment>
  );
};

export default TruckOrdersTableItem;
