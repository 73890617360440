import React, { Fragment } from "react";
import "./TransportationField.scss";

import { useTranslation } from "react-i18next";

import { BsBoxes } from "react-icons/bs";

const TransportationField = ({ order }) => {
  const { t } = useTranslation();
  let isTruck = false;
  let isAir = false;
  let isSea = false;
  // let isRail = false;

  switch (order?.type?.id) {
    case 1:
    case 2:
      isTruck = true;
      break;
    case 3:
    case 4:
      isSea = true;
      break;
    case 5:
      isAir = true;
      break;
    // case 6:
    // case 7:
    // isRail = true;
    // break;
    default:
      break;
  }

  return (
    <td id='TransportationField'>
      <div className='TransportationFieldContainer'>
        {isTruck && (
          <Fragment>
            <img
              src='/assets/images/dashboard/cover7.svg'
              alt='Box'
              className='icon'
            />
            <div className='info'>
              <div className='title'>{order?.trailer_type?.name}</div>
              <div className='value'>{order?.trailer_size?.label}</div>
            </div>
          </Fragment>
        )}
        {isSea && (
          <Fragment>
            <img
              src='/assets/images/dashboard/cover7.svg'
              alt='Box'
              className='icon'
            />
            <div className='info'>
              <div className='title'>
                {order?.cargos?.[0]?.trailer_type?.name}{" "}
                {order?.cargos?.length > 1 && `(${order?.cargos?.length})`}
              </div>
              <div className='value'>
                {order?.weight} {t("shipper.units.KG")}
              </div>
            </div>
          </Fragment>
        )}
        {isAir && (
          <Fragment>
            <div className='icon'>
              <BsBoxes />
            </div>
            <div className='info'>
              <div className='title'>
                {t("shipper.desc.Packed")}{" "}
                {order?.cargos?.length > 1 && `(${order?.cargos?.length})`}
              </div>
              <div className='value'>
                {order?.weight} {t("shipper.units.KG")}
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </td>
  );
};

export default TransportationField;
