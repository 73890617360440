import React, { useState, useEffect } from "react";
import "./SignUpShipperVerifyForm.scss";

import { useTranslation } from "react-i18next";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  verifyUser,
  resendVerifyUser,
  reset,
} from "../../../../store/auth/authSlice";
import { useAuthError } from "../../../../validations/auth/useAuthError";

import AuthFormWrapper from "../../Components/AuthFormWrapper/AuthFormWrapper";
import FormTitle from "../../Components/FormTitle/FormTitle";
import Button from "../../../Form/Button/Button";
import SendAgain from "../../Components/SendAgain/SendAgain";
import OTP from "../../../Form/OTP/OTP";

const SignUpShipperVerifyForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const email = searchParams.get("email");
  const { code_error } = useAuthError();
  const { loading, data, error } = useSelector((state) => state.auth);

  const [otp, setOtp] = useState();

  const submitHandler = () => {
    const data = {
      code: otp,
    };
    dispatch(verifyUser(data));
  };

  useEffect(() => {
    if (!error && data) {
      const url = "/register/shipper/success";
      navigate(url);
      dispatch(reset());
    }
  }, [error, data, dispatch, navigate]);

  const againHandler = () => {
    dispatch(reset());
    dispatch(resendVerifyUser("EMAIL"));
    setOtp();
  };

  return (
    <AuthFormWrapper>
      <form id='SignUpShipperVerifyForm' className='SignUpShipperVerifyForm'>
        <FormTitle center title={t("landing.auth.titles.Verification code")}>
          {t(
            "landing.auth.titles.Please enter the verification code you received in your email"
          )}
          <span> {email}</span>
        </FormTitle>
        <OTP value={otp} setValue={setOtp} count={6} error={code_error} />
        <Button
          loading={loading}
          title={t("landing.auth.form.Verified account")}
          onClick={() => {
            submitHandler();
          }}
        />
        <SendAgain
          title={t("landing.auth.form.Send it again")}
          onClick={() => againHandler()}
        />
      </form>
    </AuthFormWrapper>
  );
};

export default SignUpShipperVerifyForm;
