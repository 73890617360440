import React, { Fragment, useState } from "react";

import { useTranslation } from "react-i18next";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FiMoreVertical, FiEdit2, FiTrash2 } from "react-icons/fi";

import EditParticipantContactModal from "./../../EditParticipantContactModal/EditParticipantContactModal";
import DeleteParticipantContactModal from "./../../DeleteParticipantContactModal/DeleteParticipantContactModal";
import RowField from "../../../../Form/TableFields/RowField/RowField";
import TextField from "./../../../../Form/TableFields/TextField/TextField";
import MoreField from "../../../../Form/TableFields/MoreField/MoreField";

const ContactTableItem = ({ item, participantId }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <RowField>
        <TextField label={item && item.name ? item.name : "-"} />
        <TextField label={item && item.position ? item.position : "-"} />
        <TextField label={item && item.phone ? item.phone : "-"} />
        <TextField label={item && item.email ? item.email : "-"} />
        <MoreField>
          <div className='icon' onClick={handleClick}>
            <FiMoreVertical />
          </div>
          <Menu
            id='ContactTableMore'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenEditModal(true);
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <FiEdit2 />
                {t("tms.tables.buttons.Edit")}
              </div>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenDeleteModal(true);
                handleClose();
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  color: "red",
                }}
              >
                <FiTrash2 />
                {t("tms.tables.buttons.Delete")}
              </div>
            </MenuItem>
          </Menu>
        </MoreField>
      </RowField>

      <EditParticipantContactModal
        participantId={participantId}
        contactId={item.id}
        isOpen={openEditModal}
        isClose={() => setOpenEditModal(false)}
        name={item.name}
        posit={item.position}
        mail={item.email}
        tel={item.phone}
      />
      <DeleteParticipantContactModal
        participantId={participantId}
        contactId={item.id}
        isOpen={openDeleteModal}
        isClose={() => setOpenDeleteModal(false)}
      />
    </Fragment>
  );
};

export default ContactTableItem;
