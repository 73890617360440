import _request from "../../_request";

// get contact by country
const getContact = async (ISO, BranchID) => {
  const config = {
    url: `/settings/contact/${BranchID}`,
    ISO,
  };
  return _request(config);
};

// get contacts
const getContacts = async (ISO) => {
  const config = {
    url: "/settings/contact",
    ISO,
  };
  return _request(config);
};

const contactService = {
  getContact,
  getContacts,
};

export default contactService;
