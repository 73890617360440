import _request from "../../_request";

// create lead
const createLead = async (ISO, token, data) => {
  const config = {
    url: "/lead",
    ISO,
    method: "post",
    token,
  };
  return _request(config, data);
};

const leadService = {
  createLead,
};

export default leadService;
