import React from "react";
import "./ProgressField.scss";

import { useTranslation } from "react-i18next";

// range to 0 - 100

const ProgressField = ({ progress }) => {
  const { t } = useTranslation();
  return (
    <td id='ProgressFieldBar'>
      <div className='ProgressFieldBarWrapper'>
        {progress === 0 && <p>{t("shipper.desc.Not started")}</p>}

        <div className='progressContainer'>
          <div className='color' style={{ width: progress }}></div>
        </div>
      </div>
    </td>
  );
};

export default ProgressField;
