import React, { useEffect } from "react";
import "./ContactOffice.scss";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FiMapPin, FiPhone, FiMail } from "react-icons/fi";
import { BsArrowUpRightCircle } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";

// import SelectForm from "../../Form/Select/SelectForm";
import {
  getContacts,
  // setContact,
} from "./../../../store/additional/contact/contactSlice";

const ContactOffice = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { currentOffice } = useSelector((state) => state.contact);

  useEffect(() => {
    dispatch(getContacts());
  }, [dispatch]);
  return (
    <div className='ContactOffice'>
      <div className='desc'>
        <h1>{t("landing.titles.landingMainTitles.Get in touch with us")}</h1>
        <p>
          {t(
            "landing.titles.landingMainTitlesDesc.Fill out the form to talk to our team about how we can help."
          )}
        </p>

        <h3>{t("landing.titles.landingMainTitles.We are here to help")}</h3>

        <div className='row'>
          <div className='icon'>
            <BsArrowUpRightCircle />
          </div>
          <p>
            {t(
              "landing.titles.landingMainTitlesDesc.shippers to find a high-performance carrier to deliver a product to their customers"
            )}
          </p>
        </div>
        <div className='row'>
          <div className='icon'>
            <BsArrowUpRightCircle />
          </div>
          <p>
            {t(
              "landing.titles.landingMainTitlesDesc.carriers to expand their connections and make good results for themselves"
            )}
          </p>
        </div>

        <div className='line'></div>
      </div>

      {/* <div className='selectOffice'>
        {contacts && currentOffice && (
          <SelectForm
            loading={contactsLoading}
            options={contacts}
            value={currentOffice}
            setValue={(data) => dispatch(setContact(data))}
            name='ContactOffice'
            placeholder='Offices'
          />
        )}
      </div> */}
      {currentOffice && (
        <div className='card'>
          {/* <div
            className='img'
            style={{
              backgroundImage: `url(${currentOffice.value.img})`,
            }}
          ></div> */}
          <div className='container'>
            <div className='title'>{currentOffice.value.contact_city}</div>
            <div className='content'>
              <div className='item'>
                <div className='icon'>
                  <FiPhone />
                </div>
                <div>
                  <p>{t("landing.form.label.Have any question?")}</p>
                  <ul className='list'>
                    {currentOffice.value.contact_phone.map((item, index) => (
                      <li key={index}>
                        <Link to={`tel:${item}`}>{item}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className='item'>
                <div className='icon'>
                  <FiMail />
                </div>
                <div>
                  <p>{t("landing.form.label.Write email")}</p>
                  <ul className='list'>
                    <li>
                      <Link to={`mailto:${currentOffice.value.contact_email}`}>
                        {currentOffice.value.contact_email}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='item'>
                <div className='icon'>
                  <FiMapPin />
                </div>
                <div>
                  <p>{t("landing.form.label.Visit anytime")}</p>
                  <ul className='list'>
                    <li>
                      {currentOffice?.value?.location ? (
                        <Link
                          target='_blank'
                          to={`https://maps.google.com/?q=${currentOffice?.value?.location?.lat},${currentOffice?.value?.location?.lng}`}
                        >
                          {currentOffice.value.contact_address}
                        </Link>
                      ) : (
                        currentOffice.value.contact_address
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactOffice;
