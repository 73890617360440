import React, { Fragment, useState } from "react";
import "./QuotationField.scss";

import { useTranslation } from "react-i18next";
import { PiArrowElbowDownLeftBold } from "react-icons/pi";

import { useMoney } from "../../../../helper/useMoney";

import QuotationModal from "../../../Orders/ShipperOffers/QuotationModal/QuotationModal";

const QuotationField = ({ order }) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const bid = order?.bids?.[0];

  const price = useMoney(bid?.amount, bid?.currency?.symbol);

  return (
    <Fragment>
      <td id='QuotationField'>
        <div className='QuotationFieldContainer'>
          {order?.bids?.length > 0 ? (
            <div className='container' onClick={() => setOpenModal(true)}>
              <div className='text'>{price}</div>
              <div className='icon'>
                <PiArrowElbowDownLeftBold />
              </div>
            </div>
          ) : (
            <div className='waitingOffer'>
              {t("shipper.table.desc.Waiting for offer")}
            </div>
          )}
        </div>
      </td>
      {openModal && (
        <QuotationModal
          isOpen={openModal}
          isClose={() => setOpenModal(false)}
          order={order}
        />
      )}
    </Fragment>
  );
};

export default QuotationField;
