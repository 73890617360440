import React, { Fragment, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { FiTrash2 } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";

import {
  deleteOrderDocument,
  resetDeleteOrderDocument,
} from "./../../../../../store/orders/documents/documentsSlice";

import DModal from "../../../../Modal/DModal/DModal";

const DeleteDocumentModal = ({ isOpen, isClose, orderId, documentId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { deleteOrderDocumentLoading, deleteOrderDocumentResponce } =
    useSelector((state) => state.orderDocuments);

  const submitHandler = () => {
    const data = {
      orderId,
      documentId,
    };
    dispatch(deleteOrderDocument(data));
  };

  useEffect(() => {
    if (deleteOrderDocumentResponce) {
      isClose();
      dispatch(resetDeleteOrderDocument());
    }
  }, [dispatch, isClose, deleteOrderDocumentResponce]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={isClose}
        onClick={submitHandler}
        title={t("tms.modals.titles.Delete document")}
        submitText={t("tms.modals.buttons.Delete")}
        icon={<FiTrash2 />}
        status='danger'
        loading={deleteOrderDocumentLoading}
      >
        <p>
          {t(
            "tms.modals.desc.Are you sure you want to delete your document?"
          )}
        </p>
      </DModal>
    </Fragment>
  );
};

export default DeleteDocumentModal;
