import { useSelector } from "react-redux";

export const usePartnerErrors = () => {
  const { createPartnerError, updatePartnerError } = useSelector(
    (state) => state.partner
  );

  const create_company_legal_id_error =
    createPartnerError &&
    createPartnerError.errors &&
    createPartnerError.errors["company.legal_id"] &&
    createPartnerError.errors["company.legal_id"][0];

  const create_company_name_error =
    createPartnerError &&
    createPartnerError.errors &&
    createPartnerError.errors["company.name"] &&
    createPartnerError.errors["company.name"][0];

  const create_company_email_error =
    createPartnerError &&
    createPartnerError.errors &&
    createPartnerError.errors["company.email"] &&
    createPartnerError.errors["company.email"][0];

  const create_company_phone_error =
    createPartnerError &&
    createPartnerError.errors &&
    createPartnerError.errors["company.phone"] &&
    createPartnerError.errors["company.phone"][0];

  const create_company_phone2_error =
    createPartnerError &&
    createPartnerError.errors &&
    createPartnerError.errors["company.phone2"] &&
    createPartnerError.errors["company.phone2"][0];

  const create_company_phone_index_id_error =
    createPartnerError &&
    createPartnerError.errors &&
    createPartnerError.errors["company.phone_index_id"] &&
    createPartnerError.errors["company.phone_index_id"][0];

  const create_company_phone2_index_id_error =
    createPartnerError &&
    createPartnerError.errors &&
    createPartnerError.errors["company.phone2_index_id"] &&
    createPartnerError.errors["company.phone2_index_id"][0];

  const create_company_address_error =
    createPartnerError &&
    createPartnerError.errors &&
    createPartnerError.errors["company.address"] &&
    createPartnerError.errors["company.address"][0];

  const update_company_legal_id_error =
    updatePartnerError &&
    updatePartnerError.errors &&
    updatePartnerError.errors["company.legal_id"] &&
    updatePartnerError.errors["company.legal_id"][0];

  const update_company_name_error =
    updatePartnerError &&
    updatePartnerError.errors &&
    updatePartnerError.errors["company.name"] &&
    updatePartnerError.errors["company.name"][0];

  const update_company_email_error =
    updatePartnerError &&
    updatePartnerError.errors &&
    updatePartnerError.errors["company.email"] &&
    updatePartnerError.errors["company.email"][0];

  const update_company_phone_error =
    updatePartnerError &&
    updatePartnerError.errors &&
    updatePartnerError.errors["company.phone"] &&
    updatePartnerError.errors["company.phone"][0];

  const update_company_phone2_error =
    updatePartnerError &&
    updatePartnerError.errors &&
    updatePartnerError.errors["company.phone2"] &&
    updatePartnerError.errors["company.phone2"][0];

  const update_company_phone_index_id_error =
    updatePartnerError &&
    updatePartnerError.errors &&
    updatePartnerError.errors["company.phone_index_id"] &&
    updatePartnerError.errors["company.phone_index_id"][0];

  const update_company_phone2_index_id_error =
    updatePartnerError &&
    updatePartnerError.errors &&
    updatePartnerError.errors["company.phone2_index_id"] &&
    updatePartnerError.errors["company.phone2_index_id"][0];

  const update_company_address_error =
    updatePartnerError &&
    updatePartnerError.errors &&
    updatePartnerError.errors["company.address"] &&
    updatePartnerError.errors["company.address"][0];

  return {
    create_company_legal_id_error,
    create_company_name_error,
    create_company_email_error,
    create_company_phone_error,
    create_company_phone2_error,
    create_company_phone_index_id_error,
    create_company_phone2_index_id_error,
    create_company_address_error,
    update_company_legal_id_error,
    update_company_name_error,
    update_company_email_error,
    update_company_phone_error,
    update_company_phone2_error,
    update_company_phone_index_id_error,
    update_company_phone2_index_id_error,
    update_company_address_error,
  };
};

export default usePartnerErrors;
