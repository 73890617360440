import _request from "../../_request";

const createCargo = async (ISO, TOKEN, data) => {
  const config = {
    url: "cargo",
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const updateCargo = async (ISO, TOKEN, cargoId, data) => {
  const config = {
    url: `cargo/${cargoId}`,
    method: "put",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const deleteCargo = async (ISO, TOKEN, cargoId) => {
  const config = {
    url: `/cargo/${cargoId}`,
    method: "delete",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getCargoStatuses = async (ISO, TOKEN) => {
  const config = {
    url: "cargo-statuses",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const updateCargoStatuses = async (ISO, TOKEN, cargoId, data) => {
  const config = {
    url: `cargo/${cargoId}/change-status`,
    method: "put",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const cargoService = {
  createCargo,
  updateCargo,
  deleteCargo,
  getCargoStatuses,
  updateCargoStatuses,
};

export default cargoService;
