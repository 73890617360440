import React, { Fragment, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { getUser } from "./../store/auth/authSlice";
import { getLanguages } from "../store/additional/lang/langSlice";
import {
  getContact,
  getContacts,
} from "../store/additional/contact/contactSlice";
import {
  getCountries,
  getPhoneIndexes,
  getCurrency,
  getAllCountries,
  getGeolocationCountry,
  setPhoneIndexValueOnlyState,
  setCountryIdOnlyState,
} from "../store/additional/settings/settingsSlice";

import AppLanding from "./AppLanding";
import AppDashboard from "./AppDashboard";
import Loading from "./../componentsAdditional/Loading/Loading";
import AppBids from "./AppBids";

const Main = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { userLoading, verify, userError, access_token } = useSelector(
    (state) => state.auth
  );
  const { updateUserResponce, verifyEmailResponce, verifyPhoneResponce } =
    useSelector((state) => state.user);
  const {
    createBankAccountResponce,
    updateBankAccountResponce,
    deleteBankAccountResponce,
  } = useSelector((state) => state.bankAccounts);
  const {
    createContactResponce,
    updateContactResponce,
    deleteContactResponce,
  } = useSelector((state) => state.userContacts);
  const { uploadCompanyLogoResponce, uploadProfileAvatarResponce } =
    useSelector((state) => state.upload);

  const {
    getGeolocationCountryResponce,
    phoneIndexValueId,
    phoneIndexes,
    countryId,
    countries,
  } = useSelector((state) => state.settings);

  useEffect(() => {
    dispatch(getLanguages());
    dispatch(getCountries());
    dispatch(getPhoneIndexes());
    dispatch(getAllCountries());
    dispatch(getGeolocationCountry());
  }, [dispatch]);

  useEffect(() => {
    if (access_token) {
      dispatch(getUser());
    }
  }, [
    dispatch,
    access_token,
    updateUserResponce,
    verifyEmailResponce,
    verifyPhoneResponce,
    createBankAccountResponce,
    updateBankAccountResponce,
    deleteBankAccountResponce,
    createContactResponce,
    updateContactResponce,
    deleteContactResponce,
    uploadCompanyLogoResponce,
    uploadProfileAvatarResponce,
  ]);

  useEffect(() => {
    if (userError && userError.status === 429) {
      alert("Too many requests");
    }
  }, [userError]);

  useEffect(() => {
    const { country } = getGeolocationCountryResponce || {};
    if (!phoneIndexValueId && country && phoneIndexes) {
      const findPhoneIndex =
        phoneIndexes.find((i) => i.code === country)?.id ?? 83;
      dispatch(setPhoneIndexValueOnlyState(findPhoneIndex));
    }
  }, [
    dispatch,
    phoneIndexValueId,
    getGeolocationCountryResponce,
    phoneIndexes,
  ]);

  useEffect(() => {
    const { country } = getGeolocationCountryResponce || {};
    if (!countryId && country && countries) {
      const findBranchIndex =
        countries.find((i) => i.country.code === country)?.id ?? 1;
      dispatch(setCountryIdOnlyState(findBranchIndex));
    }
  }, [dispatch, countries, countryId, getGeolocationCountryResponce]);

  useEffect(() => {
    if (countryId) {
      dispatch(getContact());
      dispatch(getContacts());
    }
  }, [dispatch, countryId]);

  useEffect(() => {
    if (verify) {
      dispatch(getCurrency());
    }
  }, [dispatch, verify]);

  if (pathname.includes("/bids/partner/")) {
    return <AppBids />;
  }

  if (access_token && userLoading) {
    return <Loading />;
  }

  return <Fragment>{verify ? <AppDashboard /> : <AppLanding />}</Fragment>;
};

export default Main;
