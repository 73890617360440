import _request from "./../_request";

const getFinished = async (ISO, TOKEN, data) => {
  let url = "/order/payments/paid?";
  const {
    page,
    keyword,
    customer_id,
    pickup_address,
    dropoff_address,
    status_id,
  } = data || {};

  url += page ? `page=${page}` : "";
  url += keyword ? `&keyword=${keyword}` : "";
  url += customer_id ? `&customer_id=${customer_id}` : "";
  url += pickup_address ? `&pickup_address=${pickup_address}` : "";
  url += dropoff_address ? `&dropoff_address=${dropoff_address}` : "";
  url += status_id ? `&status_id=${status_id}` : "";

  const config = {
    url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getActive = async (ISO, TOKEN, data) => {
  let url = "/order/payments/pending?";
  const {
    page,
    keyword,
    customer_id,
    pickup_address,
    dropoff_address,
    status_id,
  } = data || {};

  url += page ? `page=${page}` : "";
  url += keyword ? `&keyword=${keyword}` : "";
  url += customer_id ? `&customer_id=${customer_id}` : "";
  url += pickup_address ? `&pickup_address=${pickup_address}` : "";
  url += dropoff_address ? `&dropoff_address=${dropoff_address}` : "";
  url += status_id ? `&status_id=${status_id}` : "";

  const config = {
    url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const financialService = {
  getFinished,
  getActive,
};

export default financialService;
