import React from "react";
import "./FilterTrailerGroupSelect.scss";

import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import Select from "react-select";

const FilterTrailerGroupSelect = ({ value, setValue }) => {
  const { t } = useTranslation();

  const { getTrailerGroupSelectResponse } = useSelector(
    (state) => state.trailers
  );

  return (
    <div id='FilterTrailerGroupSelect'>
      <Select
        classNamePrefix='select'
        name='filterTrailerSelect'
        className='selectComponent'
        options={getTrailerGroupSelectResponse || []}
        placeholder={t("tms.filters.placeholders.Trailer")}
        value={value}
        onChange={(state) => setValue(state)}
        isClearable={true}
        isSearchable={true}
        noOptionsMessage={() => (
          <div>{t("tms.filters.others.No trailer found")}</div>
        )}
      />
    </div>
  );
};

export default FilterTrailerGroupSelect;
