import { useSelector } from "react-redux";

export const useUpdateUser = () => {
  const { updateUserError } = useSelector((state) => state.user);

  const update_first_name_error =
    updateUserError &&
    updateUserError.errors &&
    updateUserError.errors.first_name &&
    updateUserError.errors.first_name[0];

  const update_last_name_error =
    updateUserError &&
    updateUserError.errors &&
    updateUserError.errors.last_name &&
    updateUserError.errors.last_name[0];

  const update_company_name_error =
    updateUserError &&
    updateUserError.errors &&
    updateUserError.errors["company.name"] &&
    updateUserError.errors["company.name"][0];

  const update_company_legal_id_error =
    updateUserError &&
    updateUserError.errors &&
    updateUserError.errors["company.legal_id"] &&
    updateUserError.errors["company.legal_id"][0];

  const update_carrier_legal_id_error =
    updateUserError &&
    updateUserError.errors &&
    updateUserError.errors.legal_id &&
    updateUserError.errors.legal_id[0];

  return {
    update_first_name_error,
    update_last_name_error,
    update_company_name_error,
    update_company_legal_id_error,
    update_carrier_legal_id_error,
  };
};
