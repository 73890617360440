import React, { Fragment, useState, useEffect } from "react";
import "./EditCustomerModal.scss";

import { useTranslation } from "react-i18next";
import { FiEdit2 } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";

import {
  updateCustomer,
  resetUpdateCustomer,
} from "../../../../store/participants/customer/customerSlice";
import { useCustomerErrors } from "./../../../../validations/participant/customer/useCustomerErrors";

import DModal from "../../../Modal/DModal/DModal";
import DInputs from "../../../Form/DInputs/DInputs";
import DPhoneInput from "./../../../Form/DPhoneInput/DPhoneInput";

const EditCustomerModal = ({ participant, isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { updateCustomerLoading, updateCustomerResponce } = useSelector(
    (state) => state.customer
  );
  const { phoneIndexValueId } = useSelector((state) => state.settings);
  const {
    update_company_legal_id_error,
    update_company_name_error,
    update_company_email_error,
    update_company_phone_error,
    update_company_phone_index_id_error,
    update_company_address_error,
  } = useCustomerErrors();
  const [customerID, setCustomerID] = useState(
    participant.company.legal_id ? participant.company.legal_id : ""
  );

  const [customerName, setCustomerName] = useState(
    participant.company.name ? participant.company.name : ""
  );
  const [email, setEmail] = useState(
    participant.company.email ? participant.company.email : ""
  );
  const [phone, setPhone] = useState(
    participant.company.phone ? participant.company.phone : ""
  );
  const [address, setAddress] = useState(
    participant.company.address ? participant.company.address : ""
  );
  const submitHandler = () => {
    const data = {
      participantId: participant.id,
      type: "Customer",
      company: {
        legal_id: customerID,
        name: customerName,
        email: email,
        phone: phone,
        phone_index_id: phoneIndexValueId,
        address: address,
      },
    };
    dispatch(updateCustomer(data));
  };

  useEffect(() => {
    if (updateCustomerResponce) {
      dispatch(resetUpdateCustomer());
      isClose();
    }
  }, [dispatch, updateCustomerResponce, isClose]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={isClose}
        title={t("tms.modals.titles.Edit Customer")}
        icon={<FiEdit2 />}
        status='success'
        onClick={() => submitHandler()}
        submitText={t("tms.modals.buttons.Save")}
        loading={updateCustomerLoading}
      >
        <div id='EditCustomerModal'>
          <div className='EditCustomerModalgroup'>
            <DInputs
              value={customerName}
              setValue={setCustomerName}
              placeholder={t("tms.modals.placeholder.Enter customer name")}
              label={t("tms.modals.labels.Customer name")}
              required
              id='customerName'
              error={update_company_name_error}
            />
            <DInputs
              value={customerID}
              setValue={setCustomerID}
              placeholder={t("tms.modals.placeholder.Enter customer ID")}
              label={t("tms.modals.labels.Customer ID")}
              required
              id='CustomerID'
              error={update_company_legal_id_error}
            />

            <DInputs
              value={email}
              setValue={setEmail}
              placeholder={t("tms.modals.placeholder.Enter email")}
              label={t("tms.modals.labels.Email")}
              required
              id='CustomerEmail'
              error={update_company_email_error}
            />
            <DPhoneInput
              id='CustomerPhone'
              value={phone}
              setValue={setPhone}
              placeholder={t("tms.modals.placeholder.Enter phone number")}
              label={t("tms.modals.labels.Phone number")}
              error={
                update_company_phone_error ||
                update_company_phone_index_id_error
              }
            />
            <DInputs
              value={address}
              setValue={setAddress}
              placeholder={t("tms.modals.placeholder.Enter address")}
              label={t("tms.modals.labels.Address")}
              id='CustomerAddress'
              error={update_company_address_error}
            />
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default EditCustomerModal;
