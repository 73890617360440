import React, { useState, useEffect } from "react";
import "./FavoriteRoutesModal.scss";

import { useTranslation } from "react-i18next";
import { MdEdit, MdClose } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";

import {
  getLocations,
  resetGetLocations,
} from "../../../../store/favoriteLocations/favoriteLocationsSlice";

import DOverlay from "./../../../Modal/DOverlay/DOverlay";
import FavoriteRoutesModalItem from "./FavoriteRoutesModalItem/FavoriteRoutesModalItem";
import LoadingPage from "./../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";

const FavoriteRoutesModal = ({ isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    getLocationsLoading,
    getLocationsResponce,
    createLocationResponce,
    deleteLocationResponce,
  } = useSelector((state) => state.favoriteLocations);

  const [isEditActive, setIsEditActive] = useState(false);
  const toggleEditActive = () => {
    setIsEditActive(!isEditActive);
  };

  useEffect(() => {
    dispatch(getLocations());
    return () => {
      dispatch(resetGetLocations());
    };
  }, [dispatch, createLocationResponce, deleteLocationResponce]);

  return (
    <DOverlay isOpen={isOpen} isClose={isClose} transparent>
      <div id='FavoriteRoutesModal'>
        <div className='FavoriteRoutesModalContainer'>
          <div className='FavoriteRoutesModalContainerHeader'>
            <div className='FavoriteRoutesModalContainerHeaderLeft'>
              <h1>{t("tms.titles.Favourite routes")}</h1>
            </div>
            <div className='FavoriteRoutesModalContainerHeaderRight'>
              <div
                className={
                  isEditActive
                    ? "FavoriteRoutesModalIcon active"
                    : "FavoriteRoutesModalIcon"
                }
                onClick={toggleEditActive}
              >
                <MdEdit />
              </div>
              <div className='FavoriteRoutesModalIcon' onClick={isClose}>
                <MdClose />
              </div>
            </div>
          </div>
          {getLocationsLoading && <LoadingPage />}
          {getLocationsResponce && !getLocationsLoading && (
            <ul className='FavoriteRoutesModalContainerContent'>
              {getLocationsResponce.length === 0 ? (
                <div id='noRoutes'>
                  {t("tms.desc.No favourite routes found.")}
                </div>
              ) : (
                getLocationsResponce.map((item) => (
                  <FavoriteRoutesModalItem
                    key={item.id}
                    isEditActive={isEditActive}
                    item={item}
                    isClose={isClose}
                  />
                ))
              )}
            </ul>
          )}
        </div>
      </div>
    </DOverlay>
  );
};

export default FavoriteRoutesModal;
