import React from "react";
import "./CreateOrderAdrSection.scss";

import { useTranslation } from "react-i18next";

import DCheckBox from "./../../../../Form/DCheckBox/DCheckBox";
import DInputs from "./../../../../Form/DInputs/DInputs";
import DFileForm from "./../../../../Form/DFile/DFileForm/DFileForm";
import DErrorText from "./../../../../Form/DErrorText/DErrorText";

const CreateOrderAdrSection = ({
  adr,
  setAdr,
  adrNumber,
  setAdrNumber,
  adrClass,
  setAdrClass,
  adrFile,
  setAdrFile,
  cargos_adr_error,
  cargos_un_number_error,
  cargos_adr_class_error,
  cargos_msds_file_error,
}) => {
  const { t } = useTranslation();

  return (
    <div id='CreateOrderAdrSection'>
      <DCheckBox
        value={adr}
        setValue={() => setAdr((state) => !state)}
        label={t("shipper.forms.content.ADR cargo")}
      />
      <DErrorText error={cargos_adr_error} />
      {adr && (
        <div className='adrGroupContainer'>
          {setAdrNumber && (
            <DInputs
              value={adrNumber}
              setValue={(value) => setAdrNumber(value)}
              placeholder={t("shipper.forms.content.ADR UN number")}
              error={cargos_un_number_error}
            />
          )}
          {setAdrClass && (
            <DInputs
              value={adrClass}
              setValue={(value) => setAdrClass(value)}
              placeholder={t("shipper.forms.content.ADR class")}
              error={cargos_adr_class_error}
            />
          )}
          {setAdrFile && (
            <DFileForm
              value={adrFile}
              setValue={(value) => setAdrFile(value)}
              title={t("shipper.forms.content.Upload cargo MSDS")}
              subTitle={t("shipper.forms.content.Optional")}
              error={cargos_msds_file_error}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default CreateOrderAdrSection;
