import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import orderSettingsService from "./orderSettingsService";

const initialState = {
  // direction - distibution - Collection
  orderTransferTypesLoading: false,
  orderTransferTypesResponce: null,
  orderTransferTypesError: null,

  // FTL - LTL
  orderTypesLoading: false,
  orderTypesResponce: null,
  orderTypesSelectResponce: null,
  orderTypesError: null,

  // ალკოჰოლი - საკვები და სასმელი
  cargoTypesLoading: false,
  cargoTypesResponce: null,
  cargoTypesSelectResponce: null,
  cargoTypesError: null,

  // trailer-types by branchId, ordertype
  trailerTypesLoading: false,
  trailerTypes: null,
  trailerTypesSelect: null,
  trailerTypesError: null,

  // get Temperature Ranges
  temperaturesLoading: false,
  temperatures: null,
  temperaturesSelect: null,
  temperaturesError: null,

  //get Order Payment Type
  orderPaymentTypesLoading: false,
  orderPaymentTypes: null,
  orderPaymentTypesSelect: null,
  orderPaymentTypesError: null,

  //get order services list
  getOrderServicesLoading: true,
  getOrderServicesResponce: null,
  getOrderServicesError: null,

  //get cargo package list
  getCargoPackageLoading: true,
  getCargoPackageResponce: null,
  getCargoPackageResponceSelect: null,
  getCargoPackageError: null,
};

// direction - distibution - Collection
export const getOrderTransferTypes = createAsyncThunk(
  "orderSettings/getOrderTransferTypes",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      const branchId = thunkAPI.getState().auth.user.branch.id;
      return await orderSettingsService.getOrderTransferTypes(
        ISO,
        TOKEN,
        branchId
      );
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// FTL - LTL
export const getOrderTypes = createAsyncThunk(
  "orderSettings/getOrderTypes",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      const branchId = thunkAPI.getState().auth.user.branch.id;
      return await orderSettingsService.getOrderTypes(ISO, TOKEN, branchId);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// ალკოჰოლი - საკვები და სასმელი
export const getCargoTypes = createAsyncThunk(
  "orderSettings/getCargoTypes",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await orderSettingsService.getCargoTypes(ISO, TOKEN);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// trailer-types by branchId, ordertype
export const getTrailerTypes = createAsyncThunk(
  "orderSettings/getTrailerTypes",
  async (ordertypeId, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      const branchId = thunkAPI.getState().auth.user.branch.id;
      return await orderSettingsService.getTrailerTypes(
        ISO,
        TOKEN,
        branchId,
        ordertypeId
      );
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//getTemperatureRanges
export const getTemperatureRanges = createAsyncThunk(
  "orderSettings/getTemperatureRanges",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await orderSettingsService.getTemperatureRanges(ISO, TOKEN);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// getOrderPaymentType
export const getOrderPaymentType = createAsyncThunk(
  "orderSettings/getOrderPaymentType",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      const branchId = thunkAPI.getState().auth.user.branch.id;
      return await orderSettingsService.getOrderPaymentType(
        ISO,
        TOKEN,
        branchId
      );
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get order services list
export const getOrderServicesList = createAsyncThunk(
  "orderSettings/getOrderServicesList",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await orderSettingsService.getOrderServicesList(ISO, TOKEN);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get cargo package list
export const getCargoPackageList = createAsyncThunk(
  "orderSettings/getCargoPackageList",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await orderSettingsService.getCargoPackageList(ISO, TOKEN);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const orderSettingsSlice = createSlice({
  name: "orderSettings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // direction - distibution - Collection
      .addCase(getOrderTransferTypes.pending, (state) => {
        state.orderTransferTypesLoading = true;
      })
      .addCase(getOrderTransferTypes.fulfilled, (state, action) => {
        state.orderTransferTypesLoading = false;
        state.orderTransferTypesResponce = action.payload;
        state.orderTransferTypesError = null;
      })
      .addCase(getOrderTransferTypes.rejected, (state, action) => {
        state.orderTransferTypesLoading = false;
        state.orderTransferTypesResponce = null;
        state.orderTransferTypesError = action.payload;
      })

      // FTL - LTL
      .addCase(getOrderTypes.pending, (state) => {
        state.orderTypesLoading = true;
      })
      .addCase(getOrderTypes.fulfilled, (state, action) => {
        state.orderTypesLoading = false;
        state.orderTypesResponce = action.payload;
        state.orderTypesSelectResponce = action.payload.map((item) => ({
          label: item.name,
          value: item,
        }));
        state.orderTypesError = null;
      })
      .addCase(getOrderTypes.rejected, (state, action) => {
        state.orderTypesLoading = false;
        state.orderTypesResponce = null;
        state.orderTypesSelectResponce = null;
        state.orderTypesError = action.payload;
      })

      // ალკოჰოლი - საკვები და სასმელი
      .addCase(getCargoTypes.pending, (state) => {
        state.cargoTypesLoading = true;
      })
      .addCase(getCargoTypes.fulfilled, (state, action) => {
        state.cargoTypesLoading = false;
        state.cargoTypesResponce = action.payload;
        state.cargoTypesSelectResponce = action.payload.map((item) => ({
          value: item,
          label: item.name,
        }));
        state.cargoTypesError = null;
      })
      .addCase(getCargoTypes.rejected, (state, action) => {
        state.cargoTypesLoading = false;
        state.cargoTypesResponce = null;
        state.cargoTypesSelectResponce = null;
        state.cargoTypesError = action.payload;
      })

      // trailer-types by branchId, ordertype
      .addCase(getTrailerTypes.pending, (state) => {
        state.trailerTypesLoading = true;
        state.trailerTypes = null;
        state.trailerTypesSelect = null;
        state.trailerTypesError = null;
      })
      .addCase(getTrailerTypes.fulfilled, (state, action) => {
        state.trailerTypesLoading = false;
        state.trailerTypes = action.payload;
        state.trailerTypesSelect = action.payload.map((item) => ({
          label: item.name,
          options: item.types.map((i) => ({
            label: i.name,
            value: {
              ...i,
              sizes: i.sizes.map((size) => ({
                label: size.label,
                value: size,
              })),
            },
          })),
        }));
        state.trailerTypesError = null;
      })
      .addCase(getTrailerTypes.rejected, (state, action) => {
        state.trailerTypesLoading = false;
        state.trailerTypes = null;
        state.trailerTypesSelect = null;
        state.trailerTypesError = action.payload;
      })

      // getTemperatureRanges
      .addCase(getTemperatureRanges.pending, (state) => {
        state.temperaturesLoading = true;
        state.temperatures = null;
        state.temperaturesSelect = null;
        state.temperaturesError = null;
      })
      .addCase(getTemperatureRanges.fulfilled, (state, action) => {
        state.temperaturesLoading = false;
        state.temperatures = action.payload;
        state.temperaturesSelect = action.payload.map((item) => ({
          label: item.title,
          value: item,
        }));
        state.temperaturesError = null;
      })
      .addCase(getTemperatureRanges.rejected, (state, action) => {
        state.temperaturesLoading = false;
        state.temperatures = null;
        state.temperaturesSelect = null;
        state.temperaturesError = action.payload;
      })

      //get Order Payment Type
      .addCase(getOrderPaymentType.pending, (state) => {
        state.orderPaymentTypesLoading = true;
      })
      .addCase(getOrderPaymentType.fulfilled, (state, action) => {
        state.orderPaymentTypesLoading = false;
        state.orderPaymentTypes = action.payload;
        state.orderPaymentTypesSelect = action.payload.map((item) => ({
          label: item.name,
          value: item,
        }));
        state.orderPaymentTypesError = null;
      })
      .addCase(getOrderPaymentType.rejected, (state, action) => {
        state.orderPaymentTypesLoading = false;
        state.orderPaymentTypes = null;
        state.orderPaymentTypesSelect = null;
        state.orderPaymentTypesError = action.payload;
      })

      //get order services list
      .addCase(getOrderServicesList.pending, (state) => {
        state.getOrderServicesLoading = true;
        state.getOrderServicesResponce = null;
        state.getOrderServicesError = null;
      })
      .addCase(getOrderServicesList.fulfilled, (state, action) => {
        state.getOrderServicesLoading = false;
        state.getOrderServicesResponce = action.payload;
        state.getOrderServicesError = null;
      })
      .addCase(getOrderServicesList.rejected, (state, action) => {
        state.getOrderServicesLoading = false;
        state.getOrderServicesResponce = null;
        state.getOrderServicesError = action.payload;
      })

      // get cargo package list
      .addCase(getCargoPackageList.pending, (state) => {
        state.getCargoPackageLoading = true;
        state.getCargoPackageResponce = null;
        state.getCargoPackageResponceSelect = null;
        state.getCargoPackageError = null;
      })
      .addCase(getCargoPackageList.fulfilled, (state, action) => {
        state.getCargoPackageLoading = false;
        state.getCargoPackageResponce = action.payload;
        state.getCargoPackageResponceSelect = action.payload.map((item) => ({
          label: item.name,
          value: item,
        }));
        state.getCargoPackageError = null;
      })
      .addCase(getCargoPackageList.rejected, (state, action) => {
        state.getCargoPackageLoading = false;
        state.getCargoPackageResponce = null;
        state.getCargoPackageResponceSelect = null;
        state.getCargoPackageError = action.payload;
      });
  },
});

// export const {} = ordersSlice.actions;
export default orderSettingsSlice.reducer;
