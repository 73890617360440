import React, { useState, useEffect } from "react";
import "./Form.scss";

import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import { CgMailForward } from "react-icons/cg";
import { useSelector, useDispatch } from "react-redux";

import { containsOnlyNumbers } from "./../../../helper/useContainNum";
import { useLeadErrors } from "./../../../validations/lead/useLeadErrors";
import {
  createLead,
  resetCreateLead,
} from "../../../store/additional/lead/leadSlice";

import Button from "./../../Form/Button/Button";
import Label from "./../../Form/Label/Label";
import TextArea from "./../../Form/TextArea/TextArea";
import RoleRadio from "./../../Form/Radio/RoleRadio/RoleRadio";
import ConvertInput from "./../../Form/Inputs/ConvertInput/ConvertInput";
import ErrorText from "./../../Form/ErrorText/ErrorText";
import Input from "../../Form/Inputs/Input";
import SnackBar from "../../../componentsDashboard/Form/SnackBar/SnackBar";

const Form = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { createLeadLoading, createLeadResponce, createLeadError } =
    useSelector((state) => state.lead);

  const { phoneIndexValueId, countryId } = useSelector(
    (state) => state.settings
  );
  const {
    branch_id_error,
    name_error,
    type_error,
    email_error,
    phone_index_id_error,
    phone_error,
    message_error,
    agreement_error,
  } = useLeadErrors();

  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const [convertValue, setConvertValue] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [method, setMethod] = useState("EMAIL");
  const [role, setRole] = useState();

  const reset = () => {
    setConvertValue("");
    setName("");
    setMessage("");
    setMethod("EMAIL");
    setRole("");
  };

  const submitHandler = () => {
    let data;
    if (method === "EMAIL") {
      data = {
        branch_id: countryId,
        name: name,
        type: role,
        email: convertValue,
        message: message,
        agreement: true,
      };
    } else {
      data = {
        branch_id: countryId,
        name: name,
        type: role,
        phone_index_id: phoneIndexValueId,
        phone: convertValue,
        message: message,
        agreement: true,
      };
    }
    dispatch(createLead(data));
  };

  useEffect(() => {
    if (createLeadResponce) {
      reset();
      dispatch(resetCreateLead());
      setSnackBarOpen(true);
    }
  }, [dispatch, createLeadError, createLeadResponce]);

  useEffect(() => {
    const onlyNumber = containsOnlyNumbers(convertValue);
    if (convertValue.length > 2 && onlyNumber) {
      setMethod("PHONE");
    } else {
      setMethod("EMAIL");
    }
  }, [convertValue]);

  return (
    <div id='Form' className='Form'>
      <SnackBar
        status={"success"}
        text={"Successfully submited"}
        isOpen={snackBarOpen}
        isClose={() => setSnackBarOpen(false)}
      />
      <div
        className='animation'
        data-aos='fade-left'
        data-aos-duration='1500'
        data-aos-once='true'
      >
        <div className='formContainer'>
          <div>
            <h1>
              {parse(
                t("landing.titles.landingMainTitles.Request a call back !")
              )}
            </h1>
          </div>
          <div className='inputContainer'>
            <Label
              text={t("landing.form.label.*Tell us your name")}
              id='name'
            />
            <Input
              id='name'
              value={name}
              setValue={setName}
              placeholder={t("landing.form.placeholder.Enter your name")}
              error={name_error}
            />
            <ErrorText error={name_error} />
          </div>
          <div className='convertContainer'>
            <Label
              text={t("landing.form.label.*Work email or phone number")}
              id='emailorphone'
            />
            <ConvertInput
              id='emailorphone'
              inputStatus={method}
              value={convertValue}
              setValue={setConvertValue}
              error={email_error || phone_index_id_error || phone_error}
              placeholder={t(
                "landing.form.placeholder.Enter email or phone number"
              )}
            />
            {method === "EMAIL" && <ErrorText error={email_error} />}
            {method !== "EMAIL" &&
              (<ErrorText error={phone_index_id_error} /> ||
                (method !== "EMAIL" && <ErrorText error={phone_error} />))}
          </div>
          <div className='checkBoxContainer'>
            <Label text={t("landing.form.label.*Area of interest")} />
            <RoleRadio value={role} setValue={setRole} />
            <ErrorText error={type_error} />
          </div>
          <div className='textAreaContainer'>
            <Label
              text={t("landing.form.label.*How can we help you?")}
              id='Message'
            />
            <TextArea
              placeholder={t("landing.form.placeholder.Your message...")}
              value={message}
              setValue={setMessage}
              id='Message'
              name='Message'
              error={message_error}
            />
            <ErrorText error={message_error} />
            <ErrorText error={branch_id_error} />
            <ErrorText error={agreement_error} />
          </div>

          <div className='buttonContainer'>
            <Button
              loading={createLeadLoading}
              title={t("landing.form.button.Submit")}
              rightIcon={<CgMailForward />}
              onClick={() => {
                submitHandler();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
