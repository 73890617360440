import React, { Fragment } from "react";
import "./CountrySelector.scss";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { IoIosArrowUp } from "react-icons/io";

import { setCountryId } from "../../../store/additional/settings/settingsSlice";
import { setCountryInUrl } from "../../../helper/setCountryInUrl";

const CountrySelector = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { countries, countryId } = useSelector((state) => state.settings);

  const currentCountry = countries?.find(
    (country) => country?.id === countryId
  )?.country;

  const onChange = (country) => {
    dispatch(setCountryId(country?.id));
    setCountryInUrl(country?.country?.code?.toLowerCase());
  };

  const RenderItem = ({ item }) => {
    return (
      <li onClick={() => onChange(item)} className='item'>
        {item?.country?.name}
      </li>
    );
  };

  return (
    <Fragment>
      <div id='CountrySelector'>
        <div className='countrySelectorButton'>
          <div className='title'>
            {currentCountry?.name}{" "}
            {t("landing.titles.landingMainTitlesDesc.office")}
          </div>
          <div className='icon'>
            <IoIosArrowUp />
          </div>
        </div>
        <ul className='countrySelectorContent'>
          {countries &&
            countries.map((item) => <RenderItem key={item?.id} item={item} />)}
        </ul>
      </div>
    </Fragment>
  );
};

export default CountrySelector;
