import React from "react";
import "./LangMobileButton.scss";

import { useSelector } from "react-redux";

const LangMobileButton = ({ setValue }) => {
  const { languages, ISO } = useSelector((state) => state.lang);

  const currentValueLang =
    languages && languages.find((item) => item.key === ISO);

  return (
    <div id='LangMobileButton'>
      {languages && currentValueLang && (
        <div
          className='langButtonResponsive'
          onClick={() => {
            setValue(true);
          }}
        >
          <div
            className='flag'
            style={{ backgroundImage: `url(${currentValueLang.flag})` }}
          ></div>
          <p>{currentValueLang && currentValueLang.native}</p>
        </div>
      )}
    </div>
  );
};

export default LangMobileButton;
