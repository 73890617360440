import _request from "./../../_request";

const getFinancialOrders = async (ISO, TOKEN, data) => {
  let url = "/order/shipper-payments?";
  const {
    page,
    keyword,
    status_id,
    pickup_address,
    dropoff_address,
    min_price,
    max_price,
    payment_status,
    start_date,
    end_date,
  } = data || {};
  url += page ? `page=${page}` : "";
  url += keyword ? `&keyword=${keyword}` : "";
  url += status_id ? `&status_id=${status_id}` : "";
  url += pickup_address ? `&pickup_address=${pickup_address}` : "";
  url += dropoff_address ? `&dropoff_address=${dropoff_address}` : "";
  url += min_price ? `&min_price=${min_price}` : "";
  url += max_price ? `&max_price=${max_price}` : "";
  url += payment_status ? `&payment_status=${payment_status}` : "";
  url += start_date ? `&start_date=${start_date}` : "";
  url += end_date ? `&end_date=${end_date}` : "";

  const config = {
    url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const shipperFinancialService = {
  getFinancialOrders,
};

export default shipperFinancialService;
