import React, { Fragment, useEffect, useState } from "react";
import "./ShipperCards.scss";

import { useDispatch, useSelector } from "react-redux";

import { getGeneralAnalytics } from "../../../store/analytics/analyticsSlice";
import moment from "moment";

import { Skeleton } from "@mui/material";

import ShipperCardsContainer from "./ShipperCardsContainer/ShipperCardsContainer";
import ShipperCardsSwitcher from "./ShipperCardsSwitcher/ShipperCardsSwitcher";
import ShipperCardsCalendar from "./ShipperCardsCalendar/ShipperCardsCalendar";

const ShipperCards = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { getGeneralAnalyticsResponce, getGeneralAnalyticsLoading } =
    useSelector((state) => state.analytics);

  const [date, setDate] = useState(new Date());

  const analyticsData = getGeneralAnalyticsResponce?.original || [];

  useEffect(() => {
    dispatch(getGeneralAnalytics(moment(date).format("YYYY-MM")));
  }, [date, dispatch]);

  return (
    <div id='ShipperCards'>
      <ShipperCardsCalendar date={date} setDate={setDate} />
      {getGeneralAnalyticsLoading ? (
        <Skeleton variant='rounded' className='skeleton' />
      ) : (
        <Fragment>
          <ShipperCardsContainer analyticsData={analyticsData} user={user} />
          <ShipperCardsSwitcher analyticsData={analyticsData} />
        </Fragment>
      )}
    </div>
  );
};

export default ShipperCards;
