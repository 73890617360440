import React from "react";
import "./ForWorkflowCarrier.scss";

import { BsArrowUpRight } from "react-icons/bs";
import { FiChevronRight } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

import HomeTitle from "../../Components/HomeTitle/HomeTitle";
import WorkflowItem from "./../Components/WorkflowItem/WorkflowItem";
import LinkTransparent from "./../../Components/Links/LinkTransparent/LinkTransparent";

const ForWorkflowCarrier = () => {
  const { t } = useTranslation();
  return (
    <section id='ForWorkflowCarrier' className='ForWorkflowCarrier'>
      <div className='wrapper'>
        <HomeTitle
          description={t(
            "landing.titles.landingMainTitlesDesc.Join our expansive network of independent truck owners to increase your earning potential"
          )}
        >
          {parse(
            t(
              "landing.titles.landingMainTitles.Grow your business as an <span>independent truck owner</span>"
            )
          )}
        </HomeTitle>
        <div className='container'>
          <div className='items'>
            <WorkflowItem
              title={t(
                "landing.titles.landingWorkFlowTitle.Order discovery and booking"
              )}
              content={t(
                "landing.bodyText.landingWorkFlowBody.Effortlessly discover and book orders from your preferred locations."
              )}
              icon={<BsArrowUpRight />}
              delay='0'
            />

            <WorkflowItem
              title={t(
                "landing.titles.landingWorkFlowTitle.Mobile accessibility"
              )}
              content={t(
                "landing.bodyText.landingWorkFlowBody.Access your carrier tools and information on the go with our user-friendly mobile app."
              )}
              icon={<BsArrowUpRight />}
              delay='100'
            />
            <WorkflowItem
              title={t(
                "landing.titles.landingWorkFlowTitle.Data analytics insights"
              )}
              content={t(
                "landing.bodyText.landingWorkFlowBody.Harness the power of data analytics to make informed decisions and optimize your carrier business."
              )}
              icon={<BsArrowUpRight />}
              delay='200'
            />
            <WorkflowItem
              title={t("landing.titles.landingWorkFlowTitle.Document exchange")}
              content={t(
                "landing.bodyText.landingWorkFlowBody.Upload and share document scans seamlessly using your phone camera."
              )}
              icon={<BsArrowUpRight />}
              delay='300'
            />
          </div>

          <div
            className='img'
            style={{
              backgroundImage: "url('/assets/images/static/cover43.png')",
            }}
          ></div>
        </div>

        <LinkTransparent
          title={t("landing.form.button.Start earning today")}
          to='/register'
          icon={<FiChevronRight />}
        />
      </div>
    </section>
  );
};

export default ForWorkflowCarrier;
