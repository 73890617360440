import React, { Fragment } from "react";
import "./ShipperOrdersTableItem.scss";

import NameField from "../../Form/TableFields/NameField/NameField";
import RowField from "../../Form/TableFields/RowField/RowField";
import ShippingTypeField from "../../Form/TableFields/ShippingTypeField/ShippingTypeField";
import RouteField from "../../Form/TableFields/RouteField/RouteField";
import ProductCategoryField from "../../Form/TableFields/ProductCategoryField/ProductCategoryField";
import DateField from "../../Form/TableFields/DateField/DateField";
import QuotationField from "../../Form/TableFields/QuotationField/QuotationField";
import TransportationField from "../../Form/TableFields/TransportationField/TransportationField";

const ShipperOrdersTableItem = ({ order }) => {
  return (
    <Fragment>
      <RowField
        style={{
          borderLeft: `4px solid ${
            order?.color ? order?.color : "transparent"
          }`,
        }}
      >
        <NameField
          label={order.tracking}
          to={`/dashboard/shipper/orders/${order?.id}`}
        />
        <ShippingTypeField type={order?.type} />
        <RouteField order={order} />
        <ProductCategoryField order={order} />
        <TransportationField order={order} />
        <DateField date={order?.cargos?.[0]?.pickup_date} />
        <QuotationField order={order} />
      </RowField>
    </Fragment>
  );
};

export default ShipperOrdersTableItem;
