import React from "react";

import { useTranslation } from "react-i18next";

import TableWrapper from "../../../Form/TableFields/TableWrapper/TableWrapper";
import ActiveShipmentsTableItem from "./ActiveShipmentsTableItem/ActiveShipmentsTableItem";
import TableHeader from "../../../Form/TableFields/TableHeader/TableHeader";

const ActiveShipmentsTable = ({ orders, loading }) => {
  const { t } = useTranslation();
  return (
    <TableWrapper emptyText={"no orders"}>
      <TableHeader>
        <th>{t("shipper.table.labels.Order number")}</th>
        <th style={{ textAlign: "center" }}>
          {t("shipper.table.labels.Status")}
        </th>
        <th>{t("shipper.table.labels.Route")}</th>
        <th>{t("shipper.table.labels.Pick up date")}</th>
        <th style={{ textAlign: "center" }}>
          {t("shipper.table.labels.Distance covered")}
        </th>
      </TableHeader>

      <tbody>
        {orders?.slice(0, 5)?.map((order) => (
          <ActiveShipmentsTableItem order={order} key={order.id} />
        ))}
      </tbody>
    </TableWrapper>
  );
};

export default ActiveShipmentsTable;
