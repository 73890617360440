import { useSelector } from "react-redux";

export const useMaintenancesErrors = () => {
  const { createMaintenanceError, updateMaintenanceError } = useSelector(
    (state) => state.maintenance
  );

  const create_truck_id_error =
    createMaintenanceError &&
    createMaintenanceError.errors &&
    createMaintenanceError.errors.truck_id &&
    createMaintenanceError.errors.truck_id[0];

  const create_trailer_id_error =
    createMaintenanceError &&
    createMaintenanceError.errors &&
    createMaintenanceError.errors.trailer_id &&
    createMaintenanceError.errors.trailer_id[0];

  const create_service_error =
    createMaintenanceError &&
    createMaintenanceError.errors &&
    createMaintenanceError.errors.service &&
    createMaintenanceError.errors.service[0];

  const create_provider_error =
    createMaintenanceError &&
    createMaintenanceError.errors &&
    createMaintenanceError.errors.provider &&
    createMaintenanceError.errors.provider[0];

  const create_currency_id_error =
    createMaintenanceError &&
    createMaintenanceError.errors &&
    createMaintenanceError.errors.currency_id &&
    createMaintenanceError.errors.currency_id[0];

  const create_cost_error =
    createMaintenanceError &&
    createMaintenanceError.errors &&
    createMaintenanceError.errors.cost &&
    createMaintenanceError.errors.cost[0];

  const create_downtime_start_error =
    createMaintenanceError &&
    createMaintenanceError.errors &&
    createMaintenanceError.errors.downtime_start &&
    createMaintenanceError.errors.downtime_start[0];

  const create_downtime_end_error =
    createMaintenanceError &&
    createMaintenanceError.errors &&
    createMaintenanceError.errors.downtime_end &&
    createMaintenanceError.errors.downtime_end[0];

  const update_truck_id_error =
    updateMaintenanceError &&
    updateMaintenanceError.errors &&
    updateMaintenanceError.errors.truck_id &&
    updateMaintenanceError.errors.truck_id[0];

  const update_trailer_id_error =
    updateMaintenanceError &&
    updateMaintenanceError.errors &&
    updateMaintenanceError.errors.trailer_id &&
    updateMaintenanceError.errors.trailer_id[0];

  const update_service_error =
    updateMaintenanceError &&
    updateMaintenanceError.errors &&
    updateMaintenanceError.errors.service &&
    updateMaintenanceError.errors.service[0];

  const update_provider_error =
    updateMaintenanceError &&
    updateMaintenanceError.errors &&
    updateMaintenanceError.errors.provider &&
    updateMaintenanceError.errors.provider[0];

  const update_currency_id_error =
    updateMaintenanceError &&
    updateMaintenanceError.errors &&
    updateMaintenanceError.errors.currency_id &&
    updateMaintenanceError.errors.currency_id[0];

  const update_cost_error =
    updateMaintenanceError &&
    updateMaintenanceError.errors &&
    updateMaintenanceError.errors.cost &&
    updateMaintenanceError.errors.cost[0];

  const update_downtime_start_error =
    updateMaintenanceError &&
    updateMaintenanceError.errors &&
    updateMaintenanceError.errors.downtime_start &&
    updateMaintenanceError.errors.downtime_start[0];

  const update_downtime_end_error =
    updateMaintenanceError &&
    updateMaintenanceError.errors &&
    updateMaintenanceError.errors.downtime_end &&
    updateMaintenanceError.errors.downtime_end[0];

  return {
    create_truck_id_error,
    create_trailer_id_error,
    create_service_error,
    create_provider_error,
    create_currency_id_error,
    create_cost_error,
    create_downtime_start_error,
    create_downtime_end_error,
    update_truck_id_error,
    update_trailer_id_error,
    update_service_error,
    update_provider_error,
    update_currency_id_error,
    update_cost_error,
    update_downtime_start_error,
    update_downtime_end_error,
  };
};

export default useMaintenancesErrors;
