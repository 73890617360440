import React, { Fragment, useState, useEffect } from "react";
import "./FillCarrierModal.scss";

import { useTranslation } from "react-i18next";

import { MdInfoOutline } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";

import DModal from "../../../../Modal/DModal/DModal";
import DInputs from "../../../../Form/DInputs/DInputs";

import useManageOrderErrors from "../../../../../validations/order/useManageOrderErrors";
import {
  assignCarrier,
  resetAssignCarrier,
} from "./../../../../../store/orders/manageOrder/manageOrderSlice";

const FillCarrierModal = ({ isOpen, isClose, order }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { assignCarrierResponce, assignCarrierLoading } = useSelector(
    (state) => state.manageOrder
  );
  const { truck_registration_number_error, trailer_registration_number_error } =
    useManageOrderErrors();

  const [truckRegistrationNumber, setTruckRegistrationNumber] = useState(
    order?.carrier?.truck_registration_number || ""
  );
  const [trailerRegistrationNumber, setTrailerRegistrationNumber] = useState(
    order?.carrier?.trailer_registration_number || ""
  );

  const submitHandler = () => {
    const data = {
      orderId: order.id,
      partner_id: order?.carrier?.partner?.id,
      truck_registration_number: truckRegistrationNumber,
      trailer_registration_number: trailerRegistrationNumber,
    };
    dispatch(assignCarrier(data));
  };

  useEffect(() => {
    if (assignCarrierResponce) {
      dispatch(resetAssignCarrier());
      isClose();
    }
  }, [dispatch, isClose, assignCarrierResponce]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose(false)}
        submitText={t("tms.modals.buttons.Save")}
        title={t("tms.modals.titles.Truck number / Trailer number")}
        icon={<MdInfoOutline />}
        onClick={() => submitHandler()}
        loading={assignCarrierLoading}
      >
        <div id='FillCarrierModal'>
          <div className='FillCarrierModalGroup'>
            <DInputs
              id='truckRegistrationNumber'
              label={t("tms.modals.labels.Truck registration number")}
              placeholder={t("tms.modals.placeholder.EX: tx-456-tx")}
              value={truckRegistrationNumber}
              setValue={setTruckRegistrationNumber}
              error={truck_registration_number_error}
            />

            <DInputs
              id='trailerRegistrationNumber'
              value={trailerRegistrationNumber}
              label={t("tms.modals.labels.Trailer registration number")}
              placeholder={t("tms.modals.placeholder.EX: tx-456-tx")}
              setValue={setTrailerRegistrationNumber}
              error={trailer_registration_number_error}
            />
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default FillCarrierModal;
