import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import contactService from "./contactService";

const initialState = {
  // get contact by country
  contact: null,
  contactLoading: false,
  contactError: null,

  // get contacts
  contacts: null,
  contactsLoading: false,
  contactsError: null,

  // active contact
  currentOffice: null,
};

// get contact by country
export const getContact = createAsyncThunk(
  "contact/getContact",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const BranchID = thunkAPI.getState().settings.countryId;

      return await contactService.getContact(ISO, BranchID);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get contacts
export const getContacts = createAsyncThunk(
  "contact/getContacts",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const countryId = thunkAPI.getState().settings.countryId;
      const result = await contactService.getContacts(ISO);
      return { result, countryId };
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// set active contact
export const setContact = createAsyncThunk(
  "contact/setActiveContact",
  async (data, thunkAPI) => {
    const countryId = thunkAPI.getState().settings.countryId;
    return { data, countryId };
  }
);

export const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get contact by country
      .addCase(getContact.pending, (state) => {
        state.contactLoading = true;
        state.contact = null;
        state.contactError = null;
      })
      .addCase(getContact.fulfilled, (state, action) => {
        state.contactLoading = false;
        state.contact = action.payload;
        state.contactError = null;
      })
      .addCase(getContact.rejected, (state, action) => {
        state.contactLoading = false;
        state.contact = null;
        state.contactError = action.payload;
      })

      // get contacts
      .addCase(getContacts.pending, (state) => {
        state.contactsLoading = true;
        state.contacts = null;
        state.contactError = null;
      })
      .addCase(getContacts.fulfilled, (state, action) => {
        state.contactsLoading = false;
        state.contacts = action.payload.result.map((i) => ({
          value: i,
          label: i.branch.name,
        }));
        state.currentOffice = action.payload.result
          .map((i) => ({
            value: i,
            label: i.branch.name,
          }))
          .find((i) => i.value.branch.id === action.payload.countryId);
        state.contactsError = null;
      })
      .addCase(getContacts.rejected, (state, action) => {
        state.contactsLoading = false;
        state.contacts = null;
        state.contactsError = action.payload;
      })

      // set active contact
      .addCase(setContact.fulfilled, (state, action) => {
        state.currentOffice = action.payload.data;
      });
  },
});

export default contactSlice.reducer;
