import React from "react";
import "./OrderProfit.scss";

import { useTranslation } from "react-i18next";

import { useMoney } from "./../../../../../helper/useMoney";

const OrderProfit = ({ order }) => {
  const { t } = useTranslation();

  const formatedOrderProfit = useMoney(
    order?.profit || 0,
    order?.revenue?.currency?.symbol || ""
  );

  return (
    <div id='OrderProfit'>
      <h4>{t("tms.titles.Order profit")}</h4>
      <h5>{formatedOrderProfit}</h5>
    </div>
  );
};

export default OrderProfit;
