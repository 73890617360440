import React, { Fragment, useState, useEffect } from "react";
import "./CreateParticipantContactModal.scss";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { FiPlus } from "react-icons/fi";

import {
  createParticipantContact,
  resetCreateParticipantContact,
} from "../../../../store/participants/participantContacts/participantContactsSlice";
import { useParticipantContactErrors } from "./../../../../validations/participant/useParticipantContactErrors";

import DModal from "../../../Modal/DModal/DModal";
import DInputs from "./../../../Form/DInputs/DInputs";

const CreateParticipantContactModal = ({ participantId, isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { createParticipantContactLoading, createParticipantContactResponce } =
    useSelector((state) => state.participantContacts);

  const {
    create_name_error,
    create_email_error,
    create_phone_error,
    create_position_error,
  } = useParticipantContactErrors();

  const [fullname, setFullname] = useState("");
  const [position, setPosition] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const resetState = () => {
    setFullname("");
    setPosition("");
    setEmail("");
    setPhone("");
  };

  const submitHandler = () => {
    const data = {
      participantId: participantId,
      name: fullname,
      email: email,
      phone: phone,
      position: position,
    };
    dispatch(createParticipantContact(data));
  };

  useEffect(() => {
    if (createParticipantContactResponce) {
      dispatch(resetCreateParticipantContact());
      resetState();
      isClose();
    }
  }, [dispatch, isClose, createParticipantContactResponce]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose()}
        onClick={() => submitHandler()}
        submitText={t("tms.modals.buttons.Save")}
        title={t("tms.modals.titles.Add contact")}
        status='success'
        icon={<FiPlus />}
        loading={createParticipantContactLoading}
      >
        <div id='CreateParticipantContactModal'>
          <div className='group'>
            <DInputs
              value={fullname}
              setValue={setFullname}
              placeholder={t("tms.modals.placeholder.Enter full name")}
              label={t("tms.modals.labels.Full name")}
              required
              id='fullname'
              error={create_name_error}
            />
            <DInputs
              value={position}
              setValue={setPosition}
              placeholder={t("tms.modals.placeholder.Enter position")}
              label={t("tms.modals.labels.Position")}
              id='position'
              error={create_position_error}
            />
            <DInputs
              value={email}
              setValue={setEmail}
              placeholder={t("tms.modals.placeholder.Enter email")}
              label={t("tms.modals.labels.Email")}
              id='Email'
              error={create_email_error}
            />
            <DInputs
              value={phone}
              setValue={setPhone}
              placeholder={t("tms.modals.placeholder.Enter phone number")}
              label={t("tms.modals.labels.Phone number")}
              id='Phone '
              error={create_phone_error}
            />
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default CreateParticipantContactModal;
