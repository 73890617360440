import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import GPSTrakerService from "./GPSTrakerService";

const initialState = {
  // trucks list
  getTrucksLoading: true,
  getTrucksResponce: null,
  getTrucksError: null,

  // providers lists
  getProvidersLoading: true,
  getProvidersResponce: null,
  getProvidersError: null,

  // connect to provider
  connectProviderLoading: false,
  connectProviderResponce: null,
  connectProviderError: null,

  // disconnect to provider
  disconnectProviderLoading: false,
  disconnectProviderResponce: null,
  disconnectProviderError: null,

  // get my providers
  getConnectionsLoading: true,
  getConnectionsResponce: null,
  getConnectionsError: null,

  // get my all devices
  getDevicesLoading: true,
  getDevicesResponce: null,
  getDevicesError: null,

  // connect truck to device
  connectTruckToDeviceLoading: false,
  connectTruckToDeviceResponce: null,
  connectTruckToDeviceError: null,

  // disconnect truck to device
  disconnectTruckToDeviceLoading: false,
  disconnectTruckToDeviceResponce: null,
  disconnectTruckToDeviceError: null,
};

export const getTrucks = createAsyncThunk(
  "GPSTraker/getGPSTrucks",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await GPSTrakerService.getTrucks(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getProviders = createAsyncThunk(
  "GPSTraker/getProviders",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await GPSTrakerService.getProviders(ISO, TOKEN);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const connectProvider = createAsyncThunk(
  "GPSTraker/connectProvider",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await GPSTrakerService.connectProvider(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const disconnectProvider = createAsyncThunk(
  "GPSTraker/disconnectProvider",
  async (providerId, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await GPSTrakerService.disconnectProvider(ISO, TOKEN, providerId);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getConnections = createAsyncThunk(
  "GPSTraker/getConnections",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await GPSTrakerService.getConnections(ISO, TOKEN);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDevices = createAsyncThunk(
  "GPSTraker/getDevices",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await GPSTrakerService.getDevices(ISO, TOKEN);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const connectTruckToDevice = createAsyncThunk(
  "GPSTraker/connectTruckToDevice",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await GPSTrakerService.connectTruckToDevice(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const disconnectTruckToDevice = createAsyncThunk(
  "GPSTraker/disconnectTruckToDevice",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await GPSTrakerService.disconnectTruckToDevice(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const GPSTrakerSlice = createSlice({
  name: "GPSTraker",
  initialState,
  reducers: {
    resetGetTrucks: (state) => {
      state.getTrucksLoading = true;
      state.getTrucksResponce = null;
      state.getTrucksError = null;
    },
    resetGetProviders: (state) => {
      state.getProvidersLoading = true;
      state.getProvidersResponce = null;
      state.getProvidersError = null;
    },
    resetConnectProvider: (state) => {
      state.connectProviderLoading = false;
      state.connectProviderResponce = null;
      state.connectProviderError = null;
    },
    resetDisconnectProvider: (state) => {
      state.disconnectProviderLoading = false;
      state.disconnectProviderResponce = null;
      state.disconnectProviderError = null;
    },
    resetGetConnections: (state) => {
      state.getConnectionsLoading = true;
      state.getConnectionsResponce = null;
      state.getConnectionsError = null;
    },
    resetGetDevices: (state) => {
      state.getDevicesLoading = true;
      state.getDevicesResponce = null;
      state.getDevicesError = null;
    },
    resetConnectTruckToDevice: (state) => {
      state.connectTruckToDeviceLoading = false;
      state.connectTruckToDeviceResponce = null;
      state.connectTruckToDeviceError = null;
    },
    resetDisconnectTruckToDevice: (state) => {
      state.disconnectTruckToDeviceLoading = false;
      state.disconnectTruckToDeviceResponce = null;
      state.disconnectTruckToDeviceError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTrucks.pending, (state) => {
        state.getTrucksLoading = true;
        state.getTrucksResponce = null;
        state.getTrucksError = null;
      })
      .addCase(getTrucks.fulfilled, (state, action) => {
        state.getTrucksLoading = false;
        state.getTrucksResponce = action.payload;
        state.getTrucksError = null;
      })
      .addCase(getTrucks.rejected, (state, action) => {
        state.getTrucksLoading = false;
        state.getTrucksResponce = null;
        state.getTrucksError = action.payload;
      })

      .addCase(getProviders.pending, (state) => {
        state.getProvidersLoading = true;
        state.getProvidersResponce = null;
        state.getProvidersError = null;
      })
      .addCase(getProviders.fulfilled, (state, action) => {
        state.getProvidersLoading = false;
        state.getProvidersResponce = action.payload.map((provider) => ({
          label: provider.name,
          value: provider,
        }));
        state.getProvidersError = null;
      })
      .addCase(getProviders.rejected, (state, action) => {
        state.getProvidersLoading = false;
        state.getProvidersResponce = null;
        state.getProvidersError = action.payload;
      })

      .addCase(connectProvider.pending, (state) => {
        state.connectProviderLoading = true;
        state.connectProviderResponce = null;
        state.connectProviderError = null;
      })
      .addCase(connectProvider.fulfilled, (state, action) => {
        state.connectProviderLoading = false;
        state.connectProviderResponce = action.payload;
        state.connectProviderError = null;
      })
      .addCase(connectProvider.rejected, (state, action) => {
        state.connectProviderLoading = false;
        state.connectProviderResponce = null;
        state.connectProviderError = action.payload;
      })

      .addCase(disconnectProvider.pending, (state) => {
        state.disconnectProviderLoading = true;
        state.disconnectProviderResponce = null;
        state.disconnectProviderError = null;
      })
      .addCase(disconnectProvider.fulfilled, (state, action) => {
        state.disconnectProviderLoading = false;
        state.disconnectProviderResponce = action.payload;
        state.disconnectProviderError = null;
      })
      .addCase(disconnectProvider.rejected, (state, action) => {
        state.disconnectProviderLoading = false;
        state.disconnectProviderResponce = null;
        state.disconnectProviderError = action.payload;
      })

      .addCase(getConnections.pending, (state) => {
        state.getConnectionsLoading = true;
        state.getConnectionsResponce = null;
        state.getConnectionsError = null;
      })
      .addCase(getConnections.fulfilled, (state, action) => {
        state.getConnectionsLoading = false;
        state.getConnectionsResponce = action.payload.map((conection) => ({
          label: conection.provider.name,
          value: conection,
        }));
        state.getConnectionsError = null;
      })
      .addCase(getConnections.rejected, (state, action) => {
        state.getConnectionsLoading = false;
        state.getConnectionsResponce = null;
        state.getConnectionsError = action.payload;
      })

      .addCase(getDevices.pending, (state) => {
        state.getDevicesLoading = true;
        state.getDevicesResponce = null;
        state.getDevicesError = null;
      })
      .addCase(getDevices.fulfilled, (state, action) => {
        state.getDevicesLoading = false;
        state.getDevicesResponce = action.payload.map((device) => ({
          label: device.name,
          value: device,
        }));
        state.getDevicesError = null;
      })
      .addCase(getDevices.rejected, (state, action) => {
        state.getDevicesLoading = false;
        state.getDevicesResponce = null;
        state.getDevicesError = action.payload;
      })

      .addCase(connectTruckToDevice.pending, (state) => {
        state.connectTruckToDeviceLoading = true;
        state.connectTruckToDeviceResponce = null;
        state.connectTruckToDeviceError = null;
      })
      .addCase(connectTruckToDevice.fulfilled, (state, action) => {
        state.connectTruckToDeviceLoading = false;
        state.connectTruckToDeviceResponce = action.payload;
        state.connectTruckToDeviceError = null;
      })
      .addCase(connectTruckToDevice.rejected, (state, action) => {
        state.connectTruckToDeviceLoading = false;
        state.connectTruckToDeviceResponce = null;
        state.connectTruckToDeviceError = action.payload;
      })

      .addCase(disconnectTruckToDevice.pending, (state) => {
        state.disconnectTruckToDeviceLoading = true;
        state.disconnectTruckToDeviceResponce = null;
        state.disconnectTruckToDeviceError = null;
      })
      .addCase(disconnectTruckToDevice.fulfilled, (state, action) => {
        state.disconnectTruckToDeviceLoading = false;
        state.disconnectTruckToDeviceResponce = action.payload;
        state.disconnectTruckToDeviceError = null;
      })
      .addCase(disconnectTruckToDevice.rejected, (state, action) => {
        state.disconnectTruckToDeviceLoading = false;
        state.disconnectTruckToDeviceResponce = null;
        state.disconnectTruckToDeviceError = action.payload;
      });
  },
});

export const {
  resetGetTrucks,
  resetGetProviders,
  resetConnectProvider,
  resetDisconnectProvider,
  resetGetConnections,
  resetGetDevices,
  resetConnectTruckToDevice,
  resetDisconnectTruckToDevice,
} = GPSTrakerSlice.actions;
export default GPSTrakerSlice.reducer;
