import { configureStore } from "@reduxjs/toolkit";

import {
  shipperOrders,
  shipperAccepted,
  shipperFinancial,
  GPSTraker,
  APPTracker,
  user,
  summary,
  loadBoards,
  auth,
  lang,
  settings,
  userUI,
  contact,
  rules,
  partners,
  lead,
  partner,
  customer,
  participantContacts,
  orders,
  orderSettings,
  orderCosts,
  advance,
  orderDocuments,
  manageOrder,
  cargos,
  trucks,
  trailers,
  drivers,
  maintenance,
  bidding,
  notifications,
  dashboard,
  financial,
  favoriteLocations,
  trackerManager,
  analytics,
  bankAccounts,
  userContacts,
  upload,
  routes,
  subscribe,
} from "./index";

export const store = configureStore({
  reducer: {
    shipperOrders,
    shipperAccepted,
    shipperFinancial,
    GPSTraker,
    APPTracker,
    trackerManager,
    user,
    summary,
    financial,
    loadBoards,
    orderDocuments,
    trucks,
    trailers,
    drivers,
    orders,
    orderCosts,
    advance,
    manageOrder,
    orderSettings,
    cargos,
    partner,
    customer,
    participantContacts,
    auth,
    lang,
    settings,
    userUI,
    contact,
    rules,
    partners,
    lead,
    maintenance,
    bidding,
    notifications,
    dashboard,
    favoriteLocations,
    analytics,
    bankAccounts,
    userContacts,
    upload,
    routes,
    subscribe,
  },
});
