import React, { useState, useEffect } from "react";
import "./CreateOceanOrder.scss";

import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { getTrailerTypes } from "../../../../../../store/orders/orderSettings/orderSettingsSlice";
import {
  createOcean,
  resetCreateOcean,
} from "./../../../../../../store/orders/shipperOrders/shipperOrdersSlice";
import { useCreateShipperOceanError } from "./../../../../../../validations/order/useCreateShipperOceanError";

import Wrapper from "./../../../../../../componentsAdditional/Wrapper";
import OceanTopMenu from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperOcean/OceanTopMenu/OceanTopMenu";
import CreateOrderHeader from "../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderHeader/CreateOrderHeader";
import OceanLocation from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperOcean/OceanLocation/OceanLocation";
import CreateOrderAditionalServices from "./../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderAditionalServices/CreateOrderAditionalServices";
import CreateOrderAditionalInformation from "./../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderAditionalInformation/CreateOrderAditionalInformation";
import CreateOrderSubmit from "../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderSubmit/CreateOrderSubmit";
import OceanTypeSwitcher from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperOcean/OceanTypeSwitcher/OceanTypeSwitcher";
import OceanPackage from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperOcean/OceanPackage/OceanPackage";
import NetworkErrorModal from "../../../../../../componentsDashboard/UI/NetworkErrorModal/NetworkErrorModal";

const CreateOceanOrder = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { createOceanLoading, createOceanResponce, createOceanError } =
    useSelector((state) => state.shipperOrders);

  const {
    create_order_Network_error,
    cargos_comment_error,
    cargos_ready_date_error,
    cargos_price_error,
    cargos_price_currency_id_error,
    order_documents_error,
  } = useCreateShipperOceanError();

  const ORDERTYPES = [
    { id: 3, name: "FCL" },
    { id: 4, name: "LCL" },
  ];
  const [showNetworkError, setShowNetworkError] = useState(false);

  const [group, setGroup] = useState([
    {
      cargoType: null,
      HSCode: false,
      HSCodeValue: "",
      adr: false,
      adrNumber: "",
      adrClass: "",
      adrFile: null,
      oversized: false,
      oversizedFile: null,
      oversizedCall: false,
      unknownContainer: false,
      trailerType: null,
      trailerSize: null,
      temperatureRange: null,
      weight: "",
      quantity: 1,
      /// errors
      cargoTypeError: null,
      HSCodeValueError: null,
      adrError: null,
      adrNumberError: null,
      adrClassError: null,
      adrFileError: null,
      oversizedError: null,
      oversizedFileError: null,
      oversizedCallError: null,
      unknownContainerError: null,
      trailerTypeError: null,
      trailerSizeError: null,
      temperatureRangeError: null,
      weightError: null,
    },
  ]);

  const [order_type_id, set_order_type_id] = useState(3);
  const [locations, setLocations] = useState([
    {
      direction: "A",
      inputted_address: "",
      type: "PORT",
      error: null,
    },
    {
      direction: "B",
      inputted_address: "",
      type: "PORT",
      error: null,
    },
  ]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [readyCargoTime, setReadyCargoTime] = useState(null);
  const [cargoPrice, setCargoPrice] = useState("");
  const [cargoPriceCurrency, setCargoPriceCurrency] = useState(
    user
      ? {
          value: user.currency,
          label: user.currency.code,
        }
      : null
  );
  const [comment, setComment] = useState("");
  const [aditionalDocument, setAditionalDocument] = useState(null);

  // catch locations error
  useEffect(() => {
    if (createOceanError?.errors) {
      const updatedLocations = locations.map((location, index) => ({
        ...location,
        error:
          createOceanError.errors[
            `cargos.0.locations.${index}.inputted_address`
          ]?.[0] ?? null,
      }));
      setLocations(updatedLocations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOceanError]);

  // catch cargo errors
  useEffect(() => {
    if (createOceanError?.errors) {
      const updatedGroup = group.map((item, index) => ({
        ...item,
        cargoTypeError:
          createOceanError?.errors?.[`cargos.${index}.type_id`]?.[0] || null,
        HSCodeValueError:
          createOceanError?.errors?.[`cargos.${index}.hs_code`]?.[0] || null,
        adrError:
          createOceanError?.errors?.[`cargos.${index}.adr`]?.[0] || null,
        adrNumberError:
          createOceanError?.errors?.[`cargos.${index}.un_number`]?.[0] || null,
        adrClassError:
          createOceanError?.errors?.[`cargos.${index}.adr_class`]?.[0] || null,
        adrFileError:
          createOceanError?.errors?.[`cargos.${index}.msds_file`]?.[0] || null,
        oversizedError:
          createOceanError?.errors?.[`cargos.${index}.oversized`]?.[0] || null,
        oversizedFileError:
          createOceanError?.errors?.[`cargos.${index}.oversized_file`]?.[0] ||
          null,
        oversizedCallError:
          createOceanError?.errors?.[`cargos.${index}.oversized_call`]?.[0] ||
          null,
        // unknownContainerError:
        // createOceanError?.errors?.[`cargos.${index}.unknown_container`]?.[0] || null,

        ///////////
        trailerTypeError:
          createOceanError?.errors?.[`cargos.${index}.trailer_type_id`]?.[0] ||
          null,
        trailerSizeError:
          createOceanError?.errors?.[`cargos.${index}.trailer_size_id`]?.[0] ||
          null,
        temperatureRangeError:
          createOceanError?.errors?.[
            `cargos.${index}.temperature_range_id`
          ]?.[0] || null,
        weightError:
          createOceanError?.errors?.[`cargos.${index}.weight`]?.[0] || null,
      }));
      setGroup(updatedGroup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOceanError]);

  // catch network error
  useEffect(() => {
    if (create_order_Network_error) {
      setShowNetworkError(true);
    }
    return () => {
      dispatch(resetCreateOcean());
      setShowNetworkError(false);
    };
  }, [dispatch, create_order_Network_error]);

  // get trailer types
  useEffect(() => {
    dispatch(getTrailerTypes(3));
  }, [dispatch]);

  // reset redux states
  useEffect(() => {
    if (createOceanResponce) {
      dispatch(resetCreateOcean());
      navigate("/dashboard/shipper/orders?page=1");
    }
  }, [dispatch, navigate, createOceanResponce]);

  // submit
  const submitHandler = () => {
    const filsterSelectedServices =
      selectedServices.length > 0 ? selectedServices.map((i) => i.id) : null;

    const formData = new FormData();
    formData.append("transfer_type_id", 1);
    formData.append("type_id", order_type_id || "");
    if (Array.isArray(filsterSelectedServices)) {
      filsterSelectedServices.forEach((service) =>
        formData.append("services[]", service)
      );
    }
    if (aditionalDocument !== null && aditionalDocument !== undefined) {
      formData.append("documents[]", aditionalDocument);
    } else {
      formData.append("documents", "");
    }
    const cargos = group.flatMap((item) =>
      Array.from({ length: item.quantity }, () => ({
        ...item,
      }))
    );

    cargos.forEach((cargo, index) => {
      formData.append(
        `cargos[${index}][trailer_type_id]`,
        cargo?.trailerType?.value?.id || ""
      );
      formData.append(
        `cargos[${index}][trailer_size_id]`,
        cargo?.trailerSize?.value?.value?.id || ""
      );
      formData.append(
        `cargos[${index}][temperature_range_id]`,
        cargo?.temperatureRange?.value?.id || ""
      );
      formData.append(
        `cargos[${index}][type_id]`,
        cargo?.cargoType?.value?.id || ""
      );
      formData.append(`cargos[${index}][hs_code]`, cargo?.HSCodeValue || "");
      formData.append(`cargos[${index}][price]`, cargoPrice || "");
      formData.append(
        `cargos[${index}][price_currency_id]`,
        cargoPriceCurrency?.value?.id || ""
      );
      formData.append(`cargos[${index}][adr]`, cargo.adr ? 1 : 0);
      formData.append(`cargos[${index}][about]`, comment || "");
      formData.append(`cargos[${index}][un_number]`, cargo?.adrNumber || "");
      formData.append(`cargos[${index}][adr_class]`, cargo?.adrClass || "");
      formData.append(`cargos[${index}][msds_file]`, cargo?.adrFile || "");
      formData.append(`cargos[${index}][oversized]`, cargo?.oversized ? 1 : 0);
      formData.append(
        `cargos[${index}][oversized_file]`,
        cargo?.oversizedFile || ""
      );
      formData.append(
        `cargos[${index}][oversized_call]`,
        cargo?.oversizedCall ? 1 : 0
      );
      // dont know which container
      formData.append(`cargos[${index}][weight]`, cargo?.weight || "");
      formData.append(
        `cargos[${index}][cargo_ready_date]`,
        readyCargoTime ? moment(readyCargoTime).format("YYYY-MM-DD") : ""
      );
      // Add cargo locations
      locations.forEach((location, locIndex) => {
        formData.append(
          `cargos[${index}][locations][${locIndex}][direction]`,
          location.direction
        );
        formData.append(
          `cargos[${index}][locations][${locIndex}][type]`,
          location.type
        );
        formData.append(
          `cargos[${index}][locations][${locIndex}][inputted_address]`,
          location.inputted_address
        );
      });
    });
    dispatch(createOcean(formData));
  };

  return (
    <Wrapper documentTitle={t("shipper.titles.Ocean freight")}>
      <OceanTopMenu
        ORDERTYPES={ORDERTYPES}
        order_type_id={order_type_id}
        group={group}
        time={readyCargoTime}
        locations={locations}
      />
      <section id='ShipperCreateOrderOcean'>
        <div className='ShipperCreateOrderOceanWrapper'>
          <CreateOrderHeader />
          <OceanTypeSwitcher
            ORDERTYPES={ORDERTYPES}
            order_type_id={order_type_id}
            set_order_type_id={set_order_type_id}
          />
          <OceanLocation locations={locations} setLocations={setLocations} />
          <OceanPackage group={group} setGroup={setGroup} />

          <CreateOrderAditionalServices
            title={t("shipper.forms.titles.Additional Services")}
            selectedServices={selectedServices}
            setSelectedServices={setSelectedServices}
          />
          <CreateOrderAditionalInformation
            title={t("shipper.forms.titles.Additional information")}
            readyCargoTime={readyCargoTime}
            setReadyCargoTime={setReadyCargoTime}
            cargoPrice={cargoPrice}
            setCargoPrice={setCargoPrice}
            cargoPriceCurrency={cargoPriceCurrency}
            setCargoPriceCurrency={setCargoPriceCurrency}
            comment={comment}
            setComment={setComment}
            aditionalDocument={aditionalDocument}
            setAditionalDocument={setAditionalDocument}
            // errors
            cargos_comment_error={cargos_comment_error}
            cargos_ready_date_error={cargos_ready_date_error}
            cargos_price_error={cargos_price_error}
            cargos_price_currency_id_error={cargos_price_currency_id_error}
            order_documents_error={order_documents_error}
          />
          <CreateOrderSubmit
            title={t("shipper.buttons.Get a quote")}
            loading={createOceanLoading}
            onClick={() => submitHandler()}
          />
        </div>
      </section>
      <NetworkErrorModal
        isOpen={showNetworkError}
        isClose={() => setShowNetworkError(false)}
      />
    </Wrapper>
  );
};

export default CreateOceanOrder;
