import { useSelector } from "react-redux";

export const useChangeEmailErrors = () => {
  const { changeEmailError, verifyEmailError } = useSelector(
    (state) => state.user
  );

  const update_email_error =
    changeEmailError &&
    changeEmailError.errors &&
    changeEmailError.errors.email &&
    changeEmailError.errors.email[0];

  const update_email_verify_error =
    verifyEmailError &&
    verifyEmailError.errors &&
    verifyEmailError.errors.code &&
    verifyEmailError.errors.code[0];

  return {
    update_email_error,
    update_email_verify_error,
  };
};
