import React from "react";

// import { MdEdit } from "react-icons/md";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setActiveOrderId,
  setActiveOrderIndex,
  setShipperAcceptedOrderView,
} from "../../../../../store/orders/shipperAccepted/shipperAcceptedSlice";

import RowField from "../../../../Form/TableFields/RowField/RowField";
// import ChangeActivityField from "../../../../Form/TableFields/ChangeActivityField/ChangeActivityField";
import ProgressField from "../../../../Form/TableFields/ProgressField/ProgressField";
import NameField from "../../../../Form/TableFields/NameField/NameField";
import OrderStatusField from "../../../../Form/TableFields/OrderStatusField/OrderStatusField";
import RouteField from "../../../../Form/TableFields/RouteField/RouteField";
import DateField from "../../../../Form/TableFields/DateField/DateField";

const ActiveShipmentsTableItem = ({ order }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clickFieldHandler = () => {
    dispatch(setShipperAcceptedOrderView(false));
    dispatch(setActiveOrderId(order.id));
    dispatch(setActiveOrderIndex(0));
    navigate("/dashboard/shipper/accepted");
  };
  return (
    <RowField style={{ height: "60.8px" }}>
      <NameField label={order?.tracking} onClick={() => clickFieldHandler()} />
      <OrderStatusField status={order?.status ?? null} />
      <RouteField order={order} />
      <DateField date={order?.cargos[0]?.pickup_date ?? null} />
      <ProgressField progress={order?.distanceCovered?.distance || 0} />
    </RowField>
  );
};

export default ActiveShipmentsTableItem;
