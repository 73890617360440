import React, { Fragment, useEffect } from "react";
import "../styles/dashboard/dashboard.scss";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";

import ScrollToTop from "../componentsAdditional/ScrollToTop";
import AppCompany from "./AppCompany";
import AppShipper from "./AppShipper";

import {
  getOrderPaymentType,
  getOrderTypes,
  getOrderTransferTypes,
  getCargoTypes,
  getTemperatureRanges,
  getOrderServicesList,
  getCargoPackageList,
} from "../store/orders/orderSettings/orderSettingsSlice";

import { getTrailerTypes } from "../store/fleet/trailers/trailersSlice";
import { getCargoStatuses } from "../store/orders/cargo/cargosSlice";
import { getOrderCostTypes } from "../store/orders/costs/orderCostsSlice";
import { getRoutesList } from "../store/routes/routesSlice";

import * as amplitude from "@amplitude/analytics-browser";

import AppCarrier from "./AppCarrier";

amplitude.init("6ba462e29a4264985fba7cb9f6d4930c", { defaultTracking: true });

const AppDashboard = () => {
  const dispatch = useDispatch();
  const { openSidebar } = useSelector((state) => state.userUI);
  const { userType } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getOrderPaymentType());
    dispatch(getOrderTypes());
    dispatch(getOrderTransferTypes());
    dispatch(getCargoTypes());
    dispatch(getTrailerTypes());
    dispatch(getTemperatureRanges());
    dispatch(getCargoStatuses());
    dispatch(getOrderCostTypes());
    dispatch(getRoutesList());
    dispatch(getOrderServicesList());
    dispatch(getCargoPackageList());
  }, [dispatch]);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#00b56c",
        light: "#33c489",
        dark: "#00814d",
        contrastText: "#ffffff",
      },
    },
  });

  return (
    <Fragment>
      {userType && (
        <ThemeProvider theme={theme}>
          <ScrollToTop />
          {userType === 2 && (
            <div id='C-Layout'>
              <AppCarrier />
            </div>
          )}
          {userType === 4 && (
            <div id='C-Layout' className={openSidebar ? "max" : "min"}>
              <AppCompany />
            </div>
          )}
          {userType === 5 && (
            <div id='S-Layout'>
              <AppShipper />
            </div>
          )}
        </ThemeProvider>
      )}
    </Fragment>
  );
};

export default AppDashboard;
