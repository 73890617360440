import React from "react";
import "./ContactCarrier.scss";

import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

import ContactForm from "./../Components/ContactForm/ContactForm";
import HomeTitle from "../../Components/HomeTitle/HomeTitle";

const ContactCarrier = () => {
  const { t } = useTranslation();

  return (
    <section id='ContactCarrier' className='ContactCarrier'>
      <div className='wrapper'>
        <HomeTitle
          description={t(
            "landing.titles.landingMainTitlesDesc.Fill out the form to discuss how we can assist you"
          )}
        >
          {parse(
            t(
              "landing.titles.landingMainTitles.Stay <span>in touch</span> with our sales team"
            )
          )}
        </HomeTitle>
        <div className='container'>
          <ContactForm role='CARRIER' />
        </div>
      </div>
    </section>
  );
};

export default ContactCarrier;
