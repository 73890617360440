import React, { useEffect } from "react";
import "./InsurancePolicy.scss";

import { useSelector, useDispatch } from "react-redux";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

import { getInsurance } from "./../../../../../store/additional/rules/rulesSlice";

import Wrapper from "./../../../../../componentsAdditional/Wrapper";

const InsurancePolicy = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { insurance } = useSelector((state) => state.rules);

  useEffect(() => {
    dispatch(getInsurance());
  }, [dispatch]);

  return (
    <Wrapper documentTitle={t("landing.documentTitles.Insurance policy")}>
      <section id='InsurancePolicy'>
        <div className='wrapper'>
          {insurance && (
            <div className='content'>
              <div className='title'>
                <h1>{insurance.title && insurance.title}</h1>
              </div>
              <div className='body'>
                {parse(insurance.body && insurance.body)}
              </div>
            </div>
          )}
        </div>
      </section>
    </Wrapper>
  );
};

export default InsurancePolicy;
