import React, { Fragment, useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import {
  getOrders,
  resetGetOrders,
} from "./../../../../store/orders/ordersSlice";

import TableHeader from "../../../Form/TableFields/TableHeader/TableHeader";
import LoadingPage from "../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import PartnersOrdersTableItem from "./PartnersOrdersTableItem/PartnersOrdersTableItem";
import PartnerOrdersTableHeader from "./PartnerOrdersTableHeader/PartnerOrdersTableHeader";
import TableWrapper from "../../../Form/TableFields/TableWrapper/TableWrapper";

const PartnerOrdersTable = ({ partner }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { ordersLoading, orders, ordersMeta } = useSelector(
    (state) => state.orders
  );

  const [status, setStatus] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [date, setDate] = useState({});
  const [price, setPrice] = useState({});

  const partnerId = partner?.id;

  const onClear = () => {
    if (status) setStatus(null);
    if (customer) setCustomer(null);
    if (Object.keys(date).length > 0) setDate({});
    if (Object.keys(price).length > 0) setPrice({});
  };

  useEffect(() => {
    dispatch(
      getOrders({
        page: 1,
        partner_id: partnerId,
        status_id: status?.value,
        customer_id: customer?.value?.id,
        start_date:
          Object.keys(date).length > 0 && date?.startDate
            ? moment(date.startDate).format("DD/MM/YYYY")
            : "",
        end_date:
          Object.keys(date).length > 0 && date?.endDate
            ? moment(date.endDate).format("DD/MM/YYYY")
            : "",
        min_price: price?.minPrice,
        max_price: price?.maxPrice,
        revenue_currency_id: price?.revenueCurrencyId,
      })
    );
    return () => {
      dispatch(resetGetOrders());
    };
  }, [dispatch, customer, date, partnerId, price, status]);

  return (
    <Fragment>
      <div id='PartnerOrdersTable'>
        <PartnerOrdersTableHeader
          partner={partner}
          status={status}
          setStatus={setStatus}
          customer={customer}
          setCustomer={setCustomer}
          date={date}
          setDate={setDate}
          price={price}
          setPrice={setPrice}
          onClear={onClear}
        />
        {ordersLoading && <LoadingPage />}
        {orders && (
          <TableWrapper
            total={ordersMeta.total}
            emptyText={t("tms.tables.noInfo.No orders")}
          >
            <TableHeader>
              <th>{t("tms.tables.titles.Order number")}</th>
              <th>{t("tms.tables.titles.Status")}</th>
              <th>{t("tms.tables.titles.Customer")}</th>
              <th>{t("tms.tables.titles.Route")}</th>
              <th>{t("tms.tables.titles.Driver")}</th>
              <th>{t("tms.tables.titles.Trailer type")}</th>
              <th>{t("tms.tables.titles.Pick up date")}</th>
              <th>{t("tms.tables.titles.Price")}</th>
            </TableHeader>
            <tbody>
              {orders.map((order, index) => (
                <PartnersOrdersTableItem key={index} order={order} />
              ))}
            </tbody>
          </TableWrapper>
        )}
      </div>
    </Fragment>
  );
};

export default PartnerOrdersTable;
