import {
  MdLocationPin,
  MdLocalShipping,
  MdDirectionsRailway,
  MdFlight,
} from "react-icons/md";
import { BsAirplane } from "react-icons/bs";
import { BiSolidShip } from "react-icons/bi";
import { IoIosWarning } from "react-icons/io";
import { FaAnchor } from "react-icons/fa";

export function useConvertShippingType(type) {
  const typeMapping = {
    1: {
      text: "LAND",
      icon: <MdLocalShipping />,
      secondaryIcon: <MdLocationPin />,
    },
    2: {
      text: "LAND",
      icon: <MdLocalShipping />,
      secondaryIcon: <MdLocationPin />,
    },
    3: {
      text: "OCEAN",
      icon: <BiSolidShip />,
      secondaryIcon: <FaAnchor />,
    },
    4: {
      text: "OCEAN",
      icon: <BiSolidShip />,
      secondaryIcon: <FaAnchor />,
    },
    5: { text: "AIR", icon: <MdFlight />, secondaryIcon: <BsAirplane /> },
    6: {
      text: "RAIL",
      icon: <MdDirectionsRailway />,
      secondaryIcon: <MdDirectionsRailway />,
    },
    7: {
      text: "RAIL",
      icon: <MdDirectionsRailway />,
      secondaryIcon: <MdDirectionsRailway />,
    },
  };

  const defaultType = {
    text: "No information",
    icon: <IoIosWarning />,
    secondaryIcon: <IoIosWarning />,
  };

  const { text, icon, secondaryIcon } = typeMapping[type.id] || defaultType;

  return {
    shippingTypeText: text,
    shippingTypeIcon: icon,
    shippingTypeSecondaryIcon: secondaryIcon,
  };
}
