import React from "react";
import "./FilterStatusSelect.scss";

import { useTranslation } from "react-i18next";

import Select from "react-select";
import { useSelector } from "react-redux";

const FilterStatusSelect = ({ value, setValue }) => {
  const { t } = useTranslation();

  const { getCargoStatusesResponce } = useSelector((state) => state.cargos);

  const options = getCargoStatusesResponce
    ?.filter((status) => status?.listed)
    .map((status) => ({
      value: status.id,
      label: status.name,
    }));

  return (
    <div id='FilterStatusSelect'>
      <Select
        classNamePrefix='select'
        name='filterStatusSelect'
        className='selectComponent'
        options={options || []}
        placeholder={t("tms.filters.placeholders.Status")}
        value={value}
        onChange={(state) => setValue(state)}
        isClearable={true}
        isSearchable={true}
        noOptionsMessage={() => (
          <div>{t("tms.filters.others.No status found")}</div>
        )}
      />
    </div>
  );
};

export default FilterStatusSelect;
