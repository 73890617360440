import React, { Fragment, useState, useEffect } from "react";
import "./AddTruckExpensesModal.scss";

import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  createMaintenance,
  resetCreateMaintenance,
} from "../../../../../../store/fleet/maintenance/maintenanceSlice";

import useMaintenancesErrors from "../../../../../../validations/fleet/useMaintenancesErrors";

import DModal from "../../../../../Modal/DModal/DModal";
import DInputs from "../../../../../Form/DInputs/DInputs";
import DCurrencyInput from "../../../../../Form/DCurrencyInput/DCurrencyInput";
import DCheckBox from "../../../../../Form/DCheckBox/DCheckBox";
import DDatepicker from "../../../../../Form/DDatepicker/DDatepicker";

const AddTruckExpensesModal = ({ truck, isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { createMaintenanceLoading, createMaintenanceResponce } = useSelector(
    (state) => state.maintenance
  );

  const {
    create_service_error,
    create_provider_error,
    create_cost_error,
    create_downtime_start_error,
    create_downtime_end_error,
  } = useMaintenancesErrors();

  const { user } = useSelector((state) => state.auth);
  const { currenciesSelect } = useSelector((state) => state.settings);

  const [service, setService] = useState("");
  const [serviceProvider, setServiceProvider] = useState("");
  const [cost, setCost] = useState("");
  const [currencyValue, setCurrencyValue] = useState(null);
  const [downtimeShow, setDowntimeShow] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const clearState = () => {
    setService("");
    setServiceProvider("");
    setCost("");
    setStartDate(null);
    setEndDate(null);
  };

  const submitHandler = () => {
    let data = {
      truck_id: truck.id,
      service,
      currency_id: currencyValue && currencyValue.value.id,
      provider: serviceProvider,
      cost: cost,
      downtime_start: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
      downtime_end: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
    };
    dispatch(createMaintenance(data));
  };

  useEffect(() => {
    if (currenciesSelect) {
      const findUserCurrency = currenciesSelect.find(
        (currency) =>
          currency.value.id.toString() === user.currency.id.toString()
      );
      setCurrencyValue(findUserCurrency);
    }
  }, [currenciesSelect, user]);

  useEffect(() => {
    if (createMaintenanceResponce) {
      dispatch(resetCreateMaintenance());
      clearState();
      isClose();
    }
  }, [dispatch, isClose, createMaintenanceResponce]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose(false)}
        title={t("tms.modals.titles.Add truck expense")}
        submitText={t("tms.modals.buttons.Confirm")}
        icon={<FiPlus />}
        status='success'
        onClick={() => submitHandler()}
        loading={createMaintenanceLoading}
      >
        <div id='AddTruckExpensesModal'>
          <div className='AddExpensesModalGroup'>
            <DInputs
              label={t("tms.modals.labels.Service")}
              placeholder={t("tms.modals.placeholder.Enter service")}
              value={service}
              setValue={setService}
              id='chooseService'
              required
              error={create_service_error}
            />
            <DInputs
              id='serviceProvider'
              label={t("tms.modals.labels.Service provider")}
              placeholder={t("tms.modals.placeholder.Enter service provider")}
              value={serviceProvider}
              setValue={setServiceProvider}
              required
              error={create_provider_error}
            />
            <DCurrencyInput
              id='expenseCost'
              label={t("tms.modals.labels.Cost")}
              placeholder={t("tms.modals.placeholder.Enter cost")}
              value={cost}
              setValue={setCost}
              currencyValue={currencyValue}
              setCurrencyValue={setCurrencyValue}
              required
              error={create_cost_error}
            />
            <div className='groupItem'>
              <DCheckBox
                value={downtimeShow}
                setValue={setDowntimeShow}
                label={t("tms.modals.labels.Downtime")}
              />
              {downtimeShow && (
                <div className='downtimeContainer'>
                  <DDatepicker
                    label={t("tms.modals.labels.Start date")}
                    placeholder={t("tms.modals.placeholder.Start date")}
                    value={startDate}
                    setValue={setStartDate}
                    error={create_downtime_start_error}
                  />
                  <DDatepicker
                    label={t("tms.modals.labels.End date")}
                    placeholder={t("tms.modals.placeholder.End date")}
                    value={endDate}
                    setValue={setEndDate}
                    error={create_downtime_end_error}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default AddTruckExpensesModal;
