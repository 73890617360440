import _request from "./../_request";

// register
const register = async (ISO, data) => {
  const config = {
    url: "/auth/signup",
    method: "post",
    ISO,
  };
  return _request(config, data);
};

// login
const login = async (ISO, data) => {
  const config = {
    url: "/auth/login",
    method: "post",
    ISO,
  };
  return _request(config, data);
};

// verify user
const verifyUser = async (ISO, token, data) => {
  const config = {
    url: "/auth/verification/verify",
    method: "post",
    ISO,
    token,
  };
  return _request(config, data);
};

// logout
const logout = async (ISO, token) => {
  const config = {
    url: "/auth/logout",
    method: "post",
    ISO,
    token,
  };
  return _request(config);
};

// get user
const getUser = async (ISO, token) => {
  const config = {
    url: "/user/profile",
    ISO,
    token,
  };
  return _request(config);
};

// re send verify
const resendVerifyUser = async (ISO, token, data) => {
  const config = {
    url: `/auth/verification/send/${data}`,
    method: "post",
    ISO,
    token,
  };
  return _request(config);
};

// forgotPassword
const forgotPassword = async (ISO, data) => {
  const config = {
    url: "/auth/forgot-password",
    method: "post",
    ISO,
  };
  return _request(config, data);
};

//codeCheck
const codeCheck = async (ISO, data) => {
  const config = {
    url: "/auth/code-check",
    method: "post",
    ISO,
  };
  return _request(config, data);
};

// reset password
const resetPassword = async (ISO, data) => {
  const config = {
    url: "/auth/reset-password",
    method: "post",
    ISO,
  };
  return _request(config, data);
};

const authService = {
  register,
  login,
  logout,
  verifyUser,
  getUser,
  resendVerifyUser,
  forgotPassword,
  codeCheck,
  resetPassword,
};

export default authService;
