import React, { Fragment } from "react";
import "./ProductDetails.scss";

import { useTranslation } from "react-i18next";
import { BsBoxSeam } from "react-icons/bs";

import Character from "../../componentsDashboard/UI/Character/Character";

const ProductDetailsItem = ({ title, text }) => {
  return (
    <div className='item'>
      <div className='itemTitle'>{title}</div>
      <div className='itemText'>
        <Character title={text} max={50} />
      </div>
    </div>
  );
};

const ProductDetails = ({ order }) => {
  const { t } = useTranslation();
  return (
    <div id='BidsProductDetails'>
      <div className='titleContainer'>
        <div className='icon'>
          <BsBoxSeam />
        </div>
        <div className='title'>{t("partnerBid.Product")}</div>
      </div>
      <div className='items'>
        <ProductDetailsItem
          title={`${t("partnerBid.Shipping type")}:`}
          text={order?.type?.name || ""}
        />
        <ProductDetailsItem
          title={`${t("partnerBid.Product category")}:`}
          text={order?.cargos[0]?.type?.name || ""}
        />
        <ProductDetailsItem
          title={`${t("partnerBid.Total Weight")}:`}
          text={`${order?.cargos[0]?.weight || 0} (${t("partnerBid.KG")})`}
        />
        {order?.cargos[0]?.adr > 0 && (
          <Fragment>
            <ProductDetailsItem
              title={`${t("partnerBid.ADR")}:`}
              text={order?.cargos[0]?.adr > 0 ? t("partnerBid.Yes") : ""}
            />
            <ProductDetailsItem
              title={`${t("partnerBid.UN Number")}:`}
              text={order?.cargos[0]?.un_number || ""}
            />
            <ProductDetailsItem
              title={`${t("partnerBid.ADR Class")}:`}
              text={order?.cargos[0]?.adr_class || ""}
            />
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default ProductDetails;
