import React, { Fragment, useState, useEffect } from "react";
import "./EditPhoneModal.scss";

import { useTranslation } from "react-i18next";
import { FaMobileAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import {
  changePhone,
  resetChangePhone,
  verifyPhone,
  resetVerifyPhone,
} from "../../../../../store/user/userSlice";

import { useChangePhoneErrors } from "../../../../../validations/auth/useChangePhoneErrors";

import DModal from "../../../../Modal/DModal/DModal";
import DPhoneInput from "../../../../Form/DPhoneInput/DPhoneInput";
import OTP from "../../../../../componentsLanding/Form/OTP/OTP";

const EditPhoneModal = ({ isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const {
    changePhoneLoading,
    changePhoneResponce,
    verifyPhoneLoading,
    verifyPhoneResponce,
  } = useSelector((state) => state.user);

  const { phoneIndexValueId } = useSelector((state) => state.settings);
  const { update_phone_error, update_phone_verify_error } =
    useChangePhoneErrors();

  const [phone, setPhone] = useState(user?.phone || "");
  const [showOtp, setShowOtp] = useState(false);
  const [otp, setOtp] = useState("");

  const handleFirstSubmit = () => {
    dispatch(changePhone({ phone: phone, phone_index_id: phoneIndexValueId }));
  };

  const handleSecondSubmit = () => {
    dispatch(verifyPhone({ code: otp }));
  };

  useEffect(() => {
    if (changePhoneResponce) {
      dispatch(resetChangePhone());
      setShowOtp(true);
    }
  }, [dispatch, changePhoneResponce]);

  useEffect(() => {
    if (verifyPhoneResponce) {
      dispatch(resetVerifyPhone());
      setShowOtp(false);
      isClose();
    }
  }, [dispatch, isClose, verifyPhoneResponce]);

  return (
    <DModal
      isOpen={isOpen}
      isClose={() => {
        setShowOtp(false);
        isClose();
      }}
      title={
        showOtp
          ? t("tms.modals.titles.Enter verification code")
          : t("tms.modals.titles.Edit phone number")
      }
      icon={<FaMobileAlt />}
      status={"info"}
      submitText={
        showOtp
          ? t("tms.modals.buttons.Verify")
          : t("tms.modals.buttons.Send code")
      }
      loading={changePhoneLoading || verifyPhoneLoading}
      onClick={() => (showOtp ? handleSecondSubmit() : handleFirstSubmit())}
    >
      <div id='profileEditPhone'>
        <form className='formGroup'>
          {showOtp ? (
            <Fragment>
              <div className='otpTitle'>
                {t("tms.modals.others.Enter verification code")}
              </div>
              <OTP
                count={4}
                value={otp}
                setValue={setOtp}
                error={update_phone_verify_error}
              />
            </Fragment>
          ) : (
            <DPhoneInput
              id={"editProfilePhone"}
              value={phone}
              setValue={setPhone}
              label={t("tms.modals.labels.Phone number")}
              placeholder={t("tms.modals.placeholder.Enter phone number")}
              required
              error={update_phone_error}
            />
          )}
        </form>
      </div>
    </DModal>
  );
};

export default EditPhoneModal;
