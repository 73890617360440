import React, { Fragment, useEffect, useState } from "react";
import "./MaintenanceList.scss";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  getMaintenances,
  resetGetMaintenances,
} from "../../../../../../store/fleet/maintenance/maintenanceSlice";

import EmptyList from "../../../../../../componentsDashboard/UI/EmptyList/EmptyList";
import Wrapper from "../../../../../../componentsAdditional/Wrapper";
import PageSwitcher from "../../../../../../componentsDashboard/PageSwitcher/PageSwitcher";
import AddExpensesModal from "./../../../../../../componentsDashboard/FleetManagement/FleetManagementMaintenance/AddExpensesModal/AddExpensesModal";
import MaintenanceTable from "./../../../../../../componentsDashboard/FleetManagement/FleetManagementMaintenance/MaintenanceTable/MaintenanceTable";
import LoadingPage from "../../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import MaintenanceListHeader from "../../../../../../componentsDashboard/FleetManagement/FleetManagementMaintenance/MaintenanceListHeader/MaintenanceListHeader";

const MaintenanceList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getMaintenancescountResponce, getMaintenancescountLoading } =
    useSelector((state) => state.summary);

  const [openAddModal, setOpenAddModal] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [date, setDate] = useState({});

  const {
    getMaintenancesResponce,
    getMaintenancesLoading,
    createMaintenanceResponce,
    updateMaintenanceResponce,
    deleteMaintenanceResponce,
  } = useSelector((state) => state.maintenance);

  useEffect(() => {
    dispatch(
      getMaintenances({
        start_date:
          Object.keys(date).length > 0 && date?.startDate
            ? moment(date.startDate).format("DD/MM/YYYY")
            : "",
        end_date:
          Object.keys(date).length > 0 && date?.endDate
            ? moment(date.endDate).format("DD/MM/YYYY")
            : "",
        keyword,
      })
    );
    return () => {
      dispatch(resetGetMaintenances());
    };
  }, [
    dispatch,
    createMaintenanceResponce,
    updateMaintenanceResponce,
    deleteMaintenanceResponce,
    date,
    keyword,
  ]);

  return (
    <Wrapper documentTitle={t("tms.documentTitles.Maintenance")}>
      <div id='MaintenanceList'>
        <PageSwitcher name='maintenance' />
        {getMaintenancescountLoading && <LoadingPage />}
        {getMaintenancescountResponce && (
          <Fragment>
            {getMaintenancescountResponce.maintenances_count === 0 ? (
              <EmptyList
                title={t("tms.titles.No Maintenance")}
                description={t("tms.desc.Add your expenses, and manage it")}
                buttonText={t("tms.forms.buttons.Add expense")}
                image='/assets/images/dashboard/cover3.svg'
                onClick={() => {
                  setOpenAddModal(true);
                }}
              />
            ) : (
              <Fragment>
                <MaintenanceListHeader
                  keyword={keyword}
                  setKeyword={setKeyword}
                  date={date}
                  setDate={setDate}
                  setOpenAddModal={setOpenAddModal}
                />
                {getMaintenancesLoading && <LoadingPage />}
                {getMaintenancesResponce && (
                  <MaintenanceTable maintenances={getMaintenancesResponce} />
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
      <AddExpensesModal isOpen={openAddModal} isClose={setOpenAddModal} />
    </Wrapper>
  );
};

export default MaintenanceList;
