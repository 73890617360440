import React, { Fragment, useState, useEffect } from "react";
import "./AddDriversModal.scss";

import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";

import {
  createDriver,
  resetCreateDriver,
} from "./../../../../store/fleet/drivers/driversSlice";
import { useDriverErrors } from "./../../../../validations/fleet/useDriverErrors";

import DModal from "../../../Modal/DModal/DModal";
import DInputs from "../../../Form/DInputs/DInputs";
import DPhoneInput from "../../../Form/DPhoneInput/DPhoneInput";

const AddDriversModal = ({ isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { createDriverLoading, createDriverResponce } = useSelector(
    (state) => state.drivers
  );
  const { phoneIndexValueId } = useSelector((state) => state.settings);
  const {
    create_first_name_error,
    create_last_name_error,
    create_phone_error,
    create_phone_index_id_error,
  } = useDriverErrors();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const submitHandler = () => {
    const data = {
      first_name: firstName,
      last_name: lastName,
      phone: phoneNumber,
      phone_index_id: phoneIndexValueId,
    };
    dispatch(createDriver(data));
  };

  const resetState = () => {
    setFirstName("");
    setLastName("");
    setPhoneNumber("");
  };

  useEffect(() => {
    if (createDriverResponce) {
      dispatch(resetCreateDriver());
      resetState();
      isClose();
    }
  }, [dispatch, isClose, createDriverResponce]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose(false)}
        title={t("tms.modals.titles.Add Driver")}
        submitText={t("tms.modals.buttons.Save")}
        icon={<FiPlus />}
        status='success'
        onClick={() => submitHandler()}
        loading={createDriverLoading}
      >
        <div id='AddDriversModal'>
          <div className='AddDriversModalGroup'>
            <DPhoneInput
              id='enterDriverPhoneAddDriver'
              label={t("tms.modals.labels.Phone number")}
              placeholder={t("tms.modals.placeholder.Enter phone number")}
              required
              value={phoneNumber}
              setValue={setPhoneNumber}
              error={create_phone_error || create_phone_index_id_error}
            />
            <DInputs
              id='enterDriverFisrNameAddDriver'
              value={firstName}
              setValue={setFirstName}
              required
              label={t("tms.modals.labels.Driver first name")}
              placeholder={t("tms.modals.placeholder.Enter first name")}
              error={create_first_name_error}
            />

            <DInputs
              id='enterDriverLastNameAddDriver'
              value={lastName}
              setValue={setLastName}
              required
              label={t("tms.modals.labels.Driver last name")}
              placeholder={t("tms.modals.placeholder.Enter last name")}
              error={create_last_name_error}
            />
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default AddDriversModal;
