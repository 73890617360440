import React, { useState, useEffect } from "react";
import "./SignUpCarrierAuthForm.scss";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";

import { register, reset } from "../../../../store/auth/authSlice";
import { useAuthError } from "../../../../validations/auth/useAuthError";

import AuthFormWrapper from "../../Components/AuthFormWrapper/AuthFormWrapper";
import FormTitle from "../../Components/FormTitle/FormTitle";
import Button from "../../../Form/Button/Button";
import Input from "../../../Form/Inputs/Input";
import PhoneInput from "../../../Form/Inputs/PhoneInput/PhoneInput";
import Label from "../../../Form/Label/Label";
import ErrorText from "../../../Form/ErrorText/ErrorText";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import TermsAgree from "../../../Form/Checkbox/TermsAgree/TermsAgree";

const SignUpCarrierAuthForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const firstName = searchParams.get("firstName");
  const lastName = searchParams.get("lastName");
  const countryId = searchParams.get("countryId");
  const { phoneIndexValueId } = useSelector((state) => state.settings);
  const { data, error, loading } = useSelector((state) => state.auth);
  const {
    branch_id_error,
    user_type_id_error,
    first_name_error,
    last_name_error,
    phone_index_id_error,
    phone_error,
    legal_id_error,
    password_error,
    agreement_error,
  } = useAuthError();

  const [personalId, setPersonalId] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [agree, setAgree] = useState(true);

  const submitHandler = () => {
    let data = {
      branch_id: countryId,
      user_type_id: 2,
      first_name: firstName,
      last_name: lastName,
      phone_index_id: phoneIndexValueId,
      phone: phone,
      password: password,
      password_confirmation: password,
      legal_id: personalId,
      agreement: agree,
    };
    dispatch(register(data));
  };

  useEffect(() => {
    if (!error && data) {
      const url = `/register/carrier/verify?firstName=${firstName}&lastName=${lastName}&countryId=${countryId}&phoneIndexId=${phoneIndexValueId}&phone=${phone}&personalId=${personalId}&agree=${agree}`;
      navigate(url);
      dispatch(reset());
    }
  }, [
    error,
    data,
    firstName,
    lastName,
    countryId,
    phoneIndexValueId,
    phone,
    personalId,
    agree,
    navigate,
    dispatch,
  ]);

  useEffect(() => {
    if (
      user_type_id_error ||
      first_name_error ||
      last_name_error ||
      branch_id_error
    ) {
      navigate("/register/carrier");
    }
  }, [
    user_type_id_error,
    first_name_error,
    last_name_error,
    branch_id_error,
    navigate,
  ]);

  return (
    <AuthFormWrapper>
      <form id='SignUpCarrierAuthForm' className='SignUpCarrierAuthForm'>
        <FormTitle
          title={t("landing.auth.titles.Welcome to the future of logistics")}
        >
          <span>{t("landing.auth.titles.We almost there")}</span>
        </FormTitle>
        <ProgressBar position={2} />
        <div className='group'>
          <div>
            <Label text={t("landing.auth.form.Personal ID")} id='PersonalID' />
            <Input
              value={personalId}
              setValue={setPersonalId}
              placeholder={t("landing.auth.form.Personal ID")}
              id='PersonalID'
              error={legal_id_error}
            />
            <ErrorText error={legal_id_error} />
          </div>
          <div>
            <Label
              text={t("landing.auth.form.Phone number")}
              id='PhoneNumber'
            />
            <PhoneInput
              id='PhoneNumber'
              value={phone}
              setValue={setPhone}
              placeholder={t("landing.auth.form.Enter Phone number")}
              error={phone_index_id_error || phone_error}
            />
            <ErrorText error={phone_index_id_error} />
            <ErrorText error={phone_error} />
          </div>

          <div>
            <Label
              text={t("landing.auth.form.Create Password")}
              id='Password'
              warning
              warningText={t(
                "landing.form.warningText.password must contain minimum of 6 character"
              )}
            />
            <Input
              value={password}
              setValue={setPassword}
              placeholder={t("landing.auth.form.Enter password")}
              id='Password'
              password
              error={password_error}
            />
            <ErrorText error={password_error} />
          </div>

          <div>
            <TermsAgree value={agree} setValue={setAgree} />
            <ErrorText error={agreement_error} />
            <ErrorText error={user_type_id_error} />
          </div>
        </div>

        <Button
          loading={loading}
          title={t("landing.auth.form.Next")}
          onClick={() => {
            submitHandler();
          }}
        />
      </form>
    </AuthFormWrapper>
  );
};

export default SignUpCarrierAuthForm;
