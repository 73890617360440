import { useSelector } from "react-redux";

export const useChangePasswordErrors = () => {
  const { changePasswordError } = useSelector((state) => state.user);

  const update_old_password_error =
    changePasswordError &&
    changePasswordError.errors &&
    changePasswordError.errors.old_password &&
    changePasswordError.errors.old_password[0];

  const update_new_password_error =
    changePasswordError &&
    changePasswordError.errors &&
    changePasswordError.errors.new_password &&
    changePasswordError.errors.new_password[0];

  return {
    update_old_password_error,
    update_new_password_error,
  };
};
