import React, { useState } from "react";
import "./QuotationModal.scss";

import { useTranslation } from "react-i18next";
import { FiX } from "react-icons/fi";
import { BsChatLeftTextFill } from "react-icons/bs";

import { useOrderPaymentSelectSubOptions } from "../../../../helper/useOrderPaymentSelectSubOptions";
import { useMoney } from "../../../../helper/useMoney";

import DOverlay from "../../../Modal/DOverlay/DOverlay";
import DButton from "../../../Form/DButtons/DButton/DButton";
import ShipperOfferRejectModal from "../ShipperOfferRejectModal/ShipperOfferRejectModal";
import ShipperOfferConfirmModal from "../ShipperOfferConfirmModal/ShipperOfferConfirmModal";

const QuotationModal = ({ isOpen, isClose, order }) => {
  const { t } = useTranslation();
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const paymentSubOptions = useOrderPaymentSelectSubOptions();

  const bid = order?.bids?.[0];

  const price = useMoney(bid?.amount, bid?.currency?.symbol);

  const paymentAmount = useMoney(bid?.payment_amount, bid?.currency?.symbol);

  const paymentOptionType = paymentSubOptions?.find((option) => {
    return option?.value?.id === bid?.payment_option_type;
  });

  return (
    <DOverlay isOpen={isOpen} isClose={isClose}>
      <div id='QuotationModal'>
        <div className='header'>
          <div className='title'>
            <div className='titleIcon'>
              <BsChatLeftTextFill />
            </div>
            <h2>{t("shipper.modal.Offer from cargon")}</h2>
          </div>
          <div className='closeIcon' onClick={isClose}>
            <FiX />
          </div>
        </div>
        <div className='content'>
          <div className='paymentContainer'>
            <div className='payment'>
              <h3>{bid?.paymentType?.name}</h3>
              <div className='paymentOptions'>
                {bid?.payment_amount && (
                  <div className='paymentOption'>
                    <div className='title'>
                      {t("shipper.modal.Pre payment")}
                    </div>
                    <div className='value'>{paymentAmount}</div>
                  </div>
                )}
                {paymentOptionType && (
                  <div className='paymentOption'>
                    <div className='title'>
                      {t("shipper.modal.Post payment condition")}
                    </div>
                    <div className='value'>{paymentOptionType?.label}</div>
                  </div>
                )}
                {bid?.payment_days && (
                  <div className='paymentOption'>
                    <div className='title'>
                      {t("shipper.modal.Consignation")}
                    </div>
                    <div className='value'>
                      {bid?.payment_days} {t("shipper.modal.days")}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='price'>
              <h3>{t("shipper.modal.Price")}</h3>
              <div className='priceValue'>{price}</div>
            </div>
          </div>

          <div className='comment'>
            <div className='title'>{t("shipper.modal.Comment")}</div>
            <div className='text'>
              {/* Lorem ipsum, or lipsum as it is sometimes known, is dummy text
              used in laying out print, graphic or web designs. The passage is
              attributed to an unknown typesetter in the 15th century . */}
            </div>
          </div>
        </div>
        <div className='footer'>
          <div className='refuseButton'>
            <DButton title={t("shipper.buttons.Reject")} onClick={() => setOpenRejectModal(true)} />
          </div>
          <DButton
            title={`${t("shipper.buttons.Confirm")} ${price}`}
            onClick={() => setOpenConfirmModal(true)}
          />
        </div>
      </div>

      {openRejectModal && (
        <ShipperOfferRejectModal
          isOpen={openRejectModal}
          isClose={() => setOpenRejectModal(false)}
          order={order}
          setParentModalClose={isClose}
        />
      )}
      {openConfirmModal && (
        <ShipperOfferConfirmModal
          isOpen={openConfirmModal}
          isClose={() => setOpenConfirmModal(false)}
          order={order}
          setParentModalClose={isClose}
        />
      )}
    </DOverlay>
  );
};

export default QuotationModal;
