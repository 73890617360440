import React, { Fragment, useEffect } from "react";
import "./TrailerManagement.scss";

import { useTranslation } from "react-i18next";

import { FiPlusCircle } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";

import { getTrailerTypes } from "../../../../../../store/orders/orderSettings/orderSettingsSlice";

import DButton from "../../../../../Form/DButtons/DButton/DButton";
import TrailerManagementItem from "./TrailerManagementItem/TrailerManagementItem";

const TrailerManagement = ({
  edit,
  orderType,
  trailers,
  setTrailers,
  trailer_type_id_error,
  trailer_size_id_error,
  cargos_weight_error,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { currenciesSelect } = useSelector((state) => state.settings);

  const addNewTrailerHandler = () => {
    const findCurrency = currenciesSelect.find(
      (item) => item.value.id === user.currency.id
    );
    const newTraielrs = [
      ...trailers,
      {
        trailerType: null,
        trailerSize: null,
        temperatureRange: null,
        weight: "",
        price: "",
        currency: findCurrency,
      },
    ];
    setTrailers(newTraielrs);
  };

  useEffect(() => {
    if (orderType) {
      dispatch(getTrailerTypes(orderType.value.id));
    }
  }, [dispatch, orderType]);

  return (
    <Fragment>
      <div id='AddOrderTrailerManagement'>
        <div className='list'>
          {trailers.map((trailer, index) => (
            <TrailerManagementItem
              edit={edit}
              key={index}
              orderType={orderType}
              trailer={trailer}
              index={index}
              trailers={trailers}
              setTrailers={setTrailers}
              trailer_type_id_error={trailer_type_id_error}
              trailer_size_id_error={trailer_size_id_error}
              cargos_weight_error={cargos_weight_error}
            />
          ))}
        </div>
        {!edit && (
          <div className='addNewTrailersListButton'>
            <DButton
              disabled={trailers.length >= 40 ? true : false}
              title={t("tms.forms.buttons.Add new trailer")}
              leftIcon={<FiPlusCircle />}
              onClick={() => {
                addNewTrailerHandler();
              }}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default TrailerManagement;
