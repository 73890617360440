import React, { useEffect } from "react";
import DSelect from "../DSelect";

import { useSelector, useDispatch } from "react-redux";

import {
  resetUpdateUserLanguage,
  updateUserLanguage,
} from "../../../../store/user/userSlice";
import { setLang } from "../../../../store/additional/lang/langSlice";

const DLanguageSelect = ({ width }) => {
  const dispatch = useDispatch();

  const { languagesSelect, ISO } = useSelector((state) => state.lang);
  const { updateUserLanguageResponce, updateUserLanguageLoading } = useSelector(
    (state) => state.user
  );

  const currentValueLang =
    languagesSelect && languagesSelect.find((item) => item.value.key === ISO);

  useEffect(() => {
    if (updateUserLanguageResponce) {
      dispatch(resetUpdateUserLanguage());
      window.location.reload(false);
    }
  }, [dispatch, updateUserLanguageResponce]);

  return (
    <div id='DLanguageSelectContainer' style={{ width: width }}>
      <DSelect
        id={"DLanguageSelect"}
        value={currentValueLang}
        setValue={(lang) => {
          dispatch(updateUserLanguage({ language: lang.value.key }));
          dispatch(setLang(lang.value.key));
        }}
        placeholder='Languages'
        loading={updateUserLanguageLoading}
        options={languagesSelect ? languagesSelect : []}
      />
    </div>
  );
};

export default DLanguageSelect;
