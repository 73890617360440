import React from "react";
import "./CountryMobileMenu.scss";

import { useSelector } from "react-redux";

import { HiOutlineOfficeBuilding } from "react-icons/hi";

const CountryMobileMenu = ({ setValue }) => {
  const { countries, countryId } = useSelector((state) => state.settings);

  const currentCountry = countries?.find(
    (country) => country?.id === countryId
  )?.country;

  return (
    <div id='CountryMobileMenu'>
      {countries && currentCountry && (
        <div
          className='countryButtonResponsive'
          onClick={() => {
            setValue(true);
          }}
        >
          <div className='flag'>
            <HiOutlineOfficeBuilding />
          </div>
          <p>{countries && currentCountry?.name}</p>
        </div>
      )}
    </div>
  );
};

export default CountryMobileMenu;
