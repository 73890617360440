import _request from "../../_request";

// get Partners
const getPartners = async (ISO, token) => {
  const config = {
    url: "/partners",
    ISO,
    token,
  };
  return _request(config);
};

const partnersService = {
  getPartners,
};

export default partnersService;
