import React from "react";
import "./PaymentNewOrder.scss";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import NewOrderTitle from "../Components/NewOrderTitle/NewOrderTitle";
import DSelect from "../../../../Form/DSelect/DSelect";
import DInputs from "../../../../Form/DInputs/DInputs";
import DCurrencyInput from "../../../../Form/DCurrencyInput/DCurrencyInput";
import DPaymentSubOptions from "../../../../Form/DSelect/DPaymentSubOptions/DPaymentSubOptions";

const PaymentNewOrder = ({
  payMethod,
  setPayMethod,
  payMethodSub,
  setPayMethodSub,
  payMethodAmount,
  setPayMethodAmount,
  payMethodAmountCurrency,
  setPayMethodAmountCurrency,
  payConsignationPeriod,
  setPayConsignationPeriod,

  payment_type_id_error,
  payment_option_type_error,
  payment_days_error,
  payment_amount_error,
  payment_amount_currency_error,
}) => {
  const { t } = useTranslation();

  const { orderPaymentTypesLoading, orderPaymentTypesSelect } = useSelector(
    (state) => state.orderSettings
  );

  return (
    <div id='PaymentNewOrder'>
      <NewOrderTitle title={t("tms.titles.Payment method")} />

      {payMethod && (
        <div className='PaymentNewOrderWrapper'>
          <div className='groupItem method'>
            <DSelect
              required
              id='selectPaymentMethodAddOrderPayment'
              placeholder={t("tms.forms.placeholder.Payment method")}
              label={t("tms.forms.labels.Payment method")}
              options={orderPaymentTypesSelect ? orderPaymentTypesSelect : []}
              value={payMethod}
              setValue={(e) => {
                setPayMethod(e);
                setPayMethodSub(null);
                setPayConsignationPeriod("");
                setPayMethodAmount("");
              }}
              loading={orderPaymentTypesLoading}
              error={payment_type_id_error}
            />
          </div>

          {payMethod.value.id === 2 && (
            <div className='groupItem'>
              <DCurrencyInput
                id='PaymentMethosAmount'
                label={t("tms.forms.labels.PrepaymentAmount")}
                placeholder={t("tms.forms.placeholder.Enter prepayment amount")}
                value={payMethodAmount}
                setValue={setPayMethodAmount}
                currencyValue={payMethodAmountCurrency}
                setCurrencyValue={setPayMethodAmountCurrency}
                required
                error={payment_amount_error || payment_amount_currency_error}
              />
            </div>
          )}

          {(payMethod.value.id === 2 || payMethod.value.id === 3) && (
            <div className='groupItem SubTypes'>
              <DPaymentSubOptions
                value={payMethodSub}
                setValue={(e) => {
                  setPayMethodSub(e);
                  setPayConsignationPeriod("");
                }}
                error={payment_option_type_error}
              />
            </div>
          )}

          {(payMethod.value.id === 2 || payMethod.value.id === 3) &&
            payMethodSub &&
            payMethodSub.value.id === "AFTER_DROP_OFF" && (
              <div className='groupItem'>
                <DInputs
                  required
                  id='payConsignationPeriodAddOrderPayment'
                  value={payConsignationPeriod}
                  setValue={setPayConsignationPeriod}
                  type='number'
                  placeholder={t("tms.forms.placeholder.Number of days")}
                  label={t("tms.forms.labels.Consignation period")}
                  error={payment_days_error}
                />
              </div>
            )}
        </div>
      )}
    </div>
  );
};

export default PaymentNewOrder;
