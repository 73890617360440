import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import advanceService from "./advanceService";

const initialState = {
  getAdvancesLoading: true,
  getAdvancesResponce: null,
  getAdvancesError: null,

  createAdvanceLoading: false,
  createAdvanceResponce: null,
  createAdvanceError: null,

  updateAdvanceLoading: false,
  updateAdvanceResponce: null,
  updateAdvanceError: null,

  deleteAdvanceLoading: false,
  deleteAdvanceResponce: null,
  deleteAdvanceError: null,

  markPaidLoading: false,
  markPaidResponce: null,
  markPaidError: null,
};

export const getAdvances = createAsyncThunk(
  "advance/getAdvances",
  async (orderId, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await advanceService.getAdvances(ISO, TOKEN, orderId);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createAdvance = createAsyncThunk(
  "advance/createAdvance",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await advanceService.createAdvance(ISO, TOKEN, data.orderId, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateAdvance = createAsyncThunk(
  "advance/updateAdvance",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await advanceService.updateAdvance(
        ISO,
        TOKEN,
        data.orderId,
        data.advanceId,
        data
      );
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteAdvance = createAsyncThunk(
  "advance/deleteAdvance",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await advanceService.deleteAdvance(
        ISO,
        TOKEN,
        data.orderId,
        data.advanceId
      );
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const markPaid = createAsyncThunk(
  "advance/markPaid",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await advanceService.markPaid(ISO, TOKEN, data.orderId, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const advanceSlice = createSlice({
  name: "advance",
  initialState,
  reducers: {
    resetGetAdvances: (state) => {
      state.getAdvancesLoading = false;
      state.getAdvancesResponce = null;
      state.getAdvancesError = null;
    },
    resetCreateAdvance: (state) => {
      state.createAdvanceLoading = false;
      state.createAdvanceResponce = null;
      state.createAdvanceError = null;
    },
    resetUpdateAdvance: (state) => {
      state.updateAdvanceLoading = false;
      state.updateAdvanceResponce = null;
      state.updateAdvanceError = null;
    },
    resetDeleteAdvance: (state) => {
      state.deleteAdvanceLoading = false;
      state.deleteAdvanceResponce = null;
      state.deleteAdvanceError = null;
    },
    resetMarkPaid: (state) => {
      state.markPaidLoading = false;
      state.markPaidResponce = null;
      state.markPaidError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdvances.pending, (state) => {
        state.getAdvancesLoading = true;
        state.getAdvancesResponce = null;
        state.getAdvancesError = null;
      })
      .addCase(getAdvances.fulfilled, (state, action) => {
        state.getAdvancesLoading = false;
        state.getAdvancesResponce = action.payload;
        state.getAdvancesError = null;
      })
      .addCase(getAdvances.rejected, (state, action) => {
        state.getAdvancesLoading = false;
        state.getAdvancesResponce = null;
        state.getAdvancesError = action.payload;
      })

      .addCase(createAdvance.pending, (state) => {
        state.createAdvanceLoading = true;
        state.createAdvanceResponce = null;
        state.createAdvanceError = null;
      })
      .addCase(createAdvance.fulfilled, (state, action) => {
        state.createAdvanceLoading = false;
        state.createAdvanceResponce = action.payload;
        state.createAdvanceError = null;
      })
      .addCase(createAdvance.rejected, (state, action) => {
        state.createAdvanceLoading = false;
        state.createAdvanceResponce = null;
        state.createAdvanceError = action.payload;
      })

      .addCase(updateAdvance.pending, (state) => {
        state.updateAdvanceLoading = true;
        state.updateAdvanceResponce = null;
        state.updateAdvanceError = null;
      })
      .addCase(updateAdvance.fulfilled, (state, action) => {
        state.updateAdvanceLoading = false;
        state.updateAdvanceResponce = action.payload;
        state.updateAdvanceError = null;
      })
      .addCase(updateAdvance.rejected, (state, action) => {
        state.updateAdvanceLoading = false;
        state.updateAdvanceResponce = null;
        state.updateAdvanceError = action.payload;
      })

      .addCase(deleteAdvance.pending, (state) => {
        state.deleteAdvanceLoading = true;
        state.deleteAdvanceResponce = null;
        state.deleteAdvanceError = null;
      })
      .addCase(deleteAdvance.fulfilled, (state, action) => {
        state.deleteAdvanceLoading = false;
        state.deleteAdvanceResponce = action.payload;
        state.deleteAdvanceError = null;
      })
      .addCase(deleteAdvance.rejected, (state, action) => {
        state.deleteAdvanceLoading = false;
        state.deleteAdvanceResponce = null;
        state.deleteAdvanceError = action.payload;
      })

      .addCase(markPaid.pending, (state) => {
        state.markPaidLoading = true;
        state.markPaidResponce = null;
        state.markPaidError = null;
      })
      .addCase(markPaid.fulfilled, (state, action) => {
        state.markPaidLoading = false;
        state.markPaidResponce = action.payload;
        state.markPaidError = null;
      })
      .addCase(markPaid.rejected, (state, action) => {
        state.markPaidLoading = false;
        state.markPaidResponce = null;
        state.markPaidError = action.payload;
      });
  },
});

export const {
  resetGetAdvances,
  resetCreateAdvance,
  resetUpdateAdvance,
  resetDeleteAdvance,
  resetMarkPaid,
} = advanceSlice.actions;
export default advanceSlice.reducer;
