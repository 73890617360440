import React from "react";
import "./TrackingDriverData.scss";

import { useTranslation } from "react-i18next";

import Character from "../../../UI/Character/Character";
import { useConvertShippingType } from "../../../../helper/useConvertShippingType";

const TrackingDriverData = ({ order }) => {
  const { t } = useTranslation();
  const { shippingTypeText, shippingTypeIcon } = useConvertShippingType(
    order?.type || null
  );

  return (
    <div id='ShipperTrackingSectionDrivers'>
      <div className='item'>
        <div className='cover'>{shippingTypeIcon}</div>
        <div className='content'>
          <h6>
            <Character
              max={30}
              title={
                order?.carrier?.truck_registration_number ||
                t("shipper.forms.content.No information")
              }
            />
          </h6>
        </div>
      </div>
      {shippingTypeText === "LAND" && (
        <div className='item'>
          <div
            className='cover'
            style={{
              backgroundImage: `url(${`/assets/images/dashboard/cover7.svg`})`,
            }}
          ></div>
          <div className='content'>
            <h6>
              <Character
                max={30}
                title={
                  order?.carrier?.trailer_registration_number ||
                  t("shipper.forms.content.No information")
                }
              />
            </h6>
          </div>
        </div>
      )}
    </div>
  );
};

export default TrackingDriverData;
