import React, { Fragment } from "react";
import "./OrderStatusBarWithoutAction.scss";

import { useSelector } from "react-redux";
import OrderStatus from "../components/OrderStatus/OrderStatus";

const OrderStatusBarWithoutAction = ({ order }) => {
  const { getCargoStatusesResponce } = useSelector((state) => state.cargos);

  const listedStatuses =
    getCargoStatusesResponce &&
    getCargoStatusesResponce.filter((status) => status.listed);

  const renderStatuses = (statuses) => {
    if (statuses === undefined) {
      return null;
    }
    return (
      <Fragment>
        {statuses?.map((status) => {
          const currentStatus = status.id === order?.status.id;
          const doneStatus = status.id <= order?.status.id;
          const lastStatus = status.id === statuses.length;
          return (
            <li
              className={status.id < order?.status.id ? "active" : "passive"}
              key={status.id}
            >
              <div className='active' />
              <div className='passive' />
              <div className='dots'>
                <div
                  className='smallDot'
                  style={{
                    background: currentStatus
                      ? status?.color_1
                      : status.id > order?.status.id
                      ? "#f4f6f9"
                      : "",
                  }}
                />
                <div
                  className='largeDot'
                  style={{
                    background: status?.color_2,
                    display: !currentStatus && "none",
                  }}
                />
              </div>
              {currentStatus ? (
                <div className='statusContainer'>
                  <OrderStatus
                    status={status.name}
                    done={lastStatus}
                    currentStatusColors={{
                      background: status.color_2,
                      text: status.color_1,
                    }}
                  />
                </div>
              ) : (
                <div className='statusContainer'>
                  <OrderStatus
                    status={status.name}
                    done={doneStatus}
                    current={currentStatus}
                    disabled={!doneStatus}
                  />
                </div>
              )}
            </li>
          );
        })}
      </Fragment>
    );
  };

  return (
    <div id='OrderStatusBarWithoutAction'>
      <ul className='progress-bar'>{renderStatuses(listedStatuses)}</ul>
    </div>
  );
};

export default OrderStatusBarWithoutAction;
