import React from "react";
import "./SignUpForm.scss";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { BsArrowRightCircle } from "react-icons/bs";

import AuthFormWrapper from "../../Components/AuthFormWrapper/AuthFormWrapper";
import FormTitle from "../../Components/FormTitle/FormTitle";

const SignUpForm = () => {
  const { t } = useTranslation();

  return (
    <AuthFormWrapper>
      <div id='SignUpForm' className='SignUpForm'>
        <FormTitle
          title={t("landing.titles.landingMainTitles.Tell us who you are")}
        >
          <span>{t("landing.titles.landingMainTitlesDesc.Let’s start")}</span>
        </FormTitle>

        <ul className='group'>
          <li>
            <Link to='/register/shipper'>
              {/* <Link to='/register/shipper/book-a-call'> */}
              <div className='item'>
                <div className='desc'>
                  <h2>{t("landing.titles.landingMainTitles.Shipper")}</h2>
                  <p>
                    {t(
                      "landing.titles.landingMainTitlesDesc.I’m looking for a truck to move my goods."
                    )}
                  </p>
                </div>
                <div className='icon'>
                  <BsArrowRightCircle />
                </div>
              </div>
            </Link>
          </li>

          <li>
            <Link to='/register/tms'>
              <div className='item'>
                <div className='desc'>
                  <h2>
                    {t("landing.titles.landingMainTitles.Carrier company")}
                  </h2>
                  <p>
                    {t(
                      "landing.titles.landingMainTitlesDesc.I’m looking for big amount loads to haul"
                    )}
                  </p>
                </div>
                <div className='icon'>
                  <BsArrowRightCircle />
                </div>
              </div>
            </Link>
          </li>

          <li>
            <Link to='/register/carrier'>
              <div className='item'>
                <div className='desc'>
                  <h2>{t("landing.titles.landingMainTitles.Carrier")}</h2>
                  <p>
                    {t(
                      "landing.titles.landingMainTitlesDesc.I’m looking for loads to haul."
                    )}
                  </p>
                </div>
                <div className='icon'>
                  <BsArrowRightCircle />
                </div>
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </AuthFormWrapper>
  );
};

export default SignUpForm;
