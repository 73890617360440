import React, { useState, useEffect } from "react";
import "./ChangePasswordModal.scss";

import { useTranslation } from "react-i18next";
import { RiRotateLockFill } from "react-icons/ri";
import { useSelector, useDispatch } from "react-redux";

import {
  changePassword,
  resetChangePassword,
} from "./../../../../../store/user/userSlice";
import { useChangePasswordErrors } from "./../../../../../validations/auth/useChangePasswordErrors";

import DModal from "../../../../Modal/DModal/DModal";
import DInputs from "../../../../Form/DInputs/DInputs";

const ChangePasswordModal = ({ isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { changePasswordLoading, changePasswordResponce } = useSelector(
    (state) => state.user
  );
  const { update_old_password_error, update_new_password_error } =
    useChangePasswordErrors();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const submitHandler = () => {
    const data = {
      old_password: oldPassword,
      new_password: newPassword,
    };
    dispatch(changePassword(data));
  };

  useEffect(() => {
    if (changePasswordResponce) {
      dispatch(resetChangePassword());
      setOldPassword("");
      setNewPassword("");
      isClose();
    }
  }, [dispatch, isClose, changePasswordResponce]);

  return (
    <DModal
      isOpen={isOpen}
      isClose={isClose}
      status={"info"}
      title={t("tms.modals.titles.Change password")}
      submitText={t("tms.modals.buttons.Save")}
      onClick={() => submitHandler()}
      loading={changePasswordLoading}
      icon={<RiRotateLockFill />}
    >
      <div id='profileUserChangePassword'>
        <form className='formGroup'>
          <DInputs
            id={"oldPasswordDInputs"}
            label={t("tms.modals.labels.Old password")}
            placeholder={t("tms.modals.placeholder.Enter old password")}
            type='password'
            value={oldPassword}
            setValue={setOldPassword}
            error={update_old_password_error}
            required
          />
          <DInputs
            id={"newPasswordDInputs"}
            type='password'
            value={newPassword}
            setValue={setNewPassword}
            label={t("tms.modals.labels.New password")}
            placeholder={t("tms.modals.placeholder.Enter new password")}
            error={update_new_password_error}
            required
          />
        </form>
      </div>
    </DModal>
  );
};

export default ChangePasswordModal;
