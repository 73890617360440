import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import trailersService from "./trailersService";

const initialState = {
  getTrailersLoading: true,
  getTrailersResponce: null,
  getTrailersError: null,

  getTrailerLoading: true,
  getTrailerResponce: null,
  getTrailerError: null,

  createTrailerLoading: false,
  createTrailerResponce: null,
  createTrailerError: null,

  updateTrailerLoading: false,
  updateTrailerResponce: null,
  updateTrailerError: null,

  deleteTrailerLoading: false,
  deleteTrailerResponce: null,
  deleteTrailerError: null,

  getTrailerTypesLoading: true,
  getTrailerTypesResponce: null,
  getTrailerGroupSelectResponse: null,
  getTrailerTypesSelectResponce: null,
  getTrailerTypesError: null,

  getCrmTrailerTypesLoading: true,
  getCrmTrailerTypesResponce: null,
  getCrmTrailerTypesError: null,
};

export const getTrailers = createAsyncThunk("trailers/getTrailers", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await trailersService.getTrailers(ISO, TOKEN, data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getTrailer = createAsyncThunk("trailers/getTrailer", async (id, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await trailersService.getTrailer(ISO, TOKEN, id);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const createTrailer = createAsyncThunk("trailers/createTrailer", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await trailersService.createTrailer(ISO, TOKEN, data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateTrailer = createAsyncThunk("trailers/updateTrailer", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await trailersService.updateTrailer(ISO, TOKEN, data.id, data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const deleteTrailer = createAsyncThunk("trailers/deleteTrailer", async (id, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await trailersService.deleteTrailer(ISO, TOKEN, id);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getTrailerTypes = createAsyncThunk("trailers/getTrailerTypes", async (_, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    const branchId = thunkAPI.getState().auth.user.branch.id;

    return await trailersService.getTrailerTypes(ISO, TOKEN, branchId);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getCrmTrailerTypes = createAsyncThunk(
  "trailers/getCrmTrailerTypes",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;

      return await trailersService.getCrmTrailerTypes(ISO);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const trailersSlice = createSlice({
  name: "trailers",
  initialState,
  reducers: {
    resetGetTrailers: (state) => {
      state.getTrailersLoading = true;
      state.getTrailersResponce = null;
      state.getTrailersError = null;
    },
    resetGetTrailer: (state) => {
      state.getTrailerLoading = true;
      state.getTrailerResponce = null;
      state.getTrailerError = null;
    },
    resetCreateTrailer: (state) => {
      state.createTrailerLoading = false;
      state.createTrailerResponce = null;
      state.createTrailerError = null;
    },
    resetUpdateTrailer: (state) => {
      state.updateTrailerLoading = false;
      state.updateTrailerResponce = null;
      state.updateTrailerError = null;
    },
    resetDeleteTrailer: (state) => {
      state.deleteTrailerLoading = false;
      state.deleteTrailerResponce = null;
      state.deleteTrailerError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTrailers.pending, (state) => {
        state.getTrailersLoading = true;
        state.getTrailersResponce = null;
        state.getTrailersError = null;
      })
      .addCase(getTrailers.fulfilled, (state, action) => {
        state.getTrailersLoading = false;
        state.getTrailersResponce = action.payload;
        state.getTrailersError = null;
      })
      .addCase(getTrailers.rejected, (state, action) => {
        state.getTrailersLoading = false;
        state.getTrailersResponce = null;
        state.getTrailersError = action.payload;
      })

      .addCase(getTrailer.pending, (state) => {
        state.getTrailerLoading = true;
        state.getTrailerResponce = null;
        state.getTrailerError = null;
      })
      .addCase(getTrailer.fulfilled, (state, action) => {
        state.getTrailerLoading = false;
        state.getTrailerResponce = action.payload;
        state.getTrailerError = null;
      })
      .addCase(getTrailer.rejected, (state, action) => {
        state.getTrailerLoading = false;
        state.getTrailerResponce = null;
        state.getTrailerError = action.payload;
      })

      .addCase(createTrailer.pending, (state) => {
        state.createTrailerLoading = true;
        state.createTrailerResponce = null;
        state.createTrailerError = null;
      })
      .addCase(createTrailer.fulfilled, (state, action) => {
        state.createTrailerLoading = false;
        state.createTrailerResponce = action.payload;
        state.createTrailerError = null;
      })
      .addCase(createTrailer.rejected, (state, action) => {
        state.createTrailerLoading = false;
        state.createTrailerResponce = null;
        state.createTrailerError = action.payload;
      })

      .addCase(updateTrailer.pending, (state) => {
        state.updateTrailerLoading = true;
        state.updateTrailerResponce = null;
        state.updateTrailerError = null;
      })
      .addCase(updateTrailer.fulfilled, (state, action) => {
        state.updateTrailerLoading = false;
        state.updateTrailerResponce = action.payload;
        state.updateTrailerError = null;
      })
      .addCase(updateTrailer.rejected, (state, action) => {
        state.updateTrailerLoading = false;
        state.updateTrailerResponce = null;
        state.updateTrailerError = action.payload;
      })

      .addCase(deleteTrailer.pending, (state) => {
        state.deleteTrailerLoading = true;
        state.deleteTrailerResponce = null;
        state.deleteTrailerError = null;
      })
      .addCase(deleteTrailer.fulfilled, (state, action) => {
        state.deleteTrailerLoading = false;
        state.deleteTrailerResponce = action.payload;
        state.deleteTrailerError = null;
      })
      .addCase(deleteTrailer.rejected, (state, action) => {
        state.deleteTrailerLoading = false;
        state.deleteTrailerResponce = null;
        state.deleteTrailerError = action.payload;
      })

      .addCase(getTrailerTypes.pending, (state) => {
        state.getTrailerTypesLoading = true;
        state.getTrailerTypesResponce = null;
        state.getTrailerGroupSelectResponse = null;
        state.getTrailerTypesSelectResponce = null;
        state.getTrailerTypesError = null;
      })
      .addCase(getTrailerTypes.fulfilled, (state, action) => {
        state.getTrailerTypesLoading = false;
        state.getTrailerTypesResponce = action.payload;
        state.getTrailerGroupSelectResponse = action.payload.map((item) => ({
          label: item.name,
          value: item,
        }));
        state.getTrailerTypesSelectResponce = action.payload.map((item) => ({
          label: item.name,
          options: item.types.map((i) => ({
            label: i.name,
            value: i,
          })),
        }));
        state.getTrailerTypesError = null;
      })
      .addCase(getTrailerTypes.rejected, (state, action) => {
        state.getTrailerTypesLoading = false;
        state.getTrailerTypesResponce = null;
        state.getTrailerGroupSelectResponse = null;
        state.getTrailerTypesSelectResponce = null;
        state.getTrailerTypesError = action.payload;
      })

      .addCase(getCrmTrailerTypes.pending, (state) => {
        state.getCrmTrailerTypesLoading = true;
        state.getCrmTrailerTypesResponce = null;
        state.getCrmTrailerTypesError = null;
      })
      .addCase(getCrmTrailerTypes.fulfilled, (state, action) => {
        state.getCrmTrailerTypesLoading = false;
        state.getCrmTrailerTypesResponce = action.payload?.data;
        state.getCrmTrailerTypesError = null;
      })
      .addCase(getCrmTrailerTypes.rejected, (state, action) => {
        state.getCrmTrailerTypesLoading = false;
        state.getCrmTrailerTypesResponce = null;
        state.getCrmTrailerTypesError = action.payload;
      });
  },
});

export const {
  resetGetTrailers,
  resetGetTrailer,
  resetCreateTrailer,
  resetUpdateTrailer,
  resetDeleteTrailer,
} = trailersSlice.actions;
export default trailersSlice.reducer;
