import React from "react";
import "./CarrierAppSocial.scss";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const CarrierAppSocial = () => {
  const { t } = useTranslation();
  return (
    <div id='CarrierAppSocial'>
      <div className='CarrierAppSocialContainer'>
        <div
          className='appQR'
          style={{
            backgroundImage: `url(${`/assets/images/appImages/AppQR.svg`})`,
          }}
        ></div>
        <div className='appStores'>
          <Link
            target='_blank'
            to='https://apps.apple.com/gb/app/cargon/id1361078733?ign-mpt=uo%3D2'
          >
            <div
              className='img appleStore'
              style={{
                backgroundImage: `url(${`/assets/images/appImages/AppStoreBlack.svg`})`,
              }}
            ></div>
          </Link>

          <Link
            target='_blank'
            to='https://play.google.com/store/apps/details?id=com.cargon.driver&pcampaignid=web_share'
          >
            <div
              className='img'
              style={{
                backgroundImage: `url(${`/assets/images/appImages/GooglePlayBlack.svg`})`,
              }}
            ></div>
          </Link>
        </div>
      </div>
      <div className='CarrierAppAdditions'>
        {t("landing.auth.titles.If you have more then one truck, then you can")}
        <Link to={"/register/tms"}>
          {" "}
          {t("landing.auth.titles.sign up as a Carrier company")}
        </Link>
      </div>
    </div>
  );
};

export default CarrierAppSocial;
