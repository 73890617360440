import React from "react";
import "./FinishedTableItem.scss";

import { useTranslation } from "react-i18next";

import RowField from "../../../Form/TableFields/RowField/RowField";
import NameField from "../../../Form/TableFields/NameField/NameField";
import OrderStatusField from "../../../Form/TableFields/OrderStatusField/OrderStatusField";
import TextField from "../../../Form/TableFields/TextField/TextField";
import RouteField from "../../../Form/TableFields/RouteField/RouteField";
import PaymentStatusField from "../../../Form/TableFields/PaymentStatusField/PaymentStatusField";
import TotalPaymentField from "../../../Form/TableFields/TotalPaymentField/TotalPaymentField";
import CenterField from "./../../../Form/TableFields/CenterField/CenterField";
import InvoicesMenu from "../../InvoicesMenu/InvoicesMenu";
import NoDataField from "./../../../Form/TableFields/NoDataField/NoDataField";

const FinishedTableItem = ({ item }) => {
  const { t } = useTranslation();

  return (
    <RowField
      style={{
        borderLeft: `4px solid ${item.color ? item.color : "transparent"}`,
      }}
    >
      <NameField
        label={item.tracking}
        to={`/dashboard/tms/orders/${item.id}`}
      />
      <OrderStatusField status={item.status ?? null} />
      {item.ownership ? (
        item.customer?.company?.name ? (
          <TextField max={30} label={item.customer?.company?.name ?? "-"} />
        ) : (
          <NoDataField title={t("tms.tables.noInfo.no customer")} />
        )
      ) : (
        <TextField max={30} label={item.company?.name ?? "-"} />
      )}
      <RouteField order={item} />
      <TotalPaymentField item={item} />
      <PaymentStatusField item={item} />
      <CenterField>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <InvoicesMenu item={item} />
        </div>
      </CenterField>
    </RowField>
  );
};

export default FinishedTableItem;
