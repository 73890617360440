import { useSelector } from "react-redux";

export const useManageOrderErrors = () => {
  const { assignCarrierResponce } = useSelector((state) => state.manageOrder);

  const truck_registration_number_error =
    assignCarrierResponce &&
    assignCarrierResponce.errors &&
    assignCarrierResponce.errors["truck.truck_registration_number"] &&
    assignCarrierResponce.errors["truck.truck_registration_number"][0];

  const trailer_registration_number_error =
    assignCarrierResponce &&
    assignCarrierResponce.errors &&
    assignCarrierResponce.errors["truck.trailer_registration_number"] &&
    assignCarrierResponce.errors["truck.trailer_registration_number"][0];

  return {
    truck_registration_number_error,
    trailer_registration_number_error,
  };
};

export default useManageOrderErrors;
