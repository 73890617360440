import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import trucksService from "./trucksService";

const initialState = {
  getTrucksLoading: true,
  getTrucksResponce: null,
  getTrucksError: null,

  getTruckLoading: true,
  getTruckResponce: null,
  getTruckError: null,

  createTruckLoading: false,
  createTruckResponce: null,
  createTruckError: null,

  updateTruckLoading: false,
  updateTruckResponce: null,
  updateTruckError: null,

  deleteTruckLoading: false,
  deleteTruckResponce: null,
  deleteTruckError: null,

  getTruckBrandsLoading: false,
  getTruckBrandsResponce: null,
  getTruckBrandsSelectResponce: null,
  getTruckBrandsError: null,

  manageTruckLoading: false,
  manageTruckResponce: null,
  manageTruckError: null,
};

export const getTrucks = createAsyncThunk(
  "trucks/getTrucks",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await trucksService.getTrucks(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getTruck = createAsyncThunk(
  "trucks/getTruck",
  async (id, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await trucksService.getTruck(ISO, TOKEN, id);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createTruck = createAsyncThunk(
  "trucks/createTruck",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await trucksService.createTruck(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateTruck = createAsyncThunk(
  "trucks/updateTruck",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await trucksService.updateTruck(
        ISO,
        TOKEN,
        data.id.toString(),
        data
      );
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteTruck = createAsyncThunk(
  "trucks/deleteTruck",
  async (id, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await trucksService.deleteTruck(ISO, TOKEN, id);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getTruckBrands = createAsyncThunk(
  "trucks/getTruckBrands",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await trucksService.getTruckBrands(ISO, TOKEN);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const manageTruck = createAsyncThunk(
  "trucks/manageTruck",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await trucksService.manageTruck(ISO, TOKEN, data.truckId, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const trucksSlice = createSlice({
  name: "trucks",
  initialState,
  reducers: {
    resetGetTrucks: (state) => {
      state.getTrucksLoading = true;
      state.getTrucksResponce = null;
      state.getTrucksError = null;
    },
    resetGetTruck: (state) => {
      state.getTruckLoading = true;
      state.getTruckResponce = null;
      state.getTruckError = null;
    },
    resetCreateTruck: (state) => {
      state.createTruckLoading = false;
      state.createTruckResponce = null;
      state.createTruckError = null;
    },
    resetUpdateTruck: (state) => {
      state.updateTruckLoading = false;
      state.updateTruckResponce = null;
      state.updateTruckError = null;
    },
    resetDeleteTruck: (state) => {
      state.deleteTruckLoading = false;
      state.deleteTruckResponce = null;
      state.deleteTruckError = null;
    },
    resetManageTruck: (state) => {
      state.manageTruckLoading = false;
      state.manageTruckResponce = null;
      state.manageTruckError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTrucks.pending, (state) => {
        state.getTrucksLoading = true;
        state.getTrucksResponce = null;
        state.getTrucksError = null;
      })
      .addCase(getTrucks.fulfilled, (state, action) => {
        state.getTrucksLoading = false;
        state.getTrucksResponce = action.payload;
        state.getTrucksError = null;
      })
      .addCase(getTrucks.rejected, (state, action) => {
        state.getTrucksLoading = false;
        state.getTrucksResponce = null;
        state.getTrucksError = action.payload;
      })

      .addCase(getTruck.pending, (state) => {
        state.getTruckLoading = true;
        state.getTruckResponce = null;
        state.getTruckError = null;
      })
      .addCase(getTruck.fulfilled, (state, action) => {
        state.getTruckLoading = false;
        state.getTruckResponce = action.payload;
        state.getTruckError = null;
      })
      .addCase(getTruck.rejected, (state, action) => {
        state.getTruckLoading = false;
        state.getTruckResponce = null;
        state.getTruckError = action.payload;
      })

      .addCase(createTruck.pending, (state) => {
        state.createTruckLoading = true;
        state.createTruckResponce = null;
        state.createTruckError = null;
      })
      .addCase(createTruck.fulfilled, (state, action) => {
        state.createTruckLoading = false;
        state.createTruckResponce = action.payload;
        state.createTruckError = null;
      })
      .addCase(createTruck.rejected, (state, action) => {
        state.createTruckLoading = false;
        state.createTruckResponce = null;
        state.createTruckError = action.payload;
      })

      .addCase(updateTruck.pending, (state) => {
        state.updateTruckLoading = true;
        state.updateTruckResponce = null;
        state.updateTruckError = null;
      })
      .addCase(updateTruck.fulfilled, (state, action) => {
        state.updateTruckLoading = false;
        state.updateTruckResponce = action.payload;
        state.updateTruckError = null;
      })
      .addCase(updateTruck.rejected, (state, action) => {
        state.updateTruckLoading = false;
        state.updateTruckResponce = null;
        state.updateTruckError = action.payload;
      })

      .addCase(deleteTruck.pending, (state) => {
        state.deleteTruckLoading = true;
        state.deleteTruckResponce = null;
        state.deleteTruckError = null;
      })
      .addCase(deleteTruck.fulfilled, (state, action) => {
        state.deleteTruckLoading = false;
        state.deleteTruckResponce = action.payload;
        state.deleteTruckError = null;
      })
      .addCase(deleteTruck.rejected, (state, action) => {
        state.deleteTruckLoading = false;
        state.deleteTruckResponce = null;
        state.deleteTruckError = action.payload;
      })

      .addCase(getTruckBrands.pending, (state) => {
        state.getTruckBrandsLoading = true;
        state.getTruckBrandsResponce = null;
        state.getTruckBrandsSelectResponce = null;
        state.getTruckBrandsError = null;
      })
      .addCase(getTruckBrands.fulfilled, (state, action) => {
        state.getTruckBrandsLoading = false;
        state.getTruckBrandsResponce = action.payload;
        state.getTruckBrandsSelectResponce = action.payload.map(
          (truckModel) => ({
            label: truckModel.name,
            value: {
              ...truckModel,
              models: truckModel.models.map((i) => ({
                label: i.name,
                value: i,
              })),
            },
          })
        );
        state.getTruckBrandsError = null;
      })
      .addCase(getTruckBrands.rejected, (state, action) => {
        state.getTruckBrandsLoading = false;
        state.getTruckBrandsResponce = null;
        state.getTruckBrandsSelectResponce = null;
        state.getTruckBrandsError = action.payload;
      })

      .addCase(manageTruck.pending, (state) => {
        state.manageTruckLoading = true;
        state.manageTruckResponce = null;
        state.manageTruckError = null;
      })
      .addCase(manageTruck.fulfilled, (state, action) => {
        state.manageTruckLoading = false;
        state.manageTruckResponce = action.payload;
        state.manageTruckError = null;
      })
      .addCase(manageTruck.rejected, (state, action) => {
        state.manageTruckLoading = false;
        state.manageTruckResponce = null;
        state.manageTruckError = action.payload;
      });
  },
});

export const {
  resetGetTrucks,
  resetGetTruck,
  resetCreateTruck,
  resetUpdateTruck,
  resetDeleteTruck,
  resetManageTruck,
} = trucksSlice.actions;
export default trucksSlice.reducer;
