import React, { Fragment, useState } from "react";
import "./OwnCarPrice.scss";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IoMdArrowDropright, IoMdArrowDropdown } from "react-icons/io";
import { FiPlus } from "react-icons/fi";
import CircularProgress from "@mui/material/CircularProgress";

import { useMoney } from "./../../../../../helper/useMoney";

import AddNewCostModal from "../Costs/AddNewCostModal/AddNewCostModal";
import ExtraChargesItem from "../Costs/ExtraChargesItem/ExtraChargesItem";
import DTextButton from "../../../../Form/DButtons/DTextButton/DTextButton";

const OwnCarPrice = ({ order }) => {
  const { t } = useTranslation();
  const { getOrderCostsLoading, getOrderCostsResponce } = useSelector(
    (state) => state.orderCosts
  );

  const [showCharges, setShowCharges] = useState(false);
  const [showAddCostModal, setShowAddCostModal] = useState(false);

  const formatedExtraTotalExpence = useMoney(
    order?.extra_total_expense || 0,
    order?.expense?.currency?.symbol || ""
  );

  return (
    <Fragment>
      {order && (
        <Fragment>
          <div id='financialDetailsOwnPrice'>
            <div className='titlePrice'>
              <h2>
                {t("tms.titles.My costs")}:{" "}
                <span>{formatedExtraTotalExpence}</span>
              </h2>
            </div>
            {order?.extra_total_expense > 0 ? (
              <div className='extraChargesContainer'>
                <div className='chargesHeader'>
                  <div className='title'>
                    <div
                      className='icon'
                      onClick={() => setShowCharges((state) => !state)}
                    >
                      {showCharges ? (
                        <IoMdArrowDropdown />
                      ) : (
                        <IoMdArrowDropright />
                      )}
                    </div>
                    <h4>
                      {t("tms.titles.Extra costs")}{" "}
                      <span>{formatedExtraTotalExpence}</span>
                    </h4>
                  </div>
                  <div
                    className='AddButton'
                    onClick={() => setShowAddCostModal(true)}
                  >
                    <FiPlus />
                  </div>
                </div>
                <div
                  className={showCharges ? "chargeslist show" : "chargeslist"}
                >
                  {getOrderCostsLoading ? (
                    <div className='loading'>
                      <CircularProgress size='1rem' color='primary' />
                    </div>
                  ) : (
                    <ul className='list'>
                      {getOrderCostsResponce &&
                        getOrderCostsResponce
                          .filter((i) => i.operation === "EX")
                          .map((item) => (
                            <ExtraChargesItem
                              operation='EX'
                              key={item.id}
                              item={item}
                              orderId={order.id}
                            />
                          ))}
                    </ul>
                  )}
                </div>
              </div>
            ) : (
              <div className='noExtraCharges'>
                <DTextButton
                  title={t("tms.forms.buttons.Add costs")}
                  leftIcon={<FiPlus />}
                  onClick={() => setShowAddCostModal(true)}
                />
              </div>
            )}
          </div>

          {showAddCostModal && (
            <AddNewCostModal
              operation='EX'
              isOpen={showAddCostModal}
              isClose={setShowAddCostModal}
              orderId={order.id}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default OwnCarPrice;
