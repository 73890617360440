import React, { Fragment, useState } from "react";
import "./BookShipment.scss";

import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";

import DButton from "../../../Form/DButtons/DButton/DButton";
import DIconButton from "./../../../Form/DButtons/DIconButton/DIconButton";
import BookModal from "./BookModal/BookModal";

const BookShipment = () => {
  const { t } = useTranslation();
  const [showCreateModal, setShowCreateModal] = useState(false);

  return (
    <Fragment>
      <div id='BookShipment'>
        <div className='BookShipmentLarge'>
          <DButton
            title={t("shipper.buttons.Book a shipment")}
            rightIcon={<FiPlus />}
            onClick={() => setShowCreateModal(true)}
          />
        </div>

        <div className='BookShipmentMobile'>
          <DIconButton icon={<FiPlus />} onClick={() => setShowCreateModal(true)} />
        </div>
      </div>

      {showCreateModal && (
        <BookModal isOpen={showCreateModal} isClose={() => setShowCreateModal(false)} />
      )}
    </Fragment>
  );
};

export default BookShipment;
