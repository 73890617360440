import React from "react";

import { useSelector } from "react-redux";

import DSelect from "../DSelect";

const DPackageTypeSelect = ({
  value,
  setValue,
  error,
  required,
  label,
  placeholder,
  leftIcon,
}) => {
  const { getCargoPackageLoading, getCargoPackageResponceSelect } = useSelector(
    (state) => state.orderSettings
  );

  const filterOption = (option, inputValue) => {
    const { value } = option;
    return value.name.toLowerCase().includes(inputValue.toLowerCase());
  };

  return (
    <DSelect
      id='DCargoTypesSelect'
      label={label ? label : "Package type"}
      placeholder={placeholder ? placeholder : "Select type"}
      required={required}
      value={value}
      setValue={setValue}
      loading={getCargoPackageLoading}
      search={true}
      error={error}
      filterOption={filterOption}
      options={getCargoPackageResponceSelect}
      leftIcon={leftIcon ? leftIcon : null}
    />
  );
};

export default DPackageTypeSelect;
