import React, { Fragment, useState, useEffect } from "react";
import "./TrailerList.scss";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import {
  getTrailers,
  resetGetTrailers,
} from "./../../../../../../store/fleet/trailers/trailersSlice";

import EmptyList from "../../../../../../componentsDashboard/UI/EmptyList/EmptyList";
import Wrapper from "../../../../../../componentsAdditional/Wrapper";
import PageSwitcher from "../../../../../../componentsDashboard/PageSwitcher/PageSwitcher";
import AddTrailersModal from "../../../../../../componentsDashboard/FleetManagement/FleetManagementTrailers/AddTrailersModal/AddTrailersModal";
import TrailersTable from "./../../../../../../componentsDashboard/FleetManagement/FleetManagementTrailers/TrailersTable/TrailersTable";
import LoadingPage from "../../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import TrailersListHeader from "./../../../../../../componentsDashboard/FleetManagement/FleetManagementTrailers/TrailersListHeader/TrailersListHeader";

const TrailerList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getTrailerscountLoading, getTrailerscountResponce } = useSelector(
    (state) => state.summary
  );

  const {
    getTrailersLoading,
    getTrailersResponce,
    createTrailerResponce,
    updateTrailerResponce,
    deleteTrailerResponce,
  } = useSelector((state) => state.trailers);
  const { manageTruckResponce } = useSelector((state) => state.trucks);

  const [openAddModal, setOpenAddModal] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [linkedTo, setLinkedTo] = useState(null);
  const [trailerType, setTrailerType] = useState(null);

  useEffect(() => {
    dispatch(
      getTrailers({
        keyword,
        linked_to: linkedTo?.value,
        type_id: trailerType?.value?.id,
      })
    );
    return () => {
      dispatch(resetGetTrailers());
    };
  }, [
    dispatch,
    createTrailerResponce,
    updateTrailerResponce,
    deleteTrailerResponce,
    manageTruckResponce,
    keyword,
    linkedTo,
    trailerType,
  ]);

  return (
    <Wrapper documentTitle={t("tms.documentTitles.Trailers")}>
      <section id='TrailerList'>
        <PageSwitcher name='trailers' />
        {getTrailerscountLoading && <LoadingPage />}
        {getTrailerscountResponce && (
          <Fragment>
            {getTrailerscountResponce.trailers_count === 0 ? (
              <EmptyList
                title={t("tms.titles.No Trailer")}
                description={t(
                  "tms.desc.Add trailer, make it active and take order whenever you want"
                )}
                buttonText={t("tms.forms.buttons.Add trailer")}
                image='/assets/images/dashboard/cover2.png'
                onClick={() => {
                  setOpenAddModal(true);
                }}
              />
            ) : (
              <Fragment>
                <TrailersListHeader
                  keyword={keyword}
                  setKeyword={setKeyword}
                  linkedTo={linkedTo}
                  setLinkedTo={setLinkedTo}
                  trailerType={trailerType}
                  setTrailerType={setTrailerType}
                  setOpenAddModal={setOpenAddModal}
                />
                {getTrailersLoading && <LoadingPage />}
                {getTrailersResponce && (
                  <TrailersTable trailers={getTrailersResponce} />
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      </section>
      <AddTrailersModal isOpen={openAddModal} isClose={setOpenAddModal} />
    </Wrapper>
  );
};

export default TrailerList;
