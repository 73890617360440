import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { FiTrash2 } from "react-icons/fi";

import {
  deleteContact,
  resetDeleteContact,
} from "./../../../../../../store/user/userContacts/userContactsSlice";

import DModal from "./../../../../../Modal/DModal/DModal";

const AccountantEmailDeleteModal = ({ isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { deleteContactLoading, deleteContactResponce } = useSelector(
    (state) => state.userContacts
  );
  const findAccountant = user?.contacts?.find((contact) => contact?.type?.id === 1) || null;

  useEffect(() => {
    if (deleteContactResponce) {
      dispatch(resetDeleteContact());
      isClose();
    }
  }, [dispatch, isClose, deleteContactResponce]);

  const submitHanlder = () => {
    const data = {
      id: findAccountant?.id || null,
    };
    dispatch(deleteContact(data));
  };

  return (
    <DModal
      status='danger'
      isOpen={isOpen}
      isClose={isClose}
      icon={<FiTrash2 />}
      title={t("shipper.modal.Delete accountant email")}
      submitText={t("shipper.buttons.Delete")}
      onClick={() => submitHanlder()}
      loading={deleteContactLoading}
    >
      <p>{t("shipper.modal.Are you sure you want to delete?")}</p>
    </DModal>
  );
};

export default AccountantEmailDeleteModal;
