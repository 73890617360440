import React from "react";
import "./SignUpCarrierSuccess.scss";

import { useTranslation } from "react-i18next";

import Wrapper from "./../../../../componentsAdditional/Wrapper";
import AuthCover from "./../../../../componentsLanding/Auth&Password/Components/AuthCover/AuthCover";
import SignUpCarrierSuccessForm from "./../../../../componentsLanding/Auth&Password/SignUpForms/SignUpCarrierSuccessForm/SignUpCarrierSuccessForm";

const SignUpCarrierSuccess = () => {
  const { t } = useTranslation();

  return (
    <Wrapper documentTitle={t("landing.documentTitles.Sign Up carrier")}>
      <section id='SignUpCarrierSuccess'>
        <AuthCover url='/assets/images/static/cover4.jpg' />
        <SignUpCarrierSuccessForm />
      </section>
    </Wrapper>
  );
};

export default SignUpCarrierSuccess;
