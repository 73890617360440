import React, { Fragment, useEffect, useState } from "react";
import "./RejectOrderList.scss";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  getRejectOrders,
  resetGetRejectOrders,
} from "../../../../../../store/orders/shipperOrders/shipperOrdersSlice";

import Wrapper from "../../../../../../componentsAdditional/Wrapper";
import PageSwitcher from "../../../../../../componentsDashboard/PageSwitcher/PageSwitcher";
import ShipperOrderListHeader from "../../../../../../componentsDashboard/ShipperOrderList/ShipperOrderListHeader/ShipperOrderListHeader";
import LoadingPage from "../../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import EmptyList from "../../../../../../componentsDashboard/UI/EmptyList/EmptyList";
import ShipperRejectOrdersTable from "../../../../../../componentsDashboard/ShipperOrderList/ShipperRejectOrdersTable";
import MainTitle from "../../../../../../componentsDashboard/MainTitle/MainTitle";
import BookModal from "../../../../../../componentsDashboard/ShipperHeader/Components/BookShipment/BookModal/BookModal";

const RejectOrderList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { rejectOrdersLoading, rejectOrders, rejectOrdersMeta } = useSelector(
    (state) => state.shipperOrders
  );

  const {
    getShipperActiveOrdersSummaryResponce,
    getShipperRejectOrdersSummaryLoading,
    getShipperRejectOrdersSummaryResponce,
  } = useSelector((state) => state.summary);

  const [showCreateModal, setShowCreateModal] = useState(false);

  const [page, setPage] = useState(searchParams.get("page") || 1);
  const [keyword, setKeyword] = useState("");
  const [shippingType, setShippingType] = useState(null);
  const [dateRange, setDateRange] = useState({});
  const [route, setRoute] = useState({});
  const [trailerType, setTrailerType] = useState(null);
  const [productCategory, setProductCategory] = useState(null);

  const clearFilters = () => {
    if (keyword) setKeyword("");
    if (shippingType) setShippingType(null);
    if (Object.keys(dateRange).length > 0) setDateRange({});
    if (Object.keys(route).length > 0) setRoute({});
    if (trailerType) setTrailerType(null);
    if (productCategory) setProductCategory(null);
  };

  useEffect(() => {
    const data = {
      page,
      keyword,
      trailer_type_id: trailerType?.value?.id,
      start_date:
        Object.keys(dateRange).length > 0 && dateRange?.startDate
          ? moment(dateRange.startDate).format("DD/MM/YYYY")
          : "",
      end_date:
        Object.keys(dateRange).length > 0 && dateRange?.endDate
          ? moment(dateRange.endDate).format("DD/MM/YYYY")
          : "",
      pickup_address: route?.fromCountry?.label,
      dropoff_address: route?.toCountry?.label,
      cargo_type_id: productCategory?.value?.id,
      type_ids: shippingType,
    };
    dispatch(getRejectOrders(data));
    return () => {
      dispatch(resetGetRejectOrders());
    };
  }, [
    dispatch,
    dateRange,
    keyword,
    page,
    trailerType,
    productCategory,
    route,
    shippingType,
  ]);

  return (
    <Wrapper documentTitle={t("shipper.titles.Declined")}>
      <section id='RejectOrderList'>
        <MainTitle title={t("shipper.titles.My bookings")} />
        <PageSwitcher
          name={"shipperOrdersReject"}
          badge={getShipperActiveOrdersSummaryResponce}
        />
        {getShipperRejectOrdersSummaryLoading ? (
          <LoadingPage />
        ) : getShipperRejectOrdersSummaryResponce === 0 ? (
          <EmptyList
            image={"/assets/images/dashboard/cover1.svg"}
            title={t("shipper.titles.No bookings")}
            description={t(
              "shipper.desc.Add booking and take full control of your orders"
            )}
          />
        ) : (
          <Fragment>
            <ShipperOrderListHeader
              keyword={keyword}
              setKeyword={setKeyword}
              shippingType={shippingType}
              setShippingType={setShippingType}
              dateRange={dateRange}
              setDateRange={setDateRange}
              route={route}
              setRoute={setRoute}
              trailerType={trailerType}
              setTrailerType={setTrailerType}
              productCategory={productCategory}
              setProductCategory={setProductCategory}
              onClear={clearFilters}
            />
            {rejectOrdersLoading && <LoadingPage />}
            {rejectOrders && (
              <ShipperRejectOrdersTable
                orders={rejectOrders}
                page={page}
                setPage={setPage}
                pageCount={rejectOrdersMeta.last_page}
                total={rejectOrdersMeta.total}
              />
            )}
          </Fragment>
        )}
        {showCreateModal && (
          <BookModal
            isOpen={showCreateModal}
            isClose={() => setShowCreateModal(false)}
          />
        )}
      </section>
    </Wrapper>
  );
};

export default RejectOrderList;
