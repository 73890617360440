import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import maintenanceService from "./maintenanceService";

const initialState = {
  getMaintenancesLoading: true,
  getMaintenancesResponce: null,
  getMaintenancesError: null,

  getTruckMaintenancesLoading: true,
  getTruckMaintenancesResponce: null,
  getTruckMaintenancesError: null,

  getTrailerMaintenancesLoading: true,
  getTrailerMaintenancesResponce: null,
  getTrailerMaintenancesError: null,

  getMaintenanceLoading: true,
  getMaintenanceResponce: null,
  getMaintenanceError: null,

  createMaintenanceLoading: false,
  createMaintenanceResponce: null,
  createMaintenanceError: null,

  updateMaintenanceLoading: false,
  updateMaintenanceResponce: null,
  updateMaintenanceError: null,

  deleteMaintenanceLoading: false,
  deleteMaintenanceResponce: null,
  deleteMaintenanceError: null,
};

export const getMaintenances = createAsyncThunk(
  "maintenance/getMaintenances",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await maintenanceService.getMaintenances(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getTruckMaintenances = createAsyncThunk(
  "maintenance/getTruckMaintenances",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await maintenanceService.getTruckMaintenances(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getTrailerMaintenances = createAsyncThunk(
  "maintenance/getTrailerMaintenances",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await maintenanceService.getTrailerMaintenances(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getMaintenance = createAsyncThunk(
  "maintenance/getMaintenance",
  async (id, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await maintenanceService.getMaintenance(ISO, TOKEN, id);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createMaintenance = createAsyncThunk(
  "maintenance/createMaintenance",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await maintenanceService.createMaintenance(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateMaintenance = createAsyncThunk(
  "maintenance/updateMaintenance",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await maintenanceService.updateMaintenance(
        ISO,
        TOKEN,
        data.id.toString(),
        data
      );
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteMaintenance = createAsyncThunk(
  "maintenance/deleteMaintenance",
  async (id, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await maintenanceService.deleteMaintenance(ISO, TOKEN, id);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const maintenancesSlice = createSlice({
  name: "maintenances",
  initialState,
  reducers: {
    resetGetMaintenances: (state) => {
      state.getMaintenancesLoading = false;
      state.getMaintenancesResponce = null;
      state.getMaintenancesError = null;
    },
    resetCreateMaintenance: (state) => {
      state.createMaintenanceLoading = false;
      state.createMaintenanceResponce = null;
      state.createMaintenanceError = null;
    },
    resetUpdateMaintenance: (state) => {
      state.updateMaintenanceLoading = false;
      state.updateMaintenanceResponce = null;
      state.updateMaintenanceError = null;
    },
    resetDeleteMaintenance: (state) => {
      state.deleteMaintenanceLoading = false;
      state.deleteMaintenanceResponce = null;
      state.deleteMaintenanceError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMaintenances.pending, (state) => {
        state.getMaintenancesLoading = true;
        state.getMaintenancesResponce = null;
        state.getMaintenancesError = null;
      })
      .addCase(getMaintenances.fulfilled, (state, action) => {
        state.getMaintenancesLoading = false;
        state.getMaintenancesResponce = action.payload;
        state.getMaintenancesError = null;
      })
      .addCase(getMaintenances.rejected, (state, action) => {
        state.getMaintenancesLoading = false;
        state.getMaintenancesResponce = null;
        state.getMaintenancesError = action.payload;
      })

      .addCase(getTruckMaintenances.pending, (state) => {
        state.getTruckMaintenancesLoading = true;
        state.getTruckMaintenancesResponce = null;
        state.getTruckMaintenancesError = null;
      })
      .addCase(getTruckMaintenances.fulfilled, (state, action) => {
        state.getTruckMaintenancesLoading = false;
        state.getTruckMaintenancesResponce = action.payload;
        state.getTruckMaintenancesError = null;
      })
      .addCase(getTruckMaintenances.rejected, (state, action) => {
        state.getTruckMaintenancesLoading = false;
        state.getTruckMaintenancesResponce = null;
        state.getTruckMaintenancesError = action.payload;
      })

      .addCase(getTrailerMaintenances.pending, (state) => {
        state.getTrailerMaintenancesLoading = true;
        state.getTrailerMaintenancesResponce = null;
        state.getTrailerMaintenancesError = null;
      })
      .addCase(getTrailerMaintenances.fulfilled, (state, action) => {
        state.getTrailerMaintenancesLoading = false;
        state.getTrailerMaintenancesResponce = action.payload;
        state.getTrailerMaintenancesError = null;
      })
      .addCase(getTrailerMaintenances.rejected, (state, action) => {
        state.getTrailerMaintenancesLoading = false;
        state.getTrailerMaintenancesResponce = null;
        state.getTrailerMaintenancesError = action.payload;
      })

      .addCase(getMaintenance.pending, (state) => {
        state.getMaintenanceLoading = true;
        state.getMaintenanceResponce = null;
        state.getMaintenanceError = null;
      })
      .addCase(getMaintenance.fulfilled, (state, action) => {
        state.getMaintenanceLoading = false;
        state.getMaintenanceResponce = action.payload;
        state.getMaintenanceError = null;
      })
      .addCase(getMaintenance.rejected, (state, action) => {
        state.getMaintenanceLoading = false;
        state.getMaintenanceResponce = null;
        state.getMaintenanceError = action.payload;
      })

      .addCase(createMaintenance.pending, (state) => {
        state.createMaintenanceLoading = true;
        state.createMaintenanceResponce = null;
        state.createMaintenanceError = null;
      })
      .addCase(createMaintenance.fulfilled, (state, action) => {
        state.createMaintenanceLoading = false;
        state.createMaintenanceResponce = action.payload;
        state.createMaintenanceError = null;
      })
      .addCase(createMaintenance.rejected, (state, action) => {
        state.createMaintenanceLoading = false;
        state.createMaintenanceResponce = null;
        state.createMaintenanceError = action.payload;
      })

      .addCase(updateMaintenance.pending, (state) => {
        state.updateMaintenanceLoading = true;
        state.updateMaintenanceResponce = null;
        state.updateMaintenanceError = null;
      })
      .addCase(updateMaintenance.fulfilled, (state, action) => {
        state.updateMaintenanceLoading = false;
        state.updateMaintenanceResponce = action.payload;
        state.updateMaintenanceError = null;
      })
      .addCase(updateMaintenance.rejected, (state, action) => {
        state.updateMaintenanceLoading = false;
        state.updateMaintenanceResponce = null;
        state.updateMaintenanceError = action.payload;
      })

      .addCase(deleteMaintenance.pending, (state) => {
        state.deleteMaintenanceLoading = true;
        state.deleteMaintenanceResponce = null;
        state.deleteMaintenanceError = null;
      })
      .addCase(deleteMaintenance.fulfilled, (state, action) => {
        state.deleteMaintenanceLoading = false;
        state.deleteMaintenanceResponce = action.payload;
        state.deleteMaintenanceError = null;
      })
      .addCase(deleteMaintenance.rejected, (state, action) => {
        state.deleteMaintenanceLoading = false;
        state.deleteMaintenanceResponce = null;
        state.deleteMaintenanceError = action.payload;
      });
  },
});

export const {
  resetGetMaintenances,
  resetCreateMaintenance,
  resetUpdateMaintenance,
  resetDeleteMaintenance,
} = maintenancesSlice.actions;
export default maintenancesSlice.reducer;
