import React, { Fragment, useState, useEffect } from "react";
import "./EditParticipantContactModal.scss";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { FiPlus } from "react-icons/fi";

import {
  updateParticipantContact,
  resetUpdateParticipantContact,
} from "../../../../store/participants/participantContacts/participantContactsSlice";
import { useParticipantContactErrors } from "./../../../../validations/participant/useParticipantContactErrors";

import DModal from "../../../Modal/DModal/DModal";
import DInputs from "./../../../Form/DInputs/DInputs";

const EditParticipantContactModal = ({
  participantId,
  contactId,
  isOpen,
  isClose,
  name,
  posit,
  mail,
  tel,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { updateParticipantContactLoading, updateParticipantContactResponce } =
    useSelector((state) => state.participantContacts);

  const {
    update_name_error,
    update_email_error,
    update_phone_error,
    update_position_error,
  } = useParticipantContactErrors();

  const [fullname, setFullname] = useState(name ? name : "");
  const [position, setPosition] = useState(posit ? posit : "");
  const [email, setEmail] = useState(mail ? mail : "");
  const [phone, setPhone] = useState(tel ? tel : "");

  const submitHandler = () => {
    const data = {
      participantId,
      contactId,
      name: fullname,
      email: email,
      phone: phone,
      position: position,
    };
    dispatch(updateParticipantContact(data));
  };

  useEffect(() => {
    if (updateParticipantContactResponce) {
      dispatch(resetUpdateParticipantContact());
      isClose();
    }
  }, [dispatch, isClose, updateParticipantContactResponce]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose()}
        onClick={() => submitHandler()}
        submitText={t("tms.modals.buttons.Save")}
        title={t("tms.modals.titles.Edit contact")}
        status='success'
        icon={<FiPlus />}
        loading={updateParticipantContactLoading}
      >
        <div id='EditParticipantContactModal'>
          <div className='group'>
            <DInputs
              value={fullname}
              setValue={setFullname}
              placeholder={t("tms.modals.placeholder.Enter full name")}
              label={t("tms.modals.labels.Full name")}
              required
              id='fullname'
              error={update_name_error}
            />
            <DInputs
              value={position}
              setValue={setPosition}
              placeholder={t("tms.modals.placeholder.Enter position")}
              label={t("tms.modals.labels.Position")}
              id='position'
              error={update_position_error}
            />
            <DInputs
              value={email}
              setValue={setEmail}
              placeholder={t("tms.modals.placeholder.Enter email")}
              label={t("tms.modals.labels.Email")}
              id='Email'
              error={update_email_error}
            />
            <DInputs
              value={phone}
              setValue={setPhone}
              placeholder={t("tms.modals.placeholder.Enter phone number")}
              label={t("tms.modals.labels.Phone number")}
              id='Phone '
              error={update_phone_error}
            />
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default EditParticipantContactModal;
