import React, { Fragment, useState, useEffect } from "react";
import "./EditPartnerModal.scss";

import { useTranslation } from "react-i18next";
import { FiEdit2, FiMinus, FiPlus } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";

import {
  updatePartner,
  resetUpdatePartner,
} from "../../../../store/participants/partner/partnerSlice";
import { usePartnerErrors } from "./../../../../validations/participant/partner/usePartnerErrors";

import DModal from "../../../Modal/DModal/DModal";
import DInputs from "../../../Form/DInputs/DInputs";
import DPhoneInput from "./../../../Form/DPhoneInput/DPhoneInput";
import SelectableButton from "../../Components/SelectableButton/SelectableButton";
import AddOtherRouteSelect from "../Components/AddOtherRouteSelect/AddOtherRouteSelect";
import DTextButton from "../../../Form/DButtons/DTextButton/DTextButton";

const EditPartnerModal = ({ participant, isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { updatePartnerLoading, updatePartnerResponce } = useSelector(
    (state) => state.partner
  );
  const { phoneIndexValueId, phoneIndexes } = useSelector(
    (state) => state.settings
  );
  const { getTrailerTypesResponce } = useSelector((state) => state.trailers);
  const { getRoutesListResponce } = useSelector((state) => state.routes);
  const {
    update_company_legal_id_error,
    update_company_name_error,
    update_company_email_error,
    update_company_phone_error,
    update_company_phone2_error,
    update_company_phone_index_id_error,
    update_company_phone2_index_id_error,
  } = usePartnerErrors();

  const indexesOptions =
    phoneIndexes &&
    phoneIndexes.map((item) => ({
      value: item,
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: "15px",
              height: "15px",
              backgroundImage: `url(${item.flag})`,
              backgroundPosition: "cover",
              backgroundRepeat: "no-repeat",
              marginRight: "5px",
            }}
          ></div>
          <p>{item.phone_index}</p>
        </div>
      ),
    }));

  const phoneIndexValueObj = (phoneIndexValueId) =>
    indexesOptions &&
    indexesOptions.find((item) => item.value.id === phoneIndexValueId);

  const [showSecondaryPhone, setShowSecondaryPhone] = useState(
    participant?.company?.phone2 ? true : false
  );
  const [showAddOtherRoute, setShowAddOtherRoute] = useState(false);

  const [customerID, setCustomerID] = useState(
    participant.company.legal_id ? participant.company.legal_id : ""
  );
  const [customerName, setCustomerName] = useState(
    participant.company.name ? participant.company.name : ""
  );
  const [email, setEmail] = useState(
    participant.company.email ? participant.company.email : ""
  );
  const [phone, setPhone] = useState(
    participant.company.phone ? participant.company.phone : ""
  );
  const [secondaryPhone, setSecondaryPhone] = useState(
    participant?.company?.phone2 || ""
  );
  const [phoneIndexValue, setPhoneIndexValue] = useState(
    phoneIndexValueObj(
      participant?.company?.phone_index?.id || phoneIndexValueId
    )
  );
  const [secondaryPhoneIndexValue, setSecondaryPhoneIndexValue] = useState(
    phoneIndexValueObj(
      participant?.company?.phone2_index?.id || phoneIndexValueId
    )
  );
  const [selectedTrailerTypes, setSelectedTrailerTypes] = useState(
    participant?.trailerTypes?.map((item) => item?.id) || []
  );
  const [selectedRoutes, setSelectedRoutes] = useState(
    participant?.routeTypes?.map((item) => item?.id) || []
  );

  const [prefferedRoutes, setPrefferedRoutes] = useState([]);

  const submitHandler = () => {
    const data = {
      participantId: participant.id,
      type: "Partner",
      trailer_types: selectedTrailerTypes,
      route_types: selectedRoutes,
      company: {
        legal_id: customerID,
        name: customerName,
        email: email,
        phone: phone,
        phone2: secondaryPhone || null,
        phone_index_id: phoneIndexValue?.value?.id,
        phone2_index_id: secondaryPhoneIndexValue?.value?.id || null,
      },
    };
    dispatch(updatePartner(data));
  };

  // Need to be set in state for rerendering after array changes
  useEffect(() => {
    if (getRoutesListResponce) {
      const featuredRoutes = getRoutesListResponce
        ?.filter((route) => route?.featured)
        ?.map((item) => ({
          name: item?.title,
          id: item?.id,
          featured: item?.featured,
        }));

      const notFeaturedRouteIds = selectedRoutes?.filter((item) => {
        return !featuredRoutes?.find((route) => route?.id === item);
      });

      const notFeaturedRoutes = getRoutesListResponce
        ?.filter((item) => {
          return notFeaturedRouteIds?.find((route) => route === item?.id);
        })
        ?.map((item) => ({
          name: item?.title,
          id: item?.id,
          featured: item?.featured,
        }));

      const routes = [...featuredRoutes, ...notFeaturedRoutes];

      setPrefferedRoutes(routes);
    }
  }, [getRoutesListResponce, selectedRoutes]);

  useEffect(() => {
    if (updatePartnerResponce) {
      dispatch(resetUpdatePartner());
      isClose();
    }
  }, [dispatch, updatePartnerResponce, isClose]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={isClose}
        title={t("tms.modals.titles.Edit Partner")}
        icon={<FiEdit2 />}
        status='success'
        onClick={() => submitHandler()}
        submitText={t("tms.modals.buttons.Save")}
        loading={updatePartnerLoading}
      >
        <div id='EditPartnerModal'>
          <div className='EditPartnerModalgroup'>
            <DInputs
              value={customerName}
              setValue={setCustomerName}
              placeholder={t("tms.modals.placeholder.Enter partner name")}
              label={t("tms.modals.labels.Partner name")}
              required
              id='partnerNamepartner'
              error={update_company_name_error}
            />
            <DInputs
              value={customerID}
              setValue={setCustomerID}
              placeholder={t("tms.modals.placeholder.Enter partner ID")}
              label={t("tms.modals.labels.Partner ID")}
              required
              id='partnerIDIDpartner'
              error={update_company_legal_id_error}
            />
            <DPhoneInput
              id='PartnerPhone'
              required
              value={phone}
              setValue={setPhone}
              indexValue={phoneIndexValue}
              setIndexValue={setPhoneIndexValue}
              placeholder={t("tms.modals.placeholder.Enter phone number")}
              label={t("tms.modals.labels.Phone number")}
              status={t("tms.modals.labels.Primary")}
              error={
                update_company_phone_error ||
                update_company_phone_index_id_error
              }
            />
            {!showSecondaryPhone ? (
              <div className='showSecondaryPhoneButtonContainer'>
                <div
                  className='showSecondaryPhoneButton'
                  onClick={() => setShowSecondaryPhone(true)}
                >
                  <div className='icon'>
                    <FiPlus />
                  </div>
                  <div className='text'>
                    {t("tms.modals.buttons.Add secondary phone number")}
                  </div>
                </div>
              </div>
            ) : (
              <Fragment>
                <DPhoneInput
                  id='PartnerPhoneSecondary'
                  value={secondaryPhone}
                  setValue={setSecondaryPhone}
                  indexValue={secondaryPhoneIndexValue}
                  setIndexValue={setSecondaryPhoneIndexValue}
                  placeholder={t("tms.modals.placeholder.Enter phone number")}
                  label={t("tms.modals.labels.Phone number")}
                  status={t("tms.modals.labels.Secondary")}
                  error={
                    update_company_phone2_error ||
                    update_company_phone2_index_id_error
                  }
                />
                <div className='hideSecondaryPhoneButtonContainer'>
                  <div
                    className='hideSecondaryPhoneButton'
                    onClick={() => setShowSecondaryPhone(false)}
                  >
                    <div className='icon'>
                      <FiMinus />
                    </div>
                    <div className='text'>
                      {t("tms.modals.buttons.Remove secondary phone number")}
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
            <DInputs
              value={email}
              setValue={setEmail}
              placeholder={t("tms.modals.placeholder.Enter email")}
              label={t("tms.modals.labels.Email")}
              id='PartnerEmail'
              error={update_company_email_error}
            />
            <div className='prefferedTrailerTypesContainer'>
              <div className='prefferedTrailerTypesTitle'>
                {t("tms.modals.titles.Preffered trailer types")}
              </div>
              <div className='prefferedTrailerTypesList'>
                {getTrailerTypesResponce?.map((prefferedTrilerType) => (
                  <SelectableButton
                    key={prefferedTrilerType?.id}
                    item={prefferedTrilerType}
                    selectedTypes={selectedTrailerTypes}
                    setSelectedTypes={setSelectedTrailerTypes}
                    selected={selectedTrailerTypes?.includes(
                      prefferedTrilerType?.id
                    )}
                  />
                ))}
              </div>
            </div>
            <div className='prefferedRoutesContainer'>
              <div className='prefferedRoutesTitle'>
                {t("tms.modals.titles.Preffered routes")}
              </div>
              <div className='prefferedRoutesList'>
                {prefferedRoutes?.map((prefferedRoute) => (
                  <SelectableButton
                    key={prefferedRoute?.id}
                    item={prefferedRoute}
                    selectedTypes={selectedRoutes}
                    setSelectedTypes={setSelectedRoutes}
                    setTypeList={setPrefferedRoutes}
                    removable={!prefferedRoute?.featured}
                    selected={selectedRoutes?.includes(prefferedRoute?.id)}
                  />
                ))}
                <div style={{ display: showAddOtherRoute ? "none" : "block" }}>
                  <DTextButton
                    title={t("tms.modals.buttons.Add other")}
                    leftIcon={<FiPlus />}
                    onClick={() => setShowAddOtherRoute(true)}
                  />
                </div>
                <div style={{ display: showAddOtherRoute ? "block" : "none" }}>
                  <AddOtherRouteSelect
                    routesList={getRoutesListResponce}
                    isFocused={showAddOtherRoute}
                    onChange={(route) => {
                      setShowAddOtherRoute(false);
                      setPrefferedRoutes([...prefferedRoutes, route]);
                      setSelectedRoutes([...selectedRoutes, route?.id]);
                    }}
                    selectedRoutes={selectedRoutes}
                    onBlur={() => setShowAddOtherRoute(false)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default EditPartnerModal;
