import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  setCurrency,
  resetSetCurrency,
} from "./../../../../store/additional/settings/settingsSlice";

import DSelect from "../DSelect";

const DCurrencySelect = ({ width }) => {
  const dispatch = useDispatch();
  const {
    currenciesLoading,
    currenciesSelect,
    setCurrencyLoading,
    setCurrencyResponce,
  } = useSelector((state) => state.settings);

  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);

  const curretCurrency =
    currenciesSelect &&
    currenciesSelect.find((currency) => currency?.value?.id === user?.currency?.id);

  const submitHandler = (item) => {
    const data = {
      currency_id: item.value.id,
    };
    dispatch(setCurrency(data));
  };

  useEffect(() => {
    if (setCurrencyResponce) {
      setLoading(true);
      dispatch(resetSetCurrency());
      window.location.reload(false);
    }
  }, [dispatch, setCurrencyResponce]);

  return (
    <div style={{ width: width }} id='DCurrencySelectContainer'>
      <DSelect
        id='DCurrencySelect'
        value={curretCurrency}
        setValue={(newCurrency) => {
          submitHandler(newCurrency);
        }}
        loading={currenciesLoading || setCurrencyLoading || loading}
        options={currenciesSelect}
      />
    </div>
  );
};

export default DCurrencySelect;
