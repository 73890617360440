import _request from "./../../_request";

const getTrailers = async (ISO, TOKEN, data) => {
  let url = "trailer?";

  const { linked_to, keyword, type_id } = data || {};

  url += keyword ? `&keyword=${keyword}` : "";
  url += linked_to ? `&linked_to=${linked_to}` : "";
  url += type_id ? `&type_id=${type_id}` : "";

  const config = {
    url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getTrailer = async (ISO, TOKEN, id) => {
  let url = `trailer/${id}`;
  const config = {
    url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const createTrailer = async (ISO, TOKEN, data) => {
  const config = {
    url: "trailer",
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const updateTrailer = async (ISO, TOKEN, id, data) => {
  const config = {
    url: `trailer/${id}`,
    method: "put",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const deleteTrailer = async (ISO, TOKEN, id) => {
  const config = {
    url: `trailer/${id}`,
    method: "delete",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getTrailerTypes = async (ISO, TOKEN, branchId) => {
  let url = `trailer-types/${branchId}`;
  const config = {
    url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getCrmTrailerTypes = async (ISO) => {
  let url = "/auth/trailer-types";
  const config = {
    url,
    ISO,
  };
  return _request(config);
};

const trailersService = {
  getTrailers,
  getTrailer,
  createTrailer,
  updateTrailer,
  deleteTrailer,
  getTrailerTypes,
  getCrmTrailerTypes,
};

export default trailersService;
