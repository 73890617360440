import React, { useState, useEffect, useMemo } from "react";
import "./TrackingShipperMapModal.scss";

import {
  GoogleMap,
  useJsApiLoader,
  PolylineF,
  //   DirectionsService,
  //   DirectionsRenderer,
} from "@react-google-maps/api";

import { GOOGLE_KEY } from "./../../../../constants/GOOGLE_KEY";
import { settings } from "./mapSettings";
import { useAverageGeolocation } from "../../../../helper/useAverageGeolocation";
import { useGetZoomCoords } from "./../../../../helper/useGetZoomCoords";

import Overlay from "./../../../../componentsLanding/Popup/Overlay/Overlay";
import LoadingPage from "./../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import TrackingShipperHeader from "./TrackingShipperHeader/TrackingShipperHeader";
import MarkerA from "./Markers/MarkerA";
import MarkerB from "./Markers/MarkerB";

const TrackingShipperMapModal = ({ isOpen, isClose, order }) => {
  const [zoom, setZoom] = useState(2);
  const [showPolyline, setShowPolyline] = useState(false);

  const [center, setCenter] = useState({ lat: 19.487895, lng: 73.142628 });
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_KEY.GOOGLE_API_KEY,
  });

  const filterA = order?.cargos[0]?.locations.filter(
    (i) => i.direction === "A"
  );
  const filterB = order?.cargos[0]?.locations.filter(
    (i) => i.direction === "B"
  );
  const allOnlyCoords = useMemo(
    () =>
      order.cargos[0].locations.map((i) => ({
        lat: i.coordinate.coordinates[1],
        lng: i.coordinate.coordinates[0],
      })),
    [order.cargos]
  );

  const centerValue = useAverageGeolocation(allOnlyCoords);
  const zoomValue = useGetZoomCoords(allOnlyCoords);

  useEffect(() => {
    setTimeout(() => {
      if (
        zoom !== zoomValue ||
        center.lat !== centerValue.lat ||
        center.lng !== centerValue.lng
      ) {
        setCenter(centerValue);
        setZoom(zoomValue);
      }
    }, 1500);
    setTimeout(() => {
      setShowPolyline(true);
    }, 2000);
  }, [zoomValue, centerValue, zoom, center]);

  return (
    <Overlay isOpen={isOpen} isClose={isClose}>
      <div id='TrackingShipperMapModal'>
        <TrackingShipperHeader order={order} isClose={isClose} />
        <div className='TrackingShipperMap'>
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={settings.containerStyle}
              center={center ? center : { lat: 42.34617, lng: 42.168365 }}
              zoom={zoom}
            >
              {showPolyline && (
                <PolylineF
                  path={allOnlyCoords}
                  animation={2}
                  options={{
                    strokeColor: "#4899ff",
                    strokeOpacity: 0.9,
                    strokeWeight: 4,
                    geodesic: true,
                  }}
                />
              )}
              {filterA
                .filter((item) => item.location !== null)
                .map((item) => (
                  <MarkerA key={item.id} item={item} />
                ))}
              {filterB
                .filter((item) => item.location !== null)
                .map((item) => (
                  <MarkerB key={item.id} item={item} />
                ))}
            </GoogleMap>
          ) : (
            <LoadingPage />
          )}
        </div>
      </div>
    </Overlay>
  );
};

export default TrackingShipperMapModal;
