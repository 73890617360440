import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import participantContactsService from "./participantContactsService";

const initialState = {
  getParticipantContactsLoading: false,
  getParticipantContactsResponce: null,
  getParticipantContactsError: null,

  createParticipantContactLoading: false,
  createParticipantContactResponce: null,
  createParticipantContactError: null,

  updateParticipantContactLoading: false,
  updateParticipantContactResponce: null,
  updateParticipantContactError: null,

  deleteParticipantContactLoading: false,
  deleteParticipantContactResponce: null,
  deleteParticipantContactError: null,
};

export const getParticipantContacts = createAsyncThunk(
  "participantContacts/getParticipantContacts",
  async (participantId, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await participantContactsService.getParticipantContacts(
        ISO,
        TOKEN,
        participantId
      );
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createParticipantContact = createAsyncThunk(
  "participantContacts/createParticipantContact",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await participantContactsService.createParticipantContact(
        ISO,
        TOKEN,
        data.participantId,
        data
      );
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateParticipantContact = createAsyncThunk(
  "participantContacts/updateParticipantContact",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await participantContactsService.updateParticipantContact(
        ISO,
        TOKEN,
        data.participantId,
        data.contactId,
        data
      );
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteParticipantContact = createAsyncThunk(
  "participantContacts/deleteParticipantContact",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await participantContactsService.deleteParticipantContact(
        ISO,
        TOKEN,
        data.participantId,
        data.contactId
      );
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const participantContactsSlice = createSlice({
  name: "participantContacts",
  initialState,
  reducers: {
    resetCreateParticipantContact: (state) => {
      state.createParticipantContactLoading = false;
      state.createParticipantContactResponce = null;
      state.createParticipantContactError = null;
    },
    resetUpdateParticipantContact: (state) => {
      state.updateParticipantContactLoading = false;
      state.updateParticipantContactResponce = null;
      state.updateParticipantContactError = null;
    },
    resetDeleteParticipantContact: (state) => {
      state.deleteParticipantContactLoading = false;
      state.deleteParticipantContactResponce = null;
      state.deleteParticipantContactError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getParticipantContacts.pending, (state) => {
        state.getParticipantContactsLoading = true;
        state.getParticipantContactsResponce = null;
        state.getParticipantContactsError = null;
      })
      .addCase(getParticipantContacts.fulfilled, (state, action) => {
        state.getParticipantContactsLoading = false;
        state.getParticipantContactsResponce = action.payload;
        state.getParticipantContactsError = null;
      })
      .addCase(getParticipantContacts.rejected, (state, action) => {
        state.getParticipantContactsLoading = false;
        state.getParticipantContactsResponce = null;
        state.getParticipantContactsError = action.payload;
      })

      .addCase(createParticipantContact.pending, (state) => {
        state.createParticipantContactLoading = true;
        state.createParticipantContactResponce = null;
        state.createParticipantContactError = null;
      })
      .addCase(createParticipantContact.fulfilled, (state, action) => {
        state.createParticipantContactLoading = false;
        state.createParticipantContactResponce = action.payload;
        state.createParticipantContactError = null;
      })
      .addCase(createParticipantContact.rejected, (state, action) => {
        state.createParticipantContactLoading = false;
        state.createParticipantContactResponce = null;
        state.createParticipantContactError = action.payload;
      })

      .addCase(updateParticipantContact.pending, (state) => {
        state.updateParticipantContactLoading = true;
        state.createParticipantContactResponce = null;
        state.updateParticipantContactError = null;
      })
      .addCase(updateParticipantContact.fulfilled, (state, action) => {
        state.updateParticipantContactLoading = false;
        state.createParticipantContactResponce = action.payload;
        state.updateParticipantContactError = null;
      })
      .addCase(updateParticipantContact.rejected, (state, action) => {
        state.updateParticipantContactLoading = false;
        state.createParticipantContactResponce = null;
        state.updateParticipantContactError = action.payload;
      })

      .addCase(deleteParticipantContact.pending, (state) => {
        state.createParticipantContactLoading = true;
        state.deleteParticipantContactResponce = null;
        state.deleteParticipantContactError = null;
      })
      .addCase(deleteParticipantContact.fulfilled, (state, action) => {
        state.createParticipantContactLoading = false;
        state.deleteParticipantContactResponce = action.payload;
        state.deleteParticipantContactError = null;
      })
      .addCase(deleteParticipantContact.rejected, (state, action) => {
        state.createParticipantContactLoading = false;
        state.deleteParticipantContactResponce = null;
        state.deleteParticipantContactError = action.payload;
      });
  },
});

export const {
  resetCreateParticipantContact,
  resetUpdateParticipantContact,
  resetDeleteParticipantContact,
} = participantContactsSlice.actions;
export default participantContactsSlice.reducer;
