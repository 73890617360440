import React, { Fragment, useState } from "react";
import "./FavoriteRoutesButton.scss";

import { useTranslation } from "react-i18next";

import { FaRegHeart } from "react-icons/fa6";
import { LuPanelLeftClose } from "react-icons/lu";

import FavoriteRoutesModal from "../FavoriteRoutesModal/FavoriteRoutesModal";

const FavoriteRoutesButton = () => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  return (
    <Fragment>
      <div id='FavoriteRoutesButton' onClick={() => setOpenModal(true)}>
        <div className='FavoriteRoutesButtonLeft'>
          <div className='FavoriteRoutesButtonLeftIcon'>
            <FaRegHeart />
          </div>
          <h3>{t("tms.filters.placeholders.Favourite Routes")}</h3>
        </div>
        <div className='FavoriteRoutesButtonRight'>
          <div className='FavoriteRoutesButtonRightIcon'>
            <LuPanelLeftClose />
          </div>
        </div>
      </div>
      <FavoriteRoutesModal
        isOpen={openModal}
        isClose={() => setOpenModal(false)}
      />
    </Fragment>
  );
};

export default FavoriteRoutesButton;
