import React from "react";
import "./ByRoute.scss";

import { useTranslation } from "react-i18next";

// import { Link } from "react-router-dom";
const ByRoute = ({ analyticsData }) => {
  const { t } = useTranslation();
  return (
    <div id='ByRoute'>
      <div className='ByRouteContainer'>
        {analyticsData?.top_country_routes?.length > 0 ? (
          analyticsData?.top_country_routes.map((item, index) => (
            <div key={index} className='ByRouteItem'>
              <h1>{item?.name}</h1>
              <div className='desc'>
                <h5>{item?.count}</h5>
                <div className='line'></div>
                <h4>{item?.average}%</h4>
              </div>
            </div>
          ))
        ) : (
          <div>{t("shipper.desc.You have no shipments")}</div>
        )}
      </div>
      {/* <div className='SeeAllButton'>
        <Link to='/dashboard/shipper/accepted'>See all</Link>
      </div> */}
    </div>
  );
};

export default ByRoute;
