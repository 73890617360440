import React from "react";
import "./AirPackageItem.scss";

import { useTranslation } from "react-i18next";
import { useCalculateVolume } from "../../../../../../helper/useCalculateVolume";

import DInputs from "../../../../../Form/DInputs/DInputs";
import DPackageTypeSelect from "../../../../../Form/DSelect/DPackageTypeSelect/DPackageTypeSelect";
import DQuantitySelect from "../../../../../Form/DSelect/DQuantitySelect/DQuantitySelect";
import CreateOrderAdrSection from "../../../Components/CreateOrderAdrSection/CreateOrderAdrSection";
import CreateOrderRemoveItemBtn from "./../../../Components/CreateOrderRemoveItemBtn/CreateOrderRemoveItemBtn";
import CreateOrderProductType from "./../../../Components/CreateOrderProductType/CreateOrderProductType";
import CreateOrderSectionSecondaryTitle from "../../../Components/CreateOrderSectionSecondaryTitle/CreateOrderSectionSecondaryTitle";
import CreateOrderDimentionGroup from "../../../Components/CreateOrderDimentionGroup/CreateOrderDimentionGroup";
import CreateOrderDimentionDescription from "./../../../Components/CreateOrderDimentionGroup/CreateOrderDimentionDescription/CreateOrderDimentionDescription";

const AirPackageItem = ({ edit, group, setGroup, index, item }) => {
  const { t } = useTranslation();
  const adrHandler = () => {
    const updatedGroup = [...group];
    updatedGroup[index].adr = !item.adr;
    updatedGroup[index].adrNumber = "";
    updatedGroup[index].adrClass = "";
    updatedGroup[index].adrFile = null;
    setGroup(updatedGroup);
  };
  const adrNumberHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].adrNumber = value;
    setGroup(updatedGroup);
  };
  const adrClassHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].adrClass = value;
    setGroup(updatedGroup);
  };
  const adrFileHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].adrFile = value;
    setGroup(updatedGroup);
  };
  const cargoTypeHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].cargoType = value;
    setGroup(updatedGroup);
  };
  const HSCodeHandler = () => {
    const updatedGroup = [...group];
    updatedGroup[index].HSCode = !item.HSCode;
    updatedGroup[index].HSCodeValue = "";
    setGroup(updatedGroup);
  };
  const HSCodeValueHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].HSCodeValue = value;
    setGroup(updatedGroup);
  };
  const packingTypeDimentionHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].dimensions[0].packingType = value;
    setGroup(updatedGroup);
  };
  const weightDimentionHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].dimensions[0].weight = value;
    setGroup(updatedGroup);
  };
  const quantityDimentionHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].dimensions[0].count = value;
    setGroup(updatedGroup);
  };
  const lengthDimentionHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].dimensions[0].length = value;
    setGroup(updatedGroup);
  };
  const widthDimentionHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].dimensions[0].width = value;
    setGroup(updatedGroup);
  };
  const heightDimentionHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].dimensions[0].height = value;
    setGroup(updatedGroup);
  };
  const removeItemHandler = () => {
    const updatedGroup = [...group];
    updatedGroup.splice(index, 1);
    setGroup(updatedGroup);
  };

  const volume =
    useCalculateVolume(
      item.dimensions[0].length,
      item.dimensions[0].width,
      item.dimensions[0].height
    ) * item.dimensions[0].count;
  const roundedVolume = volume.toFixed(5);
  const roundedVolumeNumber = parseFloat(roundedVolume);

  const weight = item.dimensions[0].weight * item.dimensions[0].count;
  const roundedWeight = weight.toFixed(2);
  const roundedWeightNumber = parseFloat(roundedWeight);

  return (
    <div id='ShipperAirPackageItem'>
      {!edit && (
        <CreateOrderRemoveItemBtn
          show={group.length > 1 ? true : false}
          onClick={removeItemHandler}
        />
      )}
      <div className='productWrapper'>
        <CreateOrderProductType
          cargoType={item.cargoType}
          setCargoType={(value) => cargoTypeHandler(value)}
          HSCode={item.HSCode}
          setHSCode={() => HSCodeHandler()}
          HSCodeValue={item.HSCodeValue}
          setHSCodeValue={(value) => HSCodeValueHandler(value)}
          cargos_type_id_error={item.cargoTypeError}
          hs_code_error={item.HSCodeValueError}
        />
      </div>
      <div className='productAdrWrapper'>
        <CreateOrderAdrSection
          adr={item.adr}
          setAdr={() => adrHandler()}
          adrNumber={item.adrNumber}
          setAdrNumber={(value) => adrNumberHandler(value)}
          adrClass={item.adrClass}
          setAdrClass={(value) => adrClassHandler(value)}
          adrFile={item.adrFile}
          setAdrFile={edit ? undefined : (value) => adrFileHandler(value)}
          cargos_adr_error={item.adrError}
          cargos_adr_class_error={item.adrClassError}
          cargos_un_number_error={item.adrNumberError}
          cargos_msds_file_error={item.adrFileError}
        />
      </div>
      <CreateOrderSectionSecondaryTitle title={t("shipper.forms.titles.Dimensions per package")} />

      <div className='packageDimentionForm'>
        <div className='BlockA'>
          <CreateOrderDimentionGroup
            index={index}
            length={item.dimensions[0].length}
            setLength={lengthDimentionHandler}
            lengthError={item.dimensions[0].lengthError}
            width={item.dimensions[0].width}
            setWidth={widthDimentionHandler}
            widthError={item.dimensions[0].widthError}
            height={item.dimensions[0].height}
            setHeigth={heightDimentionHandler}
            heightError={item.dimensions[0].heightError}
          />
        </div>
        <div className='line'></div>

        <div className='BlockB'>
          <DPackageTypeSelect
            required
            value={item.dimensions[0].packingType}
            setValue={(value) => packingTypeDimentionHandler(value)}
            placeholder={t("shipper.forms.content.Select type")}
            label={t("shipper.forms.content.Package type")}
            error={item.dimensions[0].packingTypeError}
          />
          <DInputs
            required
            type={"number"}
            id={`ShipperAirPackageItemTotalWeight${index}}`}
            placeholder={t("shipper.forms.content.Per weight")}
            label={t("shipper.forms.content.Package Weight (KG)")}
            value={item.dimensions[0].weight}
            setValue={(value) => weightDimentionHandler(value)}
            error={item.dimensions[0].weightError}
          />
          <DQuantitySelect
            value={item.dimensions[0].count}
            setValue={(value) => quantityDimentionHandler(value)}
            label={t("shipper.forms.content.Package quantity")}
            error={item.dimensions[0].countError}
          />
        </div>
      </div>

      <CreateOrderDimentionDescription
        volume={roundedVolumeNumber}
        weight={roundedWeightNumber}
      />
    </div>
  );
};

export default AirPackageItem;
