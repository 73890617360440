import React from "react";
import "./OceanPackageItem.scss";

import { useTranslation } from "react-i18next";

import CreateOrderAdrSection from "../../../Components/CreateOrderAdrSection/CreateOrderAdrSection";
import CreateOrderProductType from "../../../Components/CreateOrderProductType/CreateOrderProductType";
import CreateOrderSectionSecondaryTitle from "./../../../Components/CreateOrderSectionSecondaryTitle/CreateOrderSectionSecondaryTitle";
import CreateOrderContainerGroup from "./CreateOrderContainerGroup/CreateOrderContainerGroup";
import CreateOrderOversizedSection from "../../../Components/CreateOrderOversizedSection/CreateOrderOversizedSection";

const OceanPackageItem = ({ edit, group, setGroup, index, item }) => {
  const { t } = useTranslation();
  const adrHandler = () => {
    const updatedGroup = [...group];
    updatedGroup[index].adr = !item.adr;
    updatedGroup[index].adrNumber = "";
    updatedGroup[index].adrClass = "";
    updatedGroup[index].adrFile = null;
    setGroup(updatedGroup);
  };
  const adrNumberHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].adrNumber = value;
    setGroup(updatedGroup);
  };
  const adrClassHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].adrClass = value;
    setGroup(updatedGroup);
  };
  const adrFileHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].adrFile = value;
    setGroup(updatedGroup);
  };
  const cargoTypeHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].cargoType = value;
    setGroup(updatedGroup);
  };
  const HSCodeHandler = () => {
    const updatedGroup = [...group];
    updatedGroup[index].HSCode = !item.HSCode;
    updatedGroup[index].HSCodeValue = "";
    setGroup(updatedGroup);
  };
  const HSCodeValueHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].HSCodeValue = value;
    setGroup(updatedGroup);
  };

  const oversizedHandler = () => {
    const updatedGroup = [...group];
    updatedGroup[index].oversized = !item.oversized;
    updatedGroup[index].oversizedFile = null;
    updatedGroup[index].oversizedCall = false;
    updatedGroup[index].unknownContainer = false;
    setGroup(updatedGroup);
  };

  const oversizedFileHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].oversizedFile = value;
    setGroup(updatedGroup);
  };

  const oversizedCallHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].oversizedCall = value;
    setGroup(updatedGroup);
  };

  const unknownContainerHandler = () => {
    const updatedGroup = [...group];
    updatedGroup[index].unknownContainer = !item.unknownContainer;
    setGroup(updatedGroup);
  };
  return (
    <div id='OceanPackageItem'>
      <div className='productWrapper'>
        <CreateOrderProductType
          cargoType={item.cargoType}
          setCargoType={(value) => cargoTypeHandler(value)}
          HSCode={item.HSCode}
          setHSCode={() => HSCodeHandler()}
          HSCodeValue={item.HSCodeValue}
          setHSCodeValue={(value) => HSCodeValueHandler(value)}
          cargos_type_id_error={item.cargoTypeError}
          hs_code_error={item.HSCodeValueError}
        />
      </div>
      <div className='productAdrWrapper'>
        <CreateOrderAdrSection
          adr={item.adr}
          setAdr={() => adrHandler()}
          adrNumber={item.adrNumber}
          setAdrNumber={(value) => adrNumberHandler(value)}
          adrClass={item.adrClass}
          setAdrClass={(value) => adrClassHandler(value)}
          adrFile={item.adrFile}
          setAdrFile={edit ? undefined : (value) => adrFileHandler(value)}
          cargos_adr_error={item.adrError}
          cargos_adr_class_error={item.adrClassError}
          cargos_un_number_error={item.adrNumberError}
          cargos_msds_file_error={item.adrFileError}
        />
      </div>

      <div className='productOversizedWrapper'>
        <CreateOrderOversizedSection
          edit={edit}
          oversized={item.oversized}
          setOversized={oversizedHandler}
          oversizedFile={item.oversizedFile}
          setOversizedFile={
            edit ? undefined : (value) => oversizedFileHandler(value)
          }
          oversizedCall={item.oversizedCall}
          setOversizedCall={(value) => oversizedCallHandler(value)}
          unknownContainer={item.unknownContainer}
          setUnknownContainer={unknownContainerHandler}
          oversizedError={item.oversizedError}
          oversizedFileError={item.oversizedFileError}
          oversizedCallError={item.oversizedCallError}
        />
      </div>

      <CreateOrderSectionSecondaryTitle
        title={t("shipper.forms.titles.Container requirements")}
      />
      {/* {item?.oversized && (
        <div className='unknownContainerContainer'>
          <DCheckBox
            value={item.unknownContainer}
            setValue={unknownContainerHandler}
            label='I don’t know which container i need'
          />
          <DErrorText error={item?.unknownContainerError} />
        </div>
      )} */}
      <CreateOrderContainerGroup
        edit={edit}
        index={index}
        item={item}
        group={group}
        setGroup={setGroup}
      />
    </div>
  );
};

export default OceanPackageItem;
