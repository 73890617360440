import React, { useState, useEffect } from "react";
import "./CreateAirOrder.scss";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import {
  createAir,
  resetCreateAir,
} from "../../../../../../store/orders/shipperOrders/shipperOrdersSlice";
import { useCreateShipperAirError } from "./../../../../../../validations/order/useCreateShipperAirError";

import Wrapper from "./../../../../../../componentsAdditional/Wrapper";
import AirTopMenu from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperAir/AirTopMenu/AirTopMenu";
import AirLocation from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperAir/AirLocation/AirLocation";
import AirPackage from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperAir/AirPackage/AirPackage";
import CreateOrderSubmit from "../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderSubmit/CreateOrderSubmit";
import CreateOrderHeader from "../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderHeader/CreateOrderHeader";
import CreateOrderAditionalInformation from "./../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderAditionalInformation/CreateOrderAditionalInformation";
import CreateOrderAditionalServices from "./../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderAditionalServices/CreateOrderAditionalServices";
import NetworkErrorModal from "../../../../../../componentsDashboard/UI/NetworkErrorModal/NetworkErrorModal";

const CreateAirOrder = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { createAirLoading, createAirResponce, createAirError } = useSelector(
    (state) => state.shipperOrders
  );
  const {
    create_order_Network_error,
    cargos_comment_error,
    cargos_ready_date_error,
    cargos_price_error,
    cargos_price_currency_id_error,
    order_documents_error,
  } = useCreateShipperAirError();

  const [showNetworkError, setShowNetworkError] = useState(false);
  const [group, setGroup] = useState([
    {
      cargoType: null,
      HSCode: false,
      HSCodeValue: "",
      adr: false,
      adrNumber: "",
      adrClass: "",
      adrFile: null,
      cargoTypeError: null,
      HSCodeValueError: null,
      adrError: null,
      adrNumberError: null,
      adrClassError: null,
      adrFileError: null,
      dimensions: [
        {
          packingType: null,
          count: 1,
          weight: "",
          length: "",
          width: "",
          height: "",
          packingTypeError: null,
          countError: null,
          weightError: null,
          lengthError: null,
          widthError: null,
          heightError: null,
        },
      ],
    },
  ]);
  const [locations, setLocations] = useState([
    {
      direction: "A",
      inputted_address: "",
      type: "AIRPORT",
      error: null,
    },
    {
      direction: "B",
      inputted_address: "",
      type: "AIRPORT",
      error: null,
    },
  ]);

  const [selectedServices, setSelectedServices] = useState([]);
  const [readyCargoTime, setReadyCargoTime] = useState(null);
  const [cargoPrice, setCargoPrice] = useState("");
  const [cargoPriceCurrency, setCargoPriceCurrency] = useState(
    user
      ? {
          value: user.currency,
          label: user.currency.code,
        }
      : null
  );
  const [comment, setComment] = useState("");
  const [aditionalDocument, setAditionalDocument] = useState(null);

  // catch locations error
  useEffect(() => {
    if (createAirError?.errors) {
      const updatedLocations = locations.map((location, index) => ({
        ...location,
        error:
          createAirError.errors[
            `cargos.0.locations.${index}.inputted_address`
          ]?.[0] ?? null,
      }));
      setLocations(updatedLocations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAirError]);

  // catch cargo errors
  useEffect(() => {
    if (createAirError?.errors) {
      const updatedGroup = group.map((item, index) => ({
        ...item,
        cargoTypeError:
          createAirError?.errors?.[`cargos.${index}.type_id`]?.[0] || null,
        HSCodeValueError:
          createAirError?.errors?.[`cargos.${index}.hs_code`]?.[0] || null,
        adrError: createAirError?.errors?.[`cargos.${index}.adr`]?.[0] || null,
        adrNumberError:
          createAirError?.errors?.[`cargos.${index}.un_number`]?.[0] || null,
        adrClassError:
          createAirError?.errors?.[`cargos.${index}.adr_class`]?.[0] || null,
        adrFileError:
          createAirError?.errors?.[`cargos.${index}.msds_file`]?.[0] || null,
        dimensions: item.dimensions.map((dimension) => ({
          ...dimension,
          packingTypeError:
            createAirError?.errors?.[
              `cargos.${index}.dimensions.0.packing_type_id`
            ]?.[0] || null,
          countError:
            createAirError?.errors?.[
              `cargos.${index}.dimensions.0.count`
            ]?.[0] || null,
          weightError:
            createAirError?.errors?.[
              `cargos.${index}.dimensions.0.weight`
            ]?.[0] || null,
          lengthError:
            createAirError?.errors?.[
              `cargos.${index}.dimensions.0.length`
            ]?.[0] || null,
          widthError:
            createAirError?.errors?.[
              `cargos.${index}.dimensions.0.width`
            ]?.[0] || null,
          heightError:
            createAirError?.errors?.[
              `cargos.${index}.dimensions.0.height`
            ]?.[0] || null,
        })),
      }));
      setGroup(updatedGroup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAirError]);

  // catch network error
  useEffect(() => {
    if (create_order_Network_error) {
      setShowNetworkError(true);
    }
    return () => {
      dispatch(resetCreateAir());
      setShowNetworkError(false);
    };
  }, [dispatch, create_order_Network_error]);

  // reset redux states
  useEffect(() => {
    if (createAirResponce) {
      dispatch(resetCreateAir());
      navigate("/dashboard/shipper/orders?page=1");
    }
  }, [dispatch, navigate, createAirResponce]);

  // submit
  const submitHandler = () => {
    const filsterSelectedServices =
      selectedServices.length > 0 ? selectedServices.map((i) => i.id) : null;

    const formData = new FormData();
    formData.append("transfer_type_id", 1);
    formData.append("type_id", 5);
    if (Array.isArray(filsterSelectedServices)) {
      filsterSelectedServices.forEach((service) =>
        formData.append("services[]", service)
      );
    }
    if (aditionalDocument !== null && aditionalDocument !== undefined) {
      formData.append("documents[]", aditionalDocument);
    } else {
      formData.append("documents", "");
    }
    group.forEach((cargo, index) => {
      formData.append(
        `cargos[${index}][type_id]`,
        cargo?.cargoType?.value?.id || ""
      );
      formData.append(`cargos[${index}][hs_code]`, cargo?.HSCodeValue || "");
      formData.append(`cargos[${index}][price]`, cargoPrice || "");
      formData.append(
        `cargos[${index}][price_currency_id]`,
        cargoPriceCurrency?.value?.id || ""
      );
      formData.append(`cargos[${index}][adr]`, cargo.adr ? 1 : 0);
      formData.append(`cargos[${index}][about]`, comment || "");
      formData.append(`cargos[${index}][un_number]`, cargo?.adrNumber || "");
      formData.append(`cargos[${index}][adr_class]`, cargo?.adrClass || "");
      formData.append(`cargos[${index}][msds_file]`, cargo?.adrFile || "");
      formData.append(
        `cargos[${index}][weight]`,
        cargo.dimensions[0].weight * cargo.dimensions[0].count || ""
      );
      formData.append(
        `cargos[${index}][cargo_ready_date]`,
        readyCargoTime ? moment(readyCargoTime).format("YYYY-MM-DD") : ""
      );
      formData.append(
        `cargos[${index}][dimensions][0][packing_type_id]`,
        cargo?.dimensions[0]?.packingType?.value?.id || ""
      );
      formData.append(
        `cargos[${index}][dimensions][0][count]`,
        cargo.dimensions[0].count
      );
      formData.append(
        `cargos[${index}][dimensions][0][weight]`,
        cargo.dimensions[0].weight
      );
      formData.append(
        `cargos[${index}][dimensions][0][volume]`,
        ((cargo.dimensions[0].length *
          cargo.dimensions[0].width *
          cargo.dimensions[0].height) /
          1000000) *
          cargo.dimensions[0].count
      );
      formData.append(
        `cargos[${index}][dimensions][0][length]`,
        cargo.dimensions[0].length
      );
      formData.append(
        `cargos[${index}][dimensions][0][width]`,
        cargo.dimensions[0].width
      );
      formData.append(
        `cargos[${index}][dimensions][0][height]`,
        cargo.dimensions[0].height
      );
      // Add cargo locations
      locations.forEach((location, locIndex) => {
        formData.append(
          `cargos[${index}][locations][${locIndex}][direction]`,
          location.direction
        );
        formData.append(
          `cargos[${index}][locations][${locIndex}][type]`,
          location.type
        );
        formData.append(
          `cargos[${index}][locations][${locIndex}][inputted_address]`,
          location.inputted_address
        );
      });
    });
    dispatch(createAir(formData));
  };

  return (
    <Wrapper documentTitle={t("shipper.titles.Air freight")}>
      <AirTopMenu locations={locations} group={group} time={readyCargoTime} />
      <section id='ShipperCreateOrderAir'>
        <div className='ShipperCreateOrderAirWrapper'>
          <CreateOrderHeader />
          <AirLocation locations={locations} setLocations={setLocations} />
          <AirPackage group={group} setGroup={setGroup} />
          <CreateOrderAditionalServices
            title={t("shipper.forms.titles.Additional Services")}
            selectedServices={selectedServices}
            setSelectedServices={setSelectedServices}
          />
          <CreateOrderAditionalInformation
            title={t("shipper.forms.titles.Additional information")}
            readyCargoTime={readyCargoTime}
            setReadyCargoTime={setReadyCargoTime}
            cargoPrice={cargoPrice}
            setCargoPrice={setCargoPrice}
            cargoPriceCurrency={cargoPriceCurrency}
            setCargoPriceCurrency={setCargoPriceCurrency}
            comment={comment}
            setComment={setComment}
            aditionalDocument={aditionalDocument}
            setAditionalDocument={setAditionalDocument}
            //errors
            cargos_comment_error={cargos_comment_error}
            cargos_ready_date_error={cargos_ready_date_error}
            cargos_price_error={cargos_price_error}
            cargos_price_currency_id_error={cargos_price_currency_id_error}
            order_documents_error={order_documents_error}
          />
          <CreateOrderSubmit
            title={t("shipper.buttons.Get a quote")}
            loading={createAirLoading}
            onClick={() => submitHandler()}
          />
        </div>
      </section>
      <NetworkErrorModal
        isOpen={showNetworkError}
        isClose={() => setShowNetworkError(false)}
      />
    </Wrapper>
  );
};

export default CreateAirOrder;
