import React, { useState, useEffect } from "react";
import "./CreateRoadOrder.scss";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { getTrailerTypes } from "../../../../../../store/orders/orderSettings/orderSettingsSlice";
import {
  createRoad,
  resetCreateRoad,
  groupRoad,
  resetGroupRoad,
} from "./../../../../../../store/orders/shipperOrders/shipperOrdersSlice";
import { useCreateShipperRoadError } from "./../../../../../../validations/order/useCreateShipperRoadError";

import Wrapper from "./../../../../../../componentsAdditional/Wrapper";
import CreateOrderHeader from "./../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderHeader/CreateOrderHeader";
import RoadTypeSwitcher from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperRoad/RoadTypeSwitcher/RoadTypeSwitcher";
import RoadTopMenu from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperRoad/RoadTopMenu/RoadTopMenu";
import CreateOrderAditionalServices from "./../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderAditionalServices/CreateOrderAditionalServices";
import CreateOrderAditionalInformation from "./../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderAditionalInformation/CreateOrderAditionalInformation";
import RoadLocation from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperRoad/RoadLocation/RoadLocation";
import RoadPackage from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperRoad/RoadPackage/RoadPackage";
import CreateOrderSubmit from "../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderSubmit/CreateOrderSubmit";
import NetworkErrorModal from "../../../../../../componentsDashboard/UI/NetworkErrorModal/NetworkErrorModal";

const CreateRoadOrder = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const {
    createRoadLoading,
    createRoadResponce,
    createRoadResponceArray,
    createRoadErrorArray,
    createRoadError,
    groupRoadLoading,
    groupRoadResponce,
  } = useSelector((state) => state.shipperOrders);

  const {
    trailer_type_id_error,
    trailer_size_id_error,
    temperature_range_id_error,
    order_documents_error,
    cargos_type_id_error,
    hs_code_error,
    cargos_weight_error,
    cargos_adr_error,
    cargos_adr_class_error,
    cargos_un_number_error,
    cargos_msds_file_error,
    cargos_oversized_error,
    cargos_oversized_file_error,
    cargos_oversized_call_error,
    // cargos_unknown_container_error,
    cargos_pickup_date_error,
    cargos_price_error,
    cargos_price_currency_id_error,
    create_order_Network_error,
  } = useCreateShipperRoadError();

  const ORDERTYPES = [
    { id: 1, name: "FTL" },
    { id: 2, name: "LTL" },
  ];
  const [showNetworkError, setShowNetworkError] = useState(false);

  const [order_type_id, set_order_type_id] = useState(1);
  const [locations, setLocations] = useState([
    {
      direction: "A",
      inputted_address: "",
      error: null,
    },
    {
      direction: "B",
      inputted_address: "",
      error: null,
    },
  ]);
  const [pickUpDate, setPickUpDate] = useState(null);
  const [showPickUpTime, setShowPickUpTime] = useState(false);
  const [pickUpTime, setPickUpTime] = useState(null);
  const [cargoType, setCargoType] = useState(null);
  const [totalWeight, setTotalWeight] = useState("");
  const [HSCode, setHSCode] = useState(false);
  const [HSCodeValue, setHSCodeValue] = useState("");
  const [adr, setAdr] = useState(false);
  const [adrNumber, setAdrNumber] = useState("");
  const [adrClass, setAdrClass] = useState("");
  const [adrFile, setAdrFile] = useState(null);
  const [oversized, setOversized] = useState(false);
  const [oversizedFile, setOversizedFile] = useState(null);
  const [oversizedCall, setOversizedCall] = useState(false);
  const [unknownContainer, setUnknownContainer] = useState(false);
  const [group, setGroup] = useState([
    {
      trailerType: null,
      trailerSize: null,
      temperatureRange: null,
      quantity: 1,
    },
  ]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [cargoPrice, setCargoPrice] = useState("");
  const [cargoPriceCurrency, setCargoPriceCurrency] = useState(
    user
      ? {
          value: user.currency,
          label: user.currency.code,
        }
      : null
  );
  const [comment, setComment] = useState("");
  const [aditionalDocument, setAditionalDocument] = useState(null);
  // main orders
  const [orders, setOrders] = useState(null);

  // catch locations error
  useEffect(() => {
    if (createRoadError && createRoadError.errors) {
      const updatedLocations = locations.map((location, index) => {
        const errorKey = `cargos.0.locations.${index}.inputted_address`;
        const errorValue =
          createRoadError.errors[errorKey] &&
          createRoadError.errors[errorKey][0]
            ? createRoadError.errors[errorKey][0]
            : null;

        return {
          ...location,
          error: errorValue,
        };
      });
      setLocations(updatedLocations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createRoadError]);

  // catch network error
  useEffect(() => {
    if (create_order_Network_error) {
      setShowNetworkError(true);
    }
  }, [create_order_Network_error]);

  // convert orders
  useEffect(() => {
    const convertedGroup = group.flatMap((item) =>
      Array.from({ length: item.quantity }, () => ({
        trailerType: item.trailerType,
        trailerSize: item.trailerSize,
        temperatureRange: item.temperatureRange,
        weight: 0,
      }))
    );
    const convertedWeight = convertedGroup.map((order) => ({
      ...order,
      weight:
        totalWeight && totalWeight.length > 0
          ? parseFloat((+totalWeight / convertedGroup.length).toFixed(0))
          : 0,
    }));
    setOrders(convertedWeight);
  }, [group, totalWeight]);

  // get trailer types
  useEffect(() => {
    dispatch(getTrailerTypes(1));
  }, [dispatch]);

  // reset all
  useEffect(() => {
    if (groupRoadResponce) {
      dispatch(resetCreateRoad());
      dispatch(resetGroupRoad());
      navigate("/dashboard/shipper/orders?page=1");
    }
  }, [dispatch, navigate, groupRoadResponce]);

  useEffect(() => {
    if (
      orders &&
      orders.length > 1 &&
      orders.length ===
        createRoadErrorArray.length + createRoadResponceArray.length
    ) {
      const data = { orders: createRoadResponceArray };
      dispatch(groupRoad(data));
    }
  }, [
    dispatch,
    navigate,
    orders,
    createRoadErrorArray.length,
    createRoadResponceArray,
  ]);

  // success one
  useEffect(() => {
    if (orders && createRoadResponce && orders.length === 1) {
      dispatch(resetCreateRoad());
      navigate("/dashboard/shipper/orders?page=1");
    }
  }, [dispatch, navigate, createRoadResponce, orders]);

  // submit
  const submitHandler = () => {
    const filsterSelectedServices =
      selectedServices.length > 0 ? selectedServices.map((i) => i.id) : null;
    const filsterLocation = locations.map((location) => ({
      ...location,
      type: "LOCALITY",
    }));

    const newData = orders.map((order) => {
      const formData = new FormData();
      formData.append("transfer_type_id", 1);
      formData.append("type_id", order_type_id || 1);
      formData.append("trailer_type_id", order.trailerType?.value?.id || "");
      formData.append(
        "trailer_size_id",
        order?.trailerSize?.value?.value?.id || ""
      );
      formData.append(
        "temperature_range_id",
        order?.temperatureRange?.value?.id || ""
      );
      formData.append("comment", "");
      if (Array.isArray(filsterSelectedServices)) {
        filsterSelectedServices.forEach((service) =>
          formData.append("services[]", service)
        );
      }
      if (aditionalDocument !== null && aditionalDocument !== undefined) {
        formData.append("documents[]", aditionalDocument);
      } else {
        formData.append("documents", "");
      }
      const cargo = {
        type_id: cargoType?.value?.id || "",
        weight: order?.weight || "",
        adr: adr ? 1 : 0,
        adr_class: adrClass || "",
        un_number: adrNumber || "",
        msds_file: adrFile || "",
        oversized: oversized ? 1 : 0,
        oversized_file: oversizedFile || "",
        oversized_call: oversizedCall ? 1 : 0,
        // dont know which container
        hs_code: HSCodeValue || "",
        about: comment || "",
        price: cargoPrice || "",
        price_currency_id: cargoPriceCurrency?.value?.id || "",
        pickup_date: pickUpDate ? moment(pickUpDate).format("YYYY-MM-DD") : "",
      };

      if (pickUpTime && pickUpTime.length === 2) {
        formData.append("cargos[0][pickup_time_range][start]", pickUpTime[0]);
        formData.append("cargos[0][pickup_time_range][end]", pickUpTime[1]);
      }

      Object.keys(cargo).forEach((key) => {
        if (key === "pickup_time_range" && Array.isArray(pickUpTime)) {
          pickUpTime.forEach((time, index) => {
            formData.append(`cargos[0][pickup_time_range][${index}]`, time);
          });
        } else {
          formData.append(`cargos[0][${key}]`, cargo[key]);
        }
      });
      filsterLocation.forEach((location, index) => {
        Object.keys(location).forEach((key) =>
          formData.append(
            `cargos[0][locations][${index}][${key}]`,
            location[key]
          )
        );
      });
      return formData;
    });
    newData.forEach((formData) => dispatch(createRoad(formData)));
  };

  return (
    <Wrapper documentTitle={t("shipper.titles.Road freight")}>
      <RoadTopMenu
        ORDERTYPES={ORDERTYPES}
        order_type_id={order_type_id}
        pickUpDate={pickUpDate}
        totalWeight={totalWeight}
        cargoType={cargoType}
        orders={orders}
        locations={locations}
      />
      <section id='ShipperCreateOrderRoad'>
        <div className='ShipperCreateOrderRoadWrapper'>
          <CreateOrderHeader />
          <RoadTypeSwitcher
            ORDERTYPES={ORDERTYPES}
            order_type_id={order_type_id}
            set_order_type_id={set_order_type_id}
          />
          <RoadLocation
            locations={locations}
            setLocations={setLocations}
            pickUpDate={pickUpDate}
            setPickUpDate={setPickUpDate}
            showPickUpTime={showPickUpTime}
            setShowPickUpTime={setShowPickUpTime}
            pickUpTime={pickUpTime}
            setPickUpTime={setPickUpTime}
            cargos_pickup_date_error={cargos_pickup_date_error}
          />
          <RoadPackage
            group={group}
            setGroup={setGroup}
            orders={orders}
            setOrders={setOrders}
            ////////////////////
            cargoType={cargoType}
            setCargoType={setCargoType}
            totalWeight={totalWeight}
            setTotalWeight={setTotalWeight}
            HSCode={HSCode}
            setHSCode={setHSCode}
            HSCodeValue={HSCodeValue}
            setHSCodeValue={setHSCodeValue}
            adr={adr}
            setAdr={setAdr}
            adrNumber={adrNumber}
            setAdrNumber={setAdrNumber}
            adrClass={adrClass}
            setAdrClass={setAdrClass}
            adrFile={adrFile}
            setAdrFile={setAdrFile}
            oversized={oversized}
            setOversized={setOversized}
            oversizedFile={oversizedFile}
            setOversizedFile={setOversizedFile}
            oversizedCall={oversizedCall}
            setOversizedCall={setOversizedCall}
            unknownContainer={unknownContainer}
            setUnknownContainer={setUnknownContainer}
            // errors
            trailer_type_id_error={trailer_type_id_error}
            trailer_size_id_error={trailer_size_id_error}
            temperature_range_id_error={temperature_range_id_error}
            hs_code_error={hs_code_error}
            cargos_type_id_error={cargos_type_id_error}
            cargos_weight_error={cargos_weight_error}
            cargos_adr_error={cargos_adr_error}
            cargos_adr_class_error={cargos_adr_class_error}
            cargos_un_number_error={cargos_un_number_error}
            cargos_msds_file_error={cargos_msds_file_error}
            cargos_oversized_error={cargos_oversized_error}
            cargos_oversized_file_error={cargos_oversized_file_error}
            cargos_oversized_call_error={cargos_oversized_call_error}
            // cargos_unknown_container_error={cargos_unknown_container_error}
          />
          <CreateOrderAditionalServices
            title={t("shipper.forms.titles.Additional Services")}
            selectedServices={selectedServices}
            setSelectedServices={setSelectedServices}
          />
          <CreateOrderAditionalInformation
            title={t("shipper.forms.titles.Additional information")}
            cargoPrice={cargoPrice}
            setCargoPrice={setCargoPrice}
            cargoPriceCurrency={cargoPriceCurrency}
            setCargoPriceCurrency={setCargoPriceCurrency}
            comment={comment}
            setComment={setComment}
            aditionalDocument={aditionalDocument}
            setAditionalDocument={setAditionalDocument}
            // errors
            order_documents_error={order_documents_error}
            cargos_price_error={cargos_price_error}
            cargos_price_currency_id_error={cargos_price_currency_id_error}
          />
          {orders && (
            <CreateOrderSubmit
              title={t("shipper.buttons.Get a quote")}
              loading={
                createRoadLoading ||
                groupRoadLoading ||
                createRoadResponceArray.length > 0
              }
              onClick={() => submitHandler()}
            />
          )}
        </div>
      </section>
      <NetworkErrorModal
        isOpen={showNetworkError}
        isClose={() => setShowNetworkError(false)}
      />
    </Wrapper>
  );
};

export default CreateRoadOrder;
