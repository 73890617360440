import React, { Fragment, useState } from "react";
import "./OrdersTableItem.scss";

import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import { FiMoreVertical, FiEdit2, FiTrash2 } from "react-icons/fi";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import TrailerField from "../../../Form/TableFields/TrailerField/TrailerField";
import RouteField from "./../../../Form/TableFields/RouteField/RouteField";
import PriceField from "../../../Form/TableFields/PriceField/PriceField";
import RowField from "../../../Form/TableFields/RowField/RowField";
import NameField from "../../../Form/TableFields/NameField/NameField";
import TextField from "./../../../Form/TableFields/TextField/TextField";
import DateField from "../../../Form/TableFields/DateField/DateField";
import MoreField from "../../../Form/TableFields/MoreField/MoreField";
import OrderStatusField from "../../../Form/TableFields/OrderStatusField/OrderStatusField";
import PartnerField from "./../../../Form/TableFields/PartnerField/PartnerField";
import DriverPhoneField from "../../../Form/TableFields/DriverPhoneField/DriverPhoneField";
import NoDataField from "../../../Form/TableFields/NoDataField/NoDataField";
import DeleteOrder from "./../../../Orders/DeleteOrder/DeleteOrder";

const OrdersTableItem = ({ order }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const driverName = order?.carrier?.first_name ?? "";
  const driverLastName = order?.carrier?.last_name ?? "";

  return (
    <Fragment>
      <RowField
        style={{
          borderLeft: `4px solid ${order.color ? order.color : "transparent"}`,
        }}
      >
        <NameField
          label={order.tracking}
          to={`/dashboard/tms/orders/${order.id}`}
        />
        <OrderStatusField status={order.status ?? null} />
        {order.ownership ? (
          order.customer?.company?.name ? (
            <TextField max={30} label={order.customer?.company?.name ?? "-"} />
          ) : (
            <NoDataField title={t("tms.tables.noInfo.no customer")} />
          )
        ) : (
          <TextField max={30} label={order.company?.name ?? "-"} />
        )}

        <RouteField order={order} />

        {order.carrier === null ? (
          <DriverPhoneField driver={null} />
        ) : order.carrier.is_own_fleet ? (
          <DriverPhoneField
            driver={{
              name: `${driverName} ${driverLastName}`.trim() || "",
              phone: order?.carrier?.phone || "",
            }}
          />
        ) : (
          <PartnerField
            label={
              order?.carrier?.partner?.company?.name ||
              t("tms.tables.noInfo.no partner")
            }
          />
        )}

        <TrailerField
          icon={order.trailer_type?.icon}
          trailerType={order.trailer_type?.name ?? "-"}
          volume={order.trailer_size?.label ?? "-"}
        />
        <DateField date={order.cargos[0]?.pickup_date ?? null} />
        <PriceField
          number={order?.revenue?.amount ?? 0}
          symbol={order?.revenue?.currency?.symbol ?? "-"}
        />
        {order.ownership && (
          <MoreField>
            <div className='icon' onClick={handleClick}>
              <FiMoreVertical />
            </div>
            <Menu
              id='OrdersTableItemMore'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {order.ownership && (
                <MenuItem
                  onClick={() => {
                    handleClose();
                    navigate(`/dashboard/tms/orders/edit/${order.id}`);
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <FiEdit2 />
                    {t("tms.tables.buttons.Edit")}
                  </div>
                </MenuItem>
              )}
              {order.ownership && (
                <MenuItem
                  onClick={() => {
                    setOpenDeleteModal(true);
                    handleClose();
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      color: "red",
                    }}
                  >
                    <FiTrash2 />
                    {t("tms.tables.buttons.Delete")}
                  </div>
                </MenuItem>
              )}
            </Menu>
          </MoreField>
        )}
      </RowField>

      {openDeleteModal && (
        <DeleteOrder
          isOpen={openDeleteModal}
          isClose={() => setOpenDeleteModal(false)}
          orderId={order.id}
        />
      )}
    </Fragment>
  );
};

export default OrdersTableItem;
