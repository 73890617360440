import React, { Fragment, useState } from "react";
import "./AddAlreadyPaidMoney.scss";

import { useTranslation } from "react-i18next";

import { FiPlus, FiCheck } from "react-icons/fi";

import { useMoney } from "../../../../../helper/useMoney";

import DButton from "../../../../Form/DButtons/DButton/DButton";
import AddPrePaymentModal from "../../../PrePaymentComponents/AddPrePaymentModal/AddPrePaymentModal";
import ShowPrePaymentModal from "../../../PrePaymentComponents/ShowPrePaymentModal/ShowPrePaymentModal";
import DShowButton from "../../../../Form/DButtons/DShowButton/DShowButton";
import DIconButton from "../../../../Form/DButtons/DIconButton/DIconButton";
import MarkAsPaidModal from "../../../../FinancialManagement/MarkAsPaidModal/MarkAsPaidModal";

const AddAlreadyPaidMoney = ({ order }) => {
  const { t } = useTranslation();

  const [openAddPaymentModal, setOpenAddPaymentModal] = useState(false);
  const [showMarkAsPaidModal, setShowMarkAsPaidModal] = useState(false);
  const [openShowPaymentModal, setOpenShowPaymentModal] = useState(false);

  const formatedMoney = useMoney(
    order?.total_paid || 0,
    order?.revenue?.currency?.symbol || ""
  );

  return (
    <Fragment>
      <div id='AddAlreadyPaidMoney'>
        <Fragment>
          {order.total_paid > 0 ? (
            <div className='AddAlreadyPaidMoneyContainer'>
              <div className='AddAlreadyPaidMoneyLeft'>
                <h1>{t("tms.titles.Already paid")}:</h1>
                <p>{formatedMoney}</p>
              </div>
              <div className='AddAlreadyPaidMoneyRight'>
                <div className='showIcon'>
                  <DShowButton onClick={() => setOpenShowPaymentModal(true)} />
                </div>
                <DIconButton
                  icon={<FiPlus />}
                  onClick={() => {
                    setOpenAddPaymentModal(true);
                  }}
                />
              </div>
            </div>
          ) : (
            <DButton
              title={t("tms.forms.buttons.Add payment")}
              onClick={() => {
                setOpenAddPaymentModal(true);
              }}
              rightIcon={<FiPlus />}
            />
          )}
        </Fragment>

        {order.status.id === 5 && (
          <div className='markAsPaidButton'>
            <DButton
              title={t("tms.forms.buttons.Mark as paid")}
              rightIcon={<FiCheck />}
              loading={null}
              onClick={() => {
                setShowMarkAsPaidModal(true);
              }}
            />
          </div>
        )}
      </div>

      {openAddPaymentModal && (
        <AddPrePaymentModal
          isOpen={openAddPaymentModal}
          isClose={setOpenAddPaymentModal}
          order={order}
        />
      )}
      {openShowPaymentModal && (
        <ShowPrePaymentModal
          isOpen={openShowPaymentModal}
          isClose={() => setOpenShowPaymentModal(false)}
          order={order}
        />
      )}
      {showMarkAsPaidModal && (
        <MarkAsPaidModal
          isOpen={showMarkAsPaidModal}
          isClose={setShowMarkAsPaidModal}
          item={order}
        />
      )}
    </Fragment>
  );
};

export default AddAlreadyPaidMoney;
