import { useSelector } from "react-redux";

export const useParticipantContactErrors = () => {
  const { createParticipantContactError, updateParticipantContactError } =
    useSelector((state) => state.participantContacts);

  const create_name_error =
    createParticipantContactError &&
    createParticipantContactError.errors &&
    createParticipantContactError.errors.name &&
    createParticipantContactError.errors.name[0];

  const create_email_error =
    createParticipantContactError &&
    createParticipantContactError.errors &&
    createParticipantContactError.errors.email &&
    createParticipantContactError.errors.email[0];

  const create_phone_error =
    createParticipantContactError &&
    createParticipantContactError.errors &&
    createParticipantContactError.errors.phone &&
    createParticipantContactError.errors.phone[0];

  const create_position_error =
    createParticipantContactError &&
    createParticipantContactError.errors &&
    createParticipantContactError.errors.position &&
    createParticipantContactError.errors.position[0];

  const update_name_error =
    updateParticipantContactError &&
    updateParticipantContactError.errors &&
    updateParticipantContactError.errors.name &&
    updateParticipantContactError.errors.name[0];

  const update_email_error =
    updateParticipantContactError &&
    updateParticipantContactError.errors &&
    updateParticipantContactError.errors.email &&
    updateParticipantContactError.errors.email[0];

  const update_phone_error =
    updateParticipantContactError &&
    updateParticipantContactError.errors &&
    updateParticipantContactError.errors.phone &&
    updateParticipantContactError.errors.phone[0];

  const update_position_error =
    updateParticipantContactError &&
    updateParticipantContactError.errors &&
    updateParticipantContactError.errors.position &&
    updateParticipantContactError.errors.position[0];

  return {
    create_name_error,
    create_email_error,
    create_phone_error,
    create_position_error,
    update_name_error,
    update_email_error,
    update_phone_error,
    update_position_error,
  };
};

export default useParticipantContactErrors;
