import React from "react";
import "./LandTrailerDetails.scss";

import { useTranslation } from "react-i18next";

const LandTrailerDetails = ({ orderResponce }) => {
  const { t } = useTranslation();

  const cover = orderResponce?.trailer_type?.icon || "";
  const trailerType = orderResponce?.trailer_type?.name || "";
  const trailerVolume = orderResponce?.trailer_size?.label || "";
  const trailerTemperature =
    orderResponce?.trailer_type?.temperature &&
    orderResponce?.temperature?.title;

  return (
    <div id='LandTrailerDetails'>
      <div className='titleContainer'>
        <div className='icon'>
          <img src='/assets/images/dashboard/cover7.svg' alt='container' />
        </div>
        <h1 className='title'>{t("shipper.forms.titles.Trailer need")}</h1>
      </div>
      <div className='LandTrailerDetailsShipperContainer'>
        <div className='LandTrailerDetailsShipperCover'>
          <div
            className='LandTrailerDetailsShipperImg'
            style={{
              backgroundImage: `url(${cover})`,
            }}
          />
        </div>
        <div className='LandTrailerDetailsShippercontent'>
          {trailerType && (
            <p>
              {t("shipper.forms.content.Trailer type")}:{" "}
              <span>{trailerType}</span>
            </p>
          )}
          {trailerVolume && (
            <p>
              {t("shipper.forms.content.Trailer volume")}:{" "}
              <span>{trailerVolume}</span>
            </p>
          )}
          {trailerTemperature && (
            <p>
              {t("shipper.forms.content.Temperature")}:{" "}
              <span>{trailerTemperature}</span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default LandTrailerDetails;
