import React, { Fragment, useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import {
  setActiveOrderId,
  setActiveOrderIndex,
  getOrders,
  resetGetOrders,
} from "../../../../store/orders/shipperAccepted/shipperAcceptedSlice";

import Wrapper from "../../../../componentsAdditional/Wrapper";
import ListView from "../../../../componentsDashboard/ShipperAcceptedOrders/ListView/ListView";
import SideView from "../../../../componentsDashboard/ShipperAcceptedOrders/SideView/SideView";
import LoadingPage from "./../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import EmptyList from "./../../../../componentsDashboard/UI/EmptyList/EmptyList";

const AcceptedOrders = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { shipperAcceptedOrderView, activeOrderId, activeOrderIndex, orders } =
    useSelector((state) => state.shipperAccepted);
  const {
    getShipperAcceptedOrdersSummaryLoading,
    getShipperAcceptedOrdersSummaryResponce,
  } = useSelector((state) => state.summary);

  const [page, setPage] = useState(1);

  // filters
  const [keyword, setKeyword] = useState("");
  const [status, setStatus] = useState({ id: null, name: "All" });
  const [shippingType, setShippingType] = useState(null);
  const [dateRange, setDateRange] = useState({});
  const [route, setRoute] = useState({});
  const [trailerType, setTrailerType] = useState(null);
  const [productCategory, setProductCategory] = useState(null);

  const clearFilters = () => {
    if (keyword) setKeyword("");
    if (shippingType) setShippingType(null);
    if (Object.keys(dateRange).length > 0) setDateRange({});
    if (Object.keys(route).length > 0) setRoute({});
    if (trailerType) setTrailerType(null);
    if (status?.id) setStatus({ id: null, name: "All" });
    if (productCategory) setProductCategory(null);
  };

  useEffect(() => {
    const data = {
      page,
      keyword,
      trailer_type_id: trailerType?.value?.id,
      status_id: status?.id,
      start_date:
        Object.keys(dateRange).length > 0 && dateRange?.startDate
          ? moment(dateRange.startDate).format("DD/MM/YYYY")
          : "",
      end_date:
        Object.keys(dateRange).length > 0 && dateRange?.endDate
          ? moment(dateRange.endDate).format("DD/MM/YYYY")
          : "",
      pickup_address: route?.fromCountry?.label,
      dropoff_address: route?.toCountry?.label,
      cargo_type_id: productCategory?.value?.id,
      type_ids: shippingType,
    };
    dispatch(getOrders(data));
  }, [
    dispatch,
    page,
    keyword,
    trailerType,
    status,
    dateRange,
    productCategory,
    route,
    shippingType,
  ]);

  // clear state function
  useEffect(() => {
    return () => {
      dispatch(resetGetOrders());
      setPage(1);
    };
  }, [
    dispatch,
    pathname,
    keyword,
    dateRange,
    trailerType,
    status,
    productCategory,
    route,
    shippingType,
  ]);

  useEffect(() => {
    if (!activeOrderId && orders && orders.length > 0) {
      dispatch(setActiveOrderId(orders[0].id));
    }
  }, [dispatch, orders, activeOrderId]);

  return (
    <Wrapper
      documentTitle={`${t("shipper.titles.Active shipments")} ${
        activeOrderId && !shipperAcceptedOrderView
          ? `(ID: ${activeOrderId})`
          : ""
      }`}
    >
      {getShipperAcceptedOrdersSummaryLoading && <LoadingPage />}
      {getShipperAcceptedOrdersSummaryResponce === 0 ? (
        <EmptyList
          image={"/assets/images/dashboard/cover1.svg"}
          title={t("shipper.titles.No active shipments")}
          description={t(
            "shipper.desc.Add booking and take full control of your orders"
          )}
        />
      ) : (
        <Fragment>
          {shipperAcceptedOrderView ? (
            <ListView
              page={page}
              setPage={setPage}
              activeOrderId={activeOrderId}
              setActiveOrderId={(value) => dispatch(setActiveOrderId(value))}
              activeOrderIndex={activeOrderIndex}
              setActiveOrderIndex={(value) =>
                dispatch(setActiveOrderIndex(value))
              }
              // filters
              keyword={keyword}
              setKeyword={setKeyword}
              status={status}
              setStatus={setStatus}
              shippingType={shippingType}
              setShippingType={setShippingType}
              dateRange={dateRange}
              setDateRange={setDateRange}
              route={route}
              setRoute={setRoute}
              trailerType={trailerType}
              setTrailerType={setTrailerType}
              productCategory={productCategory}
              setProductCategory={setProductCategory}
              onClear={clearFilters}
            />
          ) : (
            <SideView
              page={page}
              setPage={setPage}
              activeOrderId={activeOrderId}
              setActiveOrderId={(value) => dispatch(setActiveOrderId(value))}
              activeOrderIndex={activeOrderIndex}
              setActiveOrderIndex={(value) =>
                dispatch(setActiveOrderIndex(value))
              }
              // filters
              keyword={keyword}
              setKeyword={setKeyword}
              status={status}
              setStatus={setStatus}
              shippingType={shippingType}
              setShippingType={setShippingType}
              dateRange={dateRange}
              setDateRange={setDateRange}
              route={route}
              setRoute={setRoute}
              trailerType={trailerType}
              setTrailerType={setTrailerType}
              productCategory={productCategory}
              setProductCategory={setProductCategory}
              onClear={clearFilters}
            />
          )}
        </Fragment>
      )}
    </Wrapper>
  );
};

export default AcceptedOrders;
