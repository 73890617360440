import { useSelector } from "react-redux";

export const useChangePhoneErrors = () => {
  const { changePhoneError, verifyPhoneError } = useSelector(
    (state) => state.user
  );

  const update_phone_error =
    changePhoneError &&
    changePhoneError.errors &&
    changePhoneError.errors.phone &&
    changePhoneError.errors.phone[0];

  const update_phone_index_id_error =
    changePhoneError &&
    changePhoneError.errors &&
    changePhoneError.errors.phone_index_id &&
    changePhoneError.errors.phone_index_id[0];

  const update_phone_verify_error =
    verifyPhoneError &&
    verifyPhoneError.errors &&
    verifyPhoneError.errors.code &&
    verifyPhoneError.errors.code[0];

  return {
    update_phone_error,
    update_phone_index_id_error,
    update_phone_verify_error,
  };
};
