import React from "react";
import "./RoadLocationTime.scss";

import { FaRegClock } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

import DDatepicker from "./../../../../../Form/DDatepicker/DDatepicker";
import DTextButton from "./../../../../../Form/DButtons/DTextButton/DTextButton";
import TimeSelectDropdown from "./../../../CreateOrderTMC/Components/TimeSelectDropdown/TimeSelectDropdown";

const RoadLocationTime = ({
  pickUpDate,
  setPickUpDate,
  showPickUpTime,
  setShowPickUpTime,
  pickUpTime,
  setPickUpTime,
  cargos_pickup_date_error,
}) => {
  const { t } = useTranslation();
  return (
    <div id='RoadLocationTimeShipper'>
      <DDatepicker
        required
        label={t("shipper.forms.content.Pick up date")}
        placeholder={t("shipper.forms.content.Select pick up date")}
        value={pickUpDate}
        setValue={setPickUpDate}
        error={cargos_pickup_date_error}
      />
      {showPickUpTime ? (
        <div className='timeSelectContainer'>
          <TimeSelectDropdown time={pickUpTime} setTime={setPickUpTime} />
          <div className='removeButton'>
            <DTextButton
              title={t("shipper.buttons.Remove")}
              danger
              onClick={() => {
                setShowPickUpTime(false);
                setPickUpTime(null);
              }}
            />
          </div>
        </div>
      ) : (
        <div className='addTimeRangeButton'>
          <DTextButton
            title={t("shipper.buttons.Add time")}
            rightIcon={<FaRegClock />}
            info
            onClick={() => setShowPickUpTime(true)}
          />
        </div>
      )}
    </div>
  );
};

export default RoadLocationTime;
