import React from "react";
import "./CustomersListsHeader.scss";

import { useTranslation } from "react-i18next";
import { FaPlusCircle } from "react-icons/fa";

import DButton from "../../../Form/DButtons/DButton/DButton";
import DSearch from "../../../FilterForms/DSearch/DSearch";

const CustomersListsHeader = ({ keyword, setKeyword, setAddModalShow }) => {
  const { t } = useTranslation();
  return (
    <div id='CustomersListsHeader'>
      <DSearch value={keyword} setValue={setKeyword} />
      <DButton
        onClick={() => setAddModalShow(true)}
        title={t("tms.forms.buttons.Add customer")}
        rightIcon={<FaPlusCircle />}
      />
    </div>
  );
};

export default CustomersListsHeader;
