import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import driversService from "./driversService";

const initialState = {
  getDriversLoading: true,
  getDriversResponce: null,
  getDriversError: null,

  getDriverLoading: true,
  getDriverResponce: null,
  getDriverError: null,

  createDriverLoading: false,
  createDriverResponce: null,
  createDriverError: null,

  updateDriverLoading: false,
  updateDriverResponce: null,
  updateDriverError: null,

  deleteDriverLoading: false,
  deleteDriverResponce: null,
  deleteDriverError: null,
};

export const getDrivers = createAsyncThunk(
  "drivers/getDrivers",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await driversService.getDrivers(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDriver = createAsyncThunk(
  "drivers/getDriver",
  async (id, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await driversService.getDriver(ISO, TOKEN, id);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createDriver = createAsyncThunk(
  "drivers/createDriver",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await driversService.createDriver(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateDriver = createAsyncThunk(
  "drivers/updateDriver",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await driversService.updateDriver(
        ISO,
        TOKEN,
        data.id.toString(),
        data
      );
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteDriver = createAsyncThunk(
  "drivers/deleteDriver",
  async (id, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await driversService.deleteDriver(ISO, TOKEN, id);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const driversSlice = createSlice({
  name: "drivers",
  initialState,
  reducers: {
    resetGetDrivers: (state) => {
      state.getDriversLoading = false;
      state.getDriversResponce = null;
      state.getDriversError = null;
    },
    resetCreateDriver: (state) => {
      state.createDriverLoading = false;
      state.createDriverResponce = null;
      state.createDriverError = null;
    },
    resetUpdateDriver: (state) => {
      state.updateDriverLoading = false;
      state.updateDriverResponce = null;
      state.updateDriverError = null;
    },
    resetDeleteDriver: (state) => {
      state.deleteDriverLoading = false;
      state.deleteDriverResponce = null;
      state.deleteDriverError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDrivers.pending, (state) => {
        state.getDriversLoading = true;
        state.getDriversResponce = null;
        state.getDriversError = null;
      })
      .addCase(getDrivers.fulfilled, (state, action) => {
        state.getDriversLoading = false;
        state.getDriversResponce = action.payload;
        state.getDriversError = null;
      })
      .addCase(getDrivers.rejected, (state, action) => {
        state.getDriversLoading = false;
        state.getDriversResponce = null;
        state.getDriversError = action.payload;
      })

      .addCase(getDriver.pending, (state) => {
        state.getDriverLoading = true;
        state.getDriverResponce = null;
        state.getDriverError = null;
      })
      .addCase(getDriver.fulfilled, (state, action) => {
        state.getDriverLoading = false;
        state.getDriverResponce = action.payload;
        state.getDriverError = null;
      })
      .addCase(getDriver.rejected, (state, action) => {
        state.getDriverLoading = false;
        state.getDriverResponce = null;
        state.getDriverError = action.payload;
      })

      .addCase(createDriver.pending, (state) => {
        state.createDriverLoading = true;
        state.createDriverResponce = null;
        state.createDriverError = null;
      })
      .addCase(createDriver.fulfilled, (state, action) => {
        state.createDriverLoading = false;
        state.createDriverResponce = action.payload;
        state.createDriverError = null;
      })
      .addCase(createDriver.rejected, (state, action) => {
        state.createDriverLoading = false;
        state.createDriverResponce = null;
        state.createDriverError = action.payload;
      })

      .addCase(updateDriver.pending, (state) => {
        state.updateDriverLoading = true;
        state.updateDriverResponce = null;
        state.updateDriverError = null;
      })
      .addCase(updateDriver.fulfilled, (state, action) => {
        state.updateDriverLoading = false;
        state.updateDriverResponce = action.payload;
        state.updateDriverError = null;
      })
      .addCase(updateDriver.rejected, (state, action) => {
        state.updateDriverLoading = false;
        state.updateDriverResponce = null;
        state.updateDriverError = action.payload;
      })

      .addCase(deleteDriver.pending, (state) => {
        state.deleteDriverLoading = true;
        state.deleteDriverResponce = null;
        state.deleteDriverError = null;
      })
      .addCase(deleteDriver.fulfilled, (state, action) => {
        state.deleteDriverLoading = false;
        state.deleteDriverResponce = action.payload;
        state.deleteDriverError = null;
      })
      .addCase(deleteDriver.rejected, (state, action) => {
        state.deleteDriverLoading = false;
        state.deleteDriverResponce = null;
        state.deleteDriverError = action.payload;
      });
  },
});

export const {
  resetGetDrivers,
  resetCreateDriver,
  resetUpdateDriver,
  resetDeleteDriver,
} = driversSlice.actions;
export default driversSlice.reducer;
