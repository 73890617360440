import React, { useState, useEffect } from "react";
import "./AccountantEmailEditModal.scss";

import { useTranslation } from "react-i18next";
import { MdOutlineGroupAdd, MdOutlineEmail } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";

import {
  updateContact,
  resetUpdateContact,
} from "./../../../../../../store/user/userContacts/userContactsSlice";
import { useContactsErrors } from "../../../../../../validations/useContactsErrors/useContactsErrors";

import DModal from "./../../../../../Modal/DModal/DModal";
import DInputs from "./../../../../../Form/DInputs/DInputs";

const AccountantEmailEditModal = ({ isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { updateContactLoading, updateContactResponce } = useSelector(
    (state) => state.userContacts
  );
  const findAccountant =
    user?.contacts?.find((contact) => contact?.type?.id === 1) || null;

  const [email, setEmail] = useState(findAccountant?.email || "");

  const { update_email_error } = useContactsErrors();

  useEffect(() => {
    if (updateContactResponce) {
      dispatch(resetUpdateContact());
      isClose();
    }
  }, [dispatch, isClose, updateContactResponce]);

  const submitHanlder = () => {
    const data = {
      id: findAccountant?.id || null,
      type_id: 1,
      email: email,
    };
    dispatch(updateContact(data));
  };

  return (
    <DModal
      status={"aditionalInfo"}
      isOpen={isOpen}
      isClose={isClose}
      icon={<MdOutlineGroupAdd />}
      title={t("shipper.modal.Edit accountant email")}
      submitText={t("shipper.buttons.Save")}
      onClick={() => submitHanlder()}
      loading={updateContactLoading}
    >
      <div id='AccountantEmailEditModal'>
        <div className='AccountantEmailEditModalWrapper'>
          <DInputs
            id='AccountantEmailEditModalEmail'
            placeholder={t("shipper.modal.Enter email")}
            type='email'
            label={t("shipper.modal.Email")}
            value={email}
            setValue={setEmail}
            icon={<MdOutlineEmail />}
            error={update_email_error}
            required
          />
        </div>
      </div>
    </DModal>
  );
};

export default AccountantEmailEditModal;
