import _request from "./../_request";

const getDrivers = async (ISO, TOKEN, data) => {
  let url = "tracker/drivers?";
  const { linked_to } = data || {};
  url += linked_to ? `&linked_to=${linked_to}` : "";
  const config = {
    url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const sendInvitation = async (ISO, TOKEN, driver_id) => {
  let url = `tracker/send-invitation/${driver_id}`;
  const config = {
    url,
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const APPTrackerService = {
  getDrivers,
  sendInvitation,
};

export default APPTrackerService;
