import React from "react";
import "./Step3.scss";

import { useTranslation } from "react-i18next";
import { useSubscribeErrors } from "../../../../../validations/subscribe/useSubscribeErrors";

import Title from "../Components/Title/Title";
import SubTitle from "../Components/SubTitle/SubTitle";
import ProgressBar from "../Components/ProgressBar/ProgressBar";
import Label from "../../../../Form/Label/Label";
import Input from "../../../../Form/Inputs/Input";
import PhoneInput from "../../../../Form/Inputs/PhoneInput/PhoneInput";
import SelectForm from "../../../../Form/Select/SelectForm";
import Button from "../../../../Form/Button/Button";
import ErrorText from "../../../../Form/ErrorText/ErrorText";

const Step3 = ({
  step,
  setStep,
  name,
  phone,
  email,
  communicationMethod,
  setName,
  setPhone,
  setEmail,
  setCommunicationMethod,
  handleSubmit,
  loading,
}) => {
  const { t } = useTranslation();
  const { fullname_error, phone_error, phone_index_error, email_error, contact_channel_error } =
    useSubscribeErrors();
  return (
    <div id='CarrierSubscribeFormStep3'>
      <Title back={true} setStep={setStep} />
      <SubTitle text={t("landing.landingCarrierSubscribe.It just takes 2 minutes to set up")} />
      <ProgressBar percent={70} />
      <div>
        <Label
          text={t("landing.landingCarrierSubscribe.Your name")}
          id={"CarrierSubscribeFormStep3Name"}
        />
        <Input
          id={"CarrierSubscribeFormStep3Name"}
          value={name}
          setValue={setName}
          placeholder={t("landing.landingCarrierSubscribe.Enter name")}
          error={fullname_error}
        />
        <ErrorText error={fullname_error} />
      </div>
      <div>
        <Label
          text={t("landing.landingCarrierSubscribe.Contact phone number")}
          id={"CarrierSubscribeFormStep3Phone"}
        />
        <PhoneInput
          id={"CarrierSubscribeFormStep3Phone"}
          value={phone}
          setValue={setPhone}
          placeholder={t("landing.landingCarrierSubscribe.Enter phone number")}
          error={phone_error || phone_index_error}
        />
        <ErrorText error={phone_error || phone_index_error} />
      </div>
      <div>
        <Label
          text={t("landing.landingCarrierSubscribe.Contact email")}
          id={"CarrierSubscribeFormStep3Email"}
        />
        <Input
          id={"CarrierSubscribeFormStep3Email"}
          value={email}
          setValue={setEmail}
          placeholder={t("landing.landingCarrierSubscribe.Enter email")}
          type={"email"}
          error={email_error}
        />
        <ErrorText error={email_error} />
      </div>
      <div>
        <Label
          text={t("landing.landingCarrierSubscribe.Preferred method of communication")}
          id={"CarrierSubscribeFormStep3CommunicationMethod"}
        />
        <SelectForm
          id={"CarrierSubscribeFormStep3CommunicationMethod"}
          options={[
            {
              value: "phone",
              label: t("landing.landingCarrierSubscribe.On WhatsApp"),
            },
            {
              value: "email",
              label: t("landing.landingCarrierSubscribe.Email"),
            },
          ]}
          value={communicationMethod}
          setValue={setCommunicationMethod}
          error={contact_channel_error}
        />
        <ErrorText error={contact_channel_error} />
      </div>

      <Button
        title={t("landing.landingCarrierSubscribe.Let’s verify the communicationMethod", {
          communicationMethod:
            communicationMethod.value === "phone"
              ? t("landing.landingCarrierSubscribe.phone")
              : t("landing.landingCarrierSubscribe.email"),
        })}
        onClick={handleSubmit}
        loading={loading}
      />
    </div>
  );
};

export default Step3;
