import React from "react";
import "./DCargoTypesSelect.scss";

import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

import DSelect from "./../DSelect";

const DCargoTypesSelect = ({
  value,
  setValue,
  error,
  required,
  label,
  placeholder,
  leftIcon,
}) => {
  const { t } = useTranslation();

  const { cargoTypesLoading, cargoTypesSelectResponce } = useSelector(
    (state) => state.orderSettings
  );

  const filterOption = (option, inputValue) => {
    const { value } = option;
    return value.name.toLowerCase().includes(inputValue.toLowerCase());
  };

  return (
    <DSelect
      id='DCargoTypesSelect'
      label={label ? label : t("tms.forms.labels.Product category")}
      placeholder={
        placeholder
          ? placeholder
          : t("tms.forms.placeholder.Choose product category")
      }
      required={required}
      value={value}
      setValue={setValue}
      loading={cargoTypesLoading}
      search={true}
      error={error}
      filterOption={filterOption}
      options={cargoTypesSelectResponce}
      leftIcon={leftIcon ? leftIcon : null}
    />
  );
};

export default DCargoTypesSelect;
