import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { MdOutlineEmail } from "react-icons/md";

import { useDispatch, useSelector } from "react-redux";
import {
  resetSendInvitation,
  sendInvitation,
} from "../../../../../../store/APPTracker/APPTrackerSlice";

import DModal from "../../../../../Modal/DModal/DModal";

const SendInvitationModal = ({ isOpen, isClose, driverId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { sendInvitationResponce, sendInvitationLoading } = useSelector(
    (state) => state.APPTracker
  );

  const submitHandler = () => {
    dispatch(sendInvitation(driverId));
  };

  useEffect(() => {
    if (sendInvitationResponce) {
      dispatch(resetSendInvitation());
      isClose();
    }
  }, [dispatch, sendInvitationResponce, isClose]);

  return (
    <DModal
      isOpen={isOpen}
      isClose={isClose}
      onClick={() => submitHandler()}
      title={t("tms.modals.titles.Send invitation")}
      submitText={t("tms.modals.buttons.Send")}
      icon={<MdOutlineEmail />}
      status='success'
      loading={sendInvitationLoading}
    >
      <p>{t("tms.modals.desc.Are you sure you want to send invitation?")}</p>
    </DModal>
  );
};

export default SendInvitationModal;
