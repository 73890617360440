import React from "react";
import "./SignInCarrierVerify.scss";

import { useTranslation } from "react-i18next";

import Wrapper from "./../../../../componentsAdditional/Wrapper";
import AuthCover from "./../../../../componentsLanding/Auth&Password/Components/AuthCover/AuthCover";
import SignInCarrierVerifyForm from "../../../../componentsLanding/Auth&Password/LoginForm/SignInCarrierVerifyForm/SignInCarrierVerifyForm";

const SignInCarrierVerify = () => {
  const { t } = useTranslation();

  return (
    <Wrapper documentTitle={t("landing.documentTitles.Sign Up carrier")}>
      <section id='SignInCarrierVerify'>
        <AuthCover url='/assets/images/static/cover4.jpg' />
        <SignInCarrierVerifyForm />
      </section>
    </Wrapper>
  );
};

export default SignInCarrierVerify;
