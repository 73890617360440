import React, { Fragment, useState } from "react";
import "./FilledAssignCarrier.scss";

import { useTranslation } from "react-i18next";

import { MdLinkOff } from "react-icons/md";
import { IoIosWarning } from "react-icons/io";
import { FaPhone } from "react-icons/fa6";

import DButton from "../../../Form/DButtons/DButton/DButton";
import FillCarrierModal from "./FillCarrierModal/FillCarrierModal";
import LinkOffPartnerModal from "../LinkOffPartnerModal/LinkOffPartnerModal";

const FilledAssignCarrier = ({ order }) => {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const [openLinkOffModal, setOpenLinkOffModal] = useState(false);

  return (
    <Fragment>
      {order.carrier &&
        order.carrier.partner &&
        (!order.carrier.trailer_registration_number ||
          !order.carrier.truck_registration_number) && (
          <Fragment>
            <div id='FilledAssignCarrier'>
              <div className='FilledAssignCarrierContainer'>
                <div
                  className='detachIcon'
                  onClick={() => setOpenLinkOffModal(true)}
                >
                  <MdLinkOff />
                </div>

                <div className='FilledAssignCarrierContent'>
                  <h1>{order?.carrier?.partner?.company?.name || ""}</h1>
                  {order?.carrier?.partner?.company?.phone && (
                    <div className='FilledAssignCarrierPhone'>
                      <div className='icon'>
                        <FaPhone />
                      </div>
                      {order?.carrier?.partner?.company?.phone_index?.name ||
                        ""}
                      {order?.carrier?.partner?.company?.phone || ""}
                    </div>
                  )}
                  <div className='FillInformation'>
                    <DButton
                      title={t("tms.forms.buttons.Truck Registration Number")}
                      onClick={() => {
                        setOpenModal(true);
                      }}
                    />
                    <div className='date'>
                      <div className='icon'>
                        <IoIosWarning />
                      </div>
                      <h4 className='description'>
                        {t("tms.desc.fill in truck registration number until")}{" "}
                        <span>{order?.cargos[0]?.pickup_date || ""}</span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FillCarrierModal
              order={order}
              isOpen={openModal}
              isClose={setOpenModal}
            />
            <LinkOffPartnerModal
              order={order}
              isOpen={openLinkOffModal}
              isClose={setOpenLinkOffModal}
            />
          </Fragment>
        )}
    </Fragment>
  );
};

export default FilledAssignCarrier;
