import React, { Fragment, useState } from "react";
import "./SideViewHeader.scss";

import { useTranslation } from "react-i18next";

import { IoFilter } from "react-icons/io5";
import { useSelector } from "react-redux";
import { BiReset } from "react-icons/bi";

import MainTitle from "./../../../MainTitle/MainTitle";
import ViewSwitcher from "./../UI/ViewSwitcher/ViewSwitcher";
import DSearch from "./../../../FilterForms/DSearch/DSearch";
import FilterModal from "../FilterModal/FilterModal";

const SideViewHeader = ({
  keyword,
  setKeyword,
  status,
  setStatus,
  shippingType,
  setShippingType,
  dateRange,
  setDateRange,
  route,
  setRoute,
  trailerType,
  setTrailerType,
  productCategory,
  setProductCategory,
  onClear,
}) => {
  const { t } = useTranslation();
  const [showFilterModal, setShowFilterModal] = useState(false);
  const { getCargoStatusesResponce } = useSelector((state) => state.cargos);

  let filteredStatuses = getCargoStatusesResponce?.filter(
    (status) => status?.listed
  );
  if (filteredStatuses === undefined) {
    filteredStatuses = [];
  }
  filteredStatuses = [{ id: null, name: t("shipper.filters.All") }, ...filteredStatuses];

  return (
    <Fragment>
      {getCargoStatusesResponce && (
        <Fragment>
          <div id='shipperOrdersSidebarHeader'>
            <div className='header'>
              <MainTitle title={t("shipper.titles.Active shipments")} />
              <ViewSwitcher />
            </div>

            <div className='filterContainer'>
              <DSearch value={keyword} setValue={setKeyword} />
              <div
                className='filterButtonSide'
                onClick={() => setShowFilterModal(true)}
              >
                <h6>{t("shipper.filters.Filter")}</h6>
                <div className='icon'>
                  <IoFilter />
                </div>
              </div>
              <div className='clearBtn' onClick={() => onClear()}>
                <BiReset />
              </div>
            </div>

            <div className='statusesContainer'>
              <div className='statusesContainerWrapper'>
                {filteredStatuses.map((i, index) => (
                  <div
                    key={index}
                    className={i.id === status.id ? "status active" : "status"}
                    onClick={() => setStatus(i)}
                  >
                    <h4>{i.name}</h4>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {showFilterModal && (
            <FilterModal
              isOpen={showFilterModal}
              isClose={() => setShowFilterModal(false)}
              keyword={keyword}
              setKeyword={setKeyword}
              status={status}
              setStatus={setStatus}
              shippingType={shippingType}
              setShippingType={setShippingType}
              dateRange={dateRange}
              setDateRange={setDateRange}
              route={route}
              setRoute={setRoute}
              trailerType={trailerType}
              setTrailerType={setTrailerType}
              productCategory={productCategory}
              setProductCategory={setProductCategory}
              onClear={onClear}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default SideViewHeader;
