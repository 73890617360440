import React from "react";
import "./NoTruck.scss";

import { useTranslation } from "react-i18next";

const NoTruck = ({ order }) => {
  const { t } = useTranslation();
  return (
    <div id='NoTruck'>
      <div className='NoTruckContainer'>
        <div
          className='left'
          style={{
            backgroundImage: `url(${`/assets/images/dashboard/cover9.svg`})`,
          }}
        ></div>
        <div className='right'>
          <h1>{t("tms.titles.Truck Required")}</h1>
          <div className='NoTruckContainerContent'>
            <h3>
              {t("tms.titles.Trailer")}:{" "}
              <span>
                {(order.trailer_type.name && order.trailer_type.name) || "-"}
              </span>
            </h3>
            <h3>
              {t("tms.titles.Trailer volume")}:{" "}
              <span>
                {(order.trailer_size.label && order.trailer_size.label) || "-"}
              </span>
            </h3>
            {order.temperature && (
              <h3>
                {t("tms.titles.Temperature")}:{" "}
                <span>
                  {(order.temperature && order.temperature.title) || "-"}
                </span>
              </h3>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoTruck;
