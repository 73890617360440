import React from "react";
import "./ProgressBar.scss";

const ProgressBar = ({ percent }) => {
  return (
    <div id='CarrierSubscribeFormProgressBar'>
      <div className='progressBar'>
        <div className='progress' style={{ width: `${percent}%` }}></div>

        <div className='dot'></div>
        <div className='dot' style={{ left: percent >= 50 ? "50%" : "0%" }}></div>
      </div>
    </div>
  );
};

export default ProgressBar;
