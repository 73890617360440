import { useSelector } from "react-redux";

export const useContactsErrors = () => {
  const { createContactError, updateContactError } = useSelector(
    (state) => state.userContacts
  );

  const create_email_error =
    createContactError &&
    createContactError.errors &&
    createContactError.errors.email &&
    createContactError.errors.email[0];

  const update_email_error =
    updateContactError &&
    updateContactError.errors &&
    updateContactError.errors.email &&
    updateContactError.errors.email[0];

  return { create_email_error, update_email_error };
};
