import React from "react";
import "./AddressDetailsShipper.scss";

import { useTranslation } from "react-i18next";

import { TbRoute } from "react-icons/tb";
import {
  MdCalendarToday,
  MdLocationPin,
  MdLocationSearching,
} from "react-icons/md";
import { FaAnchor, FaRegClock } from "react-icons/fa";
import { PiAirplane } from "react-icons/pi";

import Character from "../../../UI/Character/Character";

const AddressDetailsItemShipper = ({ content, icon }) => {
  return (
    <div className='AddressDetailsItemShipper'>
      <div className='icon'>{icon}</div>
      <div className='content'>
        {content ? <Character title={content} max={45} /> : "-"}
      </div>
    </div>
  );
};

const AddressDetailsShipper = ({ orderResponce }) => {
  const { t } = useTranslation();

  const locations = orderResponce?.cargos[0]?.locations || [];
  const pickUpDate = orderResponce?.cargos[0]?.pickup_date || "";
  const dropOffDate = orderResponce?.cargos[0]?.dropoff_date || "";
  const directionA = locations?.filter((location) => {
    return location.direction === "A";
  });
  const directionB = locations?.filter((location) => {
    return location.direction === "B";
  });
  const pickUpTimeRange = orderResponce?.cargos[0]?.pickup_time_range
    ? orderResponce?.cargos[0]?.pickup_time_range?.start +
      " - " +
      orderResponce?.cargos[0]?.pickup_time_range?.end
    : "";
  const dropOffTimeRange = orderResponce?.cargos[0]?.dropoff_time_range
    ? orderResponce?.cargos[0]?.dropoff_time_range?.start +
      " - " +
      orderResponce?.cargos[0]?.dropoff_time_range?.end
    : "";

  return (
    <div id='AddressDetailsShipper'>
      <div className='titleContainer'>
        <div className='titleContainerLeft'>
          <div className='icon'>
            <TbRoute />
          </div>
          <div className='title'>
            <h1>{t("shipper.forms.titles.Route")}</h1>
          </div>
        </div>
        {orderResponce.type ? (
          <div className='titleContainerRight'>{orderResponce.type?.name}</div>
        ) : null}
      </div>
      <div className='items'>
        <div className='from'>
          <div className='title'>
            <h3>{t("shipper.forms.content.From")}</h3>
            {pickUpDate && (
              <div className='timeContainer'>
                <div className='dateCont'>
                  <div className='icon'>
                    <MdCalendarToday />
                  </div>
                  <div className='time'>{pickUpDate}</div>
                </div>
                {pickUpTimeRange && (
                  <div className='timeCont'>
                    <div className='icon'>
                      <FaRegClock />
                    </div>
                    <div className='time'>{pickUpTimeRange}</div>
                  </div>
                )}
              </div>
            )}
          </div>
          {directionA && directionA.length > 0 ? (
            directionA.map((item, i) => {
              const itemType =
                item?.type === "PORT" || item?.type === "AIRPORT"
                  ? ` (${item?.type?.toLowerCase()})`
                  : "";

              const icon =
                item?.type === "PORT" ? (
                  <FaAnchor />
                ) : item?.type === "AIRPORT" ? (
                  <PiAirplane />
                ) : (
                  <MdLocationPin />
                );

              return (
                <AddressDetailsItemShipper
                  content={item.formatted_address + itemType}
                  key={i}
                  icon={icon}
                />
              );
            })
          ) : (
            <AddressDetailsItemShipper
              content=''
              icon={<MdLocationSearching />}
            />
          )}
        </div>
        <div className='to'>
          <div className='title'>
            <h3>{t("shipper.forms.content.To")}</h3>
            {dropOffDate && (
              <div className='timeContainer'>
                <div className='dateCont'>
                  <div className='icon'>
                    <MdCalendarToday />
                  </div>
                  <div className='time'>{dropOffDate}</div>
                </div>
                {dropOffTimeRange && (
                  <div className='timeCont'>
                    <div className='icon'>
                      <FaRegClock />
                    </div>
                    <div className='time'>{dropOffTimeRange}</div>
                  </div>
                )}
              </div>
            )}
          </div>
          {directionB && directionB.length > 0 ? (
            directionB.map((item, i) => {
              const itemType =
                item?.type === "PORT" || item?.type === "AIRPORT"
                  ? ` (${item?.type?.toLowerCase()})`
                  : "";

              const icon =
                item?.type === "PORT" ? (
                  <FaAnchor />
                ) : item?.type === "AIRPORT" ? (
                  <PiAirplane />
                ) : (
                  <MdLocationPin />
                );

              return (
                <AddressDetailsItemShipper
                  content={item.formatted_address + itemType}
                  key={i}
                  icon={icon}
                />
              );
            })
          ) : (
            <AddressDetailsItemShipper content='-' icon={<MdLocationPin />} />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddressDetailsShipper;
