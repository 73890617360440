import _request from "./../../_request";

const getOrderDocuments = async (ISO, TOKEN, orderId) => {
  const config = {
    url: `/order/${orderId}/documents`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const createOrderDocument = async (ISO, TOKEN, orderId, data) => {
  const config = {
    url: `order/${orderId}/documents/`,
    method: "post",
    token: TOKEN,
    ISO,
    file: true,
  };
  return _request(config, data);
};

const deleteOrderDocument = async (ISO, TOKEN, orderId, documentId) => {
  const config = {
    url: `order/${orderId}/documents/${documentId}`,
    method: "delete",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getOrderDocumentTypes = async (ISO, TOKEN) => {
  const config = {
    url: "/order/document-types",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getInvoiceData = async (ISO, TOKEN, orderId, currencyId) => {
  const config = {
    url: `/order/${orderId}/invoice-data/${currencyId}`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const createInvoice = async (ISO, TOKEN, orderId, data) => {
  const config = {
    url: `order/${orderId}/make-invoice`,
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const orderDocumentsService = {
  getOrderDocuments,
  createOrderDocument,
  deleteOrderDocument,
  getOrderDocumentTypes,
  getInvoiceData,
  createInvoice,
};

export default orderDocumentsService;
