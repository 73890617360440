import React, { Fragment, useState, useEffect } from "react";
import "./PaymentModal.scss";

import { useTranslation } from "react-i18next";
import { MdMonetizationOn } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
import { FiX } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";

import { useMoney } from "../../../../../helper/useMoney";
import { getOrderCosts } from "./../../../../../store/orders/costs/orderCostsSlice";

import DOverlay from "../../../../Modal/DOverlay/DOverlay";
import LoadingPage from "../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import { useOrderPaymentSelectSubOptions } from "./../../../../../helper/useOrderPaymentSelectSubOptions";

const PaymentModal = ({ isOpen, isClose, item }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const paymentSubOptions = useOrderPaymentSelectSubOptions();

  const { getOrderCostsResponce, getOrderCostsLoading } = useSelector(
    (state) => state.orderCosts
  );

  const [openAdditionalCosts, setOpenAdditionalCosts] = useState(false);

  const handleAdditionalCosts = () => {
    setOpenAdditionalCosts(!openAdditionalCosts);
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(getOrderCosts(item?.id));
    }
  }, [dispatch, item, isOpen]);

  const formatedOrderPrice = useMoney(
    item?.revenue?.amount || 0,
    item?.revenue?.currency?.symbol || ""
  );

  const formatedCostsPrice = useMoney(
    item?.extra_total_revenue || 0,
    item?.revenue?.currency?.symbol || ""
  );

  const formatedTotalPrice = useMoney(
    item?.total_revenue || 0,
    item?.revenue?.currency?.symbol || ""
  );

  const paymentMethod = item?.paymentType?.type?.name || null;

  const formattedMoney = useMoney(
    item?.paymentType?.amount || 0,
    item?.paymentType?.currency_id?.symbol || "-"
  );

  const findOrderPaymentSub =
    paymentSubOptions.find(
      (i) => i?.value?.id === item?.paymentType?.option_type
    )?.label || null;

  const formatedConsignation = item.paymentType?.days?.toString() || null;

  return (
    <DOverlay isOpen={isOpen} isClose={isClose}>
      <div id='PaymentModal'>
        {getOrderCostsLoading ? (
          <LoadingPage />
        ) : (
          <Fragment>
            <div
              className='header'
              style={{
                borderBottom: "2px solid #EAEBEB",
                backgroundColor: "#ffffff",
              }}
            >
              <div className='title'>
                <div className='titleIcon' style={{ color: "#848A8D" }}>
                  <MdMonetizationOn />
                </div>
                <h2 style={{ color: "#09101D" }}>
                  {t("tms.modals.titles.Total payment / payment method")}
                </h2>
              </div>
              <div className='closeIcon' onClick={isClose}>
                <FiX />
              </div>
            </div>
            <div className='content'>
              <div className='contentOrder'>
                <div className='contentOrderPrices'>
                  <div className='contentOrderPricesLeft'>
                    <div className='contentOrderPricesLeftItem'>
                      <h3>{t("tms.modals.titles.Order price")}</h3>
                      <h2>{formatedOrderPrice}</h2>
                    </div>
                    <div className='contentOrderPricesLeftItem'>
                      <h3>{t("tms.modals.titles.Additional costs")}</h3>
                      <h2>{formatedCostsPrice}</h2>
                    </div>
                  </div>
                  <div className='contentOrderPricesRight'>
                    <h3>{t("tms.modals.titles.Total price")}</h3>
                    <h2>{formatedTotalPrice}</h2>
                  </div>
                </div>
                <div className='contentOrderAdditionalCostsContainer'>
                  <div
                    className={`contentOrderAdditionalCosts ${
                      openAdditionalCosts && "open"
                    }`}
                    onClick={handleAdditionalCosts}
                  >
                    <IoMdEye />
                    <div className='contentOrderAdditionalCostsText'>
                      {openAdditionalCosts
                        ? t("tms.modals.buttons.Hide")
                        : t("tms.modals.buttons.Show")}{" "}
                      {t("tms.modals.buttons.additional costs")}
                    </div>
                  </div>
                </div>
                {openAdditionalCosts &&
                  !getOrderCostsLoading &&
                  getOrderCostsResponce && (
                    <div className='contentOrderAdditionalCostsContent'>
                      {getOrderCostsResponce?.length > 0 ? (
                        getOrderCostsResponce?.map(
                          (item) =>
                            item?.operation === "IN" && (
                              <div
                                key={item?.id}
                                className='contentOrderAdditionalCostsContentItem'
                              >
                                <div className='contentOrderAdditionalCostsContentItemName'>
                                  {item?.category?.name}
                                </div>
                                <div className='contentOrderAdditionalCostsContentItemComment'>
                                  {item?.comment ||
                                    t("tms.modals.others.No comment")}
                                </div>
                                <div
                                  className={
                                    "contentOrderAdditionalCostsContentItemPrice"
                                  }
                                >
                                  {
                                    // eslint-disable-next-line react-hooks/rules-of-hooks
                                    useMoney(
                                      item?.amount,
                                      item?.currency?.symbol
                                    )
                                  }
                                </div>
                              </div>
                            )
                        )
                      ) : (
                        <p className='noData'>
                          {t("tms.modals.others.No additional costs")}
                        </p>
                      )}
                    </div>
                  )}
              </div>
              <div className='contentPayment'>
                <div className='contentPaymentContainer'>
                  {paymentMethod && (
                    <h3>
                      {paymentMethod}
                      {item?.paymentType?.amount && (
                        <span> ({formattedMoney})</span>
                      )}
                    </h3>
                  )}
                  <h3>
                    {t("tms.titles.Condition")}:{" "}
                    <span>{findOrderPaymentSub}</span>
                  </h3>
                  {formatedConsignation && (
                    <h3>
                      {t("tms.titles.Consignation")}:
                      <span>{`${formatedConsignation || " "} ${t(
                        "tms.desc.days"
                      )}`}</span>
                    </h3>
                  )}
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </DOverlay>
  );
};

export default PaymentModal;
