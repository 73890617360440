import React, { Fragment } from "react";
import "./CreateOrderAditionalServices.scss";

import { useSelector } from "react-redux";

import CreateOrderSectionTitle from "../CreateOrderSectionTitle/CreateOrderSectionTitle";
import CreateOrderAditionalServicesItem from "./CreateOrderAditionalServicesItem/CreateOrderAditionalServicesItem";

const CreateOrderAditionalServices = ({
  selectedServices,
  setSelectedServices,
  title,
}) => {
  const { getOrderServicesResponce } = useSelector(
    (state) => state.orderSettings
  );

  return (
    <Fragment>
      {getOrderServicesResponce && (
        <div id='CreateOrderAditionalServices'>
          <CreateOrderSectionTitle title={title} />

          <div className='listContainer'>
            {getOrderServicesResponce.map((item, index) => (
              <CreateOrderAditionalServicesItem
                key={index}
                item={item}
                selectedServices={selectedServices}
                setSelectedServices={setSelectedServices}
              />
            ))}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default CreateOrderAditionalServices;
