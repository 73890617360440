import React, { Fragment, useEffect } from "react";
import "./PartnerCompanies.scss";

import parse from "html-react-parser";
import Marquee from "react-fast-marquee";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { getPartners } from "../../../store/additional/partners/partnersSlice";
import SmallHomeTitle from "../Components/SmallHomeTitle/SmallHomeTitle";

const PartnerCompanies = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { partners } = useSelector((state) => state.partners);

  useEffect(() => {
    dispatch(getPartners());
  }, [dispatch]);

  const RenderItem = ({ item }) => {
    return (
      <div className='item'>
        <Link
          to={item.url ? item.url : ""}
          target={item.url ? "_blank" : ""}
          rel='noopener noreferrer'
          title={item.name}
        >
          {item.logo && (
            <img src={item.logo} alt={item.name ? item.name : ""} />
          )}
        </Link>
      </div>
    );
  };



  return (
    <Fragment>
      {partners && partners.length > 0 && (
        <section id='PartnerCompanies' className='PartnerCompanies'>
          <div className='wrapper'>
            <SmallHomeTitle
              title={parse(
                t(
                  "landing.titles.landingMainTitles.Industry leaders trust Cargon"
                )
              )}
            />

            <div className='container'>
              <Marquee
                direction='right'
                autoFill={true}
                pauseOnHover={true}
                pauseOnClick={true}
              >
                {partners.map((item, index) => (
                  <RenderItem item={item} key={index} />
                ))}
              </Marquee>
            </div>
          </div>
        </section>
      )}
    </Fragment>
  );
};

export default PartnerCompanies;
