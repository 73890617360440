import React, { useState, useEffect } from "react";
import "./AddOrder.scss";

import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import {
  createOrder,
  resetCreateOrder,
  groupOrder,
  resetGroupOrder,
} from "../../../../../store/orders/ordersSlice";
import { useCreateOrderErrors } from "./../../../../../validations/order/useCreateOrderErrors";
import DBackButton from "../../../../../componentsDashboard/Form/DButtons/DBackButton/DBackButton";
import Wrapper from "../../../../../componentsAdditional/Wrapper";
import RouteNewOrder from "../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderTMC/RouteNewOrder/RouteNewOrder";
import TrailersNewOrder from "../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderTMC/TrailersNewOrder/TrailersNewOrder";
import PaymentNewOrder from "../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderTMC/PaymentNewOrder/PaymentNewOrder";
import AditionalInformation from "../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderTMC/AditionalInformation/AditionalInformation";
import ProductNewOrder from "../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderTMC/ProductNewOrder/ProductNewOrder";
import DButton from "../../../../../componentsDashboard/Form/DButtons/DButton/DButton";

const AddOrder = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    createLoading,
    createError,
    createResponce,
    groupLoading,
    groupResponce,
    createErrorArray,
    createResponceArray,
  } = useSelector((state) => state.orders);

  const { orderTypesSelectResponce, orderPaymentTypesSelect } = useSelector(
    (state) => state.orderSettings
  );
  const { user } = useSelector((state) => state.auth);
  const { currenciesSelect } = useSelector((state) => state.settings);
  const {
    type_id_error,
    customer_id_error,
    trailer_type_id_error,
    trailer_size_id_error,
    cargos_type_id_error,
    cargos_weight_error,
    cargos_adr_error,
    cargos_pickup_date_error,
    payment_option_type_error,
    payment_type_id_error,
    payment_days_error,
    payment_amount_error,
    payment_amount_currency_error,
  } = useCreateOrderErrors();

  const [customer, setCustomer] = useState(null);
  const [orderType, setOrderType] = useState(null);
  const [productType, setProductType] = useState(null);
  const [adr, setAdr] = useState(false);
  const [adrUnNumber, setAdrUnNumber] = useState("");
  const [adrClass, setAdrClass] = useState("");
  const [comment, setComment] = useState("");

  const [locations, setLocations] = useState([
    { direction: "A", inputted_address: "", error: null },
    { direction: "B", inputted_address: "", error: null },
  ]);
  const [pickUpDate, setPickUpDate] = useState(null);
  const [pickUpTime, setPickUpTime] = useState(null);
  const [dropOffShow, setDropOffShow] = useState(false);
  const [dropOffDate, setDoprOffDate] = useState(null);
  const [dropOffTime, setDropOffTime] = useState(null);
  const [trailers, setTrailers] = useState([
    {
      trailerType: null,
      trailerSize: null,
      temperatureRange: null,
      weight: "",
      price: "",
      currency: null,
    },
  ]);
  const [payMethod, setPayMethod] = useState(null);
  const [payMethodSub, setPayMethodSub] = useState(null);
  const [payMethodAmount, setPayMethodAmount] = useState("");
  const [payMethodAmountCurrency, setPayMethodAmountCurrency] = useState(null);
  const [payConsignationPeriod, setPayConsignationPeriod] = useState("");

  // catch locations error
  useEffect(() => {
    if (createError && createError.errors) {
      const updatedLocations = locations.map((location, index) => {
        const errorKey = `cargos.0.locations.${index}.inputted_address`;
        const errorValue =
          createError.errors[errorKey] && createError.errors[errorKey][0]
            ? createError.errors[errorKey][0]
            : null;

        return {
          ...location,
          error: errorValue,
        };
      });
      setLocations(updatedLocations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createError]);

  // select default FTL
  useEffect(() => {
    if (orderTypesSelectResponce) {
      setOrderType(orderTypesSelectResponce[0]);
    }
  }, [orderTypesSelectResponce]);

  // select default payment type
  useEffect(() => {
    if (orderPaymentTypesSelect) {
      setPayMethod(orderPaymentTypesSelect[0]);
    }
  }, [orderPaymentTypesSelect]);

  // select default user currency value
  useEffect(() => {
    if (currenciesSelect && user.currency && user.currency.id) {
      const findValue = currenciesSelect.find((item) => item.value.id === user.currency.id);
      setTrailers((state) => state.map((i) => ({ ...i, currency: findValue })));
      setPayMethodAmountCurrency(findValue);
    }
  }, [currenciesSelect, user.currency]);

  // group orders request
  useEffect(() => {
    if (
      trailers.length > 1 &&
      trailers.length === createErrorArray.length + createResponceArray.length
    ) {
      const data = { orders: createResponceArray };
      dispatch(groupOrder(data));
    }
  }, [dispatch, navigate, trailers.length, createErrorArray.length, createResponceArray]);

  // reset all
  useEffect(() => {
    if (groupResponce) {
      dispatch(resetCreateOrder());
      dispatch(resetGroupOrder());
      navigate("/dashboard/tms/orders?page=1");
    }
  }, [dispatch, navigate, groupResponce]);

  useEffect(() => {
    if (createResponce && trailers.length === 1) {
      dispatch(resetCreateOrder());
      navigate("/dashboard/tms/orders?page=1");
    }
  }, [dispatch, navigate, createResponce, trailers.length]);

  const submitHandler = () => {
    const newData = trailers.map((order) => ({
      payment: {
        type_id: payMethod ? payMethod.value.id : null,
        days: payConsignationPeriod.length > 0 ? +payConsignationPeriod : null,
        option_type: payMethodSub ? payMethodSub.value.id : null,
        amount: payMethod && payMethod.value.id === 2 ? payMethodAmount : null,
        currency_id:
          payMethod && payMethod.value.id === 2 && payMethodAmountCurrency
            ? payMethodAmountCurrency.value.id
            : null,
      },
      revenue: {
        amount: order.price.length > 0 ? order.price : 0,
        currency_id: order.currency ? order.currency.value.id : null,
      },
      transfer_type_id: 1,
      type_id: orderType ? orderType.value.id : null,
      customer_id: customer ? customer.value.id : null,
      trailer_type_id: order.trailerType ? order.trailerType.value.id : null,
      trailer_size_id: order.trailerSize ? order.trailerSize.value.value.id : null,
      temperature_range_id: order.temperatureRange ? order.temperatureRange.value.id : null,
      comment: null,
      cargos: [
        {
          type_id: productType ? productType.value.id : null,
          weight: order.weight.length > 0 ? order.weight : null,
          volume: null,
          adr: adr,
          adr_class: adrClass.length > 0 ? adrClass : null,
          un_number: adrUnNumber.length > 0 ? adrUnNumber : null,
          about: comment.length > 0 ? comment : null,
          pickup_date: pickUpDate ? moment(pickUpDate).format("YYYY-MM-DD") : null,
          dropoff_date: dropOffDate ? moment(dropOffDate).format("YYYY-MM-DD") : null,
          pickup_time_range: pickUpTime ? { start: pickUpTime[0], end: pickUpTime[1] } : null,
          dropoff_time_range: dropOffTime ? { start: dropOffTime[0], end: dropOffTime[1] } : null,
          locations: locations,
        },
      ],
    }));
    newData.map((data) => dispatch(createOrder(data)));
  };

  return (
    <Wrapper documentTitle={t("tms.documentTitles.Create Order")}>
      <section id='createNewOrderFromCompany'>
        <div className='header'>
          <DBackButton alt='/dashboard/tms/orders?page=1' />
        </div>
        <form>
          <ProductNewOrder
            customer={customer}
            setCustomer={setCustomer}
            customer_id_error={customer_id_error}
            orderType={orderType}
            setOrderType={setOrderType}
            type_id_error={type_id_error}
            productType={productType}
            setProductType={setProductType}
            cargos_type_id_error={cargos_type_id_error}
            adr={adr}
            setAdr={setAdr}
            cargos_adr_error={cargos_adr_error}
            adrUnNumber={adrUnNumber}
            setAdrUnNumber={setAdrUnNumber}
            adrClass={adrClass}
            setAdrClass={setAdrClass}
          />
          <RouteNewOrder
            locations={locations}
            setLocations={setLocations}
            pickUpDate={pickUpDate}
            setPickUpDate={setPickUpDate}
            cargos_pickup_date_error={cargos_pickup_date_error}
            dropOffDate={dropOffDate}
            setDropOffDate={setDoprOffDate}
            dropOffShow={dropOffShow}
            setDropOffShow={setDropOffShow}
            pickUpTime={pickUpTime}
            setPickUpTime={setPickUpTime}
            dropOffTime={dropOffTime}
            setDropOffTime={setDropOffTime}
          />
          <TrailersNewOrder
            orderType={orderType}
            trailers={trailers}
            setTrailers={setTrailers}
            trailer_type_id_error={trailer_type_id_error}
            trailer_size_id_error={trailer_size_id_error}
            cargos_weight_error={cargos_weight_error}
          />
          <PaymentNewOrder
            payMethod={payMethod}
            setPayMethod={setPayMethod}
            payMethodSub={payMethodSub}
            setPayMethodSub={setPayMethodSub}
            payMethodAmount={payMethodAmount}
            setPayMethodAmount={setPayMethodAmount}
            payMethodAmountCurrency={payMethodAmountCurrency}
            setPayMethodAmountCurrency={setPayMethodAmountCurrency}
            payConsignationPeriod={payConsignationPeriod}
            setPayConsignationPeriod={setPayConsignationPeriod}
            ////
            payment_type_id_error={payment_type_id_error}
            payment_option_type_error={payment_option_type_error}
            payment_days_error={payment_days_error}
            payment_amount_error={payment_amount_error}
            payment_amount_currency_error={payment_amount_currency_error}
          />

          <AditionalInformation comment={comment} setComment={setComment} />

          <div className='submitContainer'>
            <DButton
              loading={createLoading || groupLoading || createResponceArray.length > 0}
              title={t("tms.forms.buttons.Create order")}
              onClick={() => submitHandler()}
            />
          </div>
        </form>
      </section>
    </Wrapper>
  );
};

export default AddOrder;
