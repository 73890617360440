import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userService from "./userService";

const initialState = {
  updateUserLoading: false,
  updateUserResponce: null,
  updateUserError: null,

  updateUserLanguageLoading: false,
  updateUserLanguageResponce: null,
  updateUserLanguageError: null,

  changeEmailLoading: false,
  changeEmailResponce: null,
  changeEmailError: null,

  verifyEmailLoading: false,
  verifyEmailResponce: null,
  verifyEmailError: null,

  changePhoneLoading: false,
  changePhoneResponce: null,
  changePhoneError: null,

  verifyPhoneLoading: false,
  verifyPhoneResponce: null,
  verifyPhoneError: null,

  changePasswordLoading: false,
  changePasswordResponce: null,
  changePasswordError: null,

  updateUserCompanyLogoLoading: false,
  updateUserCompanyLogoResponce: null,
  updateUserCompanyLogoError: null,
};

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await userService.updateUser(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateUserCompanyLogo = createAsyncThunk(
  "user/updateUserCompanyLogo",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      const formData = new FormData();
      formData.append("document", data);
      const result = await userService.updateUserCompanyLogo(
        ISO,
        TOKEN,
        formData
      );
      return result;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateUserLanguage = createAsyncThunk(
  "user/updateUserLanguage",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await userService.updateUserLanguage(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const changeEmail = createAsyncThunk(
  "user/changeEmail",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await userService.changeEmail(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const verifyEmail = createAsyncThunk(
  "user/verifyEmail",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await userService.verifyEmail(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const changePhone = createAsyncThunk(
  "user/changePhone",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await userService.changePhone(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const verifyPhone = createAsyncThunk(
  "user/verifyPhone",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await userService.verifyPhone(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const changePassword = createAsyncThunk(
  "user/changePassword",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await userService.changePassword(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUpdateUser: (state) => {
      state.updateUserLoading = false;
      state.updateUserResponce = null;
      state.updateUserError = null;
    },
    resetUpdateUserCompanyLogo: (state) => {
      state.updateUserCompanyLogoLoading = false;
      state.updateUserCompanyLogoResponce = null;
      state.updateUserCompanyLogoError = null;
    },
    resetUpdateUserLanguage: (state) => {
      state.updateUserLanguageLoading = false;
      state.updateUserLanguageResponce = null;
      state.updateUserLanguageError = null;
    },
    resetChangeEmail: (state) => {
      state.changeEmailLoading = false;
      state.changeEmailResponce = null;
      state.changeEmailError = null;
    },
    resetVerifyEmail: (state) => {
      state.verifyEmailLoading = false;
      state.verifyEmailResponce = null;
      state.verifyEmailError = null;
    },
    resetChangePhone: (state) => {
      state.changePhoneLoading = false;
      state.changePhoneResponce = null;
      state.changePhoneError = null;
    },
    resetVerifyPhone: (state) => {
      state.verifyPhoneLoading = false;
      state.verifyPhoneResponce = null;
      state.verifyPhoneError = null;
    },
    resetChangePassword: (state) => {
      state.changePasswordLoading = false;
      state.changePasswordResponce = null;
      state.changePasswordError = null;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(updateUser.pending, (state) => {
        state.updateUserLoading = true;
        state.updateUserResponce = null;
        state.updateUserError = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.updateUserLoading = false;
        state.updateUserResponce = action.payload;
        state.updateUserError = null;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.updateUserLoading = false;
        state.updateUserResponce = null;
        state.updateUserError = action.payload;
      })

      .addCase(updateUserCompanyLogo.pending, (state) => {
        state.updateUserCompanyLogoLoading = true;
        state.updateUserCompanyLogoResponce = null;
        state.updateUserCompanyLogoError = null;
      })
      .addCase(updateUserCompanyLogo.fulfilled, (state, action) => {
        state.updateUserCompanyLogoLoading = false;
        state.updateUserCompanyLogoResponce = action.payload;
        state.updateUserCompanyLogoError = null;
      })
      .addCase(updateUserCompanyLogo.rejected, (state, action) => {
        state.updateUserCompanyLogoLoading = false;
        state.updateUserCompanyLogoResponce = null;
        state.updateUserCompanyLogoError = action.payload;
      })

      .addCase(updateUserLanguage.pending, (state) => {
        state.updateUserLanguageLoading = true;
        state.updateUserLanguageResponce = null;
        state.updateUserLanguageError = null;
      })
      .addCase(updateUserLanguage.fulfilled, (state, action) => {
        state.updateUserLanguageLoading = false;
        state.updateUserLanguageResponce = action.payload;
        state.updateUserLanguageError = null;
      })
      .addCase(updateUserLanguage.rejected, (state, action) => {
        state.updateUserLanguageLoading = false;
        state.updateUserLanguageResponce = null;
        state.updateUserLanguageError = action.payload;
      })

      .addCase(changeEmail.pending, (state) => {
        state.changeEmailLoading = true;
        state.changeEmailResponce = null;
        state.changeEmailError = null;
      })
      .addCase(changeEmail.fulfilled, (state, action) => {
        state.changeEmailLoading = false;
        state.changeEmailResponce = action.payload;
        state.changeEmailError = null;
      })
      .addCase(changeEmail.rejected, (state, action) => {
        state.changeEmailLoading = false;
        state.changeEmailResponce = null;
        state.changeEmailError = action.payload;
      })

      .addCase(verifyEmail.pending, (state) => {
        state.verifyEmailLoading = true;
        state.verifyEmailResponce = null;
        state.verifyEmailError = null;
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.verifyEmailLoading = false;
        state.verifyEmailResponce = action.payload;
        state.verifyEmailError = null;
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.verifyEmailLoading = false;
        state.verifyEmailResponce = null;
        state.verifyEmailError = action.payload;
      })

      .addCase(changePhone.pending, (state) => {
        state.changePhoneLoading = true;
        state.changePhoneResponce = null;
        state.changePhoneError = null;
      })
      .addCase(changePhone.fulfilled, (state, action) => {
        state.changePhoneLoading = false;
        state.changePhoneResponce = action.payload;
        state.changePhoneError = null;
      })
      .addCase(changePhone.rejected, (state, action) => {
        state.changePhoneLoading = false;
        state.changePhoneResponce = null;
        state.changePhoneError = action.payload;
      })

      .addCase(verifyPhone.pending, (state) => {
        state.verifyPhoneLoading = true;
        state.verifyPhoneResponce = null;
        state.verifyPhoneError = null;
      })
      .addCase(verifyPhone.fulfilled, (state, action) => {
        state.verifyPhoneLoading = false;
        state.verifyPhoneResponce = action.payload;
        state.verifyPhoneError = null;
      })
      .addCase(verifyPhone.rejected, (state, action) => {
        state.verifyPhoneLoading = false;
        state.verifyPhoneResponce = null;
        state.verifyPhoneError = action.payload;
      })

      .addCase(changePassword.pending, (state) => {
        state.changePasswordLoading = true;
        state.changePasswordResponce = null;
        state.changePasswordError = null;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.changePasswordLoading = false;
        state.changePasswordResponce = action.payload;
        state.changePasswordError = null;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.changePasswordLoading = false;
        state.changePasswordResponce = null;
        state.changePasswordError = action.payload;
      });
  },
});

export const {
  resetUpdateUser,
  resetUpdateUserCompanyLogo,
  resetUpdateUserLanguage,
  resetChangeEmail,
  resetVerifyEmail,
  resetChangePhone,
  resetVerifyPhone,
  resetChangePassword,
} = userSlice.actions;
export default userSlice.reducer;
