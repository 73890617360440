import React from "react";
import "./TrackingDistanceData.scss";

import { useTranslation } from "react-i18next";

const TrackingDistanceData = ({ order }) => {
  const { t } = useTranslation();
  const progress = order?.distanceCovered?.distance || 0;
  const estDelivery = order?.distanceCovered?.estimate_date;
  const updateDate = order?.distanceCovered?.update_date;

  return (
    <div id='ShipperTrackingSectionDistance'>
      <div className='content'>
        <h3>{t("shipper.forms.content.Distance covered")}</h3>
        <div>
          <h4>
            {t("shipper.forms.content.Estimated delivery")}:{" "}
            <span>{estDelivery || "-"}</span>
          </h4>
          <h5>
            {t("shipper.forms.content.Update")}:{" "}
            <span>{updateDate || "-"}</span>
          </h5>
        </div>
      </div>
      <div className='progressContainer'>
        <div
          className='color'
          style={{
            width:
              parseFloat(progress) > 100 ? "100%" : `${parseFloat(progress)}%`,
          }}
        >
          <div className='dot'></div>
        </div>
      </div>
    </div>
  );
};

export default TrackingDistanceData;
