import React, { Fragment } from "react";

import UpdateItem from "./UpdateItem/UpdateItem";

const TrackingUpdatesData = ({ order }) => {
  return (
    <Fragment>
      <UpdateItem order={order} />
      {/* {order?.cargos[0]?.statusHistory?.length > 0 && */}
      {/* order?.cargos[0]?.statusHistory.map((status, index) => ( */}
      {/* <UpdateItem key={index} status={status} /> */}
      {/* ))} */}
    </Fragment>
  );
};

export default TrackingUpdatesData;
