import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import cargoService from "./cargoService";

const initialState = {
  createCargoLoading: false,
  createCargoResponce: null,
  createCargoError: null,

  updateCargoLoading: false,
  updateCargoResponce: null,
  updateCargoError: null,

  deleteCargoLoading: false,
  deleteCargoResponce: null,
  deleteCargoError: null,

  getCargoStatusesLoading: false,
  getCargoStatusesResponce: null,
  getCargoStatusesError: null,

  updateCargoStatusesLoading: false,
  updateCargoStatusesResponce: null,
  updateCargoStatusesError: null,
};

export const createCargo = createAsyncThunk(
  "cargos/createCargo",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await cargoService.createCargo(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateCargo = createAsyncThunk(
  "cargos/updateCargo",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await cargoService.updateCargo(
        ISO,
        TOKEN,
        data && data.cargoId,
        data
      );
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteCargo = createAsyncThunk(
  "cargos/deleteCargo",
  async (cargoId, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await cargoService.deleteCargo(ISO, TOKEN, cargoId);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getCargoStatuses = createAsyncThunk(
  "cargos/getCargoStatuses",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await cargoService.getCargoStatuses(ISO, TOKEN);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateCargoStatuses = createAsyncThunk(
  "cargos/updateCargoStatuses",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await cargoService.updateCargoStatuses(
        ISO,
        TOKEN,
        data && data.cargoId,
        data
      );
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const cargosSlice = createSlice({
  name: "cargos",
  initialState,
  reducers: {
    resetCreateCargo: (state) => {
      state.createCargoLoading = false;
      state.createCargoResponce = null;
      state.createCargoError = null;
    },
    resetUpdateCargo: (state) => {
      state.updateCargoLoading = false;
      state.updateCargoResponce = null;
      state.updateCargoError = null;
    },
    resetDeleteCargo: (state) => {
      state.deleteCargoLoading = false;
      state.deleteCargoResponce = null;
      state.deleteCargoError = null;
    },
    resetUpdateCargoStatuses: (state) => {
      state.updateCargoStatusesLoading = false;
      state.updateCargoStatusesResponce = null;
      state.updateCargoStatusesError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCargo.pending, (state) => {
        state.createCargoLoading = true;
        state.createCargoResponce = null;
        state.createCargoError = null;
      })
      .addCase(createCargo.fulfilled, (state, action) => {
        state.createCargoLoading = false;
        state.createCargoResponce = action.payload;
        state.createCargoError = null;
      })
      .addCase(createCargo.rejected, (state, action) => {
        state.createCargoLoading = false;
        state.createCargoResponce = null;
        state.createCargoError = action.payload;
      })

      .addCase(updateCargo.pending, (state) => {
        state.updateCargoLoading = true;
        state.updateCargoResponce = null;
        state.updateCargoError = null;
      })
      .addCase(updateCargo.fulfilled, (state, action) => {
        state.updateCargoLoading = false;
        state.updateCargoResponce = action.payload;
        state.updateCargoError = null;
      })
      .addCase(updateCargo.rejected, (state, action) => {
        state.updateCargoLoading = false;
        state.updateCargoResponce = null;
        state.updateCargoError = action.payload;
      })

      .addCase(deleteCargo.pending, (state) => {
        state.deleteCargoLoading = true;
        state.deleteCargoResponce = null;
        state.deleteCargoError = null;
      })
      .addCase(deleteCargo.fulfilled, (state, action) => {
        state.deleteCargoLoading = false;
        state.deleteCargoResponce = action.payload;
        state.deleteCargoError = null;
      })
      .addCase(deleteCargo.rejected, (state, action) => {
        state.deleteCargoLoading = false;
        state.deleteCargoResponce = null;
        state.deleteCargoError = action.payload;
      })

      .addCase(getCargoStatuses.pending, (state) => {
        state.getCargoStatusesLoading = true;
        state.getCargoStatusesResponce = null;
        state.getCargoStatusesError = null;
      })
      .addCase(getCargoStatuses.fulfilled, (state, action) => {
        state.getCargoStatusesLoading = false;
        state.getCargoStatusesResponce = action.payload;
        state.getCargoStatusesError = null;
      })
      .addCase(getCargoStatuses.rejected, (state, action) => {
        state.getCargoStatusesLoading = false;
        state.getCargoStatusesResponce = null;
        state.getCargoStatusesError = action.payload;
      })

      .addCase(updateCargoStatuses.pending, (state) => {
        state.updateCargoStatusesLoading = true;
        state.updateCargoStatusesResponce = null;
        state.updateCargoStatusesError = null;
      })
      .addCase(updateCargoStatuses.fulfilled, (state, action) => {
        state.updateCargoStatusesLoading = false;
        state.updateCargoStatusesResponce = action.payload;
        state.updateCargoStatusesError = null;
      })
      .addCase(updateCargoStatuses.rejected, (state, action) => {
        state.updateCargoStatusesLoading = false;
        state.updateCargoStatusesResponce = null;
        state.updateCargoStatusesError = action.payload;
      });
  },
});

export const {
  resetCreateCargo,
  resetUpdateCargo,
  resetDeleteCargo,
  resetUpdateCargoStatuses,
} = cargosSlice.actions;
export default cargosSlice.reducer;
