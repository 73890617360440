import React, { Fragment, useState, useEffect } from "react";
import "./PartnerDetails.scss";

import { useTranslation } from "react-i18next";
import { FiEdit2, FiTrash2 } from "react-icons/fi";

import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  getPartner,
  resetGetPartner,
} from "../../../../../../store/participants/partner/partnerSlice";
import { useMoney } from "../../../../../../helper/useMoney";

import Wrapper from "./../../../../../../componentsAdditional/Wrapper";
import DTextButton from "../../../../../../componentsDashboard/Form/DButtons/DTextButton/DTextButton";
import SmallDescription from "../../../../../../componentsDashboard/CustomersPartners/Components/SmallDescription/SmallDescription";
import StatisticCard from "../../../../../../componentsDashboard/CustomersPartners/Components/StatisticCard/StatisticCard";
import ContactTable from "../../../../../../componentsDashboard/CustomersPartners/Contacts/ContactTable/ContactTable";

import DBackButton from "../../../../../../componentsDashboard/Form/DButtons/DBackButton/DBackButton";
import DeletePartnerModal from "./../../../../../../componentsDashboard/CustomersPartners/Partners/DeletePartnerModal/DeletePartnerModal";
import EditPartnerModal from "./../../../../../../componentsDashboard/CustomersPartners/Partners/EditPartnerModal/EditPartnerModal";
import PartnerOrdersTable from "../../../../../../componentsDashboard/CustomersPartners/Partners/PartnerOrdersTable/PartnerOrdersTable";
import PageNotFound from "./../../../../../../componentsBids/PageNotFound/PageNotFound";

const PartnerDetails = () => {
  const { t } = useTranslation();
  const { partnerId } = useParams();
  const dispatch = useDispatch();

  const {
    getPartnerLoading,
    getPartnerResponce,
    updatePartnerResponce,
    getPartnerError,
  } = useSelector((state) => state.partner);
  const { user } = useSelector((state) => state.auth);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  useEffect(() => {
    dispatch(getPartner(partnerId));
    return () => {
      dispatch(resetGetPartner());
    };
  }, [dispatch, partnerId, updatePartnerResponce]);

  const formattedMoneyAvarage = useMoney(
    getPartnerResponce?.analytics.average_margin || 0,
    user?.currency?.symbol || "-",
    0
  );

  const formattedMoneyCurrentMonthAvarage = useMoney(
    getPartnerResponce?.analytics.current_month_average_margin || 0,
    user?.currency?.symbol || "-",
    0
  );

  const formattedMoneyPayout = useMoney(
    getPartnerResponce?.analytics.total_expense || 0,
    user?.currency?.symbol || "-",
    0
  );

  const formattedMoneyCurrentMonthPayout = useMoney(
    getPartnerResponce?.analytics.current_month_expense || 0,
    user?.currency?.symbol || "-",
    0
  );

  return (
    <Wrapper documentTitle={t("tms.documentTitles.Partner details")}>
      {getPartnerError && <PageNotFound />}
      {getPartnerResponce && !getPartnerLoading && (
        <Fragment>
          <section id='PartnerDetails'>
            <div className='header'>
              <DBackButton alt='dashboard/tms/customers_partners/partners?page=1' />
              <div className='actions'>
                <DTextButton
                  title={t("tms.forms.buttons.edit")}
                  onClick={() => setOpenEditModal(true)}
                  leftIcon={<FiEdit2 />}
                />
                <DTextButton
                  danger
                  title={t("tms.forms.buttons.delete")}
                  onClick={() => setOpenDeleteModal(true)}
                  leftIcon={<FiTrash2 />}
                />
              </div>
            </div>
            <div className='participantInfo'>
              <SmallDescription participant={getPartnerResponce} />

              <StatisticCard
                number={getPartnerResponce.analytics.total_number_of_orders}
                title={t("tms.titles.Total shipment")}
                thisMonth={
                  getPartnerResponce.analytics.current_month_number_of_orders
                }
                text={`${
                  getPartnerResponce.analytics
                    .orders_increase_percent_from_last_month || 0
                }%`}
              />
              <StatisticCard
                number={formattedMoneyAvarage}
                title={t("tms.titles.Avarage margin")}
                thisMonth={formattedMoneyCurrentMonthAvarage}
                text={`${
                  getPartnerResponce.analytics
                    .average_margin_increase_percent_from_lastMonth || 0
                }%`}
                active
              />
              <StatisticCard
                number={formattedMoneyPayout}
                title={t("tms.titles.Pay out")}
                thisMonth={formattedMoneyCurrentMonthPayout}
                text={`${
                  getPartnerResponce.analytics
                    .expense_increase_percent_from_lastMonth || 0
                }%`}
                active
              />
            </div>
            <ContactTable participantId={partnerId} />
            <PartnerOrdersTable partner={getPartnerResponce} />
          </section>

          {openDeleteModal && (
            <DeletePartnerModal
              participantId={partnerId}
              isOpen={openDeleteModal}
              isClose={() => setOpenDeleteModal(false)}
            />
          )}
          {openEditModal && (
            <EditPartnerModal
              participant={getPartnerResponce}
              isOpen={openEditModal}
              isClose={() => setOpenEditModal(false)}
            />
          )}
        </Fragment>
      )}
    </Wrapper>
  );
};

export default PartnerDetails;
