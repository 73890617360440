import React from "react";
import "./ProductInformationDetails.scss";

import { useTranslation } from "react-i18next";
import { FaWeightHanging } from "react-icons/fa6";
import { MdLocalShipping, MdCategory } from "react-icons/md";

import AddCustomerButton from "../AddCustomerButton/AddCustomerButton";
import ShowCustomerDetails from "../ShowCustomerDetails/ShowCustomerDetails";
import AdrClassDetails from "../AdrClassDetails/AdrClassDetails";

const ProductInformationDetails = ({ order }) => {
  const { t } = useTranslation();

  const ProductDetailsIconItem = ({ icon, desc }) => {
    return (
      <div className='productDetailsItem'>
        <div className='icon'>{icon}</div>
        <h3>{desc}</h3>
      </div>
    );
  };

  return (
    <div id='ProductInformationDetails'>
      {order?.ownership && !order?.customer ? (
        <AddCustomerButton order={order} />
      ) : (
        <ShowCustomerDetails order={order} />
      )}
      <div className='productDetails'>
        <ProductDetailsIconItem
          icon={<MdLocalShipping />}
          desc={order?.type?.name}
        />
        <div className='line'></div>
        <ProductDetailsIconItem
          icon={<MdCategory />}
          desc={order?.cargos[0]?.type?.name}
        />
        <div className='line'></div>
        <ProductDetailsIconItem
          icon={<FaWeightHanging />}
          desc={`${order?.cargos[0]?.weight || 0} ${t("partnerBid.KG")}`}
        />
      </div>
      <AdrClassDetails order={order} />
    </div>
  );
};

export default ProductInformationDetails;
