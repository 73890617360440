import _request from "../../_request";

const getOrders = async (ISO, TOKEN, data) => {
  let url = "order?";
  const {
    page,
    keyword,
    trailer_type_id,
    start_date,
    end_date,
    trailer_id,
    cargo_type_id,
    direction,
    pickup_address,
    dropoff_address,
    type_ids,
  } = data || {};

  url += page ? `page=${page}` : "";
  url += keyword ? `&keyword=${keyword}` : "";
  url += trailer_type_id ? `&trailer_type_id=${trailer_type_id}` : "";
  url += start_date ? `&start_date=${start_date}` : "";
  url += end_date ? `&end_date=${end_date}` : "";
  url += trailer_id ? `&trailer_id=${trailer_id}` : "";
  url += cargo_type_id ? `&cargo_type_id=${cargo_type_id}` : "";
  url += direction ? `&direction=${direction}` : "";
  url += pickup_address ? `&pickup_address=${pickup_address}` : "";
  url += dropoff_address ? `&dropoff_address=${dropoff_address}` : "";
  if (type_ids && type_ids?.length) {
    type_ids?.forEach((i) => {
      url += `&type_ids[]=${i}`;
    });
  }
  url += `&quotation=active`;

  const config = {
    url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getRejectOrders = async (ISO, TOKEN, data) => {
  let url = "order";
  const {
    page,
    keyword,
    trailer_type_id,
    start_date,
    end_date,
    trailer_id,
    cargo_type_id,
    direction,
    pickup_address,
    dropoff_address,
    type_ids,
  } = data || {};

  url += page ? `?page=${page}` : "";
  url += keyword ? `&keyword=${keyword}` : "";
  url += trailer_type_id ? `&trailer_type_id=${trailer_type_id}` : "";
  url += start_date ? `&start_date=${start_date}` : "";
  url += end_date ? `&end_date=${end_date}` : "";
  url += trailer_id ? `&trailer_id=${trailer_id}` : "";
  url += cargo_type_id ? `&cargo_type_id=${cargo_type_id}` : "";
  url += direction ? `&direction=${direction}` : "";
  url += pickup_address ? `&pickup_address=${pickup_address}` : "";
  url += dropoff_address ? `&dropoff_address=${dropoff_address}` : "";
  if (type_ids && type_ids?.length) {
    type_ids?.forEach((i) => {
      url += `&type_ids[]=${i}`;
    });
  }
  url += `&quotation=rejected`;

  const config = {
    url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getOrder = async (ISO, TOKEN, orderId) => {
  const config = {
    url: `order/${orderId}`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const createOrder = async (ISO, TOKEN, data) => {
  const config = {
    url: "order",
    method: "post",
    token: TOKEN,
    ISO,
    file: true,
  };
  return _request(config, data);
};

const updateOrder = async (ISO, TOKEN, orderId, data) => {
  const config = {
    url: `order/${orderId}`,
    method: "put",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const groupOrder = async (ISO, TOKEN, data) => {
  const config = {
    url: "order/group",
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const deleteOrder = async (ISO, TOKEN, orderId) => {
  const config = {
    url: `/order/${orderId}`,
    method: "delete",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const takeOrder = async (ISO, TOKEN, orderId, bidId, data) => {
  const config = {
    url: `/order/${orderId}/bids/accept-bid/${bidId}`,
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const rejectOrder = async (ISO, TOKEN, orderId, bidId, data) => {
  const config = {
    url: `/order/${orderId}/bids/reject-bid/${bidId}`,
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const shipperOrdersService = {
  getOrders,
  getRejectOrders,
  getOrder,
  createOrder,
  updateOrder,
  groupOrder,
  deleteOrder,
  takeOrder,
  rejectOrder,
};

export default shipperOrdersService;
