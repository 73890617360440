import React from "react";
import "./CarrierApp.scss";

import CarrierAppCover from "./CarrierAppCover/CarrierAppCover";
import CarrierAppSocial from "./CarrierAppSocial/CarrierAppSocial";
import CarrierAppTitle from "./CarrierAppTitle/CarrierAppTitle";

const CarrierApp = () => {
  return (
    <div className='wrapper'>
      <div id='CarrierApp'>
        <div className='CarrierAppLeft'>
          <CarrierAppTitle />
          <CarrierAppSocial />
        </div>
        <CarrierAppCover />
      </div>
    </div>
  );
};

export default CarrierApp;
