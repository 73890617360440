import React, { Fragment, useState, useEffect } from "react";
import "./AddPrePaymentModal.scss";

import { useTranslation } from "react-i18next";

import moment from "moment";
import { BiSolidDollarCircle } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";

import {
  createAdvance,
  resetCreateAdvance,
} from "../../../../store/orders/advance/advanceSlice";
import { useAdvancesErrors } from "./../../../../validations/order/useAdvancesErrors";

import DCurrencyInput from "../../../Form/DCurrencyInput/DCurrencyInput";
import DDatepicker from "./../../../Form/DDatepicker/DDatepicker";
import DModal from "../../../Modal/DModal/DModal";
import DTextarea from "./../../../Form/DTextarea/DTextarea";

const AddPrePaymentModal = ({ isOpen, isClose, order }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { currenciesSelect } = useSelector((state) => state.settings);
  const { createAdvanceLoading, createAdvanceResponce } = useSelector(
    (state) => state.advance
  );
  const {
    create_currency_id_error,
    create_amount_error,
    create_payment_at_error,
    create_comment_error,
  } = useAdvancesErrors();

  const [amount, setAmount] = useState("");
  const [currencyValue, setCurrencyValue] = useState(null);
  const [paymentDate, setPaymentDate] = useState(new Date());
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (currenciesSelect) {
      const findUserCurrency = currenciesSelect.find(
        (currency) =>
          currency.value.id.toString() === order.revenue.currency.id.toString()
      );
      setCurrencyValue(findUserCurrency);
    }
  }, [currenciesSelect, order.revenue.currency.id]);

  const submitHandler = () => {
    const data = {
      orderId: order && order.id,
      currency_id: currencyValue && currencyValue.value.id,
      amount: amount,
      comment: comment,
      payment_at: moment(paymentDate).format("YYYY/MM/DD"),
    };
    dispatch(createAdvance(data));
  };

  useEffect(() => {
    if (createAdvanceResponce) {
      dispatch(resetCreateAdvance());
      setAmount("");
      setPaymentDate(new Date());
      setComment("");
      isClose();
    }
  }, [dispatch, isClose, createAdvanceResponce]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose(false)}
        title={t("tms.modals.titles.Add payment")}
        submitText={t("tms.modals.buttons.Add payment")}
        icon={<BiSolidDollarCircle />}
        status='success'
        onClick={() => submitHandler()}
        loading={createAdvanceLoading}
      >
        <div id='AddPaymentModal'>
          <div className='AddPaymentModalGroup'>
            <DCurrencyInput
              id={"AddPaymentAmount"}
              placeholder={t("tms.modals.placeholder.Enter paid money")}
              label={t("tms.modals.labels.Amount")}
              value={amount}
              setValue={setAmount}
              currencyValue={currencyValue}
              setCurrencyValue={setCurrencyValue}
              error={create_amount_error || create_currency_id_error}
              required
            />
            <DDatepicker
              placeholder={t("tms.modals.placeholder.Current date")}
              label={t("tms.modals.labels.Payment date")}
              value={paymentDate}
              setValue={setPaymentDate}
              error={create_payment_at_error}
              required
            />
            <DTextarea
              id={"AddPaymentModalTextAreaAdvances"}
              value={comment}
              placeholder={t("tms.modals.placeholder.Enter comment")}
              label={t("tms.modals.labels.Comment")}
              setValue={setComment}
              error={create_comment_error}
            />
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default AddPrePaymentModal;
