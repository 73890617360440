import _request from "./../../_request";

const assignCarrier = async (ISO, TOKEN, orderId, data) => {
  const config = {
    url: `order/${orderId}/assign-carrier`,
    method: "put",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const unassignCarrier = async (ISO, TOKEN, orderId) => {
  const config = {
    url: `order/${orderId}/unassign-carrier`,
    method: "delete",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const manageOrderService = {
  assignCarrier,
  unassignCarrier,
};

export default manageOrderService;
