import React, { Fragment } from "react";
import "./Socials.scss";

import { useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { RiInstagramFill } from "react-icons/ri";
import { FaFacebook, FaYoutube } from "react-icons/fa";

const Socials = () => {
  const { contact } = useSelector((state) => state.contact);

  return (
    <Fragment>
      {contact && (
        <div className='wrapper'>
          <div id='Socials' className='Socials'>
            <div className='social'>
              {contact.contact_fb && (
                <Link target='_blank' to={contact.contact_fb}>
                  <div className='icon'>
                    <FaFacebook />
                  </div>
                </Link>
              )}

              {contact.contact_youtube && (
                <Link target='_blank' to={contact.contact_youtube}>
                  <div className='icon'>
                    <FaYoutube />
                  </div>
                </Link>
              )}
              {contact.contact_instagram && (
                <Link target='_blank' to={contact.contact_instagram}>
                  <div className='icon'>
                    <RiInstagramFill />
                  </div>
                </Link>
              )}
            </div>
            <div className='applications'>
              <Link
                target='_blank'
                to='https://apps.apple.com/gb/app/cargon/id1361078733?ign-mpt=uo%3D2'
              >
                <div
                  className='img'
                  style={{
                    backgroundImage:
                      "url('/assets/images/appImages/AppStore.svg')",
                  }}
                ></div>
              </Link>
              <Link
                target='_blank'
                to='https://play.google.com/store/apps/details?id=com.cargon.driver&pcampaignid=web_share'
              >
                <div
                  className='img'
                  style={{
                    backgroundImage:
                      "url('/assets/images/appImages/GooglePlay.svg')",
                  }}
                ></div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Socials;
