import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import shipperAcceptedService from "./shipperAcceptedService";

const shipperOrderViewStorageValue = localStorage.getItem("shipperOrderView");
const shipperAcceptedOrderView = shipperOrderViewStorageValue
  ? JSON.parse(shipperOrderViewStorageValue)
  : true;

const initialState = {
  shipperAcceptedOrderView: shipperAcceptedOrderView,
  activeOrderId: null,
  activeOrderIndex: 0,

  ordersLoading: true,
  orders: [],
  ordersMeta: null,
  ordersError: null,

  orderLoading: true,
  orderResponce: null,
  orderError: null,
};

export const getOrders = createAsyncThunk(
  "shipperAccepted/getOrders",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await shipperAcceptedService.getOrders(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getOrder = createAsyncThunk(
  "shipperAccepted/getOrder",
  async (orderId, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await shipperAcceptedService.getOrder(ISO, TOKEN, orderId);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const setShipperAcceptedOrderView = createAsyncThunk(
  "shipperAccepted/setShipperAcceptedOrderView",
  async (data, thunkAPI) => {
    localStorage.setItem("shipperOrderView", JSON.stringify(data));
    return data;
  }
);

export const shipperAcceptedSlice = createSlice({
  name: "shipperAccepted",
  initialState,
  reducers: {
    setActiveOrderId: (state, action) => {
      state.activeOrderId = action.payload;
    },
    setActiveOrderIndex: (state, action) => {
      state.activeOrderIndex = action.payload;
    },
    resetGetOrders: (state) => {
      state.ordersLoading = true;
      state.orders = [];
      state.ordersMeta = null;
      state.ordersError = null;
    },
    resetGetOrder: (state) => {
      state.orderLoading = true;
      state.orderResponce = null;
      state.orderError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setShipperAcceptedOrderView.fulfilled, (state, action) => {
        state.shipperAcceptedOrderView = action.payload;
      })
      .addCase(getOrders.pending, (state) => {
        state.ordersLoading = true;
        state.ordersError = null;
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        state.ordersLoading = false;
        state.orders = [...state.orders, ...action.payload.data];
        state.ordersMeta = action.payload.meta;
        state.ordersError = null;
      })
      .addCase(getOrders.rejected, (state, action) => {
        state.ordersLoading = false;
        state.orders = [];
        state.ordersMeta = null;
        state.ordersError = action.payload;
      })

      .addCase(getOrder.pending, (state) => {
        state.orderLoading = true;
        state.orderResponce = null;
        state.orderError = null;
      })
      .addCase(getOrder.fulfilled, (state, action) => {
        state.orderLoading = false;
        state.orderResponce = action.payload;
        state.orderError = null;
      })
      .addCase(getOrder.rejected, (state, action) => {
        state.orderLoading = false;
        state.orderResponce = null;
        state.orderError = action.payload;
      });
  },
});

export const {
  setActiveOrderId,
  setActiveOrderIndex,
  resetGetOrders,
  resetGetOrder,
} = shipperAcceptedSlice.actions;
export default shipperAcceptedSlice.reducer;
