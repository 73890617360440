import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import rulesService from "./rulesService";

const initialState = {
  // get FAQ
  FAQ: null,
  FAQLoading: false,
  FAQError: null,

  // get getInsurance
  insurance: null,
  insuranceLoading: false,
  insuranceError: null,

  // get getPrivacy
  privacy: null,
  privacyLoading: false,
  privacyError: null,

  // get getTerms
  terms: null,
  termsLoading: false,
  termsError: null,
};

// get FAQ
export const getFAQ = createAsyncThunk("rules/getFAQ", async (_, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    return await rulesService.getFAQ(ISO);
  } catch (error) {
    const message =
      (error.response && error.response.data) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// get getInsurance
export const getInsurance = createAsyncThunk(
  "rules/getInsurance",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      return await rulesService.getInsurance(ISO);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get getPrivacy
export const getPrivacy = createAsyncThunk(
  "rules/getPrivacy",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      return await rulesService.getPrivacy(ISO);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get getTerms
export const getTerms = createAsyncThunk(
  "rules/getTerms",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      return await rulesService.getTerms(ISO);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const rulesSlice = createSlice({
  name: "rules",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get FAQ
      .addCase(getFAQ.pending, (state) => {
        state.FAQLoading = true;
        state.FAQ = null;
        state.FAQError = null;
      })
      .addCase(getFAQ.fulfilled, (state, action) => {
        state.FAQLoading = false;
        state.FAQ = action.payload;
        state.FAQError = null;
      })
      .addCase(getFAQ.rejected, (state, action) => {
        state.FAQLoading = false;
        state.FAQ = null;
        state.FAQError = action.payload;
      })

      // get getInsurance
      .addCase(getInsurance.pending, (state) => {
        state.insuranceLoading = true;
        state.insurance = null;
        state.insuranceError = null;
      })
      .addCase(getInsurance.fulfilled, (state, action) => {
        state.insuranceLoading = false;
        state.insurance = action.payload;
        state.insuranceError = null;
      })
      .addCase(getInsurance.rejected, (state, action) => {
        state.insuranceLoading = false;
        state.insurance = null;
        state.insuranceError = action.payload;
      })

      // get getPrivacy
      .addCase(getPrivacy.pending, (state) => {
        state.privacyLoading = true;
        state.privacy = null;
        state.privacyError = null;
      })
      .addCase(getPrivacy.fulfilled, (state, action) => {
        state.privacyLoading = false;
        state.privacy = action.payload;
        state.privacyError = null;
      })
      .addCase(getPrivacy.rejected, (state, action) => {
        state.privacyLoading = false;
        state.privacy = null;
        state.privacyError = action.payload;
      })

      // get getTerms
      .addCase(getTerms.pending, (state) => {
        state.termsLoading = true;
        state.terms = null;
        state.termsError = null;
      })
      .addCase(getTerms.fulfilled, (state, action) => {
        state.termsLoading = false;
        state.terms = action.payload;
        state.termsError = null;
      })
      .addCase(getTerms.rejected, (state, action) => {
        state.termsLoading = false;
        state.terms = null;
        state.termsError = action.payload;
      });
  },
});

export default rulesSlice.reducer;
