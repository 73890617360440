import React from "react";
import "./AirPackageDetails.scss";

import { useTranslation } from "react-i18next";

import { CiBoxes } from "react-icons/ci";
import { BsBoxes } from "react-icons/bs";

const AirPackageDetails = ({ orderResponce }) => {
  const { t } = useTranslation();

  return (
    <div id='AirPackageDetails'>
      <div className='titleContainer'>
        <div className='icon'>
          <CiBoxes />
        </div>
        <h1 className='title'>{t("shipper.forms.titles.Package")}</h1>
      </div>
      <div className='AirPackageDetailsShipper'>
        {orderResponce?.cargos?.map((cargo, index) => {
          const packageType = cargo?.dimensions?.[0]?.packing_type?.name;
          const perWeight = cargo?.dimensions?.[0]?.weight;
          const quantity = cargo?.dimensions?.[0]?.count;

          return (
            <div key={index} className='AirPackageDetailsShipperContainer'>
              <div className='AirPackageDetailsShipperCover'>
                <div className='AirPackageDetailsShipperIndex'>
                  {orderResponce?.cargos.length > 1 && index + 1}
                </div>
                <div className='AirPackageDetailsShipperImg'>
                  <BsBoxes />
                </div>
              </div>
              <div className='AirPackageDetailsShippercontent'>
                {packageType && (
                  <p>
                    {t("shipper.forms.content.Package type")}: <span>{packageType}</span>
                  </p>
                )}
                {perWeight && (
                  <p>
                    {t("shipper.forms.content.Per weight")}: <span>{perWeight} KG</span>
                  </p>
                )}
                {quantity && (
                  <p>
                    {t("shipper.forms.content.Quantity")}: <span>{quantity}</span>
                  </p>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AirPackageDetails;
