import { useSelector } from "react-redux";

export const useTrailerErrors = () => {
  const { createTrailerError, updateTrailerError } = useSelector(
    (state) => state.trailers
  );

  const create_trailer_type_id_error =
    createTrailerError &&
    createTrailerError.errors &&
    createTrailerError.errors.trailer_type_id &&
    createTrailerError.errors.trailer_type_id[0];

  const create_volume_capacity_error =
    createTrailerError &&
    createTrailerError.errors &&
    createTrailerError.errors.volume_capacity &&
    createTrailerError.errors.volume_capacity[0];

  const create_weight_capacity_error =
    createTrailerError &&
    createTrailerError.errors &&
    createTrailerError.errors.weight_capacity &&
    createTrailerError.errors.weight_capacity[0];

  const create_registration_number_error =
    createTrailerError &&
    createTrailerError.errors &&
    createTrailerError.errors.registration_number &&
    createTrailerError.errors.registration_number[0];

  const create_model_year_error =
    createTrailerError &&
    createTrailerError.errors &&
    createTrailerError.errors.model_year &&
    createTrailerError.errors.model_year[0];

  const update_trailer_type_id_error =
    updateTrailerError &&
    updateTrailerError.errors &&
    updateTrailerError.errors.trailer_type_id &&
    updateTrailerError.errors.trailer_type_id[0];

  const update_volume_capacity_error =
    updateTrailerError &&
    updateTrailerError.errors &&
    updateTrailerError.errors.volume_capacity &&
    updateTrailerError.errors.volume_capacity[0];

  const update_weight_capacity_error =
    updateTrailerError &&
    updateTrailerError.errors &&
    updateTrailerError.errors.weight_capacity &&
    updateTrailerError.errors.weight_capacity[0];

  const update_registration_number_error =
    updateTrailerError &&
    updateTrailerError.errors &&
    updateTrailerError.errors.registration_number &&
    updateTrailerError.errors.registration_number[0];

  const update_model_year_error =
    updateTrailerError &&
    updateTrailerError.errors &&
    updateTrailerError.errors.model_year &&
    updateTrailerError.errors.model_year[0];

  return {
    create_trailer_type_id_error,
    create_volume_capacity_error,
    create_weight_capacity_error,
    create_registration_number_error,
    create_model_year_error,
    update_trailer_type_id_error,
    update_volume_capacity_error,
    update_weight_capacity_error,
    update_registration_number_error,
    update_model_year_error,
  };
};

export default useTrailerErrors;
