import React, { Fragment, useState } from "react";
import "./ShipperNotifications.scss";

import { IoIosNotifications } from "react-icons/io";
import { useSelector } from "react-redux";

import NotificationModal from "../../../Notifications/NotificationModal/NotificationModal";

const ShipperNotifications = () => {
  const { getNewNotiMeta } = useSelector((state) => state.notifications);
  const [open, setOpen] = useState(false);
  return (
    <Fragment>
      <div id='ShipperNotifications' onClick={() => setOpen(true)}>
        <div className='icon'>
          <IoIosNotifications />
          {getNewNotiMeta && getNewNotiMeta.total > 0 && (
            <div className='badge'>
              {getNewNotiMeta.total > 99 ? "99+" : getNewNotiMeta.total}
            </div>
          )}
        </div>
      </div>
      <NotificationModal isOpen={open} isClose={() => setOpen(false)} shipper />
    </Fragment>
  );
};

export default ShipperNotifications;
