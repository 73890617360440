import React, { Fragment, useState, useEffect } from "react";
import "./LoginForm.scss";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { login, reset, getUser } from "../../../store/auth/authSlice";
import { useAuthError } from "./../../../validations/auth/useAuthError";
import { containsOnlyNumbers } from "./../../../helper/useContainNum";

import ConvertInput from "../../Form/Inputs/ConvertInput/ConvertInput";
import Input from "../../Form/Inputs/Input";
import RememberMe from "./../../Form/Checkbox/RememberMe/RememberMe";
import Button from "../../Form/Button/Button";
import Label from "../../Form/Label/Label";
import ErrorText from "../../Form/ErrorText/ErrorText";
import AuthFormWrapper from "../Components/AuthFormWrapper/AuthFormWrapper";
import FormTitle from "../Components/FormTitle/FormTitle";
import NotHaveAccount from "./../../Form/NotHaveAccount/NotHaveAccount";

const LoginForm = () => {
  const { t } = useTranslation();

  const { email_error, phone_index_id_error, password_error } = useAuthError();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, data, error, verify, access_token, user } = useSelector(
    (state) => state.auth
  );
  const { phoneIndexValueId } = useSelector((state) => state.settings);

  const [method, setMethod] = useState("EMAIL");
  const [value, setValue] = useState("");
  const [password, setPassword] = useState("");

  const submitHandler = () => {
    const data =
      method === "EMAIL"
        ? { email: value, password }
        : { phone_index_id: phoneIndexValueId, phone: value, password };

    dispatch(login(data));
  };

  useEffect(() => {
    if (data && !error) {
      dispatch(reset());
      dispatch(getUser());
    }
  }, [dispatch, navigate, data, error]);

  useEffect(() => {
    const onlyNumber = containsOnlyNumbers(value);
    setMethod(value.length > 2 && onlyNumber ? "PHONE" : "EMAIL");
  }, [value]);

  useEffect(() => {
    if (access_token && !verify) {
      user?.type?.id === 2 &&
        navigate(
          `/login/carrier/verify?phoneIndexId=${user?.phone_index_id}&phone=${user?.phone}`
        );
      user?.type?.id === 4 &&
        navigate(`/login/tms/verify?email=${user?.email}`);
      user?.type?.id === 5 &&
        navigate(`/login/shipper/verify?email=${user?.email}`);
      dispatch(reset());
    }
  }, [access_token, dispatch, navigate, user, verify]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        const data =
          method === "EMAIL"
            ? { email: value, password }
            : { phone_index_id: phoneIndexValueId, phone: value, password };

        dispatch(login(data));
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [dispatch, method, password, phoneIndexValueId, value]);

  return (
    <AuthFormWrapper>
      <form id='LoginForm' className='LoginForm'>
        <FormTitle title={t("landing.auth.titles.Welcome back !")}>
          {t("landing.auth.titles.Access your account")}
        </FormTitle>

        <div className='group'>
          <div>
            <Label
              text={t("landing.auth.form.Email or phone number")}
              id='convertContainer'
            />
            <ConvertInput
              inputStatus={method}
              value={value}
              setValue={setValue}
              autoFocus={value.length > 2 ? true : false}
              id='convertContainer'
              error={method === "EMAIL" ? email_error : email_error}
            />
            {method === "EMAIL" ? (
              <ErrorText error={email_error} />
            ) : (
              <Fragment>
                <ErrorText error={phone_index_id_error} />
                <ErrorText error={email_error} />
              </Fragment>
            )}
          </div>

          <div className='passwordContainer'>
            <Label text={t("landing.auth.form.Password")} id='LoginPassword' />
            <Input
              value={password}
              setValue={setPassword}
              password
              placeholder={t("landing.auth.form.Enter password")}
              id='LoginPassword'
              error={password_error}
            />
            <ErrorText error={password_error} />

            <div className='additional'>
              <RememberMe />
              <NotHaveAccount
                link='/forgot-password'
                text={t("landing.auth.form.Forgot password?")}
              />
            </div>
          </div>
        </div>

        <Button
          loading={loading}
          title={t("landing.auth.form.Log in")}
          onClick={(e) => {
            submitHandler(e);
          }}
        />

        <NotHaveAccount
          desc={t("landing.auth.titles.Don’t have an account?")}
          link='/register'
          text={t("landing.auth.form.Sign up here")}
        />
      </form>
    </AuthFormWrapper>
  );
};

export default LoginForm;
