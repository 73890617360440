import React, { Fragment, useState, useEffect } from "react";
import "./ConnectCompanyModal.scss";

import { useTranslation } from "react-i18next";
import { FaLocationCrosshairs } from "react-icons/fa6";
import { useSelector, useDispatch } from "react-redux";

import {
  connectProvider,
  resetConnectProvider,
} from "../../../store/GPSTraker/GPSTrakerSlice";
import useConnectProviderErrors from "../../../validations/GPSTraker/useConnectProviderErrors";

import DModal from "./../../Modal/DModal/DModal";
import DGPSProviderSelect from "../../Form/DSelect/DGPSProviderSelect/DGPSProviderSelect";
import DInputs from "./../../Form/DInputs/DInputs";
import ErrorText from "./../../../componentsLanding/Form/ErrorText/ErrorText";

const ConnectCompanyModal = ({ isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { connectProviderLoading, connectProviderResponce } = useSelector(
    (state) => state.GPSTraker
  );
  const {
    connect_provider_id_error,
    connect_username_error,
    connect_password_error,
    connect_token_error,
    connect_message,
  } = useConnectProviderErrors();

  const [GPSProvider, setGPSProvider] = useState();
  const [token, setToken] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const submitHandler = () => {
    const data = {
      provider_id: GPSProvider ? GPSProvider.value.id : null,
      username: GPSProvider && GPSProvider.value.fields.username && username,
      password: GPSProvider && GPSProvider.value.fields.password && password,
      token: GPSProvider && GPSProvider.value.fields.token && token,
    };
    dispatch(connectProvider(data));
  };

  useEffect(() => {
    if (connectProviderResponce) {
      dispatch(resetConnectProvider());
      isClose();
    }
  }, [dispatch, isClose, connectProviderResponce]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose(false)}
        title={t("tms.modals.titles.Log in to tracking device")}
        submitText={t("tms.modals.buttons.Log in")}
        icon={<FaLocationCrosshairs />}
        onClick={() => submitHandler()}
        loading={connectProviderLoading}
      >
        <div id='ConnectCompanyModal'>
          <div className='ConnectCompanyModalGroup'>
            <DGPSProviderSelect
              value={GPSProvider}
              setValue={setGPSProvider}
              error={connect_provider_id_error}
            />
            {GPSProvider && GPSProvider.value.fields.username && (
              <DInputs
                id='userNameGpsProvider'
                label={t("tms.modals.labels.User name")}
                placeholder={t("tms.modals.placeholder.Enter user name")}
                value={username}
                setValue={setUsername}
                error={connect_username_error}
                required
              />
            )}
            {GPSProvider && GPSProvider.value.fields.password && (
              <DInputs
                id='userPasswordGpsProvider'
                type='password'
                label={t("tms.modals.labels.Password")}
                placeholder={t("tms.modals.placeholder.Enter password")}
                value={password}
                setValue={setPassword}
                error={connect_password_error}
                required
              />
            )}
            {GPSProvider && GPSProvider.value.fields.token && (
              <DInputs
                id='userTokenGpsProvider'
                label={t("tms.modals.labels.User token")}
                placeholder={t("tms.modals.placeholder.Enter user token")}
                value={token}
                setValue={setToken}
                required
                error={connect_token_error}
              />
            )}

            {connect_message &&
              !connect_provider_id_error &&
              !connect_username_error &&
              !connect_password_error &&
              !connect_token_error && <ErrorText error={connect_message} />}
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default ConnectCompanyModal;
