import _request from "../../_request";

const getCustomers = async (ISO, TOKEN, data) => {
  let url = "participants?type=Customer";
  const { page, keyword } = data || {};
  url += page ? `&page=${page}` : "";
  url += keyword ? `&keyword=${keyword}` : "";

  const config = {
    url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getCustomer = async (ISO, TOKEN, participantId) => {
  const config = {
    url: `participants/${participantId}`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const createCustomer = async (ISO, TOKEN, data) => {
  const config = {
    url: "participants",
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const updateCustomer = async (ISO, TOKEN, participantId, data) => {
  const config = {
    url: `participants/${participantId}`,
    method: "put",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const deleteCustomer = async (ISO, TOKEN, participantId) => {
  const config = {
    url: `participants/${participantId}`,
    method: "delete",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const customerService = {
  getCustomers,
  getCustomer,
  createCustomer,
  updateCustomer,
  deleteCustomer,
};

export default customerService;
