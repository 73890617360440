import React, { Fragment, useState } from "react";
import "./AccountList.scss";

import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";
import { useSelector } from "react-redux";

import DButton from "./../../../../Form/DButtons/DButton/DButton";
import AccountListItem from "./AccountListItem/AccountListItem";
import AddBankRequisitesModal from "./../AddBankRequisitesModal/AddBankRequisitesModal";

const AccountList = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);

  const [showAddAccountModal, setShowAddAccountModal] = useState(false);

  return (
    <Fragment>
      {user && (
        <div id='BankAccountList'>
          <div className='bankContainerHeader'>
            <h2>{t("tms.titles.Bank requisites")}</h2>
          </div>

          <ul className='BankAccountList'>
            {user.bank_accounts &&
              user.bank_accounts.map((item) => (
                <AccountListItem key={item.id} item={item} />
              ))}

            <li className='createButton'>
              <DButton
                rightIcon={<FiPlus />}
                title={t("tms.forms.buttons.Add bank requisite")}
                onClick={() => setShowAddAccountModal(true)}
              />
            </li>
          </ul>
        </div>
      )}
      {showAddAccountModal && (
        <AddBankRequisitesModal
          isOpen={showAddAccountModal}
          isClose={() => setShowAddAccountModal(false)}
        />
      )}
    </Fragment>
  );
};

export default AccountList;
