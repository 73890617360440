import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import shipperOrdersService from "./shipperOrdersService";

const initialState = {
  ordersLoading: true,
  orders: null,
  ordersMeta: null,
  ordersError: null,

  rejectOrdersLoading: true,
  rejectOrders: null,
  rejectOrdersMeta: null,
  rejectOrdersError: null,

  orderLoading: true,
  orderResponce: null,
  orderError: null,

  createRoadLoading: false,
  createRoadResponce: null,
  createRoadResponceArray: [],
  createRoadErrorArray: [],
  createRoadError: null,

  updateRoadLoading: false,
  updateRoadResponce: null,
  updateRoadError: null,

  groupRoadLoading: false,
  groupRoadResponce: null,
  groupRoadError: null,

  createAirLoading: false,
  createAirResponce: null,
  createAirError: null,

  updateAirLoading: false,
  updateAirResponce: null,
  updateAirError: null,

  createOceanLoading: false,
  createOceanResponce: null,
  createOceanError: null,

  updateOceanLoading: false,
  updateOceanResponce: null,
  updateOceanError: null,

  deleteLoading: false,
  deleteResponce: null,
  deleteError: null,

  takeOrderLoading: false,
  takeOrderResponce: null,
  takeOrderError: null,

  rejectOrderLoading: false,
  rejectOrderResponce: null,
  rejectOrderError: null,
};

export const getOrders = createAsyncThunk(
  "shipperOrders/getOrders",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await shipperOrdersService.getOrders(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getRejectOrders = createAsyncThunk(
  "shipperOrders/getRejectOrders",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await shipperOrdersService.getRejectOrders(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getOrder = createAsyncThunk(
  "shipperOrders/getOrder",
  async (orderId, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await shipperOrdersService.getOrder(ISO, TOKEN, orderId);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createRoad = createAsyncThunk(
  "shipperOrders/createRoad",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await shipperOrdersService.createOrder(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateRoad = createAsyncThunk(
  "shipperOrders/updateRoadOrder",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await shipperOrdersService.updateOrder(
        ISO,
        TOKEN,
        data && data.orderId,
        data
      );
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const groupRoad = createAsyncThunk(
  "shipperOrders/groupRoad",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await shipperOrdersService.groupOrder(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createAir = createAsyncThunk(
  "shipperOrders/createAir",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await shipperOrdersService.createOrder(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateAir = createAsyncThunk(
  "shipperOrders/updateAir",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await shipperOrdersService.updateOrder(
        ISO,
        TOKEN,
        data && data.orderId,
        data
      );
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createOcean = createAsyncThunk(
  "shipperOrders/createOcean",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await shipperOrdersService.createOrder(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateOcean = createAsyncThunk(
  "shipperOrders/updateOcean",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await shipperOrdersService.updateOrder(
        ISO,
        TOKEN,
        data && data.orderId,
        data
      );
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteOrder = createAsyncThunk(
  "shipperOrders/deleteOrder",
  async (orderId, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await shipperOrdersService.deleteOrder(ISO, TOKEN, orderId);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const takeOrder = createAsyncThunk(
  "shipperOrders/takeOrder",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await shipperOrdersService.takeOrder(
        ISO,
        TOKEN,
        data && data.orderId,
        data && data.bidId,
        data
      );
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const rejectOrder = createAsyncThunk(
  "shipperOrders/rejectOrder",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await shipperOrdersService.rejectOrder(
        ISO,
        TOKEN,
        data && data.orderId,
        data && data.bidId,
        data
      );
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const shipperOrdersSlice = createSlice({
  name: "shipperOrders",
  initialState,
  reducers: {
    resetGetOrders: (state) => {
      state.ordersLoading = true;
      state.orders = null;
      state.ordersMeta = null;
      state.ordersError = null;
    },
    resetGetRejectOrders: (state) => {
      state.rejectOrdersLoading = true;
      state.rejectOrders = null;
      state.rejectOrdersMeta = null;
      state.rejectOrdersError = null;
    },
    resetGetOrder: (state) => {
      state.orderLoading = true;
      state.orderResponce = null;
      state.orderError = null;
    },
    resetCreateRoad: (state) => {
      state.createRoadLoading = false;
      state.createRoadResponce = null;
      state.createRoadResponceArray = [];
      state.createRoadErrorArray = [];
      state.createRoadError = null;
    },
    resetUpdateRoad: (state) => {
      state.updateRoadLoading = false;
      state.updateRoadResponce = null;
      state.updateRoadError = null;
    },
    resetGroupRoad: (state) => {
      state.groupRoadLoading = false;
      state.groupRoadResponce = null;
      state.groupRoadError = null;
    },
    resetCreateAir: (state) => {
      state.createAirLoading = false;
      state.createAirResponce = null;
      state.createAirError = null;
    },
    resetUpdateAir: (state) => {
      state.updateAirLoading = false;
      state.updateAirResponce = null;
      state.updateAirError = null;
    },
    resetCreateOcean: (state) => {
      state.createOceanLoading = false;
      state.createOceanResponce = null;
      state.createOceanError = null;
    },
    resetUpdateOcean: (state) => {
      state.updateOceanLoading = false;
      state.updateOceanResponce = null;
      state.updateOceanError = null;
    },
    resetDeleteOrder: (state) => {
      state.deleteLoading = false;
      state.deleteResponce = null;
      state.deleteError = null;
    },
    resetTakeOrder: (state) => {
      state.takeOrderLoading = false;
      state.takeOrderResponce = null;
      state.takeOrderError = null;
    },
    resetRejectOrder: (state) => {
      state.rejectOrderLoading = false;
      state.rejectOrderResponce = null;
      state.rejectOrderError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrders.pending, (state) => {
        state.ordersLoading = true;
        state.orders = null;
        state.ordersMeta = null;
        state.ordersError = null;
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        state.ordersLoading = false;
        state.orders = action.payload.data;
        state.ordersMeta = action.payload.meta;
        state.ordersError = null;
      })
      .addCase(getOrders.rejected, (state, action) => {
        state.ordersLoading = false;
        state.orders = null;
        state.ordersMeta = null;
        state.ordersError = action.payload;
      })

      .addCase(getRejectOrders.pending, (state) => {
        state.rejectOrdersLoading = true;
        state.rejectOrders = null;
        state.rejectOrdersMeta = null;
        state.rejectOrdersError = null;
      })
      .addCase(getRejectOrders.fulfilled, (state, action) => {
        state.rejectOrdersLoading = false;
        state.rejectOrders = action.payload.data;
        state.rejectOrdersMeta = action.payload.meta;
        state.rejectOrdersError = null;
      })
      .addCase(getRejectOrders.rejected, (state, action) => {
        state.rejectOrdersLoading = false;
        state.rejectOrders = null;
        state.rejectOrdersMeta = null;
        state.rejectOrdersError = action.payload;
      })

      .addCase(getOrder.pending, (state) => {
        state.orderLoading = true;
        state.orderResponce = null;
        state.orderError = null;
      })
      .addCase(getOrder.fulfilled, (state, action) => {
        state.orderLoading = false;
        state.orderResponce = action.payload;
        state.orderError = null;
      })
      .addCase(getOrder.rejected, (state, action) => {
        state.orderLoading = false;
        state.orderResponce = null;
        state.orderError = action.payload;
      })

      .addCase(createRoad.pending, (state) => {
        state.createRoadLoading = true;
        state.createRoadResponce = null;
        state.createRoadResponceArray = [];
        state.createRoadErrorArray = [];
        state.createRoadError = null;
      })
      .addCase(createRoad.fulfilled, (state, action) => {
        state.createRoadLoading = false;
        state.createRoadResponce = action.payload;
        state.createRoadResponceArray = [
          ...state.createRoadResponceArray,
          action.payload.id,
        ];
        state.createRoadError = null;
      })
      .addCase(createRoad.rejected, (state, action) => {
        state.createRoadLoading = false;
        state.createRoadResponce = null;
        state.createRoadErrorArray = [
          ...state.createRoadErrorArray,
          action.payload,
        ];
        state.createRoadError = action.payload;
      })

      .addCase(updateRoad.pending, (state) => {
        state.updateRoadLoading = true;
        state.updateRoadResponce = null;
        state.updateRoadError = null;
      })
      .addCase(updateRoad.fulfilled, (state, action) => {
        state.updateRoadLoading = false;
        state.updateRoadResponce = action.payload;
        state.updateRoadError = null;
      })
      .addCase(updateRoad.rejected, (state, action) => {
        state.updateRoadLoading = false;
        state.updateRoadResponce = null;
        state.updateRoadError = action.payload;
      })

      .addCase(groupRoad.pending, (state) => {
        state.groupRoadLoading = true;
        state.groupRoadResponce = null;
        state.groupRoadError = null;
      })
      .addCase(groupRoad.fulfilled, (state, action) => {
        state.groupRoadLoading = false;
        state.groupRoadResponce = action.payload;
        state.groupRoadError = null;
      })
      .addCase(groupRoad.rejected, (state, action) => {
        state.groupRoadLoading = false;
        state.groupRoadResponce = null;
        state.groupRoadError = action.payload;
      })

      .addCase(createAir.pending, (state) => {
        state.createAirLoading = true;
        state.createAirResponce = null;
        state.createAirError = null;
      })
      .addCase(createAir.fulfilled, (state, action) => {
        state.createAirLoading = false;
        state.createAirResponce = action.payload;
        state.createAirError = null;
      })
      .addCase(createAir.rejected, (state, action) => {
        state.createAirLoading = false;
        state.createAirResponce = null;
        state.createAirError = action.payload;
      })

      .addCase(updateAir.pending, (state) => {
        state.updateAirLoading = true;
        state.updateAirResponce = null;
        state.updateAirError = null;
      })
      .addCase(updateAir.fulfilled, (state, action) => {
        state.updateAirLoading = false;
        state.updateAirResponce = action.payload;
        state.updateAirError = null;
      })
      .addCase(updateAir.rejected, (state, action) => {
        state.updateAirLoading = false;
        state.updateAirResponce = null;
        state.updateAirError = action.payload;
      })

      .addCase(createOcean.pending, (state) => {
        state.createOceanLoading = true;
        state.createOceanResponce = null;
        state.createOceanError = null;
      })
      .addCase(createOcean.fulfilled, (state, action) => {
        state.createOceanLoading = false;
        state.createOceanResponce = action.payload;
        state.createRoadError = null;
      })
      .addCase(createOcean.rejected, (state, action) => {
        state.createOceanLoading = false;
        state.createOceanResponce = null;
        state.createOceanError = action.payload;
      })

      .addCase(updateOcean.pending, (state) => {
        state.updateOceanLoading = true;
        state.updateOceanResponce = null;
        state.updateOceanError = null;
      })
      .addCase(updateOcean.fulfilled, (state, action) => {
        state.updateOceanLoading = false;
        state.updateOceanResponce = action.payload;
        state.updateOceanError = null;
      })
      .addCase(updateOcean.rejected, (state, action) => {
        state.updateOceanLoading = false;
        state.updateOceanResponce = null;
        state.updateOceanError = action.payload;
      })

      .addCase(deleteOrder.pending, (state) => {
        state.deleteLoading = true;
        state.deleteResponce = null;
        state.deleteError = null;
      })
      .addCase(deleteOrder.fulfilled, (state, action) => {
        state.deleteLoading = false;
        state.deleteResponce = action.payload;
        state.deleteError = null;
      })
      .addCase(deleteOrder.rejected, (state, action) => {
        state.deleteLoading = false;
        state.deleteResponce = null;
        state.deleteError = action.payload;
      })

      .addCase(takeOrder.pending, (state) => {
        state.takeOrderLoading = true;
        state.takeOrderResponce = null;
        state.takeOrderError = null;
      })
      .addCase(takeOrder.fulfilled, (state, action) => {
        state.takeOrderLoading = false;
        state.takeOrderResponce = action.payload;
        state.takeOrderError = null;
      })
      .addCase(takeOrder.rejected, (state, action) => {
        state.takeOrderLoading = false;
        state.takeOrderResponce = null;
        state.takeOrderError = action.payload;
      })
      .addCase(rejectOrder.pending, (state) => {
        state.rejectOrderLoading = true;
        state.rejectOrderResponce = null;
        state.rejectOrderError = null;
      })
      .addCase(rejectOrder.fulfilled, (state, action) => {
        state.rejectOrderLoading = false;
        state.rejectOrderResponce = action.payload;
        state.rejectOrderError = null;
      })
      .addCase(rejectOrder.rejected, (state, action) => {
        state.rejectOrderLoading = false;
        state.rejectOrderResponce = null;
        state.rejectOrderError = action.payload;
      });
  },
});

export const {
  resetGetOrders,
  resetGetRejectOrders,
  resetGetOrder,
  resetCreateRoad,
  resetUpdateRoad,
  resetGroupRoad,
  resetCreateAir,
  resetUpdateAir,
  resetCreateOcean,
  resetUpdateOcean,
  resetDeleteOrder,
  resetTakeOrder,
  resetRejectOrder,
} = shipperOrdersSlice.actions;
export default shipperOrdersSlice.reducer;
