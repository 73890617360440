import React from "react";
import "./OrderComment.scss";

import { useTranslation } from "react-i18next";

const OrderComment = ({ order }) => {
  const { t } = useTranslation();

  return order?.cargos[0]?.about ? (
    <div id='OrderComment'>
      {t("tms.titles.Additional information")}:{" "}
      <span>{order?.cargos[0]?.about}</span>
    </div>
  ) : null;
};

export default OrderComment;
