import React, { Fragment, useState, useEffect } from "react";
import "./AddNewDocumentModal.scss";

import { useTranslation } from "react-i18next";

import { MdAttachFile } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";

import {
  createOrderDocument,
  resetCreateOrderDocument,
} from "../../../../../store/orders/documents/documentsSlice";
import { useOrderDocumentsErrors } from "./../../../../../validations/order/useOrderDocumentsErrors";

import DModal from "../../../../Modal/DModal/DModal";
import DFile from "./../../../../Form/DFile/DFile";
import DSelect from "./../../../../Form/DSelect/DSelect";
import UploadDocumentItem from "./../UploadDocumentItem/UploadDocumentItem";
import DErrorText from "./../../../../Form/DErrorText/DErrorText";
import DCheckBox from "../../../../Form/DCheckBox/DCheckBox";

const AddNewDocumentModal = ({ isOpen, isClose, orderId, order }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    createOrderDocumentLoading,
    createOrderDocumentResponce,
    getOrderDocumentTypesLoading,
    getOrderDocumentTypesSelectResponce,
  } = useSelector((state) => state.orderDocuments);

  const {
    create_document_type_id_error,
    create_document_error,
    create_document_Network_error,
  } = useOrderDocumentsErrors();

  const [documentType, setDocumentType] = useState(null);
  const [file, setFile] = useState(null);
  const [isGroup, setIsGroup] = useState(false);

  const isGroupOrder = order?.groups?.length > 0;

  const submitHandler = async () => {
    const data = {
      orderId: orderId,
      document_type_id: documentType && documentType.value.id,
      document: file,
      publish_to_group: isGroup ? 1 : 0,
    };
    dispatch(createOrderDocument(data));
  };

  useEffect(() => {
    if (createOrderDocumentResponce) {
      dispatch(resetCreateOrderDocument());
      setFile(null);
      setDocumentType(null);
      isClose();
    }
  }, [dispatch, isClose, createOrderDocumentResponce]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose()}
        onClick={() => submitHandler()}
        title={t("tms.modals.titles.Upload document")}
        submitText={t("tms.modals.buttons.Save")}
        icon={<MdAttachFile />}
        status='success'
        loading={createOrderDocumentLoading}
      >
        <div id='AddNewDocumentModal'>
          <form className='formGroup'>
            <DSelect
              id='OrderAdditionalDocumentsAmount'
              value={documentType}
              setValue={setDocumentType}
              placeholder={t("tms.modals.placeholder.Document type")}
              label={t(
                "tms.modals.labels.What type  of document you are uploading?"
              )}
              options={
                getOrderDocumentTypesSelectResponce
                  ? getOrderDocumentTypesSelectResponce
                  : []
              }
              loading={getOrderDocumentTypesLoading}
              required
              error={create_document_type_id_error}
            />
            {file ? (
              <UploadDocumentItem
                onClick={() => setFile(null)}
                fileName={file.name ? file.name : ""}
              />
            ) : (
              <DFile
                title={t("tms.modals.labels.Choose from your computer")}
                onChange={(e) => setFile(e.target.files[0])}
              />
            )}

            {isGroupOrder && (
              <div className='isGroupCheckbox'>
                <DCheckBox
                  value={isGroup}
                  label={t(
                    "tms.modals.labels.Upload in every truck document section that are related to this order"
                  )}
                  setValue={setIsGroup}
                />
              </div>
            )}

            <DErrorText
              error={
                create_document_error ||
                (create_document_Network_error &&
                  t("tms.modals.errors.Reduce file size"))
              }
            />
          </form>
        </div>
      </DModal>
    </Fragment>
  );
};

export default AddNewDocumentModal;
