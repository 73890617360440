import React from "react";
import "./TrailersNewOrder.scss";

import { useTranslation } from "react-i18next";

import NewOrderTitle from "../Components/NewOrderTitle/NewOrderTitle";
import TrailerManagement from "./../Components/TrailerManagement/TrailerManagement";

const TrailersNewOrder = ({
  edit,
  orderType,
  trailers,
  setTrailers,
  trailer_type_id_error,
  trailer_size_id_error,
  cargos_weight_error,
}) => {
  const { t } = useTranslation();

  return (
    <div id='TrailersNewOrder'>
      <NewOrderTitle title={t("tms.titles.Customer requirements")} />
      <div className='TrailersNewOrderWrapper'>
        <TrailerManagement
          edit={edit}
          trailer_type_id_error={trailer_type_id_error}
          trailer_size_id_error={trailer_size_id_error}
          cargos_weight_error={cargos_weight_error}
          orderType={orderType}
          trailers={trailers}
          setTrailers={setTrailers}
        />
      </div>
    </div>
  );
};

export default TrailersNewOrder;
