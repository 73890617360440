import React from "react";
import "./Step5.scss";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { FaCheck } from "react-icons/fa";
import { MdKeyboardArrowRight } from "react-icons/md";

import Button from "../../../../Form/Button/Button";

const Step5 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/");
  };
  return (
    <div id='CarrierSubscribeFormStep5'>
      <div className='titleContainer'>
        <div className='title'>{t("landing.landingCarrierSubscribe.Congratulations !")}</div>
        <div className='subTitle'>
          {t(
            "landing.landingCarrierSubscribe.You are now our team member, we will send you an available shipments every day"
          )}
        </div>
      </div>
      <div className='icon'>
        <FaCheck />
      </div>
      <Button
        title={t("landing.landingCarrierSubscribe.Go to a main page")}
        onClick={handleNavigate}
        rightIcon={<MdKeyboardArrowRight />}
      />
    </div>
  );
};

export default Step5;
