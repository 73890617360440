import { useSelector } from "react-redux";

export const useConnectProviderErrors = () => {
  const { connectProviderError } = useSelector((state) => state.GPSTraker);

  const connect_provider_id_error =
    connectProviderError &&
    connectProviderError.errors &&
    connectProviderError.errors.provider_id &&
    connectProviderError.errors.provider_id[0];

  const connect_username_error =
    connectProviderError &&
    connectProviderError.errors &&
    connectProviderError.errors.username &&
    connectProviderError.errors.username[0];

  const connect_password_error =
    connectProviderError &&
    connectProviderError.errors &&
    connectProviderError.errors.password &&
    connectProviderError.errors.password[0];

  const connect_token_error =
    connectProviderError &&
    connectProviderError.errors &&
    connectProviderError.errors.token &&
    connectProviderError.errors.token[0];

  const connect_message =
    connectProviderError &&
    connectProviderError.message &&
    connectProviderError.message;

  return {
    connect_provider_id_error,
    connect_username_error,
    connect_password_error,
    connect_token_error,
    connect_message,
  };
};

export default useConnectProviderErrors;
