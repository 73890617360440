import React, { Fragment, useState, useEffect } from "react";
import "./CreateCustomerModal.scss";

import { useTranslation } from "react-i18next";

import { FaPlusCircle } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";

import {
  createCustomer,
  resetCreateCustomer,
} from "./../../../../store/participants/customer/customerSlice";
import { useCustomerErrors } from "./../../../../validations/participant/customer/useCustomerErrors";

import DModal from "../../../Modal/DModal/DModal";
import DInputs from "../../../Form/DInputs/DInputs";
import DPhoneInput from "./../../../Form/DPhoneInput/DPhoneInput";

const CreateCustomerModal = ({ isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { createCustomerLoading, createCustomerResponce } = useSelector(
    (state) => state.customer
  );
  const { phoneIndexValueId } = useSelector((state) => state.settings);
  const {
    create_company_legal_id_error,
    create_company_name_error,
    create_company_email_error,
    create_company_phone_error,
    create_company_phone_index_id_error,
    create_company_address_error,
  } = useCustomerErrors();
  
  const [customerID, setCustomerID] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");

  const resetState = () => {
    setCustomerID("");
    setCustomerName("");
    setEmail("");
    setPhone("");
    setAddress("");
  };

  const submitHandler = () => {
    const data = {
      type: "Customer",
      company: {
        legal_id: customerID,
        name: customerName,
        email: email,
        phone: phone,
        phone_index_id: phoneIndexValueId,
        address: address,
      },
    };
    dispatch(createCustomer(data));
  };

  useEffect(() => {
    if (createCustomerResponce) {
      dispatch(resetCreateCustomer());
      resetState();
      isClose();
    }
  }, [dispatch, isClose, createCustomerResponce]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={isClose}
        title={t("tms.modals.titles.Add Customer")}
        icon={<FaPlusCircle />}
        status='success'
        onClick={() => submitHandler()}
        submitText={t("tms.modals.buttons.Save")}
        loading={createCustomerLoading}
      >
        <div id='CreateCustomerModal'>
          <div className='CreateCustomerModalgroup'>
            <DInputs
              value={customerName}
              setValue={setCustomerName}
              placeholder={t("tms.modals.placeholder.Enter customer name")}
              label={t("tms.modals.labels.Customer name")}
              required
              id='customerName'
              error={create_company_name_error}
            />
            <DInputs
              value={customerID}
              setValue={setCustomerID}
              placeholder={t("tms.modals.placeholder.Enter customer ID")}
              label={t("tms.modals.labels.Customer ID")}
              required
              id='CustomerID'
              error={create_company_legal_id_error}
            />

            <DInputs
              value={email}
              setValue={setEmail}
              placeholder={t("tms.modals.placeholder.Enter email")}
              label={t("tms.modals.labels.Email")}
              required
              id='CustomerEmail'
              error={create_company_email_error}
            />
            <DPhoneInput
              id='CustomerPhone'
              placeholder={t("tms.modals.placeholder.Enter phone number")}
              label={t("tms.modals.labels.Phone number")}
              value={phone}
              setValue={setPhone}
              error={
                create_company_phone_error ||
                create_company_phone_index_id_error
              }
            />
            <DInputs
              value={address}
              setValue={setAddress}
              placeholder={t("tms.modals.placeholder.Enter address")}
              label={t("tms.modals.labels.Address")}
              id='CustomerAddress'
              error={create_company_address_error}
            />
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default CreateCustomerModal;
