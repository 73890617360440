import React, { useCallback, useEffect } from "react";

import { FaCheckCircle } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  takeOrder,
  resetTakeOrder,
} from "./../../../../store/orders/shipperOrders/shipperOrdersSlice";
import {
  setActiveOrderId,
  setShipperAcceptedOrderView,
} from "../../../../store/orders/shipperAccepted/shipperAcceptedSlice";

import DModal from "../../../Modal/DModal/DModal";

const ShipperOfferConfirmModal = ({
  isOpen,
  isClose,
  order,
  setParentModalClose,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { takeOrderLoading, takeOrderResponce } = useSelector(
    (state) => state.shipperOrders
  );

  const clickFieldHandler = useCallback(() => {
    dispatch(setShipperAcceptedOrderView(false));
    dispatch(setActiveOrderId(order.id));
    navigate("/dashboard/shipper/accepted");
  }, [dispatch, navigate, order.id]);

  useEffect(() => {
    if (takeOrderResponce) {
      dispatch(resetTakeOrder());
      isClose();
      setParentModalClose && setParentModalClose();
      clickFieldHandler();
    }
  }, [
    clickFieldHandler,
    dispatch,
    isClose,
    setParentModalClose,
    takeOrderResponce,
  ]);

  const submitHandler = () => {
    const data = {
      orderId: order.id,
      bidId: order?.bids?.[0]?.id,
    };
    dispatch(takeOrder(data));
  };

  return (
    <DModal
      isOpen={isOpen}
      isClose={isClose}
      onClick={() => submitHandler()}
      submitText={t("shipper.buttons.Confirm")}
      title={t("shipper.modal.Confirm offer")}
      icon={<FaCheckCircle />}
      status='success'
      loading={takeOrderLoading}
    >
      <p>{t("shipper.modal.Are you sure you want to confirm this offer?")}</p>
    </DModal>
  );
};

export default ShipperOfferConfirmModal;
