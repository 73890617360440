import React, { useEffect, useState } from "react";
import "./FilterShippingType.scss";

import { useTranslation } from "react-i18next";
import {
  MdLocalShipping,
  // MdDirectionsRailway,
  MdFlight,
} from "react-icons/md";
import { BiSolidShip } from "react-icons/bi";

import FilterShippingTypeItem from "./FilterShippingTypeItem/FilterShippingTypeItem";

const FilterShippingType = ({ shippingType, setShippingType }) => {
  const { t } = useTranslation();
  const [active, setActive] = useState(1);

  useEffect(() => {
    shippingType === null && active !== 1 && setActive(1);
  }, [active, shippingType]);

  const arr = [
    {
      label: t("shipper.filters.All"),
      value: {
        id: 1,
        orderTypeId: null,
      },
    },
    {
      label: t("shipper.filters.Land"),
      value: {
        id: 2,
        orderTypeId: [1, 2],
        icon: <MdLocalShipping />,
      },
    },
    {
      label: t("shipper.filters.Ocean"),
      value: {
        id: 3,
        orderTypeId: [3, 4],
        icon: <BiSolidShip />,
      },
    },
    {
      label: t("shipper.filters.Air"),
      value: {
        id: 4,
        orderTypeId: [5],
        icon: <MdFlight />,
      },
    },
    // {
    //   label: t("shipper.filters.Rail"),
    //   value: {
    //     id: 5,
    //     orderTypeId: [6, 7],
    //     icon: <MdDirectionsRailway />,
    //   },
    // },
  ];

  return (
    <div id='FilterShippingType'>
      {arr?.map((type, index) => {
        return (
          <FilterShippingTypeItem
            key={index}
            title={type?.label}
            active={active === type?.value?.id}
            onClick={() => {
              setShippingType(type?.value?.orderTypeId);
              setActive(type?.value?.id);
            }}
            icon={type?.value?.icon || null}
          />
        );
      })}
    </div>
  );
};

export default FilterShippingType;
