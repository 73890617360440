import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import biddingService from "./biddingService";

const initialState = {
  getOrderDataLoading: true,
  getOrderDataResponce: null,
  getOrderDataError: null,

  getBidsCurrencyLoading: true,
  getBidsCurrencySelect: null,
  getBidsCurrencyError: null,

  sendBidLoading: false,
  sendBidResponce: null,
  sendBidError: null,
};

export const getOrderData = createAsyncThunk(
  "bidding/getOrderData",
  async (token, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      return await biddingService.getOrderData(ISO, token);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getBidsCurrency = createAsyncThunk(
  "bidding/getBidsCurrency",
  async (BranchId, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      return await biddingService.getBidsCurrency(ISO, BranchId);
    } catch (error) {
      const message =
        (error.response && error.response.data) || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const sendBid = createAsyncThunk(
  "bidding/sendBid",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      return await biddingService.sendBid(ISO, data.orderId, data.data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const biddingSlice = createSlice({
  name: "bidding",
  initialState,
  reducers: {
    resetSendBid: (state) => {
      state.sendBidLoading = false;
      state.sendBidResponce = null;
      state.sendBidError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrderData.pending, (state) => {
        state.getOrderDataLoading = true;
        state.getOrderDataResponce = null;
        state.getOrderDataError = null;
      })
      .addCase(getOrderData.fulfilled, (state, action) => {
        state.getOrderDataLoading = false;
        state.getOrderDataResponce = action.payload;
        state.getOrderDataError = null;
      })
      .addCase(getOrderData.rejected, (state, action) => {
        state.getOrderDataLoading = false;
        state.getOrderDataResponce = null;
        state.getOrderDataError = action.payload;
      })

      .addCase(getBidsCurrency.pending, (state) => {
        state.getBidsCurrencyLoading = true;
        state.getBidsCurrencySelect = null;
        state.getBidsCurrencyError = null;
      })
      .addCase(getBidsCurrency.fulfilled, (state, action) => {
        state.getBidsCurrencyLoading = false;
        state.getBidsCurrencySelect = action.payload.map((item) => ({
          value: item,
          label: item.code,
        }));
        state.getBidsCurrencyError = null;
      })
      .addCase(getBidsCurrency.rejected, (state, action) => {
        state.getBidsCurrencyLoading = false;
        state.getBidsCurrencySelect = null;
        state.getBidsCurrencyError = action.payload;
      })

      .addCase(sendBid.pending, (state) => {
        state.sendBidLoading = true;
        state.sendBidResponce = null;
        state.sendBidError = null;
      })
      .addCase(sendBid.fulfilled, (state, action) => {
        state.sendBidLoading = false;
        state.sendBidResponce = action.payload;
        state.sendBidError = null;
      })
      .addCase(sendBid.rejected, (state, action) => {
        state.sendBidLoading = false;
        state.sendBidResponce = null;
        state.sendBidError = action.payload;
      });
  },
});

export const { resetSendBid } = biddingSlice.actions;
export default biddingSlice.reducer;
