import React, { useState, useEffect } from "react";
import "./PasswordReset.scss";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { reset, resetPassword } from "./../../../../store/auth/authSlice";
import { useAuthError } from "../../../../validations/auth/useAuthError";

import Button from "../../../Form/Button/Button";
import Input from "../../../Form/Inputs/Input";
import ErrorText from "../../../Form/ErrorText/ErrorText";
import AuthFormWrapper from "../../Components/AuthFormWrapper/AuthFormWrapper";
import FormTitle from "../../Components/FormTitle/FormTitle";
import Label from "../../../Form/Label/Label";

const PasswordReset = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { phoneIndexValueId } = useSelector((state) => state.settings);

  const inputValue = searchParams.get("input-value");
  const method = searchParams.get("method");
  const otp = searchParams.get("value");
  const { password_error, reset_code_error } = useAuthError();
  const { loading, data } = useSelector((state) => state.auth);
  const [password, setPassword] = useState("");

  const submitHandler = () => {
    let data =
      method === "EMAIL"
        ? {
            code: otp,
            email: inputValue,
            password,
            password_confirmation: password,
          }
        : {
            code: otp,
            phone_index_id: phoneIndexValueId,
            phone: inputValue,
            password,
            password_confirmation: password,
          };

    dispatch(resetPassword(data));
  };

  useEffect(() => {
    if (data) {
      const url = "/forgot-password/success";
      navigate(url);
      dispatch(reset());
      setPassword("");
    }
  }, [dispatch, navigate, data]);

  return (
    <AuthFormWrapper>
      <form id='PasswordReset' className='PasswordReset'>
        <FormTitle title={t("landing.auth.titles.Reset password")} center />
        <div>
          <Label
            text={t("landing.auth.form.New password")}
            id='EnterResetPassword'
            warning
            warningText={t(
              "landing.form.warningText.password must contain minimum of 6 character"
            )}
          />
          <Input
            id='EnterResetPassword'
            value={password}
            setValue={setPassword}
            placeholder={t("landing.auth.form.Enter new  password")}
            password
            error={password_error || reset_code_error}
          />
          <ErrorText error={password_error} />
          <ErrorText error={reset_code_error} />
        </div>
        <Button
          loading={loading}
          title={t("landing.auth.form.Change password")}
          onClick={() => {
            submitHandler();
          }}
        />
      </form>
    </AuthFormWrapper>
  );
};

export default PasswordReset;
