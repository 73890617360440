import { useSelector } from "react-redux";

export const useCreateOrderErrors = () => {
  const { createError } = useSelector((state) => state.orders);

  const type_id_error =
    createError &&
    createError.errors &&
    createError.errors.type_id &&
    createError.errors.type_id[0];

  const customer_id_error =
    createError &&
    createError.errors &&
    createError.errors.customer_id &&
    createError.errors.customer_id[0];

  const trailer_type_id_error =
    createError &&
    createError.errors &&
    createError.errors.trailer_type_id &&
    createError.errors.trailer_type_id[0];

  const trailer_size_id_error =
    createError &&
    createError.errors &&
    createError.errors.trailer_size_id &&
    createError.errors.trailer_size_id[0];

  const cargos_type_id_error =
    createError &&
    createError.errors &&
    createError.errors["cargos.0.type_id"] &&
    createError.errors["cargos.0.type_id"][0];

  const cargos_weight_error =
    createError &&
    createError.errors &&
    createError.errors["cargos.0.weight"] &&
    createError.errors["cargos.0.weight"][0];

  const cargos_adr_error =
    createError &&
    createError.errors &&
    createError.errors["cargos.0.adr"] &&
    createError.errors["cargos.0.adr"][0];

  const cargos_pickup_date_error =
    createError &&
    createError.errors &&
    createError.errors["cargos.0.pickup_date"] &&
    createError.errors["cargos.0.pickup_date"][0];

  const payment_type_id_error =
    createError &&
    createError.errors &&
    createError.errors["payment.type_id"] &&
    createError.errors["payment.type_id"][0];

  const payment_days_error =
    createError &&
    createError.errors &&
    createError.errors["payment.days"] &&
    createError.errors["payment.days"][0];

  const payment_option_type_error =
    createError &&
    createError.errors &&
    createError.errors["payment.option_type"] &&
    createError.errors["payment.option_type"][0];

  const payment_amount_error =
    createError &&
    createError.errors &&
    createError.errors["payment.amount"] &&
    createError.errors["payment.amount"][0];

  const payment_amount_currency_error =
    createError &&
    createError.errors &&
    createError.errors["payment.currency_id"] &&
    createError.errors["payment.currency_id"][0];

  return {
    type_id_error,
    customer_id_error,
    trailer_type_id_error,
    trailer_size_id_error,
    cargos_type_id_error,
    cargos_weight_error,
    cargos_adr_error,
    cargos_pickup_date_error,
    payment_type_id_error,
    payment_option_type_error,
    payment_days_error,
    payment_amount_error,
    payment_amount_currency_error,
  };
};

export default useCreateOrderErrors;
