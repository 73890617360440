import React, { useState, useEffect } from "react";
import "./ActiveList.scss";

import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  getActive,
  resetGetActive,
} from "../../../../../store/financial/financialSlice";

import Wrapper from "../../../../../componentsAdditional/Wrapper";
import PageSwitcher from "../../../../../componentsDashboard/PageSwitcher/PageSwitcher";
import ActiveFilter from "../../../../../componentsDashboard/FinancialManagement/ActiveFilter/ActiveFilter";
import ActiveTable from "./../../../../../componentsDashboard/FinancialManagement/ActiveTable/ActiveTable";
import LoadingPage from "../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import MainTitle from "../../../../../componentsDashboard/MainTitle/MainTitle";

const ActiveList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { getActiveLoading, getActiveResult, getActiveMeta } = useSelector(
    (state) => state.financial
  );
  const { markPaidResponce, createAdvanceResponce } = useSelector(
    (state) => state.advance
  );
  const { createInvoiceResponce } = useSelector(
    (state) => state.orderDocuments
  );

  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1
  );
  const [keyword, setKeyword] = useState("");
  const [customer, setCustomer] = useState(null);
  const [route, setRoute] = useState({});
  const [status, setStatus] = useState(null);
  // const [paymentType, setPaymentType] = useState(null);

  const handleClearFilters = () => {
    if (keyword) setKeyword("");
    if (customer) setCustomer(null);
    if (Object.keys(route).length > 0) setRoute({});
    if (status) setStatus(null);
    // if (paymentType) setPaymentType(null);
  };

  useEffect(() => {
    dispatch(
      getActive({
        page,
        keyword,
        customer_id: customer?.value?.id,
        pickup_address: route?.fromCountry?.label,
        dropoff_address: route?.toCountry?.label,
        status_id: status?.value,
      })
    );
    return () => {
      dispatch(resetGetActive());
    };
  }, [
    customer,
    dispatch,
    keyword,
    page,
    route,
    markPaidResponce,
    createAdvanceResponce,
    createInvoiceResponce,
    status,
  ]);

  return (
    <Wrapper documentTitle={t("tms.documentTitles.Payment Management")}>
      <section id='ActiveList'>
        <MainTitle title={t("tms.titles.Payment Management")} />
        <PageSwitcher
          name='financialinformationPending'
          // badge={getActiveMeta ? getActiveMeta.total : 0}
        />
        <ActiveFilter
          clear={handleClearFilters}
          keyword={keyword}
          setKeyword={setKeyword}
          customer={customer}
          setCustomer={setCustomer}
          route={route}
          setRoute={setRoute}
          status={status}
          setStatus={setStatus}
          // paymentType={paymentType}
          // setPaymentType={setPaymentType}
        />

        {getActiveLoading && <LoadingPage />}
        {getActiveResult && !getActiveLoading && (
          <ActiveTable
            items={getActiveResult}
            page={page}
            setPage={setPage}
            pageCount={getActiveMeta.last_page}
            total={getActiveMeta.total}
          />
        )}
      </section>
    </Wrapper>
  );
};

export default ActiveList;
