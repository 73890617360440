import React from "react";
import "./AlreadyPaidMoneyField.scss";

import { useTranslation } from "react-i18next";
import { MdAdd } from "react-icons/md";

import { useMoney } from "./../../../../helper/useMoney";

import DButton from "./../../DButtons/DButton/DButton";
import DIconButton from "./../../DButtons/DIconButton/DIconButton";

const AlreadyPaidMoneyField = ({ item, onClick }) => {
  const { t } = useTranslation();
  const formatedPayMoney = useMoney(
    item?.total_paid || 0,
    item?.revenue?.currency?.symbol || ""
  );
  const formatedLeftMoney = useMoney(
    item?.total_left_paid || 0 || 0,
    item?.revenue?.currency?.symbol || ""
  );

  return (
    <td id='AlreadyPaidMoneyField'>
      {item.total_paid > 0 ? (
        <div className='AlreadyPaidMoneyFieldContainer'>
          <div>
            <h1>{formatedPayMoney}</h1>
            <p>
              {formatedLeftMoney} {t("tms.tables.others.left")}
            </p>
          </div>
          <DIconButton icon={<MdAdd />} onClick={onClick} />
        </div>
      ) : (
        <DButton
          title={t("tms.tables.buttons.Add")}
          rightIcon={<MdAdd />}
          onClick={onClick}
        />
      )}
    </td>
  );
};

export default AlreadyPaidMoneyField;
