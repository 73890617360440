import React from "react";
import "./FiltersContainer.scss";

import { useTranslation } from "react-i18next";

import DTextButton from "../../Form/DButtons/DTextButton/DTextButton";

const FiltersContainer = ({ children, onClear }) => {
  const { t } = useTranslation();

  return (
    <div id='FiltersContainer'>
      <div className='filters'>
        <div className='filtersTitle'>{t("tms.filters.others.Filter by")}</div>
        <div className='filtersBody'>{children}</div>
      </div>
      <div className='clear'>
        <DTextButton
          title={t("tms.filters.others.Clear all")}
          onClick={onClear}
        />
      </div>
    </div>
  );
};

export default FiltersContainer;
