import React, { Fragment, useState } from "react";
import "./Cover.scss";

import { useTranslation } from "react-i18next";

import TalkExpert from "../../Components/TalkExpert/TalkExpert";
import FadeTextAnimation from "../../../UI/Animations/FadeTextAnimation/FadeTextAnimation";

const Cover = () => {
  const { t } = useTranslation();
  const [ofenTalkExpert, setOfenTalkExpert] = useState(false);

  return (
    <Fragment>
      <TalkExpert open={ofenTalkExpert} setOpen={setOfenTalkExpert} />
      <section id='Cover' className='Cover'>
        <video
          poster='/assets/video/CargonVideoCoverPoster.jpg'
          src='/assets/video/CargonVideoCover.mp4'
          type='video/mp4'
          autoPlay={true}
          loop
          playsInline
          muted='muted'
        ></video>
        <div className='blar'>
          <div className='container'>
            <div className='textContainer'>
              <FadeTextAnimation
                text={t(
                  "landing.titles.landingMainTitles.Elevate your supply chain"
                )}
              />
            </div>

            <div
              data-aos='fade'
              data-aos-delay='500'
              data-aos-duration='1500'
              data-aos-easing='ease-in-out'
            >
              <div
                className='submit'
                onClick={() => {
                  setOfenTalkExpert((state) => !state);
                }}
              >
                {t("landing.form.button.Talk to an expert")}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Cover;
