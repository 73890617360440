import React, { Fragment, useState, useEffect } from "react";
import "./ProfileSettings.scss";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { getUser, reset } from "../../../store/auth/authSlice";

import DTextButton from "../../Form/DButtons/DTextButton/DTextButton";
import DLanguageSelect from "../../Form/DSelect/DLanguageSelect/DLanguageSelect";
import ProfileSettingsItem from "./ProfileSettingsItem/ProfileSettingsItem";
import DCurrencySelect from "../../Form/DSelect/DCurrencySelect/DCurrencySelect";
import EditEmailModal from "./components/EditEmailModal/EditEmailModal";
import EditPhoneModal from "./components/EditPhoneModal/EditPhoneModal";
import ChangePasswordModal from "./components/ChangePasswordModal/ChangePasswordModal";
import LogoutModal from "./components/LogoutModal/LogoutModal";

const ProfileSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data, userType } = useSelector((state) => state.auth);

  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const [openLogOutModal, setOpenLogOutModal] = useState(false);
  const [openEmailEditModal, setOpenEmailEditModal] = useState(false);
  const [openPhoneEditModal, setOpenPhoneEditModal] = useState(false);

  useEffect(() => {
    if (data) {
      dispatch(getUser());
      dispatch(reset());
    }
  }, [dispatch, data]);

  return (
    <Fragment>
      <div id='profileSettings'>
        <div className='titleContainer'>
          <div className='title'>{t("tms.titles.Settings")}</div>
        </div>
        <div className='items'>
          <ProfileSettingsItem
            title={t("tms.titles.Language")}
            text={t("tms.desc.Set to your preferred language")}
            button={<DLanguageSelect width={"130px"} />}
          />
          <ProfileSettingsItem
            title={t("tms.titles.Currency")}
            text={t("tms.desc.Set to your preferable currency")}
            button={<DCurrencySelect width={"130px"} />}
          />
          {(userType === 4 || userType === 5) && (
            <ProfileSettingsItem
              title={t("tms.titles.Email")}
              text={t("tms.desc.Change your email")}
              button={
                <DTextButton
                  title={t("tms.forms.buttons.Change email")}
                  onClick={() => setOpenEmailEditModal(true)}
                  info
                />
              }
            />
          )}
          <ProfileSettingsItem
            title={t("tms.titles.Phone number")}
            text={t("tms.desc.Change your phone number")}
            button={
              <DTextButton
                title={t("tms.forms.buttons.Change phone number")}
                onClick={() => setOpenPhoneEditModal(true)}
                info
              />
            }
          />
          <ProfileSettingsItem
            title={t("tms.titles.Password")}
            text={t("tms.desc.Use a strong password")}
            button={
              <DTextButton
                title={t("tms.forms.buttons.Change password")}
                onClick={() => setOpenChangePasswordModal(true)}
                info
              />
            }
          />
          <ProfileSettingsItem
            title={t("tms.titles.Logout account")}
            text={t("tms.desc.Sign out of your account")}
            button={
              <DTextButton
                title={t("tms.forms.buttons.Logout")}
                danger
                onClick={() => setOpenLogOutModal(true)}
              />
            }
            danger
          />
        </div>
      </div>
      {openChangePasswordModal && (
        <ChangePasswordModal
          isOpen={openChangePasswordModal}
          isClose={() => setOpenChangePasswordModal(false)}
        />
      )}
      {openLogOutModal && (
        <LogoutModal
          isOpen={openLogOutModal}
          isClose={() => setOpenLogOutModal(false)}
        />
      )}
      {openEmailEditModal && (
        <EditEmailModal
          isOpen={openEmailEditModal}
          isClose={() => setOpenEmailEditModal(false)}
        />
      )}
      {openPhoneEditModal && (
        <EditPhoneModal
          isOpen={openPhoneEditModal}
          isClose={() => setOpenPhoneEditModal(false)}
        />
      )}
    </Fragment>
  );
};

export default ProfileSettings;
