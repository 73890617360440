import React, { Fragment } from "react";

import { useTranslation } from "react-i18next";

import TableWrapper from "../../../Form/TableFields/TableWrapper/TableWrapper";
import TableHeader from "../../../Form/TableFields/TableHeader/TableHeader";
import ActiveOrdersTableItem from "./ActiveOrdersTableItem/ActiveOrdersTableItem";
import LoadingPage from "../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import ToBookingsButton from "./../Components/ToBookingsButton/ToBookingsButton";

const ActiveOrdersTable = ({ orders, ordersLoading, ordersMeta }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      {ordersLoading && <LoadingPage />}
      {orders && (
        <Fragment>
          <TableWrapper
            emptyText={t("tms.tables.noInfo.No orders")}
            total={ordersMeta.total}
          >
            <TableHeader>
              <th>{t("tms.tables.titles.Order number")}</th>
              <th>{t("tms.tables.titles.Status")}</th>
              <th>{t("tms.tables.titles.Customer")}</th>
              <th>{t("tms.tables.titles.Route")}</th>
              <th>{t("tms.tables.titles.Driver")}</th>
              <th>{t("tms.tables.titles.Trailer need")}</th>
              <th>{t("tms.tables.titles.Pick up date")}</th>
              <th>{t("tms.tables.titles.Price")}</th>
            </TableHeader>
            <tbody>
              {orders.slice(0, 5).map((order) => (
                <ActiveOrdersTableItem key={order.id} order={order} />
              ))}
            </tbody>
          </TableWrapper>

          <ToBookingsButton />
        </Fragment>
      )}
    </Fragment>
  );
};

export default ActiveOrdersTable;
