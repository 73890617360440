import React, { Fragment, useState } from "react";
import "./ShipperOrderListHeader.scss";

import moment from "moment";
import { FiPlus } from "react-icons/fi";
import { useTranslation } from "react-i18next";

import DSearch from "../../FilterForms/DSearch/DSearch";
import FiltersContainer from "../../FilterForms/FiltersContainer/FiltersContainer";
import FilterDateRangeForm from "../../FilterForms/FilterDateRangeForm/FilterDateRangeForm";
import FilterRouteSelectForm from "../../FilterForms/FilterRouteSelectForm/FilterRouteSelectForm";
import FilterTrailerSelect from "../../FilterForms/FilterTrailerSelect/FilterTrailerSelect";
import FilterShippingType from "../../FilterForms/FilterShippingType/FilterShippingType";
import FilterProductCategorySelect from "../../FilterForms/FilterProductCategorySelect/FilterProductCategorySelect";
import DButton from "../../Form/DButtons/DButton/DButton";
import BookModal from "../../ShipperHeader/Components/BookShipment/BookModal/BookModal";

const ShipperOrderListHeader = ({
  keyword,
  setKeyword,
  shippingType,
  setShippingType,
  dateRange,
  setDateRange,
  route,
  setRoute,
  trailerType,
  setTrailerType,
  productCategory,
  setProductCategory,
  onClear,
}) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { t } = useTranslation();

  return (
    <Fragment>
      <div id='ShipperOrderListHeader'>
        <div className='searchContainer'>
          <DSearch value={keyword} setValue={setKeyword} />
          <DButton
            title={t("shipper.buttons.New request")}
            rightIcon={<FiPlus />}
            onClick={() => setShowCreateModal(true)}
          />
        </div>
        <FiltersContainer onClear={onClear}>
          <FilterDateRangeForm
            label={t("tms.filters.placeholders.Date range")}
            value={
              Object.keys(dateRange).length > 0
                ? `${
                    dateRange.startDate
                      ? moment(dateRange.startDate).format("DD/MM/YYYY")
                      : ""
                  } - ${
                    dateRange.endDate
                      ? moment(dateRange.endDate).format("DD/MM/YYYY")
                      : ""
                  }`
                : ""
            }
            setValue={setDateRange}
          />
          <FilterRouteSelectForm
            value={
              Object.keys(route).length > 0
                ? `${route?.fromCountry?.value?.code || ""} - ${
                    route?.toCountry?.value?.code || ""
                  }`
                : ""
            }
            setValue={setRoute}
          />
          <FilterProductCategorySelect
            value={productCategory}
            setValue={setProductCategory}
          />
          <FilterTrailerSelect value={trailerType} setValue={setTrailerType} />
        </FiltersContainer>
        <FilterShippingType
          shippingType={shippingType}
          setShippingType={setShippingType}
        />
      </div>
      {showCreateModal && (
        <BookModal
          isOpen={showCreateModal}
          isClose={() => setShowCreateModal(false)}
        />
      )}
    </Fragment>
  );
};

export default ShipperOrderListHeader;
