import React, { useEffect, useRef } from "react";
import "./ProfilePicture.scss";

import { useDispatch, useSelector } from "react-redux";

import {
  resetUploadProfileAvatar,
  uploadProfileAvatar,
} from "../../../../store/user/fileUpload/uploadSlice";
import { useUploadErrors } from "../../../../validations/upload/useUploadErrors";

import { MdModeEditOutline } from "react-icons/md";
import DErrorText from "../../../Form/DErrorText/DErrorText";

const ProfilePicture = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { uploadProfileAvatarResponce } = useSelector((state) => state.upload);
  const { upload_profile_avatar_error, upload_profile_avatar_network_error } =
    useUploadErrors();

  const fileUploadButtonRef = useRef(null);

  const handleProfileAvatarSelect = (e) => {
    const data = {
      model_id: user?.id,
      file: e?.target?.files[0],
    };

    dispatch(uploadProfileAvatar(data));
  };

  useEffect(() => {
    if (uploadProfileAvatarResponce) {
      dispatch(resetUploadProfileAvatar());
    }
  }, [dispatch, uploadProfileAvatarResponce]);

  return (
    <div id='profilePicture'>
      <div
        className='profilePictureAvatar'
        style={{ backgroundImage: `url(${user?.avatar})` }}
      >
        <div
          className='editPictureAvatar'
          onClick={() => fileUploadButtonRef.current.click()}
        >
          <input
            type='file'
            onChange={handleProfileAvatarSelect}
            accept='image/*'
            ref={fileUploadButtonRef}
          />
          <div className='icon'>
            <MdModeEditOutline />
          </div>
        </div>
      </div>
      <div className='uploadError'>
        <DErrorText
          error={
            upload_profile_avatar_error ||
            (upload_profile_avatar_network_error && "Reduce file size")
          }
        />
      </div>
    </div>
  );
};

export default ProfilePicture;
