import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import manageOrderService from "./manageOrderService";

const initialState = {
  assignCarrierLoading: false,
  assignCarrierResponce: null,
  assignCarrierError: null,

  unassignCarrierLoading: false,
  unassignCarrierResponce: null,
  unassignCarrierError: null,
};

export const assignCarrier = createAsyncThunk(
  "manageOrder/assignCarrier",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await manageOrderService.assignCarrier(
        ISO,
        TOKEN,
        data && data.orderId,
        data
      );
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const unassignCarrier = createAsyncThunk(
  "manageOrder/unassignCarrier",
  async (orderId, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await manageOrderService.unassignCarrier(ISO, TOKEN, orderId);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const manageOrderSlice = createSlice({
  name: "manageOrder",
  initialState,
  reducers: {
    resetAssignCarrier: (state) => {
      state.assignCarrierLoading = false;
      state.assignCarrierResponce = null;
      state.assignCarrierError = null;
    },
    resetUnassignCarrier: (state) => {
      state.unassignCarrierLoading = false;
      state.unassignCarrierResponce = null;
      state.unassignCarrierError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(assignCarrier.pending, (state) => {
        state.assignCarrierLoading = true;
        state.assignCarrierResponce = null;
        state.assignCarrierError = null;
      })
      .addCase(assignCarrier.fulfilled, (state, action) => {
        state.assignCarrierLoading = false;
        state.assignCarrierResponce = action.payload;
        state.assignCarrierError = null;
      })
      .addCase(assignCarrier.rejected, (state, action) => {
        state.assignCarrierLoading = false;
        state.assignCarrierResponce = null;
        state.assignCarrierError = action.payload;
      })

      .addCase(unassignCarrier.pending, (state) => {
        state.unassignCarrierLoading = true;
        state.unassignCarrierResponce = null;
        state.unassignCarrierError = null;
      })
      .addCase(unassignCarrier.fulfilled, (state, action) => {
        state.unassignCarrierLoading = false;
        state.unassignCarrierResponce = action.payload;
        state.unassignCarrierError = null;
      })
      .addCase(unassignCarrier.rejected, (state, action) => {
        state.unassignCarrierLoading = false;
        state.unassignCarrierResponce = null;
        state.unassignCarrierError = action.payload;
      });
  },
});

export const { resetAssignCarrier, resetUnassignCarrier } =
  manageOrderSlice.actions;
export default manageOrderSlice.reducer;
