import React, { useEffect } from "react";
import "./OrderDetails.scss";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  getOrder,
  resetGetOrder,
} from "../../../../../store/orders/shipperOrders/shipperOrdersSlice";

import Wrapper from "../../../../../componentsAdditional/Wrapper";
import ShipperOrdersDetailsHeader from "../../../../../componentsDashboard/Orders/ShipperOrdersDetailsHeader/ShipperOrdersDetailsHeader";
import LoadingPage from "../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import PageNotFound from "../../../../../componentsBids/PageNotFound/PageNotFound";
import OrderDetailsWrapper from "../../../../../componentsDashboard/Orders/OrderDetailsWrapper/OrderDetailsWrapper";
import ShipperOrderDetailsOffer from "./../../../../../componentsDashboard/Orders/ShipperOffers/ShipperOrderDetailsOffer/ShipperOrderDetailsOffer";

const OrderDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orderId } = useParams();

  const { orderLoading, orderResponce, orderError, rejectOrderResponce } =
    useSelector((state) => state.shipperOrders);

  useEffect(() => {
    dispatch(getOrder(orderId));
    return () => dispatch(resetGetOrder());
  }, [dispatch, orderId, rejectOrderResponce]);

  return (
    <Wrapper documentTitle={t("shipper.titles.Order Details")}>
      {orderLoading && <LoadingPage />}
      {orderError && <PageNotFound />}
      {orderResponce && !orderLoading && (
        <section id='OrderDetails'>
          <ShipperOrdersDetailsHeader order={orderResponce} />
          <ShipperOrderDetailsOffer order={orderResponce} />
          <OrderDetailsWrapper opened order={orderResponce} />
        </section>
      )}
    </Wrapper>
  );
};

export default OrderDetails;
