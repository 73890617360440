import React, { Fragment, useState } from "react";
import "./TrailerManagementItem.scss";

import { useTranslation } from "react-i18next";

import { FiMinus } from "react-icons/fi";
import { useSelector } from "react-redux";
import { MdControlPointDuplicate } from "react-icons/md";

import DSelect from "../../../../../../Form/DSelect/DSelect";
import DInputs from "../../../../../../Form/DInputs/DInputs";
import DTextButton from "../../../../../../Form/DButtons/DTextButton/DTextButton";
import DModal from "../../../../../../Modal/DModal/DModal";
import DCurrencyInput from "./../../../../../../Form/DCurrencyInput/DCurrencyInput";

const TrailerManagementItem = ({
  edit,
  index,
  trailer,
  trailers,
  setTrailers,
  orderType,
  trailer_type_id_error,
  trailer_size_id_error,
  cargos_weight_error,
}) => {
  const { t } = useTranslation();

  const {
    trailerTypesLoading,
    trailerTypesSelect,
    temperaturesLoading,
    temperaturesSelect,
  } = useSelector((state) => state.orderSettings);

  const [showDublicateModal, setShowDublicateModal] = useState(false);
  const [dublicateCount, setDublicateCount] = useState(1);

  const dublicateHandler = () => {
    const newItem = {
      trailerType: trailer.trailerType,
      trailerSize: trailer.trailerSize,
      temperatureRange: trailer.temperatureRange,
      weight: trailer.weight,
      price: trailer.price,
      currency: trailer.currency,
    };

    const copyTrailers = [...trailers];
    for (let i = 0; i < dublicateCount; i++) {
      copyTrailers.splice(index + i + 1, 0, { ...newItem }); 
    }
    setTrailers(copyTrailers);
    setShowDublicateModal(false);
    setDublicateCount(1);
  };

  const changeDublicateHandler = (val) => {
    const inputValue = val;
    if (
      inputValue === "" ||
      (parseInt(inputValue) + parseInt(trailers.length) <= 40 &&
        parseInt(inputValue) >= 0)
    ) {
      setDublicateCount(inputValue);
    }
  };

  const deleteHandler = () => {
    const newTrailers = [...trailers];
    newTrailers.splice(index, 1);
    setTrailers(newTrailers);
  };

  const setTrailerTypeHandler = (value) => {
    const newTrailers = [...trailers];
    newTrailers[index].trailerType = value;
    newTrailers[index].trailerSize = null;
    newTrailers[index].temperatureRange = null;
    setTrailers(newTrailers);
  };

  const setTrailerSizeHandler = (value) => {
    const newTrailers = [...trailers];
    newTrailers[index].trailerSize = value;
    newTrailers[index].temperatureRange = null;
    setTrailers(newTrailers);
  };

  const setTemperatureRangeHandler = (value) => {
    const newTrailers = [...trailers];
    newTrailers[index].temperatureRange = value;
    setTrailers(newTrailers);
  };

  const setWeightHandler = (value) => {
    const inputdata = [...trailers];
    inputdata[index].weight = value;
    setTrailers(inputdata);
  };

  const setPriceHandler = (value) => {
    const inputdata = [...trailers];
    inputdata[index].price = value;
    setTrailers(inputdata);
  };

  const seCurrencyHandler = (value) => {
    const inputdata = [...trailers];
    inputdata[index].currency = value;
    setTrailers(inputdata);
  };

  const trailerSizeOptions = trailer?.trailerType?.value?.sizes?.map((size) => {
    return {
      label: size.label,
      value: size,
    };
  });

  return (
    <Fragment>
      <div
        className={
          edit ? "TrailerManagementItem edit" : "TrailerManagementItem"
        }
      >
        {!edit && (
          <div className='id'>
            <h1>{index + 1}</h1>
          </div>
        )}
        <div className='trailerType'>
          <DSelect
            required
            value={trailer.trailerType}
            setValue={(value) => setTrailerTypeHandler(value)}
            id={`AddOrdertrailerSelect${index}`}
            label={t("tms.forms.labels.Trailer")}
            placeholder={t("tms.forms.placeholder.Choose trailer type")}
            options={trailerTypesSelect}
            loading={trailerTypesLoading}
            error={trailer_type_id_error}
          />
        </div>
        <div className='trailerVolume'>
          <DSelect
            required
            value={trailer.trailerSize}
            setValue={(value) => setTrailerSizeHandler(value)}
            id={`AddOrderTrailerVolumeSelect${index}`}
            loading={false}
            label={t("tms.forms.labels.Trailer volume")}
            placeholder={t("tms.forms.placeholder.Choose trailer volume")}
            options={trailerSizeOptions || []}
            error={trailer_size_id_error}
            disabled={!trailer.trailerType}
          />
        </div>
        {trailer &&
          trailer.trailerType &&
          trailer.trailerType.value.temperature && (
            <div className='temperature'>
              <DSelect
                value={trailer.temperatureRange}
                setValue={(value) => setTemperatureRangeHandler(value)}
                id={`AddOrderTrailertemperatureSelect${index}`}
                loading={temperaturesLoading}
                label={t("tms.forms.labels.Temperature")}
                placeholder={t("tms.forms.placeholder.Temperature")}
                options={temperaturesSelect}
                error={null}
              />
            </div>
          )}
        <div className='weight'>
          <DInputs
            type='number'
            required
            value={trailer.weight}
            setValue={(value) => setWeightHandler(value)}
            id={`AddOrderTrailerSelect${index}`}
            label={t("tms.forms.labels.Weight(KG)")}
            placeholder={t("tms.forms.placeholder.Enter weight")}
            error={cargos_weight_error}
          />
        </div>
        <div className='priceForCustomerInput'>
          <DCurrencyInput
            id='AddOrderTotalpricefororder'
            label={t("tms.forms.labels.Price for customer")}
            placeholder={t("tms.forms.placeholder.Enter price")}
            value={trailer.price}
            setValue={(value) => setPriceHandler(value)}
            currencyValue={trailer.currency}
            setCurrencyValue={(value) => seCurrencyHandler(value)}
            error={null}
          />
        </div>
        {!edit && (
          <div className={trailers.length > 1 ? "action" : "action single"}>
            <DTextButton
              title={t("tms.forms.buttons.Duplicate")}
              onClick={() => {
                setShowDublicateModal(true);
              }}
              leftIcon={<MdControlPointDuplicate />}
            />
            {trailers.length > 1 && (
              <DTextButton
                danger
                title={t("tms.forms.buttons.Remove")}
                onClick={() => {
                  deleteHandler();
                }}
                leftIcon={<FiMinus />}
              />
            )}
          </div>
        )}
      </div>
      {showDublicateModal && (
        <DModal
          title={t("tms.modals.titles.Enter quantity")}
          onClick={() => dublicateHandler()}
          submitText={t("tms.modals.buttons.Duplicate")}
          isOpen={showDublicateModal}
          isClose={() => setShowDublicateModal(false)}
          icon={<MdControlPointDuplicate />}
          disabled={40 - parseInt(trailers.length) === 0 ? true : false}
        >
          <div id='dublicateCountquantityContainer'>
            <div className='dublicateCountquantityContainerForm'>
              <DInputs
                label={`${t("tms.modals.labels.Quantity")} (${t(
                  "tms.modals.labels.Max"
                )} ${40 - parseInt(trailers.length)})`}
                id='dublicateCountquantity'
                value={dublicateCount}
                setValue={(state) => changeDublicateHandler(state)}
                placeholder={t("tms.modals.placeholder.Enter quantity")}
                type='number'
              />
            </div>
          </div>
        </DModal>
      )}
    </Fragment>
  );
};

export default TrailerManagementItem;
