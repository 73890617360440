import _request from "./../../_request";

const getParticipantContacts = async (ISO, TOKEN, participantId) => {
  const config = {
    url: `participants/${participantId}/contacts`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const createParticipantContact = async (ISO, TOKEN, participantId, data) => {
  const config = {
    url: `participants/${participantId}/contacts`,
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const updateParticipantContact = async (
  ISO,
  TOKEN,
  participantId,
  contactId,
  data
) => {
  const config = {
    url: `participants/${participantId}/contacts/${contactId}`,
    method: "put",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const deleteParticipantContact = async (
  ISO,
  TOKEN,
  participantId,
  contactId
) => {
  const config = {
    url: `participants/${participantId}/contacts/${contactId}`,
    method: "delete",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const participantContactsService = {
  getParticipantContacts,
  createParticipantContact,
  updateParticipantContact,
  deleteParticipantContact,
};

export default participantContactsService;
