import { useSelector } from "react-redux";

export const useSubscribeErrors = () => {
  const { subscribeStep1Error, subscribeStep2Error, subscribeStep3Error } = useSelector(
    (state) => state.subscribe
  );

  //    Step 1
  const fleet_owner_error = subscribeStep1Error?.errors?.fleet_owner?.[0];
  const fleet_quantity_error = subscribeStep1Error?.errors?.fleet_quantity?.[0];
  const trailer_types_error = subscribeStep1Error?.errors?.trailer_types?.[0];
  const routes_error = subscribeStep1Error?.errors?.routes?.[0];

  //    Step 2
  const registration_country_error = subscribeStep2Error?.errors?.registration_country?.[0];
  const company_name_error = subscribeStep2Error?.errors?.company_name?.[0];
  const company_code_error = subscribeStep2Error?.errors?.company_code?.[0];

  //    Step 3
  const fullname_error = subscribeStep3Error?.errors?.fullname?.[0];
  const phone_error = subscribeStep3Error?.errors?.phone?.[0];
  const phone_index_error = subscribeStep3Error?.errors?.phone_index?.[0];
  const email_error = subscribeStep3Error?.errors?.email?.[0];
  const contact_channel_error = subscribeStep3Error?.errors?.contact_channel?.[0];

  //    Step 4

  return {
    fleet_owner_error,
    fleet_quantity_error,
    trailer_types_error,
    routes_error,

    registration_country_error,
    company_name_error,
    company_code_error,

    fullname_error,
    phone_error,
    phone_index_error,
    email_error,
    contact_channel_error,
  };
};
