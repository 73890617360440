import _request from "../../_request";

const createContact = async (ISO, TOKEN, data) => {
  const config = {
    url: "/user/contact",
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const updateContact = async (ISO, TOKEN, id, data) => {
  const config = {
    url: `/user/contact/${id}`,
    method: "put",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const deleteContact = async (ISO, TOKEN, id) => {
  const config = {
    url: `/user/contact/${id}`,
    method: "delete",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const userContactsService = {
  createContact,
  updateContact,
  deleteContact,
};

export default userContactsService;
