import React from "react";
import "./PaymentDeadlineTimeField.scss";

import { useTranslation } from "react-i18next";

const PaymentDeadlineTimeField = ({ status }) => {
  const { t } = useTranslation();
  return (
    <td id='PaymentDeadlineTimeField'>
      <div className='container'>
        {status ? (
          <p style={{ color: status.color }}>
            {status.days} {t("shipper.units.days")}
          </p>
        ) : (
          <p>{t("shipper.table.desc.No deadline")}</p>
        )}
      </div>
    </td>
  );
};

export default PaymentDeadlineTimeField;
