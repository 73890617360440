import _request from "./../_request";

const getGeneralAnalytics = async (ISO, token, date) => {
  const config = {
    url: `/analytics/general?date=${date}`,
    ISO,
    token,
  };
  return _request(config);
};

const getChartAnalytics = async (ISO, token, date) => {
  const config = {
    url: `/analytics/chart?date=${date}`,
    ISO,
    token,
  };
  return _request(config);
};

const analyticsService = {
  getGeneralAnalytics,
  getChartAnalytics,
};

export default analyticsService;
