import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { FiTrash2 } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";

import {
  deleteParticipantContact,
  resetDeleteParticipantContact,
} from "../../../../store/participants/participantContacts/participantContactsSlice";

import DModal from "../../../Modal/DModal/DModal";

const DeleteParticipantContactModal = ({
  participantId,
  contactId,
  isOpen,
  isClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { deleteParticipantContactLoading, deleteParticipantContactResponce } =
    useSelector((state) => state.participantContacts);

  const submitHandler = () => {
    dispatch(deleteParticipantContact({ participantId, contactId }));
  };

  useEffect(() => {
    if (deleteParticipantContactResponce) {
      dispatch(resetDeleteParticipantContact());
      isClose();
    }
  }, [dispatch, isClose, deleteParticipantContactResponce]);

  return (
    <DModal
      isOpen={isOpen}
      isClose={isClose}
      onClick={() => submitHandler()}
      submitText={t("tms.modals.buttons.Delete")}
      title={t("tms.modals.titles.Delete contact")}
      icon={<FiTrash2 />}
      status='danger'
      loading={deleteParticipantContactLoading}
    >
      <p>{t("tms.modals.desc.Are you sure you want to delete contact?")}</p>
    </DModal>
  );
};

export default DeleteParticipantContactModal;
