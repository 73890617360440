import { useSelector } from "react-redux";

export const useUploadErrors = () => {
  const { uploadCompanyLogoError, uploadProfileAvatarError } = useSelector(
    (state) => state.upload
  );

  const upload_company_logo_error = uploadCompanyLogoError?.errors?.file?.[0];

  const upload_company_logo_network_error =
    uploadCompanyLogoError && uploadCompanyLogoError === "Network Error";

  const upload_profile_avatar_error =
    uploadProfileAvatarError?.errors?.file?.[0];

  const upload_profile_avatar_network_error =
    uploadProfileAvatarError && uploadProfileAvatarError === "Network Error";

  return {
    upload_company_logo_error,
    upload_company_logo_network_error,
    upload_profile_avatar_error,
    upload_profile_avatar_network_error,
  };
};
