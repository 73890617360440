import React from "react";
import "./DQuantitySelect.scss";

import { FiPlus, FiMinus } from "react-icons/fi";

import DLabel from "./../../DLabel/DLabel";
import DErrorText from "./../../DErrorText/DErrorText";

const DQuantitySelect = ({
  value,
  setValue,
  error,
  label,
  required,
  disabled,
}) => {
  const incrementValue = () => {
    if (value < 100 && !disabled) {
      setValue(value + 1);
    }
  };

  const decrementValue = () => {
    if (value > 1 && !disabled) {
      setValue(value - 1);
    }
  };

  return (
    <div
      id='DQuantitySelectNumber'
      className={`${error ? "error" : ""} ${disabled ? "disabled" : ""}`}
    >
      {label && <DLabel label={label} required={required} />}
      <div className='GroupForm'>
        <div
          className={value === 1 ? "disable button" : "button"}
          onClick={decrementValue}
        >
          <FiMinus />
        </div>
        <div className='number'>
          <p>{value}</p>
        </div>
        <div
          className={value > 99 ? "disable button" : "button"}
          onClick={incrementValue}
        >
          <FiPlus />
        </div>
      </div>
      {error && <DErrorText error={error} />}
    </div>
  );
};

export default DQuantitySelect;
