import React, { useState, useEffect } from "react";
import "./AddAddressModal.scss";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { IoLocationOutline } from "react-icons/io5";

import {
  updateUser,
  resetUpdateUser,
} from "../../../../../store/user/userSlice";

import DModal from "../../../../Modal/DModal/DModal";
import DInputs from "../../../../Form/DInputs/DInputs";

const AddAddressModal = ({ isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { updateUserLoading, updateUserResponce } = useSelector(
    (state) => state.user
  );
  const [address, setAddress] = useState(user?.company?.address || "");

  const submitHandler = () => {
    const data = {
      company: {
        address: address,
      },
    };
    dispatch(updateUser(data));
  };

  useEffect(() => {
    if (updateUserResponce) {
      dispatch(resetUpdateUser());
      isClose();
    }
  }, [dispatch, isClose, updateUserResponce]);

  return (
    <DModal
      isOpen={isOpen}
      isClose={isClose}
      title={t("tms.modals.titles.Add address")}
      icon={<IoLocationOutline />}
      submitText={t("tms.modals.buttons.Save")}
      loading={updateUserLoading}
      onClick={submitHandler}
      status='info'
    >
      <div id='AddAddressModal'>
        <div className='fromGroup'>
          <DInputs
            id='companyAddressUserProfile'
            value={address}
            setValue={setAddress}
            label={t("tms.modals.labels.Company address")}
            placeholder={t("tms.modals.placeholder.Enter company address")}
          />
        </div>
      </div>
    </DModal>
  );
};

export default AddAddressModal;
