import React, { useState } from "react";
import "./Subscribe.scss";

import { useTranslation } from "react-i18next";

import Input from "./../../../Form/Inputs/Input";
import Button from "./../../../Form/Button/Button";
import FooterTitle from "./../FooterTitle/FooterTitle";
import ErrorText from "../../../Form/ErrorText/ErrorText";

const Subscribe = () => {
  const { t } = useTranslation();

  const [value, setValue] = useState("");
  const [open, setOpen] = useState(true);

  const onClickHandler = () => {
    setValue("");
  };

  return (
    <div id='Subscribe' className={open ? "open" : ""}>
      <FooterTitle
        title={t("landing.navigation.Stay up to date")}
        value={open}
        setValue={() => setOpen((state) => !state)}
      />

      <div className='list'>
        <div>
          <Input
            placeholder={t("landing.form.placeholder.Enter your email")}
            value={value}
            setValue={setValue}
            error={null}
          />
          <ErrorText error={null} />
        </div>

        <Button
          title={t("landing.form.button.Subscribe")}
          onClick={onClickHandler}
        />
      </div>
    </div>
  );
};

export default Subscribe;
