import React, { Fragment, useState, useEffect } from "react";
import "./ShipperOrdersDetailsHeader.scss";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FiEdit2, FiTrash2, FiChevronLeft } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";

import {
  deleteOrder,
  resetDeleteOrder,
} from "./../../../store/orders/shipperOrders/shipperOrdersSlice";

import DTextButton from "../../Form/DButtons/DTextButton/DTextButton";
import DModal from "./../../Modal/DModal/DModal";

const ShipperOrdersDetailsHeader = ({ order }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { deleteLoading, deleteResponce } = useSelector(
    (state) => state.shipperOrders
  );

  const typeMapping = {
    1: { url: "road" },
    2: { url: "road" },
    3: { url: "ocean" },
    4: { url: "ocean" },
    5: { url: "air" },
    6: { url: "rail" },
    7: { url: "rail" },
  };

  const { url } = typeMapping[order?.type?.id || "road"];

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    if (deleteResponce) {
      dispatch(resetDeleteOrder());
      setOpenDeleteModal(false);
      navigate("/dashboard/shipper/orders?page=1");
    }
  }, [dispatch, navigate, deleteResponce]);

  const editHandler = () => {
    navigate(`/dashboard/shipper/orders/edit/${url}/${order.id}`);
  };

  const deleteHandler = () => {
    dispatch(deleteOrder(order.id));
  };

  return (
    <Fragment>
      <div id='ShipperOrdersDetailsHeader'>
        <div className='left'>
          <div className='goShipment' onClick={() => navigate(-1)}>
            <div className='icon'>
              <FiChevronLeft />
            </div>
            <h6>{t("shipper.titles.Booking view")}</h6>
          </div>
          <div className='tracking'>
            <div className='line'></div>
            <h6>{order?.tracking}</h6>
          </div>
        </div>
        <div className='right'>
          <DTextButton
            title={t("shipper.buttons.Edit")}
            leftIcon={<FiEdit2 />}
            onClick={() => editHandler()}
          />
          <DTextButton
            danger
            title={t("shipper.buttons.Delete")}
            onClick={() => {
              setOpenDeleteModal(true);
            }}
            leftIcon={<FiTrash2 />}
          />
        </div>
      </div>
      <DModal
        isOpen={openDeleteModal}
        isClose={() => setOpenDeleteModal(false)}
        onClick={() => deleteHandler()}
        title={t("shipper.modal.Delete order")}
        submitText={t("shipper.buttons.Delete")}
        icon={<FiTrash2 />}
        status='danger'
        loading={deleteLoading}
      >
        <p>{t("shipper.modal.Are you sure you want to delete your order?")}</p>
      </DModal>
    </Fragment>
  );
};

export default ShipperOrdersDetailsHeader;
