import React, { Fragment, useState, useEffect } from "react";
import "./CustomerDetails.scss";

import { useTranslation } from "react-i18next";
import { FiEdit2, FiTrash2 } from "react-icons/fi";

import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  getCustomer,
  resetGetCustomer,
} from "../../../../../../store/participants/customer/customerSlice";

import { useMoney } from "../../../../../../helper/useMoney";

import Wrapper from "./../../../../../../componentsAdditional/Wrapper";
import DTextButton from "../../../../../../componentsDashboard/Form/DButtons/DTextButton/DTextButton";
import SmallDescription from "../../../../../../componentsDashboard/CustomersPartners/Components/SmallDescription/SmallDescription";
import StatisticCard from "../../../../../../componentsDashboard/CustomersPartners/Components/StatisticCard/StatisticCard";
import ContactTable from "../../../../../../componentsDashboard/CustomersPartners/Contacts/ContactTable/ContactTable";
import CustomerOrdersTable from "../../../../../../componentsDashboard/CustomersPartners/Customers/CustomerOrdersTable/CustomerOrdersTable";
import DBackButton from "../../../../../../componentsDashboard/Form/DButtons/DBackButton/DBackButton";
import DeleteCustomerModal from "../../../../../../componentsDashboard/CustomersPartners/Customers/DeleteCustomerModal/DeleteCustomerModal";
import EditCustomerModal from "../../../../../../componentsDashboard/CustomersPartners/Customers/EditCustomerModal/EditCustomerModal";
import PageNotFound from "./../../../../../../componentsBids/PageNotFound/PageNotFound";

const CustomerDetails = () => {
  const { t } = useTranslation();
  const { customerId } = useParams();
  const dispatch = useDispatch();
  const {
    getCustomerLoading,
    getCustomerResponce,
    getCustomerError,
    updateCustomerResponce,
  } = useSelector((state) => state.customer);
  const { user } = useSelector((state) => state.auth);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  useEffect(() => {
    dispatch(getCustomer(customerId));
    return () => {
      dispatch(resetGetCustomer());
    };
  }, [dispatch, customerId, updateCustomerResponce]);

  const formattedMoneyTotal = useMoney(
    getCustomerResponce?.analytics.total_revenue || 0,
    user?.currency?.symbol || "-",
    0
  );

  const formattedMoneyCurrentMonth = useMoney(
    getCustomerResponce?.analytics.current_month_revenue || 0,
    user?.currency?.symbol || "-",
    0
  );
  return (
    <Wrapper documentTitle={t("tms.documentTitles.Customer details")}>
      {getCustomerError && <PageNotFound />}
      {getCustomerResponce && !getCustomerLoading && (
        <Fragment>
          <section id='CustomerDetails'>
            <div className='header'>
              <DBackButton alt='/dashboard/tms/customers_partners/customers?page=1' />
              <div className='actions'>
                <DTextButton
                  title={t("tms.forms.buttons.edit")}
                  onClick={() => setOpenEditModal(true)}
                  leftIcon={<FiEdit2 />}
                />
                <DTextButton
                  danger
                  title={t("tms.forms.buttons.delete")}
                  onClick={() => setOpenDeleteModal(true)}
                  leftIcon={<FiTrash2 />}
                />
              </div>
            </div>
            <div className='participantInfo'>
              <SmallDescription participant={getCustomerResponce} />

              <StatisticCard
                number={getCustomerResponce.analytics.total_number_of_orders}
                title={t("tms.titles.Total shipment")}
                thisMonth={
                  getCustomerResponce.analytics.current_month_number_of_orders
                }
                text={`${
                  getCustomerResponce.analytics
                    .orders_increase_percent_from_last_month || 0
                }%`}
              />
              <StatisticCard
                number={formattedMoneyTotal}
                title={t("tms.titles.Total revenue")}
                thisMonth={formattedMoneyCurrentMonth}
                text={`${
                  getCustomerResponce.analytics
                    .revenue_increase_percent_from_lastMonth || 0
                }%`}
                active
              />
            </div>
            <ContactTable participantId={customerId} />
            <CustomerOrdersTable customer={getCustomerResponce} />
          </section>

          <DeleteCustomerModal
            participantId={customerId}
            isOpen={openDeleteModal}
            isClose={() => setOpenDeleteModal(false)}
          />
          <EditCustomerModal
            participant={getCustomerResponce}
            isOpen={openEditModal}
            isClose={() => setOpenEditModal(false)}
          />
        </Fragment>
      )}
    </Wrapper>
  );
};

export default CustomerDetails;
