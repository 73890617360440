import React, { Fragment, useState, useEffect } from "react";
import "./ContactFreightForm.scss";

import { Link } from "react-router-dom";
import { FiMapPin, FiPhone, FiMail } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import {
  getContacts,
  // setContact,
} from "../../../../../store/additional/contact/contactSlice";

// import SelectForm from "./../../../../Form/Select/SelectForm";
import HomeTitle from "./../../../Components/HomeTitle/HomeTitle";
import TalkExpert from "./../../../Components/TalkExpert/TalkExpert";
import Button from "../../../../Form/Button/Button";

const ContactFreightForm = ({ title, description, smallTitle }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { currentOffice } = useSelector((state) => state.contact);

  useEffect(() => {
    dispatch(getContacts());
  }, [dispatch]);

  const [ofenTalkExpert, setOfenTalkExpert] = useState(false);

  return (
    <Fragment>
      <TalkExpert open={ofenTalkExpert} setOpen={setOfenTalkExpert} />

      <div id='ContactFreightForm'>
        <div
          data-aos='fade-left'
          data-aos-duration='1500'
          data-aos-delay='100'
          data-aos-once='true'
        >
          <div className='ContactFreightFormContainer'>
            <div className='title'>
              <div className='desc'>
                <div className='icon'></div>
                <h6>{smallTitle}</h6>
              </div>
              <HomeTitle description={description}>{title}</HomeTitle>
            </div>

            <div className='container'>
              {/* {contacts && currentOffice && (
                <SelectForm
                  loading={contactsLoading}
                  options={contacts}
                  value={currentOffice}
                  setValue={(data) => dispatch(setContact(data))}
                  name='ContactOffice'
                  placeholder='Offices'
                />
              )} */}
              {currentOffice && (
                <Fragment>
                  <h2>{currentOffice.value.contact_city}</h2>
                  <div className='content'>
                    <div className='left'>
                      <div className='item'>
                        <div className='icon'>
                          <FiMail />
                        </div>
                        <div>
                          <p>{t("landing.form.label.Write email")}</p>
                          <ul className='list'>
                            <li>
                              <Link
                                to={`mailto:${currentOffice.value.contact_email}`}
                              >
                                {currentOffice.value.contact_email}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className='item'>
                        <div className='icon'>
                          <FiMapPin />
                        </div>
                        <div>
                          <p>{t("landing.form.label.Visit anytime")}</p>
                          <ul className='list'>
                            <li>
                              {currentOffice?.value?.location ? (
                                <Link
                                  target='_blank'
                                  to={`https://maps.google.com/?q=${currentOffice?.value?.location?.lat},${currentOffice?.value?.location?.lng}`}
                                >
                                  {currentOffice.value.contact_address}
                                </Link>
                              ) : (
                                currentOffice.value.contact_address
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='right'>
                      <div className='item'>
                        <div className='icon'>
                          <FiPhone />
                        </div>
                        <div>
                          <p>{t("landing.form.label.Have any question?")}</p>
                          <ul className='list'>
                            {currentOffice.value.contact_phone.map(
                              (item, index) => (
                                <li key={index}>
                                  <Link to={`tel:${item}`}>{item}</Link>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fragment>
              )}
              <div className='submit'>
                <Button
                  title={t("landing.form.button.Talk to an expert")}
                  onClick={() => {
                    setOfenTalkExpert((state) => !state);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ContactFreightForm;
