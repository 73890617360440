import React from "react";
import "./ProductDetailsShipper.scss";

import { useTranslation } from "react-i18next";

import { useMoney } from "../../../../helper/useMoney";

import { BsBoxSeam } from "react-icons/bs";

import LandProductDetails from "./LandProductDetails/LandProductDetails";
import OceanProductDetails from "./OceanProductDetails/OceanProductDetails";
import AirProductDetails from "./AirProductDetails/AirProductDetails";

const ProductDetailsShipper = ({ orderResponce }) => {
  const { t } = useTranslation();

  let isLand = false;
  let isAir = false;
  let isOcean = false;
  // let isRail = false;

  switch (orderResponce?.type?.id) {
    case 1:
    case 2:
      isLand = true;
      break;
    case 3:
    case 4:
      isOcean = true;
      break;
    case 5:
      isAir = true;
      break;
    // case 6:
    // case 7:
    // isRail = true;
    // break;
    default:
      break;
  }

  const orderPrice =
    orderResponce?.cargos?.[0]?.price &&
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMoney(
      orderResponce?.cargos?.[0]?.price,
      orderResponce?.cargos?.[0]?.price_currency?.symbol
    );

  return (
    <div id='ProductDetailsShipper'>
      <div className='titleContainer'>
        <div className='leftSide'>
          <div className='icon'>
            <BsBoxSeam />
          </div>
          <h1 className='title'>{t("shipper.forms.titles.Product")}</h1>
        </div>
        <div className='rightSide'>{orderPrice}</div>
      </div>
      <div className='items'>
        {isLand && <LandProductDetails orderResponce={orderResponce} />}
        {isOcean && <OceanProductDetails orderResponce={orderResponce} />}
        {isAir &&
          orderResponce?.cargos?.map((cargo, index) => (
            <AirProductDetails
              key={index}
              cargo={cargo}
              index={orderResponce?.cargos?.length > 1 ? index + 1 : null}
            />
          ))}
        {orderResponce?.cargos?.[0]?.cargo_ready_date && (
          <div className='cargoReadyDate'>
            {t("shipper.forms.content.Cargo will be ready")}:{" "}
            <span>{orderResponce?.cargos?.[0]?.cargo_ready_date}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductDetailsShipper;
