import React, { Fragment, useState } from "react";
import "./ListViewHeader.scss";

import moment from "moment";
import { IoFilter } from "react-icons/io5";
import { useTranslation } from "react-i18next";

import DSearch from "./../../../FilterForms/DSearch/DSearch";
import FilterDateRangeForm from "./../../../FilterForms/FilterDateRangeForm/FilterDateRangeForm";
import FilterRouteSelectForm from "./../../../FilterForms/FilterRouteSelectForm/FilterRouteSelectForm";
import FilterTrailerSelect from "./../../../FilterForms/FilterTrailerSelect/FilterTrailerSelect";
import FilterShippingType from "./../../../FilterForms/FilterShippingType/FilterShippingType";
import FilterStatusesClips from "../../../FilterForms/FilterStatusesClips/FilterStatusesClips";
import DTextButton from "./../../../Form/DButtons/DTextButton/DTextButton";
import FilterProductCategorySelect from "./../../../FilterForms/FilterProductCategorySelect/FilterProductCategorySelect";
import FilterModal from "../FilterModal/FilterModal";

const ListViewHeader = ({
  keyword,
  setKeyword,
  status,
  setStatus,
  shippingType,
  setShippingType,
  dateRange,
  setDateRange,
  route,
  setRoute,
  trailerType,
  setTrailerType,
  productCategory,
  setProductCategory,
  onClear,
}) => {
  const [showFilterModal, setShowFilterModal] = useState(false);
  const { t } = useTranslation();

  return (
    <Fragment>
      <div id='ActiveOrdersListViewHeader'>
        <div className='filterKeywordContainer'>
          <DSearch value={keyword} setValue={setKeyword} />
        </div>

        <div className='filterSelectsGroup'>
          <div className='leftSide'>
            <h6 className='filterBy'>{t("shipper.filters.Filter by")}</h6>

            <div className='groups'>
              <div
                className='filterPopupBtn active'
                onClick={() => setShowFilterModal(true)}
              >
                <h6>{t("shipper.filters.Filter")}</h6>
                <div className='icon'>
                  <IoFilter />
                </div>
              </div>
              <div className='groupItem'>
                <FilterDateRangeForm
                  label={t("tms.filters.placeholders.Date range")}
                  value={
                    Object.keys(dateRange).length > 0
                      ? `${
                          dateRange.startDate
                            ? moment(dateRange.startDate).format("DD/MM/YYYY")
                            : ""
                        } - ${
                          dateRange.endDate
                            ? moment(dateRange.endDate).format("DD/MM/YYYY")
                            : ""
                        }`
                      : ""
                  }
                  setValue={setDateRange}
                />
              </div>
              <div className='groupItem'>
                <FilterRouteSelectForm
                  value={
                    Object.keys(route).length > 0
                      ? `${route?.fromCountry?.value?.code || ""} - ${
                          route?.toCountry?.value?.code || ""
                        }`
                      : ""
                  }
                  setValue={setRoute}
                />
              </div>
              <div className='groupItem'>
                <FilterProductCategorySelect
                  value={productCategory}
                  setValue={setProductCategory}
                />
              </div>
              <div className='groupItem'>
                <FilterTrailerSelect
                  value={trailerType}
                  setValue={setTrailerType}
                />
              </div>
            </div>
          </div>

          <div className='clearButton'>
            <DTextButton
              title={t("shipper.filters.Clear all")}
              onClick={onClear}
            />
          </div>
        </div>

        <div className='filterClipsContainer'>
          <div className='filterClipsContainerStatus'>
            <FilterStatusesClips status={status} setStatus={setStatus} />
          </div>
          <div className='filterClipsContainerline'></div>
          <div className='filterClipsContainertype'>
            <FilterShippingType
              shippingType={shippingType}
              setShippingType={setShippingType}
            />
          </div>
        </div>
      </div>

      {showFilterModal && (
        <FilterModal
          isOpen={showFilterModal}
          isClose={() => setShowFilterModal(false)}
          keyword={keyword}
          setKeyword={setKeyword}
          status={status}
          setStatus={setStatus}
          shippingType={shippingType}
          setShippingType={setShippingType}
          dateRange={dateRange}
          setDateRange={setDateRange}
          route={route}
          setRoute={setRoute}
          trailerType={trailerType}
          setTrailerType={setTrailerType}
          productCategory={productCategory}
          setProductCategory={setProductCategory}
          onClear={onClear}
        />
      )}
    </Fragment>
  );
};

export default ListViewHeader;
