import React, { useEffect } from "react";
import "./PrivacyPolicy.scss";

import { useSelector, useDispatch } from "react-redux";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

import { getPrivacy } from "./../../../../../store/additional/rules/rulesSlice";

import Wrapper from "./../../../../../componentsAdditional/Wrapper";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { privacy } = useSelector((state) => state.rules);

  useEffect(() => {
    dispatch(getPrivacy());
  }, [dispatch]);

  return (
    <Wrapper documentTitle={t("landing.documentTitles.Privacy policy")}>
      <section id='PrivacyPolicy'>
        <div className='wrapper'>
          {privacy && (
            <div className='content'>
              <div className='title'>
                <h1>{privacy.title && privacy.title}</h1>
              </div>
              <div className='body'>{parse(privacy.body && privacy.body)}</div>
            </div>
          )}
        </div>
      </section>
    </Wrapper>
  );
};

export default PrivacyPolicy;
