import React from "react";
import "./UpdateItem.scss";

import { useTranslation } from "react-i18next";

// import { useSelector } from "react-redux";
// import moment from "moment";
//
// import { useFromNowDate } from "../../../../../helper/useFromNowDate";

const UpdateItem = ({ status, order }) => {
  const { t } = useTranslation();
  // const { ISO } = useSelector((state) => state.lang);
  // const inputDate = order?.created_at || 0;
  const formattedDate = order?.distanceCovered?.update_date;
  return (
    <div id='ShipperTrackingSectionMessage'>
      <div className='title'>
        <h2>
          {order?.distanceCovered?.border_completed ||
            t("shipper.forms.content.No message")}
        </h2>
        <div className='status' style={{ background: order?.status?.color_2 }}>
          <h6 style={{ color: order?.status?.color_1 }}>
            {order?.status?.name}
          </h6>
        </div>
      </div>
      {order?.distanceCovered?.location && (
        <h4>
          {t("shipper.forms.content.Location")}:{" "}
          <span>{order?.distanceCovered?.location}</span>
        </h4>
      )}
      {formattedDate && <h5>{formattedDate}</h5>}
    </div>
  );
};

export default UpdateItem;
