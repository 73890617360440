import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import analyticsService from "./analyticsService";

const initialState = {
  getGeneralAnalyticsLoading: false,
  getGeneralAnalyticsResponce: null,
  getGeneralAnalyticsError: null,

  getChartAnalyticsLoading: false,
  getChartAnalyticsResponce: null,
  getChartAnalyticsError: null,
};

export const getGeneralAnalytics = createAsyncThunk(
  "analytics/getGeneralAnalytics",
  async (date, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await analyticsService.getGeneralAnalytics(ISO, TOKEN, date);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getChartAnalytics = createAsyncThunk(
  "analytics/getChartAnalytics",
  async (date, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await analyticsService.getChartAnalytics(ISO, TOKEN, date);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGeneralAnalytics.pending, (state) => {
        state.getGeneralAnalyticsLoading = true;
        state.getGeneralAnalyticsResponce = null;
        state.getGeneralAnalyticsError = null;
      })
      .addCase(getGeneralAnalytics.fulfilled, (state, action) => {
        state.getGeneralAnalyticsLoading = false;
        state.getGeneralAnalyticsResponce = action.payload;
        state.getGeneralAnalyticsError = null;
      })
      .addCase(getGeneralAnalytics.rejected, (state, action) => {
        state.getGeneralAnalyticsLoading = false;
        state.getGeneralAnalyticsResponce = null;
        state.getGeneralAnalyticsError = action.payload;
      })

      .addCase(getChartAnalytics.pending, (state) => {
        state.getChartAnalyticsLoading = true;
        state.getChartAnalyticsResponce = null;
        state.getChartAnalyticsError = null;
      })
      .addCase(getChartAnalytics.fulfilled, (state, action) => {
        state.getChartAnalyticsLoading = false;
        state.getChartAnalyticsResponce = action.payload;
        state.getChartAnalyticsError = null;
      })
      .addCase(getChartAnalytics.rejected, (state, action) => {
        state.getChartAnalyticsLoading = false;
        state.getChartAnalyticsResponce = null;
        state.getChartAnalyticsError = action.payload;
      });
  },
});

export default analyticsSlice.reducer;
