import React from "react";
import "./FilterTruckStatusSelect.scss";

import { useTranslation } from "react-i18next";

import Select from "react-select";

const FilterTruckStatusSelect = ({ value, setValue }) => {
  const { t } = useTranslation();
  const OPTIONS = [
    { value: "true", label: t("tms.filters.labels.Active") },
    { value: "false", label: t("tms.filters.labels.Inactive") },
  ];
  return (
    <div id='FilterTruckStatusSelect'>
      <Select
        classNamePrefix='select'
        name='filterTruckStatusSelect'
        className='selectComponent'
        options={OPTIONS || []}
        placeholder={t("tms.filters.placeholders.Status")}
        value={value}
        onChange={(state) => setValue(state)}
        isClearable={true}
        isSearchable={false}
        noOptionsMessage={() => (
          <div>{t("tms.filters.others.No status found")}</div>
        )}
      />
    </div>
  );
};

export default FilterTruckStatusSelect;
