import { useSelector } from "react-redux";

export const useCreateShipperRoadError = () => {
  const { createRoadError } = useSelector((state) => state.shipperOrders);

  const type_id_error =
    createRoadError &&
    createRoadError.errors &&
    createRoadError.errors.type_id &&
    createRoadError.errors.type_id[0];

  const trailer_type_id_error =
    createRoadError &&
    createRoadError.errors &&
    createRoadError.errors.trailer_type_id &&
    createRoadError.errors.trailer_type_id[0];

  const trailer_size_id_error =
    createRoadError &&
    createRoadError.errors &&
    createRoadError.errors.trailer_size_id &&
    createRoadError.errors.trailer_size_id[0];

  const temperature_range_id_error =
    createRoadError &&
    createRoadError.errors &&
    createRoadError.errors.temperature_range_id &&
    createRoadError.errors.temperature_range_id[0];

  const order_documents_error =
    createRoadError &&
    createRoadError.errors &&
    createRoadError.errors.documents &&
    createRoadError.errors.documents[0];

  const cargos_type_id_error =
    createRoadError &&
    createRoadError.errors &&
    createRoadError.errors["cargos.0.type_id"] &&
    createRoadError.errors["cargos.0.type_id"][0];

  const hs_code_error =
    createRoadError &&
    createRoadError.errors &&
    createRoadError.errors["cargos.0.hs_code_error"] &&
    createRoadError.errors["cargos.0.hs_code_error"][0];

  const cargos_weight_error =
    createRoadError &&
    createRoadError.errors &&
    createRoadError.errors["cargos.0.weight"] &&
    createRoadError.errors["cargos.0.weight"][0];

  const cargos_adr_error =
    createRoadError &&
    createRoadError.errors &&
    createRoadError.errors["cargos.0.adr"] &&
    createRoadError.errors["cargos.0.adr"][0];

  const cargos_adr_class_error =
    createRoadError &&
    createRoadError.errors &&
    createRoadError.errors["cargos.0.adr_class"] &&
    createRoadError.errors["cargos.0.adr_class"][0];

  const cargos_un_number_error =
    createRoadError &&
    createRoadError.errors &&
    createRoadError.errors["cargos.0.un_number"] &&
    createRoadError.errors["cargos.0.un_number"][0];

  const cargos_msds_file_error =
    createRoadError &&
    createRoadError.errors &&
    createRoadError.errors["cargos.0.msds_file"] &&
    createRoadError.errors["cargos.0.msds_file"][0];

  const cargos_oversized_error =
    createRoadError &&
    createRoadError.errors &&
    createRoadError.errors["cargos.0.oversized"] &&
    createRoadError.errors["cargos.0.oversized"][0];

  const cargos_oversized_file_error =
    createRoadError &&
    createRoadError.errors &&
    createRoadError.errors["cargos.0.oversized_file"] &&
    createRoadError.errors["cargos.0.oversized_file"][0];

  const cargos_oversized_call_error =
    createRoadError &&
    createRoadError.errors &&
    createRoadError.errors["cargos.0.oversized_call"] &&
    createRoadError.errors["cargos.0.oversized_call"][0];

  const cargos_unknown_container_error =
    createRoadError &&
    createRoadError.errors &&
    createRoadError.errors["cargos.0.unknown_container"] &&
    createRoadError.errors["cargos.0.unknown_container"][0];

  const cargos_pickup_date_error =
    createRoadError &&
    createRoadError.errors &&
    createRoadError.errors["cargos.0.pickup_date"] &&
    createRoadError.errors["cargos.0.pickup_date"][0];

  const cargos_price_error =
    createRoadError &&
    createRoadError.errors &&
    createRoadError.errors["cargos.0.price"] &&
    createRoadError.errors["cargos.0.price"][0];

  const cargos_price_currency_id_error =
    createRoadError &&
    createRoadError.errors &&
    createRoadError.errors["cargos.0.price_currency_id"] &&
    createRoadError.errors["cargos.0.price_currency_id"][0];

  const create_order_Network_error =
    createRoadError && createRoadError === "Network Error";

  return {
    type_id_error,
    trailer_type_id_error,
    trailer_size_id_error,
    temperature_range_id_error,
    order_documents_error,
    cargos_type_id_error,
    hs_code_error,
    cargos_weight_error,
    cargos_adr_error,
    cargos_adr_class_error,
    cargos_un_number_error,
    cargos_msds_file_error,
    cargos_oversized_error,
    cargos_oversized_file_error,
    cargos_oversized_call_error,
    cargos_unknown_container_error,
    cargos_pickup_date_error,
    cargos_price_error,
    cargos_price_currency_id_error,
    create_order_Network_error,
  };
};

export default useCreateShipperRoadError;
