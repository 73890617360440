import React, { Fragment, useState } from "react";
import "./AdditionalInformationDetails.scss";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useMoney } from "../../../helper/useMoney";

import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import { MdInsertDriveFile, MdOutlineDone } from "react-icons/md";

const AdditionalInformationDetails = ({ orderResponce }) => {
  const { t } = useTranslation();

  const [openText, setOpenText] = useState(false);
  const cargo = orderResponce?.cargos?.[0];
  const cargoPrice = useMoney(cargo?.price, cargo?.price_currency?.symbol);
  const otherDocuments = orderResponce?.documents?.filter(
    (document) => document.type?.name === "Other"
  );

  return (
    <Fragment>
      {(cargo?.about ||
        cargo?.cargo_ready_date ||
        cargo?.price ||
        otherDocuments?.length > 0 ||
        orderResponce?.services?.length > 0) && (
        <div id='AdditionalInformationDetails'>
          <div
            className='AdditionalInformationDetailsTitle'
            onClick={() => {
              setOpenText(!openText);
            }}
          >
            <div className='text'>{t("tms.titles.Additional information")}</div>
            <div className='icon'>
              {openText ? <FiChevronUp /> : <FiChevronDown />}
            </div>
          </div>

          {openText && (
            <div className='content'>
              {cargo?.cargo_ready_date && (
                <div className='item'>
                  <div className='itemTitle'>
                    {t("shipper.forms.content.Cargo ready date")}
                  </div>
                  <div className='itemValue'>{cargo?.cargo_ready_date}</div>
                </div>
              )}

              {cargo?.price && (
                <div className='item'>
                  <div className='itemTitle'>
                    {t("shipper.forms.content.Cargo price")}
                  </div>
                  <div className='itemValue'>{cargoPrice}</div>
                </div>
              )}

              {orderResponce?.services?.length > 0 && (
                <div className='item'>
                  <div className='itemTitle'>
                    {t("shipper.forms.content.Additional services")}
                  </div>
                  <div className='servicesValuesContainer'>
                    {orderResponce?.services?.map((service, index) => (
                      <div key={index} className='servicesValue'>
                        <p>{service?.name}</p>
                        <div className='icon'>
                          <MdOutlineDone />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {otherDocuments?.length > 0 && (
                <div className='item'>
                  <div className='itemTitle'>
                    {t("shipper.forms.content.Documents")}
                  </div>
                  <div className='documentsValuesContainer'>
                    {otherDocuments?.map((document, index) => (
                      <Link key={index} to={document?.url} target='_blank'>
                        <div className='documentContainer'>
                          <div className='documentContent'>
                            <div className='documentIcon'>
                              <MdInsertDriveFile />
                            </div>
                            <div className='documentTitle'>
                              {document?.fileName}
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              )}
              {cargo?.about || ""}
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default AdditionalInformationDetails;
