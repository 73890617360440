import React from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import DSelect from "../DSelect";

const DGPSProviderSelect = ({ value, setValue, error }) => {
  const { t } = useTranslation();
  const { getProvidersLoading, getProvidersResponce } = useSelector(
    (state) => state.GPSTraker
  );

  return (
    <DSelect
      id='DGPSProviderSelect'
      label={t("tms.modals.labels.GPS company")}
      value={value}
      setValue={setValue}
      placeholder={t("tms.modals.placeholder.Choose GPS company")}
      loading={getProvidersLoading}
      search={false}
      error={error}
      required
      options={getProvidersResponce ? getProvidersResponce : []}
      emptyText={t("tms.modals.others.Provider not found")}
    />
  );
};

export default DGPSProviderSelect;
