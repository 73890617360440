import { useSelector } from "react-redux";

export const useGenerateInvoiceErrors = () => {
  const { createInvoiceError } = useSelector((state) => state.orderDocuments);

  const invoice_number_error =
    createInvoiceError &&
    createInvoiceError.errors &&
    createInvoiceError.errors["invoice.number"] &&
    createInvoiceError.errors["invoice.number"][0];

  const invoice_date_error =
    createInvoiceError &&
    createInvoiceError.errors &&
    createInvoiceError.errors["invoice.date"] &&
    createInvoiceError.errors["invoice.date"][0];

  const invoice_service_error =
    createInvoiceError &&
    createInvoiceError.errors &&
    createInvoiceError.errors["invoice.service"] &&
    createInvoiceError.errors["invoice.service"][0];

  const invoice_currency_error =
    createInvoiceError &&
    createInvoiceError.errors &&
    createInvoiceError.errors["invoice.currency"] &&
    createInvoiceError.errors["invoice.currency"][0];

  const invoice_amount_error =
    createInvoiceError &&
    createInvoiceError.errors &&
    createInvoiceError.errors["invoice.amount"] &&
    createInvoiceError.errors["invoice.amount"][0];

  const shipto_error =
    createInvoiceError &&
    createInvoiceError.errors &&
    createInvoiceError.errors.shipto &&
    createInvoiceError.errors.shipto[0];

  const bankErrorsArray =
    createInvoiceError &&
    createInvoiceError.errors &&
    createInvoiceError.errors;

  return {
    invoice_number_error,
    invoice_date_error,
    invoice_service_error,
    invoice_currency_error,
    invoice_amount_error,
    shipto_error,
    bankErrorsArray,
  };
};

export default useGenerateInvoiceErrors;
