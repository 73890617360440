import React, { useState } from "react";
import "./ActivityTable.scss";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import ActivityTableSwitcher from "./ActivityTableSwitcher/ActivityTableSwitcher";
// import FeedTable from "./FeedTable/FeedTable";
import ActiveShipmentsTable from "./ActiveShipmentsTable/ActiveShipmentsTable";

const ActivityTable = ({ orders, total, loading }) => {
  const { t } = useTranslation();
  const [active, setActive] = useState("activityFeed");

  return (
    <div id='ActivityTable'>
      <div className='ActivityTableHeader'>
        <ActivityTableSwitcher
          feedBadge={total}
          shipmentsBadge={total}
          active={active}
          setActive={setActive}
        />
        <div className='SeeAllButton'>
          <Link to='/dashboard/shipper/accepted'>
            {t("shipper.buttons.See all")}
          </Link>
        </div>
      </div>
      {/* {active === "activityFeed" ? (
        <FeedTable orders={orders} />
      ) : ( */}
      <ActiveShipmentsTable orders={orders} loading={loading} />
      {/* )} */}
    </div>
  );
};

export default ActivityTable;
