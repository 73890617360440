import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import shipperFinancialService from "./shipperFinancialService";

const initialState = {
  getFinancialOrdersLoading: true,
  getFinancialOrdersResponce: null,
  getFinancialOrdersMeta: null,
  getFinancialOrdersError: null,
};

export const getFinancialOrders = createAsyncThunk(
  "shipperFinancial/getFinancialOrders",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await shipperFinancialService.getFinancialOrders(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const shipperFinancialSlice = createSlice({
  name: "shipperFinancial",
  initialState,
  reducers: {
    resetGetFinancialOrders: (state) => {
      state.getFinancialOrdersLoading = true;
      state.getFinancialOrdersResponce = null;
      state.getFinancialOrdersMeta = null;
      state.getFinancialOrdersError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFinancialOrders.pending, (state) => {
        state.getFinancialOrdersLoading = true;
        state.getFinancialOrdersResponce = null;
        state.getFinancialOrdersMeta = null;
        state.getFinancialOrdersError = null;
      })
      .addCase(getFinancialOrders.fulfilled, (state, action) => {
        state.getFinancialOrdersLoading = false;
        state.getFinancialOrdersResponce = action.payload.data;
        state.getFinancialOrdersMeta = action.payload.meta;
        state.getFinancialOrdersError = null;
      })
      .addCase(getFinancialOrders.rejected, (state, action) => {
        state.getFinancialOrdersLoading = false;
        state.getFinancialOrdersResponce = null;
        state.getFinancialOrdersMeta = null;
        state.getFinancialOrdersError = action.payload;
      });
  },
});

export const { resetGetFinancialOrders } = shipperFinancialSlice.actions;
export default shipperFinancialSlice.reducer;
