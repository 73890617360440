import axios from "axios";
import { APP_MODE } from "./../constants/APP_MODE";

const _request = async (props, data) => {
  const DATA = data ? (props.file ? data : JSON.stringify(data)) : null;

  const config = {
    baseURL:
      APP_MODE.APP_MODE === "PRODUCTION"
        ? "https://api.cargon.com/v1/"
        : "https://test-api.cargon.com/v1/",

    url: props.url,
    method: props.method ? props.method : "get",
    headers: {
      "Content-Type": props.file ? "multipart/form-data" : "application/json",
      Accept: "application/json",
      "Accept-Language": props.ISO ? props.ISO : "en",
      Authorization: props.token ? "Bearer " + props.token : null,
    },
    ...(DATA && { data: DATA }),
  };
  const response = await axios(config);
  return response.data;
};

export default _request;
