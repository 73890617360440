import React, { useState, useEffect } from "react";
import "./SignInCompanyVerifyForm.scss";

import { useTranslation } from "react-i18next";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  verifyUser,
  resendVerifyUser,
  reset,
  removeToken,
  resetRemoveToken,
} from "../../../../store/auth/authSlice";
import { useAuthError } from "../../../../validations/auth/useAuthError";

import AuthFormWrapper from "../../Components/AuthFormWrapper/AuthFormWrapper";
import FormTitle from "../../Components/FormTitle/FormTitle";
import Button from "../../../Form/Button/Button";
import SendAgain from "../../Components/SendAgain/SendAgain";
import OTP from "../../../Form/OTP/OTP";

const SignInCompanyVerifyForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const email = searchParams.get("email");
  const { code_error } = useAuthError();
  const { loading, data, error, access_token, removeTokenResponse } =
    useSelector((state) => state.auth);

  const [otp, setOtp] = useState();

  const submitHandler = () => {
    const data = {
      code: otp,
    };
    dispatch(verifyUser(data));
  };

  useEffect(() => {
    if (!error && data && access_token) {
      const url = "/login/tms/success";
      navigate(url);
      dispatch(reset());
    }
  }, [error, data, dispatch, navigate, access_token]);

  const againHandler = () => {
    dispatch(resendVerifyUser("EMAIL"));
    setOtp();
    dispatch(reset());
  };

  const handleRemoveToken = () => {
    dispatch(removeToken());
  };

  useEffect(() => {
    if (removeTokenResponse) {
      dispatch(resetRemoveToken());
      navigate("/login");
    }
  }, [removeTokenResponse, dispatch, navigate]);

  return (
    <AuthFormWrapper>
      <form id='SignInCompanyVerifyForm' className='SignInCompanyVerifyForm'>
        <FormTitle center title={t("landing.auth.titles.Verification code")}>
          {t(
            "landing.auth.titles.Please enter the verification code you received in your email"
          )}
          <span> {email}</span>
        </FormTitle>
        <OTP value={otp} setValue={setOtp} count={6} error={code_error} />
        <Button
          loading={loading}
          title={t("landing.auth.form.Verified account")}
          onClick={() => {
            submitHandler();
          }}
        />
        <SendAgain
          title={t("landing.auth.form.Send it again")}
          onClick={() => againHandler()}
          loading={true}
        />
        <div className='anotherAccountButton' onClick={handleRemoveToken}>
          {t("landing.auth.form.Try with another account")}
        </div>
      </form>
    </AuthFormWrapper>
  );
};

export default SignInCompanyVerifyForm;
