import React, { useState, useEffect, Fragment } from "react";
import "./EditOceanOrder.scss";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { FiCheck } from "react-icons/fi";

import { getTrailerTypes } from "../../../../../../store/orders/orderSettings/orderSettingsSlice";
import {
  updateOcean,
  resetUpdateOcean,
  getOrder,
  resetGetOrder,
} from "./../../../../../../store/orders/shipperOrders/shipperOrdersSlice";
import { useEditShipperOceanError } from "./../../../../../../validations/order/useEditShipperOceanError";

import Wrapper from "./../../../../../../componentsAdditional/Wrapper";
import OceanTopMenu from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperOcean/OceanTopMenu/OceanTopMenu";
import CreateOrderHeader from "../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderHeader/CreateOrderHeader";
import OceanLocation from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperOcean/OceanLocation/OceanLocation";
import CreateOrderAditionalServices from "./../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderAditionalServices/CreateOrderAditionalServices";
import CreateOrderAditionalInformation from "./../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderAditionalInformation/CreateOrderAditionalInformation";
import CreateOrderSubmit from "../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderSubmit/CreateOrderSubmit";
import OceanTypeSwitcher from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperOcean/OceanTypeSwitcher/OceanTypeSwitcher";
import OceanPackage from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperOcean/OceanPackage/OceanPackage";
import LoadingPage from "../../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import PageNotFound from "../../../../../../componentsBids/PageNotFound/PageNotFound";

const EditOceanOrder = () => {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const {
    orderLoading,
    orderResponce,
    orderError,
    updateOceanLoading,
    updateOceanResponce,
    updateOceanError,
  } = useSelector((state) => state.shipperOrders);

  const {
    cargos_comment_error,
    cargos_ready_date_error,
    cargos_price_error,
    cargos_price_currency_id_error,
    order_documents_error,
  } = useEditShipperOceanError();

  const ORDERTYPES = [
    { id: 3, name: "FCL" },
    { id: 4, name: "LCL" },
  ];

  const [group, setGroup] = useState([
    {
      cargoType: null,
      HSCode: false,
      HSCodeValue: "",
      adr: false,
      adrNumber: "",
      adrClass: "",
      oversized: false,
      unknownContainer: false,
      trailerType: null,
      trailerSize: null,
      temperatureRange: null,
      weight: "",
      quantity: 1,
      /// errors
      cargoTypeError: null,
      HSCodeValueError: null,
      adrError: null,
      adrNumberError: null,
      adrClassError: null,
      oversizedError: null,
      unknownContainerError: null,
      trailerTypeError: null,
      trailerSizeError: null,
      temperatureRangeError: null,
      weightError: null,
    },
  ]);
  const [order_type_id, set_order_type_id] = useState(3);
  const [locations, setLocations] = useState([
    {
      direction: "A",
      inputted_address: "",
      type: "PORT",
      error: null,
    },
    {
      direction: "B",
      inputted_address: "",
      type: "PORT",
      error: null,
    },
  ]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [readyCargoTime, setReadyCargoTime] = useState(null);
  const [cargoPrice, setCargoPrice] = useState("");
  const [cargoPriceCurrency, setCargoPriceCurrency] = useState(
    user
      ? {
          value: user.currency,
          label: user.currency.code,
        }
      : null
  );
  const [comment, setComment] = useState("");
  const [cargoIds, setCargoIds] = useState(null);

  // get detail order
  useEffect(() => {
    dispatch(getOrder(orderId));
    return () => {
      dispatch(resetGetOrder());
    };
  }, [dispatch, orderId]);

  // get trailer types
  useEffect(() => {
    dispatch(getTrailerTypes(3));
  }, [dispatch]);

  // edit
  useEffect(() => {
    if (orderResponce && user) {
      set_order_type_id(orderResponce?.type?.id || 3);
      setLocations([
        ...orderResponce.cargos[0].locations
          .filter((i) => i.direction === "A")
          .map((i) => ({
            id: i.id,
            direction: "A",
            type: i.type,
            inputted_address: i.formatted_address,
            error: null,
          })),
        ...orderResponce.cargos[0].locations
          .filter((i) => i.direction === "B")
          .map((i) => ({
            id: i.id,
            direction: "B",
            type: i.type,
            inputted_address: i.formatted_address,
            error: null,
          })),
      ]);
      setComment(orderResponce?.cargos[0]?.about || "");
      setCargoPrice(orderResponce?.cargos[0]?.price || "");
      setCargoPriceCurrency({
        label:
          orderResponce?.cargos[0]?.price_currency?.code || user.currency.code,
        value: orderResponce?.cargos[0]?.price_currency || user.currency,
      });
      setReadyCargoTime(
        orderResponce?.cargos[0]?.cargo_ready_date
          ? moment(
              orderResponce?.cargos[0]?.cargo_ready_date,
              "DD/MM/YYYY"
            ).toDate()
          : null
      );
      setSelectedServices(orderResponce?.services || []);
      const newData = [
        {
          cargoType: orderResponce?.cargos[0]?.type
            ? {
                value: {
                  label: orderResponce.cargos[0]?.type?.name || "",
                  id: orderResponce.cargos[0]?.type?.id || null,
                },
                label: orderResponce.cargos[0]?.type?.name || "",
              }
            : null,
          HSCode: orderResponce.cargos[0].hs_code ? true : false,
          HSCodeValue: orderResponce.cargos[0].hs_code || "",
          adr: orderResponce?.cargos[0]?.adr ? true : false,
          adrNumber: orderResponce?.cargos[0]?.un_number || "",
          adrClass: orderResponce?.cargos[0]?.adr_class || "",
          oversized: orderResponce?.cargos[0]?.oversized ? true : false,
          // unknownContainer: orderResponce?.cargos[0]?.unknownContainer
          //   ? true
          //   : false,
          trailerType: orderResponce?.cargos[0]?.trailer_type
            ? {
                label: orderResponce?.cargos[0]?.trailer_type?.name || "",
                value: {
                  label: orderResponce?.cargos[0]?.trailer_type?.name || "",
                  id: orderResponce?.cargos[0]?.trailer_type?.id || "",
                  temperature:
                    orderResponce?.cargos[0]?.trailer_type?.temperature ||
                    false,
                },
              }
            : null,
          trailerSize: orderResponce?.cargos[0]?.trailer_size
            ? {
                label: orderResponce?.cargos[0]?.trailer_size?.label || "",
                value: {
                  value: {
                    label: orderResponce?.cargos[0]?.trailer_size?.label || "",
                    id: orderResponce?.cargos[0]?.trailer_size?.id || "",
                  },
                },
              }
            : null,
          temperatureRange: orderResponce?.cargos[0]?.temperature
            ? {
                label: orderResponce?.cargos[0]?.temperature?.title || "",
                value: {
                  label: orderResponce?.cargos[0]?.temperature?.title || "",
                  id: orderResponce?.cargos[0]?.temperature?.id || "",
                },
              }
            : null,
          weight: orderResponce?.cargos[0]?.weight || "",
          quantity: +orderResponce?.cargos?.length || 1,
          /// errors
          cargoTypeError: null,
          HSCodeValueError: null,
          adrError: null,
          adrNumberError: null,
          adrClassError: null,
          trailerTypeError: null,
          trailerSizeError: null,
          temperatureRangeError: null,
          weightError: null,
        },
      ];
      setCargoIds(orderResponce.cargos.map((cargo) => cargo.id));
      setGroup(newData);
    }
  }, [orderResponce, user]);

  // catch locations error
  useEffect(() => {
    if (updateOceanError && updateOceanError.errors) {
      const updatedLocations = locations.map((location, index) => {
        const errorKey = `cargos.0.locations.${index}.inputted_address`;
        const errorValue =
          updateOceanError.errors[errorKey] &&
          updateOceanError.errors[errorKey][0]
            ? updateOceanError.errors[errorKey][0]
            : null;

        return {
          ...location,
          error: errorValue,
        };
      });
      setLocations(updatedLocations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateOceanError]);

  // catch cargo errors
  useEffect(() => {
    if (updateOceanError?.errors) {
      const updatedGroup = group.map((item, index) => ({
        ...item,
        cargoTypeError:
          updateOceanError?.errors?.[`cargos.${index}.type_id`]?.[0] || null,
        HSCodeValueError:
          updateOceanError?.errors?.[`cargos.${index}.hs_code`]?.[0] || null,
        adrError:
          updateOceanError?.errors?.[`cargos.${index}.adr`]?.[0] || null,
        adrNumberError:
          updateOceanError?.errors?.[`cargos.${index}.un_number`]?.[0] || null,
        adrClassError:
          updateOceanError?.errors?.[`cargos.${index}.adr_class`]?.[0] || null,
        adrFileError:
          updateOceanError?.errors?.[`cargos.${index}.msds_file`]?.[0] || null,
        oversizedError:
          updateOceanError?.errors?.[`cargos.${index}.oversized`]?.[0] || null,
        // unknownContainerError:
        // createOceanError?.errors?.[`cargos.${index}.unknown_container`]?.[0] || null,
        ///////////
        trailerTypeError:
          updateOceanError?.errors?.[`cargos.${index}.trailer_type_id`]?.[0] ||
          null,
        trailerSizeError:
          updateOceanError?.errors?.[`cargos.${index}.trailer_size_id`]?.[0] ||
          null,
        temperatureRangeError:
          updateOceanError?.errors?.[
            `cargos.${index}.temperature_range_id`
          ]?.[0] || null,
        weightError:
          updateOceanError?.errors?.[`cargos.${index}.weight`]?.[0] || null,
      }));
      setGroup(updatedGroup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateOceanError]);

  // reset redux states
  useEffect(() => {
    if (updateOceanResponce) {
      dispatch(resetUpdateOcean());
      navigate(-1);
    }
  }, [dispatch, navigate, updateOceanResponce]);

  // submit
  const submitHandler = () => {
    if (cargoIds) {
      const cargos = group.flatMap((item) =>
        Array.from({ length: item.quantity }, () => ({
          ...item,
        }))
      );
      const data = {
        orderId: orderId,
        transfer_type_id: 1,
        type_id: order_type_id || 3,
        services:
          selectedServices.length > 0 ? selectedServices.map((i) => i.id) : [],
        cargos: cargos.map((cargo, index) => ({
          id: cargoIds[index],
          trailer_type_id: cargo?.trailerType?.value?.id || null,
          trailer_size_id: cargo?.trailerSize?.value?.value?.id || null,
          temperature_range_id: cargo?.temperatureRange?.value?.id || null,
          type_id: cargo?.cargoType?.value?.id || null,
          hs_code: cargo?.HSCodeValue || null,
          price: cargoPrice || null,
          price_currency_id: cargoPriceCurrency?.value?.id || null,
          adr: cargo.adr ? true : false,
          un_number: cargo?.adrNumber || "",
          adr_class: cargo?.adrClass || "",
          oversized: cargo?.oversized ? true : false,
          // unknownContainer: cargo?.unknownContainer ? true : false,
          weight: cargo?.weight || null,
          about: comment || null,
          cargo_ready_date: readyCargoTime
            ? moment(readyCargoTime).format("YYYY-MM-DD")
            : null,
          locations: locations.map((location) => ({
            id: location?.id || null,
            direction: location?.direction || null,
            type: location?.type || null,
            inputted_address: location?.inputted_address || null,
          })),
        })),
      };
      dispatch(updateOcean(data));
    }
  };

  return (
    <Wrapper documentTitle={t("shipper.titles.Ocean freight")}>
      {orderLoading && <LoadingPage />}
      {orderError && <PageNotFound />}
      {orderResponce && !orderLoading && (
        <Fragment>
          <OceanTopMenu
            ORDERTYPES={ORDERTYPES}
            order_type_id={order_type_id}
            group={group}
            time={readyCargoTime}
            locations={locations}
          />
          <section id='ShipperUpdateOrderOcean'>
            <div className='ShipperUpdateOrderOceanWrapper'>
              <CreateOrderHeader />
              <OceanTypeSwitcher
                ORDERTYPES={ORDERTYPES}
                order_type_id={order_type_id}
                set_order_type_id={set_order_type_id}
              />
              <OceanLocation
                locations={locations}
                setLocations={setLocations}
              />
              <OceanPackage edit group={group} setGroup={setGroup} />

              <CreateOrderAditionalServices
                title={t("shipper.forms.titles.Additional Services")}
                selectedServices={selectedServices}
                setSelectedServices={setSelectedServices}
              />
              <CreateOrderAditionalInformation
                title={t("shipper.forms.titles.Additional information")}
                readyCargoTime={readyCargoTime}
                setReadyCargoTime={setReadyCargoTime}
                cargoPrice={cargoPrice}
                setCargoPrice={setCargoPrice}
                cargoPriceCurrency={cargoPriceCurrency}
                setCargoPriceCurrency={setCargoPriceCurrency}
                comment={comment}
                setComment={setComment}
                //errors
                cargos_comment_error={cargos_comment_error}
                cargos_ready_date_error={cargos_ready_date_error}
                cargos_price_error={cargos_price_error}
                cargos_price_currency_id_error={cargos_price_currency_id_error}
                order_documents_error={order_documents_error}
              />
              <CreateOrderSubmit
                icon={<FiCheck />}
                title={t("shipper.buttons.Save")}
                loading={updateOceanLoading}
                onClick={() => submitHandler()}
              />
            </div>
          </section>
        </Fragment>
      )}
    </Wrapper>
  );
};

export default EditOceanOrder;
