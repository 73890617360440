import React, { Fragment, useEffect } from "react";
import "./Dashboard.scss";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  getOrders,
  resetGetOrders,
} from "../../../../store/orders/shipperOrders/shipperOrdersSlice";

import {
  getOrders as getAcceptedOrders,
  resetGetOrders as resetGetAcceptedOrders,
} from "../../../../store/orders/shipperAccepted/shipperAcceptedSlice";

import Wrapper from "../../../../componentsAdditional/Wrapper";
import ShipperOverviewHeader from "./../../../../componentsDashboard/ShipperOverview/Components/ShipperOverviewHeader/ShipperOverviewHeader";
import ShipperCards from "../../../../componentsDashboard/ShipperOverview/ShipperCards/ShipperCards";
import ShipperCharts from "../../../../componentsDashboard/ShipperOverview/ShipperCharts/ShipperCharts";
import ActivityTable from "../../../../componentsDashboard/ShipperOverview/ActivityTable/ActivityTable";
import QuotesTable from "../../../../componentsDashboard/ShipperOverview/QuotesTable/QuotesTable";
import LoadingPage from "../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";

const Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { ordersLoading, orders, rejectOrderResponce } = useSelector(
    (state) => state.shipperOrders
  );

  const { ordersLoading: acceptedOrdersLoading, orders: acceptedOrders } =
    useSelector((state) => state.shipperAccepted);

  const { getOrderscountResponce } = useSelector((state) => state.summary);

  useEffect(() => {
    dispatch(getOrders());
    dispatch(getAcceptedOrders());
    return () => {
      dispatch(resetGetOrders());
      dispatch(resetGetAcceptedOrders());
    };
  }, [dispatch, rejectOrderResponce]);

  return (
    <Wrapper documentTitle={t("shipper.titles.Overview")}>
      <section id='SDashboard'>
        <ShipperOverviewHeader />
        <div className='SDashboardStatistic'>
          <ShipperCards />
          <ShipperCharts />
        </div>
        <div className='SDashboardTables'>
          <Fragment>
            {ordersLoading && <LoadingPage />}
            {orders && (
              <ActivityTable
                orders={acceptedOrders}
                loading={acceptedOrdersLoading}
                total={getOrderscountResponce?.accepted_orders}
              />
            )}
          </Fragment>
          <Fragment>
            {ordersLoading && <LoadingPage />}
            {orders && (
              <QuotesTable
                orders={orders}
                total={getOrderscountResponce?.offered_orders}
              />
            )}
          </Fragment>
        </div>
      </section>
    </Wrapper>
  );
};

export default Dashboard;
