import _request from "./../_request";

const getOrders = async (ISO, TOKEN, data) => {
  let url = "order";
  const {
    page,
    keyword,
    status_id,
    trailer_type_id,
    customer_id,
    partner_id,
    start_date,
    end_date,
    min_price,
    max_price,
    revenue_currency_id,
    trailer_id,
    truck_id,
    cargo_type_id,
    pickup_address,
    dropoff_address,
    sort_by,
    direction,
  } = data || {};

  url += page ? `?page=${page}` : "";
  url += keyword ? `&keyword=${keyword}` : "";
  url += status_id ? `&status_id=${status_id}` : "";
  url += trailer_type_id ? `&trailer_type_id=${trailer_type_id}` : "";
  url += customer_id ? `&customer_id=${customer_id}` : "";
  url += partner_id ? `&partner_id=${partner_id}` : "";
  url += start_date ? `&start_date=${start_date}` : "";
  url += end_date ? `&end_date=${end_date}` : "";
  url += min_price ? `&min_price=${min_price}` : "";
  url += max_price ? `&max_price=${max_price}` : "";
  url += revenue_currency_id
    ? `&revenue_currency_id=${revenue_currency_id}`
    : "";
  url += trailer_id ? `&trailer_id=${trailer_id}` : "";
  url += truck_id ? `&truck_id=${truck_id}` : "";
  url += pickup_address ? `&pickup_address=${pickup_address}` : "";
  url += dropoff_address ? `&dropoff_address=${dropoff_address}` : "";
  url += cargo_type_id ? `&cargo_type_id=${cargo_type_id}` : "";
  url += sort_by ? `&sort=${sort_by}` : "";
  url += direction ? `&direction=${direction}` : "";

  const config = {
    url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getOrder = async (ISO, TOKEN, orderId) => {
  const config = {
    url: `order/${orderId}`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const createOrder = async (ISO, TOKEN, data) => {
  const config = {
    url: "order",
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const updateOrder = async (ISO, TOKEN, orderId, data) => {
  const config = {
    url: `order/${orderId}`,
    method: "put",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const deleteOrder = async (ISO, TOKEN, orderId) => {
  const config = {
    url: `/order/${orderId}`,
    method: "delete",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const groupOrder = async (ISO, TOKEN, data) => {
  const config = {
    url: "order/group",
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const bidsRequest = async (ISO, TOKEN, orderId, data) => {
  const config = {
    url: `order/${orderId}/bids/send-request`,
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const getOrderFilters = async (ISO, TOKEN, orderId) => {
  const config = {
    url: `order/${orderId}/filters`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const ordersService = {
  getOrders,
  getOrder,
  createOrder,
  updateOrder,
  deleteOrder,
  groupOrder,
  bidsRequest,
  getOrderFilters,
};

export default ordersService;
