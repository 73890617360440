import React, { Fragment, useState } from "react";
import "./CustomerPrice.scss";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IoMdArrowDropright, IoMdArrowDropdown } from "react-icons/io";
import { FiPlus, FiEdit2 } from "react-icons/fi";
import { MdMonetizationOn } from "react-icons/md";
import CircularProgress from "@mui/material/CircularProgress";

import { useMoney } from "./../../../../../helper/useMoney";

import DButton from "./../../../../Form/DButtons/DButton/DButton";
import EditOrderPriceModal from "./../EditOrderPriceModal/EditOrderPriceModal";
import AddNewCostModal from "../Costs/AddNewCostModal/AddNewCostModal";
import ExtraChargesItem from "../Costs/ExtraChargesItem/ExtraChargesItem";
import DTextButton from "../../../../Form/DButtons/DTextButton/DTextButton";

const CustomerPrice = ({ order }) => {
  const { t } = useTranslation();
  const { getOrderCostsLoading, getOrderCostsResponce } = useSelector(
    (state) => state.orderCosts
  );

  const [showCharges, setShowCharges] = useState(false);
  const [showEditOrderModal, setShowEditOrderModal] = useState(false);
  const [showAddCostModal, setShowAddCostModal] = useState(false);

  const formatedCustomerPrice = useMoney(
    order?.revenue?.amount || 0,
    order?.revenue?.currency?.symbol || ""
  );

  const formatedAllPrice = useMoney(
    order?.total_revenue || 0,
    order?.revenue?.currency?.symbol || ""
  );

  const formatedExtraTotalRevenue = useMoney(
    order?.extra_total_revenue || 0,
    order?.revenue?.currency?.symbol || ""
  );

  return (
    <Fragment>
      {order && (
        <Fragment>
          {order?.revenue?.amount > 0 ? (
            <div id='financialDetailsCustomerPrice'>
              <div className='titlePrice'>
                <h2>
                  {t("tms.titles.Customer price")}:{" "}
                  <span>{formatedAllPrice}</span>
                </h2>
              </div>
              <div className='agreedPrice'>
                <h2>
                  {t("tms.titles.Agreed price")}:{" "}
                  <span>{formatedCustomerPrice}</span>
                </h2>
                <div
                  className='editableicon'
                  onClick={() => setShowEditOrderModal(true)}
                >
                  <FiEdit2 />
                </div>
              </div>
              {order?.extra_total_revenue > 0 ? (
                <div className='extraChargesContainer'>
                  <div className='chargesHeader'>
                    <div className='title'>
                      <div
                        className='icon'
                        onClick={() => setShowCharges((state) => !state)}
                      >
                        {showCharges ? (
                          <IoMdArrowDropdown />
                        ) : (
                          <IoMdArrowDropright />
                        )}
                      </div>
                      <h4>
                        {t("tms.titles.Extra charges")}{" "}
                        <span>{formatedExtraTotalRevenue}</span>
                      </h4>
                    </div>
                    <div
                      className='AddButton'
                      onClick={() => setShowAddCostModal(true)}
                    >
                      <FiPlus />
                    </div>
                  </div>
                  <div
                    className={showCharges ? "chargeslist show" : "chargeslist"}
                  >
                    {getOrderCostsLoading ? (
                      <div className='loading'>
                        <CircularProgress size='1rem' color='primary' />
                      </div>
                    ) : (
                      <ul className='list'>
                        {getOrderCostsResponce &&
                          getOrderCostsResponce
                            .filter((i) => i.operation === "IN")
                            .map((item) => (
                              <ExtraChargesItem
                                operation='IN'
                                key={item.id}
                                item={item}
                                orderId={order.id}
                              />
                            ))}
                      </ul>
                    )}
                  </div>
                </div>
              ) : (
                <div className='noExtraCharges'>
                  <DTextButton
                    title={t("tms.forms.buttons.Add charge")}
                    leftIcon={<FiPlus />}
                    onClick={() => setShowAddCostModal(true)}
                  />
                </div>
              )}
            </div>
          ) : (
            <div id='financialDetailsNoCustomerPrice'>
              <div className='title'>
                <h2>{t("tms.desc.No customer price")}</h2>
              </div>
              <DButton
                title={t("tms.forms.buttons.Enter price")}
                onClick={() => setShowEditOrderModal(true)}
                rightIcon={<MdMonetizationOn />}
              />
            </div>
          )}
          {showEditOrderModal && (
            <EditOrderPriceModal
              order={order}
              isOpen={showEditOrderModal}
              isClose={() => setShowEditOrderModal(false)}
            />
          )}
          {showAddCostModal && (
            <AddNewCostModal
              operation='IN'
              isOpen={showAddCostModal}
              isClose={setShowAddCostModal}
              orderId={order.id}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default CustomerPrice;
