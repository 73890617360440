import React, { useState, useEffect } from "react";
import "./BoardsList.scss";

import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import {
  getBoards,
  resetGetBoards,
} from "./../../../../../store/loadBoards/loadBoardsSlice";

import Wrapper from "../../../../../componentsAdditional/Wrapper";
import PageSwitcher from "../../../../../componentsDashboard/PageSwitcher/PageSwitcher";
import LoadsTable from "../../../../../componentsDashboard/LoadBoards/LoadsTable/LoadsTable";
import LoadingPage from "../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import LoadBoardFilters from "../../../../../componentsDashboard/LoadBoards/LoadBoardFilters/LoadBoardFilters";
import SortSelect from "../../../../../componentsDashboard/FilterForms/SortSelect/SortSelect";
import FavoriteRoutesButton from "../../../../../componentsDashboard/LoadBoards/FavoriteRoutes/FavoriteRoutesButton/FavoriteRoutesButton";
import MainTitle from "../../../../../componentsDashboard/MainTitle/MainTitle";

const BoardsList = () => {
  const { t } = useTranslation();

  const SORT_OPTIONS = [
    {
      label: t("tms.filters.labels.Type"),
      options: [
        {
          value: "1",
          label: t("tms.filters.labels.Newest"),
          data: { directionBy: "desc", sortBy: "created_at" },
        },
        {
          value: "2",
          label: t("tms.filters.labels.Oldest"),
          data: { directionBy: "asc", sortBy: "created_at" },
        },
      ],
    },
    {
      label: t("tms.filters.labels.Pick up date"),
      options: [
        {
          value: "3",
          label: t("tms.filters.labels.Earliest"),
          data: { directionBy: "asc", sortBy: "pickup_date" },
        },
        {
          value: "4",
          label: t("tms.filters.labels.Latest"),
          data: { directionBy: "desc", sortBy: "pickup_date" },
        },
      ],
    },
  ];
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const {
    getBoardsLoading,
    getBoardsResult,
    getBoardsMeta,
    biddingBoardResponce,
    takeBoardResponce,
  } = useSelector((state) => state.loadBoards);
  const { favouriteLocationsInput } = useSelector(
    (state) => state.favoriteLocations
  );

  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1
  );
  const [pickUp, setPickUp] = useState(null);
  const [dropOff, setDropOff] = useState(null);
  const [trailerType, setTrailerType] = useState(null);
  const [maxWeight, setMaxWeight] = useState("");
  const [date, setDate] = useState({});
  const [rate, setRate] = useState(null);
  const [sort, setSort] = useState(null);
  const [direction, setDirection] = useState(null);

  const [pickUpInput, setPickUpInput] = useState(favouriteLocationsInput?.[0]);
  const [dropOffInput, setDropOffInput] = useState(
    favouriteLocationsInput?.[1]
  );

  const handleClearFilters = () => {
    if (pickUp) setPickUp(null);
    if (dropOff) setDropOff(null);
    if (trailerType) setTrailerType(null);
    if (maxWeight) setMaxWeight("");
    if (rate) setRate(null);
    if (Object.keys(date).length > 0) setDate({});

    if (pickUpInput) setPickUpInput("");
    if (dropOffInput) setDropOffInput("");
  };

  useEffect(() => {
    setPickUp(favouriteLocationsInput?.[0]);
    setPickUpInput(favouriteLocationsInput?.[0]);
    setDropOff(favouriteLocationsInput?.[1]);
    setDropOffInput(favouriteLocationsInput?.[1]);
  }, [favouriteLocationsInput]);

  useEffect(() => {
    dispatch(
      getBoards({
        page,
        // has_bid: true,
        pickup_address: pickUp || "",
        dropoff_address: dropOff || "",
        trailer_type_id: trailerType?.value?.id || "",
        maxweight: maxWeight || "",
        start_date:
          Object.keys(date).length > 0 && date?.startDate
            ? moment(date.startDate).format("DD/MM/YYYY")
            : "",
        end_date:
          Object.keys(date).length > 0 && date?.endDate
            ? moment(date.endDate).format("DD/MM/YYYY")
            : "",
        has_bidding: rate?.value || "",
        sort: sort?.data?.sortBy,
        direction: direction,
      })
    );
    return () => {
      dispatch(resetGetBoards());
    };
  }, [
    dispatch,
    date,
    direction,
    dropOff,
    maxWeight,
    page,
    pickUp,
    rate,
    sort,
    trailerType,
    biddingBoardResponce,
    takeBoardResponce,
  ]);

  return (
    <Wrapper documentTitle={t("tms.documentTitles.Load Boards")}>
      <section id='BoardsList'>
        <MainTitle title={t("tms.titles.Load Boards")} />
        <PageSwitcher name='boards' />
        <LoadBoardFilters
          setPickUp={setPickUp}
          setDropOff={setDropOff}
          trailerType={trailerType}
          setTrailerType={setTrailerType}
          maxWeight={maxWeight}
          setMaxWeight={setMaxWeight}
          date={date}
          setDate={setDate}
          rate={rate}
          setRate={setRate}
          clearFilters={handleClearFilters}
          pickUpInput={pickUpInput}
          setPickUpInput={setPickUpInput}
          dropOffInput={dropOffInput}
          setDropOffInput={setDropOffInput}
        />
        <div className='sortContainer'>
          <div className='result'>
            {getBoardsMeta &&
              `${t("tms.desc.Result")} ${getBoardsMeta.total} ${t(
                "tms.desc.order"
              )}`}
          </div>
          <div className='rightContainer'>
            <div className='sortButton'>
              <SortSelect
                value={sort}
                setValue={(state) => {
                  setSort(state);
                  setDirection(state?.data?.directionBy);
                }}
                options={SORT_OPTIONS}
              />
            </div>
            <div className='favRoutesButton'>
              <FavoriteRoutesButton />
            </div>
          </div>
        </div>
        {getBoardsLoading && <LoadingPage />}
        {getBoardsResult && !getBoardsLoading && (
          <LoadsTable
            items={getBoardsResult}
            page={page}
            setPage={setPage}
            pageCount={getBoardsMeta.last_page}
            total={getBoardsMeta.total}
          />
        )}
      </section>
    </Wrapper>
  );
};

export default BoardsList;
