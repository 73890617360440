import { useSelector } from "react-redux";

export const useOrderDocumentsErrors = () => {
  const { createOrderDocumentError } = useSelector(
    (state) => state.orderDocuments
  );

  const create_document_type_id_error =
    createOrderDocumentError &&
    createOrderDocumentError.errors &&
    createOrderDocumentError.errors.document_type_id &&
    createOrderDocumentError.errors.document_type_id[0];

  const create_document_error =
    createOrderDocumentError &&
    createOrderDocumentError.errors &&
    createOrderDocumentError.errors.document &&
    createOrderDocumentError.errors.document[0];

  const create_document_Network_error =
    createOrderDocumentError && createOrderDocumentError === "Network Error";

  return {
    create_document_type_id_error,
    create_document_error,
    create_document_Network_error,
  };
};
