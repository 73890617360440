import React from "react";
import "./FinancialDetailPayment.scss";

import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import { PiWarningCircleFill } from "react-icons/pi";

import { useMoney } from "./../../../../../helper/useMoney";
import { useOrderPaymentSelectSubOptions } from "../../../../../helper/useOrderPaymentSelectSubOptions";

const FinancialDetailPayment = ({ order }) => {
  const { t } = useTranslation();

  const paymentSubOptions = useOrderPaymentSelectSubOptions();

  const paymentMethod = order?.payment?.type?.name || null;

  const formattedMoney = useMoney(
    order?.payment?.amount || 0,
    order?.payment?.currency_id?.symbol || "-"
  );

  const findOrderPaymentSub =
    paymentSubOptions.find((i) => i?.value?.id === order?.payment?.option_type)
      ?.label || null;

  const formatedConsignation = order.payment?.days?.toString() || null;

  return (
    <div id='FinancialDetailspayment'>
      <h2>
        {t("tms.titles.Payment method")}: <span>{paymentMethod}</span>
      </h2>
      <Tooltip
        placement='top'
        title={
          <div className='StatusFieldContent'>
            {paymentMethod && (
              <h3>
                {paymentMethod}
                {order?.payment?.amount && <p>({formattedMoney})</p>}
              </h3>
            )}
            {findOrderPaymentSub && (
              <h3>
                {t("tms.titles.Condition")}: <p>{findOrderPaymentSub}</p>
              </h3>
            )}
            {formatedConsignation && (
              <h3>
                {t("tms.titles.Consignation")}:
                <p>{`${formatedConsignation || ""} ${t("tms.desc.days")}`}</p>
              </h3>
            )}
          </div>
        }
        classes={{ tooltip: "FinancialDetailsTooltip" }}
      >
        <div className='icon'>
          <PiWarningCircleFill />
        </div>
      </Tooltip>
    </div>
  );
};

export default FinancialDetailPayment;
