import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import trackerManagerService from "./trackerManagerService";

const initialState = {
  getTrackingLoading: true,
  getTrackingResponce: null,
  getTrackingError: null,
};

export const getTracking = createAsyncThunk(
  "trackerManager/getTracking",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await trackerManagerService.getTracking(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const trackerManagerSlice = createSlice({
  name: "trackerManager",
  initialState,
  reducers: {
    resetGetTracking: (state) => {
      state.getTrackingLoading = true;
      state.getTrackingResponce = null;
      state.getTrackingError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTracking.pending, (state) => {
        state.getTrackingLoading = true;
        state.getTrackingResponce = null;
        state.getTrackingError = null;
      })
      .addCase(getTracking.fulfilled, (state, action) => {
        state.getTrackingLoading = false;
        state.getTrackingResponce = action.payload;
        state.getTrackingError = null;
      })
      .addCase(getTracking.rejected, (state, action) => {
        state.getTrackingLoading = false;
        state.getTrackingResponce = null;
        state.getTrackingError = action.payload;
      });
  },
});

export const { resetGetTracking } = trackerManagerSlice.actions;
export default trackerManagerSlice.reducer;
