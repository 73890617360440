import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import routesService from "./routesService";

const initialState = {
  getRoutesListLoading: true,
  getRoutesListResponce: null,
  getRoutesListError: null,

  getCrmRoutesLoading: true,
  getCrmRoutesResponce: null,
  getCrmRoutesError: null,
};

export const getRoutesList = createAsyncThunk("routes/getRoutesList", async (_, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await routesService.getRoutesList(ISO, TOKEN);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getCrmRoutesList = createAsyncThunk("routes/getCrmRoutes", async (_, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    return await routesService.getCrmRoutes(ISO);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const routesSlice = createSlice({
  name: "routes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRoutesList.pending, (state) => {
        state.getRoutesListResponce = null;
        state.getRoutesListLoading = true;
        state.getRoutesListError = null;
      })
      .addCase(getRoutesList.fulfilled, (state, action) => {
        state.getRoutesListResponce = action.payload;
        state.getRoutesListLoading = false;
        state.getRoutesListError = null;
      })
      .addCase(getRoutesList.rejected, (state, action) => {
        state.getRoutesListResponce = null;
        state.getRoutesListLoading = false;
        state.getRoutesListError = action.payload;
      })

      .addCase(getCrmRoutesList.pending, (state) => {
        state.getCrmRoutesResponce = null;
        state.getCrmRoutesLoading = true;
        state.getCrmRoutesError = null;
      })
      .addCase(getCrmRoutesList.fulfilled, (state, action) => {
        state.getCrmRoutesResponce = action.payload?.data;
        state.getCrmRoutesLoading = false;
        state.getCrmRoutesError = null;
      })
      .addCase(getCrmRoutesList.rejected, (state, action) => {
        state.getCrmRoutesResponce = null;
        state.getCrmRoutesLoading = false;
        state.getCrmRoutesError = action.payload;
      });
  },
});

export default routesSlice.reducer;
