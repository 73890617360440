import React, { useEffect, Fragment, useState } from "react";
import "./Mobile.scss";

import { useTranslation } from "react-i18next";
import { IoMdSend } from "react-icons/io";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  getDrivers,
  resetGetDrivers,
} from "../../../../../store/APPTracker/APPTrackerSlice";

import PageSwitcher from "../../../../../componentsDashboard/PageSwitcher/PageSwitcher";
import Wrapper from "../../../../../componentsAdditional/Wrapper";
import MobileTitle from "../../../../../componentsDashboard/TrackerGPS/ConnectMobile/MobileTitle/MobileTitle";
import MobileTable from "../../../../../componentsDashboard/TrackerGPS/ConnectMobile/MobileTable/MobileTable";
import LoadingPage from "../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import EmptyList from "../../../../../componentsDashboard/UI/EmptyList/EmptyList";
import MobileHeader from "../../../../../componentsDashboard/TrackerGPS/ConnectMobile/MobileHeader/MobileHeader";

const Mobile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { getDriversLoading, getDriversResponce, sendInvitationResponce } =
    useSelector((state) => state.APPTracker);

  const { createDriverResponce, updateDriverResponce, deleteDriverResponce } =
    useSelector((state) => state.drivers);

  const { getDriverscountResponce, getDriverscountLoading } = useSelector(
    (state) => state.summary
  );

  const [active, setActive] = useState({
    id: 0,
    name: t("tms.filters.labels.ALL"),
    value: null,
  });
  const FILTERS = [
    { id: 0, name: t("tms.filters.labels.ALL"), value: null },
    { id: 1, name: t("tms.filters.labels.Active"), value: "true" },
    { id: 2, name: t("tms.filters.labels.No active"), value: "false" },
  ];

  useEffect(() => {
    const data = {
      linked_to: active.value,
    };
    dispatch(getDrivers(data));
    return () => {
      dispatch(resetGetDrivers());
    };
  }, [
    dispatch,
    active.value,
    createDriverResponce,
    updateDriverResponce,
    deleteDriverResponce,
    sendInvitationResponce,
  ]);

  return (
    <Wrapper documentTitle={t("tms.documentTitles.App tracking")}>
      <section id='Mobile'>
        <PageSwitcher name='Mobile' />

        {getDriverscountLoading && <LoadingPage />}
        {getDriverscountResponce && (
          <Fragment>
            {getDriverscountResponce.drivers_count === 0 ? (
              <EmptyList
                title={t("tms.titles.No Driver")}
                description={t(
                  "tms.desc.Ready to track? Add drivers to connect them to your app"
                )}
                buttonText={t("tms.forms.buttons.Add driver")}
                image='/assets/images/dashboard/cover11.svg'
                icon={<IoMdSend />}
                onClick={() => {
                  navigate("/dashboard/tms/fleet_management/drivers");
                }}
              />
            ) : (
              <Fragment>
                <MobileTitle />
                <MobileHeader
                  FILTERS={FILTERS}
                  active={active}
                  setActive={setActive}
                />
                {getDriversLoading && <LoadingPage />}
                {getDriversResponce && (
                  <MobileTable drivers={getDriversResponce} />
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      </section>
    </Wrapper>
  );
};

export default Mobile;
