import React from "react";
import "./PartnersListHeader.scss";

import { useTranslation } from "react-i18next";
import { FaPlusCircle } from "react-icons/fa";

import DButton from "../../../Form/DButtons/DButton/DButton";
import DSearch from "../../../FilterForms/DSearch/DSearch";

const PartnersListHeader = ({ keyword, setKeyword, setAddModalShow }) => {
  const { t } = useTranslation();
  return (
    <div id='PartnersListHeader'>
      <DSearch value={keyword} setValue={setKeyword} />
      <DButton
        onClick={() => setAddModalShow(true)}
        title={t("tms.forms.buttons.Add partner")}
        rightIcon={<FaPlusCircle />}
      />
    </div>
  );
};

export default PartnersListHeader;
