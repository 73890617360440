import React from "react";
import "./SelectTrailerField.scss";

import { useTranslation } from "react-i18next";
import { FaListUl } from "react-icons/fa";
import { IoMdAddCircleOutline } from "react-icons/io";

import Character from "../../../UI/Character/Character";

const SelectTrailerField = ({ trailer, onClick, icon }) => {
  const { t } = useTranslation();

  const title =
    trailer &&
    `${trailer.type && trailer.type.name} - ${
      trailer.volume_capacity && trailer.volume_capacity
    } m³`;

  return (
    <td id='SelectTrailerField' onClick={onClick}>
      {trailer ? (
        <div className='SelectTrailerFieldContainer'>
          <div
            className='cover'
            style={{
              backgroundImage: icon
                ? `url(${icon})`
                : `url(${`/assets/images/dashboard/cover7.svg`})`,
            }}
          ></div>

          <div className='content'>
            <div className='text'>
              <h3>
                <Character title={title} max={35} />
              </h3>

              <p>
                <Character
                  title={
                    trailer.registration_number
                      ? trailer.registration_number
                      : "-"
                  }
                  max={15}
                />
              </p>
            </div>

            <div className='icon'>
              <FaListUl />
            </div>
          </div>
        </div>
      ) : (
        <div className='noTrailerContainer'>
          <div className='noTrailerContainerContent'>
            <h3>{t("tms.tables.buttons.Select trailer")}</h3>
            <div className='icon'>
              <IoMdAddCircleOutline />
            </div>
          </div>
        </div>
      )}
    </td>
  );
};

export default SelectTrailerField;
