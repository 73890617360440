import React from "react";
import "./Carrier.scss";

import { useTranslation } from "react-i18next";

import Wrapper from "./../../../componentsAdditional/Wrapper";
import CoverCarrier from "./../../../componentsLanding/Landing/Covers/CoverCarrier/CoverCarrier";
import ForAboutCarrier from "./../../../componentsLanding/Landing/Abouts/ForAboutCarrier/ForAboutCarrier";
import JoinUsCarrier from "./../../../componentsLanding/Landing/Joins/JoinUsCarrier/JoinUsCarrier";
import ForWorkflowCarrier from "./../../../componentsLanding/Landing/Workflows/ForWorkflowCarrier/ForWorkflowCarrier";
import ContactCarrier from "./../../../componentsLanding/Landing/Contacts/ContactCarrier/ContactCarrier";
import FaqSectionCarrier from "./../../../componentsLanding/Landing/FAQs/FaqSectionCarrier/FaqSectionCarrier";
import SubscribeCarrier from "../../../componentsLanding/Landing/SubscribeCarrier/SubscribeCarrier";
import WebPlatformCarrier from "../../../componentsLanding/Landing/WebPlatformCarrier/WebPlatformCarrier";
import TmsSolutionCarrier from "../../../componentsLanding/Landing/TmsSolutionCarrier/TmsSolutionCarrier";

const Carrier = () => {
  const { t } = useTranslation();

  return (
    <Wrapper documentTitle={t("landing.documentTitles.Carrier")}>
      <CoverCarrier />
      <WebPlatformCarrier />
      <TmsSolutionCarrier />
      <ForWorkflowCarrier />
      <ForAboutCarrier />
      <JoinUsCarrier />
      <FaqSectionCarrier />
      <SubscribeCarrier />
      <ContactCarrier />
    </Wrapper>
  );
};

export default Carrier;
