import { useSelector } from "react-redux";

export const useUpdateStatusesError = () => {
  const { updateCargoStatusesError } = useSelector((state) => state.cargos);

  const update_status_id_error =
    updateCargoStatusesError &&
    updateCargoStatusesError.errors &&
    updateCargoStatusesError.errors.status_id &&
    updateCargoStatusesError.errors.status_id[0];

  const update_notify_error =
    updateCargoStatusesError &&
    updateCargoStatusesError.errors &&
    updateCargoStatusesError.errors.notify &&
    updateCargoStatusesError.errors.notify[0];

  const update_message_error =
    updateCargoStatusesError &&
    updateCargoStatusesError.errors &&
    updateCargoStatusesError.errors.message &&
    updateCargoStatusesError.errors.message[0];

  return {
    update_status_id_error,
    update_notify_error,
    update_message_error,
  };
};
