import React, { Fragment } from "react";
import "./BidResponseField.scss";

import { useTranslation } from "react-i18next";
import { FiCheck } from "react-icons/fi";
import { useMoney } from "../../../../../../helper/useMoney";

const BidResponseField = ({ partner }) => {
  const { t } = useTranslation();

  const bidPrice =
    partner &&
    partner.analytics &&
    partner.analytics.bids[0] &&
    partner.analytics.bids[0].amount
      ? partner.analytics.bids[0].amount
      : null;

  const bidCurrency =
    partner &&
    partner.analytics &&
    partner.analytics.bids[0] &&
    partner.analytics.bids[0].currency
      ? partner.analytics.bids[0].currency.symbol
      : null;

  const bid = useMoney(bidPrice || 0, bidCurrency || "-");

  return (
    <td id='BidResponseField'>
      <Fragment>
        {partner.analytics.number_of_bid_requests > 0 ? (
          <Fragment>
            {partner.analytics.bids.length === 0 ? (
              <div className='OfferSend'>
                <div className='icon'>
                  <FiCheck />
                </div>
                <p>{t("tms.tables.others.Offer send")}</p>
              </div>
            ) : (
              <div className='BidResponseFieldItem'>{bid}</div>
            )}
          </Fragment>
        ) : (
          <div className='noBidResponse'>
            {t("tms.tables.noInfo.You haven’t sent")}
          </div>
        )}
      </Fragment>
    </td>
  );
};

export default BidResponseField;
