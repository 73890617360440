import React, { useEffect, useRef } from "react";
import "./AddOtherRouteSelect.scss";

import Select from "react-select";

const AddOtherRouteSelect = ({
  routesList,
  selectedRoutes,
  onChange,
  isFocused,
  onBlur,
}) => {
  const selectRef = useRef(null);

  const options = routesList
    ?.filter((item) => !item?.featured)
    ?.map((item) => ({
      value: item?.id,
      label: item?.title,
    }));

  useEffect(() => {
    if (isFocused) {
      selectRef.current.focus();
    }
  }, [isFocused]);

  return (
    <div id='AddOtherRouteSelect'>
      <Select
        ref={selectRef}
        classNamePrefix='select'
        name='filterLinkedSelect'
        className='selectComponent'
        options={options || []}
        placeholder=''
        menuPlacement='top'
        onChange={(item) => {
          onChange({
            id: item?.value,
            name: item?.label,
            featured: false,
          });
        }}
        openMenuOnFocus
        isSearchable={true}
        isOptionDisabled={(item) => selectedRoutes.includes(item?.value)}
        onBlur={onBlur}
      />
    </div>
  );
};

export default AddOtherRouteSelect;
