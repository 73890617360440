import React, { useEffect } from "react";
import "./SideDetails.scss";

import { useSelector, useDispatch } from "react-redux";

import {
  getOrder,
  resetGetOrder,
} from "./../../../store/orders/shipperAccepted/shipperAcceptedSlice";

import LoadingPage from "./../../../componentsAdditional/Loading/LoadingPage/LoadingPage";

import DetailsHeader from "../Components/DetailsHeader/DetailsHeader";
import OrderStatusBarWithoutAction from "../../Orders/OrderStatusBar/OrderStatusBarWithoutAction/OrderStatusBarWithoutAction";
import OrderDetailsWrapper from "../../Orders/OrderDetailsWrapper/OrderDetailsWrapper";
import FinancialDetailsShipper from "../../Orders/FinancialDetailsShipper/FinancialDetailsShipper";
import ShipperTracking from "../../Orders/ShipperTracking/ShipperTracking";
import DocumentsDetails from "../../Orders/DocumentsDetails/DocumentsDetails";

const SideDetails = ({ activeOrderId, setActiveOrderId }) => {
  const dispatch = useDispatch();

  const { orderLoading, orderResponce } = useSelector(
    (state) => state.shipperAccepted
  );

  const { createOrderDocumentResponce, deleteOrderDocumentResponce } =
    useSelector((state) => state.orderDocuments);

  useEffect(() => {
    if (activeOrderId) {
      dispatch(getOrder(activeOrderId));
    }
    return () => {
      dispatch(resetGetOrder());
    };
  }, [
    dispatch,
    activeOrderId,
    createOrderDocumentResponce,
    deleteOrderDocumentResponce,
  ]);

  return (
    <div id='SideViewDetails'>
      {orderLoading && <LoadingPage />}

      {orderResponce && !orderLoading && (
        <div className='SideViewDetailsWrapper'>
          <DetailsHeader order={orderResponce} />
          <OrderStatusBarWithoutAction order={orderResponce} />
          <OrderDetailsWrapper item={orderResponce} order={orderResponce} />

          <div className='SideViewDetailsWrapperCards'>
            <div className='SideViewDetailsWrapperCardsleft'>
              <FinancialDetailsShipper order={orderResponce} />

              <DocumentsDetails
                orderId={orderResponce.id}
                order={orderResponce}
              />
            </div>

            <ShipperTracking order={orderResponce} />
          </div>
        </div>
      )}
    </div>
  );
};

export default SideDetails;
