import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";

import { MdMonetizationOn } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import {
  deleteAdvance,
  resetDeleteAdvance,
} from "../../../../store/orders/advance/advanceSlice";

import DModal from "../../../Modal/DModal/DModal";

const DeletePrePaymentModal = ({ isOpen, isClose, order, item }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { deleteAdvanceLoading, deleteAdvanceResponce } = useSelector(
    (state) => state.advance
  );

  const submitHandler = () => {
    const data = {
      orderId: order.id,
      advanceId: item.id,
    };
    dispatch(deleteAdvance(data));
  };
  useEffect(() => {
    if (deleteAdvanceResponce) {
      dispatch(resetDeleteAdvance());
      isClose();
    }
  }, [dispatch, isClose, deleteAdvanceResponce]);

  return (
    <DModal
      isOpen={isOpen}
      isClose={isClose}
      title={t("tms.modals.titles.Delete pre payment")}
      submitText={t("tms.modals.buttons.Delete")}
      status='danger'
      onClick={() => submitHandler()}
      icon={<MdMonetizationOn />}
      loading={deleteAdvanceLoading}
    >
      <p>
        {t("tms.modals.desc.Are you sure you want to delete this pre payment?")}
      </p>
    </DModal>
  );
};

export default DeletePrePaymentModal;
