import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customerService from "./customerService";

const initialState = {
  getCustomersLoading: true,
  getCustomersResponce: null,
  getCustomersSelect: null,
  getCustomersMeta: null,
  getCustomersError: null,

  getCustomerLoading: true,
  getCustomerResponce: null,
  getCustomerError: null,

  createCustomerLoading: false,
  createCustomerResponce: null,
  createCustomerError: null,

  updateCustomerLoading: false,
  updateCustomerResponce: null,
  updateCustomerError: null,

  deleteCustomerLoading: false,
  deleteCustomerResponce: null,
  deleteCustomerError: null,
};

export const getCustomers = createAsyncThunk(
  "customer/getCustomers",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await customerService.getCustomers(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getCustomer = createAsyncThunk(
  "customer/getCustomer",
  async (participantId, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await customerService.getCustomer(ISO, TOKEN, participantId);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createCustomer = createAsyncThunk(
  "customer/createCustomer",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await customerService.createCustomer(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateCustomer = createAsyncThunk(
  "customer/updateCustomer",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await customerService.updateCustomer(
        ISO,
        TOKEN,
        data.participantId,
        data
      );
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  "customer/deleteCustomer",
  async (participantId, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await customerService.deleteCustomer(ISO, TOKEN, participantId);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    resetGetCustomers: (state) => {
      state.getCustomersLoading = true;
      state.getCustomersResponce = null;
      state.getCustomersSelect = null;
      state.getCustomersMeta = null;
      state.getCustomersError = null;
    },
    resetGetCustomer: (state) => {
      state.getCustomerLoading = true;
      state.getCustomerResponce = null;
      state.getCustomerError = null;
    },
    resetCreateCustomer: (state) => {
      state.createCustomerLoading = false;
      state.createCustomerResponce = null;
      state.createCustomerError = null;
    },
    resetUpdateCustomer: (state) => {
      state.updateCustomerLoading = false;
      state.updateCustomerResponce = null;
      state.updateCustomerError = null;
    },
    resetDeleteCustomer: (state) => {
      state.deleteCustomerLoading = false;
      state.deleteCustomerResponce = null;
      state.deleteCustomerError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomers.pending, (state) => {
        state.getCustomersLoading = true;
        state.getCustomersResponce = null;
        state.getCustomersSelect = null;
        state.getCustomersMeta = null;
        state.getCustomersError = null;
      })
      .addCase(getCustomers.fulfilled, (state, action) => {
        state.getCustomersLoading = false;
        state.getCustomersResponce = action.payload.data;
        state.getCustomersSelect = action.payload.data.map((item) => ({
          value: item,
          label: item.company.name,
        }));
        state.getCustomersMeta = action.payload.meta;
        state.getCustomersError = null;
      })
      .addCase(getCustomers.rejected, (state, action) => {
        state.getCustomersLoading = false;
        state.getCustomersResponce = null;
        state.getCustomersSelect = null;
        state.getCustomersMeta = null;
        state.getCustomersError = action.payload;
      })

      .addCase(getCustomer.pending, (state) => {
        state.getCustomerLoading = true;
        state.getCustomerResponce = null;
        state.getCustomerError = null;
      })
      .addCase(getCustomer.fulfilled, (state, action) => {
        state.getCustomerLoading = false;
        state.getCustomerResponce = action.payload;
        state.getCustomerError = null;
      })
      .addCase(getCustomer.rejected, (state, action) => {
        state.getCustomerLoading = false;
        state.getCustomerResponce = null;
        state.getCustomerError = action.payload;
      })

      .addCase(createCustomer.pending, (state) => {
        state.createCustomerLoading = true;
        state.createCustomerResponce = null;
        state.createCustomerError = null;
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        state.createCustomerLoading = false;
        state.createCustomerResponce = action.payload;
        state.createCustomerError = null;
      })
      .addCase(createCustomer.rejected, (state, action) => {
        state.createCustomerLoading = false;
        state.createCustomerResponce = null;
        state.createCustomerError = action.payload;
      })

      .addCase(updateCustomer.pending, (state) => {
        state.updateCustomerLoading = true;
        state.updateCustomerResponce = null;
        state.updateCustomerError = null;
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        state.updateCustomerLoading = false;
        state.updateCustomerResponce = action.payload;
        state.updateCustomerError = null;
      })
      .addCase(updateCustomer.rejected, (state, action) => {
        state.updateCustomerLoading = false;
        state.updateCustomerResponce = null;
        state.updateCustomerError = action.payload;
      })

      .addCase(deleteCustomer.pending, (state) => {
        state.deleteCustomerLoading = true;
        state.deleteCustomerResponce = null;
        state.deleteCustomerError = null;
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        state.deleteCustomerLoading = false;
        state.deleteCustomerResponce = action.payload;
        state.deleteCustomerError = null;
      })
      .addCase(deleteCustomer.rejected, (state, action) => {
        state.deleteCustomerLoading = false;
        state.deleteCustomerResponce = null;
        state.deleteCustomerError = action.payload;
      });
  },
});

export const {
  resetGetCustomers,
  resetGetCustomer,
  resetCreateCustomer,
  resetUpdateCustomer,
  resetDeleteCustomer,
} = customerSlice.actions;
export default customerSlice.reducer;
