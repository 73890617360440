import React from "react";
import "./RoadLocation.scss";

import { useTranslation } from "react-i18next";

import CreateOrderSectionTitle from "./../../Components/CreateOrderSectionTitle/CreateOrderSectionTitle";
import RoadLocationRoute from "./RoadLocationRoute/RoadLocationRoute";
import RoadLocationTime from "./RoadLocationTime/RoadLocationTime";

const RoadLocation = ({
  edit,
  locations,
  setLocations,
  pickUpDate,
  setPickUpDate,
  showPickUpTime,
  setShowPickUpTime,
  pickUpTime,
  setPickUpTime,
  cargos_pickup_date_error,
}) => {
  const { t } = useTranslation();
  return (
    <div id='RoadLocationShipper'>
      <CreateOrderSectionTitle title={t("shipper.forms.titles.Route")} />
      <div className='RoadLocationShipperWrapper'>
        <RoadLocationRoute
          edit={edit}
          locations={locations}
          setLocations={setLocations}
        />

        <RoadLocationTime
          pickUpDate={pickUpDate}
          setPickUpDate={setPickUpDate}
          showPickUpTime={showPickUpTime}
          setShowPickUpTime={setShowPickUpTime}
          pickUpTime={pickUpTime}
          setPickUpTime={setPickUpTime}
          cargos_pickup_date_error={cargos_pickup_date_error}
        />
      </div>
    </div>
  );
};

export default RoadLocation;
