import React, { Fragment, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { FaCheckCircle } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";

import {
  markPaid,
  resetMarkPaid,
} from "./../../../store/orders/advance/advanceSlice";

import DModal from "../../Modal/DModal/DModal";

const MarkAsPaidModal = ({ isOpen, isClose, item }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { markPaidLoading, markPaidResponce } = useSelector(
    (state) => state.advance
  );

  const submitHandler = () => {
    const data = {
      orderId: item.id,
      status: true,
    };
    dispatch(markPaid(data));
  };

  useEffect(() => {
    if (markPaidResponce) {
      dispatch(resetMarkPaid());
      isClose();
    }
  }, [dispatch, isClose, markPaidResponce]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose(false)}
        title={t("tms.modals.titles.Mark As Paid")}
        submitText={t("tms.modals.buttons.Confirm")}
        icon={<FaCheckCircle />}
        status='success'
        onClick={() => submitHandler()}
        loading={markPaidLoading}
      >
        <p>
          {t(
            "tms.modals.desc.Are you sure you want to mark this order as paid?"
          )}
        </p>
      </DModal>
    </Fragment>
  );
};

export default MarkAsPaidModal;
