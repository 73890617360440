import React from "react";
import "./AccountantEmail.scss";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import AccountantEmailCard from "./AccountantEmailCard/AccountantEmailCard";
import AccountantEmailCreateButton from "./AccountantEmailCreateButton/AccountantEmailCreateButton";

const AccountantEmail = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);

  const findAccountant = user?.contacts?.find(
    (contact) => contact?.type?.id === 1
  );

  return (
    <div id='ShipperProfileAccountantEmail'>
      <div className='ShipperProfileAccountantHeader'>
        <h2>{t("shipper.titles.Accountant email")}</h2>
      </div>
      {findAccountant ? (
        <AccountantEmailCard />
      ) : (
        <div className='NoEmail'>
          <p>
            {t(
              "shipper.desc.Add your accountant email to give access to invoices"
            )}
          </p>
          <AccountantEmailCreateButton />
        </div>
      )}
    </div>
  );
};

export default AccountantEmail;
